import React, { useMemo } from 'react';
import { Typography, FormControlLabel, Switch, Card, CardContent, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import NutritionEditor from 'fitbud/views/reminders/editors/nutrition';
import { Header } from './fitness';
import { useReminder } from 'fitbud/views/reminders/provider';
import { getDisplayTime } from 'fitbud/views/reminders/helper';
import _ from 'lodash';
import { hhMMToDate } from '../helper';
import { useStyles } from '.';

const Nutrition = (props) => {
  const { onToggle } = props;
  const classes = useStyles()
  const { edit, setEdit, readOnlyReminders, mlNames, preference, defaultMealCategories } = useReminder();
  const openEdit = () => setEdit('nutrition');
  let { categories = {} } = _.get(readOnlyReminders, 'nutrition', {});
  if(_.isEmpty(categories))  categories = defaultMealCategories || {};
  const enabled = _.get(preference, "nutrition.enabled", false);

  const displayedCategories = useMemo(() => {
    const arr = Object.keys(categories || {})
      .map((key) => {
        const t = categories[key];
        if (!t) return null;
        const dt = getDisplayTime(t);
        const name = mlNames[key];
        return { name, dt, date: hhMMToDate(t) };
      })
      .filter((v) => !!v)
      .sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
      });
    return arr;
  }, [categories, mlNames]);

  const isCategoriesEmpty = _.isEmpty(displayedCategories);

  const handleToggle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onToggle('nutrition');
  };

  return (
    <div className="mt-15">
      <FormControlLabel
        control={<Switch color="primary" classes={{root: classes.reminderSwitch}} checked={!!enabled} onChange={handleToggle} />}
        className="mb-4x"
        label={<Typography className="font_18_600 text-0d0d0d">Nutrition</Typography>}
      />
      <Card>
        <CardContent className="px-20 pt-12 pb-20">
          <Header title="Meal Categories" handleEdit={openEdit} />
          {!isCategoriesEmpty && (
            <div 
              className={clsx('mt-n8')}
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(170px, 1fr))',
              }}
            >
              {displayedCategories.map((category, index) => {
                const { name, dt } = category;
                return (
                  <div style={{marginTop: 20, paddingRight: 20 }}>
                    <Tooltip title={name}>
                      <Typography className={clsx('font_13_500 text-light-grey-2 text-truncate mb-8')}>
                        {name}
                      </Typography>
                    </Tooltip>
                    <Typography className={clsx('text-0d0d0d')}>
                      <span className="font_15_700">{dt.time}</span>&nbsp;
                      <span className="font_15_500">{dt.m}</span>
                    </Typography>
                  </div>
                );
              })}
            </div>
          )}
          {!!isCategoriesEmpty && <div></div>}
        </CardContent>
      </Card>
      {edit === 'nutrition' && <NutritionEditor />}
    </div>
  );
};

export default Nutrition;
