import React, { useContext, useMemo } from 'react';
import moment from 'moment';
import {
  Typography, makeStyles, IconButton, Tooltip,
  CircularProgress, LinearProgress, Button,
} from '@material-ui/core';
import IconLeft from '@material-ui/icons/ChevronLeft';
import IconRight from '@material-ui/icons/ChevronRight';
import { UserSchContext } from './provider';
import WeekdaysView from './weekdays-view';

const styles = makeStyles({
  root: {
    '& .topbar': { height: 48 },
    // '& .dates': { width: 90 },
    '& .arrows': { backgroundColor: '#F2F4F7' },
  },
});

const WeekView = ({ aggrs }) => {
  const {
    aplan, ready, weekReady, dates, editing,
    startDate, lookAheadWeeks, weekPublished, controller,
    currWeek, weekToday, dayToday, remote: { plan },
  } = useContext(UserSchContext);
  const classes = styles();
  const numWeeks = useMemo(() => {
    if (plan && plan.weeks) return plan.weeks.length;
    return 0;
  }, [plan]);
  const line2 = useMemo(() => {
    if (!dates || !dates.length) return ' ';
    const a = dates[0];
    if (dates.length === 1) return dates[0].format('D MMM');
    const b = dates[dates.length - 1];
    return a.format('D MMM') + ' - ' + b.format('D MMM');
  }, [dates]);

  if (!aplan) return null; // FIXME placeholder

  if (!ready || typeof currWeek === 'undefined') return (
    <div className='w-100 h-100 bg-grey-new border bordr-0 bordr-sm-1 corner-0 corner-sm-6 d-flex justify-content-center align-items-center'>
      <CircularProgress color='primary'/>
    </div>
  );

  if (editing) return null;

  return (
    <div className={`w-100 h-100 d-flex flex-column bg-grey-new ${classes.root}`}>
      <div className='bg-white flex-shrink-0 d-flex flex-row position-relative border-bottom px-20 align-items-center topbar'>
        <IconButton size='small' onClick={controller.prevWeek} disabled={currWeek === 0} className='arrows mr-16'><IconLeft/></IconButton>
        <div className='d-flex flex-column mr-20 align-items-center'>
          <Typography className='font_15_600 d-flex'>Week {currWeek + 1}&nbsp;&nbsp;•&nbsp;&nbsp;{line2}</Typography>
        </div>
        <IconButton size='small' onClick={controller.nextWeek} disabled={currWeek === numWeeks - 1} className='arrows'><IconRight/></IconButton>
        {dayToday >= 0 && currWeek !== weekToday && <Tooltip title='Jump to Today'><Button color='primary' onClick={controller.thisWeek} className='ml-12'>Today</Button></Tooltip>}
        <div className='flex-grow-1'/>
        <Button color='primary' variant='contained' disabled={!weekReady || (currWeek < weekToday && dayToday >= 0)} onClick={controller.edit} className='shadow-none'>Edit</Button>
        {(!weekReady || !aggrs) && <LinearProgress className='w-100 position-absolute inset-bottom-left' />}
      </div>
      <WeekdaysView aggrs={aggrs}/>
      <Note startDate={startDate} lookAheadWeeks={lookAheadWeeks} currWeek={currWeek} publishedTill={weekPublished} />
    </div>
  );
};

const Note = ({ startDate, lookAheadWeeks, currWeek, publishedTill }) => {
  if (!publishedTill) return null;
  if (typeof currWeek !== 'number') return null;
  if (currWeek <= publishedTill) return null;
  if (currWeek < lookAheadWeeks) return null;
  const willPubOn = moment(startDate, 'YYYYMMDD').add(7 * (currWeek - lookAheadWeeks), 'days');
  return (
    <div className='d-flex flex-row align-items-center justify-content-center py-2 bg-vdark-grey'>
      <Typography className='text-white' align='center'>
        This week is not yet visible to the client.{' '}
        It will become visible {willPubOn.format('Do MMM')} onwards.
        <Tooltip title={`Clients can only see ${lookAheadWeeks + 1} week${lookAheadWeeks ? 's' : ''} in advance`}>
          <i className='ml-2 fas fa-info-circle'/>
        </Tooltip>
      </Typography>
    </div>
  );
};

export default WeekView;
