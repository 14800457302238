import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const LowLevelIcon = props => (
  <SvgIcon viewBox="0 0 15 15" {...props}>
    <g id="New" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="100_client-detail-1" transform="translate(-1210.000000, -89.000000)" fill-rule="nonzero">
            <g id="check-+-Group-7-+-Base-+-Base-+-Group-12-+-Group-16-+-Group-19-+-Group-19-Copy-+-Group-9-Copy-2-+-Group-7-+-Group-3-Mask-Copy" transform="translate(1020.000000, 0.000000)">
                <g id="Group-13" transform="translate(189.200000, 88.360000)">
                    <g id="1" transform="translate(0.800000, 0.640000)">
                        <path d="M0.29716164,8.736 C0.133065301,8.736 0,8.86640441 0,9.02721933 L0,14.56 L2.08,14.56 L2.08,9.02721933 C2.08,8.86640441 1.9469347,8.736 1.78283836,8.736 L0.29716164,8.736 Z" id="Path" fill="#4089F7"></path>
                        <path d="M6.24,6.51723107 C6.24,6.36407287 6.10694308,6.24 5.94285711,6.24 L4.45714289,6.24 C4.29305692,6.24 4.16,6.36407287 4.16,6.51723107 L4.16,14.56 L6.24,14.56 L6.24,6.51723107 Z" id="Path" fill="#E1E3E6"></path>
                        <path d="M10.4,3.60881867 C10.4,3.45374714 10.2669347,3.328 10.1028384,3.328 L8.61716164,3.328 C8.4530653,3.328 8.32,3.45374714 8.32,3.60881867 L8.32,14.56 L10.4,14.56 L10.4,3.60881867 Z" id="Path" fill="#E1E3E6"></path>
                        <path d="M14.56,0.364024202 C14.56,0.16300555 14.4269347,0 14.2628384,0 L12.7771616,0 C12.6130653,0 12.48,0.16300555 12.48,0.364024202 L12.48,14.56 L14.56,14.56 L14.56,0.364024202 Z" id="Path-Copy" fill="#E1E3E6"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
  </SvgIcon>
);
