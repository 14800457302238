import React from 'react';

const PdfIcon = (props) => {
  const { size, color, ...rest } = props;
  return (
    <svg
      width={size || 40}
      height={size || 40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M33.3337 32.5C33.3337 35.2614 31.0951 37.5 28.3337 37.5H11.667C8.90556 37.5 6.66699 35.2614 6.66699 32.5V7.5C6.66699 4.73858 8.90557 2.5 11.667 2.5H25.0003L33.3337 10.8333V32.5Z"
        fill={color || '#DF3B3B'}
      />
      <path d="M32.0837 11.6667H24.167V3.75L32.0837 11.6667Z" fill="#FFFFFF" />
      <path
        d="M13.175 24.5833V27.5H11.5V19.2058H14.3258C15.1458 19.2058 15.8008 19.4608 16.2883 19.9692C16.7758 20.4775 17.02 21.1383 17.02 21.9517C17.02 22.765 16.7783 23.4058 16.2967 23.8775C15.815 24.3492 15.1458 24.5833 14.2908 24.5833H13.175ZM13.175 23.1875H14.3258C14.645 23.1875 14.8917 23.0833 15.0667 22.8742C15.2417 22.665 15.3283 22.3617 15.3283 21.9625C15.3283 21.5483 15.2392 21.2192 15.0608 20.9733C14.8825 20.7292 14.6433 20.605 14.3433 20.6008H13.175V23.1875ZM18.1367 27.5V19.2058H20.33C21.2983 19.2058 22.0708 19.5133 22.645 20.1283C23.2208 20.7433 23.5142 21.5867 23.5258 22.6575V24.0017C23.5258 25.0917 23.2375 25.9475 22.6633 26.5675C22.0867 27.19 21.2942 27.5 20.2842 27.5H18.1367ZM19.8108 20.6017V26.11H20.3125C20.8708 26.11 21.2642 25.9625 21.4917 25.6683C21.7192 25.3742 21.8392 24.8667 21.8508 24.145V22.7042C21.8508 21.9292 21.7425 21.3892 21.5258 21.0842C21.3092 20.7783 20.9408 20.6175 20.4208 20.6025H19.8108V20.6017ZM29.0058 24.1158H26.4025V27.5H24.7275V19.2058H29.3192V20.6017H26.4025V22.7267H29.0058V24.1158Z"
        fill="#FFFFFF"
      />
    </svg>
  );
};

export default PdfIcon;

export const LinkIcon = (props) => {
  const { size, color, ...rest } = props;
  return (
    <svg
      dth={size || 40}
      height={size || 40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M33.3332 34.6429C33.3332 36.2208 32.054 37.5 30.476 37.5H9.52365C7.94569 37.5 6.6665 36.2208 6.6665 34.6429V5.35714C6.6665 3.77919 7.94569 2.5 9.52365 2.5H24.9998L33.3332 10.8333V34.6429Z"
        fill="#4089F7"
      />
      <path
        d="M22.1357 28.5025H23.2143C25.3715 28.5025 27.1429 26.7382 27.1429 24.574C27.1429 22.4168 25.3786 20.6454 23.2143 20.6454H22.1357"
        stroke="white"
        stroke-width="1.71429"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.8569 20.6454H16.7855C14.6212 20.6454 12.8569 22.4097 12.8569 24.574C12.8569 26.7311 14.6212 28.5025 16.7855 28.5025H17.8569"
        stroke="white"
        stroke-width="1.71429"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.1431 24.5714H22.8574"
        stroke="white"
        stroke-width="1.71429"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M32.0832 11.6667H24.1665V3.75L32.0832 11.6667Z" fill="white" />
    </svg>
  );
};

