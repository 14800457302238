import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const UpBlueArrowIcon = (props) => {
  return (
    <SvgIcon classes={{
      root: "small"
    }} width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} style={{fill:"none"}}>
      <path d="M12.0467 6.37992L8.00003 2.33325L3.95337 6.37992" stroke="#317FF5" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8 13.6668V2.44678" stroke="#317FF5" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </SvgIcon>
  );
};
