import _ from "lodash";
import React from "react";
import { withRouter } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { ClrdButton } from "fitbud/components/form-fields";
import NoPageFound from "fitbud/images/page_not_found.svg";

const PageNotFound = ({ keyName = "page", ...restProps }) => {
  return (
    <div className="text-center flex-grow-1 h-100  d-flex align-items-center justify-content-center flex-column">
      <img height="114" width="325" alt="noitem" src={NoPageFound} />
      <Typography variant="h2" className="fmb-10 fmt-30">
        {`${_.capitalize(keyName)} not found`}
      </Typography>
      <ClrdButton
        className="f-large fmt-30"
        variant="contained"
        color="primary"
        onClick={() => {
          let path = window.location.pathname.split("/");
          if(path.length<=2){
            restProps.history.goBack();
          }
          else{
            path = path.slice(0, 2);
            restProps.history.replace(path.join("/"));
          }
        }}
      >
        Go Back
      </ClrdButton>
    </div>
  );
};

export default withRouter(PageNotFound);
