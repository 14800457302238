import React,{useMemo} from "react";
import {get,lowerCase} from "lodash";
import MuteIcon from "@material-ui/icons/VolumeOff";
import CropIcon from "@material-ui/icons/CropSharp";
import {Tooltip, Typography} from "@material-ui/core";
import clsx from 'clsx';

//exData required for 'alts','is_crop_square','is_mute_video'
export const ExSecondLine=({exercise={},exData={},currentSideName,showAlts=false,showAllAlts=false,toggleAltPopup=null,className})=>{
  const altsLength=useMemo(()=>{
    if(!showAllAlts){
      if(exercise.hideAllAlts) return 0;
      if(exercise.overrideAlts){
        let hiddenAlts=0;
        for(let i in exercise.overrideAlts){
          const obj=exercise.overrideAlts[i];
          if(obj.show===false){
            hiddenAlts++;
          }
        }
        return exData && (exData.alts||[]).length-hiddenAlts;
      }
    }
    return exData && (exData.alts||[]).length;
  },[exData.alts,exercise.hideAllAlts,exercise.overrideAlts,showAllAlts])
    return (
      <Typography className={clsx('d-flex flex-wrap',className)}>
        {showAlts && (altsLength> 0) && (
          <>
            <span
              onClick={(e) => {
                toggleAltPopup();
                e.stopPropagation();
              }}
              className="text-primary font-weight-700"
            >
              {altsLength} ALT
            </span>
            <span className="mx-5">•</span>
          </>
        )}
        {
          !exercise.track && (exercise.ref_type === "reps_w_weight"
            ?<> 
            <span>reps</span>
              <span className="mx-5">•</span>
              <span>weights</span>
              </>
            : exercise.ref_type)
        }
        {
          exercise.track && exercise.track.map((i,index)=>(
            <>
               <span  className="mb-4px">{i==="weight"?"weights":i}</span>
               {index < (exercise.track.length-1) && <span className="mx-5">•</span>}
            </>
          ))
        }
        {!!currentSideName && (
          <>
            <span className="mx-5">•</span>
            {lowerCase(currentSideName[1].value)}
          </>
        )}
        {!!(exercise.tempo || '').length && (
          <>
            <span className="mx-5">•</span>
            <span>{exercise.tempo.split("").join("-")}</span>
          </>
        )} 
        {get(exData, "is_crop_square", false) && (
          <>
            <span className="mx-5">•</span>
            <Tooltip title="Media is square-cropped">
              <CropIcon className="font_16_500" />
            </Tooltip>
          </>
        )}
        {get(exData, "is_mute_video", false) && (
          <>
            <span className="mx-5">•</span>
            <Tooltip title="Media is muted">
              <MuteIcon className="font_16_500" />
            </Tooltip>
          </>
        )}
        {!!get(exercise, "gender", false) && (
          <>
            <span className="mx-5">•</span>
            <Tooltip title="Gender wise tracking available"><span> M/F</span></Tooltip>
          </>
        )}
      </Typography>
    );
  }
