import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Dialog, DialogContent, Grid, Typography, LinearProgress, List } from '@material-ui/core';
import SelectorContextProvider, { SelectorContext } from 'fitbud/components/catalogSelector/selectorContext';
import SelectorHeader from 'fitbud/components/catalogSelector/selectorHeader';
import SelectedList from 'fitbud/components/catalogSelector/selectedList';
import { ClrdButton } from 'fitbud/components/form-fields';
import { useSnackbar } from 'notistack';
import { DEFAULT_ERROR } from 'fitbud/utils/constants';
import firebase from 'firebase';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { snapshotDocsArrayToDataArray } from 'fitbud/utils/helpers';
import { DumbList, listSecondLine } from 'fitbud/components/catalog/index';
import { get } from 'lodash';
import useDebounce from 'fitbud/hooks/useDebounce';
import _ from 'lodash';

export const _parseListData = (l) => {
  return {
    primaryText: get(l, 'data.ref_name'),
    secondaryText: listSecondLine({ doc: l, keyName: 'groupClass' }),
    src: get(l, 'data.thumbnail'),
  };
};

const GcList = (props) => {
  const { onClick } = props;
  const [loading, setLoading] = useState(false);
  const { cid } = useContext(FirebaseAuthContext);
  const [docs, setDocs] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { query } = useContext(SelectorContext);
  const debouncedQuery = useDebounce(query, 500);

  const N = (docs || []).length;
  const fetchGcs = async () => {
    try {
      setLoading(true);
      const docsSnap = await firebase
        .firestore()
        .collection(`companies/${cid}/gcDetails`)
        .where('archive', '==', false)
        .get();
      if (!docsSnap.size) {
        setLoading(false);
        return;
      }
      const list = snapshotDocsArrayToDataArray(docsSnap.docs);
      console.log('>>>> list', list);
      setDocs(list);
    } catch (err) {
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchGcs();
  }, []);

  const searchedClasses = useMemo(() => {
    const q = (debouncedQuery || '').trim().toLocaleLowerCase();
    if (!q) return docs;
    return _.filter(docs, (doc) => _.get(doc, 'data.title', '').toLocaleLowerCase().includes(q));
  }, [debouncedQuery, docs]);

  const handleSelect = (e, doc) => {
    onClick(doc);
  };

  return (
    <div className="flex-1 d-flex flex-column position-relative overflow-x-hidden">
      {loading && <LinearProgress style={{ zIndex: 200 }} className="position-absolute w-100 " />}
      {!loading && !!N && (
        <List disablePadding className="overflow-auto flex-1 bg-white">
          <DumbList docs={searchedClasses} onClick={handleSelect} parseListData={_parseListData} />
        </List>
      )}
    </div>
  );
};

const CatalogMultiSelectorContainer = (props) => {
  const { keyName = 'meals', hidePreSelected } = props;
  const { addSelectedValues } = useContext(SelectorContext);

  const noSelectedPlaceHolder = () => {
    return (
      <div className="flex-grow-1 h-100 fp-20 text-center d-flex align-items-center justify-content-center flex-column ">
        <Typography className="fmt-25 font_15_700">{`No Group Classes Selected`}</Typography>
        <Typography className="fmt-10" style={{ fontSize: '13px', fontWeight: 400, color: '#37404D' }}>
          {`Select Group classes from the left panel`}
        </Typography>
      </div>
    );
  };

  return (
    <DialogContent className="d-flex fp-0">
      <Grid container className="flex-grow-1">
        <Grid
          item
          xs={7}
          className="h-100 d-flex position-relative"
          style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
          <GcList onClick={addSelectedValues} />
        </Grid>
        <Grid className="h-100 d-flex" item xs={5}>
          <SelectedList
            keyName={keyName}
            noSelectedPlaceholder={noSelectedPlaceHolder}
            hidePreSelected={hidePreSelected}
          />
        </Grid>
      </Grid>
    </DialogContent>
  );
};

const Header = (props) => {
  const { handleAdd, selected } = props;
  const { selectedValues } = useContext(SelectorContext);
  const { addAllSelectedValues } = useContext(SelectorContext);
  const totalSelectedValues = (selectedValues || []).length;

  useEffect(() => {
    if (!!selected && !!selected.length) {
      addAllSelectedValues(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const handleSave = () => {
    handleAdd && handleAdd(selectedValues);
  };

  const getHeaderActions = () => {
    return (
      <ClrdButton
        disabled={!totalSelectedValues}
        variant="contained"
        color="primary"
        className="f-medium"
        onClick={handleSave}>
        Save
      </ClrdButton>
    );
  };
  return <SelectorHeader hideLibrarySwitch={true} keyName="groupClasses" headerActions={getHeaderActions} {...props} />;
};

const GroupClassMultiSelector = (props) => {
  const { open, item, hidePreSelected = 0, keyName, handleClose } = props;
  return (
    <Dialog
      open={open}
      id="group_class_selector"
      scroll="paper"
      classes={{
        paper: 'width-800 height-70',
      }}>
      <SelectorContextProvider hidePreSelected={hidePreSelected} item={item} keyName={keyName}>
        <Header {...props} />
        <CatalogMultiSelectorContainer {...props} />
      </SelectorContextProvider>
    </Dialog>
  );
};

export default GroupClassMultiSelector;
