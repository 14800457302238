import Confirmation from 'fitbud/components/confirmationDialog';
import Dialog from 'fitbud/components/Dialog'
import { UsersListInnerSmall } from 'fitbud/components/multiClientSelector';
import { handleError } from 'fitbud/utils/helpers';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import appRdxFns from "fitbud/redux/app";
import { updateGroupClients, removeUserFromGroup } from './helper';
import { bffGetUserProfileByIDs, fetchData } from 'fitbud/api';
import { LinearProgress, Typography } from '@material-ui/core';
import _ from 'lodash';

const PER_PAGE = 15;
const ClientDialog = ({ cid, open, onClose, groupId, groupData, onDelete, onSave }) => {
  const  { type, uids, challenge = false } = groupData || {};
  const [openConfirm, setConfirm] = useState(false);
  const d = useDispatch();
  const { showLoader, hideLoader } = appRdxFns(d);
  const { enqueueSnackbar } = useSnackbar();
  const [clientsProfiles, setClientsProfile] = useState({});
  const [fetching, setFetching] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const onRemove = (id) => {
    setConfirm(id);
  };

  const fetchUsers = useCallback((next=false, initial=false) => {
    if((!initial && !hasMore) || !open) return;
    if(!next) showLoader();
    setFetching(true);
    if(type === "custom"){
      let clientsToFetch = uids.filter((i) => !clientsProfiles[i] ).map((i) => i.split(":").pop());
      bffGetUserProfileByIDs({ cid, 
        uids: initial ? clientsToFetch :
         clientsToFetch.slice(page, page+PER_PAGE), fields: ['name', 'email', 'image'] 
      }).then(({data}) => {
        delete data.success;
        let out = _.get(data, 'data', []).reduce((prev, doc) => {
          return {...prev, [doc._id]: {
            _id: doc._id,
            data: { ...doc },
          }}
        }, {});
        if(next && uids.length <= page){ 
          setHasMore(false);
        } else {
          setPage(prev=> prev+PER_PAGE);
        }
        setFetching(false);
        if(!next) hideLoader();
        setClientsProfile(prev => ({...prev, ...out}));
      });
    } else {
      fetchData('user_profiles', cid, initial ? 0 : page, null, PER_PAGE, '', '', null, null, null, groupData.criteria)
        .then((response) => {
          let userDocs = response.data.data;
          if (!userDocs.length) {
            if(!next) hideLoader();
            setFetching(false);
            return;
          };
          let out = userDocs.reduce((prev, doc) => {
            return {...prev, [doc._id]: { ...doc }}
          }, {});
          setClientsProfile(prev => ({...prev, ...out}));
          setPage(prev=> prev+1);
          setFetching(false);
          if(!next) hideLoader();
        });
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uids, page]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {fetchUsers(false, true)}, [uids]);

  const onConfirmDelete = async() => {
    try {
      showLoader();
      const uid = openConfirm;
      const newUids = uids.filter((i) => i !== uid);
      let promises = [removeUserFromGroup(uid, groupId)];
      if(type === "custom"){
        promises.push(updateGroupClients(groupId, newUids));
      }
      await Promise.all(promises);
      onDelete(uids.length - 1, newUids);
      enqueueSnackbar("User removed from group successfully", { variant: "success" });
      const newClients = { ...clientsProfiles };
      delete newClients[uid];
      setClientsProfile(newClients);
      setConfirm(false);
    } catch(e) {
      handleError(e, enqueueSnackbar);
      console.error(e);
    } finally { hideLoader();}
  };
  if(!Object.keys(clientsProfiles).length) return null;
  return (
    <>
      <Confirmation
        open={!!openConfirm}
        handleClose={() => setConfirm(false)}
        handleCancel={() => setConfirm(false)}
        handleChange={onConfirmDelete}
        msg={"This user will be removed from this group, do you wish to continue ?"}
        confirmOption="Yes, Delete"
        title='Confirm Delete'
      />
      <Dialog
        open={open}
        onClose={onClose}
        title={`${groupData.type === 'custom' ? "Manage" : "Group"} Members`}
        buttonColor="main"
        paperClass="width-400 height-70 bg-body"
        toolbarClass="height-60"
        titleFont="h3"
        dialogContentClassName="d-flex flex-column"
        subtitleClass="text-muted fmt-5 fmln-10"
        onSave={!!challenge? null: type === "custom" && !groupData.protected && onSave}
        actionText={!!challenge? '' : "Add Members"}
        fullWidth
      >
        {type === 'auto' && (
          <div className='px-15 pt-15 pb-5 bg-white'>
            <div className={"p-15"} style={{ border: '1px solid #317FF5', backgroundColor: "#F0F8FD", borderRadius: '6px' }}>
              <Typography className='font_13_400'>
                The members list of this Smart Group is dynamic and will be auto-updated based on the filters you selected while creating this group.
              </Typography>
            </div>
          </div>
        )}
        {fetching && <LinearProgress style={{ zIndex: 10, height: '2px', position: 'absolute', top:0, left: 0, width: '100%' }} />}
          <UsersListInnerSmall
            showEmail
            infiniteScrollProps={{
              next: (e) => fetchUsers(true),
              hasMore,
              className: 'pb-10',
            }}
            id="groupMembersList"
            items={Object.values(clientsProfiles)} 
            onRemove={onRemove} 
            disableRemove={!!challenge || (type !== "custom" || groupData.protected)}
          />
      </Dialog>
    </>
  )
}

export default ClientDialog