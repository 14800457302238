import _ from "lodash";
import React, {  useState, useEffect,useMemo } from "react";
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
  Divider,
  MenuItem,
  FormHelperText,
  IconButton
} from "@material-ui/core";
import { FormTextField } from "fitbud/components/form-fields";
import NumberInput from "fitbud/components/numUnitInput";
import Dialog from "fitbud/components/Dialog";
import ImageFileUpload from "fitbud/components/imageSelector";
import { roundNumber,mapDumbUnitToServingType } from "fitbud/utils/helpers";
import useServings from "fitbud/hooks/useServings";
import { Alert } from "@material-ui/lab";
import { CustomConfirmation } from "fitbud/components/customConfirmation";
import { ClrdButton } from 'fitbud/components/form-fields';
import { DeleteIcon } from 'fitbud/icons/delete';
import { ExpandMore as KeyDown } from '@material-ui/icons/';
import update from "immutability-helper";
import { mapIdToObject } from "../workouts/helperfn";
import AutoSuggest from "fitbud/components/autoSuggest";
import { InputDropdownWConversion } from "fitbud/views/foodRecipes/newUi/inputDropdown";
const DEFAULT_SERVING_OPTION={id:"",serving_size:0,label:""};

const FoodForm = (props) => {
  const {
    doc,
    errors,
    loading,
    type,
    onSubmit,
    handleChange,
    onCancel,
    handleMedia,
    handleDocument,
    removeMedia,
    handleMacros,
    handlePreferredUnit,
    servingData,
    isNew,
    handlePrevMacros,
    handleIsDocument,
    handleCaloriesChange,
    draftFeature,
    handleDraft,
    handlePublish,
    videoStatusRefresh,
    isDraftAvailable,
    updateMediaMeta,
    foodServingFormOnly=false,
    nutritionTags=[],
  } = props;
  //NOTE:serving to exclude gm,ml,ounce
  const [_sortServing,n,preferred_unit_name,extraUnits] = useServings({
    servingData,
    selected:doc.preferred_unit,
    includeDumbUnits:false,
    separateFlOz:true
  });
  const [isConfirmationOpen, showConfirmation] = useState(null);
  const [dirty, setDirty] = useState(false);
  const [allowChangePopup,setChangePopup]=useState(null);
  const {
    fdcId,
    title,
    description,
    thumbnail,
    macros,
    media,
    document,
    ref_name,
    serving,
    serving_size=100,
    serving_type,
    duration,
    noAutomaticCalories,
    noAutomaticMacros,
    systags,
    serving_options=[]
  } = doc||{};
  const _tags = useMemo(() => mapIdToObject(nutritionTags, systags), [nutritionTags, systags]);
  const dumbUnits=useMemo(()=>{
    return extraUnits.map(i=>({label:i.value,value:i.id,selected:doc.preferred_unit===i.id}))
  },[extraUnits,doc.preferred_unit])
  useEffect(()=>{
    handlePrevMacros(noAutomaticMacros) 
  },[noAutomaticMacros])

  const handleSubmit=()=>{
    if(!isNew && !dirty && !isDraftAvailable){
      onCancel();
      return;
    }
    if(isNew|| !draftFeature){
      onSubmit();
    }
    else{
      showConfirmation('save');
    }
  }
  const handleChangeWrapper = (e, skipDirty = false,confirmOpen=allowChangePopup) => {
    //WIP
    // const name=e.target.name||e.target.id;
    // if(['serving_size'  ,'serving'].includes(name) && !isNew && !skipDirty && !confirmOpen){
    //     const newServingType=e.target.unit;
    //   if(name==="serving" ){
    //     const oldServingName=(sortServing.find(i=>i.id===initialDoc.serving)||{}).value;
    //     setChangePopup({
    //       title:"Confirm",
    //       msg:`Recipes using "${title}" will continue to use unit - "${oldServingName}" till you update them.`,
    //       handleChange:()=>handleChangeWrapper(e,skipDirty,true),
    //       handleCancel:()=>setChangePopup(null),
    //       confirmOption:"confirm",
    //       cancelOption:"cancel"
    //     });
    //     return;
    //   }
    //   else if(name==="serving_size" && (newServingType!==initialDoc.serving_type)){
    //     const oldServingTypeName=MEASURE_BY_OPTIONS.find(i=>i.value===initialDoc.serving_type).label;
    //     setChangePopup({
    //       title:"Confirm",
    //       msg:`Recipes using "${title}" will continue to measure by - "${oldServingTypeName}" till you update them.`,
    //       handleChange:()=>handleChangeWrapper({target:{...e.target,id:name}},skipDirty,true),
    //       handleCancel:()=>setChangePopup(null),
    //       confirmOption:"confirm",
    //       cancelOption:"cancel"
    //     });
    //     return;
    //   }
      
    // }
    // else{
    //     //confirm cta
    //     setChangePopup(null);
    // }
    //WIP


    //for new id and recipe lock will always false to the handler
    const lock = !!isNew && type !== "fdc" ? false : !noAutomaticMacros //in case of new it is always false :: 
    handleChange(e, lock, {caloriesLock: !noAutomaticCalories, skipDirty})
    !skipDirty && setDirty(true)
  };
  const handlePreferenceChange=(e,v)=>{
    const serving_type=mapDumbUnitToServingType[v];
    handlePreferredUnit(v,serving_type);
    setDirty(true);
  }
  const handleMacrosWrapper = (e, skipDirty = false) => {
    handleMacros(e, !noAutomaticCalories)
    !skipDirty && setDirty(true)
  };
  const handleCaloriesWrapper=(e, skipDirty = false)=>{
    handleCaloriesChange(e);
    !skipDirty && setDirty(true)
  }
  const openConfirmationDialog = () => {
    dirty ? showConfirmation('cancel') : onCancel()
  }
  const handleChangeServingOption = (e, index) => {
    const key = e.target.id || e.target.name;
    const value =
      key === "serving_size" ? Number(e.target.value) : e.target.value;
    const updatedServingOptions = update(serving_options, {
      [index]: {
        [key]: {
          $set: value,
        },
      },
    });
    handleChangeWrapper({
      target: {
        name: "serving_options",
        value: updatedServingOptions,
      },
    });
  };
  const deleteServingOption = (index) => {
    const updatedServingOptions = update(serving_options, {
      $splice: [[index, 1]],
    });
    handleChangeWrapper({
      target: {
        name: "serving_options",
        value: updatedServingOptions,
      },
    });
  };
  const handleAddMoreServing=(e)=>{
    handleChangeWrapper({
      target:{
        name:"serving_options",
        value:[...serving_options,{...DEFAULT_SERVING_OPTION}]
      }
    });
  }
  
  const _key = fdcId || 'custom';
  return (
    <Dialog
      toolbarClass="height-60"
      buttonColor="primary"
      open
      onClose={openConfirmationDialog}
      onSave={handleSubmit}
      title={`${isNew ? "Add" : "Edit"} Food Item`}
      titleFont="h3"
      paperClass="width-640"
      maxWidth={"md"}
      actionText={isNew && "Add to My Library"}
    >
      {isNew && doc.fdcId && (
        <Alert severity="info">
          This will create a copy of this Food Item in your own library.
        </Alert>
      )}
      <div className="fp-20">
        {!foodServingFormOnly && (
          <>
            <div className="d-flex mb-20">
              <div className="flex-1 d-flex flex-column justify-content-between">
                <FormTextField fullWidth label="Reference Name" required>
                  <TextField
                    id="ref_name"
                    autoComplete="off"
                    value={ref_name}
                    onChange={handleChangeWrapper}
                    error={!!errors.ref_name}
                    helperText={errors.ref_name}
                    variant="outlined"
                    disabled={loading}
                    required
                    type="text"
                    InputProps={{
                      classes: {
                        root: "medium",
                        input: "size_16_500 medium",
                      },
                    }}
                  />
                </FormTextField>
                <FormTextField
                  fullWidth
                  label={"Display Name"}
                  required
                  classes={{ control: "mb-0" }}
                >
                  <TextField
                    id="title"
                    autoComplete="off"
                    value={title}
                    onChange={handleChangeWrapper}
                    error={!!errors.title}
                    helperText={errors.title}
                    variant="outlined"
                    disabled={loading}
                    required
                    type="text"
                    InputProps={{
                      classes: {
                        root: "medium",
                        input: "size_16_500 medium",
                      },
                    }}
                  />
                </FormTextField>
              </div>
              <ImageFileUpload
                thumbnail={thumbnail}
                id="thumbnail"
                onChange={handleChangeWrapper}
                size="small"
                className="ml-20"
              />
            </div>
            <FormTextField fullWidth label="Tags">
              <AutoSuggest
                placeholder="Add Tags . . ."
                onChange={value => handleChangeWrapper({ target: { name:"systags", value: value || [] }})}
                options={nutritionTags}
                value={_tags}
              />
            </FormTextField>
            <FormTextField fullWidth label="Description">
              <TextField
                multiline
                rows="2"
                rowsMax="10"
                id="description"
                fullWidth
                // onBlur={handleChange}
                onChange={handleChangeWrapper}
                defaultValue={description}
                error={!!errors.description}
                helperText={errors.description}
                variant="outlined"
                disabled={loading}
                required
                InputProps={{
                  classes: {
                    root: "large",
                    input: "size_16_500",
                  },
                }}
              />
            </FormTextField>
            <Divider
              className="position-relative mb-25"
              style={{ left: -20, width: "calc(100% + 40px)" }}
            />
            <Typography className="font_18_600 mb-25">
              Nutrition & Serving info
            </Typography>
            <div className="d-flex align-items-center mb-20">
              <Typography className="font_16_600 fmr-15">
                Macros & Calories for
              </Typography>
              <InputDropdownWConversion
                value={doc.serving_size}
                name="serving_size"
                options={dumbUnits} 
                unit={doc.preferred_unit}
                error={!serving_size && !!errors.serving_size}
                onValueChange={handleChangeWrapper}
                handleOptionChange={handlePreferenceChange}
              />
              {/* <NumberInput
                fullWidth={false}
                key={`${_key}-ss`}
                id="serving_size"
                type="number"
                placeholder="N/A"
                onChange={handleChangeWrapper}
                onUnitChange={handleServingUnit}
                value={serving_size}
                error={!serving_size && !!errors.serving_size}
                variant="outlined"
                required
                unit={serving_type}
                InputProps={{
                  classes: { root: "xsmall pr-0", input: "size_15_500" },
                }}
                LabelProps={{
                  classes: {
                    control: "mb-0",
                  },
                }}
                style={{ width: "120px" }}
              /> */}
            </div>
            <Macros
              macros={macros}
              noAutomaticCalories={noAutomaticCalories}
              handleMacrosChange={handleMacrosWrapper}
              handleCaloriesChange={handleCaloriesWrapper}
              handleCheckboxChange={handleChangeWrapper}
              errors={errors}
              key={doc.fdcId}
            />
            <Divider className="mb-25 mt-16" />
          </>
        )}
        <ServingOptions
          serving_type={serving_type}
          _sortServing={_sortServing}
          dumbUnits={dumbUnits}
          handleChangeServingOption={handleChangeServingOption}
          deleteServingOption={deleteServingOption}
          handleAddMoreServing={handleAddMoreServing}
          serving_options={serving_options}
          errors={errors}
          preferred_unit={doc.preferred_unit}
          preferred_unit_name={preferred_unit_name}
        />
      </div>
      {isConfirmationOpen && (
        <CustomConfirmation
          handleClose={() => showConfirmation(null)}
          handleDiscard={onCancel}
          handleDraft={handleDraft}
          handlePublish={handlePublish}
          dirty={dirty}
          showDiscard={dirty}
        />
      )}
    </Dialog>
  );
};

export const Macros=({macros,noAutomaticCalories,handleMacrosChange,handleCaloriesChange,handleCheckboxChange,errors})=>{
    const { fat, protein, carbs,calories } = macros;
    return (
      <div>
        <div className="d-flex mb-16" style={{ gap: "20px" }}>
          <div>
            <NumberInput
              label="Protein"
              id="protein"
              onChange={handleMacrosChange}
              value={roundNumber(protein)}
              error={!!errors.protein}
              helperText={errors.protein}
              placeholder="0"
              variant="outlined"
              InputProps={{ classes: { root: "medium", input: "size_16_500"} }}
              unit="g"
              ignoreInitial
              LabelProps={{
                classes:{control:"mb-0"}
              }}
            />
          </div>
          <div>
            <NumberInput
              label="Fat"
              id="fat"
              onChange={handleMacrosChange}
              value={roundNumber(fat)}
              error={!!errors.fat}
              helperText={errors.fat}
              placeholder="0"
              variant="outlined"
              InputProps={{ classes: { root: "medium", input: "size_16_500" } }}
              unit="g"
              ignoreInitial
              LabelProps={{
                classes:{control:"mb-0"}
              }}
            />
          </div>
          <div>
            <NumberInput
              label="Carbs"
              id="carbs"
              onChange={handleMacrosChange}
              value={roundNumber(carbs)}
              error={!!errors.carbs}
              helperText={errors.carbs}
              placeholder="0"
              variant="outlined"
              InputProps={{ classes: { root: "medium", input: "size_16_500" } }}
              unit="g"
              ignoreInitial
              LabelProps={{
                classes:{control:"mb-0"}
              }}
            />
          </div>
          <div>
            <NumberInput
              label="Calories"
              id="calories"
              onChange={handleCaloriesChange}
              value={roundNumber(calories,0)}
              error={!!errors.calories}
              helperText={errors.calories}
              variant="outlined"
              InputProps={{ classes: { root: "medium", input: "size_16_500" } }}
              unit="cals"
              disabled={!noAutomaticCalories}
              required
              LabelProps={{
                classes:{control:"mb-0"}
              }}
            />
          </div>
        </div>
        <FormControlLabel
            label={<Typography className="font_15_500">Automatically calculate calories from macros</Typography>}
            className="mb-0"
            control={
            <Checkbox
                color="primary"
                name="noAutomaticCalories"
                onChange={(e)=>handleCheckboxChange(e)}
                checked={!noAutomaticCalories}
            />
            }
        />
      </div>
    );
}

const ServingOptions=({serving_options=[],dumbUnits=[],preferred_unit,preferred_unit_name,serving_type,_sortServing,errors={},...fns})=>{
  const { handleChangeServingOption,deleteServingOption,handleAddMoreServing,}=fns
  const disableAddMore=useMemo(()=>{
    if(!serving_options.length) return false;
    else if(serving_options.length===_sortServing.length) return true;
    return !_.get(serving_options,`${serving_options.length-1}.id`,"");
  },[serving_options])
  return (
    <>
      {!!serving_options.length && <div className="d-flex mb-20" style={{gap:20}}>
        <Typography className="font_16_600" style={{flex:1}}>Serving Name</Typography>
        <Typography className="font_16_600" style={{flex:1}}>1 serving equals</Typography>
        <div style={{width:40}}/>
      </div>}
      {serving_options.map((s, index) => (
        <ServingForm
          serving_type={serving_type}
          onChange={(e) => handleChangeServingOption(e, index)}
          deleteServingOption={() => deleteServingOption(index)}
          serving_options={serving_options}
          _sortServing={_sortServing}
          key={s.id||index}
          error={errors['serving_size']}
          preferred_unit={preferred_unit}
          preferred_unit_name={preferred_unit_name}
          {...s}
        />
      ))}
      {(serving_options.length < _sortServing.length) && <div className="my-5 d-flex justify-content-center">
        <ClrdButton
          variant="text"
          color="primary"
          onClick={handleAddMoreServing}
          disabled={disableAddMore}
          >
          <Typography className="font_15_600">
            {!!serving_options.length ? "Add More":"Add Custom Serving"}
          </Typography>
        </ClrdButton>
      </div>}
    </>
  );
}


export default FoodForm;



const ServingForm=({error,onChange,deleteServingOption,serving_options=[],_sortServing,preferred_unit,preferred_unit_name,...values})=>{
  const {id,serving_size}=values;
  const options=useMemo(()=>{
      return _.filter(_sortServing,(i)=>{
          const found=serving_options.find(j=>j.id===i.id);
          if(found && found.id===values.id) return true;
          return !found;
      });
  },[_sortServing,serving_options,values]);
  if(!options.length) return null;
  return(
      <div className='d-flex align-items-center mb-20' style={{gap:20}}>
          <div style={{flex:1}}>
              <FormTextField label="" fullWidth  classes={{control:"mb-0"}}>
                  <TextField
                      id={"id"}
                      value={id||"select"}//placeholder
                      required
                      select
                      variant="outlined"
                      onChange={onChange}
                      SelectProps={{
                          name:"id",
                          IconComponent: KeyDown,
                              inputProps: {
                                  classes: {
                                      select:"pr-20",
                                      icon: 'fpl-5 text-black',
                                  },
                              },
                      }}
                      InputProps={{
                      classes: {
                          root: 'medium',
                          input: 'size_16_500 medium select-medium',
                      },
                      }}
                  >
                      <MenuItem value={"select"} disabled>Select Unit</MenuItem>
                      {options.map(i=>(
                          <MenuItem  key={i.id} value={i.id}>{i.value}</MenuItem>
                      ))}
                  </TextField>
              </FormTextField>
          </div>
          <div style={{flex:1}}>
                <InputDropdownWConversion
                  value={serving_size}
                  name="serving_size"
                  unit={preferred_unit}
                  error={!serving_size && !!error}
                  unit_name={preferred_unit_name}
                  onValueChange={onChange}
                />
              {/* <NumberInput
                  id="serving_size"
                  placeholder="0"
                  error={!serving_size && !!error}
                  unit={serving_type}
                  onChange={onChange}
                  value={serving_size}
                  type="number"
                  variant="outlined"
                  InputProps={{classes:{root:"medium",input:"size_16_500"}}}
                  hideUnitChangeIcon={true}
                  LabelProps={{
                    classes:{control:"flex-1"}
                }}
              /> */}
          </div>
          <IconButton onClick={deleteServingOption}>
              <DeleteIcon style={{ fill: 'none' }} />
          </IconButton>
      </div>
  )
}
