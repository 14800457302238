import React, { useState } from 'react';
import { IconButton, makeStyles, Typography, FormGroup, MenuItem, Select, OutlinedInput } from '@material-ui/core';
import clsx from 'clsx';
import { TimePicker } from '@material-ui/pickers';
import { CopyIcon } from 'fitbud/icons/copy2';
import { ClipboardIcon } from 'fitbud/icons/clipboard';
import DoneIcon from '@material-ui/icons/CloseRounded';
import { hhMMToDate } from 'fitbud/views/reminders/helper';
import { TrashIcon } from 'fitbud/icons/trashIcon';
import DropDownIcon from '@material-ui/icons/ExpandMore';

/* here data structure be like : {id : value} */

const useStyles = makeStyles(() => ({
  timeContainer: {
    marginLeft: 20
  },
  timeInput: {
    minHeight: '48px',
    fontSize: '15px',
    fontWeight: 500,
  },
  copyContainer: {
    // backgroundColor: 'rgba(64, 137, 247, 0.1)',
  },
}));

const SpecificTimePicker = (props) => {
  const {
    data,
    onTimeChange,
    handleCopy,
    nameMapping,
    className,
    minutesStep,
    handleDelete,
    showDropdown = false,
    dropdownOptions,
    handleMealChange
  } = props;

  const [copy, setCopy] = useState(null);
  const classes = useStyles();
  const CN = !!Object.keys(data).length; 

  return (
    <div className={className}>
      {Object.keys(data).map((key, index) => {
        const d = data[key];
        const isLast = Object.keys(data).length - 1 === index;
        const time = (!!d && hhMMToDate(d)) || '';
        const isThisCopied = copy && copy.key === key;
        const disabled = !!copy;
        return (
          <div className="position-relative">
            <FormGroup key={key} className={clsx("mb-20", isThisCopied && classes.copyContainer)} row>
              {
                showDropdown ? (
                  <Select
                    IconComponent={DropDownIcon}
                    style={{width: 200}}
                    disabled={disabled}
                    input={
                      <OutlinedInput
                        classes={{
                          root: 'medium',
                          input: clsx('size_16_500 select-medium'),
                        }}
                        name="id"
                      />
                    }
                    value={key}
                    onChange={(e) => handleMealChange(key, e.target.value, time)}
                  >
                    <MenuItem key={key} value={key}>
                      {nameMapping[key]}
                    </MenuItem>
                    {Object.entries(dropdownOptions || {}).map(([key, value]) => (
                      <MenuItem key={key} value={key}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select> 
                ) : (
                    <div className='d-flex flex-grow-1 align-items-center'>
                      <Typography variant='body1'>{nameMapping[key]}</Typography>
                    </div>
                  )
              }
              <div className={clsx(classes.timeContainer)}>
                {!d && (
                  <div
                    className={clsx(classes.timeInput, 'text-grey d-flex justify-content-center align-items-center')}
                  >
                    <Typography>No Reminder</Typography>
                  </div>
                )}
                {!!d && (
                  <TimePicker
                    format="hh:mm a"
                    value={time}
                    inputVariant="outlined"
                    name={key}
                    disabled={disabled}
                    style={{width: showDropdown ? 200 : 120}}
                    inputProps={{
                      className: clsx(classes.timeInput),
                      name: key,
                    }}
                    onChange={(e) => onTimeChange(key, e)}
                    minutesStep={minutesStep}
                  />
                )}
              </div>
              {/* IF this item is copied */}
              <div className="d-flex align-items-center">
                {!!isThisCopied && (
                  <IconButton component="div"  style={{marginRight:"-8px"}} className="ml-12" onClick={() => setCopy(null)}>
                    <DoneIcon fontSize="small" />
                  </IconButton>
                )}
                {/* If Item is copied, but not this */}
                {!isThisCopied && !!copy && (
                  <IconButton component="div" className="ml-12" style={{marginRight:"-8px"}}  onClick={() => handleCopy(key, copy.value)}>
                    <ClipboardIcon fontSize="small" />
                  </IconButton>
                )}
                {/* If no copy selected */}
                {!copy && !isThisCopied && (
                  <IconButton
                    component="div"
                    disabled={!d}
                    className="ml-12"
                    style={{marginRight:"-8px"}}
                    onClick={() => {
                      setCopy({ key, value: d });
                    }}
                  >
                    <CopyIcon fontSize="small" />
                  </IconButton>
                )}
                {handleDelete && (
                  <IconButton component="div" disabled={copy} style={{marginRight:"-8px"}} className="ml-12" onClick={() => handleDelete(key)}>
                    <TrashIcon fontSize="small" />
                  </IconButton>
                )}
              </div>
            </FormGroup>
          </div>
        );
      })}
    </div>
  );
};

export default SpecificTimePicker;
