import React from 'react';
import {Hidden,Typography,makeStyles} from '@material-ui/core';
import clsx from "clsx";
import loginBg from "fitbud/images/loginBg.jpg";

const styles=makeStyles({
banner: {
    backgroundImage: `url(${loginBg})`,
    backgroundRepeat:"no-repeat",
    backgroundSize:"cover",
    "& .motto": {
        padding: "0px 50px 100px 40px",
        "& .msg": {
        fontSize: "52px",
        whiteSpace:"break-spaces"
        },
        "& .logo": {
        zIndex: 99,
        marginBottom: "40px",
        },
    },
    }
})
export const Banner = () => {
    const classes = styles();
    return (
      <Hidden smDown>
      <div className={clsx(classes.banner, "d-flex align-items-end")}>
        <div className="motto">
          <Typography className="msg text-white font-weight-700">
            Get Healthier, Fitter, Smarter. Faster.
          </Typography>
        </div>
      </div>
      </Hidden>
    );
  };