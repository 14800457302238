import EventEmitter from 'events';
import * as Sentry from '@sentry/browser';
import { useCallback, useState } from "react";

import Video from "twilio-video";

export default function useRoom(localTracks) {
  const [room, setRoom] = useState(new EventEmitter());
  const [isConnecting, setIsConnecting] = useState(false);
  const [loading, setLoading] = useState(false)

  const connect = useCallback(
    data => {
      setIsConnecting(true);
      setLoading(true)
      return Video.connect(data.token, {tracks: localTracks}).then(
        newRoom => {
          setRoom(newRoom);
          newRoom.once('disconnected', () => {
            // Reset the room only after all other `disconnected` listeners have been called.
            setTimeout(() => setRoom(room));
          });
          setLoading(false)
        },
        error => {
          Sentry.captureException(error);
          setIsConnecting(false);
        }
      );
    },
    [localTracks]
  );
  
  return { room, isConnecting, connect, loading };
}