import React, { useMemo } from 'react';
import {Menu,MenuItem,Fab,makeStyles,Divider} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

const styles=makeStyles(theme=>({
    fabContainer:{
        position: "absolute",
        bottom: 10,
        right: 10
    },
}))


export const MenuList=({anchorEl,onClose,options=[],handleClick,
    displayEmpty=false,displayEmptyValue="",displayEmptyLabel="None",
    showDividerAtValue=[],
    ...rest})=>{
    const _options=useMemo(()=>{
        //changing string array to onject array
        if(!!options.length && typeof(options[0])==="string"){
            return options.map(i=>({
                label:i,value:i
            }));
        }
        return [...(options||[])];
    },[options]);
    const restProps={
        classes:{
                list:options.length===1?'p-0':"",
            },
        ...rest
    }
    const _handleClick=(e,value)=>{
        if(handleClick){
            handleClick(e,value);
        }
    }
    return(
        <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={onClose}
            displayEmpty={displayEmpty}
            className='d-print-none'
            {...restProps}
            >
            {displayEmpty && <MenuItem value={displayEmptyValue} onClick={(e)=>_handleClick(e,displayEmptyValue)}>{displayEmptyLabel}</MenuItem>}
            {_options.map(({label,value,...rest})=>(
                <>
                    {showDividerAtValue.includes(value) && <Divider/>}
                    <MenuItem disableRipple onClick={(e)=>_handleClick(e,value)} {...rest}>{label}</MenuItem>
                </>
            ))}
        </Menu>
    )
}

export const FabList=({onClick,...rest})=>{
    const classes=styles();
    return(
        <div className={classes.fabContainer}>
            <Fab color="primary" className="text-white" onClick={onClick}>
                <AddIcon />
            </Fab>
            <MenuList {...rest}/>
        </div>
    )
}