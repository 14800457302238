import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import UseIsTrackEnabled from './useIsTrackEnabled';
import Mic from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';

const useStyles = makeStyles(() => ({
  remoteContainer: {
    width: "100%",
  },
  textAlign: {
    textAlign: "-webkit-center",
    color: "#fff",
  }
}));

const AvatarContainer = (props) => {
  const classes = useStyles();
  const isTrackEnabled = UseIsTrackEnabled(props.audioTrack);
  const { identity, isLocal } = props;

  return (
    <div className={`${!!isLocal ? "" : classes.remoteContainer} ${classes.textAlign}`}>
      {isTrackEnabled ? <Mic fontSize="large" /> : <MicOffIcon fontSize="large" />}
      <Typography className="fmt-10" variant="h4">{identity}</Typography>
    </div>
  )
}

export default AvatarContainer;