import React, { useEffect, useState } from "react";
import firebase from "fitbud/firebase";
import { withSnackbar } from "notistack";
import { hasLength } from "fitbud/utils/helpers";
import * as Sentry from '@sentry/browser';
import {DEFAULT_ERROR, HUBSPOT_PROPS } from "fitbud/utils/constants";
import QResponses from "./QResponses";
import { bffUpdateHubspotProp } from "fitbud/api";

export function getCheckins({
  docId,
  saveMetrics,
  enqueueSnackbar,
  measurableTags,
  aplan
}) {
  firebase
    .firestore()
    .collection(`user_profiles/${docId}/checkins`)
    .orderBy("date", "desc")
    .limit(1)
    .get()
    .then(doc => {
      if (!!doc) {
        if (doc.docs.length) {
          saveMetrics(doc.docs[0].data());
        }
      } else
        enqueueSnackbar("Unable to fetch. Please try later.", {
          variant: "error"
        });
    })
    .catch(err => {
      enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
        Sentry.captureException(err);
        console.log(err)
    });
}

const Profile = (props) => {
  const {
    measurableTags,
    docId,
    enqueueSnackbar,
    aplan = null,
  } = props;

  const [metrics, saveMetrics] = useState({});
  useEffect(() => {
    if (!!docId) {
      if (hasLength(measurableTags))
        getCheckins({
          docId,
          saveMetrics,
          enqueueSnackbar,
          measurableTags,
          aplan
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docId, measurableTags]);

  useEffect(() => {
    if(hasLength(metrics)){
      bffUpdateHubspotProp(HUBSPOT_PROPS.CHECKIN_VIEWED);
    };
  },[metrics]);

  return (
    <div className="h-100">
      <div className="bg-white h-100 fp-20 flex-1">
        <QResponses {...props} />
      </div>
    </div>
  );
};
export default withSnackbar(Profile);
