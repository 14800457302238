import { useEffect, useState } from 'react';
import useVideoContext from './useVideoContext.js';

const useRoomState = props => {
  const { room } = useVideoContext();
  const [state, setState] = useState('disconnected');
  useEffect(() => {
    const setRoomState = () => setState((room.state || 'disconnected'));
    setRoomState();
    room
      .on('disconnected', setRoomState)
      .on('reconnected', setRoomState)
      .on('reconnecting', setRoomState);
    return () => {
      room
        .off('disconnected', setRoomState)
        .off('reconnected', setRoomState)
        .off('reconnecting', setRoomState);
    };
  }, [room]);

  return state;
}

export default useRoomState;