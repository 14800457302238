import { Button, DialogActions } from '@material-ui/core';
import clsx from 'clsx';
import Confirmation from 'fitbud/components/confirmationDialog';
import { capitalize } from 'lodash';
import React from 'react';

export default function LibraryConfirmation({ open = false, onClose, action1, action2, docType = 'workout' }) {
  return (
    <Confirmation
      open={open}
      title={`Save ${capitalize(docType)} Changes`}
      msg={`You are editing a client's ${docType}. Save changes for this client only, or add to your library for future use.`}
      paperClass="width-450"
      handleClose={onClose}
      customAction={
        <DialogActions style={{ justifyContent: 'space-between' }} className="fmt-20 p-0">
          <Button
            variant="outlined"
            onClick={action1}
            className={clsx('f-sm-med', 'border-primary')}
            color="primary"
            disableElevation
            classes={{ label: 'font_15_700' }}
          >
            Save for this client
          </Button>
          <Button
            variant="contained"
            onClick={action2}
            className={clsx('f-sm-med', 'ml-20 ')}
            color="primary"
            autoFocus
            disableElevation
            classes={{ label: 'font_15_700' }}
          >
            Save & add to library
          </Button>
        </DialogActions>
      }
    />
  );
}
