import React, { useState, useEffect, useContext, useMemo } from "react";
import vmsg from "vmsg";
import uuid from "uuid/v4";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import _ from "lodash";
import { useSnackbar } from 'notistack';
import {DEFAULT_ERROR} from "fitbud/utils/constants";

const initialState = {
  recordingMinutes: 0,
  recordingSeconds: 0,
  initRecording: false,
  mediaRecorder: null,
  audio: {},
};

export default function useRecorder() {
  const { comp } = useContext(FirebaseAuthContext);
  const {enqueueSnackbar} = useSnackbar()
  const [recorderState, setRecorderState] = useState(initialState);
  const maxDuration = useMemo(()=>comp && (_.get(comp.data(), "chat_config.company_max_audio_duration", 90)), [comp]);
  
  const startRecording = async() => {
    try {
      //Stop all audio tracks
      var sounds = document.getElementsByTagName('audio');
      for(let i=0; i<sounds.length; i++) sounds[i].pause();
      const recorder = new vmsg.Recorder({
        wasmURL: "https://unpkg.com/vmsg@0.3.0/vmsg.wasm"
      });
      await recorder.initAudio();
      await recorder.initWorker();
      recorder.startRecording();
      setRecorderState((prevState) => {
        return {
          ...prevState,
          initRecording: true,
          mediaRecorder: recorder,
        };
      });
      
    } catch (err) {
      enqueueSnackbar(err.message === "Permission denied" ? 
        "Please give permission to access your microphone. Check your browser settings and try again." : 
          DEFAULT_ERROR, { variant: "warning" });
      console.log(err);
    }
  }

  const saveRecording = async() => {
    if (!recorderState.initRecording) return setRecorderState(initialState);
    const blob = await recorderState.mediaRecorder.stopRecording();
    const audioFile = new File([blob], `${uuid()}.mp3`);
    setRecorderState((prevState) => {
      if (prevState.mediaRecorder)
        return {
          ...initialState,
          audio: { file: audioFile, isAudio: true,  duration: prevState.recordingSeconds}
        };
      else return initialState;
    });
  }

  const deleteAudio = async() => {
    if (!recorderState.initRecording) return setRecorderState(initialState);
    await recorderState.mediaRecorder.stopRecording();
    setRecorderState(initialState);
  }

  useEffect(()=> {
    if(!recorderState.initRecording) return;
    if(recorderState.recordingSeconds >= maxDuration && recorderState.mediaRecorder){
      // Stop recording if time limit exceeds
      recorderState.mediaRecorder.stopRecording().then((blob)=> {
        const audioFile = new File([blob], `${uuid()}.mp3`);
        setRecorderState((prevState) => {
          return {
            ...initialState,
            audio: { file: audioFile, isAudio: true,  duration: maxDuration}
          };
        });
      })
    }
  }, [recorderState, maxDuration, setRecorderState]);

  useEffect(() => {
    let recordingInterval = null;
    if (!recorderState.initRecording) return;
    recordingInterval = setInterval(() => {
      setRecorderState((prevState) => {
        if (prevState.recordingSeconds >= 0){
          // duration + 1
          return {
            ...prevState,
            recordingSeconds: prevState.recordingSeconds + 1,
          };
        }
      });
    }, 1000);
    return () => clearInterval(recordingInterval);
  }, [recorderState.initRecording, maxDuration]);

  return {
    recorderState,
    startRecording: startRecording,
    saveRecording: saveRecording,
    deleteAudio,
  };
}