import { Checkbox, Typography, FormControlLabel } from '@material-ui/core';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import CreateIcon from '@material-ui/icons/Create';
import { RoleContext } from 'fitbud/providers/roleProvider';
import React, { useContext } from 'react';
import { isInactiveUser, isSelfSignupUser } from 'fitbud/views/users/helpers';

// const useStyles = makeStyles((theme) => ({
//   warningBox:{
//     backgroundColor:'#FFFBF3',
//     border: '1px solid #FFDEA9',
//     borderRadius: '3px',
//     padding:'15px'
//   },
// }));

// const showWarningText = (bookingsCount, videoPlan) => {
//   if (!videoPlan || !videoPlan.includes_calls) return "This client is not on a plan that includes 1 on 1 appointments";
//   if (bookingsCount <= 0) return `This client has used all appointments in this ${videoPlan.frequency || ''}`;
//   return `${bookingsCount || ' - '} ${bookingsCount === 1 ? 'appointment' : 'appointments'} remaining in this ${videoPlan.frequency || ''}`;
// }

const UserNameBox = ({ user, onClick, showTrainerName, disableEdit }) => {
  let { name, email, trainer_name } = user;
  if (!name || !email) {
    // Get name and email from profile if user structure is different than elastic one
    let { name: _name, email: _email } = user.profile;
    name = _name;
    email = _email;
  }
  return (
    <div
      className={`w-100 d-flex justify-content-between 
        align-items-start fp-20 border rounded shadow-sm ${!disableEdit ? 'cursor-pointer' : ''}`}
      onClick={onClick}>
      <div className="d-flex flex-column" style={{ width: '90%' }}>
        <Typography variant="h3" noWrap>
          {name || ''}
        </Typography>
        <Typography variant="body1" color="textSecondary" noWrap className="fmt-5">
          {email || ''}
        </Typography>
        {!!showTrainerName && !!trainer_name ? (
          <div className="d-flex fmt-10">
            <AccountBoxIcon fontSize="small" />
            <Typography variant="body1" className="fml-10">
              {trainer_name}
            </Typography>
          </div>
        ) : null}
      </div>
      {!disableEdit && (
        <div>
          <CreateIcon fontSize="small" />
        </div>
      )}
    </div>
  );
};
const UserData = ({ user, rescheduleMode, onNameBoxClick, toggleQuota, isTrainerBooking, disableEdit }) => {
  const isLead = isSelfSignupUser(user);
  const inInactive = isInactiveUser(user);
  const showQuotaCount = !isLead && !inInactive;
  const { isOwner, tEnabled } = useContext(RoleContext);
  return (
    <div>
      <div className={`${rescheduleMode ? 'fmb-20' : ''}`}>
        <UserNameBox
          user={user}
          showTrainerName={!rescheduleMode && !!tEnabled && isOwner}
          onClick={disableEdit ? null : onNameBoxClick}
          disableEdit={disableEdit}
        />
      </div>
      {showQuotaCount ? (
        <div className="d-flex align-items-center mt-15">
          <FormControlLabel
            classes={{ root: 'mb-0' }}
            checked={!isTrainerBooking}
            onChange={toggleQuota}
            control={<Checkbox color="primary" />}
            label="Count this booking in client's quota"
          />
        </div>
      ) : null}
    </div>
  );
};
export default UserData;
