import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { Typography } from '@material-ui/core';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import clsx from 'clsx';
import { bffLogout } from 'fitbud/api';
import appRdxFns from 'fitbud/redux/app';
import { useDispatch } from 'react-redux';

export const Overlay = (props) => {
  return (
    <Dialog
      style={{
        width: '100vw',
        height: '100vh',
        background: '#aaaaaaaa',
        backdropFilter: 'saturate(180%) blur(10px)',
      }}
      open
      disableEscapeKeyDown
    />
  );
};

//z index of dialog root is 1300
const useLogoutStyles = makeStyles(() => ({
  container: {
    position: 'fixed',
    left: 30,
    bottom: 30,
    zIndex: 1500,
  },
}));

export const OverlayLogout = (props) => {
  const classes = useLogoutStyles();
  const { styles } = props;
  const d = useDispatch();
  const { showLoader } = appRdxFns(d);
  const handleLogout = () => {
    showLoader();
    bffLogout();
  };
  return (
    <div className={clsx(classes.container)} styles={styles}>
      <div className="d-flex align-items-center cursor-pointer" onClick={handleLogout}>
        <PowerSettingsNewIcon className="text-white" />
        <Typography className="ml-10 text-white font_15_600">Logout</Typography>
      </div>
    </div>
  );
};

const OverlayWithLogout = () => {
  return (
    <div>
      <Overlay />
      <OverlayLogout />
    </div>
  );
};

export default OverlayWithLogout;
