export const DEFAULT_SERVICE_STATE = {
  title: '',
  ref_name: '',
  mode: 'offline',
  duration: 30,
  description: '',
  thumbnail: '',
  facility: false,
  status: 'inactive',
  archive:false,
  locations: [],
  trainers: [],
  drop_in_rate: {
    enabled: false,
    currency: 'USD',
    price: 20,
  },
  index:0,
};

export const SERVICE_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const SERVICE_INDEX_FACTOR = 100;


export const TRAINER_TYPE = { SPECIFIC: 'specific', ALL: 'all' };

export const TRAINER_OPTIONS = [
  { label: 'All', value: TRAINER_TYPE.ALL },
  { label: 'Selected Trainers', value: TRAINER_TYPE.SPECIFIC },
];
