import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import noSearchFoundImg from 'fitbud/images/noSearchFound.png';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: {},
  label:{
    fontSize:15,
    fontWeight:600,
    marginTop:20
  }
}));

const NoResultFound = (props) => {
  const {
    label = 'No Results Found',
    subLabel,
    className,
    labelClassName,
    subLabelClassName,
    noResultFoundAction,
    iconClassName,
  } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, 'd-flex flex-column justify-content-center align-items-center', className)}>
      <img alt='' src={noSearchFoundImg} className={clsx(iconClassName)} width='60' height='auto'/>
      <Typography className={clsx(labelClassName, classes.label)}>{label}</Typography>
      {subLabel && <Typography className={clsx(subLabelClassName)}>{subLabel}</Typography>}
      {noResultFoundAction && noResultFoundAction()}
    </div>
  );
};

export default NoResultFound;
