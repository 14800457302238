import React, { useContext } from 'react'
import _ from "lodash";
import { SearchCheckboxFilter } from "fitbud/components/filterComps";
import { useDispatch, useSelector } from 'react-redux';
import planRdxFns from "fitbud/redux/plans";
import firebase from 'firebase';
import { parsePacks } from '../plan/helper';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';

function PlanFilter(props) {
    const { cid } = props;
    const {comp} = useContext(FirebaseAuthContext);
    const company = comp ? comp.data() : {};
    const isGroupClass = Boolean(_.get(company, "features.group_class.enabled", false));
    const defaultFields = [{ label: "Custom", value: 'custom' }]
    const d = useDispatch();
    const planOps = planRdxFns(d);
    const dataParser = ({ data = {} }) => _.map(data, d => ({value: d._id, label: d.data.ref_name}));
    const planRedux = useSelector(s => s.plans);
    const fetch = (q) => new Promise(async(res, rej) => {
            if(!!planRedux.docs) res({ data: planRedux.docs });
            try{
                const data = await firebase.firestore().collection(`companies/${cid}/packs`).get();
                planOps.set(parsePacks(data, isGroupClass), 0, true);
                res({ data: parsePacks(data, isGroupClass) });
            }catch(err){
                rej(err);
            }
        });
    return (
        <SearchCheckboxFilter {...props} dataParser={dataParser} fetchData={fetch} defaultFields={defaultFields} isMulti/>
    )
}

export default PlanFilter
