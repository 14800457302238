import _ from 'lodash';
import { useMemo } from 'react';
import {
  DEFAULT_SERVING_ID,
  DUMB_UNITS
} from "fitbud/utils/helpers";
/*
useServings used in food recipes form, supplement form and ingredients.
By default or in case of null/undefined selected every where used DEFAULT_SERVING_ID, 
in case of ingredients we don't need this so passed setDefault argument to support default or not
*/

const useServings = ({servingData, selected, setDefault = true,includeDumbUnits=true,separateFlOz=false}) => useMemo(() => {
  let servingsOut = [];
  let selectedIdOut = setDefault ? DEFAULT_SERVING_ID : selected;
  let selectedNameOut = 'Serving';
  const dumbUnits=[];
  !!servingData && servingData.forEach(([id, obj]) => {
    const buggy = DUMB_UNITS.includes(id);
    const value = _.capitalize(obj.value);
    if (!buggy) servingsOut.push({id, value}); // don't include gm,ml,ounce, they'll be added at the end
    else {
      if(separateFlOz && id==="volume_imperial_us"){
        dumbUnits.push({id,value:obj.value+" (US)"});//avoid capitalize of dumb units
      }
      else{
        dumbUnits.push({id,value:obj.value});//avoid capitalize of dumb units
      }
    }
    if (id === selected) {
      selectedIdOut = id;
      // if (!buggy)
        selectedNameOut = value;
    }
  });
  servingsOut = _.orderBy(servingsOut, 'value', 'asc');
  if(!!includeDumbUnits){
    servingsOut.push(...dumbUnits);
  }
  return [
    servingsOut,
    selectedIdOut,
    selectedNameOut,
    dumbUnits
  ];
}, [servingData, selected, setDefault, includeDumbUnits, separateFlOz]);

export default useServings;
