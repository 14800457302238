import * as React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const LinkIcon = props => (
 <SvgIcon
  width="16" height="16" viewBox="0 0 16 16"
  classes={{
   root: "small3"
  }}
  fill="none"
  {...props}
 >
    <g clip-path="url(#clip0_27743_138273)">
    <path d="M12 4H9.6C9.15817 4 8.8 4.35817 8.8 4.8C8.8 5.24183 9.15817 5.6 9.6 5.6H12C13.32 5.6 14.4 6.68 14.4 8C14.4 9.32 13.32 10.4 12 10.4H9.6C9.15817 10.4 8.8 10.7582 8.8 11.2C8.8 11.6418 9.15817 12 9.6 12H12C14.208 12 16 10.208 16 8C16 5.792 14.208 4 12 4ZM7.2 11.2C7.2 10.7582 6.84183 10.4 6.4 10.4H4C2.68 10.4 1.6 9.32 1.6 8C1.6 6.68 2.68 5.6 4 5.6H6.4C6.84183 5.6 7.2 5.24183 7.2 4.8C7.2 4.35817 6.84183 4 6.4 4H4C1.792 4 0 5.792 0 8C0 10.208 1.792 12 4 12H6.4C6.84183 12 7.2 11.6418 7.2 11.2ZM4.8 8C4.8 7.55817 5.15817 7.2 5.6 7.2H10.4C10.8418 7.2 11.2 7.55817 11.2 8C11.2 8.44183 10.8418 8.8 10.4 8.8H5.6C5.15817 8.8 4.8 8.44183 4.8 8Z" fill="#A2AEBF"/>
    </g>
    <defs>
    <clipPath id="clip0_27743_138273">
    <rect width="16" height="16" fill="white"/>
    </clipPath>
    </defs>
 </SvgIcon>
);