import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const ClientAppIcon = (props) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 20"
    classes={{
      root: 'small',
    }}
  >
    <path
      d="M10.25 18H6.66667C6.22464 18 5.80072 17.8244 5.48816 17.5118C5.17559 17.1993 5 16.7754 5 16.3333V3.66667C5 3.22464 5.17559 2.80072 5.48816 2.48816C5.80072 2.17559 6.22464 2 6.66667 2H13.3333C13.7754 2 14.1993 2.17559 14.5118 2.48816C14.8244 2.80072 15 3.22464 15 3.66667V9.5"
      stroke="#8F969F"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M10 14.166V14.1743" stroke="#8F969F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M12.7997 15.5486C12.6591 15.6893 12.5801 15.8801 12.5801 16.079V17.3301C12.5801 17.7443 12.9159 18.0801 13.3301 18.0801H14.5812C14.7801 18.0801 14.9709 18.0011 15.1115 17.8604L18.771 14.2009L18.771 14.201L18.7763 14.1956C18.971 13.9969 19.0801 13.7298 19.0801 13.4516C19.0801 13.1735 18.971 12.9064 18.7763 12.7077L18.7763 12.7077L18.771 12.7024L17.9578 11.8892L17.9578 11.8891L17.9525 11.8839C17.7538 11.6892 17.4867 11.5801 17.2085 11.5801C16.9303 11.5801 16.6632 11.6892 16.4646 11.8839L16.4645 11.8839L16.4592 11.8892L12.7997 15.5486Z"
      stroke="#8F969F"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </SvgIcon>
);
