import React, {  useState } from 'react';
import _ from 'lodash';
import {  Badge, List, ListItem, ListItemText } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ChipGroups from 'fitbud/components/catalog-filter/chipGroups';

const useClasses = makeStyles(() => ({
  checkBoxLabel: {
    fontSize: 15,
    fontWeight: 400,
    color: '37404D',
  },
  checkboxLabelClass: {
    width: '45%',
    marginRight: 0,
    paddingRight: 0,
  },
}));

const FilterInner = (props) => {
  const { onSelectItem, filters, filterOptions, ignoreFilter } = props;
  const _filters = filterOptions;
  const classes = useClasses();
  const [selectedType, setSelectedType] = useState(_filters[0].type);
  const _selectedFilters = _.find(_filters, (op) => op.type === selectedType) || {};
  const _isComponent = _.get(_selectedFilters, 'isComponent', false);
  const FComponent = _.get(_selectedFilters, 'Component');
  const _selectedOptions = _.get(_selectedFilters, 'options', []);

  const checkFilterExistsForType = (filterOptions, filters) => {
    const _optionsType = filterOptions.map((op) => op.subType) || [];
    for (const key of _optionsType) {
      let values = _.get(filters, key, []);
      if (values.length) {
        return true;
        break;
      }
    }
    return false;
  };
  
  const checkFilterExistsForComponent = (selectedFilters, filters) => {
    const subType = _.get(selectedFilters, 'subType', '');
    const _selectedFilters = _.get(filters, subType, []);
    return !!_.get(_selectedFilters, 'length', 0);
  };


  return (
    <div className="d-flex p-0 h-100">
      <div className="col-3 p-0 border-right d-flex flex-column justify-content-between bg-body">
        <List component="nav" className="p-0 overflow-auto" onScroll={(e) => e.stopPropagation()}>
          {_filters.map((option, index) => {
            const selected = option.type === selectedType;
            const _isFilerExists = option.isComponent ? checkFilterExistsForComponent(option, filters): checkFilterExistsForType(option.options, filters);

            return (
              <ListItem
                button
                key={option.value + index}
                onClick={() => {
                  setSelectedType(option.type);
                }}
                className={selected ? 'bg-white' : 'bg-body'}
                style={{
                  height: '60px',
                }}
              >
                <ListItemText
                  primary={option.label}
                  className={selected ? 'text-primary' : 'text-muted'}
                  primaryTypographyProps={{ variant: "h6" }}
                />
                {_isFilerExists && <Badge color="primary" variant="dot" />}
              </ListItem>
            );
          })}
        </List>
      </div>
      <div className="col-9 overflow-auto fp-20 fpy-25 " onScroll={(e) => e.stopPropagation()}>
        {!!_isComponent && !!FComponent && (
          <FComponent
            key={_.get(_selectedFilters, 'type')}
            label={_.get(_selectedFilters, 'label')}
            subType={_.get(_selectedFilters, 'subType')}
            tag={_.get(_selectedFilters, 'tag')}
            append={_.get(_selectedFilters, 'append')}
            searchPlaceholder={_.get(_selectedFilters, 'searchPlaceholder')}
            onSelectItem={onSelectItem}
            filters={filters}
          />
        )}
        {!_isComponent &&
          _selectedOptions.map((selectedOption, index) => {
            const label = selectedOption.label;
            const subType = selectedOption.subType;
            const filterType=selectedOption.filterType;
            const subOptions = selectedOption.subOptions;
            const _isComponent = _.get(selectedOption, 'isComponent', false);
            const FComponent = _.get(selectedOption, 'Component');
            const _handleChange = (e) => {
              const value = e.target.name;
              onSelectItem({ subType, value,filterType });
            };
            if(!!ignoreFilter && !!_.includes(ignoreFilter, subType)) {
              return null
            };
            if(!!_isComponent){
              return <FComponent
              key={_.get(selectedOption, 'type')}
              label={_.get(selectedOption, 'label')}
              subType={_.get(selectedOption, 'subType')}
              tag={_.get(selectedOption, 'tag')}
              append={_.get(selectedOption, 'append')}
              onSelectItem={onSelectItem}
              filters={filters}
              isMinified = {true}
            />
            }
            return (
              <div className="fmb-10">
                <ChipGroups
                  label={label}
                  handleChange={_handleChange}
                  componentState={_.get(filters, subType, [])}
                  fields={subOptions}
                  isMulti={true}
                  isColumn={false}
                  groupLabelClassName="font_16_600 text-black fmb-10"
                  labelClass={classes.checkBoxLabel}
                  checkboxLabelClass={classes.checkboxLabelClass}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default FilterInner;
