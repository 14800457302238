import React from 'react';

export const LockIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.99837 1.30225C6.15771 1.30225 4.66504 2.79491 4.66504 4.63558V5.96891C3.56037 5.96891 2.66504 6.86425 2.66504 7.96891V12.6356C2.66504 13.7402 3.56037 14.6356 4.66504 14.6356H11.3317C12.4364 14.6356 13.3317 13.7402 13.3317 12.6356V7.96891C13.3317 6.86425 12.4364 5.96891 11.3317 5.96891V4.63558C11.3317 2.79491 9.83904 1.30225 7.99837 1.30225ZM7.99837 2.63558C9.10304 2.63558 9.99837 3.53091 9.99837 4.63558V5.96891H5.99837V4.63558C5.99837 3.53091 6.89371 2.63558 7.99837 2.63558ZM7.99837 7.96891C8.73504 7.96891 9.33171 8.56558 9.33171 9.30225C9.33171 9.77558 9.03637 10.1076 8.66504 10.3436V11.9689C8.66504 12.3369 8.36637 12.6356 7.99837 12.6356C7.63037 12.6356 7.33171 12.3369 7.33171 11.9689V10.3436C6.96037 10.1069 6.66504 9.77558 6.66504 9.30225C6.66504 8.56558 7.26171 7.96891 7.99837 7.96891Z"
        fill="#37404D"
      />
    </svg>
  );
};

export const UnlockIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.6761 1.30225C10.0194 1.30225 8.67611 2.64558 8.67611 4.30224V5.9689H3.34277C2.23811 5.9689 1.34277 6.86423 1.34277 7.96889V12.6355C1.34277 13.7402 2.23811 14.6355 3.34277 14.6355H10.0094C11.1141 14.6355 12.0094 13.7402 12.0094 12.6355V7.96889C12.0094 6.86423 11.1141 5.9689 10.0094 5.9689V4.30224C10.0094 3.38157 10.7554 2.63558 11.6761 2.63558C12.5968 2.63558 13.3428 3.38157 13.3428 4.30224V5.30223C13.3428 5.67023 13.6414 5.9689 14.0094 5.9689C14.3774 5.9689 14.6761 5.67023 14.6761 5.30223V4.30224C14.6761 2.64558 13.3328 1.30225 11.6761 1.30225ZM6.67611 7.96889C7.41277 7.96889 8.00944 8.56556 8.00944 9.30222C8.00944 9.77555 7.71411 10.1075 7.34277 10.3442V11.9689C7.34277 12.3369 7.04411 12.6355 6.67611 12.6355C6.30811 12.6355 6.00944 12.3369 6.00944 11.9689V10.3442C5.63811 10.1069 5.34277 9.77555 5.34277 9.30222C5.34277 8.56556 5.93944 7.96889 6.67611 7.96889Z"
        fill="#37404D"
      />
    </svg>
  );
};
