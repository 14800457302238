import React, { useMemo, useState } from 'react';
import { AppBar, IconButton, Typography, Toolbar, Divider, TextField, InputAdornment } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseRounded';
import SearchIcon from '@material-ui/icons/Search';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import _ from 'lodash';
import useDebounce from 'fitbud/hooks/useDebounce';
import ClassUserList from './classUserList';
import { useStyles } from './header';

const ClientSearch = (props) => {
  const { users, onClose, ...rest } = props;
  const classes = useStyles();
  const [q, setQ] = useState('');
  const debouncedQuery = useDebounce(q, 500);
  const onQueryChange = (e) => setQ(e.target.value);

  const searchedUsers = useMemo(() => {
    const q = (debouncedQuery || '').trim().toLocaleLowerCase();
    if (!q) return users;
    return _.filter(users, (user) => _.get(user, 'data.name', '').toLocaleLowerCase().includes(q));
  }, [debouncedQuery, users]);

  return (
    <>
      <AppBar position="static" className="bg-grey-new">
        <Toolbar classes={{ root: classes.toolBar }}>
          <div className="w-100">
            <div className="d-flex align-items-center mb-10">
              <IconButton className="ml-n8" onClick={onClose}>
                <CloseIcon />
              </IconButton>
              <Typography className={'ml-8 flex-grow-1 font_18_600 text-0d0d0d'}>{`Search (${
                !!debouncedQuery ? searchedUsers.length : users.length
              })`}</Typography>
            </div>
            <div className="flex-grow-1">
              <TextField
                fullWidth
                value={q}
                onChange={onQueryChange}
                variant="outlined"
                placeholder="Search by name ..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  classes: {
                    root: clsx('height-40', 'rounded-pill bg-white'),
                    input: 'size_16_500 height-40',
                  },
                }}
              />
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Divider />
      <ClassUserList className="py-12" users={searchedUsers} {...rest} isFilter={!!debouncedQuery} />
    </>
  );
};

export default ClientSearch;
