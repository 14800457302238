import React from "react";
import { connect } from "react-redux";

import { withSnackbar } from "notistack";
import DragDropInputForm from "../../components/dragDropInputFormV2";
import appRdxFns from "fitbud/redux/app";
import {DRAFT,PUBLISHED } from "fitbud/utils/constants";

const RecipesStep = props => {
  const onSave = (steps,type) => {
    const {handleDraft,handlePublish}=props;
    if(type===DRAFT){
      handleDraft({steps});
    }
    else if(type===PUBLISHED){
      handlePublish({steps});
    }
  };

  return (
    <DragDropInputForm
      open={props.open}
      options={props.steps}
      onClose={props.onClose}
      onSubmit={(steps,type) => onSave(steps,type)}
      addMoreLabel="Add more steps"
      title="How to prepare"
      showCustomConfirmation={!!props.draftFeature}
      confirmation={true}
      isDraftAvailable={props.isDraftAvailable}
    />
  );
};

const mapDispatchToProps = d => {
  const { showLoader, hideLoader } = appRdxFns(d);
  return { showLoader, hideLoader };
};

export default withSnackbar(connect(null, mapDispatchToProps)(RecipesStep));
