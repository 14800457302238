import React from 'react';
import { Drawer } from '@material-ui/core';
import { DialogAppBar } from 'fitbud/components/Dialog';
import _ from 'lodash';
import ReminderProvider from 'fitbud/views/reminders/provider';
import RemindersView from 'fitbud/views/reminders/reminderViews';
import { useReminder } from 'fitbud/views/reminders/provider';
import CircularLoader from 'fitbud/components/CircularLoader';

const Content = () => {
  const { loading } = useReminder();
  if (loading) return <CircularLoader centerAlign={true} />;
  if (!loading) return <RemindersView type="user" />;
  return null;
};

const ReminderSetting = (props) => {
  const { handleClose, reminders, saveToDB, checkInConfig, user_preference, savePreferenceToDb } = props;
  return (
    <Drawer open anchor="right">
      <DialogAppBar title={'Reminders'} onClose={handleClose} titleFont="h3" />
      <ReminderProvider checkInConfig={checkInConfig} saveToDB={saveToDB} type="user" data={reminders} preference={user_preference || {}} savePreferenceToDb = {savePreferenceToDb}>
        <Content />
      </ReminderProvider>
    </Drawer>
  );
};
export default ReminderSetting;
