import _ from 'lodash';
import moment from 'moment';
import update from 'immutability-helper';
import React, { useEffect, useContext, useMemo, useState } from 'react';
import { withSnackbar } from 'notistack';
import {
  TextField,
  Typography,
  Grid,
  InputAdornment,
  Collapse,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  ListItemText,
  FormControlLabel,
  Checkbox,
  Button,
} from '@material-ui/core';
import { Alert } from "@material-ui/lab";
import BMRCalc from 'fitbits/bmr';
import Dialog from 'fitbud/components/Dialog';
import { TagsContext } from 'fitbud/providers/tagsProvider';
import { ToggleLabel } from 'fitbud/components/toggles';
import SortBy from 'fitbud/views/meals/sortBy';
import CategoryList from 'fitbud/views/meals/categoryList';
import { DEFAULT_GROUP } from 'fitbud/views/meals/helper';
import { UserSchContext } from '../provider';
import { useInput, useToggle, usePicker, useValidators } from 'fitbud/hooks/form';

const BMREditor = ({ onClose, enqueueSnackbar }) => {
  const { cid, bmrlocal, controller, currWeek, opimport } = useContext(UserSchContext);
  const { mealCategories: mealCats, fetchTags } = useContext(TagsContext);
  const {bmr: compBMR, responses, gender, dob, weight, height, groups, sort_by, overrides} = bmrlocal;
  const bmrConfig = useMemo(() => _bmrConf(compBMR, responses, overrides), [compBMR, responses, overrides]);
  const [warn, toggleWarning] = useToggle(true);
  const bmrMode = controller.bmrMode;
  const canShowDetails = !['laura252'].includes(bmrMode);
  const [showDetails, toggleShowDetails] = useToggle(false);
  const {parsed: p, props: pProps, isValid: pValid} = useInput(_.get(bmrlocal, 'p') || '0', 'number * 0:min 100:max');
  const {parsed: f, props: fProps, isValid: fValid} = useInput(_.get(bmrlocal, 'f') || '0', 'number * 0:min 100:max');
  const {parsed: c, props: cProps, isValid: cValid} = useInput(_.get(bmrlocal, 'c') || '0', 'number * 0:min 100:max');
  const {parsed: activity, props: activityProps} = useInput(_.get(bmrConfig, 'activity.id'));
  const {parsed: goal, props: goalProps} = useInput(_.get(bmrConfig, 'goal.id') === 'custom' ?
    `${bmrConfig.goal.id}-${bmrConfig.goal.meta.type}` : _.get(bmrConfig, 'goal.id'));
  const {parsed: goalVal, props: goalValProps, setError: errGoalVal} = useInput(_.get(bmrConfig, 'goal.id') === 'custom' ?
    _.get(bmrConfig, 'goal.meta.value') : 0, 'number * 1:min');
  const [goalUnit, setGoalUnit] = usePicker(_.get(bmrConfig, 'goal.meta.unit') || 'perc');
  const [overrideCals, toggleOverrideCals] = useToggle(_.get(bmrConfig, 'calsOverride', 0) > 0);
  const {parsed: calsOverride, props: overrideProps, setError: errCalsOverride} = useInput(_.get(bmrConfig, 'calsOverride') || '', 'number * 1:min');
  const customGoal = useMemo(() => (goal && goal.startsWith('custom')), [goal]);
  const [errors, setErrors] = useState([]);
  const isValid = useValidators(pValid, fValid, cValid);
  const [sortValue, setSortValue] = useState(sort_by || 'custom');
  const [mealCategories, setMealCategories] = useState(() => {
    const { updatedMealCategories } = calculateCaloriesChange(groups || DEFAULT_GROUP);
    return updatedMealCategories;
  });
  const setMealCats = ({ groups }) => {
    setMealCategories(groups);
    setErrors([]);
  };

  const [calc, calcTargetCals, p0, f0, c0, allowMacroEdit] = useMemo(() => {
    if (!gender || !dob || !weight || !height) return [null, 0, 40, 30, 30, false];
    const calc = new BMRCalc(gender, dob, weight, height, undefined, undefined, { cid, mode: bmrMode });
    try {
      const activityMultiplier = _.chain(compBMR).get('bmr_activity.options').find(['id', activity]).get('meta.mult').value();
      if (goal === 'custom-surplus') {
        return [calc, calc.calcTarget(activityMultiplier, true, goalUnit === 'perc', goalVal, null, {macrosOnly: true}).calories, 0, 0, 0, true];
      } else if (goal === 'custom-deficit') {
        return [calc, calc.calcTarget(activityMultiplier, false, goalUnit === 'perc', goalVal, null, {macrosOnly: true}).calories, 0, 0, 0, true];
      } else {
        if (bmrMode === 'laura252') {
          const { activity, goal, diet } = responses;
          if (diet && activity && goal && activity.meta && goal.meta) {
            const {p: p0, f: f0, c: c0} = goal.meta;
            return [calc, calc.getTargetFromMeta(activity.meta, goal.meta, undefined, {macrosOnly: true, q3: diet}).calories, p0, f0, c0, false];
          }
        }
        let allowMacroEdit = false;
        let {type, value, unit, p = 0, f = 0, c = 0} = _.chain(compBMR).get('bmr_goal.options').find(['id', goal]).get('meta', {}).value();
        if (p + f + c !== 100) {
          p = 40; f = 30; c = 30;
          allowMacroEdit = true;
        }
        return [calc, calc.calcTarget(activityMultiplier, type === 'surplus', unit === 'perc', value, null, {macrosOnly: true}).calories, p, f, c, allowMacroEdit];
      }
    } catch (e) {
      return [calc, 0, 40, 30, 30, false];
    }
  }, [cid, bmrMode, responses, gender, dob, weight, height, compBMR, activity, goal, goalVal, goalUnit]);

  useEffect(() => {
    if (mealCats && mealCats.length) return;
    fetchTags('mealCategories');
  }, [mealCats, fetchTags]);

  const [timeUpdate, setTimeUpdate] = useToggle();

  useEffect(() => {
    //apply sorting after time changes
    if (!!timeUpdate) {
      handleSort(sortValue);
    }
  }, [timeUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!mealCats || !mealCats.length) return null;

  const onSave = () => {
    if (!isValid()) return;
    errGoalVal('');
    errCalsOverride('');
    if (customGoal) {
      if (goalVal < 0) return errGoalVal('Can\'t be less than 0');
      if (goalUnit === 'perc' && goalVal > 100) return errGoalVal('Can\'t be more than 100%');
    }
    if (overrideCals && calsOverride < 100) return errCalsOverride('Too Low'); 
    if ((overrideCals || customGoal) && p + f + c !== 100)
      return enqueueSnackbar('Protein + Fat + Carb percentages must equal a 100', {variant: 'error'});
    const errors = [];
    let calSum = 0, errCount = 0;
    mealCategories.forEach(cat => {
      const out = {};
      const { type, iftime, order, calories } = cat;
      if (!type || !order) {
        errCount += 1;
        out.type = 'Required';
      }
      if (sortValue === 'time' && !iftime) {
        errCount += 1;
        out.iftime = 'Required';
      }
      calSum += calories;
      errors.push(out);
    });
    setErrors(errors);
    if (errCount) return;
    if (calSum !== 100) return enqueueSnackbar('All meals should sum upto 100% calorie intake', {variant: 'error'});
    const update = { sort_by: sortValue, groups: mealCategories,
      p: overrideCals || allowMacroEdit ? p : p0,
      f: overrideCals || allowMacroEdit ? f : f0,
      c: overrideCals || allowMacroEdit ? c : c0 };
    let goalOverride = [goal];
    if (goal === 'custom-surplus') goalOverride = { id: 'custom', meta: {type: 'surplus', value: goalVal, unit: goalUnit}};
    else if (goal === 'custom-deficit') goalOverride = { id: 'custom', meta: {type: 'deficit', value: goalVal, unit: goalUnit}};
    else goalOverride = _.find(compBMR.bmr_goal.options, ['id', goal]);
    const activityOverride = _.find(compBMR.bmr_activity.options, ['id', activity]);
    update.overrides = buildOverrides(bmrConfig, overrideCals ? calsOverride : 0, activityOverride, goalOverride);
    //NEW
    controller.changeMasters('ml', currWeek, {mode: 'bmr', single: 'bmr'}, opimport.day,true);
    controller.updateBMR(update);
    onClose();
  };

  const onRemove = (index) => {
    let updatedCategories = [...mealCategories];
    updatedCategories.splice(index, 1);
    // set the last category item  to manual
    if (updatedCategories.length && updatedCategories[updatedCategories.length - 1].manual) {
      updatedCategories = update(updatedCategories, {
        [updatedCategories.length - 1]: {
          manual: {
            $set: false,
          },
        },
      });
    }
    const { updatedMealCategories } = calculateCaloriesChange(updatedCategories);
    setMealCategories(updatedMealCategories);
    setErrors([]);
  };

  const onAddOptions = (e) => {
    let updatedCategories = [...mealCategories];
    let calories = '-----';
    for (let i = 0; i < mealCategories.length; i++) {
      if (mealCategories[i].manual || mealCategories[i].calories >= 0) {
        calories = 0;
        break;
      }
    }
    updatedCategories = [...updatedCategories, { calories, type: '', manual: false }];
    const { updatedMealCategories } = calculateCaloriesChange(updatedCategories);
    setMealCategories(updatedMealCategories);
    setErrors([]);
  };

  const removeTimePicker = (index) => {
    let out = update(mealCategories, {
      [index]: {
        $merge: {
          iftime: null,
        },
      },
    });

    const { updatedMealCategories } = calculateCaloriesChange(out);
    setMealCategories(updatedMealCategories);
    setErrors([]);
  };

  const handleMealTime = (index, date) => {
    if (!date) return;
    let time = moment(date).format('HH:mm');
    let key = 'iftime';
    let updatedMeal;
    updatedMeal = update(mealCategories, {
      [index]: {
        [key]: {
          $set: time,
        },
      },
    });
    const { updatedMealCategories } = calculateCaloriesChange(updatedMeal);
    setMealCategories(updatedMealCategories);
    setErrors([]);
    setTimeUpdate(true);
  };

  const handleSort = (value) => {
    let updatedMeal;
    if (value === 'time') {
      const sortByTime = _.sortBy(mealCategories, ['iftime']);
      updatedMeal = update(mealCategories, {
        $set: sortByTime,
      });
      const { updatedMealCategories } = calculateCaloriesChange(updatedMeal);
      setMealCategories(updatedMealCategories);
    }
    setErrors([]);
    setSortValue(value);
    setTimeUpdate(false);
  };

  const handleMealChange = (index) => (e) => {
    let updatedMeal;
    const key = [e.target.id || e.target.name];

    if (e.target.name === 'calories') {
      if (e.target.value === '' || isNaN(Number(e.target.value))) {
        e.target.value = 0;
      }

      let value = Number(e.target.value);
      updatedMeal = update(mealCategories, {
        [index]: {
          [key]: {
            $set: value,
          },
          manual: {
            $set: true,
          },
        },
      });
      // in case when the current cals sum is greater than previous cals
      const manualTotalCals = updatedMeal.reduce((a, b) => {
        if (b.manual) {
          return a + b.calories;
        }
        return a;
      }, 0);
      //if cals value greater than total cals and manual total cals
      if (value > 100 || manualTotalCals > 100) return;
    } else {
      if (e.target.value === 'select') return;
      let order = mealCats.findIndex((cat) => cat[0] === e.target.value);
      order = order === -1 ? 1 : order + 1;
      updatedMeal = update(mealCategories, {
        [index]: {
          [key]: {
            $set: e.target.value,
          },
          order: {
            $set: order,
          },
        },
      });
      updatedMeal = updatedMeal.sort((a, b) => a.order - b.order);
      updatedMeal = update(updatedMeal, {
        [updatedMeal.length - 1]: {
          manual: {
            $set: false,
          },
        },
      });
    }
    const { updatedMealCategories } = calculateCaloriesChange(updatedMeal);
    setMealCategories(updatedMealCategories);
    setErrors([]);
  };

  return (
    <Dialog open
      toolbarClass="height-60"
      buttonColor="primary"
      onClose={onClose}
      onSave={onSave}
      title="Edit Macro & Meal Split"
      titleFont="h3" paperClass="width-600">
      {warn && !calcTargetCals ? (!calc ? <Alert severity='warning' classes={{root: 'mt-20 mx-20'}}>
          BMR calculation requires the client's age, gender, weight &amp; height details. Please ask the client to complete their onboarding questionnaire to utilize this feature effectively.
        </Alert> : <Alert severity='info' classes={{root: 'mt-20 mx-20'}}>
          BMR calculation requires the client's goal and activity information. Please ask the client to complete their onboarding questionnaire to utilize this feature effectively.
          <div className='w-100 d-flex flex-row'><div className='flex-grow-1'/><Button onClick={toggleWarning} color='inherit' size='small'>OK</Button></div>
        </Alert>) : null}
      <div className="mt-30 mb-10">
        <div className='d-flex flex-row px-20 mb-20'>
          <Typography className='font_18_600 text-0d0d0d'>Calories &amp; Macros</Typography>
          <div className='flex-1'/>
          {canShowDetails && Boolean(calc) && <Button color='primary' className='p-0' onClick={toggleShowDetails}>{showDetails ? 'Hide' : 'Show'} Calculations</Button>}
        </div>
        <Collapse in={showDetails}>
          <div className='px-20'>
            <div className='d-flex flex-row mb-20'>
              <Typography className='font_15_500 text-0d0d0d'>BMR</Typography>
              <div className='flex-1'/>
              <Typography className='font_15_500 text-0d0d0d'>
                <span className='font-700'>{calc ? Math.round(calc.bmr) : 'n/a'}</span> cals</Typography>
            </div>
            <div className='w-100 mb-20'>
              <Typography variant='body2' color='textSecondary' className='mb-10'>
                Activity Level
              </Typography>
              <Select fullWidth variant='outlined' className='mr-20' classes={{root: 'medium font_15_500', select: 'select-medium'}} {...activityProps}>
                {compBMR.bmr_activity.options.map(x => <MenuItem key={x.id} value={x.id}>
                  <ListItemText primary={x.text} secondary={<LIT activity value={activity} opt={x} userChoice={responses.activity} />} />
                </MenuItem>)}
              </Select>
            </div>
            <Typography variant='body2' color='textSecondary' className='w-100 mb-10'>
              Goal
            </Typography>
            <Grid container className="p-0 mb-20" spacing={2}>
              <Grid item className='pb-0' xs={customGoal ? 8 : 12}>
                <Select fullWidth variant='outlined' className='mr-20' classes={{root: 'medium font_15_500', select: 'select-medium'}} {...goalProps}>
                  {compBMR.bmr_goal.options.map(x => <MenuItem key={x.id} value={x.id}>
                    <ListItemText primary={x.text} secondary={<LIT value={goal} opt={x} userChoice={responses.goal} />} />
                  </MenuItem>)}
                  <MenuItem value='custom-surplus'>Custom Calorie Surplus</MenuItem>
                  <MenuItem value='custom-deficit'>Custom Calorie Deficit</MenuItem>
                </Select>
              </Grid>
              {customGoal && <Grid item className='pb-0' xs={4}>
                <TextField fullWidth variant="outlined" {...goalValProps}
                  InputProps={{
                    classes: {root: "medium pr-0", input: "size_15_500 medium"},
                    endAdornment: (<InputAdornment position="end">
                      <ToggleLabel options={OPTSCALPERC} value={goalUnit} onChange={setGoalUnit}/>
                      </InputAdornment>),
                  }}/>
              </Grid>}
            </Grid>
          </div>
        </Collapse>
        {canShowDetails && <FormControlLabel className='px-20 mb-15'
          control={<Checkbox checked={overrideCals} color="primary" onClick={toggleOverrideCals}/>}
          label={<Typography>Specify Target Calories &amp; Macros Manually</Typography>}/>}
        <Grid container className="p-0 px-20 mb-20" spacing={2}>
          <Grid item className='pb-0' xs>
            <FormControl fullWidth>
              <FormLabel className="mb-10">
                <div className="d-flex justify-content-between">
                  <Typography variant='body2' color="textSecondary">
                    Calories
                  </Typography>
                </div>
              </FormLabel>
              <TextField disabled={!overrideCals} {...overrideProps} variant='outlined'
                value={overrideCals ? overrideProps.value : Math.round(calcTargetCals)}
                InputProps={{
                  classes: { root: 'medium pl-0', input: 'size_16_500 medium', disabled: 'text-black-50' },
                  endAdornment: <InputAdornment position="end">cals</InputAdornment>,
                }}/>
            </FormControl>
          </Grid>
          <BmrMacros disabled={!overrideCals && !allowMacroEdit} calcCals={calcTargetCals} overrideCals={overrideCals} calsOverride={calsOverride} item='p' {...pProps} fallback={p0}/>
          <BmrMacros disabled={!overrideCals && !allowMacroEdit} calcCals={calcTargetCals} overrideCals={overrideCals} calsOverride={calsOverride} item='f' {...fProps} fallback={f0}/>
          <BmrMacros disabled={!overrideCals && !allowMacroEdit} calcCals={calcTargetCals} overrideCals={overrideCals} calsOverride={calsOverride} item='c' {...cProps} fallback={c0}/>
        </Grid>
        <hr/>
        <Typography className='px-20 font_18_600 mb-10 text-0d0d0d'>Meal Categories</Typography>
        <div className='px-20'>
          <SortBy className="py-10" handleSort={handleSort} handleChange={setSortValue} value={sortValue} />
          <CategoryList
            handleMealChange={handleMealChange}
            groups={mealCategories}
            errors={errors}
            mealCats={mealCats}
            onRemove={onRemove}
            onAddOptions={onAddOptions}
            mealCategories={mealCategories}
            handleMealTime={handleMealTime}
            removeTimePicker={removeTimePicker}
            sortBy={sortValue}
            valueAdornmentText="%"
            updateParent={setMealCats}/>
        </div>
      </div>
    </Dialog>
  );
};

const calculateCaloriesChange = ([...items], calories = 100) => {
  let usedCalories = 0;

  usedCalories = items.reduce((a, b) => {
    if (b.manual) {
      return a + b.calories;
    }
    return a;
  }, 0);

  let remainingCals = calories - usedCalories;

  const unChangesGrps = items.filter((a) => !a.manual);
  // If remain cals is negative then divide the cals equally in manual case
  if (remainingCals < 0) {
    const changesGrps = items.filter((a) => a.manual);
    const manualCals = calories / changesGrps.length;
    items.forEach((item, i, thisArg) => {
      if (item.manual) {
        items = update(items, {
          [i]: {
            calories: {
              $set: manualCals,
            },
          },
        });
      }
    });
  }

  remainingCals = remainingCals > 0 ? remainingCals : 0;

  remainingCals = remainingCals / unChangesGrps.length;

  if (unChangesGrps.length) {
    items.forEach((item, i) => {
      if (!item.manual) {
        items = update(items, {
          [i]: {
            calories: {
              $set: remainingCals,
            },
          },
        });
      }
    });
  }
  return { updatedMealCategories: items, remainingCals };
};

const BmrMacros = (props) => {
  const { fallback = 0, calcCals = 0, overrideCals = false, calsOverride = 0, index, item, ...rest } = props;
  const finalCals = (overrideCals && calsOverride) || calcCals || 0;
  const val = finalCals * (rest.value || 0) / 100 / (item === 'f' ? 9 : 4);
  const rounded = Math.round(val);
  return (
    <Grid item className='pb-0' xs>
      <div className='w-100'>
        <div className='d-flex flex-row mb-10'>
          <Typography variant='body2' color='textSecondary'>{_.capitalize(PFC_CONSTANT[item])}</Typography>
          <div className='flex-1'/>
          <Typography variant='body2' color='textSecondary'>{val ? `${rounded} g` : ''}</Typography>
        </div>
        <TextField fullWidth required {...rest} value={rest.disabled ? fallback : rest.value} variant='outlined'
        InputProps={{
          classes: { root: 'medium pl-0', input: 'size_16_500 medium', disabled: 'text-black-50' },
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}/>
      </div>
    </Grid>
  );
};

const LIT = (props) => {
  const {value, activity = false, opt, userChoice} = props;
  if (!opt || !opt.id || !opt.meta) return null;
  const { id, meta } = opt;
  const txt = activity ? `BMR x ${meta.mult}` :
    `Calorie ${meta.type} - ${meta.value} ${meta.unit === 'perc' ? '%' : 'cals'}`;
  let suffix = '';
  if (value !== id && userChoice && userChoice.id === id)
    suffix = ' ⬩ Client\'s choice';
  return (
    <Typography classes={{root: 'font_12_500'}}>
      {txt}<span className='text-danger'>{suffix}</span>
    </Typography>
  );
};

const _bmrConf = (bmr, responses, overrides) => {
  const { activity, goal, cals } = overrides || {};
  return {
    activity: activity || responses.activity || {},
    goal: goal || responses.goal || {},
    uactivity: responses.activity || {},
    ugoal: responses.goal || {},
    calsOverride: cals || 0,
  };
};
const buildOverrides = (bmrConfig, cals, activity, goal) => {
  if (cals) return {cals, activity: null, goal: null};
  const {uactivity: a, ugoal: g, calsOverride} = bmrConfig;
  if (_.isEqual(a, activity || {}) && _.isEqual(g, goal || {}) && calsOverride === cals) return null;
  const out = {};
  if (activity) out.activity = activity;
  if (goal) out.goal = goal;
  if (cals) out.cals = cals;
  return out;
};

const PFC_CONSTANT = {
  p: 'Protein',
  c: 'Carbs',
  f: 'Fat',
};
const OPTSCALPERC = [
  {label: 'cals', value: 'cal'},
  {label: '%', value: 'perc'},
];

export default withSnackbar(BMREditor);
