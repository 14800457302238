import React,{useMemo, useState} from 'react';
import {Card,makeStyles, Typography} from '@material-ui/core';
import {Avatar} from "fitbud/components/avatar";
import _ from 'lodash';
import {ExerciseDetailPopup} from "fitbud/views/workouts/exercise";
import getSecondaryTextListItems from "fitbud/components/catalog/listSecondLine";
import clsx from 'clsx';
import { getExIcon } from 'fitbud/utils/catalog';


const styles=makeStyles({
    container:{
        display:"flex",
        flexWrap:"wrap",
        gap:"20px"
    },
    card:{
        height:"74px",
        width:"280px",
        // maxWidth:"360px",
        // width:"250px",
        borderRadius:"10px",
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.06)',
        padding:"12px 15px!important",
        display:"flex",
        // marginRight:"20px",
        border:"1px solid #D3D9DB!important",
        // marginBottom:"20px",
        '& .icon':{
            position: "absolute",
            bottom: "-3px",
            right: "5px",
            height: "14px",
            width: "14px",
        }
    },
    cardTextContainer:{
        marginLeft:"15px",
        display:"flex",
        flexDirection:"column",
        flex:1,
        minWidth:"0px",
        justifyContent:"center"
    }
})

export const AltExercises=({alts=[],lib={},isPreview})=>{
    const [open,toggleOpen]=useState(null);
    const classes=styles();
    const exercises=useMemo(()=>{
        if(!alts) return {};
        const obj={}
        alts.forEach(i=>{
            const found=lib.find(j=>j._id===i);
            if(!!found){
                obj[i]={
                    ...found.data,
                    id:found._id
                }
            }
            
        });
        return obj;
    },[alts,lib]);
    function openPreview(id){
        if(isPreview) return;
        toggleOpen(id);
    }
    function closePreview(){
        toggleOpen(null);
    }
    return(
    <>
        <div className={classes.container}>
            {Object.keys(exercises).map(i=>{
                const data=exercises[i];
                const {thumbnail,title,id,ref_name,media_type,media} =data;
                const secondLine=getSecondaryTextListItems({keyName:"exercises",doc:{data}})
                const icon=getExIcon({type:media_type,media});
                return(
                <Card key={id} className={clsx(classes.card,isPreview?"":"cursor-pointer")} onClick={()=>openPreview(id)}>
                    <div className='position-relative'>
                        <Avatar size={"small"} src={thumbnail} name={ref_name} styles={{fontSize:13}}/>
                        {icon && <img className="icon" src={icon} alt="" />}
                    </div>
                    <div className={classes.cardTextContainer}>
                        <Typography className={clsx('font_15_600 text-truncate',secondLine && "mb-8")}>{title}</Typography>
                        {secondLine && <Typography className='font_13_500 text-grey'>{secondLine}</Typography>}
                    </div>
                </Card>
                )
            })}
        </div>
        {open && <ExerciseDetailPopup id={open} onClose={closePreview}/>}
    </>
    )
}