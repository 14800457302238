import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Tooltip,
  Typography,
  InputAdornment,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import { ClrdButton } from 'fitbud/components/form-fields';
import { daysNameFull } from 'fitbud/utils/scheduling';
import { TimePick } from 'fitbud/views/liveSessions/masterSettings/SlotPicker';
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { CopyIcon } from 'fitbud/icons/copy2';
import { dateToHHmm, hhMMtoDate, MORNING_10AM, DEFAULT_GAP_IN_NEW_SLOT } from '../helper';
import 'moment-timezone';
import { ClipboardIcon } from 'fitbud/icons/clipboard';
import DoneIcon from '@material-ui/icons/CloseRounded';
import CancelIcon from '@material-ui/icons/Cancel';

const useClasses = makeStyles(() => ({
  timeInput: {
    width: 160,
    marginTop: 10,
    marginRight: 30,
  },
}));

const TimeInputWrapper = (props) => {
  const { onCancel, disabled, ...rest } = props;
  const handleCancel = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onCancel && onCancel(e);
  };
  return (
    <TimePick
      {...rest}
      disabled={disabled}
      minutesStep={5}
      InputProps={{
        endAdornment: (
          <InputAdornment className="mr-n10">
            <IconButton disabled={disabled} size="small" onClick={handleCancel}>
              <CancelIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

const SlotConfig = (props) => {
  const { slotsConfig, setSlotConfig, duration } = props;
  const { different_slots, days = {}, same_rcr_time } = slotsConfig;

  const handleSameChange = (e) => {
    const { checked } = e.target;
    if (checked) setSlotConfig({ different_slots: checked, same_rcr_time: [], days: {} });
    else setSlotConfig({ different_slots: checked, same_rcr_time: [dateToHHmm(MORNING_10AM)], days: {} });
  };

  const handleDayChange = (e, data) => {
    const { id } = e.target;
    const curr = days[id] || false;
    if (!different_slots) setSlotConfig((prev) => ({ ...prev, days: { ...prev.days, [id]: !curr } }));
    else setSlotConfig((prev) => ({ ...prev, days: { ...prev.days, [id]: data } }));
  };

  return (
    <div>
      <div className="px-20 pt-20">
        <FormControlLabel
          classes={{ root: 'mb-0', label: 'font_18_600 text-0d0d0d' }}
          label={'Different times on different days'}
          control={<Checkbox checked={different_slots} onChange={handleSameChange} color="primary" />}
        />
      </div>
      {!different_slots && <WeekSlot handleDayChange={handleDayChange} {...props} />}
      {!!different_slots && <DaySlots onChange={handleDayChange} days={days} duration={duration} />}
    </div>
  );
};

const WeekSlot = (props) => {
  const { slotsConfig, setSlotConfig, duration, handleDayChange } = props;
  const { days = {}, same_rcr_time } = slotsConfig;
  const classes = useClasses();

  const handleTimeChange = (time, index) => {
    const _t = dateToHHmm(time);
    const out_same_rcr_time = [...same_rcr_time];
    out_same_rcr_time.splice(index, 1, _t);
    setSlotConfig((prev) => ({ ...prev, same_rcr_time: out_same_rcr_time }));
  };

  const addTimeSlot = (e) => {
    e.preventDefault();
    let out_rcr_time = [...same_rcr_time];
    if (!out_rcr_time || !out_rcr_time.length) out_rcr_time.push(dateToHHmm(MORNING_10AM));
    else {
      const lastTime = same_rcr_time[same_rcr_time.length - 1];
      const addedTime = dateToHHmm(
        moment(lastTime, 'HHmm')
          .add(duration + DEFAULT_GAP_IN_NEW_SLOT, 'minutes')
          .toDate()
      );
      out_rcr_time.push(addedTime);
    }
    setSlotConfig((prev) => ({ ...prev, same_rcr_time: out_rcr_time }));
  };

  const removeTimeSlot = (index) => {
    let out_rcr_time = [...same_rcr_time];
    out_rcr_time.splice(index, 1);
    setSlotConfig((prev) => ({ ...prev, same_rcr_time: out_rcr_time }));
  };

  return (
    <div className="px-20">
      <div className="mt-15">
        <Typography className="font_12_500" color="textSecondary">
          Days
        </Typography>
        <div className="d-flex mt-10">
          {daysNameFull.map((i) => {
            const isChecked = days[i.toLowerCase()];
            return (
              <div
                className={clsx(
                  'border mr-20 d-flex justify-content-center align-items-center cursor-pointer',
                  isChecked && 'bg-primary'
                )}
                style={{ height: '48px', width: '48px', borderRadius: '100px' }}
                onClick={handleDayChange}
                id={i.toLowerCase()}>
                <Typography
                  id={i.toLowerCase()}
                  onClick={handleDayChange}
                  className={clsx('font_12_500', isChecked && 'text-white')}>
                  {i[0]}
                </Typography>
              </div>
            );
          })}
        </div>
      </div>
      <div className="mt-20  mb-20">
        <div>
          <Typography className="font_12_500" color="textSecondary">
            Time
          </Typography>
          <div className="d-flex flex-wrap">
            {same_rcr_time.map((rcr_time, index) => {
              return (
                <TimeInputWrapper
                  value={hhMMtoDate(rcr_time)}
                  format="hh:mm a"
                  onChange={(time) => handleTimeChange(time, index)}
                  onCancel={() => removeTimeSlot(index)}
                  name="time"
                  minTime={moment().toDate()}
                  minutesStep={15}
                  className={clsx(classes.timeInput)}
                />
              );
            })}
            <div className="align-self-center">
              <ClrdButton onClick={addTimeSlot} color="primary" className="mt-10">
                {' '}
                + Add More
              </ClrdButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DaySlots = ({ days = {}, onChange, duration }) => {
  const [copyDay, setCopyDay] = useState('');
  const handleCheckChange = (e) => {
    const { checked } = e.target;
    if (!checked) onChange(e, false);
    else onChange(e, [moment().hour(10).minutes(0).toDate()]);
  };

  const handleTimeChange = (time, index, day) => {
    const dayId = day.toLowerCase();
    const _time = moment(time);
    const dayData = _.get(days, dayId, []);
    dayData.splice(index, 1, _time);
    onChange({ target: { id: dayId } }, dayData);
  };

  const removeTimeSlot = (e, day, index) => {
    e.preventDefault();
    e.stopPropagation();
    const dayId = day.toLowerCase();
    const dayData = _.get(days, dayId, []);
    if (dayData.length < 2) onChange({ target: { id: dayId } }, false);
    else {
      dayData.splice(index, 1);
      onChange({ target: { id: dayId } }, dayData);
    }
  };

  const addTimeSlot = (e, day) => {
    e.preventDefault();
    const dayId = day.toLowerCase();
    const dayData = _.get(days, dayId, []);
    if (!dayData.length) return onChange({ target: { id: dayId } }, [moment().hour(10).minutes(0).toDate()]);
    const prevSlot = _.get(dayData, `${dayData.length - 1}`, false);
    const newSlot = moment(prevSlot)
      .add(duration + DEFAULT_GAP_IN_NEW_SLOT, 'minutes')
      .toDate();
    dayData.push(newSlot);
    onChange({ target: { id: dayId } }, dayData);
  };

  const clearCopyDay = () => {
    setCopyDay('');
  };

  const handleCopy = (e, day) => {
    setCopyDay(day);
  };

  const handlePaste = (e, dayId) => {
    onChange({ target: { id: dayId } }, _.get(days, `${copyDay}`));
  };

  return (
    <React.Fragment>
      {daysNameFull.map((day) => {
        const dayId = day.toLowerCase();
        const isChecked = !!_.get(days, dayId, false);
        const slots = _.get(days, dayId, []);
        const isSameDayCopied = dayId === copyDay;
        return (
          <div
            className="d-flex w-100 px-20 border-bottom pt-20 pb-5"
            style={{ backgroundColor: isSameDayCopied ? 'rgba(64, 137, 247, 0.1)' : '' }}>
            <div className="mr-10" style={{ width: 160 }}>
              <FormControlLabel
                // classes={{ root: 'm-0' }}
                label={<Typography className="font_15_500">{day.toUpperCase()}</Typography>}
                control={
                  <Checkbox
                    disabled={!!copyDay}
                    checked={isChecked}
                    id={dayId}
                    onChange={handleCheckChange}
                    // classes={{ root: 'fmr-10' }}
                    color="primary"
                  />
                }
              />
            </div>
            <div className="flex-grow-1">
              {(!slots || !slots.length) && (
                <div className="">
                  <Typography className="font_15_500 mt-12" color="textSecondary">
                    No Slot Added
                  </Typography>
                </div>
              )}
              {!!slots && !!slots.length && (
                <>
                  {slots.map((time, index) => {
                    return (
                      <div className={clsx('mb-15')} key={day + index}>
                        <TimeInputWrapper
                          style={{ width: '140px' }}
                          value={time}
                          format="hh:mm a"
                          onChange={(time) => handleTimeChange(time, index, day)}
                          name="time"
                          minTime={moment().toDate()}
                          minutesStep={15}
                          disabled={!!copyDay}
                          onCancel={(e) => removeTimeSlot(e, day, index)}
                        />
                      </div>
                    );
                  })}
                </>
              )}
            </div>
            <div className="" style={{ paddingTop: 6, marginLeft: 5 }}>
              {!!slots && !!slots.length && (
                <div className="d-flex align-items-center">
                  <ClrdButton color="primary" onClick={(e) => addTimeSlot(e, day)} disabled={!!copyDay}>
                    + Add More
                  </ClrdButton>
                  {!copyDay && (
                    <Tooltip title="Copy">
                      <IconButton edge="end" onClick={(e) => handleCopy(e, dayId)}>
                        <CopyIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {!!copyDay && !isSameDayCopied && (
                    <Tooltip title="Paste">
                      <IconButton edge="end" id={dayId} onClick={(e) => handlePaste(e, dayId)}>
                        <ClipboardIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {!!copyDay && !!isSameDayCopied && (
                    <Tooltip title="Cancel">
                      <IconButton edge="end" onClick={(e) => clearCopyDay()}>
                        <DoneIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default SlotConfig;
