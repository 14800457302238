import React from "react";
import { withRouter } from "react-router";
import {
  withMobileDialog,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Toolbar,
  AppBar,
  Link
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/CloseRounded";
import useVideoContext from './useVideoContext';
import Logo from "fitbud/components/logo";
import clsx from "clsx";

export const DialogAppBar = ({
  onClose,
  toolbarClass = "height-70",
  appBarColor = "bg-white",
  position = "sticky",
  appBarTextColor = "text-black",
}) => {
  const { reJoin, removeLocalAudioTrack, removeLocalVideoTrack, localTracks } = useVideoContext();
  
  const handleClose = (e) => {
    removeLocalAudioTrack()
    removeLocalVideoTrack()
    onClose(e)
    localTracks.forEach(track => track.stop()) //stop all tracks
  }

  return (
    <AppBar position={position} className={appBarColor} elevation={0} >
      <Toolbar
        className="d-flex justify-content-between align-item-center fpx-20"
        classes={{
          root: toolbarClass
        }}
      > 
        <Link to='/' className='logo d-flex flex-row align-items-center'>
          <Logo height={32} color='fancy-color' fullWidth align='center' />
        </Link>
        {!reJoin &&
          <IconButton edge="start" onClick={handleClose}>
            <CloseIcon className={appBarTextColor} />
          </IconButton>
        }
      </Toolbar>
    </AppBar>
  );
};

const SimpleDialog = props => {
  const {
    open,
    onClose,
    children,
    fullScreen,
    fullWidth,
    classes,
    paperClass,
    roomState,
    ...restProps
  } = props;
  return (
    <Dialog
      id="dialog-document-modal"
      open={open}
      fullScreen={fullScreen}
      scroll="paper"
      disableBackdropClick={!!fullScreen}
      disableEscapeKeyDown={!!fullScreen}
      classes={Object.assign({}, { paper: paperClass }, restProps.className)}
    >
      <DialogTitle className="fp-0" disableTypography>
        {(roomState === "connected") || roomState === "reconnecting" ? null : (
          <DialogAppBar onClose={onClose} {...restProps} />
        )}
      </DialogTitle>
      <DialogContent
        className={clsx(`p-0`, restProps.dialogContentClassName)}
        style={restProps.dialogContentStyle}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default withRouter(withMobileDialog({ breakpoint: "xs" })(SimpleDialog));
