import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import firebase from 'fitbud/firebase';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import planRdxFns from 'fitbud/redux/plans';
import { PlanContext } from 'fitbud/views/plan/planProvider';
import _ from 'lodash';
import { parsePacks } from 'fitbud/views/plan/helper';


const usePacks = () => {
  const dispatch = useDispatch();
  const { docs } = useSelector((s) => s.plans);
  const { cid, comp } = useContext(FirebaseAuthContext);
  const { parsePacksForIapIds } = useContext(PlanContext);
  const { set } = planRdxFns(dispatch);

  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const company = comp ? comp.data() : {};
  const isGroupClass = Boolean(_.get(company, 'features.group_class.enabled', false));

  const fetchPacks = useCallback(async (refresh = false) => {
    const loader = refresh ? setRefreshing : setLoading;
    try {
      loader(true);
      const docs = await firebase.firestore().collection(`companies/${cid}/packs`).get();
      const packs = parsePacks(docs, isGroupClass);
      parsePacksForIapIds(docs);
      set(packs, 0, true);
      loader(false);
    } catch (err) {
      loader(false);
    }
  }, []);

  useEffect(() => {
    if (!_.size(docs)){
      fetchPacks(false);
    }
  }, []);

  return { packs: docs || [], refreshing, loading, fetchPacks };
};

export default usePacks;
