import React, { useContext , useMemo} from "react";
import { IconButton, Typography } from "@material-ui/core";
import { UserSchContext } from '../provider';
import _ from "lodash";
import LeftIcon from '@material-ui/icons/ArrowBackIosRounded';
import RightIcon from '@material-ui/icons/ArrowForwardIosRounded';
export const DateNav=()=>{
	const { currWeek, controller,local } = useContext(UserSchContext);
    const range = useMemo(() => {
        return controller.weekL2(currWeek,true);
    }, [controller,currWeek]);
    const prevWeek=()=>controller.prevWeek();
    const nextWeek=()=>controller.nextWeek();
    const totalWeeks=_.get(local,'plan.weeks',[]).length;
    return(
        <div className="d-flex align-items-center">
            <IconButton onClick={prevWeek} disabled={!currWeek}>
                <LeftIcon classes={{root:"small2"}}/>
            </IconButton>
            <Typography className="font_15_600 mx-30">
                {range}
            </Typography>
            <IconButton onClick={nextWeek} disabled={(totalWeeks-1)===currWeek}>
                <RightIcon classes={{root:"small2"}}/>
            </IconButton>
        </div>
    )
}