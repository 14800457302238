const INF = '999999999999';
const DAY = 24 * 60;
const FREQUENCY = {
  1: 'Once a day',
  7: 'Once a week',
  30: 'Once a month',
};

const ACTIONS = {
  allPlans: 'All Plans Page',
  paymentPlan: 'Specific Payment Plan',
  custom: 'Custom Link',
  challenges: 'Challenge'
};

export { INF, DAY, FREQUENCY, ACTIONS };
