import React from "react";
import { FormControlLabel, Typography, Checkbox,makeStyles } from "@material-ui/core";
import {DurationTextField} from "fitbud/components/durationInput";
import clsx from 'clsx';

const styles=makeStyles({
  secondaryText:{
    lineHeight:"18px"
  }
})

const PSLabel = ({primary, secondary}) => {
  const classes=styles();
  return (
    <div>
      <Typography className={clsx("font_15_600 mb-8 text-1B1B1B")}>{primary}</Typography>
      <Typography className={clsx('font_13_400 text-grey',classes.secondaryText)}>{secondary}</Typography>
    </div>
  );
};

const VideoFeatures = (props) => {
  const {
    doc,
    handleChange,
    mediaType ,
    disabled,
    featureType=null,
    withThumbnail,
  } = props;
  const {
    is_mute_video,
    is_crop_square,
    total_duration,
    intro_duration,
    warmup_duration,
    outro_duration,
  }=doc;
  const _handleChange=(e)=>{
    const { id, value } = e.target;
    switch (id) {
        case "intro_duration":{
          //--mount edit case
          if(intro_duration===value) return;
          //--mount edit case - end
          handleChange(e);
          break;
        }
        case "warmup_duration":{
          const newValue=value - intro_duration;
          //--mount edit case
          if(warmup_duration===newValue) return;
          //--mount edit case - end
          handleChange({
            target: { ...e.target, value: newValue },
          });
          break;
        }
        case "outro_duration":{
          const newValue=total_duration - value;
          // --mount edit case
          if(outro_duration===newValue) return;
          // --mount edit case - end
          handleChange({
            target: { ...e.target, value:newValue },
          });
          break;
        }
        default:
          handleChange(e);
          break;
      }
  }
  const isImage=mediaType==="image";
  return (
    <div className="d-flex flex-column">
      {featureType === "workout" && (
        <>
          <DurationTextField
            fullWidth
            variant="outlined"
            label={intro_duration ? "Intro ends at" : "Intro"}
            InputProps={{
              classes: {
                root: "medium",
                input: "size_16_500",
                label: "font_13_400",
              },
            }}
            value={intro_duration || 0}
            id="intro_duration"
            isZero={!total_duration || !intro_duration}
            disabled={disabled || !total_duration || isImage}
            max={total_duration}
            onChange={_handleChange}
            noMountCb={true}
          />
          <DurationTextField
            fullWidth
            label={warmup_duration ? "Warmup ends at" : "Warmup"}
            variant="outlined"
            InputProps={{
              classes: {
                root: "medium",
                input: "size_16_500",
                label: "font_13_400",
              },
            }}
            value={(intro_duration || 0) + (warmup_duration || 0)}
            id="warmup_duration"
            isZero={!total_duration || !warmup_duration}
            disabled={disabled || !total_duration || isImage}
            min={intro_duration}
            max={total_duration}
            onChange={_handleChange}
            noMountCb={true}
          />
          <DurationTextField
            fullWidth
            label={outro_duration ? "Cooldown starts at" : "Cooldown"}
            variant="outlined"
            InputProps={{
              classes: {
                root: "medium",
                input: "size_16_500",
                label: "font_13_400",
              },
            }}
            value={total_duration - outro_duration}
            id="outro_duration"
            isZero={!total_duration || !outro_duration}
            disabled={disabled || !total_duration || isImage}
            min={(intro_duration || 0) + (warmup_duration || 0)}
            max={total_duration}
            onChange={_handleChange}
            formTextFieldProps={{
              classes: {
                control: "mb-0",
              },
            }}
            noMountCb={true}
          />
        </>
      )}
      {featureType === "exercise" && (
        <div className={clsx(withThumbnail && "d-flex justify-content-between" )}>
          <FormControlLabel
            classes={{ root: clsx("align-items-start m-0", !withThumbnail && "mb-20", withThumbnail && "w-50") }}
            label={
              <PSLabel
                primary="Disable Audio"
                secondary="Enabling this setting will cause audio track to be disabled for client playback"
              />
            }
            control={
              <Checkbox
                color="primary"
                id="is_mute_video"
                name="is_mute_video"
                className="p-0 mr-10"
                onChange={_handleChange}
                checked={!!is_mute_video}
                disabled={isImage}
              />
            }
          />
          <FormControlLabel
            classes={{ root: clsx("align-items-start m-0", withThumbnail && "w-50") }}
            label={
              <PSLabel
                primary="Crop to Square"
                secondary="Video will play in square format on the client app"
              />
            }
            control={
              <Checkbox
                color="primary"
                id="is_crop_square"
                name="is_crop_square"
                className="p-0 mr-10"
                onChange={_handleChange}
                checked={!!is_crop_square}
              />
            }
          />
        </div>
      )}
    </div>
  );
};

export default VideoFeatures;
