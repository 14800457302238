import axnsBuilder from "./actions";

export const DEFAULT_STATE = {
  doc: undefined,
  loading: false
};

const axns = axnsBuilder();
export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case axns.setStaff:
      return {
        ...state,
        loading: false,
        doc: action.doc,
        timestamp: Date.now()
      };

    default:
      return state;
  }
};
