const getLocationHash = props => {
  return props.location.hash;
};

const doesHashMatch = (props, hash) => {
  return `#${hash}` === getLocationHash(props);
};
const doesHashStartsWith = (props, str) => {
  let regex = new RegExp(`^${str}`);
  return regex.test(getLocationHash(props).substring(1));
};
const removeLocationHash = props => {
  if (props.history.length <= 1) props.history.replace("#");
  else props.history.goBack();
};

export {
  getLocationHash,
  doesHashMatch,
  removeLocationHash,
  doesHashStartsWith
};
