import _ from 'lodash';
import React, { useState, useContext } from 'react';
import { withSnackbar } from 'notistack';
import { Typography, TextField, IconButton, Grid, Divider, Drawer } from '@material-ui/core';
// import { DeleteIcon } from 'fitbud/icons/delete';
import { EditorContext } from './supplementsForm';
import { Avatar } from 'fitbud/components/avatar';
import { Conversion } from 'fitbud/providers/conversion';
import { roundNumber } from 'fitbud/utils/helpers';
import clsx from 'clsx';
import { SimpleNumberInput } from 'fitbud/components/numberInput';
import { makeStyles } from '@material-ui/core/styles';
import { DeleteIcon } from "fitbud/icons/delete";
import {DialogAppBar} from "fitbud/components/Dialog";
import SupplementInfo from "fitbud/views/supplement/info";




const useStyle = makeStyles(() => ({
  root: {
    width: '70px !important',
    height:'40px !important '
  },
  textAlignRigth: {
    textAlign: 'right',
  },
}));



const Supplement = (props) => {
  const { supplementData, meal, itemIndex, length } = props;
  const { editorMode: isEditMode, handleChange, handleDelete, serving } = useContext(EditorContext);
  const { convertor } = useContext(Conversion);
  const _isLast = length - 1 === itemIndex;
  const classes = useStyle();
  const [selectedSupplement, setSelectedSupplement] =  useState(null);

  let size = supplementData.serving_size;
  let unit = supplementData.serving_unit || '';
  if (supplementData.type === 'supplement' && supplementData.serving_type) {
    let servingSize = roundNumber(meal.quantity * Number(size));
    size = convertor.getDisplayValue(supplementData.serving_type, servingSize);
    unit = convertor.getDisplayUnit(supplementData.serving_type);
  }

  const servingItem = _.find(serving, (d) => d[0] === supplementData.serving);
  const handleQuantity = (e) => {
    let value = Number(e.target.value);
    // if (!value) return;
    handleChange(e);
  };

  const handleBlur = (e) =>{
    let _value = Number(e.target.value);
    if (!_value)
      props.enqueueSnackbar("Please enter a valid quantity", {
        variant: 'error',
      });
  }

  const onDelete = () => {
    handleDelete({
      grpIndex: props['data-group'],
      itemIndex,
    });
  };

  // if (!!error) {
  //   props.enqueueSnackbar(error, {
  //     variant: 'error',
  //   });
  // }

  const onCloseFoodDetail = () =>{
    setSelectedSupplement(null);
  }


  return (
    <>
      <div className={clsx('d-flex justify-content-between align-items-center', !_isLast && 'fmb-20')}>
        <div className="d-flex justify-content-start align-items-center  cursor-pointer col fp-0"
        onClick={()=>{
          setSelectedSupplement({id:supplementData.id});
        }}
        >
          <Avatar
            name={supplementData.ref_name}
            src={supplementData.thumbnail}
            size="large"
            alt={supplementData.ref_name || ' '}
            className="fmr-20"
          />
          <div>
            <Typography className="fmb-5 font_15_600 word-break">{supplementData.ref_name}</Typography>
            {isEditMode && (
              <Typography  variant="body2" className="text-labels">
                <span className="font_13_600">{meal.quantity}</span> 
                {servingItem && <span className="font_13_500">&nbsp;{servingItem[1].value}</span>}
                {!!size && <span>&nbsp;•&nbsp;<span className="font_13_600">{size}</span>&nbsp;<span className="font_13_500">{unit}</span></span>}
              </Typography>
            )}
          </div>
        </div>
        <div className="d-flex h-100 fp-0 justify-content-end align-items-center col">
          {isEditMode ? (
            <>
              <SimpleNumberInput
                name="quantity"
                type="number"
                value={roundNumber(meal.quantity)}
                autoComplete="off"
                variant="outlined"
                required
                error={!!props.qtyError}
                onChange={handleQuantity}
                classes={{
                  root: clsx(classes.root , "flex-shrink-0 flex-grow-0"),
                }}
                InputProps={{
                  inputProps: {
                    'data-group': props['data-group'],
                    'data-itemindex': itemIndex,
                    min: 1,
                  },
                  classes: {
                    root: 'small height-40',
                    input: 'font_16_600 text-center',
                  },
                }}
                onBlur={handleBlur}

              />
              <IconButton className="fml-10" onClick={() => onDelete()}>
                <DeleteIcon style={{fill:"none"}} className="text-labels" />
              </IconButton>
            </>
          ) : (
            <>
              <Typography variant="subtitle2" className="fmr-5" style={{color:"#0D0D0D"}}>
                {meal.quantity}
              </Typography>
              {" "}<Typography variant="body2" style={{color:"#0D0D0D"}}>{servingItem && servingItem[1].value}</Typography>
            </>
          )}
        </div>
      </div>
      {!!selectedSupplement && selectedSupplement.id && 
      <Drawer anchor="right" open={true} onClose={onCloseFoodDetail}>
        <DialogAppBar title={"Supplement"} onClose={onCloseFoodDetail} titleFont='h3'/>
        <SupplementInfo id={selectedSupplement.id} key={selectedSupplement.id} isPreview={true} />
      </Drawer>
      }
    </>
  );
};

export default withSnackbar(Supplement);
