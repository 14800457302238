import React from "react";

const Stepper = (props) => (
  <>
    {/* -------round-ball--------- */}
    <div
      className={`rounded-circle bg-primary p-exact`}
      style={{
        width: "12px",
        height: "12px"
      }}
    >
      {"\u00A0"}
    </div>
    {/* -------round-ball end--------- */}
    <div
      className="h-100 p-exact"
      style={{
        width: "2px",
        backgroundColor: "#808080",
        opacity: 0.2
      }}
    >
      {"\u00A0"}
    </div>
  </>
);

export default Stepper;
