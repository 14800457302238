import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import { Card, CardContent, IconButton, Typography, makeStyles, Menu, MenuItem } from '@material-ui/core';
import moment from 'moment';
import _ from 'lodash';
import { ddt2str } from 'fitbud/views/plan/tabs/DetailsTab';
import { durationInTypes } from 'fitbud/views/schedulePlanner/helper';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { getState } from 'fitbud/views/users/helpers';

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 15,
  },
  cardContent: {
    padding: 20,
  },
}));

const PURCHASE_TYPE = {
  MEMBERSHIP: 'membership',
  SESSION: 'sessions',
};

const PurchaseCard = (props) => {
  const { data, allowEdit, openEdit, onDelete, userTz, allowDelete, userDoc } = props;
  const classes = useStyles();
  const accessType = _.get(data, 'accessType');
  const isMemberShip = accessType === PURCHASE_TYPE.MEMBERSHIP;
  const isSessionPack = accessType === PURCHASE_TYPE.SESSION;
  const [anchorEl, setAnchorEl] = useState(null);
  const openOptions = (e) => setAnchorEl(e.currentTarget);
  const closeOptions = () => setAnchorEl(null);
  const editDisable =  getState(userDoc) !== "active"; //use case: if aplan not assign, then api crashed.

  const edit = useMemo(() => {
    return !!allowEdit;
  }, [allowEdit, data]);

  const getTopLeft = () => {
    if (isMemberShip) return 'MEMBERSHIP PLAN';
    else if (isSessionPack) return 'SESSIONS PACK';
    return 'MEMBERSHIP PLAN';
  };
  const getTopRight = () => {
    if (isMemberShip) {
      if (data?.mode === 'one_time') {
        return (
          moment(data.dateFirst).tz(userTz).format('DD MMM') +
          ' - ' +
          moment(data.dateExpiry).tz(userTz).format('DD MMM YYYY')
        );
      } else if (data?.mode === 'subscription') {
        return `${data?.status === 'active' ? 'renews on' : 'ends on'} ${moment(data.dateExpiry)
          .tz(userTz)
          .format('DD MMM YYYY')}`;
      }
    } else if (isSessionPack) {
      return 'Validity - ' + moment(data.dateExpiry).tz(userTz).format('DD MMM YYYY');
    }
    return 'Lorem Ipsum';
  };
  const getBottomLeft = () => {
    if (isMemberShip) {
      return _.get(data, 'pack.title');
    } else if (isSessionPack) {
      return `${_.get(data, 'accessValueOrig')} Sessions`;
    }
    return 'Lorem Ipsum ';
  };
  const getBottomRight = () => {
    if (isMemberShip) {
      if (data?.mode === 'one_time') {
        const _d = durationInTypes(data.accessValue, data.durationType);
        return ddt2str(Math.round(_d), data.durationType);
      } else if (data?.mode === 'subscription') {
        return 'Subscription';
      }
    } else if (isSessionPack) {
      return (
        <span>
          <span>{_.get(data, 'accessValueOrig') - _.get(data, 'accessValue')}</span> /{' '}
          <span className="font_15_500">{_.get(data, 'accessValueOrig')}</span>
        </span>
      );
    }
    return 'Lorem Ipsum';
  };
  return (
    <Card elevation={1} className={clsx(classes.root, 'mb-15')}>
      <CardContent className={clsx(classes.cardContent, 'd-flex align-items-start')}>
        <div className="flex-grow-1">
          <div className="d-flex mb-10 justify-content-between">
            <Typography color="primary" className="font_13_600">
              {getTopLeft()}
            </Typography>
            <Typography className="text-dark-gray font_13_500">{getTopRight()}</Typography>
          </div>
          <div className={clsx('d-flex justify-content-between')}>
            <Typography className="font_15_600 text-truncate">{getBottomLeft()}</Typography>
            <Typography className="font_15_600 flex shrink-0 ml-10">{getBottomRight()}</Typography>
          </div>
        </div>
        {!!edit && (
          <IconButton disabled={editDisable} size="medium" edge="end" style={{ marginTop: '-8px' }} onClick={openOptions}>
            <MoreVertIcon style={{ fontSize: 20 }} />
          </IconButton>
        )}
        <Menu
          anchorEl={anchorEl}
          keepMounted
          elevation={4}
          getContentAnchorEl={null}
          open={Boolean(anchorEl)}
          onClose={closeOptions}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClick={closeOptions}>
          <MenuItem key="edit" onClick={openEdit}>
            Edit
          </MenuItem>
          {allowDelete && (
          <MenuItem key="delete" onClick={onDelete}>
            Delete
          </MenuItem>
          )}
        </Menu>
      </CardContent>
    </Card>
  );
};
export default PurchaseCard;
