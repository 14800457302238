import React, {useEffect, useState} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from '@material-ui/lab';
import Visibility from 'visibilityjs';
import Err from 'fitbud/components/loaders-errors';
import 'whatwg-fetch'; //polyfill for unsupported browsers.
import * as Sentry from "@sentry/browser";

const Maintenance = (props) => {
  return <Err error fill className='vh-100' message={
    <>Website is under maintenance<br/>Please try again later</>
  }/>;
};

const reload = (e) => {
  e.preventDefault();
  e.stopPropagation();
  window.location.reload();
};

const propAO = { vertical: 'top', horizontal: 'center' };
const propCA = {touchEvent: false, mouseEvent: false};
const NewVersion = (props) => {
  if (!props.versionChanged) return null;
  return (
    <Snackbar anchorOrigin={propAO} open onClose={reload} ClickAwayListenerProps={propCA}>
      <Alert severity='info' variant='filled' onClick={reload} className='cursor-pointer'>
        An improved version of the FitBudd dashboard is now available. Click here to upgrade.
      </Alert>
    </Snackbar>
  );
};

const checkVersion = (meta) => {
  if (!meta) return;

  const {version: curVer, hash: curHsh} = (window.__app_meta || {});
  const {version: newVer, hash: newHsh, maintenance = false} = meta;

  const versionChanged = !!window.__app_meta && (newVer !== curVer || newHsh !== curHsh);

  if (!window.__app_meta) // set existing meta
    window.__app_meta = meta;
  
  // Add hash to sentry
  Sentry.configureScope((scope) => {
    scope.setTag("commitHash", curHsh);
  });
  return {maintenance, versionChanged};
}

const fetchAndCheckVersion = (setMaintenance) => {
  return window.fetch(`/__app_meta.json?ts=${new Date().getTime()}`)
    .then(response => {
      if (!response.ok)
        throw new Error('__app_meta fetch failed');
      return response.json();
    })
    .then(checkVersion)
    .then(setMaintenance)
    .catch(e => console.error(e));
}

const VersionCheck = (props) => {
  const {children} = props;
  const [{maintenance, versionChanged}, setMaintenance] = useState({});
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') return;

    if (!window.__app_meta) // first load, fetch blindly
      fetchAndCheckVersion(setMaintenance);

    // every time user returns to the tab, fetch and check
    const listener = Visibility.change((e, state) => {
      if (state !== 'visible') return;
      fetchAndCheckVersion(setMaintenance);
    });
    return () => Visibility.unbind(listener);
  }, []);

  if (maintenance) return <Maintenance />;

  return (
    <>
      <NewVersion versionChanged={versionChanged}/>
      {children}
    </>
  );
}

export default VersionCheck;
