import React, { useState, useRef, useEffect, useContext } from "react";
import { TextField, Typography } from "@material-ui/core";
import * as Sentry from "@sentry/browser";
import { FormTextField } from "fitbud/components/form-fields";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import { makeStyles } from "@material-ui/core/styles";
import useVideoContext from "./useVideoContext";
import DraftEditor from "fitbud/views/appPages/draftEditor";
import firebase from "fitbud/firebase";

const useStyles = makeStyles(() => ({
  mainRoot: {
    zIndex: "5",
    gridArea: "1 / 3 / 1 / 3",
    overflowY: "hidden",
    position: "relative",
    background: "#fff",
  },
  root: {
    width: "100%",
    bottom: "0",
    "& .DraftEditor-editorContainer > .public-DraftEditor-content": {
      height: "72vh !important"
    }
  },
}));

const Notes = () => {
  const classes = useStyles();
  const { cid } = useContext(FirebaseAuthContext);
  const { notes, sessionData, addNote, noteValue } = useVideoContext();
  const [note, setNote] = useState({notes: noteValue.note});
  const editor = useRef(null);

  const debounceMethod = function (fn, delay) {
    let timer;
    return function () {
      const self = this,
        args = arguments;
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(self, args);
      }, delay);
    };
  };

  useEffect(() => {
      firebase.firestore().collection(`companies/${cid}/bookings`).doc(`${sessionData.book_id}`).get().then((resp) => {
        if (!!resp) {
          const data = resp.data()
          if(data) {
            setNote({notes: data.note})//update local state
            addNote({note: data.note})//update context api state
          }
        }
      })
      .catch(err => {
          console.log(err)
      });
  },[sessionData.book_id])

  const handleChange = async (name, content) => {
    const chng = { [name]: content };
    setNote({ ...note, ...chng });
    try {
      const docRef = firebase.firestore().collection(`companies/${cid}/bookings`).doc(`${sessionData.book_id}`);
      let out = { note: content };
      if(out.note) {
        await docRef.update(out);
        addNote(out); //value set in context API
      }
    } catch (err) {
      console.log(err);
      Sentry.captureException(err);
    }
  };

  const handleTCNPP = debounceMethod(handleChange, 300);

  return (
    <div className={classes.mainRoot}>
      <div className={classes.root} style={{ height: `${!!notes ? "100%" : ""}` }}>
        {!!notes && (
          <div className='fmt-10 fmx-20'>
            <Typography className='fmt-20 fmb-20' variant='body1'>
              Add Notes
            </Typography>
            <div className='d-flex'>
              <DraftEditor
                ref={editor}
                text={note.notes || ""}
                placeholder='Enter notes here...'
                handleTCNPP={handleTCNPP}
                name='notes'
                isNote={true}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Notes;
