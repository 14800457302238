import React, { useState, useEffect, useContext } from 'react';
import { FormControlLabel, Radio, RadioGroup, TextField, Divider, Typography } from '@material-ui/core';
import { useInput, usePicker, useValidators } from 'fitbud/hooks/form';
import ImageFileUpload from 'fitbud/components/imageSelector';
import { FormTextField } from 'fitbud/components/form-fields';
import clsx from 'clsx';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import _ from "lodash";

const INFO={
  regular:"Use Membership plans to offer your regular services with subscription or one-time pricing options. A client can have only one Membership plan active at a time.",
  add_on:"Use session packs to sell bundled 1:1 sessions or group classes. A client can purchase multiple session packs.",
  add_on_membership:"Use Add-ons to offer additional services to your clients. A client can purchase multiple Add-ons."
}

const getType = ({type,add_on_type}) => {
  if(!!type && type === 'add_on' && add_on_type==='membership')
    return 'add_on_membership';
  else return type || 'regular';

}

const BasicEditor = (props) => {
  const { fnRef, setDirty, data = {}, isNew } = props;
  const {comp, useNewPurchase} = useContext(FirebaseAuthContext);
  const {parsed: title, props: titleProps, isValid: tValid} = useInput(data.title, 'text *', { trim: true });
  const {parsed: ref_name, props: refProps, isValid: rValid} = useInput(data.ref_name, 'text *', { trim: true });
  const {parsed: description, props: descProps, isValid: dValid} = useInput(data.description, 'text *', { trim: true });
  const [type, setType] = usePicker(getType(data));
  const [image, setImage] = useState(data.image);
  const isValid = useValidators(tValid, rValid, dValid);
  const company = comp ? comp.data() : {};
  const isGroupClass = !!Boolean(_.get(company, "features.group_class.enabled", false));
  useEffect(() => {
    if (setDirty) setDirty({ title, ref_name, image, description, type });
    fnRef.current = (setState) => {
      if (!setState) return false;
      if (!isValid()) return false;
      setState(curr => ({...curr, title, ref_name, image: image || null, description, type}));
      const _state={...data};
      if(type==='regular'){
        _state['type']='regular';
        delete _state.add_on_type;
      }
      else if(type==='add_on_membership'){
        _state['type']='add_on';
        _state['add_on_type']='membership';
      }
      else if(type==='add_on' && !!isNew){
        _state['type']='add_on';
        _state['add_on_type']='one_to_one';
      }
      setState({
        ..._state,
        title,
        ref_name, 
        image: image || null, 
        description, 
      });
      return true;
    };
  }, [fnRef, isValid, setDirty, title, ref_name, image, description, type]);
  return (
    <div className='p-20 flex-1'>
     {isNew && useNewPurchase && <div>
      <RadioGroup value={type} style={{marginTop:"-4px", marginBottom:"11px"}} className={clsx('d-flex flex-row')} onChange={setType}>
          <FormControlLabel value={"regular"} control={<Radio color="primary" />} classes={{label:"font_16_500"}} className='mr-30 mb-0' label="Membership Plan" />
          <FormControlLabel value={"add_on_membership"} control={<Radio color="primary" />} classes={{label:"font_16_500"}} className='mr-30 mb-0' label="Add-ons" />
          {isGroupClass && <FormControlLabel value={"add_on"} control={<Radio color="primary" />} classes={{label:"font_16_500"}} className='mb-0' label={<span>Sessions Pack</span>} />}
        </RadioGroup>
        <div className='info-box mb-20'>
          <Typography className='font_14_500 text-0d0d0d'>
            {INFO[type]}
          </Typography>
        </div> 
       <Divider className='ml-n20 mr-n20 mb-25' />  
      </div>}
      <div className='d-flex fmb-20'>
        <div className="flex-1 fmr-20 d-flex flex-column justify-content-between">
          <FormTextField required fullWidth label='Display Name'>
            <TextField {...titleProps}
              autoComplete='off'
              variant='outlined'
              required
              type='text'
              InputProps={{ classes: { root: 'medium', input: 'size_16_500 medium' } }}
            />
          </FormTextField>
          <FormTextField required fullWidth label='Reference Name' classes={{control:"mb-0"}}>
            <TextField {...refProps}
              autoComplete='off'
              variant='outlined'
              required
              type='text'
              InputProps={{ classes: { root: 'medium', input: 'size_16_500 medium' } }}
            />
          </FormTextField>
        </div>
        <ImageFileUpload
          thumbnail={image}
          onChange={setImage}
          aspectRatio={3 / 2}
          size="medium"
        />
      </div>
      <FormTextField required fullWidth label='Description' classes={{control:"mb-0"}}>
        <TextField {...descProps}
          multiline
          minRows='4'
          maxRows='15'
          id='description'
          fullWidth
          variant='outlined'
          required
          InputProps={{ classes: { root: 'large', input: 'size_16_500' } }}
        />
      </FormTextField>
    </div>
  );
};


export default BasicEditor;
