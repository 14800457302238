import React from 'react';
//import MUIBaseline from '@material-ui/core/CssBaseline'; <-- FIXME starting without it, uncomment and include if needed
import MUITheme from 'fitbud/css/mui-theme';
import VersionCheck from './VersionCheck';
import Fitbud from 'fitbud';

function App() {
  return (
    <MUITheme>
      <VersionCheck>
        <Fitbud/>
      </VersionCheck>
    </MUITheme>
  );
}

export default App;
