import { Chip, makeStyles, MenuItem, OutlinedInput, Select, TextField, Typography } from '@material-ui/core';
import DropDownIcon from "@material-ui/icons/ExpandMore";
import firebase from 'firebase';
import CustomSlider from 'fitbud/components/customSlider';
import Dialog from 'fitbud/components/Dialog';
import { ClrdButton, FormTextField } from 'fitbud/components/form-fields';
import { Conversion } from 'fitbud/providers/conversion';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import appRdxFns from "fitbud/redux/app";
import { DEFAULT_ERROR, EXCL_QUES_TYPES } from 'fitbud/utils/constants';
import _, { truncate } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useContext, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

const styles = makeStyles(theme => ({
  chips: {
    display: 'flex',
    flexWrap: 'no-wrap',
    overflowX:'scroll',
    "::-webkit-scrollbar":{
      display: 'none'
    },
    overflowY:'hidden',
  },
  chip: {
    borderRadius: '40px',
    height: '30px',
    marginRight:'10px',
  },
  chipLabel:{
    fontSize: '13px',
    fontWeight: 500
  },
  select:{
    paddingRight: '40px',
    '&:focus':{
      backgroundColor:'white'
    },
  }
}));

const EditQuestionsDialog = ({
  open = false,
  onClose,
  userDoc = {},
  docId = '',
  updateUserDoc,
  ques,
}) => {
  const { onboarding_response } = userDoc;
  const d = useDispatch();
  const { convertor } = useContext(Conversion);
  const { comp } = useContext(FirebaseAuthContext)
  const { showLoader, hideLoader } = appRdxFns(d);
  const { enqueueSnackbar } = useSnackbar();
  const questions = useMemo(() => {
    let out = {};
    ques.forEach((q, i) => !!q.visible && (out[q.id]={...q}));
    return out;
  }, [ques]);

  // Local State Vars
  const [isDirty, setDirty] = useState(false);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({...onboarding_response});
  const validate = () => {
    let keys = Object.keys(data);
    keys.forEach((id) => {
      if(!data[id])  { setErrors({ [id]: 'Please enter a value' }); return false;} 
    });
    return true;
  };
  const handleChange = (e) => {
    setDirty(true);
    setErrors({});
    const { name, value } = e;
    setData({ ...data, [name]: value });
  };
  const handleSave = () => {
    if (!validate()) return;
    const out = {
      ...userDoc, onboarding_response: { ...data },
    };
    showLoader();
    firebase.firestore().doc(`user_profiles/${docId}`)
      .set({ ...out }, { merge: true })
      .then((res) => {
        enqueueSnackbar("Changes Saved Successfully", { variant: 'success' });
        updateUserDoc({ ...out });
        onClose();
      }).catch((err) => {
        console.log(err)
        enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
      }).finally(() => {
        hideLoader();
      });
  };

  return (
    <Dialog
      open={!!open} maxWidth='sm' fullWidth title='Edit Onboarding Questionnaire' titleFont="h3"
      appBarColor="bg-body"
      buttonColor="primary"
      buttonSize="f-small"
      toolbarClass="height-60"
      onClose={onClose}
      onSave={handleSave}
      isDirty={isDirty}
      withConfirmation
    >
      <div className='fpx-20 fpt-20'>
        {Object.keys(questions).map((id, i) => {
          const response = data[id]
          const question = questions[id];
          if(!question || EXCL_QUES_TYPES.includes(question.type) || !question.visible)
            return null;
          return (
            <QuestionResponseField 
              key={id+i} 
              id={id} 
              question={question} 
              response={response} 
              onChange={handleChange} 
              errors={errors}
              companyData={comp.data()}
              convertor={convertor}
            />
          )})}
      </div>
    </Dialog>
  )
}
const QuestionResponseField = ({ id, response, onChange, errors, question, companyData, convertor }) => {
  const { type, sub_type='', text: questionText, options } = question;
  const isAnswered = !!response;
  const handleChange = (e, param1, payload) => {
    const isMultiSelect = type === 'multi_select';
    e.preventDefault();
    let out;
    const { value='' } = e.target || {};
    out = [value];
    if(isMultiSelect && param1 !== 'deleteOpt'){const { value } = e.target;out = [...value];};
    if(isMultiSelect && param1 === 'deleteOpt') out = [...(response.filter(i => i !== payload))];
    if(type === 'scale'){
      out=[String(param1)];
    };
    if(type === 'single_select' || type === 'free_text'){
      if(sub_type === 'bmr_activity' || sub_type === 'bmr_goal' || sub_type === 'bmr_diet'){
        const { options } = _.get(companyData, `bmr.${sub_type}`, {});
        (!!options && !_.isEmpty(options) && options.forEach((opt) => opt.text === value ? out = [opt] : null));
      };
    };
    return onChange({ value: [...out], name: id });
  };
  const classes = styles();
  
  // SCALE TYPE
  if(type === 'scale'){
    const { min, max, step } = question;
    const marks = Array.from({length: step+1}, (_, i) => {
      if(i === 1){return ({value: 1, label: min})};
      if(i === step){return ({value: step, label: max})};
      return ({value: i, label: ''});
    });
    return (
      <div className='w-100 position-relative d-flex align-items-center'>
        {!!isAnswered && (
          <ClrdButton color='primary' className='position-absolute font_13_500' 
            style={{right: '0px', top: '-5px', zIndex: 1}} onClick={(e) => handleChange(e, 1)}>
            <Typography className='font_13_500 cursor-pointer'>
              Reset
            </Typography>
          </ClrdButton>
        )}
        <FormTextField labelVariant='subtitle1' fullWidth
          label={questionText + ` ${!!min & !!max ? (`(Rate between \u201C${min}\u201D and \u201C${max}\u201D)`) : '' }`}>
          <div className='fpt-20 fpb-10 fpx-40 border rounded position-relative'>
            <CustomSlider
              aria-labelledby={questionText}
              value={!!isAnswered ? Number(response[0]) : 0}
              marks={marks}
              onChange={handleChange}
              min={1}
              max={step}
            />
          </div>
        </FormTextField>
      </div>
    )
  };

  // SELECT TYPE
  if(type.includes('select')){
    const isMultiSelect = type === 'multi_select';
    const singleResponse = isAnswered ? response[0] : undefined;
    const { text: responseText } = singleResponse || {};
    return (
      <FormTextField labelVariant={'subtitle1'} fullWidth label={questionText}>
        <Select
          fullWidth
          displayEmpty
          multiple={isMultiSelect}
          value={isAnswered ? (sub_type.includes('bmr') ? responseText : isMultiSelect ? [...response] : singleResponse) : (isMultiSelect ? [] : '')}
          className='fmr-5'
          error={errors.height}
          onChange={handleChange}
          renderValue={isMultiSelect ? (selected) => (
            <div className={classes.chips + '  no-scroll-bar'}>
              {isAnswered ? selected.map((value) => (
                <Chip
                  onMouseDown={(event) => {
                    event.stopPropagation();
                  }}
                  id={value}
                  color="primary" 
                  variant='outlined' 
                  key={value}
                  label={truncate(value, 2)} 
                  className={classes.chip} 
                  onDelete={(e) => handleChange(e, 'deleteOpt', value)}
                  classes={{label: classes.chipLabel, iconColorPrimary: 'secondary', iconColorSecondary: 'textSecondary'}}
                />
              )) : <Typography color='textSecondary' variant='body1'>Select Option</Typography>}
            </div>
          ): null}
          classes={{ root: classes.select, icon: '' }}
          IconComponent={DropDownIcon}
          input={
            <OutlinedInput
              classes={{ 
                root: `medium`, 
                input: "size_15_500 select-medium fpr-40" 
              }} 
              name={id}
            />
          }
        >
          <MenuItem disabled value={''}><div className='text-grey'>Select Option</div></MenuItem>
          {options.map((opt, i) => {
            const isSelected = isAnswered ?
            (sub_type.includes('bmr') ? opt === responseText  : isMultiSelect ? [...response].includes(opt) : opt === singleResponse) 
              : false;
            return <MenuItem selected={isSelected} value={opt} key={opt+i}>{opt}</MenuItem>
          })}
        </Select>
      </FormTextField>
    )
  }

  // FREE TEXT
  return (
    <FormTextField 
      labelVariant={'subtitle1'} fullWidth
      label={question.text}>
      <TextField
        id={id}
        placeholder='Enter text . . .'
        name={id}
        autoComplete="off"
        onKeyDown={e => {
          if (e.key === ".") {
            e.preventDefault();
          }
        }}
        multiline
        minRows={2}
        maxRows={2}
        value={response || ''}
        onChange={handleChange}
        error={!!errors.id}
        helperText={errors.id}
        variant="outlined"
        type="text"
        InputProps={{
          classes: {
            input: "size_15_500",
          },
        }}
      />
    </FormTextField>
  )
}
export default EditQuestionsDialog;
