import axios from 'axios';

const client = axios.create({
  baseURL: 'https://d1amt628qtdjrn.cloudfront.net',
  validateStatus: function (status) {
    return (status >= 200 && status < 300) || status === 404 || status === 403; // default
  },
});

export const packs = async (cid) => {
  return await client.get(`/outbox/${cid}/packs.json`).then(resp => resp.data);
};
