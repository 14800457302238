import firebase from "fitbud/firebase";
import {DRAFT_STATES,PUBLISHED,DRAFT,FILE_STATUS} from "fitbud/utils/constants";
import _ from 'lodash';

/* create: ⬇︎
  In exercises,recipe that support media upload in create action,
  firebase id is pre-created to create video-Object.
  They send createNewId=false and 'data.docIdx' is to be used as fb document id
*/

const builder = (cid, collection,draftFeature=false) => ({
  doc: async (id, nqSnack = null, draft=false) => {
    try {
      //TODO: Needs to be tested to avoid crash, saves api call
      //if(draft && !draftFeature) return null;
      const _collection = draft ? `draft-${collection}` : collection;
      const docRef = firebase
        .firestore()
        .collection(`companies/${cid}/${_collection}`)
        .doc(id);
      return await docRef.get();
    } catch (err) {
      if (!!nqSnack) {
        nqSnack(err.message, { variant: "error" });
        return null;
      }
      throw err;
    }
  },
  fetch: async (nqSnack = null) => {
    try {
      const snapshot = await firebase
        .firestore()
        .collection(`companies/${cid}/${collection}`)
        .get();
      return snapshot.docs;
    } catch (err) {
      if (!!nqSnack) {
        nqSnack(err.message, { variant: "error" });
        return [];
      }
      throw err;
    }
  },
  create: async (data, nqSnack = null,createNewId=true) => {
    //* create ⬆︎
    try {
      const collectionRef = firebase
        .firestore()
        .collection(`companies/${cid}/${collection}`);
      const docRef=(data.docIdx && !createNewId)?collectionRef.doc(data.docIdx):collectionRef.doc();
      delete data.docIdx;
      const draftRef = firebase
        .firestore()
        .collection(`companies/${cid}/draft-${collection}`)
        .doc(docRef.id);
      await firebase.firestore().runTransaction(async (t) => {
        let promises = [];
        const _publish_status= draftFeature?DRAFT_STATES['DRAFT_ONLY']:DRAFT_STATES['PUBLISHED'];
        promises.push(t.set(docRef, { ...data,publish_status:_publish_status }));
        if(draftFeature && _publish_status!==DRAFT_STATES['PUBLISHED']){
          promises.push(t.set(draftRef, { ...data,publish_status:DRAFT_STATES['DRAFT_ONLY'] }));
        }
        await Promise.all(promises);
      });
      return await docRef.get();
    } catch (err) {
      if (!!nqSnack) {
        nqSnack(err.message, { variant: "error" });
        return null;
      }
      throw err;
    }
  },
  update: async (id, data, nqSnack = null, updateType = PUBLISHED) => {
    try {
      if (updateType === DRAFT) {
        // delete data.to_publish;
        // const draftRef = firebase
        //   .firestore()
        //   .collection(`companies/${cid}/draft-${collection}`)
        //   .doc(id);
        // const docRef = firebase
        //   .firestore()
        //   .collection(`companies/${cid}/${collection}`)
        //   .doc(id);
        // const newPublishStatus = (!data.publish_status||data.publish_status===DRAFT_STATES['PUBLISHED'])
        //   ? DRAFT_STATES["DRAFT_ASWELL"]
        //   : data.publish_status;
        // await firebase.firestore().runTransaction(async (t) => {
        //   let promises = [];
        //   promises.push(t.set(draftRef, { ...data, publish_status: newPublishStatus }));
        //   promises.push(t.update(docRef, { publish_status: newPublishStatus }));
        //   await Promise.all(promises);
        // });
        // return await draftRef.get();
      } else {
        const docRef = firebase
          .firestore()
          .collection(`companies/${cid}/${collection}`)
          .doc(id);
        // if(!!draftFeature){
        //   const draftRef = firebase
        //   .firestore()
        //   .collection(`companies/${cid}/draft-${collection}`)
        //   .doc(id);
        //   const isDraft=get(data, "publish_status", PUBLISHED).includes("draft");
        //   const mediaUploadStatus=[FILE_STATUS["processing"],FILE_STATUS['uploading']];
        //   const isMediaUploading=mediaUploadStatus.includes(get(data,'media.0.status',''))||mediaUploadStatus.includes(get(data,'media_bgoff.status',''));
        //   const to_publish=data.to_publish;
        //   delete data.to_publish;
        //   if (isDraft && isMediaUploading && !to_publish) {
        //     let returnObj
        //     await firebase.firestore().runTransaction(async (t) => {
        //       //get latest draft doc
        //       const latestDraft = await (await t.get(draftRef)).data();
        //       const primMedia = get(latestDraft, "media.0", {});
        //       const secMedia = get(latestDraft, "media_bgoff", {});
        //       if (
        //         mediaUploadStatus.includes(primMedia.status)||
        //         mediaUploadStatus.includes(secMedia.status)
        //       ) {
        //         //set to_publish flag
        //         await t.update(draftRef, {
        //           to_publish: true,
        //         });
        //         returnObj= draftRef
        //       } else {
        //         //upload completed /upload failed
        //         delete latestDraft.to_publish;
        //         const publishedDoc = {
        //           ...latestDraft,
        //           publish_status: DRAFT_STATES["PUBLISHED"],
        //         };
        //         let promises=[];
        //         promises.push(t.set(docRef, {...publishedDoc}));
        //         promises.push(t.set(draftRef, {...latestDraft}))
        //         await Promise.all(promises);
        //         returnObj=docRef;
        //       }
        //     });
        //     return await returnObj.get();
        //   }
        // }
        const isPrimMediaUploading=isMediaUploading(data,undefined,collection);
        const isSecMediaUploading=isMediaUploading(data,true,collection);
        if(isPrimMediaUploading || isSecMediaUploading){
          await firebase.firestore().runTransaction(async (t)=>{
            const latestDoc=await (await t.get(docRef)).data();
            if(isPrimMediaUploading && isSameVideoId(data,latestDoc,undefined,collection)){
              data.media=_.cloneDeep(latestDoc.media);
            }
            if(isSecMediaUploading && isSameVideoId(data,latestDoc,true,collection)){
              //WIP:sanitize hardcoding 
              if(collection==='exercises'){
                data.media_tutorial=_.cloneDeep(latestDoc.media_tutorial);
              }
              else{
                data.media_bgoff=_.cloneDeep(latestDoc.media_bgoff);
              }
            }
            await t.set(docRef,{...data});
          });
        }
        else{
          await docRef.update({
            ...data,
            publish_status: DRAFT_STATES["PUBLISHED"],
          });
        }
        return await docRef.get();
      }
    } catch (err) {
      if (!!nqSnack) {
        nqSnack(err.message, { variant: "error" });
        return null;
      }
      throw err;
    }  
  },
  createCID: async (id, data, nqSnack = null) => {
    try {
      const docRef = await firebase
        .firestore()
        .collection(`companies/${cid}/${collection}`)
        .doc(id);
      await docRef.set(data);
      return await docRef.get();
    } catch (err) {
      if (!!nqSnack) {
        nqSnack(err.message, { variant: "error" });
        return null;
      }
      throw err;
    }
  },
  deleteDraft: async (id, nqSnack = null, publish_status = "") => {
    try {
        const docRef = firebase
          .firestore()
          .collection(`companies/${cid}/${collection}`)
          .doc(id);
        const draftRef = firebase
          .firestore()
          .collection(`companies/${cid}/draft-${collection}`)
          .doc(id);
        await firebase.firestore().runTransaction(async (t) => {
          let promises = [];
          const docPayload=publish_status === DRAFT_STATES["DRAFT_ONLY"]?
            {archive:true}:{ publish_status: DRAFT_STATES["PUBLISHED"] };
          promises.push(t.update(docRef, docPayload));
          promises.push(t.update(draftRef, { archive: true, to_publish:false }));//kill media pipeline if any
          await Promise.all(promises);
        });
      return docRef.get();
    } catch (err) {
      if (!!nqSnack) {
        nqSnack(err.message, { variant: "error" });
        return null;
      }
      throw err;
    }
  },
  delete: async(id,nqSnack)=>{
    try{
      const docRef = firebase
      .firestore()
      .collection(`companies/${cid}/${collection}`)
      .doc(id);
    await docRef.update({
      archive: true
    });
    return await docRef.get();
    }
    catch(err){
      if (!!nqSnack) {
        nqSnack(err.message, { variant: "error" });
        return null;
      }
      throw err;
    }
  },
  stopPublishPipeline:async (id,nqSnack)=>{
    try{
      const docRef = firebase
      .firestore()
      .collection(`companies/${cid}/draft-${collection}`)
      .doc(id);
      await docRef.update({
        to_publish:false
      });
      return await docRef.get();
    }
    catch(err){
      if (!!nqSnack) {
        nqSnack(err.message, { variant: "error" });
        return null;
      }
      throw err;
    }
  }
});

export default builder;

const isMediaUploading=(doc,media_bgoff=false,collection)=>{
  let media=_.get(doc,'media.0',{})||{};
  if(!!media_bgoff){
    //WIP:sanitize hardcoding 
    media=collection==="exercises"?_.get(doc,'media_tutorial',{})||{}:_.get(doc,'media_bgoff',{})||{};
  }
  return  media.status===FILE_STATUS.processing||media.status===FILE_STATUS.uploading;
}

const isSameVideoId=(updateDoc={},latestDoc={},media_bgoff=false,collection)=>{
  //WIP:sanitize hardcoding 
  const mediaPath=!media_bgoff?"media.0.videoId":(collection==='exercises'?'media_tutorial.videoId':"media_bgoff.videoId");
  const updateMedia=_.get(updateDoc,mediaPath);
  const latestMedia=_.get(latestDoc,mediaPath);
  return updateMedia && latestMedia && updateMedia===latestMedia;
}
