import {DRAFT_STATES,PUBLISHED,FILE_STATUS} from "fitbud/utils/constants";
import imgIndicator from 'fitbud/images/imgIndicator.svg';
import videoIndicator from 'fitbud/images/videoIndicator.svg';
import errIndicator from 'fitbud/images/errIndicator.svg';
import _ from 'lodash';

export const calculatePublishStatus = (isNew,draftFeature,oldDoc,docType) => {
    if (isNew && !!draftFeature) return DRAFT_STATES["DRAFT_ONLY"];
    else {
      if (docType === PUBLISHED) return DRAFT_STATES["PUBLISHED"];
      if (!oldDoc.publish_status||(!!oldDoc.publish_status && oldDoc.publish_status===DRAFT_STATES["PUBLISHED"])) return DRAFT_STATES["DRAFT_ASWELL"];
      else return oldDoc.publish_status;
    }
  };

  export const checkErrInMediaUpload=(mediaObj={},files=[])=>{
    if(!mediaObj) mediaObj={};
    let flag=0;
    const fileFoundInPipeline=files.find(i=>i.id===mediaObj.videoId)||null;
    if(mediaObj.status===FILE_STATUS.error){
      flag=1;
      return flag;
    }
    if(mediaObj.status===FILE_STATUS.uploading){
      if(fileFoundInPipeline){
        if(fileFoundInPipeline.status===FILE_STATUS.error){
          flag=1;
          return flag;
        }
      }
      else{
        flag=1;
        return flag;
      }
    }
    if(mediaObj.status===FILE_STATUS.processing){
      if(!fileFoundInPipeline){
        if(!mediaObj.videoId){
          flag=1;
          return flag;
        }
      }
    }
    return flag;
  }

  export const getExIcon=({type,media})=>{
    if(!type && media){
      const {status,type:mediaType}=_.get(media,"0",{});
      if(!status) return null;
      if(status==="complete" || status==="COMPLETE"){
        type=mediaType
      }
      else type="ERROR";
    }
    switch(type){
      case "image":return imgIndicator;
      case "video":return videoIndicator;
      case "processing":
      case "PROCESSING":
      case "error":
      case "ERROR": return errIndicator;
      default: return null;
    }
  }