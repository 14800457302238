import React, { useMemo } from 'react';
import { FormTextField } from 'fitbud/components/form-fields';
import { Select, MenuItem, OutlinedInput } from '@material-ui/core';
import DropDownIcon from '@material-ui/icons/ExpandMore';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';

const useClasses = makeStyles(() => ({
  outLineInput: {
    paddingRight: '58px !important',
  },
  endAdornment: {
    position: 'absolute',
    right: '35px',
    marginRight: 0,
  },
}));

const AltWo = (props) => {
  const {altWo, alt_id, handleChange, loading } = props;
  const classes = useClasses();
  let [list, map] = useMemo(() => {
    if (!altWo) return [false, false];
    const entries = Object.entries(altWo).sort((a, b) => {
      return (a[1].index || 0) - (b[1].index || 0);
    });
    if (!entries.length) return [false, false];
    const l = [], m = {};
    entries.forEach(([key, obj]) => {
      const out = { alt_id: key, alt_name_locales: {} };
      for (const [key, value] of Object.entries(obj)) {
        if (key === 'value') out.alt_name = value;
        else if (key === 'color') out.alt_color = value;
        else if (key.startsWith('value_'))
          out.alt_name_locales[key.replace('value_', '')] = value;
      }
      l.push(out);
      m[key] = out;
    });
    return l.length ? [l, m] : [false, false];
  }, [altWo]);

  const _handleChange = (e) => {
    let v = e.target.value;
    const selectedOp = map[v];
    if (!v || !selectedOp) {
      handleChange({ alt_id: null, alt_name: null, alt_color: null, alt_name_locales: null });
      return;
    }
    handleChange(selectedOp);
  };

  if (!list) return null;
  return (
    <FormTextField
      info="Alternate name and color is used in the mobile app wherever this workout is used as an alternative to another workout"
      fullWidth
      label="Alternate Name"
      classes={{ control: 'ml-20' }}
    >
      <Select
        displayEmpty
        fullWidth
        IconComponent={DropDownIcon}
        input={
          <OutlinedInput
            classes={{
              root: 'medium pr-0',
              input: clsx('size_16_500 select-medium ', classes.outLineInput),
            }}
          />
        }
        value={alt_id || ''}
        onChange={_handleChange}
        disabled={loading}
        required
      >
        <MenuItem value="">None</MenuItem>
        {list.map(x => {
          return (
            <MenuItem key={x.alt_id} value={x.alt_id}>
              <span className="mr-10">{x.alt_name}</span>
            </MenuItem>
          );
        })}
      </Select>
    </FormTextField>
  );
};
export default AltWo;
