import React, { useCallback, useContext, useMemo, useState } from 'react';
import _ from 'lodash';
import { servicesRdxFn } from 'fitbud/redux/exports';
import { useDispatch, useSelector } from 'react-redux';
import firebase from 'firebase';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { snapshotDocsArrayToDataArray } from 'fitbud/utils/helpers';

export const SERVICE_CONTEXT = React.createContext({});

export const ServiceProvider = (props) => {
  const dispatch = useDispatch();
  const { cid } = useContext(FirebaseAuthContext);
  const servicesRdxOptions = servicesRdxFn(dispatch);
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const { docs = [] } = useSelector((s) => s.services);

  const fetchServices = async (refresh) => {
    //either refreshing or loading for first time.
    const ref = firebase.firestore().collection(`/companies/${cid}/services`).where("archive", "==", false);
    if (!refresh && (docs || []).length) return; //if loading and data presents then do nothing.
    const _loader = refresh ? setRefreshing : setLoading;
    _loader(true);
    const snap = await ref.get();
    let services = [], legacy = [];
    if (!snap.size) services = [];
    else {
      snapshotDocsArrayToDataArray(snap.docs).forEach(x => {
        if (x.data.legacy) legacy.push({_id: x._id, duration: x.data.duration, status: x.data.status});
        else services.push(x);
      });
    }
    servicesRdxOptions.set(services.sort((a, b) => {
      const out = a.data.index - b.data.index;
      if (out === 0) return a.data.ref_name.localeCompare(b.data.ref_name);
      return out;
    }), 0, true);
    servicesRdxOptions.legacy(legacy.sort((a, b) => (a.duration - b.duration)));
    _loader(false);
  };

  const loadServices = async () => await fetchServices(false); //loading
  const refreshServices = async () => await fetchServices(true); //refreshing.

  const getServiceById = useCallback((id) =>{
    if(!id || !docs || !docs.length) return undefined;
    const find = _.find(docs,['_id', id]);
    return find;
  },[docs])

  const _value = useMemo(() => {
    return {
      loading,
      refreshing,
      services: docs,
      loadServices,
      refreshServices,
      getServiceById
    };
  }, [loading, docs, refreshing]);
  return <SERVICE_CONTEXT.Provider value={_value}>{props.children}</SERVICE_CONTEXT.Provider>;
};

export const useServices = () => React.useContext(SERVICE_CONTEXT);





/*

 1 . slot conflicts.
 2. default value for service and location

*/
