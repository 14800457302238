import React, { useContext, useState, useReducer, useMemo, useEffect } from 'react';
import update from 'immutability-helper';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { TagsContext } from 'fitbud/providers/tagsProvider';
import { getDefaultReminder, DEFAULT_REMINDER_TAG, DEFAULT_NUTRITION_CONFIG } from './helper';
import { DEFAULT_ERROR } from 'fitbud/utils/constants';
import appRdxFns from 'fitbud/redux/app';
import { useDispatch } from 'react-redux';
import firebase from 'fitbud/firebase';
import { getReminderTags } from 'fitbud/utils/services';

const ReminderContext = React.createContext({});

export const useReminder = () => useContext(ReminderContext);

const reducer = (os, ns) => ({ ...os, ...ns });

const ReminderProvider = (props) => {
  const d = useDispatch();
  const { data: propReminders, type, saveToDB, checkInConfig, savePreferenceToDb, preference : propPreference } = props;
  const [loading, setLoading] = useState(false);
  const [reminder_tag_config, setReminderTagConfig] = useState({});
  const { cid } = useContext(FirebaseAuthContext);
  const [reminders, setReminders] = useReducer(reducer, {}); //state for editor
  const [preference, setPreference] = useState({});
  const [readOnlyReminders, setReadOnlyReminders] = useState({});
  const [dirty, setDirty] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { mealCategories, fetchTags } = useContext(TagsContext);
  const { showLoader, hideLoader } = appRdxFns(d);
  const [edit, setEdit] = useState('');
  const isUser = type === 'user';
  const {
    measurements: measurement_tag_config,
    water: water_tag_config,
    nutrition: nutrition_tag_config,
    fitness: fitness_tag_config,
    time_picker_step,
    checkins: check_in_tag_config
  } = reminder_tag_config || {};

  const { allMeals, mlNames, defaultMealCategories } = useMemo(() => {
    const allMeals = {};
    const mlNames = {};
    const defaultMealCategories = {};
    (mealCategories || []).forEach((ml) => {
      const key = ml[0],
        mld = ml[1];
      allMeals[key] = mld;
      mlNames[key] = mld.value;
      if(mld.isDefault){
        defaultMealCategories[key] = mld.time || _.get(reminder_tag_config, "nutrition.default_time") || DEFAULT_NUTRITION_CONFIG.default_time
      }
    });

    return { allMeals, mlNames, defaultMealCategories };
  }, [mealCategories, reminder_tag_config]);

  //fetch mealTags
  useEffect(() => {
    fetchTags('mealCategories');
  }, []);

  //load reminder
  useEffect(() => {
    fetchReminders();
  }, []);

  const showError = (error) => {
    enqueueSnackbar(error || DEFAULT_ERROR, { variant: 'error' });
  };

  const fetchRemindersTag = async () => {
    const tags = await getReminderTags();
    const out = { ...DEFAULT_REMINDER_TAG, ...tags };
    setReminderTagConfig(out);
    return out;
  };

  const fetchReminders = async () => {
    if (isUser) {
      //if it is case of users then reminders data will come from props;
      setLoading(true);
      await fetchRemindersTag();
      const defaultReminders = getDefaultReminder(reminder_tag_config);
      const outReminders = {...defaultReminders, ...propReminders }
      setReminders(outReminders);
      setReadOnlyReminders(outReminders);
      setPreference({...propPreference})
      setLoading(false);
    } else {
      try {
        setLoading(true);
        const ref = firebase.firestore().doc(`/companies/${cid}/misc/app-reminders`);
        const reminder_tag_config = await fetchRemindersTag();
        const defaultReminders = getDefaultReminder(reminder_tag_config);
        const snap = await ref.get();
        const res = snap.data();
        const {company_config, company_prefs = {}} = res || {};
        const data = { ...defaultReminders, ...company_config };
        setReminders(data);
        setPreference({...company_prefs})
        setReadOnlyReminders(data);
        setLoading(false);
      } catch (err) {
        showError();
        setLoading(false);
      }
    }
  };
  const markDirty = (value = true) => setDirty(value);

  const toggleCategory = async (item, componentCb) => {
    let out = _.clone(preference);
    const lastEnabled = _.get(out, `${item}.enabled`, false);
     out = update(out, {
      [item]: foo => { 
        return  update(foo || {}, {
          enabled : {
            $set : !lastEnabled
          }
        })
      }
    });
    try {
      showLoader();
      const cb = () => {
        setPreference(out);
        componentCb && componentCb();
      };
      await publishPreference(out, cb, item);
      hideLoader();
    } catch (err) {
      showError();
    }
  };

  const onSave = async (key) => {
    const cb = () => {
      //on final save, reset dirty and read only and editor state same;
      markDirty(false);
      setReadOnlyReminders(reminders);
      setEdit('');
    };
    publishReminder(reminders, cb, key);
  };

  const closeEdit = () => {
    setEdit(false);
    setReminders(readOnlyReminders); //editor state updated with read only;
    markDirty(false);
  };

  const publishReminder = async (reminders, cb, key) => {
    try {
      showLoader();
      await saveToDB(reminders, key, {check_in_tag_config}); //user's reminder will only save updated key only
      cb && cb();
    } catch (err) {
      console.log(">> Error in saving reminder",err)
      showError();
    }
    hideLoader();
  };

  const publishPreference = async (preference, cb, key) =>{
    try {
      showLoader();
      await savePreferenceToDb(preference, key); //user's reminder will only save updated key only
      cb && cb();
    } catch (err) {
      showError();
    }
    hideLoader();
  }

  const setMealTime = (key, value) => {
    const out = update(reminders, {
      nutrition: {
        categories: {
          [key]: {
            $set: value,
          },
        },
      },
    });
    setReminders(out);
    markDirty();
  };

  const setMealCategories = (value, dirty = true) => {
    const out = update(reminders, {
      nutrition: {
        categories: {
          $set: value,
        },
      },
    });
    setReminders(out);
    if(dirty) markDirty();
  }

  const handleMealCheck = (key, value) => {
    if (!!value) {
      const default_time = _.get(nutrition_tag_config, 'default_time');
      setMealTime(key, default_time);
    } else {
      const out = update(reminders, {
        nutrition: {
          categories: {
            $unset: [key],
          },
        },
      });
      setReminders(out);
      markDirty();
    }
  };

  const setFitnessDayTime = (day, time) => {
    const out = update(reminders, {
      fitness: {
        days: {
          [day]: {
            $set: time,
          },
        },
      },
    });
    setReminders(out);
    markDirty();
  };

  const setConfigKey = (configKey, key, value) => { //used to store key value pair, if key is not available then save whole config_key.
    let out; 
    if(!key){
      out = update(reminders, {
        [configKey]: {
          $set:{...value} //generally accept here full object.
        },
      });

    }else{
      out = update(reminders, {
        [configKey]: {
          [key]: {
            $set: value,
          },
        },
      });
  
    }
    setReminders(out);
    markDirty();
  };

  const getCategoryEnabled = (key) => {
    return _.get(reminders, `${key}.enabled`, false);
  };

  //helper functions regarding default tag config:

  const getMeasurementRccOptions = () => {
    return _.get(measurement_tag_config, 'recurrence', []);
  };

  const getMeasurementRccOptById = (id) => {
    const options = getMeasurementRccOptions();
    return _.find(options, (op) => op.id === id) || {};
  };

  const getCheckInsRccOptions = () =>{
    return _.get(check_in_tag_config, 'recurrence', []);
  };

  const getCheckInRccOptById = (id) =>{
    const options = getCheckInsRccOptions();
    return _.find(options, (op) => op.id === id) || {};
  }

  return (
    <ReminderContext.Provider
      value={{
        //value
        dirty,
        loading,
        readOnlyReminders, //reminders value for readonly detail value
        reminders, // reminders value for editor
        allMeals, //default all meal categories like : {id: and values}
        mlNames, //ml categories names map eg: {id:name},
        defaultMealCategories,
        edit, //open edit dialog value
        checkInConfig, //check In config
        //default tags options
        water_tag_config,
        fitness_tag_config,
        time_picker_step,
        preference, //preference for enable disabled reminder section
        reminder_tag_config, //reminder tag config
        //handlers
        setConfigKey, // for set reminder config key value like nutrition->key->value
        setFitnessDayTime, // for set time for days in fitness like , fitness ->days-> monday ->time,
        handleMealCheck, //enabled and disabled meal categories
        setMealTime, //set time for meal categories eg, (key,value),
        onSave, //final save of reminders
        setEdit, // open edit dialog for categories.
        closeEdit, //for manually close editor dialog
        getCategoryEnabled, //for to get value  enable/disable category
        toggleCategory, //methods for enable and disable actions for category
        //Hepper methods options
        getMeasurementRccOptions, //method for getting all measurement options
        getMeasurementRccOptById, //get measurement mode by id
        getCheckInsRccOptions, //method for get all checking mode options
        getCheckInRccOptById,  //get check in mode by id .
        nutrition_tag_config,
        setMealCategories
      }}
    >
      {props.children}
    </ReminderContext.Provider>
  );
};

export default ReminderProvider;
