import React, { createContext, useContext, useCallback, useState } from 'react';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { getTags } from 'fitbud/utils/services.js';
import _ from "lodash";

const TagsContext = createContext(undefined);


function TagsProvider({ children }) {
  const { comp } = useContext(FirebaseAuthContext);
  const [maps, setMaps] = useState({});
  const [tags, setTags] = useState({});
  const fetchTags = useCallback((key, {callback, source} = {}) => {
    if (tags[key] && tags[key].length){
      callback && callback();
      return tags[key];
    }

    getTags(key, source, comp).then(data => {
      if (!data){
        callback && callback({notFound:true});
        setMaps(x => ({ ...x, [key]: {} }));
        setTags(x => ({ ...x, [key]: [] }));
        return; 
      }
      updateTags(key, data);
      callback && callback({success:true});
    }).catch(err => {
      console.error(err);
      callback && callback({error:true});
      setMaps(x => ({ ...x, [key]: {} }));
      setTags(x => ({ ...x, [key]: [] }));
    });
  }, [tags, comp]);

  const tagName = useCallback((key, id, fallback = '') => {
    return _.get(maps, [key, id, 'value']) || fallback;
  }, [maps]);

  const updateTags = (key, data) => {
    setMaps(x => ({ ...x, [key]: data }));
    const sorted = _.chain(data).toPairs().sortBy(['1.order', '1.index', '1.default_priority']).value();
    setTags(x => ({ ...x, [key]: sorted}));
  };

  return <TagsContext.Provider value={{ ...tags, maps, fetchTags, tagName, updateTags }}>{children}</TagsContext.Provider>;
}

export { TagsProvider, TagsContext };
