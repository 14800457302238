import React from 'react';

const FileUploadingIcon = ({ className }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9999 2.4165C11.7331 2.4165 11.4672 2.50326 11.2676 2.69997L7.21497 6.74122C6.81741 7.13767 6.8185 7.78196 7.21741 8.17705V8.17705C7.61037 8.56625 8.24299 8.56794 8.63803 8.18085L10.9814 5.88465V16.5393C10.9814 17.0962 11.4377 17.5481 11.9999 17.5481C12.5621 17.5481 13.0184 17.0962 13.0184 16.5393V5.88465L15.3618 8.18085C15.7568 8.56794 16.3895 8.56625 16.7824 8.17705V8.17705C17.1813 7.78196 17.1824 7.13767 16.7849 6.74123L12.7322 2.69997C12.5326 2.50326 12.2668 2.4165 11.9999 2.4165ZM3.85177 14.5218C3.28955 14.5218 2.83325 14.9737 2.83325 15.5305V17.5481C2.83325 19.7765 4.65742 21.5832 6.90733 21.5832H17.0925C19.3424 21.5832 21.1666 19.7765 21.1666 17.5481V15.5305C21.1666 14.9737 20.7103 14.5218 20.1481 14.5218C19.5858 14.5218 19.1295 14.9737 19.1295 15.5305V17.5481C19.1295 18.6628 18.218 19.5656 17.0925 19.5656H6.90733C5.78186 19.5656 4.87029 18.6628 4.87029 17.5481V15.5305C4.87029 14.9737 4.41399 14.5218 3.85177 14.5218Z"
        fill="white"
      />
    </svg>
  );
};

export default FileUploadingIcon;
