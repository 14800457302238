import React from 'react';
import { Typography, Fade } from '@material-ui/core';
import NoResultFound from 'fitbud/components/catalog/NoResultFound';
import { getLabel, getNoItemSvg } from 'fitbud/components/catalog/util';
import clsx from 'clsx';
import { ClrdButton } from '../form-fields';

export const Placeholder = ({ keyName, isSearch = false,initialSearchLabel, initialSearchAction, initialSearchSubLabel, noResultFoundAction ,initialSearch = false,className, noResultLabel, ...rest }) => {
  const getRender = () => {
    if (!!initialSearch)
      return (
        <NoResultFound
          label={initialSearchLabel || `Search ${getLabel(keyName)} by name`}
          subLabel={initialSearchSubLabel}
          subLabelClassName="font_13_500 text-dark-grey mt-12"
          {...rest}
          noResultFoundAction={initialSearchAction}
        />
      );
    else if (!!isSearch) return <NoResultFound label={noResultLabel} noResultFoundAction={noResultFoundAction} {...rest} />;
    else if (!isSearch)
    {
      const _itemLabel = getLabel(keyName); 
      const _img = getNoItemSvg(keyName);
      const _label = `No ${_itemLabel} added`;
      const _subLabel = `Click on ‘+’ to add ${_itemLabel}`;
      return <PlaceholderContent labelClassName="font_18_600" subLabelClassName="font_14_500" img={_img} label={_label} subLabel={_subLabel} />
    }
    return null;
  };

  return (
    <Fade in {...{ timeout: 400 }}>
      <div
        className={clsx("text-center d-flex align-items-center justify-content-center flex-column position-absolute",className)}
        style={{
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
          zIndex:500
        }}
      >
        {getRender()}
      </div>
    </Fade>
  );
};

export const PlaceholderContent = (props) => {
  const { img, label, subLabel, className, labelClassName, subLabelClassName, imgClassName,
    subLabelWrap=false, labelMarginClass='fmb-5',
  action,actionProps={} } = props;
  return (
    <div className={className}>
      {img && (
        <div className=" fmb-30 d-flex justify-content-center">
          <img alt="noitem" src={img} className={imgClassName}/>
        </div>
      )}
      {label && <Typography className={clsx("text-capitalize",labelMarginClass,labelClassName)}>{label}</Typography>}
      {subLabel && <Typography className={clsx(subLabelClassName,subLabelWrap?'text-wrap':'text-nowrap', "font_14_500 text-dark-grey")}>{subLabel}</Typography>}
      {Object.keys(actionProps) && <ClrdButton {...actionProps}/>}
    </div>
  );
};
