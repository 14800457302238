import React from 'react';

export const FLibIcon = React.forwardRef((props, ref) => {
  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <rect x="0.75" y="0.75" width="14.5" height="14.5" rx="2.25" stroke="#65768C" stroke-width="1.5"/>
    <path d="M11 5.48571V4H5V12H6.83743V9.08571H10.5123V7.6H6.83743V5.48571H11Z" fill="#65768C"/>
  </svg>;

});

export const CustomLibIcon = React.forwardRef((props, ref) => {
  return (
    <svg width="16" height="16" ref={ref} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g opacity="0.8">
        <rect width="16" height="16" rx="3" fill="#37404D" />
        <path
          d="M8.50168 11.4507C9.69457 11.4507 10.6821 11.0204 11.3275 10.2284L10.3106 9.28977C9.85101 9.82755 9.27412 10.1013 8.58968 10.1013C7.30879 10.1013 6.39945 9.20177 6.39945 7.91111C6.39945 6.62044 7.30879 5.72088 8.58968 5.72088C9.27412 5.72088 9.85101 5.99466 10.3106 6.52266L11.3275 5.584C10.6821 4.80177 9.69457 4.37155 8.51145 4.37155C6.3799 4.37155 4.7959 5.848 4.7959 7.91111C4.7959 9.97422 6.3799 11.4507 8.50168 11.4507Z"
          fill="white"
        />
      </g>
    </svg>
  );
});

export const FitbuddCustomIcon = React.forwardRef((props, ref) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <rect x="0.75" y="0.75" width="14.5" height="14.5" rx="2.25" stroke="#65768C" stroke-width="1.5"/>
      <path d="M12 12L11.9797 4H10.6312L8.01521 8.97143L5.35868 4H4V12H5.54119V7.30286L7.61977 11.1543H8.35995L10.4487 7.2L10.4588 12H12Z" fill="#65768C"/>
    </svg>
  );
});

export const InstructionIcon = React.forwardRef((props, ref) => {
  return (
    <svg ref={ref} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g opacity="0.8">
        <path
          d="M4.44444 0.0289612C3.31733 0.0289612 2.31467 0.742384 1.91645 1.83447L0.888889 1.80674C0.398222 1.80674 0 2.20469 0 2.69563C0 3.18656 0.398222 3.58452 0.888889 3.58452H1.77778V5.36229H0.888889C0.398222 5.36229 0 5.76025 0 6.25118C0 6.74212 0.398222 7.14007 0.888889 7.14007H1.77778V8.91785H0.888889C0.398222 8.91785 0 9.31581 0 9.80674C0 10.2977 0.398222 10.6956 0.888889 10.6956H1.77778V12.4734H0.888889C0.398222 12.4734 0 12.8714 0 13.3623C0 13.8532 0.398222 14.2512 0.888889 14.2512H1.94489C2.248 15.2386 3.31733 16.029 4.44444 16.029H13.3333C14.8062 16.029 16 14.8352 16 13.3623V2.69563C16 1.22283 14.8062 0.0287834 13.3333 0.0289612H4.44444ZM4.44444 1.80674H13.3333C13.824 1.80674 14.2222 2.20469 14.2222 2.69563V13.3623C14.2222 13.8532 13.824 14.2512 13.3333 14.2512H4.44444C3.95378 14.2512 3.55556 13.8532 3.55556 13.3623V9.80674V6.25118V2.69563C3.55556 2.20469 3.95378 1.80674 4.44444 1.80674ZM6.22222 3.58452C5.73156 3.58452 5.33333 3.98247 5.33333 4.47341C5.33333 4.96434 5.73156 5.36229 6.22222 5.36229H11.5556C12.0462 5.36229 12.4444 4.96434 12.4444 4.47341C12.4444 3.98247 12.0462 3.58452 11.5556 3.58452H6.22222ZM6.22222 7.14007C5.73156 7.14007 5.33333 7.53803 5.33333 8.02896C5.33333 8.51989 5.73156 8.91785 6.22222 8.91785H11.5556C12.0462 8.91785 12.4444 8.51989 12.4444 8.02896C12.4444 7.53803 12.0462 7.14007 11.5556 7.14007H6.22222ZM6.22222 10.6956C5.73156 10.6956 5.33333 11.0936 5.33333 11.5845C5.33333 12.0754 5.73156 12.4734 6.22222 12.4734H9.77778C10.2684 12.4734 10.6667 12.0754 10.6667 11.5845C10.6667 11.0936 10.2684 10.6956 9.77778 10.6956H6.22222Z"
          fill="#37404D"
        />
      </g>
    </svg>
  );
});

export const HighIntensityIcon = React.forwardRef((props, ref) => {
  return (
    <svg ref={ref} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g opacity="0.8">
        <path
          d="M13 2C12.7239 2 12.5 2.224 12.5 2.5V13.5C12.5 13.776 12.7239 14 13 14H15C15.2761 14 15.5 13.776 15.5 13.5V2.5C15.5 2.224 15.2761 2 15 2H13ZM9 6C8.7239 6 8.5 6.224 8.5 6.5V13.5C8.5 13.776 8.7239 14 9 14H11C11.2761 14 11.5 13.776 11.5 13.5V6.5C11.5 6.224 11.2761 6 11 6H9ZM5 10C4.7239 10 4.5 10.224 4.5 10.5V13.5C4.5 13.776 4.7239 14 5 14H7C7.2761 14 7.5 13.776 7.5 13.5V10.5C7.5 10.224 7.2761 10 7 10H5ZM1 12C0.7239 12 0.5 12.224 0.5 12.5V13.5C0.5 13.776 0.7239 14 1 14H3C3.2761 14 3.5 13.776 3.5 13.5V12.5C3.5 12.224 3.2761 12 3 12H1Z"
          fill="#37404D"
        />
      </g>
    </svg>
  );
});

export const ModerateIntensityIcon = React.forwardRef((props, ref) => {
  return (
    <svg ref={ref} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g opacity="0.8">
        <path
          d="M9 2C8.7239 2 8.5 2.224 8.5 2.5V13.5C8.5 13.776 8.7239 14 9 14H11C11.2761 14 11.5 13.776 11.5 13.5V2.5C11.5 2.224 11.2761 2 11 2H9ZM5 6C4.7239 6 4.5 6.224 4.5 6.5V13.5C4.5 13.776 4.7239 14 5 14H7C7.2761 14 7.5 13.776 7.5 13.5V6.5C7.5 6.224 7.2761 6 7 6H5ZM1 10C0.7239 10 0.5 10.224 0.5 10.5V13.5C0.5 13.776 0.7239 14 1 14H3C3.2761 14 3.5 13.776 3.5 13.5V10.5C3.5 10.224 3.2761 10 3 10H1ZM13 12C12.7239 12 12.5 12.224 12.5 12.5V13.5C12.5 13.776 12.7239 14 13 14H15C15.2761 14 15.5 13.776 15.5 13.5V12.5C15.5 12.224 15.2761 12 15 12H13Z"
          fill="#37404D"
        />
      </g>
    </svg>
  );
});

export const LowIntensityIcon = React.forwardRef((props, ref) => {
  return (
    <svg ref={ref} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g opacity="0.8">
        <path
          d="M5 6C4.7239 6 4.5 6.224 4.5 6.5V13.5C4.5 13.776 4.7239 14 5 14H7C7.2761 14 7.5 13.776 7.5 13.5V6.5C7.5 6.224 7.2761 6 7 6H5ZM1 10C0.7239 10 0.5 10.224 0.5 10.5V13.5C0.5 13.776 0.7239 14 1 14H3C3.2761 14 3.5 13.776 3.5 13.5V10.5C3.5 10.224 3.2761 10 3 10H1ZM13 12C12.7239 12 12.5 12.224 12.5 12.5V13.5C12.5 13.776 12.7239 14 13 14H15C15.2761 14 15.5 13.776 15.5 13.5V12.5C15.5 12.224 15.2761 12 15 12H13Z"
          fill="#37404D"
        />
        <path
          d="M8.5 12.5C8.5 12.224 8.7239 12 9 12H11C11.2761 12 11.5 12.224 11.5 12.5V13.5C11.5 13.776 11.2761 14 11 14H9C8.7239 14 8.5 13.776 8.5 13.5V12.5Z"
          fill="#37404D"
        />
      </g>
    </svg>
  );
});

export const WorkoutInstructionIcon = React.forwardRef((props, ref) => {
  return (
    <svg ref={ref} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M4.44444 0.0289307C3.31733 0.0289307 2.31467 0.742353 1.91645 1.83444L0.888889 1.80671C0.398222 1.80671 0 2.20466 0 2.6956C0 3.18653 0.398222 3.58449 0.888889 3.58449H1.77778V5.36226H0.888889C0.398222 5.36226 0 5.76022 0 6.25115C0 6.74209 0.398222 7.14004 0.888889 7.14004H1.77778V8.91782H0.888889C0.398222 8.91782 0 9.31577 0 9.80671C0 10.2976 0.398222 10.6956 0.888889 10.6956H1.77778V12.4734H0.888889C0.398222 12.4734 0 12.8713 0 13.3623C0 13.8532 0.398222 14.2512 0.888889 14.2512H1.94489C2.248 15.2386 3.31733 16.029 4.44444 16.0289H13.3333C14.8062 16.0289 16 14.8352 16 13.3623V2.6956C16 1.2228 14.8062 0.0287529 13.3333 0.0289307H4.44444ZM4.44444 1.80671H13.3333C13.824 1.80671 14.2222 2.20466 14.2222 2.6956V13.3623C14.2222 13.8532 13.824 14.2512 13.3333 14.2512H4.44444C3.95378 14.2512 3.55556 13.8532 3.55556 13.3623V9.80671V6.25115V2.6956C3.55556 2.20466 3.95378 1.80671 4.44444 1.80671ZM6.22222 3.58449C5.73156 3.58449 5.33333 3.98244 5.33333 4.47337C5.33333 4.96431 5.73156 5.36226 6.22222 5.36226H11.5556C12.0462 5.36226 12.4444 4.96431 12.4444 4.47337C12.4444 3.98244 12.0462 3.58449 11.5556 3.58449H6.22222ZM6.22222 7.14004C5.73156 7.14004 5.33333 7.538 5.33333 8.02893C5.33333 8.51986 5.73156 8.91782 6.22222 8.91782H11.5556C12.0462 8.91782 12.4444 8.51986 12.4444 8.02893C12.4444 7.538 12.0462 7.14004 11.5556 7.14004H6.22222ZM6.22222 10.6956C5.73156 10.6956 5.33333 11.0936 5.33333 11.5845C5.33333 12.0754 5.73156 12.4734 6.22222 12.4734H9.77778C10.2684 12.4734 10.6667 12.0754 10.6667 11.5845C10.6667 11.0936 10.2684 10.6956 9.77778 10.6956H6.22222Z" fill="#65768C"/>
    </svg>
  );
});

export const WorkoutVideoInstruction = React.forwardRef((props, ref) => {
  return (
    <svg ref={ref} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M3.33333 0C2.904 0 2.55556 0.342109 2.55556 0.763636C2.55556 1.18516 2.904 1.52727 3.33333 1.52727H12.6667C13.096 1.52727 13.4444 1.18516 13.4444 0.763636C13.4444 0.342109 13.096 0 12.6667 0H3.33333ZM2.55556 3.05455C2.12622 3.05455 1.77778 3.39665 1.77778 3.81818C1.77778 4.23971 2.12622 4.58182 2.55556 4.58182H13.4444C13.8738 4.58182 14.2222 4.23971 14.2222 3.81818C14.2222 3.39665 13.8738 3.05455 13.4444 3.05455H2.55556ZM3.33333 6.10909C2.04456 6.10909 1 7.13466 1 8.4V12.9818C1 14.2472 2.04456 15.2727 3.33333 15.2727H12.6667C13.9554 15.2727 15 14.2472 15 12.9818V8.4C15 7.13466 13.9554 6.10909 12.6667 6.10909H3.33333ZM3.33333 7.63636H12.6667C13.096 7.63636 13.4444 7.97847 13.4444 8.4V12.9818C13.4444 13.4033 13.096 13.7455 12.6667 13.7455H3.33333C2.904 13.7455 2.55556 13.4033 2.55556 12.9818V8.4C2.55556 7.97847 2.904 7.63636 3.33333 7.63636ZM7.56288 9.23541C7.04566 8.98112 6.44444 9.35989 6.44444 9.92727V11.4545C6.44444 12.0219 7.04566 12.4007 7.56288 12.1464L9.11844 11.3828C9.69166 11.1018 9.69166 10.2801 9.11844 9.99905C8.92321 9.9036 7.75732 9.33087 7.56288 9.23541Z" fill="#65768C"/>
    </svg>
  );
});

export const OdIcon = React.forwardRef((props, ref) => {
  return (
    <svg ref={ref} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g opacity="0.8">
        <rect width="16" height="16" rx="3" fill="#37404D" />
        <path
          d="M4.36862 11.5C6.3036 11.5 7.73724 10.0207 7.73724 8C7.73724 5.97928 6.3036 4.5 4.36862 4.5C2.42485 4.5 1 5.98895 1 8C1 10.011 2.42485 11.5 4.36862 11.5ZM4.36862 10.1657C3.2692 10.1657 2.44244 9.28591 2.44244 8C2.44244 6.71409 3.2692 5.83425 4.36862 5.83425C5.46804 5.83425 6.2948 6.71409 6.2948 8C6.2948 9.28591 5.46804 10.1657 4.36862 10.1657Z"
          fill="white"
        />
        <path
          d="M8.80807 11.384H11.605C13.6191 11.384 15 10.0497 15 8C15 5.95028 13.6191 4.61602 11.605 4.61602H8.80807V11.384ZM10.2329 10.0981V5.90193H11.5346C12.7572 5.90193 13.5576 6.70442 13.5576 8C13.5576 9.29558 12.7572 10.0981 11.5346 10.0981H10.2329Z"
          fill="white"
        />
      </g>
    </svg>
  );
});

export const SupplementLinkIcon = React.forwardRef((props, ref) => {
  return (
    <svg ref={ref} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M11.3333 4.66663H8.66659V5.99996H11.3333C12.4333 5.99996 13.3333 6.89996 13.3333 7.99996C13.3333 9.09996 12.4333 9.99996 11.3333 9.99996H8.66659V11.3333H11.3333C13.1733 11.3333 14.6666 9.83996 14.6666 7.99996C14.6666 6.15996 13.1733 4.66663 11.3333 4.66663ZM7.33325 9.99996H4.66659C3.56659 9.99996 2.66659 9.09996 2.66659 7.99996C2.66659 6.89996 3.56659 5.99996 4.66659 5.99996H7.33325V4.66663H4.66659C2.82659 4.66663 1.33325 6.15996 1.33325 7.99996C1.33325 9.83996 2.82659 11.3333 4.66659 11.3333H7.33325V9.99996ZM5.33325 7.33329H10.6666V8.66663H5.33325V7.33329ZM11.3333 4.66663H8.66659V5.99996H11.3333C12.4333 5.99996 13.3333 6.89996 13.3333 7.99996C13.3333 9.09996 12.4333 9.99996 11.3333 9.99996H8.66659V11.3333H11.3333C13.1733 11.3333 14.6666 9.83996 14.6666 7.99996C14.6666 6.15996 13.1733 4.66663 11.3333 4.66663ZM7.33325 9.99996H4.66659C3.56659 9.99996 2.66659 9.09996 2.66659 7.99996C2.66659 6.89996 3.56659 5.99996 4.66659 5.99996H7.33325V4.66663H4.66659C2.82659 4.66663 1.33325 6.15996 1.33325 7.99996C1.33325 9.83996 2.82659 11.3333 4.66659 11.3333H7.33325V9.99996ZM5.33325 7.33329H10.6666V8.66663H5.33325V7.33329Z" fill="#65768C"/>
    </svg>
  );
});

export const MealMacroBasedIcon=React.forwardRef((props, ref) => {
  return (
    <svg ref={ref} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M7.99984 15.059C11.3136 15.059 13.9999 12.3727 13.9999 9.05892C13.9999 5.74517 11.3136 3.05884 7.99984 3.05884C4.68608 3.05884 1.99976 5.74517 1.99976 9.05892C1.99976 12.3727 4.68608 15.059 7.99984 15.059Z" stroke="#65768C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.2636 7.35767L8.9812 8.66357" stroke="#65768C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M6.58813 2V1.64705C6.58813 1.45984 6.66251 1.28029 6.79489 1.14791C6.92727 1.01553 7.10681 0.941162 7.29403 0.941162H8.70581C8.89302 0.941162 9.07257 1.01553 9.20495 1.14791C9.33733 1.28029 9.4117 1.45984 9.4117 1.64705V2" stroke="#65768C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8 10.1177C8.58478 10.1177 9.05884 9.64362 9.05884 9.05884C9.05884 8.47406 8.58478 8 8 8C7.41522 8 6.94116 8.47406 6.94116 9.05884C6.94116 9.64362 7.41522 10.1177 8 10.1177Z" stroke="#65768C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.5881 3.05884L13.9999 4.47062" stroke="#65768C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
});

export const MealFoodBasedIcon=React.forwardRef((props, ref) => {
  return (
    <svg ref={ref} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M3.99998 10.3466C1.57331 6.75331 2.54665 4.69998 4.41998 4.22665C4.93276 4.07131 5.4769 4.05065 5.99998 4.16665C6.10172 4.19712 6.20184 4.23272 6.29998 4.27331" stroke="#65768C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7.04004 4.87334C7.35395 4.60023 7.7187 4.39179 8.11337 4.26001C8.60931 4.11255 9.13743 4.11255 9.63337 4.26001C11.26 4.62001 12.1867 6.26001 10.8867 8.96001" stroke="#65768C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.08012 2.93333C9.56012 2.75333 10.5535 1.77333 10.3668 1.42666C10.1801 1.07999 9.33345 1.20666 8.78012 1.39333C8.30857 1.567 7.88239 1.84505 7.53345 2.20666C7.37084 2.3687 7.23171 2.55271 7.12012 2.75333" stroke="#65768C" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M6.23999 1.77332C6.51522 1.93209 6.74501 2.15897 6.90729 2.43216C7.06957 2.70534 7.15888 3.01566 7.16666 3.33332C7.1801 3.45071 7.1801 3.56925 7.16666 3.68665" stroke="#65768C" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3334 11.7133C11.5867 12.9 11.1 14.0667 9.8267 14.28C7.12164 14.5725 4.39844 14.6617 1.68004 14.5467C1.47413 14.5331 1.27928 14.4486 1.12858 14.3076C0.977884 14.1667 0.880641 13.9779 0.853369 13.7733V13.7733C0.800175 13.5712 0.818984 13.3569 0.906568 13.1671C0.994152 12.9773 1.14505 12.824 1.33337 12.7333C3.80667 11.6036 6.36034 10.6588 8.97337 9.90666C10.2534 9.61999 11.1467 10.52 11.3334 11.7133Z" stroke="#65768C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11.3334 11.7133C11.5867 12.9 11.1 14.0667 9.8267 14.28C7.12164 14.5725 4.39844 14.6617 1.68004 14.5467C1.47413 14.5331 1.27928 14.4486 1.12858 14.3076C0.977884 14.1667 0.880641 13.9779 0.853369 13.7733C0.800175 13.5712 0.818984 13.3569 0.906568 13.1671C0.994152 12.9773 1.14505 12.824 1.33337 12.7333C3.80667 11.6036 6.36034 10.6588 8.97337 9.90666C10.2534 9.61999 11.1467 10.52 11.3334 11.7133Z" stroke="#65768C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.6734 10.5667C13.7468 10.5 13.8201 10.4267 13.8868 10.3533C14.5001 9.68667 14.7334 9.02 14.4068 8.86667C14.0801 8.71333 13.3134 9.13333 12.7001 9.8C12.5611 9.95637 12.4119 10.1034 12.2534 10.24" stroke="#65768C" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.6667 12.4267L13.2934 12.7133C14.1134 13.0933 14.9801 13.1933 15.2201 12.9267C15.4601 12.66 15.0001 12.1333 14.1801 11.7467C14.0934 11.7 14.0001 11.6667 13.9067 11.6267C14.7801 11.4133 15.4134 11.06 15.3601 10.7933C15.3302 10.6863 15.2668 10.5917 15.1793 10.5233C15.0917 10.4549 14.9845 10.4164 14.8734 10.4133" stroke="#65768C" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.66671 12.1399C8.67169 11.5315 8.55378 10.9284 8.32005 10.3666C8.28671 10.2799 8.24671 10.1999 8.21338 10.1133" stroke="#65768C" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M6.47339 12.5734C6.74601 13.0815 6.85981 13.6598 6.80006 14.2334C6.79372 14.3364 6.78036 14.4388 6.76006 14.54" stroke="#65768C" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3.62661 13.1333C3.72804 12.7006 3.66132 12.2454 3.43994 11.86" stroke="#65768C" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
});

export const MealBmrBasedIcon=React.forwardRef((props, ref) => {
  return (
    <svg ref={ref} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g opacity="0.8">
        <path d="M1.66675 10.3333C1.66675 8.65363 2.33401 7.04272 3.52174 5.85499C4.70947 4.66726 6.32038 4 8.00008 4C9.67979 4 11.2907 4.66726 12.4784 5.85499C13.6662 7.04272 14.3334 8.65363 14.3334 10.3333" stroke="#37404D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1.66675 10.3333C1.66675 8.65363 2.33401 7.04272 3.52174 5.85499C4.70947 4.66726 6.32038 4 8.00008 4C9.67979 4 11.2907 4.66726 12.4784 5.85499C13.6662 7.04272 14.3334 8.65363 14.3334 10.3333" stroke="#37404D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.8001 5.91846C6.04081 6.12654 5.34774 6.52625 4.78739 7.07926C4.22703 7.63226 3.81819 8.31999 3.6001 9.07646" stroke="#37404D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1 11.6665H15" stroke="#37404D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.94458 3.19449C6.94458 3.05591 6.97188 2.91867 7.02491 2.79063C7.07795 2.6626 7.15568 2.54626 7.25368 2.44826C7.35168 2.35026 7.46802 2.27253 7.59605 2.21949C7.72409 2.16646 7.86133 2.13916 7.99991 2.13916C8.1385 2.13916 8.27573 2.16646 8.40377 2.21949C8.53181 2.27253 8.64815 2.35026 8.74615 2.44826C8.84414 2.54626 8.92188 2.6626 8.97491 2.79063C9.02795 2.91867 9.05525 3.05591 9.05525 3.19449" stroke="#37404D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1.66675 11.6665C2.15741 12.2158 2.59141 13.6665 4.15008 13.6665H11.8501C13.4074 13.6665 13.8447 12.2132 14.3334 11.6665" stroke="#37404D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </svg>
  );
});