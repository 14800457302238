import {useContext} from 'react';
import _ from 'lodash';
import { TagsContext } from 'fitbud/providers/tagsProvider';


const separator=' • ';
export const GetDetailSecLine=(keyName,doc)=>{
    switch(keyName){
        case "exercises":{
            const {source,ref_name}=doc;
            const array=[ref_name];
            //----
            // if(category && category!=="none"){
            //     array.push(_.capitalize(exCategoryHelper.typeToText(category)));
            // }
            //-----
            if(source==='fitbudd'){
                array.push('Library');
            }
            else if(source==='fitbudd_custom'){
                array.push('Library Modified');
            }
            //----
            // if(!!steps.length){
            //     array.push('Instructions Available')
            // }
            return array.join(separator);
        }
        case "workout":{
            const {is_single_video,ref_name}=doc;
            const array=[ref_name];
            //-----
            // if(!!sub_type && sub_type!=='regular'){
            //     array.push(_.capitalize(sub_type));
            // }
            // else{
            //     array.push('Workout');
            // }
            //-----
            // if(duration){
            //     array.push(`${Math.round(duration/60)} min`);
            // }
            // //----
            // if(intensity){
            //     array.push(`${_.capitalize(intensity)} Intensity`);
            // }
            //----
            if(is_single_video){
                array.push('Follow Along Workout');
            }
            else{
                array.push('Structured Workout');
            }
            return array.join(separator);
        }
        case "cardio":{
            const {sub_type,ref_name}=doc;
            const array=[ref_name];
            if(sub_type==='hiit'){
                array.push('High Intensity')
            }
            else if(sub_type==="liss"){
                array.push('Low Intensity')
            }
            //---
            // if(duration){
            //     array.push(`${Math.round(duration/60)} min`);
            // }
            return array.join(separator);
        }
        case "workout-schedule":{
            const {ref_name}=doc;
            const array=[ref_name];
            // if(duration){
            //     array.push(`${Math.round(duration/60)} min`);
            // }
            return array.join(separator);
        }
        case "food":{
            const {source,ref_name}=doc;
            const array=[ref_name];
            // if(serving_size){
            //     array.push(`${roundNumber(serving_size,2)} ${serving_unit}`);
            // }
            // //----
            // if(macros.calories){
            //     array.push(`${roundNumber(macros.calories,0)} cals`);
            // }
            //-----
            if(source==='fitbudd'){
                array.push('Library');
            }
            else if(source==='fitbudd_custom'){
                array.push('Library Modified');
            }
            return array.join(separator);
        }
        case "recipes":{
            const {ref_name}=doc;
            const array=[ref_name];
            // //---
            // if(serving && servingData){
            //     const found=servingData.find(i=>i[0]===serving);
            //     if(found){
            //         array.push(`1 ${found[1].value}`);
            //     }
            // }
            // //----
            // if(macros.calories){
            //     array.push(`${roundNumber(macros.calories,0)} cals`);
            // }
            return array.join(separator);
        }
        case "meal-plans":{
            const {diet_type,type,ref_name}=doc;
            const array=[ref_name];
            // if(macros.calories){
            //     array.push(`${roundNumber(macros.calories,0).toLocaleString()} cals`);
            // }
            // if(diet_type && dietType){
            //     const found=dietType.find(i=>i[0]===diet_type);
            //     if(found){
            //         array.push(found[1].value);
            //     }
            // }
            if(type){
                array.push(`${_.capitalize(type)} Based`);
            }
            return array.join(separator);
        }
        case "supplements":{
            const {ref_name}=doc;
            const array=[ref_name];
            // if(serving && servingData){
            //     const found=servingData.find(i=>i[0]===serving);
            //     if(found){
            //         array.push(`${roundNumber(serving_size,2)||1} ${_.capitalize(found[1].value)}`);
            //     }
            // }
            // if(link){
            //     array.push("Product Link available")
            // }
            return array.join(separator);
        }
         case "supplement-plans":{
            const {groups,ref_name}=doc;
            const array=[ref_name];
            // if(groups.length){
            //     array.push(`${groups.length} Supplements`);
            // }
            return array.join(separator);
        }
        case "meal-schedule":{
            const {ref_name}=doc;
            const array=[ref_name];
            // if(duration){
            //     array.push(`${Math.round(duration/60)} min`);
            // }
            return array.join(separator);
        }
        case "resources":{
            const {ref_name, type} = doc;
            const array=[ref_name, type];
            return array.join(separator);
        }
        default: return null;
    }
}