import SplitDetailView from 'fitbud/components/splitDetailView';
import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import NotificationList from './notificationList';
import Placeholder from './placeholder';
import NotificationInfo from './info';
import InAppNotificationDialog from './inAppNotificationDialog';
import { useHistory } from 'react-router-dom';
import firebase from 'fitbud/firebase';
import { parsePacks } from '../plan/helper';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { PlanProvider } from '../plan/planProvider';

function AppNotification(props) {
  const { match } = props;
  const notificationId = match.params.id;
  const { cid } = useContext(FirebaseAuthContext);
  const history = useHistory();
  const isNew = notificationId === 'new';
  const navigate = !!notificationId ? props.history.replace : props.history.push;
  const onSelect = (id) => navigate(`/messages/in-app-notifications/${id}`);
  const onAdd = () => navigate('/messages/in-app-notifications/new');
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleDialogClose = () => {
    if (history.length <= 2) history.replace('/messages/in-app-notifications');
    else history.goBack();
  };

  async function fetchList() {
    setLoading(true);
    const docs = await firebase
      .firestore()
      .collection(`companies/${cid}/interstitials`)
      .where('archive', '==', false)
      .get();

    const data = parsePacks(docs);
    setList(data);
    setLoading(false);
  }

  return (
    <PlanProvider>
      <Helmet>
        <title>FitBudd » In App Notification</title>
      </Helmet>
      <SplitDetailView details={!!notificationId}>
        <NotificationList
          selected={notificationId}
          onSelect={onSelect}
          onAdd={onAdd}
          list={list}
          loading={loading}
          fetchList={fetchList}
        />
        {!!notificationId ? (
          <NotificationInfo key={notificationId} fetchList={fetchList} id={notificationId} />
        ) : (
          <Placeholder />
        )}
      </SplitDetailView>
      {isNew && <InAppNotificationDialog id={notificationId} open onClose={handleDialogClose} onSave={fetchList} />}
    </PlanProvider>
  );
}

export default AppNotification;
