import React from "react";
import WIPImage from "fitbud/images/work_in_progress.svg";
import { NavIcon } from "fitbud/partials/appBar";

const WorkInProgress = () => {
  return (
    <>
    <NavIcon className="position-fixed"/>
    <div className="flex-grow-1 d-flex align-items-center justify-content-center ">
      <img height="256" width="325" alt="noitem" src={WIPImage} />
    </div>
    </>
  );
};

export default WorkInProgress;
