import React from 'react';
import { IconButton, Typography, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import _ from 'lodash';
import { AvatarImage } from 'fitbud/views/users/header';
import { getNameAndImage, getEmailFirstLetter } from 'fitbud/utils/helpers';
import { Close } from '@material-ui/icons';
import { ClrdButton } from 'fitbud/components/form-fields';
import { ONLINE_MODE_TEXT, getMode, OFFLINE_MODE_TEXT } from 'fitbud/views/groupClasses/helper';

const useClasses = makeStyles(() => ({
  itemRoot: {
    paddingLeft: 0,
    paddingRight: 0,
    '& .display_cross': {
      display: 'none',
    },
    '&:hover': {
      '& .display_cross': {
        display: 'inline',
      },
    },
  },
  waiting: {
    backgroundColor: 'rgba(215, 55, 23, 0.10)',
    color: '#D73717',
    borderRadius: 3,
    padding: '3px 8px',
    marginLeft: 10,
  },
}));

const UserLists = (props) => {
  const { users, onSelectCanceled, onSelectAllowed, mode, isFilter, className, isDisabled } = props;
  const classes = useClasses();
  const { isHybrid } = getMode(mode);
  const userCount = users.length;
  return (
    <List disablePadding className={clsx('px-25 flex-grow-1 overflow-auto', className)}>
      {(users || []).map((user) => {
        const { status, mode } = user || {};
        const _id = _.get(user, 'data._id', user._id);
        const { name, image_data, email, identifier } = getNameAndImage(user || {});
        const renderSecondLine = () => {
          //only show user mode status when it is hybrid;
          const user_mode = isHybrid ? (mode === 'offline' ? OFFLINE_MODE_TEXT : ONLINE_MODE_TEXT) : '';
          return (
            <span>
              <span className={clsx(mode === 'online' ? 'text-27AE60' : 'text-dark-grey', 'text-uppercase')}>
                {user_mode}
              </span>
              {status === 'waiting' && (
                <span>
                  {' '}
                  • <span className="text-uppercase text-D73717">{status}</span>
                </span>
              )}
            </span>
          );
        };
        return (
          <ListItem className={clsx(classes.itemRoot)}>
            <ListItemAvatar>
              <AvatarImage
                src={identifier ? `user_profiles/${_id}/profile/original/${identifier}` : image_data}
                name={name || getEmailFirstLetter(email)}
                base64={!identifier}
                alt={name || ' '}
                styles={{ fontSize: 13, fontWeight: 600 }}
              />
            </ListItemAvatar>
            <ListItemText
              primaryTypographyProps={{ component: 'div' }}
              primary={name}
              secondary={renderSecondLine()}
              classes={{
                secondary: 'font_11_500 text-truncate text-grey',
                primary: ' text-truncate font_15_600 text-0d0d0d mr-10 mb-5',
              }}
            />
            {!isDisabled && (
              <>
                {status === 'yes' && (
                  <IconButton
                    className="display_cross"
                    style={{ padding: '6px' }}
                    onClick={() => {
                      onSelectCanceled(_id);
                    }}>
                    <Close style={{ fontSize: 15 }} />
                  </IconButton>
                )}
                {status === 'waiting' && (
                  <ClrdButton
                    className="f-sm-med flex-shrink-0"
                    variant="contained"
                    color="primary"
                    onClick={() => onSelectAllowed(_id)}>
                    <Typography className="font_13_600">Allow to Join</Typography>
                  </ClrdButton>
                )}
              </>
            )}
          </ListItem>
        );
      })}
      {/* Placeholder in case of no user found */}
      {!userCount && (
        <div className="d-flex flex-column h-100 justify-content-center">
          <Typography className="text-gray font_14_500 text-center">
            {!!isFilter && <span>No user Found</span>}
            {!isFilter && <span>No user Added, You can add user by clicking + icon</span>}
          </Typography>
        </div>
      )}
    </List>
  );
};

export default UserLists;
