import React from 'react';

const TimeIcon = (props) => {
 return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 0.666626H6V1.99996H10V0.666626ZM7.33333 9.33329H8.66667V5.33329H7.33333V9.33329ZM12.6867 4.92663L13.6333 3.97996C13.3467 3.63996 13.0333 3.31996 12.6933 3.03996L11.7467 3.98663C10.7133 3.15996 9.41333 2.66663 8 2.66663C4.68667 2.66663 2 5.35329 2 8.66663C2 11.98 4.68 14.6666 8 14.6666C11.32 14.6666 14 11.98 14 8.66663C14 7.25329 13.5067 5.95329 12.6867 4.92663ZM8 13.3333C5.42 13.3333 3.33333 11.2466 3.33333 8.66663C3.33333 6.08663 5.42 3.99996 8 3.99996C10.58 3.99996 12.6667 6.08663 12.6667 8.66663C12.6667 11.2466 10.58 13.3333 8 13.3333Z"
      fill="#6F8099"
    />
  </svg>;
};

export default TimeIcon;
