import React, { useState } from "react";
import _ from "lodash";
import { FormHelperText } from "@material-ui/core";
import { FormTextField } from "fitbud/components/form-fields";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment-timezone";
import { DatePickerField } from "fitbud/views/users/createEditForm";
import Button from '@material-ui/core/Button';


const DateRangeFilter = (props) => {
  const { componentState, type, dispatch, errors,  } = props;
  const [state, setState] = useState(componentState || { from: null, to: null })
  function validate(newState, fieldId) {
    const fromD = _.get(newState, 'from');
    const toD = _.get(newState, 'to');
    errors.current[type] = errors.current[type] || {}
    if(!fromD || !toD) {
      if(!fromD) {
        errors.current[type].from = 'Please select a date'
      }
      if(!toD) {
        errors.current[type].to = 'Please select a date'
      }
      return
    }
    const val = fromD > toD
    if (fieldId === 'from') {
      errors.current[type].from = val ? "Should be less than or equal to the date below" : ''
      errors.current[type].to = ''
    } else {
      errors.current[type].from = ''
      errors.current[type].to = val ? "Should be greater than or equal to the date above" : ''
    }
  }
  function handleDateChange(date, id) {
    const newState = { ...state, [id]: date.format('YYYYMMDD') }
    validate(newState, id)
    setState(newState)
    dispatch({ type, payload: newState })
  }
  function clear() {
    const newState = { from: null, to: null }
    setState(newState)
    dispatch({ type, payload: newState })
  }

  return (<>
    <FormHelperText className="font_14_500 mb-4">Filter clients by the start date of their schedule</FormHelperText>
    <FormTextField fullWidth label="Starting on or after" classes={{ control: 'fmb-0' }} required>
      <DatePicker
        autoOk
        value={state && state.from ? moment(state.from): null}
        id="date-picker-dialog-from"
        inputVariant="outlined"
        onChange={(date) => handleDateChange(date, 'from')}
        KeyboardButtonProps={{
          "aria-label": "change date"
        }}
        format="DD MMM YYYY"
        TextFieldComponent={(props) => <DatePickerField hideLabel {...props} />}
        // minDate={(extend ? origEndDate.current : startDate) || moment()}
        minDateMessage=""
        error={_.get(errors, ["current", type, "from"])}
        helperText={_.get(errors, ["current", type, "from"])} 
      />
    </FormTextField>
    <FormTextField fullWidth label="Starting on or before" required classes={{ control: 'fmb-0' }}>
      <DatePicker
        autoOk
        value={state && state.to ? moment(state.to): null}
        id="date-picker-dialog-to"
        inputVariant="outlined"
        onChange={(date) => handleDateChange(date, 'to')}
        KeyboardButtonProps={{
          "aria-label": "change date"
        }}
        format="DD MMM YYYY"
        TextFieldComponent={(props) => <DatePickerField hideLabel {...props} />}
        minDateMessage=""
        error={_.get(errors, ["current", type, "to"])}
        helperText={_.get(errors, ["current", type, "to"])}
      />
    </FormTextField>
    <Button onClick={clear} color="primary">Clear</Button>
  </>
  )
}

export default DateRangeFilter
