import React, { useEffect, useMemo } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { getMinutesSeconds, getClassJoinUrl, getMode } from '../helper';
import clsx from 'clsx';
import useLiveTime, { CLASS_RUNNING_STATUS } from 'fitbud/hooks/useLiveTime';
import { useLocations } from 'fitbud/providers/gcLocationsProvider';
import { ClrdButton } from 'fitbud/components/form-fields';

const useClasses = makeStyles(() => ({
  timer: {
    paddingTop: 15,
    paddingBottom: 15,
    textAlign: 'center',
    borderTop: '1px solid #D3D9DB',
    backgroundColor: '#DEEBFF',
  },
  join: {
    paddingTop: 15,
    paddingBottom: 15,
    textAlign: 'center',
    borderTop: '1px solid #D3D9DB',
    backgroundColor: '#317FF5',
  },
  commonJoin: {
    textAlign: 'center',
    paddingTop: 15,
    paddingBottom: 15,
    backgroundColor: '#317FF5',
    height:40
  },
}));

const CountDown = (props) => {
  const { time, data, className, setStatus } = props;
  const { duration } = data;
  const [status, remainingTime] = useLiveTime(time, duration);
  const { m, s } = getMinutesSeconds(remainingTime);

  useEffect(() => {
    setStatus(status);
  }, [status]);

  const classes = useClasses();
  return (
    <div className='d-flex'>
      {status === CLASS_RUNNING_STATUS.UPCOMING && (
        <div className={clsx(classes.timer, className, "flex-grow-1")}>
          <Typography className="font_13_500">
            Starts in{' '}
            <span className="font_13_700 d-inline-block" style={{ width: 35 }}>
              <span>{m}</span>:<span>{s}</span>
            </span>{' '}
            mins
          </Typography>
        </div>
      )}
      {status === CLASS_RUNNING_STATUS.LIVE && <JoinLiveGroupClass className={clsx(classes.join, "flex-grow-1")} data={data} />}
    </div>
  );
};

export default CountDown;

export const JoinLiveGroupClass = (props) => {
  const { data, className, style } = props;
  const { getLocation } = useLocations();
  const { isOffline, isOnLine, isHybrid } = getMode(data.mode);
  const classes = useClasses();
  const joinUrl = useMemo(() => {
    const { location_offline, location_online } = data || {};
    const offlineDetail = getLocation(location_offline);
    const onlineDetail = getLocation(location_online);
    const url = getClassJoinUrl(data, offlineDetail, onlineDetail);
    return url;
  }, [data, getLocation]);

  const handleClick = () => {
    window.open(joinUrl, '_blank');
  };

  return (
    <ClrdButton
      color="primary"
      variant="contained"
      className={clsx('f-large flex-grow-1')}
      onClick={handleClick}
      style={style}>
      <Typography className="font_14_700 text-white">{isOffline ? 'Class Started' : 'Join Class'}</Typography>
    </ClrdButton>
  );
};
