import React, { useEffect, createContext, useMemo } from "react";
import {get,find} from "lodash";
import clsx from "clsx";
import ExerciseGrpContainer from "fitbud/views/workouts/exerciseGroup";
import { ClrdButton } from "fitbud/components/form-fields";
import { Container,Card,makeStyles,Typography,List,ListItem } from "@material-ui/core";
import {EditorSummary} from "./editorSummary";
import DownloadIcon from "fitbud/icons/download";
import RedorderIcon from '@material-ui/icons/ImportExportOutlined';
import ImportWo from "fitbud/views/workouts/importWo";
import ExerciseSelector from "fitbud/components/catalogSelector/exerciseSelector";
import {ListStyling} from "fitbud/components/catalog";
import useMediaQuery from '@material-ui/core/useMediaQuery';

const styles = makeStyles({
  container: {
    padding: "20px 5vw",
    display:"flex",
    gap:"20px",
    width:"fit-content"
  },
  editorContainer: {
    flex:2.2,
    minWidth:"710px"
  },
  summaryContainer: {
    flex:1,
    position:'sticky',
    top:"20px",
    height:"100%",
    minWidth:"300px"
  },
  button: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
    borderRadius: "10px",
    border:"1px solid #DDDDDD",
    width:"100%",
    height:"48px",
    boxShadow:"none",
    '&:hover':{
      boxShadow:"none"
    }
  },
});

export const EditorContext = createContext({});

const ExerciseEditor = props => {
  const {
    editorMode,
    doc = {},
    storedExrcs,
    handleChange,
    handleExTypeValuesChange,
    handleValuesOnSetsChange,
    handleDelete,
    errors,
    loading,
    handleRestChange,
    handleMove,
    handleReorder,
    handleGrpAttributes,
    sidesData,
    setNotesState,
    isProgress,
    dayWiseWo,
    woType,
    cid,
    handleDuplicate,
    onAddExerciseClick,
    closeAddExerciseDialog,
    minified,
    targetAreasData,
    equipmentsData,
    muscleGroupsData,
    //---CHECK PROPS BELOW
    selectedGps,
    selectorMode,
    grpData,
    docId,
    disableExClick,
    altExercises,
    handleExerciseAltChange,
    isPreview,
    handleGrpHeader
  } = props;
  const under1000Width = useMediaQuery('(max-width:1200px)');//dont delete
  const handleClose = () => {
    closeAddExerciseDialog();
  };
  useEffect(() => {
    if(closeAddExerciseDialog) closeAddExerciseDialog();
  }, [doc]);
  const totalGrps = useMemo(()=>get(doc,"groups",[]),[doc.groups]);
  const buttonProps=!selectorMode?{}:{
    disableRipple:true,
    button:true
  }

  return (
    <EditorContext.Provider
      value={{
        editorMode,
        storedExrcs,
        handleChange,
        handleExTypeValuesChange,
        handleValuesOnSetsChange,
        handleDelete,
        errors,
        loading,
        handleRestChange,
        handleMove,
        totalGrps:totalGrps.length,
        handleReorder,
        handleGrpAttributes,
        sidesData,
        setNotesState,
        handleDuplicate,
        selectedGps,
        grpData,
        doc,
        targetAreasData,
        equipmentsData,
        muscleGroupsData,
        docId,
        selectorMode,
        disableExClick,
        altExercises,
        handleExerciseAltChange,
        isPreview,
        under1000Width,
        handleGrpHeader
      }}
    >
      {/*-----        EDIT MODE      ------*/}
      {editorMode && <EditorMode doc={doc} handleClose={handleClose} totalGrps={totalGrps.length} {...props}/>}
      {/*-----      NON-EDIT MODE      ------*/}
        {!editorMode && (
          <ListStyling>
            <List component={Container} className="fp-0 fpy-0">
              {totalGrps.map((group, index) => {
                return (
                  <ListItem 
                    {...buttonProps}
                    // disable 
                    divider={selectorMode} 
                    selected={!!find(grpData, group)} 
                    className={clsx(selectorMode?"p-20":"p-0")} 
                    style={{height:"unset"}}
                    classes={{root:"flex-column"}}
                    >
                    <ExerciseGrpContainer
                      disableExClick={disableExClick}
                      key={`group-${index + 1}`}
                      group={group}
                      index={ "index" in group ? group.index : index}
                      addExercise={() => onAddExerciseClick(index, true)}
                      isProgress={isProgress}
                      dayWiseWo={dayWiseWo}
                      woType={woType}
                      cid={cid}
                      minified={minified}
                    />
                  </ListItem>
                );
              })}
            </List>
          </ListStyling>
      )}
    </EditorContext.Provider>
  );
};

export default ExerciseEditor;

const EditorMode=({doc,totalGrps,handleClose,summary,groupWiseWoDuration,onReorderClick,...props})=>{
  const {insideGrp,grpIndex,open,handleExerciseAdd,isProgress,dayWiseWo,cid,storedExrcs,
    woType,minified,onAddExerciseClick,isWoSelectorOpen,openWoSelector,closeWoSelector,handleGrpAdd}=props;
  const classes=styles();
  const getGroupExercise = () =>{ //pass all selected ex to exercise Selector
    if (!insideGrp) return [];
    let selectedGroup = doc.groups && doc.groups[grpIndex];
    if (!selectedGroup) return;
    const exs = (selectedGroup.exercises || []).map((exercise) => {
      const exId = exercise.ref.id;
      const doc = storedExrcs && storedExrcs.find((d) => exId === d.id);
      return { _id: exId, data: { ...doc }, existsData: { ...exercise } };
    });
    return exs;
  }
  const _getSelectedExercises = useMemo(getGroupExercise, [doc,insideGrp,grpIndex, storedExrcs ])
  return(
    <>
        <Container maxWidth="lg" className={classes.container}>
          {/*----- Editor -----*/}
          <div className={classes.editorContainer}>
            {(get(doc,"groups",[])).map((group, index) => {
              return (
                <ExerciseGrpContainer
                  key={`group-${index + 1}`}
                  group={group}
                  index={ "index" in group ? group.index : index}
                  addExercise={() => onAddExerciseClick(index, true)}
                  isProgress={isProgress}
                  dayWiseWo={dayWiseWo}
                  woType={woType}
                  cid={cid}
                  minified={minified}
                  segmentDuration={groupWiseWoDuration[index]}
                />
              );
            })}
            <Card component={ClrdButton} color="invert" className={clsx(classes.button,!totalGrps && "mt-0")} onClick={() => onAddExerciseClick(null, false)}>
              <Typography className="font_15_600">Add Exercises</Typography>
            </Card>
          </div>
          {/*----- Summary -----*/}
          <div className={classes.summaryContainer}>
            <EditorSummary {...summary} className="fmb-20" {...props} cid={cid}/>
            <ImportSegment className="fmb-20" onClick={() => openWoSelector()}/>
            <ReorderSegment onClick={onReorderClick} disabled={totalGrps<=1}/>
          </div>
        </Container>
      <ExerciseSelector
        doc={doc}
        handleClose={handleClose}
        open={open}
        handleAdd={payload => handleExerciseAdd(payload)}
        insideGrp={insideGrp}
        grpIndex={grpIndex}
        selectedExercise = {_getSelectedExercises}
        allowDuplicate={true}
      />
      {isWoSelectorOpen && (
        <ImportWo
          handleClose={closeWoSelector}
          handleGroupAdd={(importGrpIndex, groups) => handleGrpAdd(importGrpIndex, groups)}
          workoutId={props.docId}
        />
      ) }
    </>
  )
}

const ImportSegment=({className,...rest})=>{
  const classes=styles();
  return(
    <Card elevation={0} component={ClrdButton} color="invert" className={clsx(classes.button,"fmb-20")} {...rest}>
      <Typography className="font_14_600 d-flex align-items-center">
          <DownloadIcon className="fmr-10" style={{fill:"none",width:'0.8em',height:"0.8em"}}/>
          <span>Import Segments from other Workouts</span>
      </Typography>
    </Card>
  )
}

const ReorderSegment=({onClick,disabled})=>{
  const classes=styles();
  return(
    <Card elevation={0} component={ClrdButton} color="invert" onClick={!disabled && onClick} className={classes.button} disabled={disabled}>
      <Typography className="font_14_600 d-flex align-items-center">
        <RedorderIcon fontSize="medium" color={!disabled?"primary":"disabled"} className="fmr-10"/>
        <span>Reorder Segments</span>
      </Typography>
    </Card>
  )
}

