import React, { useCallback, useRef } from 'react';

import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';

// import { useHasVideoInputDevices } from '../../../hooks/deviceHooks/deviceHooks';
import useLocalVideoToggle from './useLocalVideoToggle';


const useStyles = makeStyles(() => ({
  buttonContainer: {
    border: "1px solid #E0E0E0",
    width: "50px",
    height: "50px"
  },
  iconButton: {
    backgroundColor: "#D9322E!important", 
    border: "none"
  }
}));

const ToggleVideoButton = (props) => {
  const classes = useStyles(props);
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();

  const toggleVideo = useCallback(() => {
    toggleVideoEnabled();
  }, [toggleVideoEnabled]);

  return (
    <Tooltip
      title={!!isVideoEnabled ? 'Turn off camera' : 'Turn on camera'}
      placement="top"
      PopperProps={{ disablePortal: true }}
    >
      <IconButton
        className={`${classes.buttonContainer} fmr-20 ${!!isVideoEnabled ? "" : classes.iconButton}`}
        onClick={toggleVideo}
        style={{color: props.isLocal ? "#fff" : !!isVideoEnabled ? "#221F1F" : "#fff"}}
      >
        {isVideoEnabled ? <VideocamIcon /> : <VideocamOffIcon />}
      </IconButton>
    </Tooltip>
  );
}

export default ToggleVideoButton;