import React from "react";
import { Drawer } from "@material-ui/core";
import WoDetails from "./form";
import MealDetails from "fitbud/views/meals/mealInfo";
import SuppDetails from "fitbud/views/supplements/info";
import { useParams } from "react-router-dom";
import {DialogAppBar} from "fitbud/components/Dialog";
import { VIEWS_CONSTANTS } from "fitbud/utils/constants";
import ScheduleDetails from "fitbud/views/schedulePlanner/detail";


export const PublishedDrawer = ({onClose,keyName,...rest}) => {
  const {id} = useParams();
  return (
    <Drawer anchor="right" open>
      <DialogAppBar
        toolbarClass="height-60 text-capitalize"
        onClose={onClose}
        titleFont="h3"
        title={"Published Version"}
      />
      {keyName===VIEWS_CONSTANTS.WORKOUT && (
        <WoDetails id={id} isPreview={true}/>
      )}
      {keyName===VIEWS_CONSTANTS.MEAL && (
        <MealDetails id={id} isPreview={true}/>
      )}
      {keyName===VIEWS_CONSTANTS.SUPPLEMENTS && (
        <SuppDetails id={id} isPreview={true}/>
      )}
      {keyName===VIEWS_CONSTANTS.SCHEDULE && (
        <ScheduleDetails id={id} isPreview={true} {...rest}/>
      )}
    </Drawer>
  );
};
