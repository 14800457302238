import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const DownBlueArrowIcon = (props) => {
  return (
    <SvgIcon classes={{
        root: "small"
      }} width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} style={{fill:"none"}}>
      <path d="M12.0467 9.62012L8.00003 13.6668L3.95337 9.62012" stroke="#317FF5" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8 2.33325V13.5533" stroke="#317FF5" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>    </SvgIcon>
  );
};
