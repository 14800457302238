import builder from "../base/reducer";
const base = builder("services");
export default (state, action) => {
  switch (action.type) {
    case 'legacy':
      return {...state, legacy: action.list};
    default:
      return base(state, action);
  }
};
