import React from 'react';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';

export const Copyright=({className,...rest})=>{
  return(
    <Typography {...rest} className={clsx(className)} style={{color:"#65768C",zIndex:9}}>
      © {new Date().getFullYear()} FitBudd, Inc.
    </Typography>
  )
}
