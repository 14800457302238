import React, { useState, useEffect, useReducer, useMemo } from 'react';
import { Typography, Card, CardContent, Select, OutlinedInput, MenuItem, IconButton } from '@material-ui/core';
import { ClrdButton } from 'fitbud/components/form-fields';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import _ from 'lodash';
import { ExpandMore as KeyDown } from '@material-ui/icons/';
import moment from 'moment';
import { TimePicker } from '@material-ui/pickers';
// import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ClearIcon from '@material-ui/icons/Clear';

import update from 'immutability-helper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useClasses = makeStyles(() => ({
  card: {
    border: '1px solid #DDDDDD !important',
    borderRadius: '10px',
  },
  addBtn: {
    backgroundColor: '#ffff',
    border: '1px solid #DDDDDD',
    borderRadius: '10px',
  },
  mealSelect: {
    width: 180,
    // lineHeight: '36px !important',
  },
  required: {
    '&::after': {
      content: `" *"`,
      color: 'red',
    },
  },
  timePicker: {
    width: 180,
    height: '48px !important ',
    lineHeight:48
  },
  action_btn: {
    height: '40px !important ',
    padding: '9px 15px',
  },
  customTimePicker: {
    backgroundColor: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 3,
    '&:hover': {
      border: '1px solid black',
    },
  },
  error: {
    border: '1px solid red',
  },
  icon:{
    //  padding: '4px 6px', 
  }
}));

const _defaultState = { calories: 0, food_items: [], type: '', iftime: null };

const CustomTimePicker = (props) => {
  const { value, removeTime, required, error } = props;
  const classes = useClasses();
  return (
    <div {...props}>
      <div
        className={clsx(
          'h-100 d-flex justify-content-center align-items-center pl-10 pr-5 cursor-pointer',
          classes.customTimePicker,
          error && classes.error
        )}
      >
        {!!value && <Typography className="font_15_500 flex-grow-1">{value}</Typography>}
        {!!value && (
          <IconButton size="small" className={classes.icon} onClick={removeTime}>
            <ClearIcon />
          </IconButton>
        )}
        {!value && (
          <Typography className="font_15_500 flex-grow-1">
            Select Time {required && <span className={classes.required}></span>}
          </Typography>
        )}
        {!value && <IconButton style={{color:"#d1d1d1", padding:0}} className={classes.icon}><KeyboardArrowDownIcon  /></IconButton>}
      </div>
    </div>
  );
};

const AddMeal = (props) => {
  const { mealCats, groups, doc, updateParent } = props;
  const classes = useClasses();
  const [isAddOption, setIsAddOption] = useState(false);
  const [errors, setError] = useState({});
  const [state, setState] = useReducer((os, ns) => ({ ...os, ...ns }), _defaultState);
  const newMealCats = useMemo(() => {
    return _.filter(mealCats, (d) => !!d && !_.find(groups, (d2) => d2.type === d[0]));
  }, [mealCats, groups]);
  const sort_by = _.get(doc, 'sort_by');
  const isSortByTime = sort_by === 'time';

  //if groups has changed and selected type exists in groups then clear it: case manage category popup open when add meal
  useEffect(() => {
    const matchedType = _.find(groups, (g) => g.type === state.type);
    if (!!matchedType) setState({ type: '' });
  }, [groups, state]);

  const enableAddOption = () => setIsAddOption(true);
  const disableAddOption = () => setIsAddOption(false);

  const isValidate = () => {
    let out = true;
    if (!state.type || state.type === 'select category') {
      out = false;
      setError((s) => ({ ...s, type: true }));
    }
    if (isSortByTime && !state.iftime) {
      setError((s) => ({ ...s, iftime: true }));
      out = false;
    }
    return out;
  };

  const handleDiscard = () => {
    setState(_defaultState);
    disableAddOption();
  };

  const handleMealChange = (e) => {
    const key = [e.target.id || e.target.name];
    let value = e.target.value;
    setError((s) => ({ ...s, type: false }));
    setState({ [key]: value });
  };

  const handleMealTime = (date) => {
    if (!date) return;
    let time = moment(date).format('HH:mm');
    setState({ iftime: time });
  };

  const removeTime = (e) => {
    e.stopPropagation();
    setState({ iftime: null });
    setError((s) => ({ ...s, iftime: false }));
  };

  const handleAddMeal = () => {
    if (!isValidate()) return;
    let _updatedGroups = update(groups, {
      $push: [{ ...state }],
    });
    if (isSortByTime) {
      _updatedGroups = _.sortBy(_updatedGroups, ['iftime']);
    }
    //reset state:
    setState(_defaultState);
    setError({});
    disableAddOption();
    //update paren
    updateParent({ groups: _updatedGroups });
  };

  const ifTime = _.get(state, 'iftime', '');
  const timeValue = !!ifTime ? moment(ifTime, 'LT') : null;
  const addActionDisabled = !state || !state.type;

  return (
    <div>
      {isAddOption && (
        <Card className={clsx(classes.card)}>
          <CardContent className="d-flex pl-30  pr-20 py-25 align-items-center">
            <Select
              IconComponent={KeyDown}
              input={
                <OutlinedInput
                  classes={{
                    input: clsx('size_15_500 select-medium', classes.mealSelect, !state.type && classes.required),
                  }}
                  name="type"
                />
              }
              value={state.type ? state.type : 'select category'}
              error={!!errors && !!errors.type}
              onChange={handleMealChange}
              required
            >
              <MenuItem key={'select'} value={'select category'}>
                Select Category
              </MenuItem>
              {newMealCats.map((cat) => (
                <MenuItem key={cat[0]} value={cat[0]}>
                  {cat[1].value}
                </MenuItem>
              ))}
            </Select>
            <TimePicker
              fullWidth
              showTodayButton
              todayLabel="now"
              value={timeValue}
              onChange={handleMealTime}
              error={!!errors && !!errors.iftime}
              className={clsx('ml-20', classes.timePicker)}
              TextFieldComponent={(props) => {
                return (
                  <CustomTimePicker required={isSortByTime} removeTime={removeTime} value={timeValue} {...props} />
                );
              }}
            />
            <div className="flex-grow-1" />
            <ClrdButton
              className={classes.action_btn}
              color="primary"
              disabled={addActionDisabled}
              onClick={handleAddMeal}
              classes={{ label: 'font_15_600' }}
            >
              Add
            </ClrdButton>

            <ClrdButton
              onClick={handleDiscard}
              className={classes.action_btn}
              color="primary"
              classes={{ label: 'font_15_600' }}
            >
              Discard
            </ClrdButton>
          </CardContent>
        </Card>
      )}
      {!isAddOption && (
        <ClrdButton
          classes={{
            root: 'f-xxxlarge',
          }}
          variant="outlined"
          component="div"
          className={clsx('shadow-none', classes.addBtn)}
          onClick={enableAddOption}
        >
          <Typography color="primary" className="font_15_600">
            Add Meal Category
          </Typography>
        </ClrdButton>
      )}
    </div>
  );
};

export default AddMeal;
