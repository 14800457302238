import React from 'react';

const RefreshIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.0001 12C21.0001 16.9706 16.9706 21 12.0001 21C8.66879 21 5.76024 19.1901 4.2041 16.5"
        stroke="#37404D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M4 20.5V16.5H8" stroke="#37404D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M3.00006 12C3.00006 7.02944 7.0295 3 12.0001 3C15.3313 3 18.2399 4.80989 19.796 7.5"
        stroke="#37404D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M20 3.5V7.5H16" stroke="#37404D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export default RefreshIcon;
