import React, { Children, useMemo, useRef } from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import { ArrowBackSharp, ArrowForwardSharp } from '@material-ui/icons';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const BASE = {
  dots: true,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};
const styles = makeStyles(theme => ({
  button_bar: {
    position: 'absolute',
    display: 'flex !important',
    bottom:'-50px',
    transform: 'translateX(-3%)',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    listStyle:'none',
    '& .slick-active':{
      backgroundColor: '#000',
      width: '10px',
      height: '10px',
      borderRadius:'100%',
      cursor: 'pointer',
      marginRight: '10px',
      opacity:'100%',
    }
  },
  dot:{
    backgroundColor: '#000',
    opacity:'15%',
    cursor: 'pointer',
    marginRight: '10px',
    width: '8px',
    height: '8px',
    borderRadius:'100%',
  }
}));

const CheckinImageSlider = ({ children, num = 1, className = '', childClass }) => {
  const count = Children.count(children);
  const sliderRef = useRef();
  const nextSlide = () => sliderRef.current.slickNext();
  const prevSlide = () => sliderRef.current.slickPrev();
  const classes = styles();
  const settings = useMemo(() => {
    const out = {...BASE};
    if (num) {
      out.slidesToShow = num;
      out.slidesToScroll = num;
    }
    return out;
  }, [num]);
  return (
    <div className={`position-relative ${className}`}>
      <Slider {...settings} ref={sliderRef} 
        dotsClass={classes.button_bar}
        customPaging={(index) => <div className={classes.dot} />}>
        {children}
        {count < num && [...Array(num - count)].map((x, n) => <div key={`blank-${n + 1}`} className={childClass}/>)}
      </Slider>
      {count > num ? (
        <div className='d-flex justify-content-between align-items-center fmt-5'>
          <IconButton onClick={prevSlide}>
            <ArrowBackSharp style={{color: '#000'}} />
          </IconButton>
          <IconButton onClick={nextSlide}>
            <ArrowForwardSharp style={{color: '#000'}} />
          </IconButton>
        </div>
      ): null}
    </div>
  );
};

export default CheckinImageSlider;
