import axnsBuilder from "./actions";

export const DEFAULT_STATE = {
  files: [],
};

const axns = axnsBuilder();

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case axns.addFile:
      return {
        ...state,
        files: [...(state.files ? state.files : []), action.data],
      };
    case axns.updateFile:
      return {
        files: state.files.map((file) => {
          if (file.id === action.data.id) {
            return {
              ...file,
              ...action.data,
            };
          }
          return file;
        }),
      };
    case axns.clearFile:
      return {
        files: state.files.filter((file) => file.id !== action.id),
      };
    case axns.clearFiles:
      return DEFAULT_STATE;
    default:
      return state;
  }
};
