import React from 'react';
import {
  MenuItem, Select,
  OutlinedInput, Typography
} from '@material-ui/core';
import useDevices from './useDevices';
import useActiveSinkId from './useActiveSinkId';
import DropDownIcon from "@material-ui/icons/ExpandMore";
import { FormTextField } from "fitbud/components/form-fields";


const AudioOutputList = (props) => {
  const { audioOutputDevices } = useDevices();
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const activeOutputLabel = audioOutputDevices.find(device => device.deviceId === activeSinkId);

  return (
    <FormTextField classes={{ control: "fpb-0" }} fullWidth label="Speaker">
      <Select
        IconComponent={DropDownIcon}
        input={
          <OutlinedInput
            classes={{
              root: "height-40",
              input: "size_16_500 select-height-40",
            }}
            name="type"
            onChange={e => setActiveSinkId(e.target.value)}
            value={activeSinkId}
          />
        }
        required
        {...props}
      >
        {audioOutputDevices.length > 0 ? (
          audioOutputDevices.map(device => (
            <MenuItem value={device.deviceId} key={device.deviceId}>
              {device.label}
            </MenuItem>
          ))
        ) : (
          <MenuItem>
            {activeOutputLabel && activeOutputLabel.label || 'System Default Audio Output'}
          </MenuItem>
        )}
      </Select>
    </FormTextField>
  )
}

export default AudioOutputList;