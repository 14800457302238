import React, { createContext, useContext, useMemo, useEffect, useCallback, useState } from 'react';
import _ from 'lodash';
import firebase from 'fitbud/firebase';
import { getCatalogByIds } from 'fitbud/api';
import { packs as getPacks } from 'fitbud/api/s3cache';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { getServicesByIds } from 'fitbud/utils/services';

const DEBUG = false;

const AccessContext = createContext(undefined);

const EXCEPTKEYS = {
  classes: 'except',
  challenges: 'chExcept',
  services: 'service_except',
};

const accessForObj = (access, type, objId) => {
  if (!access) return false;
  const catData = access[type];
  if (!catData) return false;
  const objData = catData[objId];
  if (!objData) return false;
  if (type === 'packs') {
    const out = {};
    if (objData.service_config) out.service_config = objData.service_config;
    ['classes', 'services', 'challenges'].forEach(key => {
      const x_key = EXCEPTKEYS[key]; // KEY for except array in packs for this category
      const {[key]: yes, [x_key]: no} = objData;
      if (no && no.length) {
        out[key] = ['all'];
        out[x_key] = no || [];
      } else {
        out[key] = yes || [];
        out[x_key] = [];
      }
    });
    return out;
  } else {
    const {packs, except} = objData;
    if (except && except.length) return { packs:['all'], except };
    return {packs, except: []};
  }
};

const vidCallFromAccess = (packAccess) => {
  const {services = [], service_config} = packAccess;
  if (!service_config)
    return {...NO_LEGACY};
  const {all, ...rest} = service_config || {all: {count: 1, frequency: 'week'}};
  if (services.includes('all') && all)
    return {duration: 15, frequency: all.frequency || 'week', includes_calls: false, num_sessions: all.count || 1};
  for (let i = 0; i < services.length; i++) {
    const id = services[i];
    const match = id.match(/^vidcall_(\d+)$/);
    if (!match) continue;
    const duration = Number(match[1]);
    let num_sessions = 1, frequency = 'week';
    if (!_.isEmpty(rest)) {
      num_sessions = 0;
      frequency = 'plan';
    } else if (all) {
      num_sessions = all.count || 1;
      frequency = all.frequency || 'week';
    }
    return {duration, frequency, includes_calls: true, num_sessions};
  }
  if (all)
    return {duration: 15, frequency: all.frequency || 'week', includes_calls: false, num_sessions: all.count || 1};
  return {...NO_LEGACY};
};

function AccessProvider({ category, children }) {
  const { cid, hasClasses } = useContext(FirebaseAuthContext);
  const [access, setAccess] = useState(false); // raw access object loaded from DB
  // Array of IDs of packs/classes/services/challenges which don't offer any access
  const [classesNone, setCNone] = useState([]);
  const [servicesNone, setSNone] = useState([]);
  const [packsNoClass, setPCNone] = useState([]);
  const [packsNoSrvcs, setPSNone] = useState([]);
  const [target, setTarget] = useState(false); // ID of the object that is currently being displayed
  const [currentAccess, setCurrent] = useState(false); // copy of remote access for the current target
  const [editAccess, setEdit] = useState(false); // local edit object which will get merged to DB on Save
  const [currVidCall, setCurrVC] = useState({...NO_LEGACY}); // copy of remote vid_call for the current target pack
  const [editVidCall, setEditVC] = useState({...NO_LEGACY}); // local edit vid_call object for the current target pack
  const [plansCache, _pCache] = useState({}); // local map of plan id to data
  const [classCache, _cCache] = useState({}); // local map of class id to data
  const [serviceCache, _sCache] = useState({vidcall: LEGACY_SERVICE_BASE}); // local map of service id to data

  const loadRemoteAccess = useCallback(() => { // loads access DOC from DB and saves in local state
    firebase.firestore().doc(`companies/${cid}/misc/access`).get().then(doc => {
      if (!doc.exists) setAccess({});
      else setAccess(doc.data());
      ['packs', 'classes', 'services'].forEach(key => {
        const data = (doc.data() || {})[key];
        if (!data) return;
        const ids = Object.keys(data);
        const idsWithNoPacks = [], packsWithNoClasses = [], packsWithNoServices = [];
        ids.forEach(id => {
          const {packs, classes, services} = data[id] || {};
          if (key === 'packs') {
            if (!classes || !classes.length) packsWithNoClasses.push(id);
            if (!services || !services.length) packsWithNoServices.push(id);
          } else if (!packs || !packs.length) {
            if (key === 'services' && id.match(/^vidcall_\d+$/)) return;
            idsWithNoPacks.push(id);
          }
        });
        switch (key) { // eslint-disable-line default-case
          case 'packs':
            setPCNone(packsWithNoClasses);
            setPSNone(packsWithNoServices);
            break;
          case 'classes':
            setCNone(idsWithNoPacks);
            break;
          case 'services':
            setSNone(idsWithNoPacks);
            break;
        }
      });
    });
  }, [cid]);

  const getAccessType = useCallback(({key, current = true, init = false} = {}) => {
    if (category === 'packs') {
      const X_KEY = EXCEPTKEYS[key];
      const { [key]: yes = [], [X_KEY]: no = [] } = (current ? currentAccess : editAccess) || {};
      if (yes.includes('all')) {
        if (!!no && !!no.length) return ['except_all', no];
        return ['all', no];
      }
      if (current && (!yes || !yes.length)) {
        if (init || !target || target === 'new') return ['partial', []];
        else return ['none', []];
      }
      return ['partial', yes];
    } else {
      const { packs = [], except = [] } = (current ? currentAccess : editAccess) || {};
      if (packs.includes('all')) {
        if (!!except && !!except.length) return ['except_all', except];
        return ['all', except];
      }
      if (packs.includes('free')) {
        return ['free', []];
      }
      if (current && (!packs || !packs.length)) {
        if (!target || target === 'new') return ['partial', []];
        else return ['none', []];
      }
      return ['partial', packs];
    }
  }, [category, currentAccess, editAccess, target]);

  const getPackAccess = useCallback((id) => {
    if (!access) return {accessNA: true};
    const { services, classes, service_config } = (access.packs && access.packs[id]) || {}
    const { all, ...rest } = service_config || {};
    const hasClasses = classes && !!classes.length;
    const hasServices = services && !!services.length && ((all && !!all.count) || !_.isEmpty(rest))
    return {hasClasses, hasServices};
  }, [access]);

  // loads currentAccess from access DOC for the target DOC
  useEffect(() => {
    setCurrVC({...NO_LEGACY});
    if (!target || target === 'new') return setCurrent(false);
    const currAccess = accessForObj(access, category, target);
    setCurrent(currAccess);
    if (category === 'packs') {
      const vc = vidCallFromAccess(currAccess);
      setCurrVC(vc);
    }
  }, [access, category, target]);

  // loads the various cache objects for the target DOC
  useEffect(() => {
    if (category === 'packs') {
      // classes
      const currClassIds = getAccessType({key: 'classes'}).pop();
      const editClassIds = getAccessType({key: 'classes', current: false}).pop();
      const fetchClassIds = [...currClassIds, ...editClassIds, ...classesNone].filter(x => !classCache[x]);
      if (fetchClassIds.length) {
        getCatalogByIds({ cid, groupclasses: _.uniq(fetchClassIds) }).then(res => {
          const { data } = res || {};
          const classes = data.groupclasses;
          const _classes = {};
          classes.forEach(x => {
            _classes[x._id] = x;
          });
          _cCache(state => ({...state, ..._classes}));
        });
      }
      // services
      const currServiceIds = getAccessType({key: 'services'}).pop();
      const editServiceIds = getAccessType({key: 'services', current: false}).pop();
      const fetchServiceIds = [...currServiceIds, ...editServiceIds, ...servicesNone].filter(x => !serviceCache[x]);
      if (fetchServiceIds.length) {
        if (fetchServiceIds.length) {
          getServicesByIds(_.uniq(fetchServiceIds), cid).then(data=>{
            const _services = {};
            data.forEach(x => {
              _services[x._id] = x;
            });
            _sCache(state => ({...state, ..._services}));
          })
        }
      }
    } else if (_.isEmpty(plansCache))
      getPacks(cid).then(_pCache);
  }, [cid, category, getAccessType]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!plansCache || _.isEmpty(plansCache)) return; // cache isn't filled. Give up
    // cleanup packsNoClass & packsNoSrvcs as session packs cannot be considered in opposing category. And challenge add-ons are not to be considered at all
    const rmFromSrvcsArr = [], rmFromClassArr = [];
    packsNoSrvcs.forEach(id => {
      const data = plansCache[id];
      if (!data) return rmFromSrvcsArr.push(id); // if pack is not known, just ignore it
      const { type, add_on_type } = data;
      if (type === 'add_on' && ['challenges', 'group_class'].includes(add_on_type))
        rmFromSrvcsArr.push(id);
    });
    if (rmFromSrvcsArr.length) setPSNone(arr => _.without(arr, rmFromSrvcsArr));
    packsNoClass.forEach(id => {
      const data = plansCache[id];
      if (!data) return rmFromClassArr.push(id); // if pack is not known, just ignore it
      const { type, add_on_type } = data;
      if (type === 'add_on' && ['challenges', 'one_to_one'].includes(add_on_type))
        rmFromClassArr.push(id);
    });
    if (rmFromClassArr) setPCNone(arr => _.without(arr, rmFromClassArr));
  }, [plansCache]); // eslint-disable-line react-hooks/exhaustive-deps

  // loads the access DOC from DB
  useEffect(() => {
    loadRemoteAccess();
  }, [loadRemoteAccess]);

  // sets editVidCall from editAccess
  useEffect(() => {
    if (category !== 'packs') return;
    const vc = vidCallFromAccess(editAccess);
    setEditVC(vc);
  }, [category, editAccess]);

  const startEdit = useCallback(({ add_on_type } = {}) => {
    const out = _.cloneDeep(currentAccess) || {};
    if (!['group_class', 'one_to_one'].includes(add_on_type)) {
      if (out.services && out.services.length && !out.service_config)
        out.service_config = {all: {count: 1, frequency: 'week'}};
    }
    setEdit(out);
  }, [currentAccess]);

  const updateAccessPacks = useCallback(({ auto, push, pull, packs, except } = {}) => {
    if (auto) { // this sets packs as the packs with ALL access
      const packsWithAll = _.get(access, ['packs', 'all', category]) || [];
      setEdit({ packs: [...packsWithAll], except: [] });
    } else if (push) { // update state using immutability-helper
      const arr = (typeof push === 'string') ? [push] : (Array.isArray(push) ? push : []);
      setEdit(state => {
        if (!state) return {packs: arr, except: []};
        const key = ((state.except && state.except.length) || (state.packs && state.packs.includes('all'))) ? 'except' : 'packs';
        return {...state, [key]: arr};
      });
    } else if (pull) { // update state using immutability-helper
      setEdit(state => {
        if (!state) return {packs: [], except: []};
        const key = ((state.except && state.except.length) || (state.packs && state.packs.includes('all'))) ? 'except' : 'packs';
        const curr = state[key];
        if (!curr || !curr.length) return state;
        return {...state, [key]: _.without(curr, pull)};
      });
    } else if (except) {
      setEdit({ packs: ['all'], except });
    } else if (packs && packs.length) {
      if (packs.includes('free')) setEdit({ packs: ['free'], except: [] });
      else if (packs.includes('all')) setEdit({ packs: ['all'], except: [] });
      else setEdit({ packs, except: [] });
    } else setEdit({ packs: [], except: [] });
  }, [access, category]);

  const updateTargetAccess = useCallback((key, { auto, push, pull, ids, except, add_on_type } = {}) => {
    const X_KEY = EXCEPTKEYS[key];
    if (add_on_type) {
      let servicesWithAll = _.get(access, ['services', 'all', 'packs']) || [];
      let classessWithAll = _.get(access, ['classes', 'all', 'packs']) || [];
      if (!hasClasses) servicesWithAll.push('vidcall_15');
      const out = {
        services: [], [EXCEPTKEYS.services]: [], service_config: {},
        classes: [], [EXCEPTKEYS.classes]: [], challenges: [], [EXCEPTKEYS.challenges]: []};
      switch (add_on_type) {
        case 'one_to_one':
          out.services = [...servicesWithAll];
          out.service_config = {};
          break;
        case 'group_class':
          out.classes = [...classessWithAll];
          break;
        case 'challenges':
          throw new Error('WIP');
        default:
          out.services = [...servicesWithAll];
          out.service_config = {all: {frequency: 'week', count: 1}};
          out.classes = [...classessWithAll];
          break;
      }
      setEdit(out);
    } else if (auto) { // this sets access as the objects with ALL access
      if (key === 'services') {
        const { num_sessions, frequency } = editVidCall || NO_LEGACY;
        let servicesWithAll = _.get(access, ['services', 'all', 'packs']) || [];
        if (!hasClasses) servicesWithAll.push('vidcall_15');
        setEdit(x => ({...x, [key]: [...servicesWithAll], [X_KEY]: [], service_config: {all: {frequency: frequency || 'week', count: num_sessions || 1}} }));
      } else if (key === 'classes') {
        const objsWithAll = _.get(access, [key, 'all', 'packs']) || [];
        setEdit(x => ({...x, [key]: [...objsWithAll], [X_KEY]: [] }));
      }
    } else if (push) { // update state using immutability-helper
      const arr = (typeof push === 'string') ? [push] : (Array.isArray(push) ? push : []);
      setEdit(state => {
        if (!state) return {[key]: arr, [X_KEY]: []};
        const tmp = ((state[X_KEY] && state[X_KEY].length) || (state[key] && state[key].includes('all'))) ? X_KEY : key;
        return {...state, [tmp]: arr};
      });
    } else if (pull) { // update state using immutability-helper
      setEdit(state => {
        if (!state) return {[key]: [], [X_KEY]: []};
        const tmp = ((state[X_KEY] && state[X_KEY].length) || (state[key] && state[key].includes('all'))) ? X_KEY : key;
        const curr = state[tmp];
        if (!curr || !curr.length) return state;
        return {...state, [tmp]: _.without(curr, pull)};
      });
    } else if (except) {
      setEdit(x => ({...x, [key]: ['all'], [X_KEY]: except }));
    } else if (ids && ids.length) {
      if (ids.includes('all')) setEdit(x => ({...x, [key]: ['all'], [X_KEY]: [] }));
      else setEdit(x => ({...x, [key]: ids, [X_KEY]: [] }));
    } else setEdit(x => ({...x, [key]: [], [X_KEY]: [] }));
  }, [access, hasClasses, editVidCall]);

  const saveAccessChanges = useCallback(async({objId, current = false} = {}) => {
    if (!current && !editAccess) return; // save is requested, but nothing has changed
    if (!objId) {
      if (!target || target === 'new') throw new Error('Unknown Access Target');
      objId = target;
    }
    const db = firebase.firestore();
    await db.runTransaction(async txn => {
      const doc = await txn.get(db.doc(`companies/${cid}/misc/access`)); // load latest access snapshot
      const data = doc.data() || {};
      const payload = {};
      if (category === 'packs') {
        _.set(payload, ['packs', objId], {});
        ['classes', 'services', 'challenges'].forEach(key => {
          const X_KEY = EXCEPTKEYS[key];
          const {[key]: yesOld = [], [X_KEY]: noOld = [], service_config: srvcConfOld = null } = (current ? false : currentAccess) || {};
          const {[key]: yesNew = [], [X_KEY]: noNew = [], service_config: srvcConfNew = null } = (current ? currentAccess : editAccess) || {};
          let srvcConfChanged = false,  noChange = _.isEqual(_.sortBy(yesOld), _.sortBy(yesNew)) && _.isEqual(_.sortBy(noOld), _.sortBy(noNew));
          if (key === 'services') srvcConfChanged = !_.isEqual(srvcConfOld, srvcConfNew);
          if (noChange && !srvcConfChanged) return; // nothing to do, niether access has changed nor exceptions have changed
          const isThisAllAccess = !!yesNew.includes('all');
          _.set(payload, ['packs', objId, key], yesNew);
          _.set(payload, ['packs', objId, X_KEY], noNew);
          _.set(data, ['packs', objId, key], yesNew);
          _.set(data, ['packs', objId, X_KEY], noNew);
          if (srvcConfChanged) {
            _.set(payload, ['packs', objId, 'service_config'], srvcConfNew);
            _.set(data, ['packs', objId, 'service_config'], srvcConfNew);
          }

          // Add / Remove from the special all object's array depending on if THIS is ALL access or NOT
          if (isThisAllAccess) _push(data, payload, objId, 'packs', 'all', key);
          else _pull(data, payload, objId, 'packs', 'all', key);

          // iterate over ALL objects and update them to match expectations
          const xids = Object.keys(data[key] || {});
          xids.forEach(xid => {
            if (xid === 'all') return;
            const { packs: xYes } = accessForObj(data, key, xid);
            const isXAllAccess = xYes && xYes.includes('all');
            const isXFree = xYes && xYes.includes('free');
            const isXSelected = yesNew && yesNew.includes(xid);
            const isXRejected = noNew && noNew.includes(xid);
            if (isThisAllAccess) {                                  // target is ALL access
              if (isXAllAccess) {                                   // AND X is also ALL access
                if (isXRejected)                                    // but X is rejected by target => Add reverse exception
                  _push(data, payload, objId, key, xid, 'except');
                else                                                // else => remove from X's exception IN CASE it was previously there
                  _pull(data, payload, objId, key, xid, 'except');
              } else if (isXRejected)                               // else if rejected => remove from X's specific array IN CASE it was previously there
                _pull(data, payload, objId, key, xid, 'packs');
              else if (xYes && xYes.length)                         // X is specific => add THIS to it's specific array
                _push(data, payload, objId, key, xid, 'packs');
            } else if (isXSelected) {                               // X is explicitly selected
              if (isXAllAccess)                                     // X is ALL access => remove THIS from X's except IN CASE it was previously there
                _pull(data, payload, objId, key, xid, 'except');
              else if (isXFree) {                                   // X will no longer be free because plan says so
                _.set(payload, [key, xid, 'packs'], [objId]);
                _.set(payload, [key, xid, 'except'], []);
              } else                                                // else => add THIS to X's specific array
                _push(data, payload, objId, key, xid, 'packs');
            } else if (isXAllAccess) {                              // X is ALL access => add THIS to X's except array
              _push(data, payload, objId, key, xid, 'except');
            } else {                                                // neither are ALL access & X is not selected => pull THIS from X's packs
              _pull(data, payload, objId, key, xid, 'packs');
            }
          });
          yesNew.forEach(xid => { // for any other Xs mentioned in this target but not already present in ACCESS, build it now
            if (['all', 'free', 'trial'].includes(xid)) return;
            if (xids.includes(xid)) return;
            const { packs: xYes } = accessForObj(data, key, xid);
            const isXAllAccess = xYes && xYes.includes('all');
            if (isXAllAccess) // if X is all access then remove this from X's except
              _pull(data, payload, objId, key, xid, 'except');
            else // else add this to packs access
              _push(data, payload, objId, key, xid, 'packs');
          });
        });
      } else {
        const intended = current ? currentAccess : editAccess;
        const isThisAllAccess = !!intended && intended.packs.includes('all');
        const isThisFree = !!intended && intended.packs.includes('free');
        const {packs = [], except = []} = intended || {};
        const X_KEY = EXCEPTKEYS[category]; // KEY for except array in packs for this category
        _.set(payload, [category, objId], {packs, except});
        _.set(data, [category, objId], {packs, except});

        // Add / Remove from the special all object's packs array depending on if THIS is ALL access or NOT
        // FIXME - I don't know why this special all object is even there ???
        if (isThisAllAccess) _push(data, payload, objId, category, 'all', 'packs');
        else _pull(data, payload, objId, category, 'all', 'packs');

        // iterate over ALL packs and update them to match expectations
        const packIds = Object.keys(data.packs || {});
        packIds.forEach(packId => {
          if (packId === 'all') return;
          const { [category]: packYes } = accessForObj(data, 'packs', packId);
          const isPackAllAccess = packYes && packYes.includes('all');
          const isPackSelected = packs && packs.includes(packId);
          const isPackRejected = except && except.includes(packId);
          if (isThisFree) {                                           // target is FREE => it should NOT be in specific NOR except array of any pack
            _pull(data, payload, objId, 'packs', packId, category);
            _pull(data, payload, objId, 'packs', packId, X_KEY);
          } else if (isThisAllAccess) {                               // target is ALL access
            if (isPackAllAccess) {                                    // AND pack is also ALL access
              if (isPackRejected)                                     // but pack is rejected by target => Add reverse exception
                _push(data, payload, objId, 'packs', packId, X_KEY);
              else                                                    // else => remove from pack's exception IN CASE it was previously there
                _pull(data, payload, objId, 'packs', packId, X_KEY);
            } else if (isPackRejected)                                // else if rejected => remove from pack's specific array IN CASE it was previously there
              _pull(data, payload, objId, 'packs', packId, category);
            else if (packYes && packYes.length)                       // pack is specific => add THIS to it's specific array
              _push(data, payload, objId, 'packs', packId, category);
          } else if (isPackSelected) {                                // pack is explicitly selected
            if (isPackAllAccess)                                      // pack is ALL access => remove THIS from pack's except IN CASE it was previously there
              _pull(data, payload, objId, 'packs', packId, X_KEY);
            else                                                      // else => add THIS to pack's specific array
              _push(data, payload, objId, 'packs', packId, category);
          } else if (isPackAllAccess) {                               // pack is ALL access => add THIS to pack's except array
            _push(data, payload, objId, 'packs', packId, X_KEY);
          } else {                                                    // neither are ALL access & pack is not selected => pull THIS from packs's access
            _pull(data, payload, objId, 'packs', packId, category);
          }
        });
        packs.forEach(packId => { // for any other packs mentioned in this target but not already present in ACCESS, build it now
          if (['all', 'free', 'trial'].includes(packId)) return;
          if (packIds.includes(packId)) return;
          const { [category]: packYes } = accessForObj(data, 'packs', packId) || {};
          const isPackAllAccess = packYes && packYes.includes('all');
          if (isPackAllAccess) // if pack is all access then remove this from pack's except
            _pull(data, payload, objId, 'packs', packId, X_KEY);
          else // else add this to packs access
            _push(data, payload, objId, 'packs', packId, category);
        });
      }
      if (_.isEmpty(payload[category][objId]))
        delete payload[category];
      await txn.set(doc.ref, payload, {merge: true}); // complete the transaction
      setAccess(data); // update local state with updated data
      setEdit(false);
    });
  }, [cid, category, target, currentAccess, editAccess]);

  /****** LEGACY VID CALL RELATED SETTERS *******/

  const updateLegacyCount = useCallback((count, price = 'all') => {
    setEdit(state => {
      const out = {...state};
      if (!out.service_config) out.service_config = {}
      if (price === 'all') {
        if (!out.service_config.all) out.service_config.all = {};
        out.service_config.all.count = count;
        if (!out.service_config.all.frequency)
          out.service_config.all.frequency = editVidCall.frequency || 'week';
      } else {
        if (out.service_config.all) delete out.service_config.all;
        if (!out.service_config[price]) out.service_config[price] = {};
        out.service_config[price].count = count;
        out.service_config[price].frequency = 'plan'; // one-to-one add-ons are the only packs that store count against prices and their frequency is always plan
      }
      return out;
    });
  }, [editVidCall]);

  const updateLegacyConf = useCallback((key, e, price = 'all') => { // takes a Select event. Like a usePicker
    let out = null;
    if (e && e.target && e.target.value !== "none")
      out = e.target.value;
    else if (typeof e === 'string' || typeof e === 'number')
      out = e;
    if (key === 'duration') {
      const val = Number(out);
      setEdit(state => {
        const out = {...state};
        const list = [...(out.services || [])].filter(x => !x.match(/^vidcall_\d+$/));
        list.push(`vidcall_${val}`);
        out.services = list;
        return out;
      });
    } else if (key === 'frequency') {
      const val = out;
      setEdit(state => {
        const out = {...state};
        if (!out.service_config) out.service_config = {}
        if (!out.service_config[price]) out.service_config[price] = {};
        out.service_config[price].frequency = val;
        return out;
      });
    }
  }, []);

  const updateLegacyFrequency = useCallback((e) => { // takes a Select event. Like a usePicker
    updateLegacyConf('frequency', e);
  }, [updateLegacyConf]);

  const updateLegacyDuration = useCallback((e) => {
    updateLegacyConf('duration', e);
  }, [updateLegacyConf]);

  /**********************************************/

  const value = useMemo(() => {
    return {
      // states
      currentAccess, editAccess, currVidCall, editVidCall, plansCache, classCache, serviceCache, access,
      // arrays of IDs of objects which offer no access
      classesNone, servicesNone, packsNoClass, packsNoSrvcs,
      // callbacks
      setTarget, startEdit, saveAccessChanges, updateAccessPacks, updateTargetAccess, getAccessType, getPackAccess,
      // legacy callbacks
      updateLegacyDuration, updateLegacyFrequency, updateLegacyCount,
    };
  }, [
    currentAccess, editAccess, currVidCall, editVidCall, plansCache, classCache, serviceCache, access,
    classesNone, servicesNone, packsNoClass, packsNoSrvcs,
    setTarget, startEdit, saveAccessChanges, updateAccessPacks, updateTargetAccess,  getAccessType, getPackAccess,
    updateLegacyDuration, updateLegacyFrequency, updateLegacyCount,
  ]);

  return (
    <AccessContext.Provider value={value}>
      {children}
      {DEBUG && <pre className='position-fixed bg-black text-white inset-top-left h-mx100 overflow-auto' style={{zIndex: 9999}}>
        {JSON.stringify({target, editAccess, editVidCall, currentAccess}, null, 2)}
      </pre>}
    </AccessContext.Provider>
  );
};

const LEGACY_SERVICE_BASE = {
  facility: false, archive: false, legacy: true, description: '', drop_in_rate: null, duration: 0, // NOTE duration is 0 for a reason
  index: 0, locations: ['app'], mode: 'online', status: 'active',  ref_name: 'Video Call', title: 'Video Call',
  thumbnail: 'https://cdn-images.fitbudd.com/public/vidcall.jpg',
};

const LEGACY_THUMBS = {
  vidcall_15: 'https://cdn-images.fitbudd.com/public/15min-20241021.jpg',
  vidcall_30: 'https://cdn-images.fitbudd.com/public/30min-20241021.jpg',
  vidcall_45: 'https://cdn-images.fitbudd.com/public/45min-20241021.jpg',
  vidcall_50: 'https://cdn-images.fitbudd.com/public/50min-20241021.jpg',
  vidcall_60: 'https://cdn-images.fitbudd.com/public/60min-20241021.jpg',
  vidcall_90: 'https://cdn-images.fitbudd.com/public/90min-20241021.jpg',
};

// builds and returns a legacy service object
const LEGACY_SERVICE = (id) => {
  const match = id.match(/^vidcall_(\d+)$/);
  if (!match) return null;
  const duration = Number(match[1]);
  const thumbnail = LEGACY_THUMBS[id] || 'https://cdn-images.fitbudd.com/public/vidcall.jpg';
  return {...LEGACY_SERVICE_BASE, duration, thumbnail};
};

export const DEFAULT_LEGACY_SERVICE_ID = "vidcall_15";
const NO_LEGACY = {duration: 15, frequency: 'week', includes_calls: false, num_sessions: 1};

export { AccessContext, AccessProvider, LEGACY_SERVICE_BASE, LEGACY_SERVICE };

// HELPER FNs
// pushes / pulls value to / from the array denoted by path
// for data it actually mutates the array using lodash
// for payload it sets a arrayUnion / arrayRemove DB OP
const _push = (data, payload, value, ...path) => {
  _.set(payload, path, firebase.firestore.FieldValue.arrayUnion(value));
  const current = _.get(data, path);
  if (!current) _.set(data, path, [value]);
  else if (!current.includes(value)) current.push(value);
};
const _pull = (data, payload, value, ...path) => {
  _.set(payload, path, firebase.firestore.FieldValue.arrayRemove(value));
  const current = _.get(data, path);
  if (!current || !current.length) return; // nothing to do
  _.pull(current, value);
};
