export const getUserAgent = () => (window.navigator.userAgent)

export const isStandAlone = () => {
  try {
    return (window.navigator.standalone == true || // eslint-disable-line eqeqeq
      window.matchMedia('(display-mode: standalone)').matches)
  } catch (err) {
    return false
  }
}

export const standAloneSafeTargetProps = () => {
  if (isStandAlone()) return {}
  else return { target: '_blank', rel: 'noopener noreferrer' }
}

export const isInAppBrowser = (getUserAgent().match(/fitbud/i) != null)

export const isAndroid = (getUserAgent().match(/android/i) != null)

export const isIPad = (getUserAgent().match(/iPad/i) != null)

export const isIPhone = (getUserAgent().match(/iPhone/i) != null)

export const isIOS = (isIPad || isIPhone)

export const isMobile = (isIOS || isAndroid)
