import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const EditedCardIcon = (props) => (
  <SvgIcon
    width="26px"
    height="26px"
    viewBox="0 0 26 26"
    version="1.1"
    {...props}
  >
     <g id="App" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group-15@2x">
            <path d="M3,0 L25.3849641,0 L0,25.3849641 L0,3 C2.41183085e-16,1.34314575 1.34314575,7.48448398e-16 3,0 Z" id="Rectangle" fill="#E0E0E0" fill-rule="nonzero"></path>
            <g id="Group-13" stroke-linecap="round" transform="translate(5.000000, 5.000000)" stroke="#808080" stroke-width="0.5">
                <path d="M4.7080376,0.125370051 L5.76118693,1.08016775 C5.95506636,1.25594116 5.96567299,1.55123815 5.78487749,1.73973205 L1.91582713,5.77352925 C1.87753649,5.81345033 1.82606179,5.8390565 1.77035906,5.84589257 L0.0540113648,6.05652995 C0.0277104105,6.05975771 0.00369789471,6.04164545 0.000377909626,6.01607508 C1.43341321e-05,6.01327484 -8.71908185e-05,6.0104483 7.45830364e-05,6.00763022 L0.0964760472,4.32833033 C0.0996046816,4.27382996 0.122287609,4.22212082 0.160578244,4.18219974 L4.02962861,0.148402544 C4.21042411,-0.0400913546 4.51415816,-0.0504033529 4.7080376,0.125370051 Z" id="Combined-Shape"></path>
                <path d="M2.01591901,5.66074019 C1.61714281,5.51190033 1.27148398,5.30486961 0.978942495,5.03964803 C0.686401014,4.77442644 0.448419402,4.45232179 0.264997659,4.07333408 L0.264997659,4.07333408" id="Path"></path>
                <path d="M3.37491018,0.830999332 L5.13015907,2.42232884 L3.37491018,0.830999332 Z" id="Path-323"></path>
                <path d="M1.05573856,4.97019219 L3.41163322,2.51648939" id="Path-316"></path>
            </g>
        </g>
    </g>
  </SvgIcon>
);

export default EditedCardIcon;
