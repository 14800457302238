import React, { useContext, useCallback, useEffect } from 'react';
import {
  Select,
  MenuItem,
  OutlinedInput,
  FormControlLabel,
  InputAdornment,
  Grid,
  Typography,
  TextField,
  Switch,
  Divider,
} from '@material-ui/core';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useInput, useToggle } from 'fitbud/hooks/form';
import { ToggleLabel } from 'fitbud/components/toggles';
import { FormTextField } from 'fitbud/components/form-fields';
import GcAccess from './gcAccess';
import ServiceAccess from './servicesAccess';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { AccessContext } from 'fitbud/providers/access-provider';

const FT = [
  { value: 'week', label: 'Per Week' },
  { value: 'month', label: 'Per Month' },
  { value: 'plan', label: 'Total' },
];

const RegularCalling = (props) => {
  const { fnRef, setDirty } = props;
  const {comp} = useContext(FirebaseAuthContext)
  const { legacy = [] } = useSelector((s) => s.services);
  const {getAccessType, updateTargetAccess, currVidCall, editVidCall, updateLegacyFrequency, updateLegacyDuration, updateLegacyCount} = useContext(AccessContext);
  const company = comp ? comp.data() : {};
  const noVidCall = Boolean(_.get(company, 'features.video_calling', false)) === false;
  const onSrvcsToggle = useCallback((enabled) => updateTargetAccess('services', enabled ? {auto: true} : {}), [updateTargetAccess]);
  const [srvcsEnabled, toggleSrvcs] = useToggle(noVidCall ? false : (getAccessType({key: 'services'})[0] !== 'none'), onSrvcsToggle);
  const onClassToggle = useCallback((enabled) => updateTargetAccess('classes', enabled ? {auto: true} : {}), [updateTargetAccess]);
  const [classEnabled, toggleClass] = useToggle(getAccessType({key: 'classes'})[0] !== 'none', onClassToggle);
  const isGroupClass = Boolean(_.get(company, "features.group_class.enabled", false));
  const {
    parsed: num_sessions,
    props: nProps,
    isValid: nValid,
  } = useInput(currVidCall.num_sessions || 1, 'number * 1:min');

  useEffect(() => {
    if(setDirty) setDirty({editVidCall})
    fnRef.current = (setState) => {
      if (!setState) return false;
      if (editVidCall.includes_calls && !editVidCall.num_sessions) return false;
      setState((curr) => ({...curr, vid_call: editVidCall}));
      return true;
    };
  }, [fnRef, editVidCall, setDirty]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (nValid()) updateLegacyCount(num_sessions);
  }, [num_sessions]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="my-15 fmx-20 w-100">
      {/* --------- 1 - 1 calling setting -------- */}
      <div>
        <FormControlLabel
          control={<Switch color="primary" />}
          label={<Typography variant="h3">1 on 1 Sessions</Typography>}
          className="mb-0 mb-15"
          checked={srvcsEnabled}
          disabled={noVidCall}
          onChange={toggleSrvcs}
        />
        <Grid container justify="space-between">
          <Grid item xs={12} sm={6} className="pr-sm-2">
            <FormTextField fullWidth label="Number of Sessions">
              <TextField
                required
                {...nProps}
                variant="outlined"
                disabled={!srvcsEnabled}
                InputProps={{
                  classes: { root: 'medium pr-1', input: 'size_16_500 medium' },
                  endAdornment: (
                    <InputAdornment position="end">
                      <ToggleLabel disabled={!srvcsEnabled} size="small" value={editVidCall.frequency} options={FT} onChange={updateLegacyFrequency} />
                    </InputAdornment>
                  ),
                }}
              />
            </FormTextField>
          </Grid>
          {!isGroupClass && <Grid item xs={12} sm={6} className="pl-sm-2">
            <FormTextField fullWidth label="Duration">
              <Select
                disabled={!srvcsEnabled}
                fullWidth
                required
                value={editVidCall.duration}
                onChange={updateLegacyDuration}
                variant="outlined"
                input={<OutlinedInput classes={{ root: 'medium', input: 'size_16_500 select-medium' }} />}>
                {legacy.map(x => {
                  const {duration, status} = x;
                  if (status !== 'active') return null;
                  return <MenuItem key={duration} value={duration}>{duration} mins</MenuItem>
                })}
              </Select>
            </FormTextField>
          </Grid>}
        </Grid>
        {srvcsEnabled && isGroupClass && (
          <div>
            <div className="info-box-blue p-15 mb-20">
              <Typography className="font_14_500">
               Clients on this payment plan can book appointments for the 1 on 1 sessions specified below.
              </Typography>
            </div>
            <ServiceAccess disabled={!srvcsEnabled} setDirty={setDirty} legacy/>
          </div>
        )}
      </div>
      {/*--------- Group Class Access ------------*/}
      {isGroupClass && <div>
        <Divider className="mb-20" />
        <FormControlLabel
          control={<Switch color="primary" />}
          label={<Typography variant="h3">Group Classes</Typography>}
          className="mb-0 mb-15"
          checked={classEnabled}
          onChange={toggleClass}
        />
        <div className="info-box-blue p-15 mb-20">
          <Typography className="font_14_500">
           The clients on this plan can book any of the specified group classes as long as their membership is active.
          </Typography>
        </div>
        <GcAccess disabled={!classEnabled} setDirty={setDirty}/>
      </div>}
    </div>
  );
};
export default RegularCalling;
