import { useState, useCallback, useEffect, useContext } from "react";
import _ from "lodash";
import firebase from "fitbud/firebase";
import { useSelector, useDispatch } from 'react-redux'
import trainerRdxFns from "fitbud/redux/trainer";
import { snapshotDocsArrayToDataArray } from "fitbud/utils/helpers";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";

export const parseTrainer = (d, cid) => {
  const meta = _.get(d, `data.meta.${cid}`, {});
  if (!meta.name) meta.name = d.data.name || d.data.email.replace(/@.*/, '');
  return { data: { ...meta, email: d.data.email }, _id: d._id };
}

const RGX = /appstreet.io|iappstreet.com|fitbudd.com/i;
const filter = cid => data => {
  if (!data || !data.cids || !data.cids.length || !data.email) return false;
  // if (data.email.match(RGX)) return false; //@TODo :: need to remove.
  if (data.cids.includes('fitbud')) return false;
// either person owns this company and nothing else, or has an explicitly set trainer role
  return (data.cids.length === 1 && data.cids[0] === cid)
    || _.get(data, `meta.${cid}.role`) === 'trainer';
};

const useTrainers = (_cid) => {
    const { cid } = useContext(FirebaseAuthContext);
    const __cid = cid || _cid;
    const [loading, setLoading] = useState(false);
    const trainers = useSelector(state => _.get(state,"trainer.docs",[]))
    const [data, setData] = useState(trainers);
    const dispatch = useDispatch();
    const { set: setTrainer } = trainerRdxFns(dispatch);
    const _filter = useCallback(filter(__cid), [__cid]);
    useEffect(() => {
        if (!data || !data.length) {
            setLoading(true)
            firebase.firestore().collection("/staff").where('cids', 'array-contains', __cid).get()
            .then((resp) => {
                const data = snapshotDocsArrayToDataArray(resp.docs, true, _filter);
                const out = data.map(x => parseTrainer(x, __cid));
                setData(out)
                setTrainer(out, 0, true)
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
        }
    }, [__cid, _filter]);

    const getTrainer = useCallback(
      (id) => {
        const f = _.find(data, (t)=>t._id === id);
        return f || {};        
      },
      [data]
    );
    return { loading, data, getTrainer }
}

export default useTrainers;
