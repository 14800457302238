import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  videoContainer: {
    transform: (props) => (props.isLocal ? 'rotateY(180deg)' : ''),
    objectFit: 'cover',
    width: '100%',
    height: '100%'
  }
}));

const VideoTrack = (props) => {
  const { track } = props;
  const classes = useStyles(props);
  const ref = useRef();
  useEffect(() => {
    const videoTrack = track;
    if (videoTrack) {
      videoTrack.attach(ref.current);
      // return () => {
      //   videoTrack.detach();
      // };
    }
  }, [track]);

  return (
    <video className={classes.videoContainer} ref={ref} autoPlay={true} />
  )
}

export default VideoTrack;