import React, {
  useEffect,
  useState,
  useContext,
  useReducer,
  useCallback,
  useRef,
  useMemo
} from "react";
import firebase from "fitbud/firebase";
import { useHistory } from "react-router-dom";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import { AnalyticsContext } from "fitbud/providers/analytics";
import { withSnackbar } from "notistack";
import { connect, useDispatch, useSelector } from "react-redux";
import "emoji-mart/css/emoji-mart.css";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Typography,
  Divider,
  Menu,
  MenuItem,
  Hidden,
  Tooltip,
  CircularProgress
} from "@material-ui/core";
import clsx from 'clsx';
import appRdxFns from "fitbud/redux/app";
import withWidth from "@material-ui/core/withWidth/index";
import ChatInput from 'fitbud/views/chats/chatInput';
import { pluralize } from "fitbud/utils/helpers";
import AudioRecorder from "fitbud/components/audio-recorder/audioRecorder";
import MediaPreview from "fitbud/views/chats/mediaPreview";
import { DEFAULT_ERROR } from "fitbud/utils/constants";
import { handleError } from "fitbud/utils/helpers";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Confirmation from "fitbud/components/confirmationDialog";
import PageNotFound from "fitbud/views/pageNotFound";
import CircularLoader from "fitbud/components/CircularLoader";
import { GroupTypeIndicator } from "./groupChatList";
import { resizeAndUpload } from "../broadcasts/broadcastView";
import groupChatReduxFns from "fitbud/redux/groupChatList";
import GroupChatMessageListView from "./groupChatMessageListView";
import ClientDialog from "./clientDialog";
import { AvatarImage } from "../users/header";
import { DEFAULT_GC_GROUPS, updateGroupClients, updateGroupToUserProfiles } from "./helper";
import MultiClientSelector from "fitbud/components/multiClientSelector";
import GroupNameDialog from "./groupNameDialog";
import _ from "lodash";
import { bffGetUserProfileByIDs, bffDeleteGroup } from "fitbud/api";
import { WorkerContext } from "fitbud/providers/workerProvider";
import { MoreLessTextBtn } from "fitbud/components/moreLessText";
import { getFilterString } from "../broadcasts/broadcastList";
import PeopleGroup from "fitbud/images/people_group_2.svg";
import { ClrdButton } from "fitbud/components/form-fields";
import { planRdxFns } from "fitbud/redux/exports";
import { parsePacks } from "../plan/helper";
import moment from "moment";

const CHAT_FILE_SIZE_DEFAULT = 3; // MB

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  preview: {
    width: "100%",
    height: "max-content",
    minHeight: "100%",
    top: 0,
    backgroundColor: "white"
  },
  rootPaper: {
    padding: "10px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#f2f2f2",
    borderRadius: "0",
    border: "none"
  },
  input: {
    // width: "90%",
    borderRadius: "25px",
    backgroundColor: "#ffffff",
    padding: "10px 25px 10px 20px"
  },
  toolbar: {
    borderLeft: "none"
  },
  noMinHeight: {
    minHeight: "unset"
  },
  chatArea: {
    background: "#ffffff",
    flex: "1",
    minHeight: "0",
    overflow: "scroll",
    [theme.breakpoints.down("xs")]: {
      height: "calc(100vh - 187px)"
    }
  },
  bubble: {
    width: "100%",
    whiteSpace: "pre-wrap"
  },
  timeLeft: {
    marginRight: "auto",
    marginLeft: "30px",
    marginTop: "10px",
    justifyContent: "start",
    color: "#818181"
  },
  timeRight: {
    marginTop: "10px",
    marginRight: "30px",
    marginLeft: "auto",
    justifyContent: "flex-end",
    color: "#818181"
  },

  leftMsg: {
    width: "max-content",
    maxWidth: "50%",
    background: "#f7f7f7",
    wordWrap: "break-word",
    marginRight: "auto",
    borderBottom: "none",
    marginLeft: "30px",
    position: "relative",
    borderRadius: "0 15px 15px 15px"
  },

  rightMsg: {
    width: "max-content",
    maxWidth: "50%",
    background: "white",
    wordWrap: "break-word",
    marginRight: "30px",
    border: "solid 1px #cccccc",
    marginLeft: "auto",
    borderRadius: "15px 0 15px  15px"
  },
  mediaRight: {
    padding: 0,
    paddingRight: "30px",
    marginLeft: "auto",
    border: "none"
  },
  mediaLeft: {
    padding: 0,
    paddingLeft: "30px",
    marginRight: "auto",
    border: "none"
  },
  dateTab: {
    width: "160px",
    backgroundColor: "#dfecff",
    borderRadius: "16px",
    margin: "auto",
    color: "#4089f7"
  },
  emojis: {
    position: "absolute",
    bottom: 70,
    zIndex: 1200,
    left: 10,
    cssFloat: "right",
    "& .emoji-mart-preview": {
      display: "none"
    }
  },
  wrapperInner: {
    display: "flex",
    flexDirection: "column"
  },
  textareaInput: {
    maxHeight: "100px",
    overflow: "scroll",
    minHeight: "20px"
  },
  wrapper: {
    minHeight: "0px",
    width: "100%"
  },
  collapseHeight: {
    maxHeight: "320px"
  },
  attachmentAnchor: {
    color: "#000",
    "&:hover": {
      textDecoration: "none"
    }
  },
  banner: { color: "#65768C", background: '#FAFAFA' },
  avatarMedium:{
    height: "60px !important",
    width:"60px !important",
    fontSize:"15px !important"
  },
  avatarBadge: {
    right: 8,
    top: 36,
    boxShadow:'0px 0px 0px 1px #fff',
    height: '14px',
    backgroundColor:"#6F8099",
    color: '#FFF',
    fontSize: '10px',
    borderRadius: '100%',
    width: '14px',
  },
}));

const currentTime = moment();

export function findNewMessageIndex(messages, targetTime) {
  return messages.findIndex((msg) => {
    const msgTimestamp = moment(msg.data({ serverTimestamps: 'estimate' }).timestamp.toDate());
    return msgTimestamp.isAfter(targetTime);
  });
}

export function splitMessages(messages, newMsgIndex) {
  const leftMessages = newMsgIndex > -1 ? messages.slice(0, newMsgIndex) : messages;
  const rightMessages = newMsgIndex > -1 ? messages.slice(newMsgIndex) : [];
  return { leftMessages, rightMessages };
}

function reducer(state, action) {
  const { type, payload, cid } = action;
  const chatarea = document.getElementById("groupChatArea");
  const last_msg = document.getElementById("groupMessagesEnd");
  const chatAreaBottom = chatarea && chatarea.getBoundingClientRect().bottom;
  const lastMsgBottom = last_msg && last_msg.getBoundingClientRect().bottom;

  let idToScroll = "groupMessagesEnd";
  if (lastMsgBottom - chatAreaBottom > 100 && !!state.messages.length) {
    if (type === "added" && payload[0] && payload[0].data().authorId !== cid) {
      idToScroll = null;
    }
  }
  if(state.secondaryMsgs.length) {
    idToScroll = null
  }
  switch (type) {
    case "added":
      const newMsgIndex = findNewMessageIndex(state.messages, currentTime);
      const { leftMessages: oldMsgs, rightMessages: remainingMsgs } = splitMessages(state.messages, newMsgIndex);

      const newMessages = _.sortBy([...remainingMsgs, ...payload], [
        (o) => o.data({ serverTimestamps: 'estimate' }).timestamp.seconds,
        (o) => o.data({ serverTimestamps: 'estimate' }).timestamp.nanoseconds,
        (o) => o.id,
      ]);

      if(payload.length === 0) idToScroll = null

      return {
        ...state,
        messages: [...oldMsgs, ...newMessages],
        lastFetchedMsg: !!state.messages.length
          ? state.messages[0]
          : payload[0],
        msgScroll: {
          id: idToScroll,
          block: "end",
          behavior: "instant"
        },
        seen: false
      };
    case "modified":{
      const newState = {
        ...state,
        messages: [
          ...state.messages.map(msg => {
            if (msg.id === payload.id) return payload;
            else return msg;
          })
        ],
        seen: false
      };

      if(state.secondaryMsgs.length) {
        newState.secondaryMsgs = [
            ...state.secondaryMsgs.map(msg => {
              if (msg.id === payload.id) return payload;
              else return msg;
            })
          ]
      }
      return newState
    }
    case "metaChanges": {
      const newState = {
        ...state,
        messages: state.messages.map(
          obj => payload.find(o => o.id === obj.id) || obj
        )
      };
      if(state.secondaryMsgs.length) {
        newState.secondaryMsgs = state.secondaryMsgs.map(
            obj => payload.find(o => o.id === obj.id) || obj
          )
      }
      return newState
    }
    case "fetchPrevMsgs":
      if(state.secondaryMsgs.length) {
        return {
          ...state,
          secondaryMsgs: [...payload.reverse(), ...state.secondaryMsgs],
          lastFetchedMsg: !!payload.length ? payload[0] : null,
          msgScroll: {
            id: state.secondaryMsgs[0].id,
            block: "end",
            behavior: "instant"
          },
        }
      }
      return {
        ...state,
        messages: [...payload.reverse(), ...state.messages],
        lastFetchedMsg: !!payload.length ? payload[0] : null,
        msgScroll: {
          id: state.messages[0].id,
          block: "end",
          behavior: "instant"
        },
      };
      case "fetchNextMsgs":
        // Check if the last message in the payload is already in the state (overlapping)
        const overlapIndex = state.messages.findIndex((msg) => msg.id === payload[payload.length - 1].id);
        const secondaryMsgsLength = state.secondaryMsgs.length;
        if(overlapIndex > -1) {
          const newPayload = payload.slice(0, payload.length - 1 - overlapIndex);
  
          return {
            ...state,
            messages: [...state.secondaryMsgs, ...newPayload, ...state.messages],
            lastFetchedMsg: state.secondaryMsgs[0],
            latestFetchedMsg: null,
            msgScroll: {
              id: state.secondaryMsgs[secondaryMsgsLength - 1].id,
              block: "end",
              behavior: "smooth"
            },
            secondaryMsgs: []
          }
        }
        return {
          ...state,
          secondaryMsgs: [...state.secondaryMsgs, ...payload],
          latestFetchedMsg: payload[payload.length - 1],
          msgScroll: {
            id: state.secondaryMsgs[secondaryMsgsLength - 1].id,
            block: "end",
            behavior: "smooth"
          }
        };
      case "fetchSecondaryMsgs": 
        return {
          ...state,
          secondaryMsgs: [...payload],
          lastFetchedMsg: payload[0],
          latestFetchedMsg: payload[payload.length - 1],
          msgScroll: {
            id: action.scrollToId,
            block: "center",
            behavior: "instant"
          }
        };
      case "resetChat":
        return {
          ...state,
          secondaryMsgs: [],
          msgScroll: {
            id: "groupMessagesEnd",
            block: "end",
            behavior: "smooth"
          },
          lastFetchedMsg: state.messages[0],
          latestFetchedMsg: null
        }
    case "setSeen":
      return {
        ...state,
        seen: true
      };
    default:
      return state;
  }
}

const initialState = {
  messages: [],
  lastFetchedMsg: null,
  msgScroll: {
    id: "groupMessagesEnd",
    block: "end",
    behavior: "smooth"
  },
  seen: false,
  latestFetchedMsg: null,
  secondaryMsgs: []
};
const MSG_LIMIT = 15;

const fetchProfiles = async (cid, profileRef = {}, newMessages, { uid }) => {
  const userProfiles = profileRef.current || {};
  let uids = [];
  let staffIds = [];
  let out = {};
  newMessages.forEach((doc) => {
    const { authorId, staffId, reply } = doc.data();
    if(reply) {
      const {authorId: replyAuthorId, staffId: replyStaffId} = reply;

      if(!!replyStaffId && !userProfiles[replyStaffId] && replyStaffId !== uid)
        return staffIds.push(firebase.firestore().doc(`/staff/${replyStaffId}`).get())
      if(!userProfiles[replyAuthorId] && replyAuthorId !== cid && uids.indexOf(replyAuthorId) === -1)
        return uids.push(replyAuthorId);
    }
    if(!!staffId && !userProfiles[staffId] && staffId !== uid && staffIds.indexOf(staffId) === -1)
      return staffIds.push(firebase.firestore().doc(`/staff/${staffId}`).get())
    if(!userProfiles[authorId] && authorId !== cid && uids.indexOf(authorId) === -1)
      return uids.push(authorId);
  });
  if(uids.length){
    let { data } = await bffGetUserProfileByIDs({ cid, uids, fields: ['name', 'email', 'image'] });
    delete data.success;
    out = _.get(data, 'data', []).reduce((prev, doc) => {
      return {...prev, [doc._id]: {
        _id: doc._id,
        data: { ...doc },
      }}
    }, out);
  };
  if(staffIds.length) {
    const docs = await Promise.all(staffIds);
    out = docs.reduce((prev, doc) => {
      if(!doc || !doc.exists) return {...prev};
      return { ...prev, [doc.id]: { _id: doc.id, data: doc.data(), isStaff: true } };
    }, out);
  }
  return {...out, ...userProfiles};
};

const GroupChatView = props => {
  const { appConfig, enqueueSnackbar, groupId, hideLoader, showLoader, docs = [], delete: deleteRdxFn, update: updateRdxFn,
    hideHeader=false
   } = props;
  const { cid, comp, authUser } = useContext(FirebaseAuthContext);
  const { event } = useContext(AnalyticsContext);
  const { markRead, removeItem } = useContext(WorkerContext);
  const history = useHistory();
  const classes = useStyles();

  const d = useDispatch();
  const planOps = planRdxFns(d);
  const planRedux = useSelector(s => s.plans);

  const [state, dispatch] = useReducer(reducer, initialState);
  const [anchorEl, setAnchorEl] = useState(null);
  const [group, setGroup] = useState({});
  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [previewFile, setPreviewFile] = useState({});
  const { messages, lastFetchedMsg, msgScroll, secondaryMsgs, latestFetchedMsg } = state;
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showAudio, setShowAudioRecorder] = useState(false);
  const [openClientDialog, setClientDialog] = useState(false);
  const [clientSelectorDialog, setClientSelectorDialog] = useState(false);
  const [showNameDiag, setShowNameDiag] = useState(false);
  const [replyData, setReplyData] = useState(null);
  const [isChatLoading, setChatLoading] = useState(false)
  const [showScrollBtn, setShowScrollBtn] = useState(false);
  
  const company = comp ? comp.data() : {};
  const isGroupClass = Boolean(_.get(company, "features.group_class.enabled", false));
  const displayMsgs = secondaryMsgs.length ? secondaryMsgs : messages;

  const debouncedMarkRead = useCallback(_.debounce((args) => markRead(args), 1000), []);
  const profileCache = useRef({});
  const isDefaultGroup = useMemo(() => {
    const _group = DEFAULT_GC_GROUPS(cid).find(grp => grp._id === groupId);
    if(!_group) return false;
    else return true;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  const isProtectedGroup = group.protected;
  const activateGroup = async() => {
    showLoader();
    if(!!group && group.deleted && (group.total_messages >= 0 || group.total_clients)){
      // Reactivate
      let promises = [firebase.firestore().doc(`groupChats/${groupId}`).update({ deleted : false, _muat: firebase.firestore.FieldValue.serverTimestamp() })];
      if(_.get(group, 'criteria', false)){
        promises.push(firebase.firestore().doc(`companies/${cid}/misc/gcCriteria`).set({
          [groupId]: _.get(group, 'criteria', false),
        }, { merge: true }));
      }
      await Promise.all(promises);
      setGroup(prev=> ({ ...prev, deleted: false }));
      return hideLoader();
    };
    // Create new group
    const _group = DEFAULT_GC_GROUPS(cid).find(grp => grp._id === groupId);
    if(_group) {
      const data = {
        ...(_group.data),
        deleted: false, type: 'auto', total_messages: 0,
        [cid]: { read_count: 0, last_seen: firebase.firestore.FieldValue.serverTimestamp() }
      };
      let promises = [firebase.firestore().doc(`groupChats/${groupId}`).set(data)];
      if(_.get(_group, 'data.criteria', false)){
        promises.push(firebase.firestore().doc(`companies/${cid}/misc/gcCriteria`).set({ 
          [groupId]: _.get(_group, 'data.criteria', false),
        }, { merge: true }))
      }
      await Promise.all(promises);
      setGroup(data);  
    } else setGroup(false);
    hideLoader();
  }

  const fetchPlanData = useCallback(async() => {
    const data = await firebase.firestore().collection(`companies/${cid}/packs`).get();
    planOps.set(parsePacks(data, isGroupClass), 0, true);
  }, [cid, planOps]);

  const fetchGroupData = async () => {
    try {
      const reduxDoc = _.find(docs, (d) => d._id === groupId);
      if(_.get(reduxDoc, 'data.criteria.plan', null) && !planRedux.docs) await fetchPlanData();
      if(!!reduxDoc) return reduxDoc.data;
      const docSnap = await firebase.firestore().doc(`groupChats/${groupId}`).get();
      const data = docSnap.data();
      if(_.get(data, 'criteria.plan', null) && !planRedux.docs) await fetchPlanData();
      if (docSnap.exists) return data;
      else {
        const group = DEFAULT_GC_GROUPS(cid).find(grp => grp._id === groupId);
        if(!group) return;
        return { ...group.data };
      }
    } catch (error) {
      console.log(error)
      handleError(error, enqueueSnackbar)
    }
  }

  const fetchGroupMessages = async () => {
    try {
      const snapshot = await firebase
        .firestore()
        .collection("groupChats")
        .doc(groupId)
        .collection("messages")
        .orderBy("timestamp", "desc")
        .limit(MSG_LIMIT).get();

      const newUserProfiles = await fetchProfiles(cid, profileCache, snapshot.docs, { uid: authUser.uid });
      dispatch({ type: "added", payload: snapshot.docs.reverse() });
      profileCache.current = { ...newUserProfiles };
    } catch (error) {
      console.log(error)
      handleError(error, enqueueSnackbar)
    }
  }

  useEffect(() => {
    if(!groupId) return;
    let unmount = false;
    if(!unmount) {
      fetchGroupData().then((data) => {
        if(!data){
          setGroup(data);
          if(loading) setLoading(false)
          return;
        }
        fetchGroupMessages().then(() => {
          setGroup(data);
          markRead({ cid, keyName: "groupChats", groupId, ...data });
          if(loading) setLoading(false); // Set initial loader false
        })
      });
    }
    return () => {
      if(group && !_.isEmpty(group)) markRead({ cid, keyName: "groupChats", groupId, ...group });
      unmount = true
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // listener for changes in group doc
  useEffect(() => {
    if(!docs.length) return;
    const index = docs.findIndex(i => i._id === groupId);
    if(index === -1) return;
    const newDoc = docs[index].data;
    if(group.type === 'auto' &&
      (group.total_clients !== newDoc.total_clients || group.total_messages !== newDoc.total_messages)) 
        setGroup(newDoc);
    if(group.type === 'custom' && group.total_messages !== newDoc.total_messages) 
      setGroup(newDoc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docs]);

  const { total_messages } = group || {};
  useEffect(() => {
    if (group && group.total_messages) {
      debouncedMarkRead({ cid, keyName: "groupChats", groupId, ...group });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total_messages]);

  useEffect(() => {
    // Listen for new messages in the group
    const unsubscribe = firebase
      .firestore()
      .collection("groupChats")
      .doc(groupId)
      .collection("messages")
      .where("_uat", ">", firebase.firestore.Timestamp.fromDate(new Date()))
      .onSnapshot({ includeMetadataChanges: true },async querySnapshot => {
        if (!!querySnapshot.docChanges().length) {
          const newMessages = [];
          querySnapshot.docChanges().forEach(function(change) {
            const msg = change.doc.data({serverTimestamps: "estimate"})
            const lastMsg = state.messages[state.messages.length - 1]?.data();
            const latestMsgTimestamp = moment(lastMsg?.timestamp?.toDate() || currentTime);
            const timestamp = moment(msg.timestamp.toDate())
            if (change.type === "added" && timestamp.isAfter(latestMsgTimestamp)) {
              newMessages.unshift(change.doc);
            } else {
              dispatch({ type: "modified", payload: change.doc });
            }
          });

          const newUserProfiles = await fetchProfiles(cid, profileCache, newMessages, { uid: authUser.uid });
          profileCache.current = ({ ...newUserProfiles });
          dispatch({ type: "added", payload: newMessages, cid });
        } else {
          dispatch({ type: "metaChanges", payload: querySnapshot.docs });
        }
      }, (err) => {
        console.log(err, '>>');
      });
    return () => unsubscribe();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);


  const sendMessage = async msg => {
    try {
      if(!msg) return;
      if(!msg.authorId) msg.authorId = cid;
      if(!msg.staffId) msg.staffId = authUser.uid;
      if(!msg.timestamp) msg.timestamp = firebase.firestore.FieldValue.serverTimestamp();
      if(!msg._uat) msg._uat = firebase.firestore.FieldValue.serverTimestamp();
      if (msg.body) msg.body = msg.body.trim();
      if (msg.body === "" && (msg.type === 'text' || msg.type === 'video')) return;
      if(replyData) {
        msg.reply = { ...replyData };
        setReplyData(null)
      }
      setPreviewFile({});
      await firebase.firestore().collection(`groupChats/${groupId}/messages`).add(msg);
      msg.ts = Date.now();
      event("groupChat_send");
      handleBottomScroll()
      return true;
    } catch (err) {
      console.log(err);
      hideLoader();
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' })
    }
  };

  const fetchPrev = async () => {
    if (fetching) return;
    if (!lastFetchedMsg) {
      setFetching(false);
      return;
    }
    setFetching(true);
    const fetchedMsgs = await firebase
      .firestore()
      .collection(`groupChats/${groupId}/messages`)
      .orderBy("timestamp", "desc")
      .startAfter(lastFetchedMsg)
      .limit(10)
      .get();

    fetchProfiles(cid, profileCache, fetchedMsgs.docs, { uid: authUser.uid }).then((newUserProfiles) => {
      dispatch({ type: "fetchPrevMsgs", payload: fetchedMsgs.docs });
      profileCache.current = ({...profileCache.current, ...newUserProfiles});
      setFetching(false);
    });
  };

  const fetchNext = async () => {
    if(fetching) return;
    if(!latestFetchedMsg) {
      setFetching(false);
      return
    }
    setFetching(true);
    const fetchedMsgs = await firebase
      .firestore()
      .collection(`groupChats/${groupId}/messages`)
      .orderBy("timestamp", "asc")
      .startAfter(latestFetchedMsg)
      .limit(10)
      .get();

      fetchProfiles(cid, profileCache, fetchedMsgs.docs, { uid: authUser.uid }).then((newUserProfiles) => {
        dispatch({ type: "fetchNextMsgs", payload: fetchedMsgs.docs})
        profileCache.current = ({...profileCache.current, ...newUserProfiles});
        setFetching(false);
      });
  }

  const cancelPreview = () => {
    setPreviewFile({});
  };

  const handleSend = async () => {
    if (!previewFile.file) return;
    showLoader();
    try {
      const path = `companies/${groupId}/groupChats/chats/${previewFile.isImage ? 'original' : 'attachment'}`;
      let _previewFile = {...previewFile}
      setPreviewFile({});
      await resizeAndUpload(_previewFile, cid, sendMessage, path, true, groupId, authUser.uid, "groupChats");
      if(previewFile.isAudio) setShowAudioRecorder(false);
      hideLoader();
    } catch (err) {
      console.log(err);
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' })
      hideLoader();
    }
  };

  useEffect(() => {
    let unmount = false;
    if (!previewFile.file || unmount) return;
    let fileSize = (previewFile && previewFile.file.size) || 0;
    const maxSize = (appConfig && appConfig.company_chat_file_max_size) || CHAT_FILE_SIZE_DEFAULT;

    if (!previewFile.isVideo && !previewFile.isAudio && !previewFile.isImage && fileSize > 1024 * 1024 * maxSize) {
      enqueueSnackbar(`Document size should not be more than ${maxSize} MB`, { variant: "error" });
      setPreviewFile({});
      return;
    }
    const { chat_config } = comp.data() || {};
    const maxVideoSize = (chat_config && chat_config.company_max_video_size) || 16; // in MB
    const maxVideoDuration = (chat_config && chat_config.company_max_video_duration) || 90; // in seconds
    if(previewFile.isVideo && fileSize > 1024 * 1024 * maxVideoSize) {
      enqueueSnackbar(`Video file size should not be more than ${maxVideoSize} MB`, { variant: "error" });
      setPreviewFile({});
      return;
    }
    if(previewFile.isVideo && previewFile.duration >  maxVideoDuration) {
      enqueueSnackbar(`Video file duration should not be more than ${maxVideoDuration} seconds`, { variant: "error" });
      setPreviewFile({});
      return;
    }
    if (!previewFile.isImage && !previewFile.isVideo && !previewFile.isAudio) {
      handleSend();
    }
    return () => unmount = true;
  }, [previewFile, appConfig, enqueueSnackbar]); // eslint-disable-line react-hooks/exhaustive-deps

  function handleClick(e) {
    setAnchorEl(e.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    try {
      showLoader();
      if(isDefaultGroup || isProtectedGroup) await firebase.firestore().doc(`groupChats/${groupId}`).update({
        deleted: true, _muat: firebase.firestore.FieldValue.serverTimestamp(),
      });
      else await bffDeleteGroup({ cid, groupChatId: groupId, groupData: group });
      if(!isProtectedGroup && !isDefaultGroup){
        deleteRdxFn(groupId);
        removeItem({ groupId, keyName: "groupChats" });
      } else setGroup(prev => ({ ...prev, deleted: true }));
      setShowDeleteConfirmation(false);
      enqueueSnackbar("Group deleted successfully", { variant: "success" });
      if(!isProtectedGroup && !isDefaultGroup) history.goBack();
    } catch (error) {
      console.error(error);
      handleError(error, enqueueSnackbar);
    } finally {
      hideLoader();
    }
  }

  const handleBottomScroll = () => {
    const chatarea = document.getElementById("groupChatArea");
    if(secondaryMsgs.length === 0) {
      chatarea.scrollTo({ top: (0, chatarea.scrollHeight), behavior: "smooth" });
    } else {
      dispatch({ type: "resetChat" });
    }
  }

  const toggleClientsDialog = async() => {
    setClientDialog(prev => !prev);
  }

  const toggleAddClientsDialog = () => {
    setClientSelectorDialog(prev => !prev);
  }

  const toggleNameDialog = () => setShowNameDiag(prev => !prev);

  const saveGroupInfo = async (name="", thumbnail="", desc="", ref_name="", mute = false) => {
    showLoader();
    const chng = { 
      ref_name: ref_name.trim(), 
      thumbnail, 
      name: name.trim(), 
      desc, 
      _muat: firebase.firestore.FieldValue.serverTimestamp(), 
      mute 
    };
    try {
      await firebase.firestore().doc(`groupChats/${groupId}`).update({ ...chng });
      const updatedDoc = { _id: groupId, data: { ...group, ...chng }};
      updateRdxFn(updatedDoc);
      setGroup(updatedDoc.data);
    } catch(e) {
      console.log(e);
      handleError(e, enqueueSnackbar);
    } finally { hideLoader(); }
  };

  const handleAddClients = (selectedUsers) => {
    showLoader();
    const promises = [ updateGroupToUserProfiles(selectedUsers, groupId, group.mute) ];
    const newUids = [...group.uids, ...selectedUsers.map((i) => i._id)]
    if(group.type === 'custom'){
      promises.push(updateGroupClients(groupId, newUids))
    }
    Promise.all(promises).then(() => {
      const updatedDoc = { _id: groupId, data: { ...group, total_clients: newUids.length, uids: newUids }};
      setGroup(updatedDoc.data);
      updateRdxFn(updatedDoc);
      toggleAddClientsDialog();
    }).catch((err) => {
      console.log(err);
      handleError(err, enqueueSnackbar);
    }).finally(() => {
      hideLoader();
    });
  }

  const handleRemoveUser = (newCount, uids) => {
    // Decrease count in redux here and remove from usersList
    const updatedDoc = { _id: groupId, data: { ...group, total_clients: newCount, uids }};
    setGroup(updatedDoc.data);
    updateRdxFn(updatedDoc);
    if(!uids.length) toggleClientsDialog();
  };

  const groupCriteriaText = useMemo(() => getFilterString(group.criteria, planRedux.docs), [group.criteria, planRedux.docs]);
  if(loading){
    return (
      <div className="d-flex flex-column h-100 flex-1  w-100 position-relative">
        <CircularLoader className="position-absolute" />
      </div>
    );
  }

  const showChatArea = (((isDefaultGroup || isProtectedGroup) && !group.deleted)
    || (!isDefaultGroup && !isProtectedGroup));

  const hideOptions= !!group.challenge;

  // if(!group || (!isDefaultGroup && !isProtectedGroup)) return <PageNotFound keyName="Group Chat" />
  return (
    <div className="d-flex flex-column h-100 position-relative flex-1">

      {/* Dialogs */}
      {openClientDialog && 
        <ClientDialog
          open={openClientDialog}
          cid={cid}
          onClose={toggleClientsDialog}
          onDelete={handleRemoveUser}
          groupId={groupId}
          groupData={group}
          onSave={toggleAddClientsDialog}
        />
      }

      {clientSelectorDialog &&
        <MultiClientSelector
          title="Select Clients"
          keyName="browse"
          open
          onClose={toggleAddClientsDialog}
          actionText="Save"
          onSave={handleAddClients}
          selectedUsers={_.get(group,'uids',[]).map((id) => ({ _id: id })) || []}
          disableRemove
        />
      }

      {showNameDiag && (
        <GroupNameDialog
          onSave={saveGroupInfo}
          onClose={toggleNameDialog}
          thumbnail={group.thumbnail}
          groupName={group.name}
          refName={group.ref_name}
          description={group.desc}
          mute={group.mute}
        />
      )}

      {/* Header */}
      {!hideHeader && (<>
        <div className="p-20 bg-white" style={{zIndex: 12}}>
        <div className="d-flex align-items-center">
          <Hidden smDown>
            <GroupTypeIndicator type={group.type} showTooltip classes={{badge: classes.avatarBadge}}>
              <AvatarImage
                src={group.thumbnail}
                name={group.name || group.ref_name}
                base64={false}
                externalUrl={true}
                size={classes.avatarMedium}
                alt={"Group image:" + group.name}
                styles={{ fontSize: 13, fontWeight: 600 }}
              />
            </GroupTypeIndicator>
          </Hidden>
          <div className="flex-1 d-flex flex-column justify-content-center ml-15 overflow-hidden">
            <Tooltip title={group.name}>
              <Typography className="font_20_600 pr-20 " noWrap>{group.name}</Typography>
            </Tooltip>
            <div className={"d-flex align-items-center pr-20"}>
              <Typography variant="body2" className="text-dark-grey mt-8">
                <span 
                  className={clsx("font_13_500",(!hideOptions || (hideOptions && !!group.total_clients)) && 'text-primary cursor-pointer')} 
                  onClick={(!!hideOptions && !group.total_clients) ? null :(group.total_clients ? toggleClientsDialog : isProtectedGroup || group.type === 'auto' ? null : toggleAddClientsDialog)}
                >
                  {group.total_clients ? `${group.total_clients} Client${pluralize(group.total_clients)}` : group.type === "auto" ? "" : "No client added "}
                </span>
                {group.type === "auto" ? groupCriteriaText ? <span className="mx-5 font_13_500">{'\u2004●\u2004'}</span> : null 
                  : _.isNumber(group.total_clients) ? <span className="mx-5 font_13_500">{'\u2004●\u2004'}</span> : null}
                <span className="font_13_500">
                  {group.type === "auto" ? groupCriteriaText : 'Custom Group'}
                </span>
              </Typography>
            </div>
          </div>
          {showChatArea && !hideOptions && <IconButton edge="start" onClick={handleClick} className='align-self-start fmln-8' style={{ marginTop : "-4px" }}>
            <MoreVertIcon />
          </IconButton>}
        </div>
        {!!group.desc && <MoreLessTextBtn text={group.desc} className="font_14_500 text-grey mt-20" btnClassName="viewMore" />}
      </div>
      <Divider classes={{ root: 'height-1' }} style={{ backgroundColor: "#D8DCE0" }} />
      </>)}
      
      {/* Conversation message view */}
      {showChatArea ? (
        <div className="position-relative d-flex flex-column flex-1">
          {isChatLoading && (
            <div className="white-overlay d-flex justify-content-center align-items-center">
              <CircularProgress />
            </div>
          )}
          <GroupChatMessageListView
            profileCache={profileCache.current}
            groupId={groupId}
            messages={displayMsgs}
            fetchMore={fetchPrev}
            fetchNext={fetchNext}
            lastFetchedMsg={lastFetchedMsg}
            latestFetchedMsg={latestFetchedMsg}
            fetching={fetching}
            showLoader={showLoader}
            hideLoader={hideLoader}
            msgScroll={msgScroll}
            setReplyData={setReplyData}
            setChatLoading={setChatLoading}
            loadSecondaryMsgs={(payload, id) => dispatch({ type: "fetchSecondaryMsgs", payload, scrollToId: id })}
            setShowScrollBtn={setShowScrollBtn}
          />

          {/* Inputs and previews */}
          <ChatInput
            replyData={replyData}
            cancelReply={() => setReplyData(null)}
            groupId={groupId}
            sendMessage={sendMessage}
            setPreviewFile={setPreviewFile} 
            setShowAudioRecorder={setShowAudioRecorder}
            profileCache={profileCache.current}
            showScrollBtn={showScrollBtn}
            onScrollBottom={handleBottomScroll}
          />
           {!!group.deleted && (
            <div style={{height:'67px'}} className="bg-light-grey d-flex justify-content-center align-items-center">
              <Typography className="font_14_500 text-grey">Chat is closed. You can still view old messages.</Typography>
            </div>
          )}
          {showAudio && (
            <AudioRecorder
              onSave={handleSend}
              onChange={(data) => setPreviewFile(data)}
              open={showAudio}
              onClose={()=> {cancelPreview();setShowAudioRecorder(false)}}
              hideAction={!previewFile || !previewFile.file}
            />
          )}
          {previewFile && previewFile.file && !previewFile.isAudio  &&  (
            <MediaPreview previewFile={previewFile} onSend={handleSend} onCancel={cancelPreview} />
          )}
        </div> ) : (
          <div className="position-relative d-flex flex-column flex-1">
              <div className=" h-100 text-center d-flex align-items-center justify-content-center flex-column px-5 ">
                <img alt="noitem" src={PeopleGroup} />
                <Typography variant="h3" className="fmt-20">
                  {group.name || group.ref_name}
                </Typography>
                <Typography variant="body1" className="fmt-20 w-50" color="textSecondary">
                  This group is currently inactive
                </Typography>
                <ClrdButton
                  onClick={activateGroup} 
                  variant="contained"
                  color="primary"
                  className="fpt-20 fpb-20 f-xl border-0 shadow-none fmt-20">
                    Activate Now
                </ClrdButton>
              </div>
          </div>
        )}
      {showDeleteConfirmation && (
        <Confirmation
          open
          handleClose={() =>
            setShowDeleteConfirmation(false)
          }
          handleChange={handleDelete}
          handleCancel={() =>
            setShowDeleteConfirmation(false)
          }
          title="Confirmation"
          msg={`Are you sure you want to ${isDefaultGroup || isProtectedGroup ? "deactivate" : "delete"} this group?`}
          confirmOption={`Yes, ${isDefaultGroup || isProtectedGroup ? "Deactivate" : "Delete"}`}
        />
      )}
      <Menu
        anchorEl={anchorEl}
        elevation={4}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        classes={{
          paper: `fb-border  ${classes.menu}`
        }}
        onClick={handleClose}
      >
        <MenuItem onClick={toggleNameDialog}>
          Edit group
        </MenuItem>
        {group.type === "custom" && !isProtectedGroup &&
          <MenuItem onClick={group.total_clients ? toggleClientsDialog : toggleAddClientsDialog}>
            Manage Members
          </MenuItem>
        }
        <MenuItem onClick={() => setShowDeleteConfirmation(true)}>
          {isDefaultGroup || isProtectedGroup ? "Deactivate Group" : "Delete Group"}
        </MenuItem>
      </Menu>
    </div>
  );
};

const mapDispatchToProps = d => {
  return {
    ...appRdxFns(d),
    ...groupChatReduxFns(d),
  };
};

const mapStateToProps = (s, op) => {
  return s.groupChatList;
};

export default withWidth()(
  withSnackbar(connect(mapStateToProps, mapDispatchToProps)(GroupChatView))
);
