import React from 'react';

const CameraIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.9664 7.02031C21.1878 6.90842 21.4508 6.92044 21.6614 7.05209C21.872 7.18378 22.0001 7.41632 22 7.66667V16.3333C22.0006 16.4467 21.9748 16.5587 21.9247 16.6601C21.7483 17.0169 21.3193 17.1616 20.9664 16.9833L16.2857 14.613V16.3333C16.2857 17.53 15.3263 18.5 14.1428 18.5H4.14285C2.95939 18.5 2 17.53 2 16.3333V7.66667C2 6.47005 2.95939 5.5 4.14285 5.5H14.1428C15.3263 5.5 16.2857 6.47005 16.2857 7.66667V9.38701L20.9664 7.02031ZM6.28489 11.9997C6.28489 13.5952 7.56407 14.8886 9.14204 14.8886C10.72 14.8886 11.9992 13.5952 11.9992 11.9997C11.9992 10.4042 10.72 9.11084 9.14204 9.11084C7.56407 9.11084 6.28489 10.4042 6.28489 11.9997Z"
        fill="white"
      />
    </svg>
  );
};

export default CameraIcon;
