import _ from "lodash";
import React, { createContext, useMemo, useEffect, useContext } from "react";
import { useDispatch } from 'react-redux';
import admin from "fitbud/firebase";
import firebase from "fitbud/firebase";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import useStateUpdate from 'fitbud/hooks/useStateUpdate';
import quesRdxFns from 'fitbud/redux/questionnaires';

const PlanContext = createContext(undefined);
PlanContext.displayName = 'PackContext';

const getVaultCollection = (cid, updateKeys) => {
  return firebase.firestore().doc(`vault/${cid}`).get()
    .then(doc => {
      if (!doc.exists) updateKeys('hasAppleIAP', false, 'hasPlayIAP', false);
      else updateKeys('hasAppleIAP', Boolean(_.get(doc.data(), 'ios.sharedSecret')),
        'hasPlayIAP', Boolean(_.get(doc.data(), 'pcsa.private_key')));
    }).catch(e => {
      console.error(e);
      updateKeys('hasAppleIAP', false, 'hasPlayIAP', false);
    });
};

const loadQuesForRules = (cid, bmrQuestions, updateKeys) => {
  return firebase.firestore().doc(`companies/${cid}/questionnaire/sample`).get()
    .then(doc => {
      if (!doc.exists) return;
      const { questions } = doc.data();
      const out = [];
      questions.forEach(q => {
        if (q.sub_type === 'gender') return out.push({...q, ruleId: 'gender', options: ['Male', 'Female']});
        if (q.type !== 'single_select') return;
        if (!q.visible) return;
        if (bmrQuestions[q.id]) out.push({...q, ruleId: q.id, options: bmrQuestions[q.id].options});
        else out.push({...q, ruleId: q.id});
      });
      updateKeys('questions', out);
    }).catch(e => {
      console.error(e);
      updateKeys('questions', []);
    });
};

const figurePg = (company) =>{
   const gates = ['stripe', 'paypal', 'razorpay'];
   let out = "";
   for(let gate of gates){
     if(`${gate}Account` in company){
       out = gate;
       break;
     }
   }
   return out;
}

function PlanProvider({ children }) {
  const { isAdmin, cid, comp, availableCurrencies, defaultCurrency } = useContext(FirebaseAuthContext);
  const rdx = useDispatch();
  const { setQuestions } = quesRdxFns(rdx);
  const bmrQuestions = useMemo(() => (comp && _.get(comp.data(), 'bmr', {})), [comp]);
  const paymentGateWay = useMemo(()=>{
    if(!comp) return;
    const company = comp.data();
    return figurePg(company);
  },[comp])

  const [state, updateKeys, setState] = useStateUpdate({
    isAdmin, cid, hasVidCall: false, hasAppleIAP: false, hasPlayIAP: false,
    iosPackMode: 'all', androidPackMode: 'all', defaultCurrency,
    packFlags: {}, availableCurrencies,
    iosAppId: '', iapIdMap: {}, pcIdMap: {}, questions: [],
    protectQuestions: (packId, rulesBefore, rulesAfter) => {
      if (!rulesBefore && !rulesAfter) return true;
      const keysBefore = Object.keys(rulesBefore);
      const keysAfter = Object.keys(rulesAfter);
      const update = {};
      keysAfter.forEach(id => {
        if (id === '_id' || rulesBefore[id]) return; // ques was there before and after
        update[`ruleUsage.${id}.${packId}`] = admin.firestore.FieldValue.increment(1);
      });
      keysBefore.forEach(id => {
        if (id === '_id' || rulesAfter[id]) return; // ques was there before and after
        update[`ruleUsage.${id}.${packId}`] = admin.firestore.FieldValue.increment(-1);
      });
      setQuestions(null); // clear redux to force re-fetch
      return firebase.firestore().doc(`companies/${cid}/questionnaire/sample`).update(update);
    },
    parsePacksForIapIds: (packs) => {
      const iapMap = {};
      const pcMap = {};
      packs.forEach(x => {
        const pid = x._id || x.id;
        const data = x._id ? x.data : x.data();
        const {pc_ids, iap_ids} = data;
        if (iap_ids && iap_ids.length)
          data.iap_ids.forEach(id => (iapMap[id.toUpperCase()] = pid));
        if (pc_ids && pc_ids.length)
          data.pc_ids.forEach(id => (pcMap[id.toLowerCase()] = pid));
      });
      updateKeys('iapIdMap', iapMap, 'pcIdMap', pcMap);
    },
    parseSinglePackForIapIds: (x) => {
      if (!x) return;
      const pid = x._id || x.id;
      const data = x._id ? x.data : x.data();
      const iap_ids = data.iap_ids || [];
      const pc_ids = data.pc_ids || [];
      iap_ids.forEach(id => (state.iapIdMap[id.toUpperCase()] = pid));
      pc_ids.forEach(id => (state.pcIdMap[id.toLowerCase()] = pid));
    },
  });

  useEffect(() => {
    loadQuesForRules(cid, bmrQuestions, updateKeys);
    getVaultCollection(cid, updateKeys);
    updateKeys(`paymentGateWay`,paymentGateWay);
  }, [cid, bmrQuestions, updateKeys, paymentGateWay]);

  useEffect(() => {
    if (!comp || !comp.data()) return;
    setState(x => ({...x,
      hasVidCall: _.get(comp.data(), 'features.video_calling', false),
      packFlags: _.get(comp.data(), 'defaults.pack_flags', {}),
      defaultCurrency, availableCurrencies,
      iosPackMode: comp.data().enable_plan_type || 'all',
      androidPackMode: comp.data().enable_plan_type_android || 'all',
      iosAppId: _.get(comp.data(), 'app.appStoreId', ''),
    }));
  }, [comp, defaultCurrency, availableCurrencies, setState]);

  return <PlanContext.Provider value={state}>{children}</PlanContext.Provider>;
}

export { PlanProvider, PlanContext };
