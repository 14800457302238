import {useTheme} from '@material-ui/core/styles';
import {makeStyles} from '@material-ui/core/styles';
import { DrawerWidth } from "fitbud/utils/constants";

const GAPY = 30;
const BP = 1920 + 2 * GAPY;
const MAX = BP - DrawerWidth;
export const LEFT = 400;
const RIGHT = MAX - LEFT;

const useSplitStyles = ({
  breakpoint = 'sm', show = 'left',
  ...rest
}, props) => {
  const theme = useTheme();
  return makeStyles({
    ...rest,
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'center',
      overflow: 'hidden',
      [theme.breakpoints.up(BP)]: {
        alignItems: 'center',
      }
    },
    split: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      alignItems: 'stretch',
      justifyContent: 'center',
      overflow: 'hidden',
      [theme.breakpoints.up(BP)]: {
        width: MAX,
        marginTop: GAPY,
        marginBottom: GAPY,
      }
    },
    left: {
      width: '100%',
      display: show === 'left' ? 'block' : 'none',
      overflow: 'scroll',
      borderRight:"1px solid #D8DCE0",
      [theme.breakpoints.up(breakpoint)]: {
        display: 'block',
        width: LEFT,
      }
    },
    right: {
      width: '100%',
      display: show === 'right' ? 'block' : 'none',
      flexGrow: 1,
      overflow: 'scroll',
      [theme.breakpoints.up(breakpoint)]: {
        flex: '1 0 auto',
        display: 'block',
        width: `calc(100% - ${LEFT}px)`,
        maxWidth: RIGHT,
      }
    }
  })(props)
}

export default useSplitStyles;
