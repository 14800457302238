import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  TextField,
  IconButton,
  MenuItem,
  InputAdornment,
  Select,
  OutlinedInput,
} from "@material-ui/core";
// import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ClearIcon from '@material-ui/icons/Clear';
import { ClrdButton } from "fitbud/components/form-fields";
import { TimePicker } from "@material-ui/pickers";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { roundNumber } from "fitbud/utils/helpers";
import moment from "moment";
import {
  Add as AddIcon,
  ExpandMore as KeyDown,
} from "@material-ui/icons/";
import { DeleteIcon } from "fitbud/icons/delete";
import {DragIcon} from "fitbud/icons/drag";


const CategoryList = props => {
  const {
    groups,
    mealCategories,
    errors,
    mealCats,
    handleMealChange,
    onRemove,
    onAddOptions,
    handleMealTime,
    removeTimePicker,
    sortBy,
    updateParent,
    dType,
    valueAdornmentText
  } = props;
  const [mealCate, setMealCategories] = useState(mealCategories)
  const removeTime = (e, index) => {
    e.stopPropagation();
    removeTimePicker(index)
  }

  useEffect(() => {
    setMealCategories(mealCategories);
  }, [mealCategories])

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      mealCate,
      result.source.index,
      result.destination.index
    );
    setMealCategories(items);
    // Update parent state
    
    updateParent({ groups: items });
  };
  
  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {mealCate.map((meal, i, thisArg) => {
                const newMealCats = _.filter(
                  mealCats,
                  d =>
                    !!d && !_.find(groups, d2 => meal.type !== d[0] && d2.type === d[0])
                );
                const ifTime = _.get(meal, "iftime", "");
                const timeValue = !!ifTime ? moment(ifTime, "LT") : null;
                return (
                  <Draggable
                    draggableId={`__dragId${i}`}
                    key={`__dragId${i}`}
                    index={i}
                  >
                    {(provided, snapshot) => {
                      return (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <div key={`_mealCat${meal.type}${i}`}
                            className="d-flex fp-0  align-items-center fpy-10">
                            <Select
                              IconComponent={KeyDown}
                              input={
                                <OutlinedInput
                                  classes={{
                                    root: "medium flex-grow-1",
                                    input: "size_16_500 select-medium"
                                  }}
                                  name="type"
                                />
                              }
                              value={meal.type ? meal.type : "select"}
                              error={!!errors && !!errors[i] && !!errors[i].type}
                              onChange={handleMealChange(i)}
                              required
                            >
                              <MenuItem key={"select"} value={"select"}>
                                Select
                              </MenuItem>
                              {newMealCats.map(cat => (
                                <MenuItem key={cat[0]} value={cat[0]}>
                                  {cat[1].value}
                                </MenuItem>
                              ))}
                            </Select>
                            <TimePicker
                              fullWidth
                              showTodayButton
                              todayLabel="now"
                              inputVariant="outlined"
                              value={timeValue}
                              onChange={(date) => handleMealTime(i, date)}
                              error={sortBy === "time" && !!errors && !!errors[i] && !!errors[i].iftime}
                              className={sortBy === 'custom' ? 'fmx-10' : 'fmx-20'}
                              style={{width:"140px"}}
                              InputProps={{
                                classes: {
                                  root: "medium pl-10 pr-5 py-0",
                                  input: "size_16_500 px-0"
                                },
                                name: "time",
                                endAdornment: (
                                  <IconButton size="small" onClick={(e) => removeTime(e, i)}>
                                    <ClearIcon />
                                  </IconButton>)
                              }}
                              placeholder="Time"
                              name="time"
                            />
                            <TextField
                              fullWidth
                              name="calories"
                              type={
                                !isNaN(Number(meal.calories)) === "number" ? "number" : "text"
                              }
                              onChange={handleMealChange(i)}
                              value={!isNaN(Number(meal.calories)) ? roundNumber(meal.calories) : "-----"}
                              variant="outlined"
                              required
                              disabled={thisArg.length === i + 1 || dType === "foodMealCategory"}
                              error={!!errors && !!errors[i] && !!errors[i].calories}
                              helperText={errors && errors[i] && errors[i].calories}
                              style={{width:"140px"}}
                              InputProps={{
                                inputProps: {
                                  min: 0
                                },
                                endAdornment: (
                                  <InputAdornment position="end">{valueAdornmentText ||"cals"}</InputAdornment>
                                ),
                                classes: {
                                  root: "medium",
                                  input: "size_16_500"
                                }
                              }}
                            />
                            <div className="ml-10" {...provided.dragHandleProps}>
                              {sortBy === "custom" && (
                                <IconButton component="div"><DragIcon  /></IconButton>
                              )}
                            </div>
                            <IconButton  onClick={() => onRemove(i)}><DeleteIcon style={{fill:"none"}} /></IconButton>
                          </div>
                        </div>
                      )
                    }}
                  </Draggable>
                )
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {mealCategories.length !== mealCats.length && (
        <div className="d-flex w-100 justify-content-center">
          <ClrdButton
            variant="contained"
            color="invert"
            className="f-meduim border-0 shadow-none  fmt-10"
            onClick={onAddOptions}
          >
            <AddIcon /> Add More Options
          </ClrdButton>
        </div>
      )}
    </div>
  );
};

export default CategoryList;
