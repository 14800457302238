import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const HomeIcon = props => (
    <SvgIcon
        {...props}
        viewBox="0 0 20 20"
        classes={{
          root: "small"
        }}
    >
      <path d="M12.5 18.3333H15.8333C16.2754 18.3333 16.6993 18.1577 17.0118 17.8452C17.3244 17.5326 17.5 17.1087 17.5 16.6667V7.49999L10 1.66666L2.5 7.49999V16.6667C2.5 17.1087 2.67559 17.5326 2.98816 17.8452C3.30072 18.1577 3.72464 18.3333 4.16667 18.3333H7.5" stroke="#8F969F" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7.5 18.3333V13H12.5V18.3333" stroke="#8F969F" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </SvgIcon>
);
