import React, { useEffect, useState } from 'react';
import { Divider, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { getImageDownloadUrl, getItemSvg } from './chatView';
import _ from 'lodash';
import { secondsToMinutes } from 'fitbud/utils/helpers';
import PlayIcon from 'fitbud/images/play_icon.svg';

const useStyles = makeStyles(() => ({
  replyBody: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    textOverflow: 'ellipsis',
    marginBottom: 0,
    whiteSpace: 'normal',
  },
  replyBar: { width: 2, borderRadius: 1 },
  replyBubble: {
    backgroundColor: '#0000000F',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 10,
  },
  replyContainer: {
    display: 'flex',
    maxWidth: '50%',
    columnGap: '10px',
    marginBottom: '10px',
  },
  replyRight: {
    marginRight: '30px',
    flexDirection: 'row-reverse',
  },
  replyLeft: {
    marginLeft: '30px',
  },
  replyImg: {
    width: 100,
    height: 100,
    borderRadius: '10px 10px 0 0',
    objectFit: 'cover',
  },
  videoPlayIcon: {
    width: 30,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  videoThumb: {
    borderRadius: '10px 10px 0 0',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#666',
    width: 100,
    height: 100,
  },
  audioRange: {
    width: '220px',
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    background: '#e1e1e1',
    outline: 'none',
    height: '2px',
    '&::-webkit-slider-thumb': {
      '-webkit-appearance': 'none',
      background: '#000000',
      cursor: 'pointer',
      width: '6px',
      height: '6px',
      border: 0,
      borderRadius: '50%',
    },
    '&::-moz-range-thumb': {
      '-moz-appearance': 'none',
      background: '#000000',
      cursor: 'pointer',
      width: '6px',
      height: '6px',
      border: 0,
      borderRadius: '50%',
    },
  },
}));

function getAttachmentUrl(identifier) {
  const ext = identifier.split('.').pop().toLowerCase();
  const iconUrl = getItemSvg(ext);

  return iconUrl;
}

function getVideoThumbnail(imageData) {
  if (!imageData) return '';
  return `data:image/png;base64,${imageData.replace(/\s/g, '')}`;
}

export default function TargetMessage({ onClick, msg, className, propsId, userName }) {
  const classes = useStyles();
  const [imageUrl, setImgUrl] = useState(null);
  const isImgOrVideo = ['image', 'video'].includes(msg.type);

  useEffect(() => {
    async function fetchImageUrl() {
      const image_base_path = _.get(msg, 'media.base_path', false);

      const storageFolderRef = !!image_base_path ? `${image_base_path}/chats` : `user_profiles/${propsId}/chats`;
      const url = await getImageDownloadUrl({ identifier: msg.media.identifier, storageFolderRef });
      setImgUrl(url);
    }
    if (msg?.type === 'image') {
      fetchImageUrl();
      const ext = msg.media.identifier.split('.').pop();
      const url = `data:image/${ext};base64, ${msg.media.imageData}`;
      setImgUrl(url);
    }
  }, [msg, propsId]);

  if(["attachment", "audio", "video"].includes(msg.type) && !msg.attachment) return null;

  return (
    <div className={clsx(classes.replyContainer, className)}>
      <Divider flexItem orientation="vertical" className={classes.replyBar} />
      <div className={clsx('text-grey font_13_500 rounded-10', classes.replyBubble)} onClick={onClick}>
        {["text", "attachment", "audio"].includes(msg.type) && (
          <div className="pt-10 px-12">
            {msg.type === 'text' && <p className={clsx(classes.replyBody, "text-dark-grey")}>{msg.body}</p>}
            {msg.type === 'attachment' && (
              <div className="d-flex align-items-center">
                <img
                  style={{ width: 20, height: 20 }}
                  src={getAttachmentUrl(msg.attachment.identifier)}
                  alt="Attachment"
                />
                <Typography className="font_13_500 text-grey ml-8">{msg.body}</Typography>
              </div>
            )}

            {msg.type === 'audio' && (
              <div className="d-flex align-items-center justify-content-center">
                <img alt="Play Recording" className="mr-10" src={PlayIcon} style={{ width: '20px' }} />
                <div className={`d-flex justify-content-center align-items-center`}>
                  <span className="font_11_500 text-muted">0:00</span>
                  <input
                    className={clsx('fml-10 fmr-10', classes.audioRange)}
                    type="range"
                    min="0"
                    max={msg.attachment.duration}
                    value={0}
                    disabled
                    style={{ pointerEvents: 'none', width: 121 }}
                  />
                  <span className="font_11_500 text-muted">{secondsToMinutes(msg.attachment.duration)}</span>
                </div>
              </div>
            )}
          </div>
        )}

        {msg.type === 'video' && (
          <div className="position-relative">
            <img alt="Play Recording" src={PlayIcon} className={classes.videoPlayIcon} />
            <div
              style={{
                backgroundImage: `url(${getVideoThumbnail(msg.attachment.imageData)})`,
              }}
              className={classes.videoThumb}
            ></div>
          </div>
        )}

        {msg.type === 'image' && <img src={imageUrl} alt="target-message" className={classes.replyImg} />}
        {userName && (
          <Typography
            className={clsx('font_11_500 text-grey text-truncate mt-6 px-12')}
            style={{
              width: isImgOrVideo ? 100 : 'auto',
            }}
          >
            {userName}
          </Typography>
        )}
      </div>
    </div>
  );
}
