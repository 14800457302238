import React from 'react';
import { parseImgUrl } from "croppy";
import { getUiAvatar } from "fitbud/utils/helpers";

const Img = ({name, src, size, width = 100, aspect = 1.0, className='', style}) => {
  const styles = style || {};
  styles.width = width;
  styles.height = width / aspect;
  if (!src) {
    styles.fontSize = '20px'
    let clss = 'bg-light-grey text-primary d-flex justify-content-center align-items-center ' + className;
    return (
      <div className={clss} style={styles}>
        {getUiAvatar(name).toUpperCase()}
      </div>
    );
  }

  return (
    <img
    className={className || ''}
    style={styles}
    alt={name || ''}
    src={parseImgUrl(src, size || 240)}/>
  );
};

const isEqual = (prevProps, nextProps) => {
  const {src: prevSrc, name: prevName} = prevProps || {};
  const {src: nextSrc, name: nextName} = nextProps || {};
  if (nextSrc && prevSrc === nextSrc) return true;
  return prevSrc === nextSrc && prevName === nextName;
};

const PicMemoized = React.memo(Img, isEqual);

export default PicMemoized;
