import React, { useState } from "react";
import { withRouter } from "react-router";
import {
  withMobileDialog,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Toolbar,
  AppBar,
  DialogActions,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import BackIcon from '@material-ui/icons/KeyboardBackspace';
import CloseIcon from "@material-ui/icons/CloseRounded";
import { ClrdButton } from "fitbud/components/form-fields";
import clsx from "clsx";
import Confirmation from "./confirmationDialog";
import {CustomConfirmation} from "fitbud/components/customConfirmation";

export const DialogAppBar = ({
  fullScreen,
  title,
  subtitle,
  onClose,
  onSave,
  loading,
  formId,
  headerActions=null, // these replace the standardactions completely
  additionalActions, // these preface the existing action
  toolbarClass = "height-70",
  buttonSize = "f-medium",
  buttonColor = "primary",
  titleFont = "h5",
  subtitleFont = "subtitle1",
  appBarColor = "bg-grey-new",
  position = "sticky",
  appBarTextColor = "text-0d0d0d",
  subtitleClass  = '',
  actionText = "Done",
  disablePrimaryAction = false,
  showBack=false,
  disableAction = false,
  hideHeaderDivider=false,
  headingCenter=false,
}) => {
  appBarColor=fullScreen?"bg-white":appBarColor;
  const titlePadding = !!onSave || formId ? "" : "fpr-25";
  const buttonStyle = {
    display: !!onSave || formId ? "block" : "none"
  };
  const axnProps = !!onSave ? { onClick: onSave }
    : (formId ? { type: 'submit', form: formId } : {});

  return (
    <AppBar position={position} className={appBarColor} elevation={0} >
      <Toolbar
        className={clsx(fullScreen && "d-flex justify-content-between align-item-center pl-20 pr-0",!fullScreen && "fpx-20",toolbarClass)}
        style={{borderBottom:(hideHeaderDivider || fullScreen)?'none':'1px solid #D8DCE0'}}
      >
        <IconButton edge="start" onClick={onClose} disabled={loading}>
          {showBack?<BackIcon className={appBarTextColor}/>:<CloseIcon className={appBarTextColor} />}
        </IconButton>
        <div className={clsx("d-flex flex-grow-1 fpl-8", { "justify-content-center": headingCenter })}>
          <Typography className={clsx(appBarTextColor, titlePadding)} variant={titleFont}>
            {title}
          </Typography>
          { subtitle && <Typography className={clsx(subtitleClass)} variant={subtitleFont}>
            {subtitle}
          </Typography>
          }
        </div>
        {!disableAction && (headerActions ? (typeof headerActions === 'function' ? headerActions() : headerActions) : (
          loading ? <CircularProgress size={20} color='primary'/> : (
          <div className="d-flex align-items-center">
            {additionalActions}
            <ClrdButton
              style={buttonStyle}
              className={clsx(buttonSize,fullScreen && "rounded-0")}
              variant="contained"
              color={buttonColor}
              disabled={disablePrimaryAction}
              {...axnProps}
            >
              {actionText}
            </ClrdButton>
          </div>
        )))}
      </Toolbar>
    </AppBar>
  );
};

const SimpleDialog = props => {
  const {
    open,
    onClose,
    children,
    footer,
    footerClass='d-flex justify-content-around align-items-center fp-0 fpb-20',
    footerDivider=false,
    fullScreen,
    fullWidth,
    classes,
    paperClass,
    maxWidth,
    loading,
    isDirty=false,
    withConfirmation,
    confirmationProps,
    showCustomConfirmation=false,
    handlePublish,
    handleDraft,
    headingDividerHeight,
    ...restProps
  } = props;
  const [warnDialog, setWarn] = useState(false);
  const handleClose = (e) => (!withConfirmation || !isDirty) ? onClose(e): setWarn(true);
  return (
    <Dialog
      id="dialog-document-modal"
      open={open}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      scroll="paper"
      disableBackdropClick={!!fullScreen}
      disableEscapeKeyDown={!!fullScreen}
      classes={Object.assign({}, { paper: paperClass }, restProps.className)}
    >
      {(withConfirmation && warnDialog) && <>
        {showCustomConfirmation ? 
          <CustomConfirmation 
            handleClose={() => setWarn(false)}
            handleDiscard={onClose}
            handleDraft={handleDraft}
            handlePublish={handlePublish}
            dirty={isDirty}
            showDiscard={true}
            />
        : <Confirmation 
            open={true} 
            handleCancel={() => setWarn(false)}  
            handleChange={onClose} 
            onClose={onClose}
            {...confirmationProps} 
            />  
        }
      </>
       }
      <DialogTitle className="fp-0" disableTypography>
        <DialogAppBar onClose={handleClose} fullScreen={fullScreen} loading={loading} {...restProps} />
      </DialogTitle>
      {fullScreen && <Divider style={{height: headingDividerHeight}}/>}
      <DialogContent
        className={clsx(`p-0 m-0 position-relative`, restProps.dialogContentClassName)}
        style={restProps.dialogContentStyle}
      >
        {children}
        {loading && <div className='position-absolute w-100 h-100 inset-0 bg-transluscent-white'></div>}
      </DialogContent>
      {footer && (
        <DialogActions className={footerClass}>
          <div className="w-100">
            {footerDivider && <Divider orientation="vertical" className="fmb-20" />}
            {footer}
          </div>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default withRouter(withMobileDialog({ breakpoint: "xs" })(SimpleDialog));
