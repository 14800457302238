import React, { useCallback } from 'react';
import { Typography, FormControlLabel, Switch, Card, CardContent, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { Header } from './fitness';
import { useReminder } from 'fitbud/views/reminders/provider';
import { renderTime, getOnlyOrdinalSuffix } from 'fitbud/views/reminders/helper';
import _ from 'lodash';
import CheckInEditor from '../editors/checkins';
import { useItemClass } from './fitness';
import { useStyles } from '.';

const CheckIns = (props) => {
  const { onToggle } = props;
  const classes = useStyles();
  const { edit, setEdit, readOnlyReminders, preference, getCheckInRccOptById } = useReminder();
  const itemClasses = useItemClass();
  const { day_of_month, day_of_week, mode, time, automatic } = _.get(readOnlyReminders, 'checkins', {});
  const enabled = _.get(preference, 'checkins.enabled', false);
  const openEdit = () => setEdit('check-in');
  const isMonthly = (mode || '').includes('month');
  const isDaily = (mode || '').includes('day');

  const getCheckInLabel = useCallback(
    (mode) => {
      const option = getCheckInRccOptById(mode);
      return _.get(option, 'label');
    },
    [getCheckInRccOptById]
  );

  const handleToggle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onToggle('checkins');
  };

  const renderDay = () => {
    if (automatic) return 'Automatic';
    if (isMonthly) {
      if (day_of_month === '31') return 'Last Day';
      const suffix = getOnlyOrdinalSuffix(day_of_month);
      return (
        <spam className="font_15_500">
          <span className="font_15_600">{day_of_month}</span>
          {`${suffix}`}
        </spam>
      );
    }
    return <span className="text-capitalize font_15_600">{day_of_week}</span>;
  };

  return (
    <div className="mt-15">
      <FormControlLabel
        control={
          <Switch
            color="primary"
            classes={{ root: classes.reminderSwitch }}
            checked={!!enabled}
            onChange={handleToggle}
          />
        }
        className="mb-4x"
        label={<Typography className="font_18_600 text-0d0d0d">Check-ins</Typography>}
      />
      <Card>
        <CardContent className="px-20 pb-20 pt-12">
          <Header title={getCheckInLabel(mode)} handleEdit={openEdit} />
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(170px, 1fr))',
            }}>
            {/* Mode */}
            {!isDaily && (
              <div className={clsx(itemClasses.container)}>
                <Typography className={clsx('font_13_500 text-light-grey-2 text-truncate mb-8')}>Day</Typography>
                <Typography className={clsx('text-0d0d0d')}>{renderDay()}</Typography>
              </div>
            )}
            <div className={clsx(itemClasses.container)}>
              <Typography className={clsx('font_13_500 text-light-grey-2 text-truncate mb-8')}>Time</Typography>
              <Typography className={clsx('text-0d0d0d')}>{renderTime(time)}</Typography>
            </div>
          </div>
        </CardContent>
      </Card>
      {edit === 'check-in' && <CheckInEditor />}
    </div>
  );
};
export default CheckIns;
