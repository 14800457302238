import React, { Component } from "react";
import firebase from "fitbud/firebase";
import withWidth from "@material-ui/core/withWidth";
import { getThumborUrlByAspect } from "fitbud/utils/thumborHelper";
import { withSnackbar } from "notistack";

const storageRef = firebase.storage().ref();

class ImgAspectFill extends Component {
  constructor(props) {
    super(props);
    this.state = { url: null };
  }
  toggleModal = () => {
    this.setState(state => ({ modalIsOpen: !state.modalIsOpen }));
  };
  render() {
    const aspect = this.props.aspect || 1.0;
    const ht = this.props.targetHeight
      ? this.props.targetHeight + "px"
      : 100.0 / aspect + "%";
    const { backgroundColor, ...styleIn } = this.props.style || {};
    const style = {
      ...styleIn,
      position: "relative",
      height: 0,
      paddingTop: `${ht}`,
      overflowY: "hidden",
      width:"100%"
    };
    let bgStyle = {
      width: "100%",
      height: "auto",
      top: 0,
      left: 0,
      objectFit:"contain"
    };
    if (this.props.circle) bgStyle.borderRadius = "50%";
    if (this.props.stroked) bgStyle.border = "1px solid rgba(0, 0, 0, 0.3)";
    if (this.props.imageStyles) bgStyle = { ...bgStyle, ...this.props.imageStyles};
    return (
      <div
        ref={node => {
          this.theDiv = node;
        }}
        className={this.props.className || ""}
        style={style}
        onClick={this.toggleModal}
      >
        <img
          ref={node => {
            this.theImg = node;
          }}
          alt={this.props.alt || ""}
          className="position-absolute"
          style={bgStyle}
          onClick={() => {
            if (this.props.completed)
              window.location.hash = `image&${this.props.storageFolderRef}&${
                this.props.bucketName
              }&${this.props.identifier}&${this.props.position || 0}`;
            // else
            //   this.props.enqueueSnackbar("Image is still uploading", { variant: "error" });
          }}
        />
      </div>
    );
  }

  refreshImgUrl = async () => {
    const { url } = this.state;
    const imgBucket = !!url ? url.split("/")[0] : null;
    if (
      !this.theDiv ||
      !this.props.width ||
      !this.props.aspect ||
      !this.props.identifier
    )
      return; // cannot proceed
    const vWidth = this.theDiv.offsetWidth;
    if (vWidth <= 0) return;
    const urlIn = this.props.urlIdentifier || this.props.identifier;
    if (!urlIn) return;
    const thumborUrl = getThumborUrlByAspect(
      urlIn,
      vWidth,
      this.props.imgAspect || this.props.aspect,
      this.props.bucketName
    );
    if (thumborUrl === this.thumborUrl) return;
    if ( !url || Math.round(vWidth * (window.devicePixelRatio || 1) > imgBucket)) {
      const { authorId = "", authUserId = "" } = this.props;
      let url = !!authorId && !!authUserId && (authorId === authUserId)
          ? `${this.props.storageFolderRef}/original/${this.props.urlIdentifier||this.props.identifier}`
          : `${this.props.storageFolderRef}/${thumborUrl}`;
      try {
        const downloadUrl = await storageRef.child(`${url}`).getDownloadURL();
        this.thumborUrl = thumborUrl;
        this.theImg.src = downloadUrl;
        this.setState({
          url: this.thumborUrl
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  componentDidMount() {
    let ext = this.props.ext;
    this.theImg.src = `data:image/${ext};base64, ${this.props.imageData}`;
    if (this.props.completed) this.refreshImgUrl();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.width !== this.props.width) {
      if (this.props.completed) this.refreshImgUrl();
    }
    if (this.props.completed && prevProps.completed !== this.props.completed) {
      this.refreshImgUrl();
    }
  }
}

export default withWidth()(withSnackbar(ImgAspectFill));
