import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { UserListInner } from '../views/users/list';
import { Divider, Grid, IconButton, InputAdornment, InputBase, LinearProgress, List, ListItem, ListItemAvatar, ListItemText, makeStyles, Typography } from '@material-ui/core';
import { calculateHeight, getEmailFirstLetter, getNameAndImage, handleError, pluralize } from 'fitbud/utils/helpers';
import useDebounce from 'fitbud/hooks/useDebounce';
import { fetchData } from 'fitbud/api';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import appRdxFns from "../redux/app/";
import { AvatarImage } from '../views/users/header';
import { Close } from '@material-ui/icons';
import Dialog from 'fitbud/components/Dialog';
import InfiniteScroll from 'react-infinite-scroll-component';
import searchIcon from 'fitbud/images/searchIcon.svg';
import NoUserList from "fitbud/images/no_selected_user.svg";
import BlockingLoader from './BlockingLoader';
import { useDispatch } from 'react-redux';

const keyName = "browse";
const PER_PAGE = 15;

const queryFn = async ({ q, page, per_page = 15, cid }) => {
  const res = await fetchData('user_profiles', cid, page, q, per_page, null, 'browseUsersList')
  return res.data;
}

const styles = makeStyles((theme) => ({
  searchInput: {
    borderRadius: '20px',
    height: 40,
    border: '0.5px solid #D3D9DB',
    background: '#fff',
    padding: '0px 10px',
    fontSize: '13px',
    marginBottom: 15
  }
}));

const AllUsersList = (props) => {
  const { items, onSelect, query, keyName, cid, scrollerRef, handleScroll, selectedItems } = props;
  return (
    <List
      disablePadding
      className="position-relative overflow-auto flex-1"
      id={keyName}
      ref={scrollerRef}
      onScroll={handleScroll}
    >
      <UserListInner
        cid={cid}
        keyName={keyName}
        docs={items}
        query={query}
        onSelect={onSelect}
        selected={selectedItems}
        hidePlanInfo
        emailTextClasses="font_13_500"
        showTooltip={["chats", "checkins"].includes(keyName)}
      />
    </List>
  );
};
export const UsersListInnerSmall = (props) => {
  const { items, onRemove, disableRemove, showEmail=false, id='clientsList', infiniteScrollProps={}, primaryTextClass = "" } = props;
  return (
    <List
      disablePadding
      className="position-relative overflow-auto bg-white"
      id={id}
    >
      <InfiniteScroll {...infiniteScrollProps} dataLength={items.length} scrollableTarget={id}>
        {items.map((doc) => {
          let { name, image_data, email, identifier } = getNameAndImage(doc, keyName);
          return (
            <ListItem key={doc._id} id={doc._id}>
              <ListItemAvatar>
                <AvatarImage
                  src={identifier ? `user_profiles/${doc._id}/profile/original/${identifier}` : image_data}
                  name={name || getEmailFirstLetter(email)}
                  base64={!identifier}
                  alt={name || " "}
                  styles={{ fontSize: 13, fontWeight: 600 }}
                />
              </ListItemAvatar>
              <ListItemText
                primaryTypographyProps={{component:"p"}}
                primary={<span className="text-truncate font_13_600">{name || getEmailFirstLetter(email)}</span>}
                secondary={showEmail ? <span className="text-truncate font_13_600">{email|| ""}</span> : ""}
                classes={{secondary: 'font', primary:primaryTextClass}}
              />
              {!disableRemove && <IconButton className="bg-grey-new" style={{ padding: '6px' }} onClick={(e) => {e.preventDefault();onRemove(doc._id)}}>
                <Close style={{ fontSize:15 }} />
              </IconButton>}
            </ListItem>
          )
        })}
      </InfiniteScroll>
    </List>
  )
}
const MultiClientSelector = (props) => {
  const { open, onClose, onSave, actionText, selectedUsers=[], title, primaryTextClass, headerActions } = props; 
  const { enqueueSnackbar } = useSnackbar();
  const { cid } = useContext(FirebaseAuthContext);

  const d = useDispatch();
  const { showLoader, hideLoader } = appRdxFns(d);
  // Local State
  const [selectedClients, setSelectedClients] = useState([]);
  const [allClients, setAllClients] = useState([]);
  const [query, setQuery] = useState("");
  const [nextPage, setNextPage] = useState(1);
  const [isDirty, setDirty] = useState(false);
  const [loadingState, setLoading] = useState("initial");
  const [count, setCount] = useState(0);

  // Refs
  const dataLimit = useRef(0);
  const debouncedSearchTerm = useDebounce(query, 500);

  const scrollerRef = useCallback(node => {
    if (node !== null) {
      dataLimit.current = calculateHeight(node)
    }
  }, []);

  const handleItemSelect = (selected) => {
    if (loadingState) return;
    if(!isDirty) setDirty(true);
    const newSelectedClient = _.find(allClients, (i) => i._id === selected);
    setSelectedClients(prev => ([ ...prev, { ...newSelectedClient } ]));
  };

  const handleRemoveItem = (selected) => {
    if(!isDirty) setDirty(true);
    const newList = selectedClients.filter(a => a._id !== selected);
    setSelectedClients(newList);
  };

  // Search Query Logic
  const handleQueryChange = (e) => {
    const { value } = e.target;
    setQuery(value);
  };

  const fetchPage = async (q = query, page = 0) => {
    try {
      if(loadingState === 'initial') showLoader();
      else setLoading(true);
      const res = await queryFn({ q, page, per_page: PER_PAGE, cid })
      setAllClients(docs => (page ? docs : []).concat(res.data));
      if(!q && !page) setCount(res.count); //store initial count so we can check how much original data have...
      setNextPage(res.data.length === PER_PAGE ? page + 1 : null);
    } catch (error) {
      handleError(error, enqueueSnackbar);
    } finally {
      if(loadingState === "initial") hideLoader()
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchPage(query, 0);
    setNextPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const handleScroll = e => {
    if (loadingState) return;
    if(allClients.length && allClients.length === count) return;
    if (e.target.scrollTop + e.target.clientHeight >= (e.target.scrollHeight - 100)) {
      fetchPage("", nextPage)
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    onSave([...selectedClients]);
  };

  const clientsToSelect = useMemo(() => [...selectedClients, ...selectedUsers].length ?
  (allClients || []).filter((a) => ![...selectedClients, ...selectedUsers].map(b => b._id).includes(a._id))
    : allClients, [selectedClients, selectedUsers, allClients]);

  useEffect(() => {
    if(loadingState) return; //if loading do nothing 
    if(!!query) return; 
    if((!clientsToSelect.length || clientsToSelect.length < PER_PAGE ) && count > allClients.length){ //corner case: if not query and nothing to show and original count is more than selected, then fetch next page.
      fetchPage(query, nextPage);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[clientsToSelect.length, count, allClients, loadingState, query ]);
  const classes = styles();
  if(loadingState === 'initial') return null;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={title || "Select clients to add"}
      onSave={handleSave}
      paperClass="width-800 height-70"
      titleFont="h3"
      hideHeaderDivider
      withConfirmation
      isDirty={isDirty}
      actionText={actionText}
    >
      <div className='overflow-hidden h-100 d-flex flex-column position-relative'>
      <div className='w-100 bg-grey-new px-20'>
        <InputBase fullWidth value={query}
          className={classes.searchInput + ' bg-white'}
          onChange={handleQueryChange}
          startAdornment={
            <InputAdornment position="start">
              <img alt="search-icon" src={searchIcon}/>
            </InputAdornment>
          }
          placeholder={"Search by name, tag ..."}
        />
      </div>
      <Grid container className="flex-grow-1 overflow-hidden position-relative">
        {/* Loader */}
        {!!loadingState && <LinearProgress className="position-absolute w-100 height-2" />}

        {/* All items component */}
        <Grid item xs={7} className="h-100 d-flex position-relative"
          style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}
        >
          {!clientsToSelect || !clientsToSelect.length ? (
            <div className='flex-1 d-flex flex-column justify-content-center align-items-center'>
              <img src={NoUserList} alt="no item selected" style={{width: '120px'}} />
              <Typography variant='body1' className='mt-15' color='textSecondary'>
                {loadingState ? "Please wait..."  : "No matching Client found"}
              </Typography>
            </div>
          ) : 
          <AllUsersList 
            items={clientsToSelect} 
            onSelect={handleItemSelect} 
            scrollerRef={scrollerRef} 
            handleScroll={handleScroll} 
          /> }
        </Grid>
        <Grid className="h-100 w-100 d-flex flex-column" item xs={5}>
          {/* Selected Count */}
          {headerActions && headerActions()}
          <div className='bg-white pt-20 px-20 w-100 mb-8'>
            <div className='d-flex justify-content-between'>
              <Typography className="font_15_600">
                Selected
              </Typography>
              <Typography className="font_13_500">
                <span className='font_13_700'>
                  {selectedClients.length}
                </span> {' '}
                {`Client${pluralize(selectedClients.length)}`}
              </Typography>
            </div>
            <Divider className='w-100 mt-20' />
          </div>

          {/* Selected List Component */}
          {!selectedClients.length ? (
            <div className='flex-1 d-flex flex-column justify-content-center align-items-center'>
              <img src={NoUserList} alt="no item selected" style={{width: '120px'}} />
              <Typography variant='body1' className='mt-15' color='textSecondary'>
                Please select a client
              </Typography>
            </div>
          ) : <UsersListInnerSmall primaryTextClass={primaryTextClass} items={selectedClients} onRemove={handleRemoveItem} />
          }
        </Grid>
      </Grid>
    </div>
    </Dialog>
  )
};

export default MultiClientSelector;