import { makeStyles, Paper } from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';
import React, { useMemo } from 'react';

const useStyles = makeStyles(() => ({
  bubble: {
    padding: '3px 7px',
    borderRadius: '25px',
    display: 'flex',
    columnGap: 5,
    fontSize: 13,
    fontWeight: 500,
    lineHeight: '16px',
    position: 'absolute',
    top: '-11px',
    zIndex: 1,
  },
  rightReact: {
    left: '-10px',
  },
  leftReact: {
    right: '-10px',
  },
}));

function getReactionsCounts(reactions = {}) {
  const reactMap = {};
  Object.values(reactions).forEach((reaction) => {
    if (reactMap[reaction.text]) {
      const prevDate = moment(reactMap[reaction.text]._uat.toDate());
      const newDate = moment(reaction._uat.toDate());
      reactMap[reaction.text].count += 1;
      if (newDate.isAfter(prevDate)) {
        reactMap[reaction.text]._uat = reaction._uat;
      }
    } else {
      reactMap[reaction.text] = {
        count: 1,
        _uat: reaction._uat,
      };
    }
  });
  return reactMap;
}

export default function ReactionBubble({ reactions = {}, isRightSide }) {
  const classes = useStyles();

  const totalReactions = Object.values(reactions).length;
  const reactionCounts = useMemo(() => getReactionsCounts(reactions), [reactions]);

  const sortedReactions = useMemo(() => {
    return Object.keys(reactionCounts).sort((a, b) => {
      if (reactionCounts[a].count === reactionCounts[b].count) {
        const aUat = moment(reactionCounts[a]._uat.toDate());
        const bUat = moment(reactionCounts[b]._uat.toDate());
        return aUat.isBefore(bUat) ? 1 : -1;
      }
      return reactionCounts[b].count - reactionCounts[a].count;
    });
  }, [reactionCounts]);

  const firstThreeReactions = sortedReactions.slice(0, 3);

  if(totalReactions === 0) return null;

  return (
    <Paper elevation={2} className={clsx(classes.bubble, isRightSide ? classes.rightReact : classes.leftReact)}>
      {firstThreeReactions.map((text) => (
        <span>{text}</span>
      ))}
      {<span>{totalReactions}</span>}
    </Paper>
  );
}
