import { Card, Menu, MenuItem, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CalendarIcon } from "fitbud/icons/calendarIcon";
import DownIcon from "fitbud/images/down.svg";
import NormalIcon from "fitbud/images/normal.svg";
import NoProgress from "fitbud/images/trainer_no_schedule.svg";
import UpIcon from "fitbud/images/up.svg";
import { Conversion } from "fitbud/providers/conversion";
import { oprtnlDateFormat } from "fitbud/utils/constants";
import { roundNumber } from "fitbud/utils/helpers";
import { calculateValues } from "fitbud/views/users/subComponents";
import _ from "lodash";
import moment from "moment";
import React, { useContext, useMemo, useState } from "react";
import ProgressImages from "./progressImages";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import { NoAccessPlaceholder } from "./checkinStepper";
const DISPLAY_DATE_FORMAT = "DD MMM, YYYY";

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: "center"
  },
  spanColor: {
    color: "#7f8b9b"
  },
  paper: {
    border: "none",
    borderRadius: "10px!important"
  },
  heading: {
    fontSize: "16px",
    fontWeight: "600"
  },
  borderRight: {
    borderRight: "1px solid #ffff"
  }
}));

const ProgressNotFound = () => {
  return (
    <div className="h-100 text-center d-flex align-items-center justify-content-center flex-column">
      <img alt="no progress" src={NoProgress} className="fmb-20" />
      <Typography variant="h2" className={`fmb-10`}>
        No Progress
      </Typography>
      <Typography variant="body2">
        You can see this user's progress here after 2 check-ins
      </Typography>
    </div>
  )
}

const Difference = (props) => {
  const { checkInsLeft, checkInsRight } = props;
  let diff = roundNumber(checkInsRight - checkInsLeft, 1);
  const classes = useStyles();

  function renderValue() {
    if (diff > 0) {
      return (
        <>
          <img src={UpIcon} className="fpt-10" alt='fitbud-up-icon' />
          <Typography className={`font_14_500 fmt-5`} style={{ color: "#fd8847" }} >{`+${Math.abs(diff)}`}</Typography>
        </>
      )
    }
    else if (diff < 0) {
      return (
        <>
          <img src={DownIcon} className="fpt-10" alt='fitbud-down-icon' />
          <Typography className={`font_14_500 fmt-5`} style={{ color: "#fd8847" }} >{`-${Math.abs(diff)}`}</Typography>
        </>
      )
    } else if (diff === 0) {
      return (
        <>
          <img src={NormalIcon} className="fpt-10" alt='fitbud-normal-icon' />
          <Typography className={`font_14_500 fmt-5`} style={{ color: "#fd8847" }} >{`0`}</Typography>
        </>
      )
    }
  }

  return (
    <div className={classes.root}>
      {renderValue()}
    </div>
  );
};



const CompareProgress = ({ docId,
  checkinsAggregate,
  measurableTags,
  measurableUnits,
  unitSystem = "metric",
  unitDetails,
  userDoc = {},
  checkinAggPhotosArray,
  compChkinConf,
}) => {
  const { features, userProfile, cid } = useContext(FirebaseAuthContext);
  const classes = useStyles();
  const [initialCheckins, setInitialCheckins] = useState(checkinsAggregate)
  const [anchorLeftEl, setLeftAnchorEl] = useState(null);
  const [anchorRightEl, setRightAnchorEl] = useState(null);
  const { convertor } = useContext(Conversion);
  let mo = checkinAggPhotosArray && checkinAggPhotosArray.length > 1 ?
    checkinAggPhotosArray.map(d => moment(d)) :
      checkinsAggregate && checkinsAggregate.map((d) => moment(d.date));
  const minDate = moment(moment.min(mo)).format("YYYYMMDD")
  const maxDate = moment(moment.max(mo)).format("YYYYMMDD")
  const firstCheckins = _.find(checkinsAggregate, ['date', minDate])
  const lastCheckins = _.find(checkinsAggregate, ['date', maxDate])
  const [checkInsLeft, setCheckInsLeft] = useState(firstCheckins)
  const [checkInsRight, setCheckinsRight] = useState(lastCheckins)
  let startDate = userDoc.startDate || null;

  function fetchWeeks(item, suffix = '') {
    let week = item && item.tag === "00000000" ? 0 : Math.ceil(moment(item && item.tag, oprtnlDateFormat).diff(moment(startDate),"days") / 7);
    if(week < 0) return '';
    return !week ? `Day 1 ${suffix}` : `Week ${week}${suffix}`;
  }

  const handleClickLeftPicker = (event) => {
    setLeftAnchorEl(event.currentTarget);
  };

  const handleClickRightPicker = (event) => {
    setRightAnchorEl(event.currentTarget);
  };

  const handleChangeLeftWrapper = (e) => {
    const value = e.target.value
    if (!value) return;
    const filterCheckins = _.find(initialCheckins, ['date', value.toString()]);
    setCheckInsLeft(filterCheckins)
    setLeftAnchorEl(null);
  }

  const handleChangeRightWrapper = (e) => {
    const value = e.target.value
    if (!value) return;
    const filterCheckins = _.find(initialCheckins, ['date', value.toString()]);
    setCheckinsRight(filterCheckins)
    setRightAnchorEl(null);
  }

  const handleClose = () => {
    setLeftAnchorEl(null);
    setRightAnchorEl(null);
  };
  const leftWeeks = fetchWeeks(checkInsLeft);
  const rightWeeks = fetchWeeks(checkInsRight);
  const hasImageAccess = useMemo(() => !features.privacy || !!_.get(userProfile, `meta.${cid}.checkin`, false), [userProfile, cid, features.privacy]);
  return (
    <div className='h-100 bg-white'>
      {checkinsAggregate && checkinsAggregate.length > 1 ?
        <div>
          <div className="d-flex flex-wrap justify-content-between align-items-center fpx-20 fmt-25 fmb-5">
            <div className='w-50 d-flex justify-content-center align-items-center' style={{ borderRight: "2px solid #DFDFE0"}}>
              <div onClick={handleClickLeftPicker} aria-controls="day-one" aria-haspopup="true" className="d-flex align-items-center cursor-pointer">
                <CalendarIcon className='fmr-10'/>  
                <Typography variant="body1" className='fmr-10'>
                  {!!leftWeeks && leftWeeks} {' '} &bull; {' '} {checkInsLeft && moment(checkInsLeft.date, oprtnlDateFormat).format('DD MMM')}
                </Typography>
              </div>
              <Menu
                id="day-one"
                anchorEl={anchorLeftEl}
                keepMounted
                open={Boolean(anchorLeftEl)}
                onClose={handleClose}
                onClick={handleChangeLeftWrapper}
              >
                {initialCheckins.map((item, i) => (
                  <MenuItem disabled={checkInsRight &&  (item.date == checkInsRight.date || item.date > checkInsRight.date) } key={item.date} value={item.date}>
                    {fetchWeeks(item, ' - ') + moment(item.date, oprtnlDateFormat).format(DISPLAY_DATE_FORMAT)}
                  </MenuItem>
                ))}
              </Menu>
            </div>
            <div className='w-50 d-flex justify-content-center align-items-center'>
              <div onClick={handleClickRightPicker} aria-controls="right" align='right' aria-haspopup="true" className="d-flex cursor-pointer" >
                <CalendarIcon className='fmr-10'/>  
                <Typography variant="body1" className='fmr-10'>
                  {!!rightWeeks && rightWeeks} {' '} &bull; {' '} {checkInsRight && moment(checkInsRight.date, oprtnlDateFormat).format('DD MMM')}
                </Typography>
              </div>
              <Menu
                id="right"
                anchorEl={anchorRightEl}
                keepMounted
                open={Boolean(anchorRightEl)}
                onClose={handleClose}
                onClick={handleChangeRightWrapper}
              >
                {initialCheckins.map((item, i) => (
                  <MenuItem disabled={checkInsLeft && (item.date === checkInsLeft.date || item.date < checkInsLeft.date) } key={item.date} value={item.date}>
                    {fetchWeeks(item, ' - ') + moment(item.date, oprtnlDateFormat).format(DISPLAY_DATE_FORMAT)}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </div>
          <div className='position-relative fp-20 mx-auto'>
            {hasImageAccess ? <ProgressImages docId={docId} checkInsLeft={checkInsLeft} checkInsRight={checkInsRight}
              compChkinConf={compChkinConf} userChkinConf={userDoc && userDoc.overrides} /> : <NoAccessPlaceholder />}
          </div>
          <div className="d-flex flex-wrap fpx-20">
            {measurableTags &&
              Object.keys(measurableTags).length > 0 &&
              Object.keys(measurableTags).map((i, j) => {
                if (!measurableTags[i].tracking) return null;
                let leftCheckinValue = checkInsLeft && checkInsLeft.measurements && checkInsLeft.measurements[i];
                let rightCheckinValue = checkInsRight && checkInsRight.measurements && checkInsRight.measurements[i];
                let unit_type = measurableTags[i] && measurableTags[i].unit_type;
                const leftValue = calculateValues({
                  value: roundNumber(leftCheckinValue, 1),
                  unitSystem,
                  unitDetails,
                  measurableUnits,
                  unit: unit_type,
                });
                const rightValue = calculateValues({
                  value: roundNumber(rightCheckinValue, 1),
                  unitSystem,
                  unitDetails,
                  measurableUnits,
                  unit: unit_type,
                });
                if(!leftValue && !rightValue) return null; // Hide cards with no data on either sides
                return (
                  <Card className="d-flex flex-column fmb-20 w-100 light-gray-border rounded-10" key={j}>
                    <Paper className={`bg-white fp-20 ${classes.paper}`}>
                      <Typography className={`fmb-20 ${classes.heading}`}>
                        {measurableTags[i].value}
                      </Typography>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <Typography variant="h1" className='m-0 w-25'>
                          { leftValue || "-"}
                        </Typography>
                        {leftCheckinValue && rightCheckinValue &&
                          <Difference checkInsLeft={convertor && convertor.getDisplayValue(unit_type, leftCheckinValue)} checkInsRight={convertor && convertor.getDisplayValue(unit_type, rightCheckinValue)} />}
                        <Typography variant="h1" className='m-0 w-25' align='right'>
                          { rightValue || "-"}
                        </Typography>
                      </div>
                    </Paper>
                  </Card>
                )
              })}
          </div>
        </div>
        : <ProgressNotFound />}
    </div>
  )
};
export default CompareProgress;
