import { AccordionSummary, AccordionDetails, CircularProgress, Divider, Accordion, makeStyles, Paper, Typography, IconButton } from '@material-ui/core';
import React, { useState } from 'react';
import { findComplianceMark } from '../progress/helpers';
import clsx from 'clsx';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLess from '@material-ui/icons/ExpandLess';
import { Images } from '../subComponents';
import _ from 'lodash';
import Slider from '../checkIns/slider';
import usePoses from 'fitbud/hooks/usePoses';

const styles = makeStyles((theme) => (
  {
    dataGridRoot:{
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      alignItems: 'start',
    },
    circularProgRoot:{
      transform: 'rotate(125deg) !important',
      zIndex: 1,
      position: 'absolute',
      left: '-1.6px',
      top: '-1.2px',
    },
    top:{
      color: ({complianceColor}) => `#${complianceColor}` || theme.palette.primary,
    },
    bottom: {
      color: '#D8DCE0',
      zIndex: 0,
    },
    circularProgLabel:{
      position:'absolute',
      padding:'3px 6px',
      zIndex: 2,
      bottom: 0,
      left:'50%',
      transform: 'translateX(-50%)',
      minWidth:'38px',
      overflow: 'hidden'
    },
    complianceText:{
      position: 'absolute',
      top: 0,
      left: '50%',
      transform: 'translate(-50%, 65%)',
    },
    roundedCircle:{
      strokeLinecap: "round !important",
    },
    accordianRoot:{
      margin: '0px !important',
      padding: '0px !important',
      minHeight: '0px !important',
      borderRadius: '6px !important',
      backgroundColor: 'white',
      '&:before': {
        display: 'none'
      },
    },
    summaryRoot:{
      margin: '0px !important',
      padding: '0px',
      minHeight: '0px',
    },
    summaryContent:{
      minHeight: '0px !important',
      margin: '0px !important',
      padding: ({ isCollapsible, rightAdornment, 
        paddingHorizontal: pH = 15, paddingVertical: pV = 15 }) => 
        !!isCollapsible ? `${pV - 7}px ${pH}px`: 
          !isCollapsible && !!rightAdornment ? `${pV - 7}px ${pH}px` : `${pV - 3}px ${pH}px`,
    },
    summaryExpanded:{
      minHeight: '0px !important',
    },
  }
));
export const ComplianceCircularProgress = ({ compliance=0, complianceConfig }) => {
  const { label, color } = findComplianceMark(complianceConfig, compliance);
  const classes = styles({ complianceColor: color });
  const differedValue = compliance < 20 ? compliance + ((compliance / 100) * 20):compliance - ((compliance / 100) * 20);
  return (
    <div className='position-relative'>
      <CircularProgress 
        size={60}
        variant="determinate"
        value={differedValue}
        className={classes.top}
        classes={{
          root: classes.circularProgRoot,
          circle: classes.roundedCircle,
        }}
        thickness={4} />
        <CircularProgress
          variant="determinate"
          className={classes.bottom}
          size={57}
          thickness={1.5}
          value={100}
        />
      <Typography variant='h2' className={classes.complianceText}>
        {compliance || '-'}
      </Typography>
      {!!compliance && <Paper className={classes.circularProgLabel+ ' mx-auto'}>
        <Typography className='font_10_500 text-center'>
          {label}
        </Typography>
      </Paper>}
    </div>
  );
}
export const HText = ({ title, ...rest }) => <Typography variant='h6' {...rest} >{title}</Typography>
export const HeadingAndLabel = ({heading, label, headingProps, labelProps, headingComponent }) => (
  <div>
    {headingComponent ? headingComponent() : <Typography variant={'h2'} className='font_20_700' noWrap {...headingProps}>{heading}</Typography>}
    <Typography variant={'subtitle1'} color='textSecondary' className='fmt-5' noWrap {...labelProps}>{label}</Typography>
  </div>
);
export const DataContainer = ({left=null, right=null, center=null, divider=false}) => {
  const classes = styles();
  return (
    <>
      <div className={classes.dataGridRoot + " fpx-15 fpt-15"}>
        <div>
          {!!left && left()}
        </div>
        <div className='fml-30 '>
          {!!center && center()}
        </div>
        <div className='d-flex fml-30 justify-content-end'>
          {!!right && right()}
        </div>
      </div>
      {divider && <Divider className='fmx-15 fmt-15'/>}
    </>
  )
};

export const ParentCard = ({
  title='Card Title',
  rightAdornment=false,
  cardRootClasses='',
  isCollapsible,
  rightEdge, 
  noDivider, 
  titleVariant,
  paddingHorizontal,
  paddingVertical,
  minHeight,
  titleRootStyles,
  noBottomPadding,
  ...restProps 
}) => {
  const [isExpanded, setExpanded] = useState(isCollapsible ? title : true );
  const handleCollapser = (e, expanded) => {
    if(!isCollapsible) return setExpanded(true);
    setExpanded(expanded ? title : false);
  };
  const classes = styles({ isCollapsible, rightAdornment, paddingVertical, paddingHorizontal, minHeight});
  return (
    <Accordion
      elevation={0}
      classes={{
        root: clsx(classes.accordianRoot, cardRootClasses),
      }}
      expanded={isCollapsible ? isExpanded === title : true}
      id={title}
      onChange={handleCollapser} 
      {...restProps}
    >
      <AccordionSummary
        classes={{ 
          root: classes.summaryRoot,
          content: clsx(classes.summaryContent),
          expanded: clsx(classes.summaryExpanded),
        }}
      >
        <div className='d-flex flex-1 bg-white justify-content-between align-items-center' style={titleRootStyles}>
          <Typography variant={titleVariant || 'body2'} className='font-weight-bold fmy-5'>
            {title}
          </Typography>
          <div className='d-flex align-items-center position-relative'>
            {!!rightAdornment && rightAdornment()}
            {isCollapsible && (
              <IconButton size='small' classes={{root: 'black'}}>
                {isExpanded ? <ExpandLess /> : <ExpandMoreIcon />}
              </IconButton>
            )}
          </div>
        </div>
      </AccordionSummary>
      {!!restProps.children && (
        <AccordionDetails classes={{root: 'fp-0'}}>
          <div className={clsx('flex-1 w-100 h-100', !noDivider && 'border-top', !noBottomPadding && "fpb-15")}>
            {restProps.children}
          </div>
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export const ProgressInfoImages = (props) => {
  const { checkInsLeft, checkInsRight, docId, compChkinConf, userChkinConf } = props;
  const poses = usePoses(compChkinConf, userChkinConf, checkInsLeft, checkInsRight);
  
  // Make new_identifier for each side to eliminate null images and then feed to url logic
  const new_identifier_left = poses.reduce((prev, curr, idx) => {
    const side_identifier = !!checkInsLeft && _.get(checkInsLeft, `photos.${curr}`, false)
    ? (_.get(checkInsLeft, `photos.${curr}.identifier`, "") || _.get(checkInsLeft, "photos.identifier", "")) : false ;
    if(side_identifier) return prev.concat(idx  > 0 ? '%' : '', `${curr}_${side_identifier}`);
    return prev;
  }, '');
  const new_identifier_right = poses.reduce((prev, curr, idx) => {
    const side_identifier = !!checkInsRight && _.get(checkInsRight, `photos.${curr}`, false)
    ? (_.get(checkInsRight, `photos.${curr}.identifier`, "") || _.get(checkInsRight, "photos.identifier", "")) : false ;
    if(side_identifier) return prev.concat(idx  > 0 ? '%' : '', `${curr}_${side_identifier}`);
    return prev;
  }, '');

  return (
    <div className='px-15 mx-n1'>
      <Slider num={2} className='mb-15' childClass='px-1'>
        {poses.map((i, j) => {
          const leftIdentifier = checkInsLeft && (_.get(checkInsLeft, `photos.${i}.identifier`, "") || _.get(checkInsLeft, "photos.identifier", ""));
          const rightIdentifier = checkInsRight && (_.get(checkInsRight, `photos.${i}.identifier`, "") || _.get(checkInsRight, "photos.identifier", ""));
          if(!_.get(checkInsLeft, `photos.${i}`, false) && !_.get(checkInsRight, `photos.${i}`, false)) return null;
          return (
            <div className='d-flex justify-content-center h-100 w-100 px-1' key={i}>
              <div className='w-50'>
                <Images
                  side={i}
                  key={`${i}_${leftIdentifier}}`}
                  img={checkInsLeft && _.get(checkInsLeft, `photos[${i}]`, {})}
                  userId={docId}
                  identifier={`${i}_${leftIdentifier}`}
                  aspect={0.5}
                  position={j}
                  new_identifier={new_identifier_left}
                  imageStyles={{borderRadius: '15px 0px 0px 15px', height: '100%', objectFit: 'cover' }}
                />
              </div>
              <div style={{ borderRight: "1px solid #FFF" }}></div>
              <div className='w-50'>
                <Images
                  side={i}
                  key={`${i}_${rightIdentifier}`}
                  img={rightIdentifier !== leftIdentifier ? checkInsRight && _.get(checkInsRight, `photos[${i}]`, {}) : {}}
                  userId={docId}
                  identifier={checkInsLeft && checkInsRight && checkInsLeft.date === checkInsRight.date ? '' : `${i}_${rightIdentifier}`}
                  aspect={0.5}
                  position={j}
                  new_identifier={new_identifier_right}
                  imageStyles={{borderRadius: '0px 15px 15px 0px', height: '100%', objectFit: 'cover' }}
                />
              </div>
            </div>
        )})}
      </Slider>
    </div>
  )
}
