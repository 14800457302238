import React,{useState,useEffect,useContext,useMemo, useRef} from 'react';
import { IconButton, Typography,TextField } from '@material-ui/core';
import { DeleteIcon } from 'fitbud/icons/delete';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FormTextField } from "fitbud/components/form-fields";
import _ from 'lodash';
import { RecipeContext } from '../foodRecipesInfo';
import {DEFAULT_ERROR} from "fitbud/utils/constants";
import clsx from 'clsx';
import { Conversion } from 'fitbud/providers/conversion';
import { InputDropdown,findStandardUnit } from "fitbud/views/foodRecipes/newUi/inputDropdown";
import {mapDumbUnitToServingType} from "fitbud/utils/helpers";

const useStyles = makeStyles(() => ({
  icons: {
    height: '24px !important',
    width: '24px !important',
  },
  opacityHalf:{
    opacity:0.5
  }
}));

const IngredientInput = (props) => {
  const { convertor } = useContext(Conversion);
  const {
    onRemove,
    item,
    onSelect,
    inputProps,
    error,
    _sortServing,
    dumbUnits,
    index,
    provided,
    snapshot,
    withoutQuantity,
    data,
    handleServingChange,
    onServingSizeChange,
    // handleCreateNewIng,
    loading,
  } = props;
  const classes = useStyles();
  // const { enqueueSnackbar } = useSnackbar();
  // const { cid } = useContext(FirebaseAuthContext);
  const oldServingUnitRef=useRef(item.serving_unit);
  const {fetchedIngredients}=useContext(RecipeContext);
  // const [isLoading,toggleOptionLoading]=useState(false);
  //TODO:Delete this
  // const ingredient=useMemo(()=>{
  //   if(fetchedIngredients && item.ref_id){
  //     return fetchedIngredients[item.ref_id]
  //   }
  //   return null;
  // },[fetchedIngredients,item])
  const food=useMemo(()=>{
    if(fetchedIngredients && item.ref_id){
      return fetchedIngredients[item.ref_id]
    }
    return null;
  },[fetchedIngredients,item])
  const standardUnit=useMemo(()=>{
    const {serving_type}=data;
    return findStandardUnit(serving_type,_sortServing);
  },[data.serving_type])
  useEffect(()=>{
    oldServingUnitRef.current=item.serving_unit;
  },[item.serving_unit]);
  const servingOptions = useMemo(() => {
    let arr = [];
    if (!food) return [];
    //-------------serving options
    const serving_units = food.serving_options || [];
    for (let i in serving_units) {
      arr.push({
        label: (serving_units[i].label || "").toLowerCase(),
        value: serving_units[i].id,
        selected: item.serving_unit === serving_units[i].id,
      });
    }
    //-------------dumbunits
    if (data.serving_type) {
      const filteredDumbUnits = dumbUnits
        .filter((i) => mapDumbUnitToServingType[i.id] === data.serving_type)
        .map((i) => ({
          label: i.value,
          value: i.id,
          selected: item.serving_unit === i.id,
        }));
      arr.push(...(filteredDumbUnits || []));
    }
    //--------------------no-unit
    arr.push({
      label: "no unit",
      value: "",
      selected: item.serving_unit === "",
    });
    arr.push({ label: "manage units", value: "others" });
    return arr;
  }, [_sortServing, data, item, food]);
  return (
    <div
      className={clsx("d-flex fmb-20 justify-contents-center align-items-center")}
      ref={provided.innerRef}
      {...provided.draggableProps}
      key={`__dragId${index}`}
    >
      <FormTextField fullWidth classes={{control:"mb-0 flex-row"}}>
        <div style={{flex:3}} className="mr-20">
          <TextField
           value={item.name}
           InputProps={{
            classes: {
              root: 'medium',
              input: 'size_16_500 medium text-truncate',
            },
          }}
          inputProps={{
            disabled:true
          }}
          fullWidth
          type="text"
          variant="outlined"
          readOnly={true}
          />
        </div>
        <div style={{flex:1}}>
          <InputDropdown 
            name="serving_size"
            options={servingOptions} 
            data={data} 
            item={item}
            unit={item.serving_unit}
            value={item.serving_size}
            handleOptionChange={(e,v,uiServingSize)=>handleServingChange(index,standardUnit,v,uiServingSize)}
            onValueChange={e=>onServingSizeChange(index,e,standardUnit)}
            disabled={loading}
            />
        </div>
      </FormTextField>
      <div {...provided.dragHandleProps}>
        <DragIndicatorIcon className={"m-10"} />
      </div>
      <IconButton onClick={() => onRemove(index)}>
        <DeleteIcon style={{ fill: 'none' }} className={classes.icons} />
      </IconButton>
    </div>
  );
};

export default IngredientInput;
