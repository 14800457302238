import firebase from "fitbud/firebase";

const builder = (collection) => ({
  doc: async (id, nqSnack = null) => {
    try {
      const docRef = firebase
        .firestore()
        .collection(collection)
        .doc(id);
      return await docRef.get();
    } catch (err) {
      if (!!nqSnack) {
        nqSnack(err.message, { variant: "error" });
        return null;
      }
      throw err;
    }
  },
  fetch: async (nqSnack = null) => {
    try {
      const snapshot = await firebase
        .firestore()
        .collection(collection)
        .get();
      return snapshot.docs;
    } catch (err) {
      if (!!nqSnack) {
        nqSnack(err.message, { variant: "error" });
        return [];
      }
      throw err;
    }
  },
  create: async (data, nqSnack = null) => {
    try {
      const docRef = await firebase
        .firestore()
        .collection(collection)
        .add(data);
      return await docRef.get();
    } catch (err) {
      if (!!nqSnack) {
        nqSnack(err.message, { variant: "error" });
        return null;
      }
      throw err;
    }
  },
  update: async (id, data, nqSnack = null) => {
    try {
      const docRef = firebase
        .firestore()
        .collection(collection)
        .doc(id);
      await docRef.update(data);
      return await docRef.get();
    } catch (err) {
      if (!!nqSnack) {
        nqSnack(err.message, { variant: "error" });
        return null;
      }
      throw err;
    }
  },
  set: async (id, data, nqSnack = null) => {
    try {
      const docRef = firebase
        .firestore()
        .collection(collection)
        .doc(id);
      await docRef.set(data);
      return await docRef.get();
    } catch (err) {
      if (!!nqSnack) {
        nqSnack(err.message, { variant: "error" });
        return null;
      }
      throw err;
    }
  }
});

export default builder;
