import React from 'react';
import { useSnackbar } from 'notistack';
import { IconButton, Tooltip } from "@material-ui/core";
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import { DEFAULT_ERROR } from 'fitbud/utils/constants';
import { useToggle } from 'fitbud/hooks/form';
import { bffExportClients } from 'fitbud/api'; 
import Confirmation from 'fitbud/components/confirmationDialog';

const MSG = 'You will receive an email on your registered email id with the clients list in a CSV format. This process may take some time, depending on the number of clients.';

const ExportClientsDialog = ({cid, storeKey}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [confirm, toggleConfirm] = useToggle();
  const [loading, toggleLoading] = useToggle();
  const proceed = () => {
    toggleLoading();
    bffExportClients(cid, storeKey).then(() => {
      toggleLoading();
      toggleConfirm();
      enqueueSnackbar('Export request raised', {variant: 'success'});
    }).catch(err => {
      const {code, message} = (err && err.response && err.response.data) || {}
      toggleLoading();
      enqueueSnackbar(message || DEFAULT_ERROR, {variant: 'error'});
      if (code === 'QUOTA') toggleConfirm();
    });
  };

  if (!cid || !storeKey) return null;

  return (
    <>
    <Tooltip title='Export'>
      <IconButton onClick={toggleConfirm}>
        <DownloadIcon/>
      </IconButton>
    </Tooltip>
    {confirm && <Confirmation open
      handleClose={toggleConfirm}
      handleCancel={toggleConfirm}
      handleChange={proceed}
      title='Export Clients List'
      confirmOption='Proceed'
      loading={loading} msg={MSG}/>}
    </>
  );
};

export default ExportClientsDialog;
