import { combineReducers } from "redux";
import app from "./app/reducer";
import home from "./home/reducer";
import exercises from "./exercises/reducer";
import workouts from "./workouts/reducer";
import wocardio from "./wocardio/reducer";
import foodRecipes from "./foodRecipes/reducer";
import foodRecipesSupps from "./foodRecipesSupps/reducer";
import supps from "./supps/reducer";
import meals from "./meals/reducer";
import foodMeals from "./foodMeals/reducer";
import foods from "./food/reducer";
import recipes from "./recipes/reducer";
import chatList from "./chatList/reducer";
import broadcastList from "./broadcastList/reducer";
import groupChatList from "./groupChatList/reducer";
import browseUsersList from "./browseUsersList/reducer";
import checkinsList from "./checkinsList/reducer";
import activeUsersList from "./activeUsersList/reducer";
import inactiveUsersList from "./inactiveUsersList/reducer";
import plansToAssignList from "./plansToAssignList/reducer";
import pendingSignInList from "./pendingSignInUsersList/reducer";
import leadsList from "./leadsList/reducer";
import onboardedUsersList from "./onboardedUsersList/reducer";
import notifications from "./notifications/reducer";
import cardio from "./cardio/reducer";
import companies from "./companies/reducer";
import supplements from "./supplements/reducer";
import staff from "./staff/reducer";
import tags from "./tags/reducer";
import questionnaires from "./questionnaires/reducer";
import config from "./config/reducer";
import schedules from "./schedules/reducer";
import selectedSchedule from "./selectedSchedule/reducer";
import plans from "./plans/reducer";
import fileUpload from "./fileUpload/reducer";
import mlSchedules from "./mlSchedules/reducer";
import trainer from "./trainer/reducer";
import groupClasses from "./groupClasses/reducer";
import challenges from "./challenges/reducer";
import services from "./services/reducer";

const appState = combineReducers({
  app,
  home,
  exercises,
  workouts,
  foodRecipes,
  foods,
  recipes,
  foodRecipesSupps,
  supps,
  meals,
  foodMeals,
  chatList,
  broadcastList,
  groupChatList,
  checkinsList,
  browseUsersList,
  activeUsersList,
  inactiveUsersList,
  pendingSignInList,
  plansToAssignList,
  leadsList,
  onboardedUsersList,
  notifications,
  cardio,
  companies,
  tags,
  config,
  supplements,
  wocardio,
  schedules,
  staff,
  questionnaires,
  selectedSchedule,
  plans,
  fileUpload,
  mlSchedules,
  trainer,
  groupClasses,
  challenges,
  services
});

export default appState;
