import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const EmailIcon = props => (
  <SvgIcon
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M15.8334 3.33333H4.16669C3.50365 3.33333 2.86776 3.59672 2.39892 4.06556C1.93008 4.5344 1.66669 5.17029 1.66669 5.83333V14.1667C1.66669 14.8297 1.93008 15.4656 2.39892 15.9344C2.86776 16.4033 3.50365 16.6667 4.16669 16.6667H15.8334C16.4964 16.6667 17.1323 16.4033 17.6011 15.9344C18.07 15.4656 18.3334 14.8297 18.3334 14.1667V5.83333C18.3334 5.17029 18.07 4.5344 17.6011 4.06556C17.1323 3.59672 16.4964 3.33333 15.8334 3.33333V3.33333ZM15.4917 4.99999L10.5917 9.89999C10.5142 9.9781 10.4221 10.0401 10.3205 10.0824C10.219 10.1247 10.11 10.1465 10 10.1465C9.89001 10.1465 9.78109 10.1247 9.67954 10.0824C9.57799 10.0401 9.48582 9.9781 9.40835 9.89999L4.50835 4.99999H15.4917ZM16.6667 14.1667C16.6667 14.3877 16.5789 14.5996 16.4226 14.7559C16.2663 14.9122 16.0544 15 15.8334 15H4.16669C3.94567 15 3.73371 14.9122 3.57743 14.7559C3.42115 14.5996 3.33335 14.3877 3.33335 14.1667V6.17499L8.23335 11.075C8.70211 11.5432 9.33752 11.8061 10 11.8061C10.6625 11.8061 11.2979 11.5432 11.7667 11.075L16.6667 6.17499V14.1667Z" fill="#0D0D0D" />
  </SvgIcon>
);
