import React from 'react';
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import useVideoContext from './useVideoContext';
import CallEndIcon from '@material-ui/icons/CallEnd';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    color: '#D9322E',
    border: "1px solid #E0E0E0",
    width: "50px",
    height: "50px"
  }
}));

const EndCallButton = (props) => {
  const classes = useStyles();
  const { handleDisconnect } = useVideoContext();
  const leaveCall = (e) => {
    handleDisconnect(e)
  }
  return (
    <Tooltip
      title={'Leave call'}
      placement="top"
      PopperProps={{ disablePortal: true }}
    >
      <IconButton
        className={`${classes.buttonContainer} fmr-20`}
        onClick={leaveCall}
      >
        <CallEndIcon />
      </IconButton>
    </Tooltip>
  );
}

export default EndCallButton;
