import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const CrossIcon = props => (
  <SvgIcon
    {...props}
    viewBox="0 0 22 22"
    classes={{
      root: "small"
    }}
  >
    <g
      id="Nutrition"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Nutrition-2" transform="translate(-961.000000, -339.000000)">
        <g id="cross" transform="translate(961.000000, 339.000000)">
          <circle id="Oval" fill="#FFFFFF" cx="11" cy="11" r="11"></circle>
          <g
            id="Close"
            transform="translate(7.000000, 7.000000)"
            stroke="#1B1B1B"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.33333333"
          >
            <path
              d="M0.100587755,7.94785306 L7.93732245,0.111118367"
              id="Stroke-1081"
            ></path>
            <path
              d="M7.93732245,7.94785306 L0.100587755,0.111118367"
              id="Stroke-1082"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);
