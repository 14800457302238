import {useState, useMemo, useContext} from 'react';
import {Typography, Button, Dialog as MUIDailog, Drawer, makeStyles,TextField,IconButton} from '@material-ui/core';
import CardioDetails from "fitbud/views/cardio/info";
import WoDetails from "fitbud/views/workouts/form";
import React from 'react';
import MealInfo from '../meals/mealInfo';
import BMRMealInfo from '../users/bmrMealInfo';
import {DialogAppBar} from "fitbud/components/Dialog";
import Dialog from "fitbud/components/Dialog";
import { ClrdButton, FormTextField } from "fitbud/components/form-fields";
import appRdxFns from 'fitbud/redux/app';
import { useDispatch } from 'react-redux';
import woRepo from "fitbud/repo/workouts";
import { FirebaseAuthContext} from "fitbud/providers/firebase-auth";
import { DEFAULT_ERROR } from 'fitbud/utils/constants';
import { useSnackbar } from "notistack";
import EditIcon from "fitbud/icons/detailEdit.js";
import mealRepo from "fitbud/repo/meals";
import { useToggle } from "fitbud/hooks/form";

const styles=makeStyles((theme) => ({
  root:{
    width: '100vw',
    [theme.breakpoints.up('sm')]: { 
      width : ({ increaseWidth }) => (increaseWidth ? 600 : 490),
    },
    [theme.breakpoints.up('md')]: { 
      width : ({ increaseWidth }) => (increaseWidth ? 720 : 490),
    },
  }
}));

const CardDetailDrawer = ({ schName, data, onSave,onClose, ...restProps }) => {
  const d = useDispatch();
  const type = data ? data.type || data.dtype : null;
  const { enqueueSnackbar } = useSnackbar();
  const {cid}=useContext(FirebaseAuthContext);
  const { showLoader, hideLoader } = appRdxFns(d);
  const [copyScheme,setCopyScheme]=useState("");
  const [openDialog,toggleDialog]=useState(false);
  const [tempData,setTempData]=useState({});
  const [loading,toggleLoading]=useState(false);
  const classes = styles({ increaseWidth: copyScheme === 'update' });
  const title = useMemo(() => {
    let out = '';
    if (!type) return out;
    if (type === 'workout' || type === 'cardio') out = 'Workout';
    else out = 'Meal';
    return out;
  }, [type]);

  const handleChange=(v)=>{
    setCopyScheme(v);
  }
  const handleCloseDrawer=()=>{
    setCopyScheme("");
    onClose();
  }
  const onDocUpdate=(obj=null)=>{
    if(!obj){
      enqueueSnackbar(DEFAULT_ERROR,{variant:"error"});
      setCopyScheme("");
      return;
    }
    setTempData({...obj});
    if (copyScheme==="copy") {
      const { dayIndex, weekIndex } = data;
      if (data.ref_name && schName) {
        let newName = data.ref_name + ' - ' + schName;
        if (dayIndex && weekIndex) newName += ` - W${weekIndex}D${dayIndex}`;
        toggleDialog(newName);
      } else if (data.ref_name)
        toggleDialog(`Copy of ${data.ref_name}`);
      else
        toggleDialog(true);
    } else onSave(obj);
  }
  const dialogRejectCb=()=>{
    toggleDialog(false);
    setCopyScheme("");
    setTempData({});
  }
  const dialogSuccessCb = async (ref_name) => {
    showLoader();
    toggleLoading(true);
    const newData = {
      ...tempData,
      ref_name,
      archive: false,
      srcId: data.srcId || data.id || data._id,
    };
    if (!type) return; //NOTE: show default error on UI
    try {
      //here creation of new doc is in PUBLISHED mode, hardcoding draftFeature as false(check companyBase for ref)
      switch (type) {
        case "workout": {
          const r = await woRepo(cid,false).create(newData);
          onSave(r.data(),r.id);
          toggleDialog(false);
          setTempData({});
          setCopyScheme("update");
          break;
        }
        case "cardio":{
          const r = await woRepo(cid,false).create(newData);
          onSave(r.data(),r.id);
          toggleDialog(false);
          setTempData({});
          setCopyScheme("update");
          break;
        }
        case "macros":
        case "food":{
          const r = await mealRepo(cid,false).create(newData);
          onSave(r.data(),r.id);
          toggleDialog(false);
          setTempData({});
          setCopyScheme("update");
          break;
        }
        default: {
          return;
        }
      }
    } catch (err) {
      enqueueSnackbar(DEFAULT_ERROR, {
        variant: "error",
      });
    }
    finally {
      hideLoader();
      toggleLoading(false);
    }
  };

  return (
    <Drawer classes={{ paper: classes.root }} onClose={handleCloseDrawer} {...restProps}>
      <DialogAppBar
        toolbarClass="height-60 text-capitalize"
        onClose={handleCloseDrawer}
        titleFont="h3"
        title={title}
        headerActions={<Action title={title} editable={data.displayEditOption} value={copyScheme} handleChange={handleChange} />}/> 
      {data && (data.type === "workout" || data.dtype === "workout") && (
        <>
          {!loading && <WoDetails
            id={data.id || data._id}
            scheduleSaveWoEdit={onDocUpdate}
            minified={true}
            isPreview={copyScheme !== 'update'}
            showOnlyEdit={data.displayEditOption && copyScheme}
            draftFeature={false}
            // disableExClick={data.displayEditOption}
          />}
        </>
      )}
      {data && (data.type === "cardio" || data.dtype === "cardio") && (
        <>
        {!loading && <CardioDetails
          id={data.id || data._id}
          scheduleSaveCardioEdit={onDocUpdate}
          minified={true}
          // isPreview={!data.displayEditOption}
          // isPreview={true}
          isPreview={copyScheme !== 'update'}
          showOnlyEdit={data.displayEditOption && copyScheme}
          draftFeature={false}
        />}
        </>
      )}
      {data && data.isBMR && <BMRMealInfo data={data} />}
      {data && ((data.dtype === "macros") || (data.dtype === "food")||(data.type==="food")||(data.type==="macros")) && (
        <>
       {!loading && <MealInfo
          id={data.id || data._id}
          scheduleSaveMealEdit={onDocUpdate}
          minified={true}
          isPreview={copyScheme !== 'update'}
          showOnlyEdit={data.displayEditOption && copyScheme}
          draftFeature={false}
        />}
        </>
      )}
      {openDialog && <CopyDocDialog prefill={openDialog} onSave={dialogSuccessCb} onCancel={dialogRejectCb}/>}
    </Drawer>
  );
}
const Action=({title, value, editable, handleChange})=>{
  const [ask, toggleAsk, setAsk] = useToggle();
  const exit = () => {handleChange(''); setAsk(false)};
  const editOriginal = () => {handleChange('update'); setAsk(false)};
  const copyAndEdit = () => {handleChange('copy'); setAsk(false)};
  if (!editable) return null;
  if (value) {
    if (value === 'update') return (<ClrdButton color="primary" onClick={exit}>Done</ClrdButton>);
    return null;
  }
  if (!ask) return (<IconButton onClick={toggleAsk}><EditIcon/></IconButton>);
  return (
    <MUIDailog open onClose={toggleAsk} classes={{paper: 'p-20'}}>
      <Typography className="font_18_600 mb-10">
        Please Confirm
      </Typography>
      <Typography className="font_15_500 text-65768C">
        Editing this {title.toLowerCase()} will also update it for:<ul className='pl-4'>
          <li>Customers who have been assigned this {title.toLowerCase()}</li>
          <li>{title} Schedules where this {title.toLowerCase()} is used</li>
        </ul>
        If you want to continue editing this workout, click '<b>Proceed</b>'.<br/><br/>
        Or '<b>Copy and Edit</b>' to create a copy of this {title.toLowerCase()} and edit that instead.
        The copy will be added to your catalog and be available to be used in other {title} Schedules.
      </Typography>
      <div className="mt-20 p-0 d-flex flex-row">
        <Button onClick={toggleAsk} color='primary'>Cancel</Button>
        <div className='flex-grow-1'/>
        <Button variant='outlined' color='primary' disableElevation
          className='py-10'
          onClick={copyAndEdit}>Copy and Edit</Button>
        <Button variant='contained' color='primary' disableElevation
          className='ml-20 py-10'
          onClick={editOriginal}>Proceed</Button>
      </div>
    </MUIDailog>
  );
}
const CopyDocDialog=({prefill, onSave, onCancel})=>{
  const [value,handleChange]=useState(() => {
    if (typeof prefill === 'string') return prefill;
    return '';
  });
  return (
    <Dialog 
      open 
      title="Create a Copy" 
      toolbarClass="height-60"
      onClose={onCancel} 
      onSave={()=>onSave(value.trim())}
      dialogContentClassName="p-20"
      paperClass="width-400"
      disablePrimaryAction={!value.trim()}
      actionText="Save"
      >
      <FormTextField fullWidth label="Enter Reference Name for the Copy" required>
        <TextField 
          type="text" 
          variant="outlined"
          InputProps={{
            classes: {
              root: "medium",
              input: "size_16_500 medium",
            },
          }}
          onChange={e=>handleChange(e.target.value)}
          value={value}
          />
      </FormTextField>
    </Dialog>
  )
}
export default CardDetailDrawer;
