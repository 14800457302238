import React, { Component } from "react";
import firebase from "fitbud/firebase";
import { withRouter } from "react-router";
import { CircularProgress } from "@material-ui/core";
import Carousel, { Modal, ModalGateway } from "react-images";
import {
  removeLocationHash,
  getLocationHash,
  doesHashStartsWith
} from "fitbud/utils/RouterHash";
import { getThumborUrlByAspect } from "fitbud/utils/thumborHelper";
import withWidth from "@material-ui/core/withWidth/index";

const storageRef = firebase.storage().ref();

const navButtonStyles = base => ({
  ...base,
  backgroundColor: "white",
  boxShadow: "0 1px 6px rgba(0, 0, 0, 0.18)",
  color: "black",

  "&:hover, &:active": {
    backgroundColor: "white",
    color: "black",
    opacity: 1
  },
  "&:active": {
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.14)",
    transform: "scale(0.96)"
  }
});
const DEFAULT_STATE = { currentModal: null, media: [], aspect: null };
class ImageViewer extends Component {
  state = { ...DEFAULT_STATE };
  toggleModal = (index = null) => {
    removeLocationHash(this.props);
    this.setState({ currentModal: index, media: [], aspect: null });
  };

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.hash === this.props.location.hash) return;
    this.fetch();
  }
  fetch = async () => {
    const hash = getLocationHash(this.props);
    if (!hash) return;
    // eslint-disable-next-line no-unused-vars
    const [type, storageFolderRef, bucketName, identifier, position] = hash.split("&");
    const images = [];
    if(!identifier) return;
    for (const id of identifier.split("%")) {
      let downloadUrl;
      const thumborUrl = getThumborUrlByAspect(
        id,
        window.innerWidth,
        Math.round(window.innerWidth / window.innerHeight),
        bucketName
      );
      if (!thumborUrl) continue;
      try {
        downloadUrl = await storageRef
          .child(`${storageFolderRef}/${thumborUrl}`)
          .getDownloadURL();
        images.push({ src: downloadUrl });
      } catch (err) {
        console.log(err);
        images.push({ src: "" });
      }
    }
    try {
      // let aspect;
      // const img = new Image();
      // const that=this;
      // img.onload = function() {
      //   aspect = this.width / this.height;
      //   that.setState({aspect:aspect})
      // };
      // img.src = downloadUrl;
      this.setState({
        currentModal: Number(position),
        media: [...images]
      });
    } catch (err) {
      console.log(err);
    }
  };
  render() {
    const { media, currentModal } = this.state;
    // const view_width = document.getElementsByClassName('fullscreen')[0] ? document.getElementsByClassName('fullscreen')[0].getBoundingClientRect().width : 0 ;
    // const view_height = document.getElementsByClassName('fullscreen')[0] ? document.getElementsByClassName('fullscreen')[0].getBoundingClientRect().bottom : 0 ;
    // let target_Width =0;
    // let  target_height =0;
    // if(aspect)
    // {if(aspect>=1)
    // {
    //   target_Width=view_width;
    //   target_height=view_width/aspect;
    // }else {
    //   target_height=view_height;
    //   target_Width=aspect*target_height;
    // }}

    if (!doesHashStartsWith(this.props, "image")) return null;
    return (
      <ModalGateway>
        {doesHashStartsWith(this.props, "image") ? (
          <Modal
            allowFullscreen
            closeOnBackdropClick={false}
            onClose={this.toggleModal}
            isModal={!!media.length}
            // children = {!media.length && <CircularProgress/>}
            styles={{
              blanket: base => ({
                ...base,
                zIndex: "2000 !important"
              }),
              positioner: base => ({
                ...base,
                zIndex: "2000 !important"
              }),
              dialog: base => ({
                ...base,
                width: "100vw",
                height: "100vh",
                textAlign: "center"
              })
            }}
          >
            {!!media.length   ? (
              <Carousel
                currentIndex={currentModal}
                components={{ Footer: null }}
                frameProps={{ autoSize: "height" }}
                views={media}
                styles={{
                  container: base => ({
                    ...base,
                    height: '100vh',
                  }),
                  view: base => ({
                    ...base,
                    alignItems: "center",
                    display: "flex ",
                    height: "100vh",
                    justifyContent: "center",

                    "& > img": {
                      maxHeight: "calc(100vh - 94px)",
                    }
                  }),
                  navigationPrev: navButtonStyles,
                  navigationNext: navButtonStyles
                }}
              />
            ) : (
              <CircularProgress style={{
                position: "relative",
                top: "50%"
                , color: "#fff"}} />
            )}
          </Modal>
        ) : null}
      </ModalGateway>
    );
  }
}

export default withWidth()(withRouter(ImageViewer));
