import React from 'react';
import { Typography, Button } from '@material-ui/core';
import { MenuList } from 'fitbud/components/menuList';
import { ResourcePlaceholder } from 'fitbud/icons/resourceIcons';

export const AddPlaceholder = (props) => {
  const { title, handleAdd } = props;
  return (
    <div className="h-100 text-center d-flex align-items-center justify-content-center flex-column">
      <div style={{width:300}}>
      <ResourcePlaceholder />
      <Typography className="mt-30 mb-15 font_16_600">{`Nothing Added to ${title}`}</Typography>
      <Typography className="mb-30 font_15_500 text-grey">
        {' '}
        Add Resources (Links, Docs or Videos) here and arrange them into collections for your clients to browse.
      </Typography>
      <Button className={'f-large font_15_700'} variant="contained" color={'primary'} type="submit" onClick={handleAdd}>
        Start Adding
      </Button>
      </div>
    </div>
  );
};

export const AddMenus = (props) => {
  const { anchorEl, onClose, handleOption, src } = props;
  let _anchorPosition = {
    vertical: 'center',
    horizontal: 'center',
  };
  if (src === 'card') {
    _anchorPosition = { vertical: 'center', horizontal: 'right' };
  } else {
    _anchorPosition = { vertical: 'bottom', horizontal: 'right' };
  }

  return (
    <MenuList
      anchorEl={anchorEl}
      onClose={onClose}
      handleClick={handleOption}
      options={[
        { label: 'Video', value: 'video' },
        { label: 'Link', value: 'link' },
        { label: 'Document', value: 'document' },
      ]}
      getContentAnchorEl={null}
      anchorOrigin={_anchorPosition}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    />
  );
};
