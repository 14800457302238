import React from "react";
import Img from "react-image";
import PlACEHOLDER from "fitbud/images/placeholderImg.svg";

const DefaultPlaceHolder = ({ className }) => {
  return <img src={PlACEHOLDER} className={className} />;
};

const PlaceHolderImage = (props) => {
  let {
    src,
    imgClassName,
    imgLoader,
    fallbackImgContent,
    imgLoaderClassName,
    fallbackClassName,
    ...rest
  } = props;
  if (!imgLoader)
    imgLoader = (
      <DefaultPlaceHolder className={imgLoaderClassName || imgClassName} />
    );
  if (!fallbackImgContent)
    fallbackImgContent = (
      <DefaultPlaceHolder className={fallbackClassName || imgClassName} />
    );
  return (
    <Img
      src={src}
      className={imgClassName}
      loader={imgLoader}
      unloader={fallbackImgContent}
      {...rest}
    />
  );
};

export default PlaceHolderImage;
