import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const CompanyIcon = props => (
    <SvgIcon
        {...props}
        viewBox="0 0 20 20"
        classes={{
          root: "small"
        }}
    >
      <g id="company" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="building" transform="translate(1.000000, 3.000000)" fill="#8F969F" fillRule="nonzero">
          <path d="M4.86230103,14.9760305 L8.04009897,14.9760305 L8.04009897,11.6341603 L9.95021443,11.6341603 L9.95021443,14.9760305 L13.1280124,14.9760305 L13.1280124,0.0139694656 L4.86230103,0.0139694656 L4.86230103,14.9760305 Z M9.57499794,1.77725191 L11.4850763,1.77725191 L11.4850763,3.74164122 L9.57499794,3.74164122 L9.57499794,1.77725191 Z M9.57499794,4.93431298 L11.4850763,4.93431298 L11.4850763,6.89870229 L9.57499794,6.89870229 L9.57499794,4.93431298 Z M9.57499794,8.09129771 L11.4850763,8.09129771 L11.4850763,10.055687 L9.57499794,10.055687 L9.57499794,8.09129771 Z M6.5052,1.77725191 L8.41527835,1.77725191 L8.41527835,3.74164122 L6.5052,3.74164122 L6.5052,1.77725191 Z M6.5052,4.93431298 L8.41527835,4.93431298 L8.41527835,6.89870229 L6.5052,6.89870229 L6.5052,4.93431298 Z M6.5052,8.09129771 L8.41527835,8.09129771 L8.41527835,10.055687 L6.5052,10.055687 L6.5052,8.09129771 Z" id="Shape"></path>
          <path d="M13.8699835,1.35427481 L13.8699835,11.9335878 L15.0971381,11.9335878 L15.0971381,14.9760305 L17.9902763,14.9760305 L17.9902763,1.35427481 L13.8699835,1.35427481 Z M16.4945691,10.4965267 L14.7555464,10.4965267 L14.7555464,8.70812977 L16.494532,8.70812977 L16.494532,10.4965267 L16.4945691,10.4965267 Z M16.4945691,7.62225191 L14.7555464,7.62225191 L14.7555464,5.83389313 L16.494532,5.83389313 L16.494532,7.62225191 L16.4945691,7.62225191 Z M16.4945691,4.74801527 L14.7555464,4.74801527 L14.7555464,2.95965649 L16.494532,2.95965649 L16.494532,4.74801527 L16.4945691,4.74801527 Z" id="Shape"></path>
          <path d="M0,14.9760305 L2.89313814,14.9760305 L2.89313814,11.9335878 L4.12029278,11.9335878 L4.12029278,1.35427481 L0,1.35427481 L0,14.9760305 Z M1.49574433,2.95965649 L3.2347299,2.95965649 L3.2347299,4.74801527 L1.49574433,4.74801527 L1.49574433,2.95965649 Z M1.49574433,5.83389313 L3.2347299,5.83389313 L3.2347299,7.62225191 L1.49574433,7.62225191 L1.49574433,5.83389313 Z M1.49574433,8.70805344 L3.2347299,8.70805344 L3.2347299,10.4964122 L1.49574433,10.4964122 L1.49574433,8.70805344 Z" id="Shape"></path>
        </g>
      </g>
    </SvgIcon>
);
