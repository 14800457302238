import React, { useEffect, useContext, useState, useMemo } from "react";
import CatalogMultiSelector from "fitbud/components/catalogSelector/catalogMultiSelector";
import { SelectorContext } from "fitbud/components/catalogSelector/selectorContext";
import {
  Typography,
  IconButton,
  Divider,
  makeStyles
} from "@material-ui/core";
import {Avatar} from 'fitbud/components/avatar';
import { DumbList } from "fitbud/components/catalog";
import CloseIcon from "@material-ui/icons/ClearRounded";
import get from "lodash/get";
import { UserSchContext } from "fitbud/views/schedulev2/provider";
import clsx from 'clsx';

const styles=makeStyles({
  box:{
    border:"2px solid transparent",
  },
  highlighted:{
    background: "#E8F4FD", borderRadius: 10,border:"2px solid #317FF5"
  }
})
const UserSchdMealSelector = (props) => {
  const { opimport } = useContext(UserSchContext);
  const { purpose } = opimport;
  const {
    open,
    onClose,
    handleAdd,
    keyName = "meals",
    showDraftInd = false,
  } = props;
  const _handleAdd =async (v,p) => {
    handleAdd(v,{
      ...opimport,
      purpose:p||purpose
    });
  };
  if(purpose==="ml")
  return (
    <CatalogMultiSelector
      title={"Select Meal"}
      handleAdd={_handleAdd}
      item="meal"
      open={open}
      keyName={keyName}
      handleClose={onClose}
      showDraftInd={showDraftInd}
      addLimit={1}
      selectedComponent={SingleSelectMeal}
    />
  );
  return(
    <AllDayMeal _handleAdd={_handleAdd} {...props}/>
  )
};
const SingleSelectMeal = ({ preSelected }) => {
  const { selectedValues = [],addAllSelectedValues } = useContext(SelectorContext);
  useEffect(()=>{
    if(preSelected && !!preSelected.length){
      addAllSelectedValues(preSelected.map(i=>({data:{...i},_id:i.id})));
    }
  },[preSelected]);
  if (!selectedValues.length) return null;
  return (
    <div className="h-100 w-100">
      <Typography className="font_15_500 px-20 pt-20">Selected</Typography>
      <DumbListWrapper docs={selectedValues} />
    </div>
  );
};

const AllDayMeal = ({
    open,
    onClose,
    _handleAdd,
    keyName = "meals",
    showDraftInd = false,
    selectedMls=[],
}) => {
  const classes=styles();
  const [focus,setFocus]=useState('on');
  const [selectedIds,setSelectedIds]=useState([...selectedMls]);
  const handleFocus=(v)=>{
    setFocus(v);
  }
  
  const handleRemoveSelected=(index)=>{
    const newIds=[...selectedIds];
    newIds[index]=null;
    setSelectedIds(newIds);
  }
  const handleSelect=(doc)=>{
    const index=focus==="on"?0:1;
    // if(!selectedIds[index]){
      const newIds=[...selectedIds];
      newIds[index]={...doc};
      setSelectedIds(newIds);
    // }
  }
  const handleSave=()=>{
    const validIds=selectedIds.filter(i=>!!i);
    _handleAdd([...validIds],validIds.length>1?"mlms-on_off":"mlms-single");
  }
  const [onDay, offDay] = selectedIds;
  const onDaySelected=focus==="on";
  return (
    <CatalogMultiSelector
      title={"Select Meal"}
      handleAdd={handleSave}
      item="meal"
      open={true}
      keyName={keyName}
      handleClose={onClose}
      showDraftInd={showDraftInd}
      handleSelectCb={handleSelect}
      allowDuplicate
      fDisblHeaderSaveBtn={!selectedIds[0]}
      selectedComponent={()=>{
        return(
        <div className="w-100">
          <div className={clsx("pt-20",!onDay && 'cursor-pointer')} onClick={()=>handleFocus('on')}>
            <Typography className="font_15_700 px-20">
              Daily Meal
            </Typography>
            <div className={clsx("p-10 m-10 d-flex justify-content-center align-items-center h-60",
              onDaySelected ? classes.highlighted: classes.box)}
            >
              {onDay ? (<SelectedListItem {...onDay} removeItem={()=>handleRemoveSelected(0)}/>) : (<Typography className="text-65768C">
                  {onDaySelected? "Select daily meal":"Click here to select daily meal"}
              </Typography>)}
            </div>
          </div>
          <Divider />
          {/*------- Rest DAY ---------*/}
          <div className={clsx("pt-20",!offDay && 'cursor-pointer')} onClick={()=>handleFocus('off')}>
            <Typography className="font_15_700 px-20">
              Rest Day Meal <span className="font-weight-500">(Optional)</span>
            </Typography>
            <div
              className={clsx("p-10 m-10 d-flex justify-content-center align-items-center h-60",
              !onDaySelected ? classes.highlighted:classes.box)}
            >
              {offDay ? (<SelectedListItem {...offDay}  removeItem={()=>handleRemoveSelected(1)}/>) : (<Typography className="text-65768C">
                  {!onDaySelected? "Select Rest day meal":"Click here to select rest day meal"}
              </Typography>)}
            </div>
          </div>
          <Divider />
        </div>
        )
      }}
    />
    
  );
};

const DumbListWrapper = ({ docs = [],indexStartsFrom=0 }) => {
  const { removeSelectedValue } = useContext(SelectorContext);
  return (
    <DumbList
      docs={[...docs]}
      ListItemSecondaryAction={({ index:i, doc }) => {
        const index=i+indexStartsFrom;
        const disabled = get(doc, "disabled", false);
        return (
          <IconButton
            disabled={disabled}
            onClick={(e) => {
              e.stopPropagation();
              removeSelectedValue(index);
            }}
          >
            <CloseIcon/>
          </IconButton>
        );
      }}
    />
  );
};
export const SelectedListItem=({data,removeItem})=>{
  const {thumbnail,title}=data||{};
  return(
    <div className="d-flex align-items-center w-100">
      <Avatar src={thumbnail} size="vsmall" className="mr-10" name={title} styles={{fontSize:'13px'}}/>
      <Typography className="font_13_600 text-black twoLineEllipsis">{title}</Typography>
      <IconButton  onClick={removeItem} className="mr-0 ml-auto">
        <CloseIcon/>
      </IconButton>
    </div>
  )
}
export default UserSchdMealSelector;
