import React,{useState,useEffect} from 'react';
import {Tabs as MuiTabs,Tab} from '@material-ui/core';
import {merge} from 'lodash';

const slideBtnsConfig=({
  _tabsProps:{
    style:{
      height:40,
      padding:2,
      minHeight:"unset",
      background:"#EFEFF0",
      borderRadius:8
    },
    className:"fmb-20",
    classes:{
      flexContainer:"h-100",
    }
  },
  _tabProps:{
    disableRipple:true,
    style:{
      minWidth:"unset",
      maxWidth:"unset",
      minHeight:"unset",
      flex:1,
      zIndex:5
    },
    className:"font_13_500 text-capitalize text-black",
    classes:{
      selected:"font-weight-600"
    }
  },
  _indicatorProps:{
    style:{
      background:"#fff",
      borderRadius:7,
      height:'100%',
      top:0,
    }
  }
})

export const Tabs = ({ options = [], value, handleChange,componentType=null,useMountHack=false, ...rest }) => {
  const [mounted,setMounted]=useState(false);
  useEffect(()=>{
    setTimeout(()=>{
        setMounted(true)
    },200)
  },[])
  let {tabsProps={},tabProps={},indicatorProps={},...props } = rest;
  if(componentType==="slideBtns"){
    const {_tabsProps,_tabProps,_indicatorProps}=slideBtnsConfig;
    tabsProps={...(merge(
      tabsProps,_tabsProps,useMountHack?{
          style:{visibility:!mounted?"collapse":"visible"}
        }:{}
      ))};
    tabProps={...(merge(tabProps,_tabProps))};
    indicatorProps={...(merge(indicatorProps,_indicatorProps))};
  }
  const _handleChange = (e, newVal) => {
    handleChange(newVal);
  };
  return (
    <MuiTabs
      value={value}
      onChange={_handleChange}
      TabIndicatorProps={indicatorProps}
      {...tabsProps}
      {...props}
    >
      {options.map((i) => (
        <Tab
          key={i.value}
          value={i.value}
          label={i.label}
          {...tabProps}
        />
      ))}
    </MuiTabs>
  );
};
