import { createStore, compose, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import initialState from "fitbud/redux";

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  initialState,
  compose(
    applyMiddleware(sagaMiddleware),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true}) : (f) => f
  )
);

export { store, sagaMiddleware };
