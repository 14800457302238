import React from "react";
import KeyDown from "@material-ui/icons/KeyboardArrowDown";
import { makeStyles } from "@material-ui/core/styles";
import { Badge, IconButton } from "@material-ui/core";
import clsx from "clsx";
import { KeyboardArrowDownRounded } from "@material-ui/icons";


const useStyles = makeStyles(theme => ({
  div: {
    borderRadius: "50%",
    width: "44px",
    height: "44px",
    right: "10px",
    color: "#4089f7",
    zIndex: "11",
    cursor: "pointer",
    backgroundColor: "#fff",
    transform: "scaleX(1) scaleY(1)",
    border: "1px solid #D3D9DB",
    "&:hover": {
      backgroundColor: "#fff",
    }
  },
  bottom: {
    bottom: "100px",
  },
  icon: {
    width: "40px",
    height: "40px"
  }
}));
const ScrollToBottom = ({ 
  unread = 0, 
  show = false, 
  id="chatArea", 
  onClick, 
  style = {
    bottom: "100px", 
    position: "fixed" 
  }
}) => {
  const classes = useStyles();
  const chatarea = document.getElementById(id);

  const scroll = () => {
    chatarea.scrollTo({ top: (0, chatarea.scrollHeight), behavior: "smooth" });
  };
  return (
    <IconButton
      className={clsx("shadow", classes.div, !!show ? "d-flex" : "d-none")}
      style={style}
      onClick={onClick || scroll}
    >
      <Badge
        badgeContent={unread}
        color="secondary"
        children={""}
        invisible={!unread}
      >
        <KeyboardArrowDownRounded className={classes.icon} />
      </Badge>
    </IconButton>
  );
};
export default ScrollToBottom;
