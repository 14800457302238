import _get from 'lodash/get';
import * as Sentry from '@sentry/browser';
import {useContext, useEffect, useMemo, useState} from 'react';
import moment from 'moment-timezone';
import Visibility from 'visibilityjs';
import { useSnackbar } from 'notistack';
import { connect } from 'react-redux';
import staffRdxFns from 'fitbud/redux/staff';
import appRdxFns from 'fitbud/redux/app';
import firebase from 'fitbud/firebase';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';

const TeeZed = (props) => {
  const { setStaff, setProfile } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { authLoading, userProfile, refreshco, refreshstaff, comp, cid } = useContext(FirebaseAuthContext);
  const [sysTZ, setSysTZ] = useState();
  const staffTZ = useMemo(() => _get(userProfile, 'time_zone'), [userProfile]);
  const compTZ = useMemo(() => comp.exists && _get(comp.data(), 'profile.time_zone'), [comp]);
  const guess = () => setSysTZ(moment.tz.guess(true));
  const { uid } = userProfile;
  const { primary_owner_id } = comp && comp.exists ? comp.data() : {};
  useEffect(() => {
    if (authLoading || !sysTZ || !uid || !primary_owner_id) return;
    const isOwner = uid === primary_owner_id;
    if (sysTZ === staffTZ && (!isOwner || sysTZ === compTZ)) return;
    Promise.all([
      isOwner ? firebase.firestore().doc(`companies/${cid}`).update({'profile.time_zone': sysTZ}) : Promise.resolve(false),
      firebase.firestore().doc(`staff/${uid}`).update({time_zone: sysTZ}),
    ]).then(() => Promise.all([
      isOwner ? refreshco() : Promise.resolve(false),
      refreshstaff(),
    ])).then(([comp, staff]) => {
      if (comp) setProfile(comp);
      setStaff(staff);
      enqueueSnackbar(`Time zone updated to ${sysTZ}. This ensures your video calls and availability are shown correctly to your customers`, {variant: 'info'});
    }).catch(err => {
      console.error(err);
      Sentry.captureException(err);
    });
  }, [enqueueSnackbar, authLoading, compTZ, staffTZ, sysTZ, cid, uid, primary_owner_id, refreshco, refreshstaff, setStaff, setProfile]);
  useEffect(() => {
    // every time user returns to the tab, fetch and check
    const listener = Visibility.change((e, state) => {
      if (state !== 'visible') return;
      guess();
    });
    guess();
    return () => Visibility.unbind(listener);
  }, []);

  return null
}


const mapDispatchToProps = d => {
  const { setStaff } = staffRdxFns(d);
  const { setProfile } = appRdxFns(d);
  return { setStaff, setProfile };
};

export default connect(undefined, mapDispatchToProps)(TeeZed);
