import { Button, IconButton, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import Dialog from 'fitbud/components/Dialog'
import { ClrdButton } from 'fitbud/components/form-fields';
import React, { useContext, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import WorkoutCard from './scheduleWorkoutCard';
import WorkoutSelector from '../schedules/workoutSelector';
import { getWoMlFirebase } from './helper';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { useSnackbar } from 'notistack';
import { DEFAULT_ERROR } from 'fitbud/utils/constants';
import { useDispatch } from 'react-redux';
import appRdxFns from "fitbud/redux/app";
import * as Sentry from '@sentry/browser';
import { DeleteIcon } from 'fitbud/icons/delete';
import {DragIcon} from "fitbud/icons/drag";
import _ from 'lodash';

const styles = makeStyles((theme) => ({
  dragIcon: {
    position:"absolute",
    right:-15,
    backgroundColor:"#fff!important",
    border:"1px solid #D3D9DB"
  },
  paperHeight:{
    minHeight:'280px',
  },
  viewOnlyWidth:{
    width: '350px'
  },
}));

const AlternateWoDialog = ({ ids='', onSave, fetchedItems, viewOnly, dayIds=[],
 updateFetchedItems, toggleDrawer, ...restProps }) => {
  const { cid } = useContext(FirebaseAuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const d = useDispatch();
  const { showLoader, hideLoader } = appRdxFns(d);

  const [items, setItems] = useState(ids.split('/'));
  const [isDirty, setDirty] = useState(false);
  const [selectorState, setSelectorState] = useState(false);
  
  const toggleSelector = (val=true) => {setSelectorState(val)};

  const handleReorder = (data) => {
    const { source, destination } = data;
    if(!source || !destination) return;
    setDirty(true);
    const result = Array.from(items);
    const [removed] = result.splice(source.index, 1);
    result.splice(destination.index, 0, removed);
    setItems(result);
  };

  const handleDelete = (id, index) => {
    setDirty(true);
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
  };

  const handleSave = () => {
    if(items.length > 1) onSave(items.join('/'));
    else onSave(items[0]);
    restProps.onClose();
  };

  const handleItemAdd = (_ids=[]) => {
    setDirty(true);
    if(!_ids.length) return toggleSelector(false);
    _ids = _ids.map((i)=> i._id);
    let toFetch = [..._ids].filter((o) => !fetchedItems[o]);
    if(toFetch.length > 0){
      showLoader();
      getWoMlFirebase(cid, toFetch, { type: 'workout' }).then((items) => {
        toggleSelector(false);
        updateFetchedItems(prev => ({ ...prev, ...items }));
        setItems(prev => [...prev, ..._ids]);
      }).catch((err) => {
        enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
        Sentry.captureException(err);
      }).finally(() => {hideLoader();});
    } else {
      setItems(prev => [...prev, ..._ids]);
      toggleSelector(false);
    };
  };
  const classes = styles();
  if(!items) return null;
  return (
    <>
      <Dialog
        withConfirmation
        isDirty={isDirty}
        title='Workout Alternatives'
        titleFont="h3"
        appBarColor="bg-body"
        buttonColor="primary"
        buttonSize="f-small"
        toolbarClass="height-60"
        onSave={!viewOnly && handleSave}
        {...restProps}
      >
        <div className='fpx-20 fpt-20 fpb-10 d-flex flex-column align-items-center'> 
          <DragDropContext onDragEnd={handleReorder}>
            <Droppable droppableId="droppable">
              {(provided) => {
                return (
                  <div {...provided.droppableProps} ref={provided.innerRef} className={clsx('fmb-10 flex-1', classes.paperHeight)}>
                    {items.map((id, index) => {
                      return (
                        <Draggable 
                          draggableId={`__dragId${index}`}
                          key={`__dragId${index}`}
                          index={index}
                          isDragDisabled={viewOnly}
                        >
                          {(provided) => {
                            return (
                              <div
                                key={`${id}+${index}`}
                                ref={provided.innerRef}
                                className={clsx(index < items.length - 1 && 'fmb-20', 
                                  'd-flex align-items-center align-self-start')}
                                {...provided.draggableProps}
                              >
                                <div className='d-flex align-items-center position-relative mr-25 w-100' {...provided.dragHandleProps}>
                                  <WorkoutCard
                                    minWidth={310}
                                    editable={false}
                                    isPrimary={index === 0}
                                    woData={fetchedItems[id]}
                                    itemId={id}
                                    toggleDrawer={() => toggleDrawer({
                                      ..._.get(fetchedItems, id.includes('/') ? id.split('/')[0] : id, {}), displayEditOption: !viewOnly,altIndex:index,setAltItems:setItems
                                    })}
                                    toggleAlternateDialog={null}
                                  />
                                  {!viewOnly && <IconButton component="div" {...provided.dragHandleProps} size="small"
                                    className={clsx(classes.dragIcon, items.length === 1 && index === 0 && 'vis-collapse' )}>
                                    <DragIcon />
                                  </IconButton>}
                                </div>
                                {!viewOnly && items.length > 1 && <IconButton size="small" edge="end" onClick={() => handleDelete(id, index)}>
                                  <DeleteIcon />
                                </IconButton>}
                              </div> 
                            )
                          }}
                        </Draggable>
                      )
                    })}
                    {provided.placeholder}
                  </div>)}}
            </Droppable>
          </DragDropContext>
          {!viewOnly && <ClrdButton color="invert" className='shadow-none f-medium font_15_600 w-100' onClick={toggleSelector}>
            Add Alternatives
          </ClrdButton>}
        </div>
      </Dialog>

      {/* Workout Selectors */}
      <WorkoutSelector
        open={!!selectorState}
        onClose={() => toggleSelector(false)}
        moduleName="schedule"
        handleAdd={handleItemAdd}
        selectedWOs={items && items.concat(dayIds)
          .reduce((prev, curr) => curr.includes("/") ? prev= [...prev, ...curr.split("/")] : prev = [...prev, curr], [])
          .map((id) => fetchedItems[id])
        }
        showDraftInd={true}
      />
    </>
  )
}

export default AlternateWoDialog
