import { Typography } from '@material-ui/core';
import React from 'react';
import appNotification from 'fitbud/images/app-notification.png';

export default function Placeholder() {
  return (
    <div className="h-100 d-flex align-items-center justify-content-center flex-column px-5 bg-white">
      <img alt="noitem" src={appNotification} width={360} height={360} />
      <div style={{ maxWidth: 360 }}>
        <Typography className="fmt-20 font_20_700" color="textPrimary">
          Better Outreach with In-App Notifications
        </Typography>
        <Typography className="fmt-20 font_15_500" style={{ color: '#37404D' }}>
          Use In-App Notifications for dynamic product announcements, upgrades, and promotions, empowering trainers to
          market plans for enhanced client engagement.{' '}
        </Typography>
      </div>
    </div>
  );
}
