import React from "react";
import _, { isEmpty, xorWith, isEqual, cloneDeep } from 'lodash';
import {
  PROGRAMS_WORKOUT,
  MEAL_PLANS_NUTRITION,
  ALL_COLLECTION,
  RESOURCE_COLLECTION,
  NUTRITION_COLLECTION,
  GROUP_ENUM
} from './constants';
import {durationToHMString} from "fitbud/views/workouts/helperfn";

export const isArrayEqual = (x, y) => isEmpty(xorWith(x, y, isEqual));

export const getEnableLabel = (keyName, exploreType) => {
  const condi = exploreType === 'workout';
  const condi2 = exploreType === 'resources';
  switch (keyName) {
    case 'programs':
      return `If you enable explore in ${
        condi ? 'Schedules' : 'Meal Plans'
      }, your active clients will be able to browse and switch to these ${
        condi ? 'workout schedules' : 'plans'
      }. Do you want to proceed?`;
    case 'on_demand':
      return (
        <span>
          Enabling <b className="text-black">All {`${condi ? 'Workouts' : condi2 ? 'Resources' : 'Recipes'}`}</b> will give access to all the{' '}
          <span>{`${condi ? 'workouts' : condi2 ? 'resources' : 'recipes'}`}</span> to your active clients. Do you want
          to proceed?
        </span>
      );
    case 'meal_plans':
      return 'If you enable explore in Meal Plans, your active clients will be able to browse and switch to these plans. Do you want to proceed?';
    default:
      return `Enabling this collection will allow your active clients to browse and use the listed ${
        condi ? 'workouts' : condi2 ? 'resources' : 'recipes'
      }. Do you want to proceed?`;
  }
};

export const getDisableLabel = (keyName, exploreType) => {
  const condi = exploreType === 'workout';
  const condi2 = exploreType === 'resources';

  switch (keyName) {
    case 'programs':
      return `If you disable explore in ${
        condi ? 'Schedules' : 'Meal Plans'
      }, your clients won’t be able to browse or use these ${
        condi ? 'workout schedules' : 'plans'
      }. Do you want to proceed?`;
    case 'on_demand':
      return <span>
        If you disable <b className="text-black">All {` ${
        condi ? 'Workouts' : condi2 ? 'Resources' : 'Recipes'
      }`}</b> <span>your clients won’t be able to use them directly unless and until they are part of a collection. Do you want to proceed?</span>
      </span>
      return `If you disable All ${
        condi ? 'Workouts' : condi2 ? 'Resources' : 'Recipes'
      }, your clients won’t be able to browse or use these ${
        condi ? 'workouts' : condi2 ? 'resources' : 'recipes'
      }. Do you want to proceed?`;
    case 'meal_plans':
      return `If you disable explore in Meal Plans, your clients won’t be able to browse or use these plans. Do you want to proceed?`;
    default:
      return `If you disable this collection, your clients won’t be able to browse or use the listed  ${
        condi ? 'workouts' : condi2 ? 'resources' : 'recipes'
      }. Do you want to proceed?`;
  }
};

export const getEnable = (exploreType, exploreId) => {
  const TXT_ENABLE = getEnableLabel(exploreId, exploreType);
  return TXT_ENABLE;
};

export const getDisable = (exploreType, exploreId) => {
  const TXT_DISABLE = getDisableLabel(exploreId, exploreType);
  return TXT_DISABLE;
};

export const isCollection = (exploreId) => !['programs', 'meal_plans', 'on_demand'].includes(exploreId);

export const isEditCollectionName = (exploreId) => ['programs', 'meal_plans'].includes(exploreId); //only edit default collection name condition

export const getKeyName = (exploreType, exploreId) => {
  if (exploreType === 'workout') {
    if (exploreId === 'programs') return 'schedules';
    else return 'workouts';
  }
  if (exploreType === 'nutrition') {
    if (exploreId === 'meal_plans') return 'meals';
    else return 'foodRecipes';
  }
  if (exploreType === 'resources') return 'resources';
};
export const getHeader = (exploreType, selectedTab, selectedItemId = '') => {
  if (exploreType === 'workout') {
    if (selectedTab === 'schedules') return 'Program';
    else return selectedItemId.type === 'cardio' ? 'Cardio' : 'Workout';
  }
  if (exploreType === 'nutrition') {
    if (selectedTab === 'schedules') return 'Meal Plan';
    else return 'Recipe';
  }
  if (exploreType === 'resources') return 'Resource';
};

export const mergeAccentColor = (obj, color_theme) => {
  const accent_color = getDefinedAccentColors(color_theme);
  if (!!accent_color) return { ...cloneDeep(obj), accent_color };
  else return cloneDeep(obj);
};

export function getInitialValue(exploreType, collection, color_theme, group=false) {
  if(!!group) return mergeAccentColor(GROUP_ENUM, color_theme);
  if (exploreType === 'workout') {
    switch (collection) {
      case 'schedules':
        return mergeAccentColor(PROGRAMS_WORKOUT, color_theme);
      case 'collections':
        return mergeAccentColor(ALL_COLLECTION, color_theme);
      default:
        return mergeAccentColor(ALL_COLLECTION, color_theme);
    }
  } else if (exploreType === 'nutrition') {
    switch (collection) {
      case 'schedules':
        return mergeAccentColor(MEAL_PLANS_NUTRITION, color_theme);
      case 'collections':
        return mergeAccentColor(NUTRITION_COLLECTION, color_theme);
      default:
        return mergeAccentColor(NUTRITION_COLLECTION, color_theme);
    }
  } else if (exploreType === 'resources') {
    switch (collection) {
      case 'collections':
        return mergeAccentColor(RESOURCE_COLLECTION, color_theme);
      default:
        return mergeAccentColor(RESOURCE_COLLECTION, color_theme);
    }
  }
}

export const getWoLabel = (keyName, free = false, hidepaid = false) => {
  switch (keyName) {
    case 'programs':
      return {
        heading: 'Clients on any active plan will be able to explore & switch to these schedules.',
        subHeading: 'Manage which schedules you want to show to your active clients.',
      };
    case 'on_demand':
      return {
        heading: 'Allow your active clients to access all workouts from your workouts library.',
        subHeading: 'Your active clients will be able to use these workouts by simply searching for them.',
      };
    default:
      const prefix = free ? (hidepaid ? 'Leads' : 'All clients') : 'Clients on any active plan';
      return {
        heading: prefix + ' will be able to explore & use workouts listed here.',
        subHeading: 'Manage which workouts you want to add/remove from this collection.',
      };
  }
};

export const getNutritionLabel = (keyName, free = false, hidepaid = false) => {
  switch (keyName) {
    case 'meal_plans':
      return {
        heading: 'Clients on any active plan will be able to explore & use these meal plans.',
        subHeading: 'Manage which meal plans you want to show to your active clients.',
      };
    case 'on_demand':
      return {
        heading: 'Allow your active clients to access all recipes from your recipe library.',
        subHeading: 'Your active clients will be able to use these recipes by simply searching for them.',
      };
    default:
      const prefix = free ? (hidepaid ? 'Leads' : 'All clients') : 'Clients on any active plan';
      return {
        heading: prefix + ' will be able to explore & use recipes from this collection.',
        subHeading: 'Manage which recipes you want to add/remove from this collection.',
      };
  }
};

export const getResourcesLabel = (keyName, free = false, hidepaid = false) => {
   if(keyName !== "on_demand")return {
    heading: 'Clients on any active plan will be able to explore & use resources from this collection.',
    subHeading: 'Manage which resources you want to add/remove from this collection.',
   }
   else return {
    heading: 'Give access to all your resources listed below',
    subHeading: 'Your active clients will be able to use these resources from the client apps.',
  };
};

export const ADD_MEAL_OPTIONS = [
  { label: 'Meal Plan', key: 'meals' },
  { label: 'Meal Schedule', key: 'mlSchedules' },
];

export const getDefinedAccentColors = (color_theme) =>{
    let accent_color = _.get(color_theme, "colors.pp_home_tint",'') ;
    if(!!accent_color){
        accent_color = '#' + accent_color.replace(/^#/, '')
    }
    return accent_color;
}

export const getMeta = (item, exploreType, { targetAreas }) => {
  if (exploreType === 'workout') {
    const [ta] = item.target_area || [];
    // eslint-disable-next-line no-unused-vars
    const [_ign, { value }] = (ta && (targetAreas || []).find((_ta) => _ta[0] === ta)) || [null, {}];
    return {
      title: item.title,
      subtitle: `${durationToHMString(item.duration)} ${
        value ? `\u2004•\u2004${value}` : ''
      }`,
      // subtitle: `${Math.round(item.duration / 60) ? Math.round(item.duration / 60) + ' mins' : ''} ${
      //   value ? `\u2004•\u2004${value}` : ''
      // }`,
    };
  }
  if (exploreType === 'nutrition') {
    return {
      title: item.title,
      subtitle: `${Math.round(_.get(item, 'macros.calories', 0)).toLocaleString()} cals`,
    };
  }
  if (exploreType === 'resources') {
    return {
      title: item.title,
      subtitle: item.dtype,
    };
  }
};
