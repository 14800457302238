import _ from 'lodash';
import uuid from 'uuid/v4';
import React, { useContext, useEffect, useRef, useMemo, useState } from 'react';
import {
  Select, MenuItem, OutlinedInput, FormHelperText,
  RadioGroup, Radio, FormControlLabel, InputAdornment,
  Grid, Typography, TextField, Checkbox, Tooltip
} from '@material-ui/core';
import { useInput, usePicker, useToggle, useValidators } from 'fitbud/hooks/form';
import { ToggleLabel } from 'fitbud/components/toggles';
import { FormTextField } from 'fitbud/components/form-fields';
import { AccessContext } from 'fitbud/providers/access-provider';
import { PlanContext } from '../planProvider';
import makeStyles from "@material-ui/core/styles/makeStyles";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import DropDownIcon from "@material-ui/icons/ExpandMore";
import {getActiveStatus} from "../helper";

const SUBSCRIPTION_OPTIONS = [
  {label:"Weekly",value:"week"},
  {label:"2 Weekly",value:"2 week"},
  {label:"6 Weekly",value:"6 week"},
  {label:"Monthly",value:"month"},
  {label:"Quarterly",value:"quarter"},
  {label:"6 Monthly",value:"6 month"},
  {label:"Yearly",value:"year"},
]

export const DatePickerField = (props) => {
  const InputProps = {
    classes: {
      root: 'medium',
      input: 'size_16_500 medium',
    },
    endAdornment:<InputAdornment><DropDownIcon/></InputAdornment>,
    ...props.InputProps,
  };
  return <TextField {...props} InputProps={InputProps} />;
};

const PriceEditor = (props) => {
  const { id, iap, play, fnRef, setDirty, data = {} } = props;
  const {startEdit, updateLegacyCount} = useContext(AccessContext);
  const { cid, defaultCurrency, availableCurrencies, iapIdMap, pcIdMap } = useContext(PlanContext);
  let priceOpt = {};
  if (id === 'new') // pick the last price in the existing list
    priceOpt = _.get(data, 'price_opts[0]', {});
  else if (id && id !== 'add')
    priceOpt = _.chain(data).get('price_opts').find(['id', id]).value() || {};
  const isMembershipAddOn = _.get(data, "type") === "add_on" && _.get(data, "add_on_type") === "membership";
  const isAddOnPack = _.get(data, "type") === "add_on" && !isMembershipAddOn;
  const isRegularPack = _.get(data, "type",'regular') === "regular";
  useEffect(() => {
    if (id === 'new') return;
    if (!isAddOnPack || (!data || data.add_on_type !== 'one_to_one')) return;
    startEdit();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const isIAP = useRef(iap || (!!priceOpt.iap_id && priceOpt.method_ios === 'iap'));
  const isPlay = useRef(play || (!!priceOpt.play_product_id));
  const [mode, setMode] = usePicker(priceOpt.mode || 'one_time');
  const {parsed: iap_id, props: aProps, isValid: aValid} = useInput(priceOpt.iap_id || '', 'text *', {validator: v => {
    if (!isIAP.current) return '';
    if (!v) return 'Required';
    if (!!iapIdMap[v.toUpperCase()]) return 'Already used in a purchase plan';
  }});
  const {parsed: play_product_id, props: xProps, isValid: xValid} = useInput(priceOpt.play_product_id || '', 'text *', {validator: v => {
    if (!isPlay.current) return '';
    if (!v) return 'Required';
    if (!!pcIdMap[v.toLowerCase()]) return 'Already used in a purchase plan';
  }});
  const {parsed: duration, props: dProps, isValid: dValid, setError: setXError} = useInput( priceOpt.duration ? priceOpt.duration : isAddOnPack ? 12 : 1, 'number * 1:min');
  const [duration_type, setDType] = usePicker(priceOpt.duration_type ?  priceOpt.duration_type : 'months');
  const [subs_duration_type, setSubUnit] = usePicker(priceOpt.subs_duration_type);
  const [sd_error, setSdError] = useState("");
  const [currency, setCurrency] = usePicker(priceOpt.currency || defaultCurrency || 'USD');
  const {parsed: price, props: pProps, isValid: pValid, setError: setPError} = useInput(priceOpt.price || (priceOpt.price === 0 ? '0' : 1), 'number * 0:min');
  const {parsed: num_sessions, props: nsProps, isValid: nsValid, setError: setNsError} = useInput(priceOpt.num_sessions || 1, 'number * 1:min .');
  const [discount_enabled, toggleDiscount, setDiscount] = useToggle(priceOpt.discount_enabled || false);
  const [validity_enabled, toggleValidityEnabled, setValidityEnabled] = useToggle(priceOpt.validity_enabled || false);
  const {parsed: discounted_price, props: dpProps, isValid: dpValid, setError: setDError} = useInput(priceOpt.discounted_price || 1, 'number * 1:min');
  const [enable_trial_pack, toggleTrial, setTrial] = useToggle(priceOpt.enable_trial_pack || false);
  const [trialDuration, setTrialDuration] = usePicker(`${priceOpt.trial_duration || 1} ${priceOpt.trial_duration_type || 'weeks'}`);
  const isRegularPackValid = useValidators(dValid, pValid);
  const isAddOnPackValid = useValidators(pValid, nsValid, dValid);
  const _subsOption = useMemo(()=>{
    const po = data.price_opts;
    if (!po || !po.length) return SUBSCRIPTION_OPTIONS;
    if (isIAP.current || isPlay.current) return SUBSCRIPTION_OPTIONS;
    return _.filter(SUBSCRIPTION_OPTIONS,(opt)=>!_.find(po,(p)=>{
      if (p.id === id) return false;
      if (!!p.play_product_id || (!!p.iap_id && p.method_ios === 'iap')) return false;
      return p.mode === 'subscription' && p.subs_duration_type === opt.value 
    }))
  },[id, data.price_opts, isIAP, isPlay ])


  useEffect(() => {
    if (mode === 'one_time') setTrial(false);
    const [trial_duration, trial_duration_type] = mode === 'subscription' && enable_trial_pack ? trialDuration.split(' ') : [null, null];
    fnRef.current = (setState) => {
      if (!setState) return false;
      if(!isAddOnPack) if (!isRegularPackValid()) return false;
      if(!!isAddOnPack) if(!isAddOnPackValid()) return false; 
      if(!!isAddOnPack) if(!num_sessions){
        setNsError("Number of calls required");
        return false;
      };

      if (discount_enabled && !dpValid()) return false;
      if (isIAP.current && !aValid()) return false;
      if (isPlay.current && !xValid()) return false;
      if (!isIAP.current && !isPlay.current && discount_enabled && discounted_price >= price) {
        setDError('Discounted price can\'t be more than actual price');
        return false;
      }
      if(!isAddOnPack){
        if (!price && mode === 'subscription') {
          setPError('"Zero Price" or "Free" is available for One Time purchases only');
          return false;
        }
        if (!isIAP.current && !isPlay.current && mode === "subscription" && !subs_duration_type) {
          setSdError('Please select an option');
          return false;
        }
        if (mode === 'one_time' && (
          (duration_type === 'days' && duration > 365) ||
          (duration_type === 'weeks' && duration > 52) ||
          (duration_type === 'months' && duration > 12))) {
          setXError('Duration of a one-time purchase cannot be more than a year');
          return false;
        }
      }
      if (setDirty) setDirty({dirty: true});
      setState(curr => {
        const opts = curr.price_opts || [];
        let index = id === 'new' ? 0 : _.findIndex(opts, ['id', id]);
        if (index < 0) index = opts.length;
        const price_opts = id === 'new' ? [] : [...opts];
        let iap_ids = curr.iap_ids || [];
        let pc_ids = curr.pc_ids || [];
        const hasActive = getActiveStatus(_.get(price_opts, index), "active");
        const hasActiveIos = getActiveStatus(_.get(price_opts, index), "active_ios");
        const hasActiveAndroid = getActiveStatus(_.get(price_opts, index), "active_android");
        let opt = {
          mode: isPlay.current ? 'subscription' : mode,
          id: _.get(price_opts, `[${index}.id]`, uuid()),
          activeV2: hasActive === undefined ? isIAP.current || isPlay.current ? false : true : hasActive,
          active_iosV2: hasActiveIos === undefined ? isPlay.current ? false : true: hasActiveIos,
          active_androidV2: hasActiveAndroid === undefined ?isIAP.current ? false : true :  hasActiveAndroid,
          currency: currency || 'USD', // Android parser makes this mandatory, so adding a fallbacl
        };
        if(isRegularPack ){ //for membership plan, keep old key as well.
          opt.active = opt.activeV2 ;
          opt.active_ios = opt.active_iosV2;
          opt.active_android = opt.active_androidV2;
        }
        if (isIAP.current) {
          const _id = iap_id.toUpperCase();
          iap_ids = _.uniq([...iap_ids, _id]);
          opt.iap_id = _id;
          opt.method_ios = 'iap';
          if (mode === 'one_time')
            if(!isAddOnPack) opt = {...opt, duration, duration_type};
            else opt = {...opt, num_sessions};
        } else if (isPlay.current) {
          const _id = play_product_id.toLowerCase();
          pc_ids = _.uniq([...pc_ids, _id]);
          opt.play_product_id = _id;
        } else {
          if(!isAddOnPack) opt = {...opt, duration, duration_type, price};
          else if (!!isAddOnPack) opt = {...opt, num_sessions, price, duration, duration_type};
          if (discount_enabled) {
            opt.discount_enabled = true;
            opt.discounted_price = discounted_price;
          }
          if(validity_enabled){
            opt.validity_enabled = true;
          }
          if (mode === 'subscription') {
            opt.subs_duration_type = subs_duration_type;
            opt.duration_type = 'months';
            switch (subs_duration_type) {
              case 'week':
                opt.duration = 1;
                opt.duration_type = 'weeks';
                break;
              case '2 week':
                opt.duration = 2;
                opt.duration_type = 'weeks';
                break;
              case '6 week':
                opt.duration = 6;
                opt.duration_type = 'weeks';
                break;
              case 'month':
                opt.duration = 1;
                break;
              case 'quarter':
                opt.duration = 3;
                break;
              case '6 month':
                opt.duration = 6;
                break;
              case 'year':
                opt.duration = 12;
                break;
              default:
                break;
            }
            if (enable_trial_pack) {
              opt.enable_trial_pack = enable_trial_pack;
              opt.trial_duration = Number(trial_duration);
              opt.trial_duration_type = trial_duration_type;
            }
          }
        }
        price_opts[index] = opt;
        if (data && data.add_on_type === 'one_to_one')
          updateLegacyCount(num_sessions, opt.id);
        return {...curr, iap_ids, pc_ids, price_opts};
      });
      return true;
    }
  }, [fnRef, setDirty, isRegularPackValid, discount_enabled, dpValid, mode, enable_trial_pack, setTrial, setDiscount, trialDuration, setPError,
    iap_id, aValid, play_product_id, xValid, duration, duration_type, currency, price, discounted_price, subs_duration_type, id, setDError, num_sessions, isAddOnPack, isAddOnPackValid, validity_enabled]);

  if (isIAP.current) {
    const sub = mode === 'subscription';
    return (
      <div className='my-15 mx-20 w-100'>
        {id === 'add' && <Mode id={id} mode={mode} onChange={setMode} subOptions={_subsOption}/>}
        <Grid container justify='space-between'>
          <Grid item xs={12} sm={sub ? 12 : 6} className='pr-sm-2'>
            <FormTextField fullWidth label='Apple In-App Product Id'>
              <TextField required {...aProps} variant='outlined'
                InputProps={{ classes: { root: 'medium pr-1', input: 'size_16_500 medium text-uppercase' } }}/>
            </FormTextField>
          </Grid>
          {!sub && <Grid item xs={12} sm={6} className='pl-sm-2'>
            <FormTextField fullWidth label='Duration'>
              <TextField required {...dProps} variant='outlined'
              InputProps={{
                classes: { root: 'medium pr-1', input: 'size_16_500 medium' },
                endAdornment: (<InputAdornment position='end'>
                  <ToggleLabel size='small' value={duration_type} options={DT} onChange={setDType}/>
                </InputAdornment>)
              }}/>
            </FormTextField>
          </Grid>}
        </Grid>
        <div className='border-top pt-3'>
          <Typography style={{ color: "#37404D" }} variant='caption' className='font-weight-500'>
            Prices are managed directly from Apple App Store Connect. In case of subscriptions, the billing cycle too is managed from App Store Connect.&nbsp;
            You can view and edit these details under <b>Monetization&nbsp;>&nbsp;Subscriptions</b> on&nbsp;
            <a target='_blank' rel='noopener noreferrer' href='https://appstoreconnect.apple.com/apps'>App Store Connect</a>.
          </Typography>
        </div>
      </div>
    );
  }

  if (isPlay.current) {
    return (
      <div className='my-15 mx-20 w-100'>
        <Grid container justify='space-between'>
          <Grid item xs={12}>
            <FormTextField fullWidth label='Google Play Subscription Id'>
              <TextField required {...xProps} variant='outlined'
                InputProps={{ classes: { root: 'medium pr-1', input: 'size_16_500 medium text-lowercase' } }}/>
            </FormTextField>
          </Grid>
        </Grid>
        <div className='border-top pt-3'>
          <Typography style={{ color: "#37404D" }} variant='caption' className='font-weight-500'>
            Prices and billing cycles are managed directly from Google Play Console.&nbsp;
            You can view and edit these details under <b>Monetize&nbsp;>&nbsp;Products&nbsp;>&nbsp;Subscriptions</b> on{' '}
            <a target='_blank' rel='noopener noreferrer' href='https://play.google.com/console/developers'>Google Play Console</a>.
          </Typography>
        </div>
      </div>
    );
  }

  //for AddOn Pack
  if(!!isAddOnPack){
    return <div className='p-20 w-100'>
      <Grid container>
      <Grid item xs={12} md={6} className='pr-20' >
            <FormTextField fullWidth label="Total Number of sessions">
              <TextField  fullWidth required {...nsProps} variant='outlined'
              InputProps={{
                classes: { root: 'medium', input: 'size_16_500 medium' },
              }}
              />
            </FormTextField>
          </Grid>
        <Grid item sx={12} sm={6}>
          <FormTextField  fullWidth label='Price'>
              <TextField required {...pProps} variant='outlined'
              InputProps={{
                classes: { root: 'medium pl-0', input: `size_16_500 medium ${Boolean(defaultCurrency) ? 'pl-2' : ''}` },
                startAdornment: <Currency fixed={Boolean(defaultCurrency)} currency={currency} currencies={availableCurrencies} onChange={setCurrency}/>
              }}/>
          </FormTextField>
        </Grid>
        <Grid item xs={12} className='mt-n5'>
          <FormControlLabel className='mb-10' control={<Checkbox color='primary'/>}
          label={<Typography variant='h6'>Enable Discounted Price&nbsp;
            <span><Tooltip placement="right" title="Give a discount on this plan to grab the attention of your potential clients."><span><InfoOutlinedIcon style={{color:"#6F8099" , fontSize:18}} /></span></Tooltip></span> 
          </Typography>}
          checked={discount_enabled} onChange={toggleDiscount}/>
        </Grid>
        {discount_enabled &&
            <Grid item xs={6} >
              <TextField fullWidth required {...dpProps} variant='outlined'
              InputProps={{
                classes: { root: 'medium pl-0 mb-20', input: 'size_16_500 medium pl-2' },
                startAdornment: <Currency fixed currency={currency}/>
              }}/>
            </Grid>}
        <Grid item xs={12} className='mt-n5'>
          <FormControlLabel className='mb-10' control={<Checkbox color='primary'/>}
          label={<Typography variant='h6'>Enable Validity&nbsp;
            <span><Tooltip placement="right" title="Select how long the sessions pack remains active after the purchase date."><span><InfoOutlinedIcon style={{color:"#6F8099" , fontSize:18}} /></span></Tooltip></span> 
          </Typography>}
          checked={validity_enabled} onChange={toggleValidityEnabled}/>
        </Grid>
        {validity_enabled &&
            <Grid item xs={6} >
              <FormTextField fullWidth label='Validity Duration'>
              <TextField required {...dProps} variant='outlined'
              InputProps={{
                classes: { root: 'medium pr-1', input: 'size_16_500 medium' },
                endAdornment: (<InputAdornment position='end'>
                  <ToggleLabel size='small' value={duration_type} options={DT} onChange={setDType}/>
                </InputAdornment>)
              }}/>
            </FormTextField>
            </Grid>}
      </Grid>
    </div>
  }
  
  //for regular pack
  return (
    <div className='my-15 mx-20 w-100'>
     {!isMembershipAddOn && <Mode id={id} mode={mode} onChange={setMode} subOptions={_subsOption}/>}
      <Grid container justify='space-between'>
        <Grid item xs={12} sm={6} className='pr-sm-2'>
          {mode === 'subscription' && <FormTextField fullWidth label='Billing Cycle'>
            <Select required value={subs_duration_type || "none"} error={!!sd_error} onChange={setSubUnit} variant='outlined'
              input={<OutlinedInput classes={{ root: 'medium', input: 'size_16_500 select-medium' }}/>}>
              <MenuItem  value="none">Select</MenuItem>
              {_subsOption.map((opt,index) => (<MenuItem key={opt.value + index} value={opt.value}>
                {opt.label}
              </MenuItem>))}
            </Select>
            <FormHelperText className='text-danger'>{sd_error}</FormHelperText>
          </FormTextField>}
          {mode === 'one_time' && <FormTextField fullWidth label='Duration'>
            <TextField required {...dProps} variant='outlined'
            InputProps={{
              classes: { root: 'medium pr-1', input: 'size_16_500 medium' },
              endAdornment: (<InputAdornment position='end'>
                <ToggleLabel size='small' value={duration_type} options={DT} onChange={setDType}/>
              </InputAdornment>)
            }}/>
          </FormTextField>}
        </Grid>
        <Grid item xs={12} sm={6} className='pl-sm-2'>
          <FormTextField fullWidth label='Price'>
            <TextField required {...pProps} variant='outlined'
            InputProps={{
              classes: { root: 'medium pl-0', input: `size_16_500 medium ${Boolean(defaultCurrency) ? 'pl-2' : ''}` },
              startAdornment: <Currency fixed={Boolean(defaultCurrency)} currency={currency} currencies={availableCurrencies} onChange={setCurrency}/>
            }}/>
          </FormTextField>
        </Grid>
        {mode === 'subscription' && (<>
          <Grid item xs={12}>
            <FormControlLabel className='mb-0' control={<Checkbox color='primary'/>}
            label={
              <Typography variant='h6'>Add Free Days&nbsp;
                <span className="font_13_500">(Recommended)</span>&nbsp;
                <span><Tooltip placement="right" title="Clients will be charged only after the free trial period is over."><span><InfoOutlinedIcon style={{color:"#6F8099" , fontSize:18}} /></span></Tooltip></span> 
              </Typography>
            }
            checked={enable_trial_pack} onChange={toggleTrial} />
          </Grid>
          {enable_trial_pack && <Grid item xs={6} className='pr-10'>
            <Select fullWidth required value={trialDuration} onChange={setTrialDuration} variant='outlined'
            input={<OutlinedInput classes={{ root: 'medium mb-15', input: 'size_16_500 select-medium' }}/>}>
              {cid === 'aggam' && <MenuItem value='1 days'>1 Day</MenuItem>}
              {cid === 'aggam' && <MenuItem value='2 days'>2 Days</MenuItem>}
              <MenuItem value='3 days'>3 Days</MenuItem>
              {cid === 'aggam' && <MenuItem value='4 days'>4 Days</MenuItem>}
              {cid === 'aggam' && <MenuItem value='5 days'>5 Days</MenuItem>}
              {cid === 'aggam' && <MenuItem value='6 days'>6 Days</MenuItem>}
              <MenuItem value='1 weeks'>1 Week</MenuItem>
              <MenuItem value='2 weeks'>2 Weeks</MenuItem>
              <MenuItem value='1 months'>1 Month</MenuItem>
              <MenuItem value='3 months'>3 Months</MenuItem>
              <MenuItem value='6 months'>6 Months</MenuItem>
            </Select></Grid>}
          </>)}
        <Grid item xs={12}>
          <FormControlLabel className='mb-0' control={<Checkbox color='primary'/>}
          label={<Typography variant='h6'>Enable discounted price&nbsp;
            <span><Tooltip placement="right" title="Give a discount on this plan to grab the attention of your potential clients."><span><InfoOutlinedIcon style={{color:"#6F8099" , fontSize:18}} /></span></Tooltip></span> 
          </Typography>}
          checked={discount_enabled} onChange={toggleDiscount}/>
        </Grid>
        {discount_enabled && <Grid item xs={6} className='pr-10'>
          <TextField fullWidth required {...dpProps} variant='outlined'
          InputProps={{
            classes: { root: 'medium pl-0 mb-20', input: 'size_16_500 medium pl-2' },
            startAdornment: <Currency fixed currency={currency}/>
          }}/>
        </Grid>}
      </Grid>
    </div>
  );
};

const useCurrencyClasses = makeStyles(()=>({
  select:{
    paddingRight:"36px !important"
  }
}))

export const Currency = (props) => {
  const classes = useCurrencyClasses();
  const { fixed, currency, currencies, onChange, name } = props;
  if (fixed) {
    return (
      <Typography variant='body1' component='div' className='d-flex align-items-center bg-light h-100 border-right px-3'>
        {currency}
      </Typography>
    );
  }
  return (
    <InputAdornment position='start'>
      <Select value={currency} onChange={onChange} name={name}
      classes={{select: classes.select}}
      input={<OutlinedInput classes={{ root: `medium bg-light`, input: 'size_16_500 select-medium' }}/>}>
        {currencies.map((currency) => <MenuItem value={currency} key={currency}>{currency}</MenuItem>)}
      </Select>
    </InputAdornment>
  );
};

const Mode = ({id, mode, onChange, subOptions}) => {
  if (id !== 'new' && id !== 'add') return null;
  const isSubscription = subOptions && subOptions.length;
  // if(!isSubscription) return <div style={{height:9}} />; //if only one option then nothing render
  if(!isSubscription) return null; //if only one option then nothing render
  return (
    <RadioGroup value={mode} onChange={onChange} className='flex-row'>
      <FormControlLabel value='one_time' control={<Radio color='primary'/>} label='One Time' className='mr-30'/>
      {!!isSubscription && <FormControlLabel value='subscription' control={<Radio color='primary'/>} label='Subscription'/> }
    </RadioGroup>
  );
};

const DT = [
  {value: 'days', label: 'Days'},
  {value: 'weeks', label: 'Weeks'},
  {value: 'months', label: 'Months'},
];


export default PriceEditor;
