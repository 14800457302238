import React, { useContext, useEffect, useState,useMemo } from "react";
import { Popover,Typography,FormControlLabel,Checkbox } from '@material-ui/core';
import {Tabs} from 'fitbud/components/tabs';
import { RadioGrps } from "fitbud/components/RadioGroups";
import ExerciseTypeSubTypeForm from "../exerciseTypeSubTypeForm";
import { ClrdButton } from "fitbud/components/form-fields";
import { exTypeHelper } from "fitbud/utils/helpers";
import { EditorContext } from "fitbud/views/workouts/exerciseEditor";
import {MenuList} from 'fitbud/components/menuList';
import _, {get} from 'lodash';
import ArrowIcon from "@material-ui/icons/ExpandMoreRounded";
import { useSnackbar } from "notistack";
import {Tempo} from "./tempo";

const SET_SPEC_OPTS = [{label: "Range", value: "range", className:'visible'}, {label: "Fixed", value: "fixed", className:'visible'}, {label: "Per Set", value: "perset", className:'visible'}];
const DURATION_VALID_MSG="Please enter a valid duration of the exercise";
const REPS_VALID_MSG="Please enter valid repetitions of the exercise";
const WEIGHT_VALID_MSG="Please provide valid weight";

const PopoverAttr={
    classes:{
        paper: "large"
    },
    anchorOrigin:{
        vertical: "top",
        horizontal: "left"
    },
    transformOrigin:{
        vertical: "top",
        horizontal: "right"
    }
}

function getRefTypeOptions(grpType){
  if(grpType==="tabata"){
    return [{label:'Duration',value:"duration"}]
  }
  else if(grpType==="amrap"||grpType==="emom"){
    return [{label:'Repetition',value:"reps"}]
  }
  else{
    return [{label:'Repetition',value:"reps"},{label:'Duration',value:"duration"}];
  }
}
function getExTypeOptions(grpType){
  if(grpType==="circuit"){
    return [...SET_SPEC_OPTS].map(i=>{
      if(i.value==="perset"){
        return({
          ...i,
          label:"Per Round"
        })
      }
      return {...i}
    })
  }
  else if(grpType==="amrap"){
    return [...SET_SPEC_OPTS].map(i=>{
      if(i.value==='perset'){
        return {...i,className:'invisible'}
      }
      return {...i}
    });
  }
  else return [...SET_SPEC_OPTS];
}

export const ValuePopup=({
  anchor,
  handleClose:_handleClose,
  onSave,
  loading,
  disabled,
  props,
  ...rest
  })=>{
  const {enqueueSnackbar}=useSnackbar();
  const {
    // refType,//[reps,duration,reps_w_weight]
    // handleRefType,
    // exType,//[fixed,range,per_set]
    // handleExType,
    // values,// 15
    // handleRepsValuesChange,
    // specifyWeights, //[true,false]
    // handleWeightsChng,
    // wtType,//[fixed,range,per_set]
    // handleWtType,
    // weights,//20
    // handleWeightsChange,
  }=rest;
    const [errors, setErrors] = useState({});
    const [state,updateState]=useState({
      side:rest.side,
      refType:rest.refType||"",//exercise.ref_type
      exType:rest.exType||"",//exercise.type
      values:rest.values||[],//exercise.values
      specifyWeights:rest.specifyWeights||false,
      wtType:rest.wtType||"",
      weights:rest.weights||[],
      tempo:rest.tempo?rest.tempo.split(""):['','','','']
    });
    const {type:grpType}=props;
    const refTypeOptions=getRefTypeOptions(grpType);
    const exTypeOptions=getExTypeOptions(grpType);
    function handleClose(){
        _handleClose();
    }
    const assignValues=(type)=>{
      switch(type){
        case "fixed":return [0];
        case "range":return [0,0];
        case "perset":return Array(props.group.sets).fill(0)
      }
    }
    
    function updateFn(e){
      const {id,name,value}=e.target||{};
      const keyword=id||name;
      const change={[keyword]:value};
      if(keyword==="refType"){
        if(value!==exTypeHelper.typeRepsWeight){
          change['wtType']='';
          change['weights']=[];
          change['specifyWeights']=false;
        }
      }
      if(keyword==="exType"){
        change['values']=assignValues(value);
      }
      if(keyword==="wtType"){
        change['weights']=assignValues(value);
      }
      updateState(o=>({...o,...change}));
      setErrors({});
    }
    function handleRepsValuesChange(e){
      const newValues=[...state.values];
      const valueIndex = e.target.name.split("_").pop();
      newValues[valueIndex] = parseInt(e.target.value);
      updateState(o=>({
        ...o,
        values:[...newValues]
      }));
      setErrors({});
    }
    function handleWeightsValuesChange(e){
      const newValues = [...state.weights];
      const valueIndex = e.target.name.split("_").pop();
      newValues[valueIndex] = Number(e.target.value);
      updateState(o=>({
        ...o,
        weights:[...newValues]
      }));
      setErrors({});
    }
    function handleAlowWeightsChange(name,checked){
      if(name==="allow-weights"){
        if(checked){
          updateState(o=>({
            ...o,
            refType:"reps_w_weight"
          }));
        }
        else{
          updateState(o=>({
            ...o,
            refType:"reps",
            specifyWeights:false,
            wtType:"",
            weights:[]
          }));
        }
      }
      else if(name==="specify-weights"){
        if(checked){
          const weights=props.weights||[];
          const wtType=(weights.length>2)?"perset"
            :(weights.length>1)?"range":"fixed";
          updateState(o=>({
            ...o,
            refType:"reps_w_weight",
            specifyWeights:true,
            wtType:wtType||o.exType,
            weights:[0]
          }))
        }
        else{
          updateState(o=>({
            ...o,
            specifyWeights:false,
            wtType:"",
            weights:[]
          }));
        }
      }
      setErrors({});
    }
    
    function valid(){
      let out=true;
      let errors={};
      errors.values = [];
      errors.weights = [];
      const {exType,values,refType,wtType,weights,tempo}=state;
      switch(exType){
        case "fixed":{
          if(!values[0]){
            errors.values[0]=exTypeHelper.typeDuration===refType?DURATION_VALID_MSG:REPS_VALID_MSG;
            out=false;
          }
          break;
        }
        case "range":{
          if(!values[0]){
            errors.values[0]=exTypeHelper.typeDuration===refType?DURATION_VALID_MSG:REPS_VALID_MSG;
            out=false;
          }
          if(!values[1]){
            errors.values[1]=exTypeHelper.typeDuration===refType?DURATION_VALID_MSG:REPS_VALID_MSG;
            out=false;
          }
          if(values[0] && values[1] && values[0]>values[1]){
            errors.values[0] = "Please provide valid min value";
            out = false;
          }
          break;
        }
        case "perset":{
          const sets=get(props,"group.sets",0);
          for(let i=0;i<sets;i++){
            if(!values[i]){
              errors.values[i]=exTypeHelper.typeDuration===refType?DURATION_VALID_MSG:REPS_VALID_MSG;
              out=false;
            }
          };
          break;
        }
      }
      if(state.specifyWeights){
        switch(wtType){
          case "fixed":{
            if(!weights[0]){
              errors.weights[0]=WEIGHT_VALID_MSG;
              out=false;
            }
            break;
          }
          case "range":{
            if(!weights[0]){
              errors.weights[0]=WEIGHT_VALID_MSG
              out=false;
            }
            if(!weights[1]){
              errors.weights[1]=WEIGHT_VALID_MSG;
              out=false;
            }
            if(weights[0] && weights[1] && (weights[0]>weights[1])){
              errors.weights[0] = "Please provide valid min weight";
              out = false;
            }
            break;
          }
          case "perset":{
            const sets=get(props,"group.sets",0);
            for(let i=0;i<sets;i++){
              if(!weights[i]){
                errors.weights[i]=WEIGHT_VALID_MSG;
                out=false;
              }
            };
            break;
          }
        }
      }
      if(tempo && tempo.length){
        let isEmpty=false;
        const validTempo=tempo.filter(i=>!!i);
        if(validTempo.length>0 && validTempo.length<4){
          errors.tempo="Tempo entered is invalid";
          out=false;
        }
      }
      const firstErr=(errors.values||[]).find(i=>!!i);
      const firstWtErr=(errors.weights||[]).find(i=>!!i);
      if(!!firstErr){
        enqueueSnackbar(firstErr,{
          variant:"error"
        });
      }
      else if(!!firstWtErr){
        enqueueSnackbar(firstWtErr,{
          variant:"error"
        });
      }
      setErrors(errors);
      return out;
    }
    function handleSave(e){
      if(!valid()) {
        return;
      }
      const updatedExercise={
        type:state.exType,
        ref_type:state.refType,
        values:[...state.values.map(i=>i||0)],
        side:state.side||"",
      };
      if((updatedExercise.ref_type==="reps_w_weight"||updatedExercise.ref_type==="duration_w_weight")
       && !!state.specifyWeights){
        updatedExercise['weight_type']=state.wtType||"";
        updatedExercise['weights']=[...state.weights.map(i=>i||0)];
      }
      //TODO:TEST this
      if('tempo' in state){
        updatedExercise['tempo']=state['tempo'].join("");
      }
      //TODO:TEST this
      onSave(e,updatedExercise);
    }
    return (
      <Popover
        id="value-popover"
        open
        anchorEl={anchor}
        onClose={handleClose}
        {...PopoverAttr}
      >
        <div className="fpx-20 fpy-25">
          <div className="d-flex justify-content-between align-items-center fmb-20 ">
            <Typography className="font_15_600 font-weight-700">
              Tracking Field
            </Typography>
            <SideTypeMenu  side={state.side} updateFn={updateFn}/>
          </div>
          <Tabs
            options={refTypeOptions}
            value={state.refType === "reps_w_weight" ? "reps" : state.refType}
            className={"fmb-20"}
            handleChange={(v)=>updateFn({target:{id:"refType",value:v}})}
            componentType="slideBtns"
            useMountHack={true}
          />
          <RadioGrps
            keyName="exType"
            value={state.exType}
            handleChange={updateFn}
            fields={exTypeOptions}
            inputProps={{
              "data-group": props["data-group"],
              "data-exercise": props.index,
            }}
          />
          <ExerciseTypeSubTypeForm
            unit={
              state.refType === exTypeHelper.typeDuration
                ? exTypeHelper.typeDuration
                : exTypeHelper.typeReps
            }
            sub_type={state.exType}
            sets={props.group.sets}
            key={`ex_type_form${state.exType}`}
            values={state.values || []}
            handleChange={handleRepsValuesChange}
            errors={errors.values || []}
            inputProps={{
              "data-group": props["data-group"],
              "data-exercise": props.index,
            }}
          />
          {(state.refType === exTypeHelper.typeReps ||
            state.refType === exTypeHelper.typeRepsWeight) && (
            <>
              <Typography variant="body1" className="fmb-10 font-weight-700">
                Weights
              </Typography>
              <AllowWeights
                onChange={handleAlowWeightsChange}
                initValue={{
                  "allow-weights": state.refType === "reps_w_weight",
                  "specify-weights": state.specifyWeights,
                }}
              />
            </>
          )}
          {state.refType === exTypeHelper.typeRepsWeight && !!state.wtType && (
            <>
              <RadioGrps
                keyName="wtType"
                value={state.wtType}
                handleChange={updateFn}
                fields={exTypeOptions}
                inputProps={{
                  "data-group": props["data-group"],
                  "data-exercise": props.index,
                }}
              />
              <ExerciseTypeSubTypeForm
                unit="equipment-weight"
                sub_type={state.wtType}
                sets={props.group.sets}
                key={`_weight_${state.wtType}`}
                values={state.weights || []}
                handleChange={handleWeightsValuesChange}
                loading={loading}
                disabled={disabled}
                errors={errors.weights || []}
                inputProps={{
                  "data-group": props["data-group"],
                  "data-exercise": props.index,
                }}
              />
            </>
          )}
          <Typography variant="body1" className="fmb-10 font-weight-700">
            Tempo
          </Typography>
          <Tempo value={state.tempo} onChange={updateFn} error={errors.tempo}/>
          <ClrdButton
            variant="contained"
            color="primary"
            fullWidth
            className=" f-xlarge mt-25"
            onClick={handleSave}
            disabled={disabled}
            data-group={props["data-group"]}
            data-exercise={props.index}
          >
            Save
          </ClrdButton>
        </div>
      </Popover>
    );
}

const SideTypeMenu = ({ side, setSide,updateFn }) => {
  const { sidesData } = useContext(EditorContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const closeMenu = () => setAnchorEl(null);
  const openMenu = (e) => setAnchorEl(e.currentTarget);
  const handleSideSelect = (e, value) => {
    updateFn({
      target:{
        id:"side",
        value
      }
    })
    closeMenu();
  };
  const options = useMemo(()=>{
    return sidesData.map((i) => ({ label: i[1].value, value: i[0],dense:true }));
  },[sidesData]);

  const selected = useMemo(()=>{
    return get(
      options.find((i) => i.value === side),
      "label"
    );
  },[side,options])
  return (
    <>
      <ClrdButton onClick={openMenu}>
        <span className="mr-15 font_13_500 text-dark-grey">
          {selected || "Select side"}
        </span>
        <ArrowIcon />
      </ClrdButton>
      <MenuList
        anchorEl={anchorEl}
        onClose={closeMenu}
        options={options}
        handleClick={handleSideSelect}
        getContentAnchorEl={null}
        displayEmpty={!!selected}
        displayEmptyValue={'none'}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      />
    </>
  );
};

const AllowWeights=({
  onChange,
  initValue={0:false,1:false}
})=>{
  const handleChange=(e)=>{
      onChange(e.target.name,e.target.checked);
  }
  return(
      <div className="d-flex flex-column">
          <FormControlLabel
              className="fmb-0"
              control={<Checkbox color="primary" checked={!!initValue['allow-weights']} onChange={handleChange} name={'allow-weights'} />}
              label="Allow clients to log weight with reps"
              disabled={!!initValue['specify-weights']}
          />
          <FormControlLabel
              control={<Checkbox color="primary" checked={!!initValue['specify-weights']} onChange={handleChange} name={'specify-weights'} />}
              label="Specify Weights"
          />
      </div>
  )
}