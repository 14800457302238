import React from "react";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import {DRAFT,PUBLISHED } from "fitbud/utils/constants";
import DragDropInputForm from "../../components/dragDropInputFormV2";
import appRdxFns from "fitbud/redux/app";
import exRdxFns from "fitbud/redux/exercises";


const ExerciseStep = props => {
  let { onClose,handleDraft,handlePublish,draftFeature=false,isDraftAvailable=false } = props;

  const onSave = (steps,type) => {
    if(type===DRAFT){
      handleDraft({steps});
    }
    else if(type===PUBLISHED){
      handlePublish({steps})
    }
  };

  return (
    <DragDropInputForm
      open
      options={props.steps}
      onClose={onClose}
      onSubmit={(steps,type) => onSave(steps,type)}
      addMoreLabel="Add More Instructions"
      title="Instructions to Perform"
      confirmation={true}
      showCustomConfirmation={!!draftFeature}
      isDraftAvailable ={isDraftAvailable}
    />
  );
};

const mapDispatchToProps = d => {
  const { showLoader, hideLoader } = appRdxFns(d);
  return { ...exRdxFns(d), showLoader, hideLoader };
};

export default withSnackbar(connect(null, mapDispatchToProps)(ExerciseStep));
