import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import MultilineInput from 'fitbud/components/multilineInput';

const PointsEditor = (props) => {
  const { fnRef, setDirty, src, data = {} } = props;
  const defaultPoints=(Array.isArray(data[src]) && data[src].length)?data[src]:[""]
  const [points, setPoints] = useState(defaultPoints);
  useEffect(() => {
    if (setDirty) setDirty({ [src]: points });
    fnRef.current = (setState) => {
      if (!setState) return false;
      setState(curr => ({...curr, [src]: _.chain(points)
        .map(_.trim).without('').value()}));
      return true;
    };
  }, [fnRef, setDirty, src, points]);
  return (
    <div className='w-100 p-20'>
      <MultilineInput
        addMoreLabel="Add More Points"
        options={points}
        onChange={setPoints}
      />
    </div>
  );
};

export default PointsEditor;
