import React, { useState, useEffect, useContext } from 'react';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import usePreviewLink from 'fitbud/hooks/useLinkPreview';
export const WebsiteLinkContext = React.createContext({});

const WebsiteLinkProvider = (props) => {
  const { children } = props;
  const [values, setValues] = useState({});
  const { cid, comp } = useContext(FirebaseAuthContext);
  const [previewLink, primaryLink] = usePreviewLink(cid);

  useEffect(() => {
    if (!previewLink) return;
    let usesCommonApp = false;
    let useExtForPacks = false;
    if (comp && comp.exists) {
      const { app } = comp.data();
      usesCommonApp = !!app && !app.android && !app.appStoreId;
      useExtForPacks = !!app && app.useExtForPacks;
    }
    setValues({ previewLink, primaryLink, usesCommonApp, useExtForPacks });
  }, [previewLink, comp]); // eslint-disable-line react-hooks/exhaustive-deps

  return <WebsiteLinkContext.Provider value={values}>{children}</WebsiteLinkContext.Provider>;
};

export default WebsiteLinkProvider;
