import React, { useEffect, useRef, useState } from 'react';
import { getLocalTimeFromUtc, getMode, getMinutesSeconds } from 'fitbud/views/groupClasses/helper';
import moment from 'moment';
const TIMER_START_FREQUENCY = 30 * 60; //seconds;

/*
this hooks will return::
props : 
    upcomingFrequency : time in seconds
    time : local time,
    duration : in minutes
remaining time, in seconds:
status: 
    BEFORE, 
    UPCOMING, 
    CURRENT, 
    PAST
*/

export const CLASS_RUNNING_STATUS = {
  BEFORE: 'BEFORE',
  UPCOMING: 'UPCOMING',
  LIVE: 'LIVE',
  PAST: 'PAST',
};

const useLiveTime = (time, duration, upcomingFrequency = TIMER_START_FREQUENCY) => {
  const [status, setStatus] = useState(CLASS_RUNNING_STATUS.PAST);
  const [remaining_time, setRemainingTime] = useState(0); // in seconds
  const interval = useRef();

  const checkStatus = (currentLocalTime, classLocalTime) =>{
    let timer = true;
    const diffSeconds = currentLocalTime.diff(classLocalTime, 'seconds'); // difference is negative means upcoming class
    // if deffSeconds is -ve then before time:
    if (diffSeconds < 0) {
      if (diffSeconds > -upcomingFrequency) {
        //set upcoming other wise before::
        setStatus(CLASS_RUNNING_STATUS.UPCOMING);
        //if it is upcoming then show remaining time
        setRemainingTime(-diffSeconds);
      } else {
        if(diffSeconds < 0 && diffSeconds < -1 * 12 * 60 * 60){ 
          //if upcoming time is greater than a day ie no timer required.
          timer = false;
        }
        setStatus(CLASS_RUNNING_STATUS.BEFORE);
      }
    } else {
      //if it is positive then check for live or past::
      if (diffSeconds < duration * 60) {
        //it's live
        setStatus(CLASS_RUNNING_STATUS.LIVE);
      } else {
        //its past
        setStatus(CLASS_RUNNING_STATUS.PAST);
        timer = false;
      }
    }
    return timer;
  };


  useEffect(() => {
    const classLocalTime = typeof time === 'object' ? moment(time) : getLocalTimeFromUtc(time, 'YYYYMMDDHHmm');
    const currentLocalTime = moment();
    const timer = checkStatus(currentLocalTime, classLocalTime);
    if(!timer) return;
    interval.current = setInterval(() => {
      const currentLocalTime = moment();
      checkStatus(currentLocalTime, classLocalTime)
    }, 1000);
    return () => {
      interval.current && clearInterval(interval.current);
    };
  }, [time, duration, upcomingFrequency]);
  return [status, remaining_time];
};

export default useLiveTime;
