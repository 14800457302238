import { Button, IconButton, makeStyles } from '@material-ui/core';
import React from 'react'
import send_icon from "fitbud/images/send_icon.svg";
import ReactPlayer from 'react-player';

const useStyles = makeStyles(theme => ({
  preview: {
    width: "100%",
    height: "max-content",
    minHeight: "100%",
    bottom: 0,
    backgroundColor: "#f2f2f2",
    zIndex: 12
  },
}));
export default function MediaPreview({ previewFile, onSend, onCancel }) {
  const classes = useStyles();
  let file_url = previewFile && previewFile.file ? URL.createObjectURL(previewFile.file) : null;
  if (!file_url) return null;
  if(!previewFile.isImage && !previewFile.isVideo ) return null;
  return (
    <div
      className={`position-absolute ${classes.preview} text-center d-flex flex-column fp-20 justify-content-between
    `}
    >
      {previewFile && previewFile.isImage && (<div
        className=" m-auto d-flex justify-content-center"
        style={{
          width: "100%",
          flex: "1",
          backgroundImage: `url(${file_url})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat"
        }}
      />)}
      {previewFile && previewFile.isVideo && (
        <div
          className="d-flex justify-content-center w-100 flex-grow-1 align-items-center"
        >
          <ReactPlayer
            className='bg-black'
            controls={true}
            url={file_url}
            muted
          />
        </div>
      )}
      <div className="d-flex align-items-center justify-content-between">
        <Button color="primary" onClick={onCancel}> Cancel</Button>
        <IconButton
          color="primary"
          className={"fml-20 p-12"}
          classes={{root: "bg-primary"}}
          aria-label="send"
          onClick={onSend}
        >
          <img src={send_icon} alt="sendIcon" />
        </IconButton>
      </div>
    </div>
  )
}
