import React from 'react';
import { makeStyles,withStyles } from "@material-ui/core";

export const ListStyling=withStyles({
  listStyle:{
    '& .MuiListItem-root':{
      minHeight:"80px",
    },
    '& .MuiListItem-gutters':{
      padding:"15px 20px"
    },
    '& .MuiListItemAvatar-root':{
      marginRight:"15px",
      minWidth:"unset",
      '& .MuiAvatar-root':{
        width:"50px",
        height:"50px",
        fontSize:"18px",
        fontWeight:"600"
      },
    },
    '& .Mui-selected':{
        background: '#E0ECFE',
        '& .MuiListItemText-primary':{
          color:"#000",
        },
      },
    '& .MuiListItemText-root':{
      '& .MuiListItemText-primary':{
        fontSize:"15px",
        fontWeight:"600",
        color:"#37404D",
        //text-truncate
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        '&.selected ':{
          color:"#000",
        },
      },
      '& .MuiListItemText-secondary':{
        fontSize:"13px",
        // color:"#65768C",
        marginTop:"5px",
        //text-truncate
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        '& svg':{
          fontSize:"13px",
        }
      }
    },
    '& .MuiListItemText-multiline':{
      margin:"0px"
    },
    '& .MuiListItemSecondaryAction-root':{
      right:20
    }
  }
  
})(({children,classes})=>(
  <div className={classes.listStyle}>
    {children}
  </div>
))



const useListItemStyle = props => {
  return makeStyles(theme => ({
    root: {
      minHeight: (props && props.minHeight) || 80,
      "&:nth-child(even)": {
        borderLeft: `4px solid rgba(0, 0, 0, 0.05)`,
        backgroundColor: "rgba(0, 0, 0, 0.05)",
        "&:hover": {
          background: `rgba(0, 0, 0, 0.08)`,
          borderLeft: `4px solid transparent`,
          color:'unset'
        }
      },
      "&:nth-child(2n+1)": {
        borderLeft: "4px solid transparent",
        "&:hover": {
          background: `rgba(0, 0, 0, 0.08)`,
          borderLeft: `4px solid transparent`,
          color:'unset'
        }
      }
    },
    selected: {
      borderLeft: `4px solid ${theme.palette.primary.main} !important`,
      backgroundColor: `${theme.palette.primary.main}1E !important`,
      color: theme.palette.primary.main
    }
  }))(props);
};
export default useListItemStyle;
