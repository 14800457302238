import React, { useState, useEffect } from "react";
import parse from "url-parse";
import { CircularProgress } from "@material-ui/core";
import GTM from "fitbud/utils/gtm";
import firebase from "fitbud/firebase";
import { bffUpdateHubspot } from 'fitbud/api';
import JotformEmbed from 'react-jotform-embed';

const GTMevent = (data) => {
  if (typeof window === 'undefined') return;
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
}

const event = (name, event_label = '') => {
  const eventDims = event_label ? { event_label } : {};
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({event: name, ...eventDims});
}

const Welcome = () => {
  const [email, setEmail] = useState(process.env.NODE_ENV === 'production' ? false : 'developer@fitbudd.com');
  const showLoader = !email;
  useEffect(() => {
    const { token } = parse(window.location.toString(), true).query; 
    if (!token) {
      if (process.env.NODE_ENV === 'production') window.location.href = window.location.origin + '/sign-in';
      else {
        event('sign_up');
        window.localStorage.setItem('shout-about-call', 1);
        window.localStorage.setItem('just-signed-up', 1);
      }
      return;
    }
    firebase.auth().signInWithCustomToken(token)
      .then((userCredential) => {
        const user = userCredential.user;
        const { uid, email } = user;
        if (uid) {
          GTMevent({uid});
        }
        setEmail(email);
        event('sign_up');
        bffUpdateHubspot({ source_self_signup: true, signup_complete: true }).catch(console.error)
        window.localStorage.setItem('shout-about-call', 1);
        window.localStorage.setItem('just-signed-up', 1);
      }).catch((err) => {
        console.error(err);
        window.location.href = window.location.origin + '/sign-in';
      });
  }, []);
  return (
    <div className='vw-100 min-vh-100 bg-light-bluegray d-flex justify-content-center align-items-center'>
      <GTM/>
      {showLoader ? <CircularProgress/> : <JotformEmbed className='vh-100' scrolling src={`https://form.jotform.com/${process.env.REACT_APP_WELCOME_JOTFORM}?email=${email}`}/>}
    </div>
  );
};

export default Welcome;
