import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const LineChartLegend = (props) => {
  return (
    <SvgIcon classes={{
      root: "small"
    }} width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} style={{fill:"none"}}>
      <path d="M1.33325 2.66675V12.8667C1.33325 13.8627 2.13725 14.6667 3.13325 14.6667H13.3333" stroke="#4089F7" stroke-width="1.33333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M4.66675 11.3333L7.34425 8.11726C7.78758 7.58926 8.57508 7.55325 9.05341 8.05125L9.60758 8.62126C10.0859 9.11326 10.8734 9.08325 11.3167 8.55525L14.0001 5.33325" stroke="#4089F7" stroke-width="1.33333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </SvgIcon>
  );
};
