import _get from 'lodash/get';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Typography, Switch, TextField,
  InputAdornment, Select, OutlinedInput, MenuItem,
} from '@material-ui/core';
import { Conversion } from 'fitbud/providers/conversion';
import { countDecimals } from 'fitbud/utils/helpers';
import { UserSchContext } from '../provider';
import { ItemCard } from './cards';

const MLMODES = [{value: 'single', label: 'Single'}, {value: 'on_off', label: 'On/Off Day'}, {value: 'per_day', label: 'Per Day'}, {value: 'bmr', label: 'BMR Based'}];
const SLMODES = [{value: 'single', label: 'Single'}, {value: 'on_off', label: 'On/Off Day'}];

const MLPicker = (props) => {
  const { controller, disabled, week, weekId, supplement, id, on = false, off = false, future=false, ...rest } = props;
  if (id) {
    return (
      <ItemCard id={id} week={week} weekid={weekId} type={supplement ? 'sl' : 'ml'}
        rhs on={on} off={off} future={future}
        controller={controller} disabled={disabled}
        className='mt-20' {...rest}/>
    );
  }

  return (
    <div className='mt-20 rounded border bg-grey-new cursor-pointer d-flex justify-content-center align-items-center'
      style={{height: 80}} onClick={controller.importClick}
      data-week={week} data-weekid={weekId}
      data-purpose={`${supplement ? 'slms' : 'mlms'}-${off ? 'off' : (on ? 'on' : 'single')}`}>
      <Typography>
        {on ? 'Select On Day ' : (off ? 'Select Off Day ' : 'Select ')}
        {supplement ? 'Supplement' : 'Meal'}
      </Typography>
    </div>
  );
};

const Nutrition = ({ supplement }) => {
  const { weekToday, dayToday, currWeek, currWeekId, opcopy, controller, objCache, local, bmrlocal } = useContext(UserSchContext);
  const weekData = _get(local, ['weeks', currWeekId]);
  const [master, d7copy] = useMemo(() => {
    if (!weekData) return [{mode: ''}, null];
    return [
      weekData[supplement ? 'sl' : 'ml'] || {mode: ''},
      _get(weekData, 'data.d7.copy')];
  }, [supplement, weekData]);

  const pastWeek = currWeek < weekToday && dayToday >= 0;
  let {mode, single, on, off} = master;
  if (mode === 'single' && single === 'bmr') mode = 'bmr';
  const noBMRFeature = !bmrlocal || bmrlocal.disabled;
  const disabled = pastWeek || Boolean(opcopy);
  const enabled = Boolean(mode);
  const show1 = enabled && mode !== 'per_day';
  const show2 = enabled && mode === 'on_off';
  const opts = supplement ? SLMODES : MLMODES;
  const toggle = (e) => {
    if (pastWeek) return;
    const checked = e.target.checked;
    controller.changeMasters(supplement ? 'sl' : 'ml', currWeek,
      checked ? { mode: 'single' } : null);
  };
  const change = (e) => {
    if (pastWeek) return;
    if (e.target.value === 'bmr') {
      if (noBMRFeature) return;
      controller.changeMasters(supplement ? 'sl' : 'ml', currWeek, {mode: 'bmr', single: 'bmr'});
    } else
      controller.changeMasters(supplement ? 'sl' : 'ml', currWeek, {mode: e.target.value});
  };
  console.log("show1",show1)
  return (
    <div className='border-bottom-2 p-15 p-md-25'>
      <div className='rhsbar d-flex flex-row align-items-center'>
        <Typography className='font_15_600'>{supplement ? 'Supplement' : 'Nutrition'}</Typography>
        <div className='flex-grow-1'/>
        {!pastWeek && <Switch color='primary' edge='end' className='my-n12' disabled={disabled} checked={enabled} onChange={toggle}/>}
      </div>
      {enabled && <Select disabled={disabled} fullWidth required value={mode} onChange={change} variant='outlined'
      input={<OutlinedInput classes={{ root: 'medium mt-20', input: 'size_16_500 select-medium text-black' }}/>}>
      {opts.map(({value, label}) => <MenuItem key={value} value={value}>{label}</MenuItem>)}
      </Select>}
      {!pastWeek && mode === 'per_day' && <div className='d-flex mt-20'>
        <Typography className='text-grey'>
          Use the import option on week or day to apply a Meal Schedule there onwards.
          Or use the add option to import a specific Meal to a single day.
        </Typography></div>}
      {show1 && <MLPicker id={single || on} on={mode === 'on_off'}
        disabled={disabled} controller={controller}
        week={currWeek} weekId={currWeekId}
        supplement={supplement} future={!pastWeek}
        objCache={objCache} dayCopy={d7copy}/>}
      {show2 && <MLPicker id={off} off
        disabled={disabled} controller={controller}
        week={currWeek} weekId={currWeekId}
        supplement={supplement} future={!pastWeek}
        objCache={objCache} dayCopy={d7copy}/>}
    </div>
  );
};

const Water = () => {
  const { currWeek, currWeekId, weekToday, dayToday, local, controller, opcopy } = useContext(UserSchContext);
  const [activity, pastWeek] = useMemo(() => {
    if (currWeek < weekToday && dayToday >= 0) { // past week, read from week
      return [_get(local, ['weeks', currWeekId, 'water'], {}), true];
    } else { // this/future week, read from master
      return [_get(local, ['plan', 'wc'], {}), false];
    }
  }, [local, weekToday, dayToday, currWeek, currWeekId]);
  const disabled = (Boolean(opcopy) || pastWeek);
  const { required = false, value = '' } = activity;
  const toggle = () => controller.toggleWCAC(currWeek, true);
  return (
    <div className='border-bottom-2 p-15 p-md-25 d-flex flex-column'>
      <div className='rhsbar d-flex flex-row align-items-center'>
        <Typography className='font_15_600'>Daily Water Intake</Typography>
        <div className='flex-grow-1'/>
        {!pastWeek && <Switch color='primary' edge='end' className='my-n12' disabled={disabled} checked={required} onChange={toggle}/>}
      </div>
      <WCACInput required={required} disabled={disabled} controller={controller} value={value} unit='water'/>
    </div>
  );
};

const Activity = () => {
  const { currWeek, currWeekId, weekToday, dayToday, local, controller, opcopy } = useContext(UserSchContext);
  const [activity, pastWeek] = useMemo(() => {
    if (currWeek < weekToday && dayToday >= 0) { // past week, read from week
      return [_get(local, ['weeks', currWeekId, 'activity']) || {}, true];
    } else { // this/future week, read from master
      return [_get(local, ['plan', 'ac']) || {}, false];
    }
  }, [local, weekToday, dayToday, currWeek, currWeekId]);
  const disabled = (Boolean(opcopy) || pastWeek);
  const { required = false, value } = activity;
  const {steps = '', distance = '', flights = '', energy = ''} = value || {};
  const toggle = () => controller.toggleWCAC(currWeek);
  return (
    <div className='p-15 p-md-25 d-flex flex-column'>
      <div className='rhsbar d-flex flex-row align-items-center'>
        <Typography className='font_15_600'>Daily Activity Targets</Typography>
        <div className='flex-grow-1'/>
        {!pastWeek && <Switch color='primary' edge='end' className='my-n12' disabled={disabled} checked={required} onChange={toggle}/>}
      </div>
      <WCACInput required={required} disabled={disabled} controller={controller} value={steps} unit='steps'/>
      <WCACInput required={required} disabled={disabled} controller={controller} value={distance} unit='distance'/>
      <WCACInput required={required} disabled={disabled} controller={controller} value={flights} unit='flights'/>
      <WCACInput required={required} disabled={disabled} controller={controller} value={energy} unit='energy'/>
    </div>
  );
};

const RHS = () => {
  return (
    <>
    <Nutrition/>
    <Nutrition supplement/>
    <Water/>
    <Activity/>
    </>
  );
};

export const WCACInput = ({ required, disabled, value, unit, handleChange,error }) => {
  const { convertor } = useContext(Conversion);
  const [val, setVal] = useState(0);
  const onChange = (e) => {
    let v = e.target.value;
    if (v === '.') v = '0.';
    const numDecimals = convertor.getNumDecimals(unit);
    if (countDecimals() > numDecimals) return;
    let value = convertor.getDisplayValue('', v, numDecimals);
    setVal(value);
    const dbvalue = convertor.getDBValue(unit, v);
    handleChange(dbvalue,unit);
  };
  // useEffect(()=>{
  //   if(disabled){
  //     onChange({
  //       target:{
  //         value:0,
  //       }
  //     })
  //   }
  // },[disabled])
  let suffix = unit;
  if (suffix === 'energy') suffix = 'cals';
  if (suffix === 'flight') suffix = 'floors';
  return (
      <TextField variant='outlined' disabled={disabled} onChange={onChange}
      value={val || convertor.getDisplayValue(unit, value) || ''} fullWidth
      InputProps={{ classes: { root: 'medium', input: 'size_16_500 medium text-black' },
        endAdornment: <InputAdornment position='end'>{convertor.getDisplayUnit(suffix)}</InputAdornment>}} 
      error={!!error} helperText={error}/>
  );
}

export default RHS;
