import React from "react";
import {
  Typography
} from "@material-ui/core";
import { roundNumber } from "fitbud/utils/helpers";

const MacrosList = (props) => {
  const { data, fontSize } = props;
  return (
    <div className="d-flex fmt-5 justify-content-between" style={{width: '95%'}}>
      {["protein", "fat", "carbs"].map((item, index) => {
        return (
          <div key={index} className="d-flex flex-column fmr-10">
            <div>
              <Typography
                variant="body1"
                className="font_11_500 text-capitalize"
                color="textSecondary"
              >
                {item}
              </Typography>
              <Typography variant="body1" className="font_13_600">
                {roundNumber(data[item])}&nbsp;<span className="font_11_500">g</span>
              </Typography>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default MacrosList;
