import TagFilter from "./tag-filter";
export const EXERCISE_FILTER_CONFIG = [
  {
    label: 'Type of Exercise',
    type: 'type',
    options: [
      {
        label: 'Type',
        subType: 'category',
        isComponent:true,
        Component:TagFilter,
        tag:"exCats"
      },
      {
        label: 'Tracking Fields ',
        subType: 'extype',
        subOptions: [
          {
            label: 'Duration',
            value: 'duration',
          },
          {
            label: 'Repetitions',
            value: 'reps',
          },
          {
            label: 'Repetitions With Weight',
            value: 'reps_w_weight',
          },
        ],
      },
      {
        label: 'Source',
        subType: 'source',
        subOptions: [
          {
            label: 'Fitbudd Library',
            value: 'fitbudd',
          },
          {
            label: 'My Library',
            value: 'custom',
          },
          {
            label: 'Fitbudd Customized',
            value: 'fitbudd_custom',
          },
        ],
      },
      {
        label: 'Has Instructions to perform',
        subType: 'has_instructions',
        filterType: "single_select",
        subOptions: [
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          },
        ],
      },
    ],
  },
  {
    label:"Equipment",
    type:"equipments",
    isComponent:true,
    Component:TagFilter,
    tag:"equipments",
    subType:"equipments",
    searchPlaceholder:"Search by Equipment"
  },
  {
    label:"Target Area",
    type:"bodyParts",
    isComponent:true,
    Component:TagFilter,
    tag:"targetAreas",
    subType:"body_parts",
    searchPlaceholder:"Search by Target Area"

  },
  {
    label:"Muscle Groups ",
    type:"muscleGroups",
    isComponent:true,
    Component:TagFilter,
    tag:"muscleGroups",
    subType:"muscle_groups",
    searchPlaceholder:"Search by Muscle Group"

  },
];


export const WORKOUT_FILTER_CONFIG = [
  {
    label: 'Type Of Workout',
    type: 'type',
    options: [
      {
        label: 'Type ',
        subType: 'sub_type',
        isComponent:true,
        tag:"woSubTypes",
        Component:TagFilter,
      },
      {
        label: 'Intensity ',
        subType: 'intensity',
        subOptions: [
          {
            label: 'High Intensity',
            value: 'high',
          },
          {
            label: 'Moderate Intensity',
            value: 'moderate',
          },
          {
            label: 'Low Intensity',
            value: 'low',
          },
        ],
      },
      {
        label: 'Instruction Type ',
        subType: 'wotype',
        subOptions: [
          {
            label: 'Follow Along',
            value: 'follow_along',
          },
          
          {
            label: 'Structured',
            value: 'structured',
          },
        ],
      },
      {
        label: 'Duration ',
        subType: 'duration',
        subOptions: [
          {
            label: '0 - 20 mins',
            value: 'gte:0-lte:1200',
          },
          
          {
            label: '21 - 40 mins',
            value: 'gte:1201-lte:2400',
          },
          {
            label: '41 - 60 mins',
            value: 'gte:2401-lte:3600',
          },
          {
            label: '> 1hr',
            value: 'gte:3601',
          },
        ],
      },
    ],
  },
  {
    label:"Target Area",
    type:"target_area",
    isComponent:true,
    Component:TagFilter,
    tag:"targetAreas",
    subType:"target_area"
  },
  {
    label:"Equipment",
    type:"equipments",
    isComponent:true,
    Component:TagFilter,
    tag:"equipments",
    subType:"equipments"
  },
];
export const CARDIO_FILTER_CONFIG = [
  {
    label: 'Cardio Type ',
    subType: 'sub_type',
    subOptions: [
      {
        label: 'Low Intensity',
        value: 'liss',
      },
      {
        label: 'High Intensity',
        value: 'hiit',
      },
    ],
  },
  {
    label: 'Duration ',
    subType: 'duration',
    subOptions: [
      {
        label: '0 - 20 mins',
        value: 'gte:0-lte:1200',
      },
      
      {
        label: '20 - 40 mins',
        value: 'gte:1201-lte:2400',
      },
      {
        label: '40 - 60 mins',
        value: 'gte:2401-lte:3600',
      },
      {
        label: '> 1hr',
        value: 'gte:3601',
      },
    ],
  },
];

export const WORKOUT_CARDIO_FILTER_CONFIG = [
  {
    label: 'Type Of Workout',
    type: 'type',
    options: [
      {
        label: 'Type ',
        subType: 'sub_type',
        isComponent:true,
        tag:"woSubTypes",
        Component:TagFilter,
        append: [ 
          {
            label: 'Cardio (High Intensity)',
            value: 'hiit',
          },{
            label: 'Cardio (Low Intensity)',
            value: 'liss',
          }
        ],
      },
      {
        label: 'Intensity ',
        subType: 'intensity',
        subOptions: [
          {
            label: 'High Intensity',
            value: 'high',
          },
          {
            label: 'Moderate Intensity',
            value: 'moderate',
          },
          {
            label: 'Low Intensity',
            value: 'low',
          },
        ],
      },
      {
        label: 'Instruction Type ',
        subType: 'wotype',
        subOptions: [
          {
            label: 'Follow Along',
            value: 'follow_along',
          },
          
          {
            label: 'Structured',
            value: 'structured',
          },
        ],
      },
      {
        label: 'Duration ',
        subType: 'duration',
        subOptions: [
          {
            label: '0 - 20 mins',
            value: 'gte:0-lte:1200',
          },
          
          {
            label: '21 - 40 mins',
            value: 'gte:1201-lte:2400',
          },
          {
            label: '41 - 60 mins',
            value: 'gte:2401-lte:3600',
          },
          {
            label: '> 1hr',
            value: 'gte:3601',
          },
        ],
      },
    ],
  },
  {
    label:"Target Area",
    type:"target_area",
    isComponent:true,
    Component:TagFilter,
    tag:"targetAreas",
    subType:"target_area"
  },
  {
    label:"Equipment",
    type:"equipments",
    isComponent:true,
    Component:TagFilter,
    tag:"equipments",
    subType:"equipments"
  },
]
