import React, { useContext, useMemo } from 'react';
import { Typography, Dialog } from '@material-ui/core';
import { ClrdButton } from 'fitbud/components/form-fields';
import successGif from 'fitbud/images/success.gif';
import _ from 'lodash';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';

const SuccessUI = (props) => {
  const { handleClose, reload, isYearly, isProductPurchase, product, selectedAddOns, addOnFeatures } = props;
  const { refreshco } = useContext(FirebaseAuthContext);
  const onClose = async () => {
    await reload();
    await refreshco();
    handleClose();
  };
  const message = useMemo(() => {
    const addOnNames = [];
    selectedAddOns &&
      selectedAddOns.forEach((addOnId) => {
        const find = _.find(addOnFeatures, (addOn) => addOn.id === addOnId);
        addOnNames.push(find.name);
      });

    if (isProductPurchase) {
      const name = _.get(product, 'metadata.display_name', '');
      return {
        title: (
          <span>
            Congratulations! Your <span>{name}</span> Plan is ready.
            {!!addOnNames.length && (
              <span>
                <br /> Active add ons : {addOnNames.join(',')}
              </span>
            )}
          </span>
        ),
        subTitle: `Next steps? Shape your app as you shape lives—customize seamlessly, launch immediately, and boost client engagement. Excited to see your fitness brand thrive! 💪`,
      };
    } else {
      //if add on purchase...
      return {
        title: `${addOnNames.join(',')} Activated`,
        subTitle: `Congrats! Your ${addOnNames.join(
          ','
        )} is now active. Go on and try out your new add-on and further enhance the coaching experience for your clients.`,
      };
    }
  }, [product, isProductPurchase, isYearly, selectedAddOns, addOnFeatures]);
  return (
    <Dialog open classes={{ paper: 'width-400 height-60' }}>
      <div
        style={{ flex: 1, padding: '40px' }}
        className="d-flex flex-column align-items-center justify-content-center">
        <img src={successGif} alt="" style={{ marginBottom: '35px', height: '120px', width: '120px' }} />
        <Typography className="font_24_700 text-center font-weight-700 fmb-20" style={{ lineHeight: '34px' }}>
          {message.title}
        </Typography>
        <Typography className=" font_15_500 text-center text-dark-grey fmb-30" style={{ lineHeight: '22px' }}>
          {message.subTitle}
        </Typography>
        <ClrdButton color="main" className="f-xxxlarge" onClick={onClose}>
          <Typography className="font_15_700">Continue</Typography>
        </ClrdButton>
      </div>
    </Dialog>
  );
};

export default SuccessUI;
