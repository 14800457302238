import React, {useMemo} from "react";
import {
  FormControlLabel,
  Typography,
  Checkbox,
} from "@material-ui/core";
import _ from 'lodash';
import { ATTRIBUTE_FILTER_MAP } from "fitbud/utils/constants";

const AttributeFilter = (props) =>{
  const { componentState: state = {}, dispatch, type } = props;
  const handleChange  = (e) => {
    const {name,value,checked}=e.target;
    let newValue=value;
    if(name==='gender'){
      //array
      const oldValues=_.get(state,[name],[])||[];
      newValue=checked?[...oldValues,value]:oldValues.filter(i=>i!==value);
    }
    dispatch({ type,payload: { ...state, [name]: newValue }});
  }
  return (
      <div className="py-10">
        {ATTRIBUTE_FILTER_MAP.map(i=>(<>
          <Typography key={i.value} className="font_15_500 mb-10">{i.label}</Typography>
          <div className="d-flex">
            {i.options.map(j=>(<CheckBoxComp stateValue={state.gender} name={i.value } label={j.label} 
              value={j.value} onChange={handleChange} />
            ))}
          </div>
        </>))}
      </div>
  )
}

const CheckBoxComp = ({name,label,value,onChange,stateValue}) =>{
  const checked=useMemo(()=>{
    return !!(stateValue||[]).includes(value);
  },[stateValue])
  return(
    <>
      <FormControlLabel
        control={<Checkbox checked={checked} name={name} color="primary" value={value} onClick={onChange}/>}
        label={<Typography key={value} className="font_14_600">{label}</Typography>}
      />
    </>
  )
}

export default AttributeFilter;