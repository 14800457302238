import _ from 'lodash';
import uuid from 'uuid/v4';
import moment from 'moment';
import update from 'immutability-helper';
import BMRCalc from 'fitbits/bmr';
const { DEFAULT_PFC, DEFAULT_MEAL_GRPS } = require('fitbits/bmr/constants');

const weekDates = (week, startDate) => Array(7).fill().map((x, n) => moment(startDate, 'YYYYMMDD').add(7 * week + n, 'd'));
const changeWeek = (week, state, startDate, plan) =>{
  const s=update(state, {
    weekReady: {$set: false},
    currWeek: {$set: week},
    currWeekId: {$set: plan.weeks[week]},
    dates: {$set: weekDates(week, startDate)}});
  return s;
};
const publishedTill = (remote, lookAheadWeeks, weekToday) => {
  if (!remote) return 0;
  if (!remote.plan) return 0;
  if (remote.plan.plan_state !== 'activated') return 0;
  if (typeof weekToday !== 'number') return 0;
  return weekToday + lookAheadWeeks;
};
const emm = (src) => {
  const {_m, wom, mlm, slm} = _.get(src, 'remote.plan') || {};
  if (!_m && _.isEmpty(wom) && _.isEmpty(mlm) && _.isEmpty(slm))
    return {needed: true, isDone: false};
  return {needed: false, isDone: true};
};

const calcBMRMEal = (cid, {mode, gender, dob, weight, height, bmr, responses, p, f, c, groups, sort_by, overrides}) => {
  const activity = (overrides && overrides.activity) || responses.activity;
  const q3 = (overrides && overrides.diet) || responses.diet;
  const calsOverride = (overrides && overrides.cals) || 0;
  const goal = calsOverride ? {meta: false} : ((overrides && overrides.goal) || responses.goal);
  try {
    const calc = new BMRCalc(gender, dob, weight, height, groups && groups.map(x => x.type), calsOverride, { cid, mode });
    return calc.getTargetFromMeta(activity.meta || {}, {p, f, c, ...goal.meta}, null, { calsOverride, q3 });
  } catch (e) {
    return null;
  }
};

/**
 * State Structure:
 * - ready    // false by default
 * - aplan // id of the plan object in DB
 * - remote:  // firebase data. Read Only. Only updated at save
 *   - xx_master (x5)
 *   - data
 * - local:   // local RW copy of firebase data. Only populated during edit and null'd at save
 *   - xx_master (x5) // copied from remote.xx_master when edit starts
 *   - update `sparse objectstarts as an empty object and get
 * - display: // flags related to display state
 *   - week   // currently visible week, 1 based
 *   - weekready // false by default
 *   - weekData:
 *     - woSrc: { ref, offset } , false if entire week not linked
 *     - mlSrc: same
 *     - d1:
 *       - woOverride // true if day is overriden, false otherwise
 *       - mlOverride // true if day is overriden & per day mode in master, false otherwise
 *       - xx (x5)    // actual data, not references
 * */

const reducer = (state, action) => {
  const { cid, editing, weekToday, lookAheadWeeks, currWeek, startDate, remote, local } = state;
  const currentSrc = editing ? local : remote;
  const currentDst = editing ? 'local' : 'remote';
  const { type, comp, bmrMode, ...rest } = action;
  if (comp) {
    const ftAltWOs = _.get(comp, 'app_config.tags.altwo', false);
    const lookAheadWeeks = _.get(comp, 'app_config.week_threshold_limit', 1);
    let grps = DEFAULT_MEAL_GRPS;
    const mlcatovrs = _.get(comp, 'app_config.tags.mealCategories');
    if (mlcatovrs) {
      const tmp = _.chain(mlcatovrs).toPairs().filter(x => x[1].isDefault).sortBy('1.order').map('[0]').value();
      if (tmp && tmp.length) grps = tmp;
    }
    const defaultMlGrps = grps.map((type, index) => ({type, order: index + 1, calories: 100 / grps.length}));
    return update(state, {
      ftAltWOs: {$set: ftAltWOs},
      lookAheadWeeks: {$set: lookAheadWeeks},
      defaultMlGrps: {$set: defaultMlGrps},
    });
  }
  const wx = 'w' + rest.week;
  const dy = 'd' + rest.day;
  switch (type) {
    case 'bmr':
      const _bmrMode = bmrMode || _.get(comp, 'features.bmr');
      return update(state, {
        [`bmr${currentDst}`]: (x) => {
          if (rest.disabled) return { disabled: true };
          const _in = x || { ...DEFAULT_PFC, groups: state.defaultMlGrps };
          const out = {..._in, ...rest, mode: _bmrMode};
          out.meal = calcBMRMEal(cid, out);
          if(out && !out.overrides && _.get(out, 'responses.goal.meta')) {
            if (!_.isNil(_.get(out, 'responses.goal.meta.p')) && !_.isNil(_.get(out, 'responses.goal.meta.f')) && !_.isNil(_.get(out, 'responses.goal.meta.c'))) {
              out.p = out.responses.goal.meta.p
              out.f = out.responses.goal.meta.f
              out.c = out.responses.goal.meta.c
            }
          }
          return out;
        }});
    case 'init':{
      
      return update(state, {
        startDate: {$set: rest.remote.plan.startDate},
        dayToday: {$set: rest.dayToday},
        weekToday: {$set: rest.week}, // on init, today week is same as curr week
        weekPublished: x => publishedTill(rest.remote, lookAheadWeeks, rest.week),
        aplan: {$set: rest.aplan},
        ready: {$set: true},
        weekReady: {$set: false},
        currWeek: {$set: rest.week},
        currWeekId: {$set: rest.weekId},
        dates: {$set: weekDates(rest.week, rest.remote.plan.startDate)},
        remote: {$set: rest.remote},
        local: {$set: {plan: {}, weeks: {}, woms: [], mlms: [], slms: []}},
        emm: () => emm(rest),
        buildCmd: {$set: null},
        editing: {$set: false},
        block: {$set: false},
        opimport: {$set: false},
        opcopy: {$set: false},
        opdelete: {$set: false},
        opaddwks: {$set: false},
        opconfirm: {$set: false},
        opmngalt: {$set: false}});
    }
    case 'dobuild':{
      const s= update(state, {
        startDate: {$set: rest.startDate},
        dayToday: {$set: rest.dayToday},
        weekToday: {$set: rest.week}, // on init, today week is same as curr week
        ready: {$set: true},
        weekReady: {$set: false},
        currWeek: {$set: rest.week},
        currWeekId: {$set: rest.weekId},
        dates: {$set: weekDates(rest.week, rest.startDate)},
        buildCmd: {$set: rest.buildCmd},
        local: {$set: rest.local},
        emm: {$set: {needed: false, isDone: true}}, // new plan is always migrated
        dfrdOvrs: {$set: {workouts: [], meals: []}}, 
        bmrlocal: {$set: state.bmrremote},
        editing: {$set: true},
        reassign: {$set: false},
        block: {$set: false},
        opimport: {$set: false},
        opcopy: {$set: false},
        opdelete: {$set: false},
        opaddwks: {$set: false},
        opconfirm: {$set: false},
        opmngalt: {$set: false}});
      return s;
    }
    case 'reset':{
      return update(state, {
        buildCmd: {$set: null}, // unset the build CMD which prompted a reset scenario to be invoked
        startDate: {$set: rest.startDate},
        dayToday: {$set: rest.dayToday},
        weekToday: {$set: rest.week},
        weekPublished: x => publishedTill(remote, lookAheadWeeks, rest.week),
        weekReady: {$set: false}, // force reload of the week
        currWeek: {$set: rest.week},
        currWeekId: {$set: rest.weekId},
        dates: {$set: weekDates(rest.week, rest.startDate)},
        local: {$set: {plan: {}, weeks: {}, woms: [], mlms: [], slms: []}},
        showCardDetails: { $set: null },
        emm: () => emm(state),
        bmrlocal: {$set: state.bmrremote},
        editing: {$set: false},
        block: {$set: false},
        opimport: {$set: false},
        opcopy: {$set: false},
        opdelete: {$set: false},
        opaddwks: {$set: false},
        opconfirm: {$set: false},
        opmngalt: {$set: false}});
    }
    case 'week.load':
      return changeWeek(rest.x, state, startDate, currentSrc.plan);
    case 'week.load.curr':{
      return changeWeek(weekToday, state, startDate, currentSrc.plan);
    }
    case 'week.load.prev':
      return changeWeek(Math.max(0, currWeek - 1), state, startDate, currentSrc.plan);
    case 'week.load.next':
      return changeWeek(currWeek + 1, state, startDate, currentSrc.plan);
    case 'week.ready':{
      return update(state, {
        weekReady: {$set: true},
        emm: {isDone: (x) => (editing ? true : x)},
        [currentDst]: {weeks: {[rest.weekId]: {$set: rest.data}}}});
      }
    case 'legacy':{
      return update(state, {
        weekReady: {$set: true},
        emm: {isDone: {$set: true}},
        dfrdOvrs: (x) => (rest.dfrdOvrs || x),
        schCache: {$set: rest.schCache},
        objCache: (x) => {
          let out = rest.schCache || {};
          if (rest.dfrdOvrs.workouts)
            rest.dfrdOvrs.workouts.forEach(x => (out[x.id] = x));
          if (rest.dfrdOvrs.meals)
            rest.dfrdOvrs.meals.forEach(x => (out[x.id] = x));
          return out;
        },
        local: {
          woms: (x) => (rest.woms || x),
          mlms: {$set: rest.mlms},
          slms: {$set: rest.slms},
          weeks: {$merge: rest.weeksData},
          plan: {
            data: {$set: rest.overrides},
            wc: {$set: rest.wc},
            ac: {$set: rest.ac}}}});
        }
    // case 'toggle.wcac':
    //   return update(state, {
    //     local: {plan: {[rest.key]: (x) => {
    //       const required = !x ? true : !x.required;
    //       const value = !x || !x.value ? (rest.key === 'wc' ? 0 : {}) : x.value;
    //       return {required, value};
    //     }}}});
    case 'set.water':{
      return update(state,{
        local:{plan:{
          wc:{
            $set:rest.value
          }
        }}
      })
    }
    case 'set.activity':{
      return update(state,{
        local:{plan:{
          ac:{
            $set:rest.value
          }
        }}
      })
    }
    case 'set.masters':{
      const x=update(state, {
        local: {
          [rest.key]: {$set: rest.masters},
          plan: {data: {$apply: (x) => {
            if (!x || !rest.chngData) return x;
            const { s, d = 0, other } = rest.chngData;
            const startWeek = (1 + Math.floor(s / 7));
            const endWeek = d >= 7 ? (1 + Math.floor((s + d) / 7)) : false;
            const endDayInLastWeek = 'd' + (1 + (s % 7));
            const out = {};
            _.each(x, (week, wx) => {
              if (!week || _.isEmpty(week)) return;
              const w = Number(wx.replace(/\D/, ''));
              if (w >= startWeek && (!endWeek || w < endWeek)) {
                const inner = {};
                _.each(week, (day, dy) => {
                  if (!day || _.isEmpty(day)) return;
                  //NOTE:Made change here , schedule shall be applied from selected date and not available date in that week
                  if (w === startWeek && dy < endDayInLastWeek) { // retain as is
                    inner[dy] = day;
                    return;
                  }
                  // for the rest only keep the other key
                  (other||[]).forEach(i=>{
                    const otherVal = day[i];
                    if (!otherVal) return;
                    inner[dy] = {
                      ...(inner[dy]||{}),
                      [i]: otherVal
                    };
                  });
                });
                if (!_.isEmpty(inner)) out[wx] = inner;
              } else {
                out[wx] = week; // previous weeks don't get impacted
              }
            });
            return out;
          }}}},
        // opimport: {$set: false}, need back-to-back omimport popups
        weekReady: {$set: false}}); // make the week load all over again to capture new master
      return x;
    }
    case 'set.wb':
      return update(state, {local: { // update wo in plan.data and week.data.dN.wb simultaneously
        plan: {data: (x) => {
          if (!x) return {[wx]: {[dy]: {'wo': rest.value}}};
          if (!x[wx]) return {...x, [wx]: {[dy]: {'wo': rest.value}}};
          if (!x[wx][dy]) return {...x, [wx]: {...x[wx], [dy]: {'wo': rest.value}}};
          return {...x, [wx]: {...x[wx], [dy]: {...x[wx][dy], 'wo': rest.value}}};
        }},
        ...(local.weeks && local.weeks[rest.weekId] ? {weeks: {[rest.weekId]: {data: {[dy]: {wb: {$set: rest.value}}}}}} : {})}});
    case 'set.mb':
      return update(state, {local: { // update ml in plan.data and week.data.dN.mb simultaneously
        plan: {data: (x) => {
          if (!x) return {[wx]: {[dy]: {'ml': rest.value}}};
          if (!x[wx]) return {...x, [wx]: {[dy]: {'ml': rest.value}}};
          if (!x[wx][dy]) return {...x, [wx]: {...x[wx], [dy]: {'ml': rest.value}}};
          return {...x, [wx]: {...x[wx], [dy]: {...x[wx][dy], 'ml': rest.value}}};
        }},
        ...(local.weeks && local.weeks[rest.weekId] ?
          ({weeks: {[rest.weekId]: {
            data: 
            {[dy]: {
              mb: {
                $set: {onoff: false, single: rest.value}
                }
              }
            }
          }}} ):
          {})}}
          );
    
     case "set.sb":
      return update(state, {local: { // update sl in plan.data and week.data.dN.sb simultaneously
        plan: {data: (x) => {
          if (!x) return {[wx]: {[dy]: {'sl': rest.value}}};
          if (!x[wx]) return {...x, [wx]: {[dy]: {'sl': rest.value}}};
          if (!x[wx][dy]) return {...x, [wx]: {...x[wx], [dy]: {'sl': rest.value}}};
          return {...x, [wx]: {...x[wx], [dy]: {...x[wx][dy], 'sl': rest.value}}};
        }},
        ...(local.weeks && local.weeks[rest.weekId] ?
          ({weeks: {[rest.weekId]: {
            data: 
            {[dy]: {
              sb: {
                $set: {onoff: false, single: rest.value}
                }
              }
            }
          }}} ):
          {})}}
          );
    case 'rm.override':{
      return update(state, {local: { // update wo in plan.data and week.data.dN.wb simultaneously
        plan: {data: {[wx]: {[dy]: {$unset: [rest.key ==='ml' ? 'ml' : (rest.key==='sl'?'sl':'wo')]}}}},
        weeks: {[rest.weekId]: {data: {[dy]: {$unset: [rest.key==='ml' ? 'mb' : (rest.key=== 'sl' ? 'sb' : 'wb')]}}}}}});
    }
    case 'opaddwks.start': return update(state, {opaddwks: {$set: true}});
    case 'opaddwks.close': return update(state, {opaddwks: {$set: false}});
    case 'opaddwks.process':
      if (!editing) return state;
      return update(state, {
        local: {plan: {weeks: {$push: Array(rest.n).fill().map(() => uuid())}}},
        opaddwks: {$set: false},
        dayToday:{$set:rest.dayToday},
        weekToday:{$set:rest.week}
      });
    case 'opcopy.start': {
      return update(state, {opcopy: (x) => {
        const week = _.get(local, ['plan', 'weeks'], []).indexOf(rest.weekId);
        if (week < 0) return false;
        rest.week = week;
        return rest;
      }});
    }
    case 'opcopy.close': return update(state, {opcopy: {$set: false}});
    case 'opimport.start': return update(state, {opimport: {$set: rest}});
    case 'opimport.close': return update(state, {opimport: {$set: false}});
    case 'opimport.process':
      return state;
    case 'opmngalt.start': return update(state, {opmngalt: {$set: rest}});
    case 'opmngalt.close': return update(state, {opmngalt: {$set: false}});
    case 'opconfirm.start': return update(state, {opconfirm: {$set: rest}});
    case 'opconfirm.close': return update(state, {opconfirm: {$set: false}});
    case 'edit':{
      let _local=_.chain(remote).omit('weeks').set('weeks', {}).value();
      // if(!_.get(_local,'plan.wams')){
      //  _local= _.chain(_local).set('plan.wams',[{s:state.dayToday,value:_local.plan.wc.value}]).value();
      // }
      
      const s= update(state, {
        // XXX we aren't cloning weeks, coz weeks are V.V.Heavy objects, better to just fetch afresh
        // local: {$set: _.chain(remote).omit('weeks').set('weeks', {}).value()},
        local: {$set:_local},
        bmrlocal: {$set: state.bmrremote},
        weekReady: {$set: false},
        editing: {$set: true},
        block: {$set: false},
        opimport: {$set: false},
        opcopy: {$set: false},
        opdelete: {$set: false},
        opaddwks: {$set: false},
        opconfirm: {$set: false},
        opmngalt: {$set: false}});
      return s;
    }
      
    case 'edit.cancel':
      return update(state, {
        local: {$set: {plan: {}, weeks: {}, woms: [], mlms: [], slms: []}},
        showCardDetails: { $set: null },
        emm: () => emm(state),
        bmrlocal: {$set: null},
        currWeek: x => {
          const { weeks } = (remote && remote.plan) || {};
          if (x < 0 || !weeks || !weeks.length) return 0;
          if (x < weeks.length) return x;
          return weeks.length - 1;
        },
        currWeekId: x => {
          const { weeks } = (remote && remote.plan) || {};
          if (!weeks || !weeks.length) return '';
          if (currWeek < 0) return weeks[0];
          if (currWeek < weeks.length) return x;
          return weeks[weeks.length - 1];
        },
        dayToday: {$set:rest.dayToday},
        weekToday: {$set:rest.week},
        weekReady: {$set: false},
        buildCmd: {$set: null},
        editing: {$set: false},
        block: {$set: false},
        opimport: {$set: false},
        opcopy: {$set: false},
        opdelete: {$set: false},
        opaddwks: {$set: false},
        opconfirm: {$set: false},
        opmngalt: {$set: false}});
    case 'apply':
      return update(state, {
        // XXX unlike edit where we started from scratch, here we're not. Coz it ain't a clone
        // but if we just migrated, better to clear the cached weeks as well
        remote: (x) => {
          const plan=_.merge({},local.plan,rest.plan);
          return (state.emm.needed ?
             _.chain(local).omit('weeks', 'deactivated').set('weeks', {}).set('plan',plan).value() : 
             _.chain(local).set('plan',plan).value())
        },
        // if we just migrated, better to clear all caches and fetch afresh
        schCache: (x) => (state.emm.needed ? {} : x),
        objCache: (x) => (state.emm.needed ? {} : x),
        bmrremote: {$set: state.bmrlocal},
        local: {$set: {plan: {}, weeks: {}, woms: [], mlms: [], slms: []}},
        showCardDetails: { $set: null },
        emm: {$set: {needed: false, isDone: true}}, // saved plan is always migrated
        dfrdOvrs: {$set: {workouts: [], meals: []}}, 
        bmrlocal: {$set: null},
        weekReady: {$set: false},
        buildCmd: {$set: null},
        editing: {$set: false},
        block: {$set: false},
        opimport: {$set: false},
        opcopy: {$set: false},
        opdelete: {$set: false},
        opaddwks: {$set: false},
        opconfirm: {$set: false},
        opmngalt: {$set: false}});
    case 'block':
      return update(state, {block: {$set: true}});
    case 'unblock':
      return update(state, {block: {$set: false}});
    case 'halt':
      return update(state, {halt: {$set: true}});
    case 'unhalt':
      return update(state, {halt: {$set: false}});
    case 'reassign.open':
      return update(state, {reassign: {$set: rest.data}});
    case 'reassign.close':
      return update(state, {reassign: {$set: false}});
    case 'set':{
      return update(state, {local: rest.op, // op is expected to be a fn that takes in local and returns modified local
        weekReady: {$set: true},
        halt: {$set: false},
        block: {$set: false}});}
    case 'detail.set':
      return update(state, { showCardDetails: { $set: rest.data } });
    case 'objcache.set':{
      return update(state, { objCache: { [rest.id] : { $set: rest.data }, _uat: { $set: Date.now() } } });
    }
        
    case '___': // directly applies `rest` on state
      return update(state, rest);
    default:
      return state;
  }
};

export default reducer;
