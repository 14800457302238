import { Button, IconButton } from '@material-ui/core';
import AudioPlayer from 'fitbud/components/Audio';
import Dialog from "fitbud/components/Dialog";
import send_icon from "fitbud/images/send_icon.svg";
import React, { useCallback, useEffect, useMemo } from 'react';
import RecorderControls from './recorder-controls';
import useRecorder from './useRecorder';

const AudioRecorder = ({
  onSave, onChange, onClose, open, hideAction,
  ...props
}) => {
  const { recorderState, ...handlers } = useRecorder();
  const { audio } = recorderState;
  const file_url = useMemo(() => audio && audio.file ? URL.createObjectURL(audio.file) : false, [audio] ) 
  const { deleteAudio } = handlers;

  useEffect(()=> {
    onChange(audio);
  },[audio, onChange]);

  const handleClose = async(e) => {
    e.preventDefault()
    if(recorderState.initRecording) await deleteAudio();
    onClose();
  }

  const actionButton = () => !hideAction ? (
    <IconButton
      color="primary"
      className={"fml-20 p-12"}
      classes={{root: "bg-primary"}}
      aria-label="send"
      onClick={onSave}
    >
      <img src={send_icon} alt="sendIcon" />
    </IconButton>
  ): <div className='fpx-10'></div>;

  if(!open) return null;
  return (
    <Dialog 
      open={open} 
      onClose={handleClose} 
      maxWidth='xs' 
      title="Voice Message"
      fullWidth
      onSave={onSave}
      headerActions={actionButton}
    >
      <div className="mx-auto fpx-10 py-30">
        {(!audio || !audio.file) && !file_url ? (
          // Audio Recorder
          <RecorderControls recorderState={recorderState} handlers={handlers} />
        ) : (
          // Audio Preview Player
          <div className='w-100 d-flex flex-column'>
            <AudioPlayer src={file_url} duration={audio.duration} />
            <Button onClick={deleteAudio} className='mx-auto fmt-10' color='primary'>
              Delete
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  )
}
export default AudioRecorder;