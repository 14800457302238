import React from 'react';
import { Images } from '../subComponents';
import _ from "lodash";
import Slider from './slider';
import usePoses from 'fitbud/hooks/usePoses';

const ProgressImages = (props) => {
  const { checkInsLeft, checkInsRight, docId, compChkinConf, userChkinConf } = props;
  const poses = usePoses(compChkinConf, userChkinConf, checkInsLeft, checkInsRight);

  // Make new_identifier for each side to eliminate null images and then feed to url logic
  const new_identifier_left = poses.reduce((prev, curr, idx) => {
    const side_identifier = !!checkInsLeft && _.get(checkInsLeft, `photos.${curr}`, false)
    ? (_.get(checkInsLeft, `photos.${curr}.identifier`, "") || _.get(checkInsLeft, "photos.identifier", "")) : false ;
    if(side_identifier) return prev.concat(idx  > 0 ? '%' : '', `${curr}_${side_identifier}`);
    return prev;
  }, '');
  const new_identifier_right = poses.reduce((prev, curr, idx) => {
    const side_identifier = !!checkInsRight && _.get(checkInsRight, `photos.${curr}`, false)
    ? (_.get(checkInsRight, `photos.${curr}.identifier`, "") || _.get(checkInsRight, "photos.identifier", "")) : false ;
    if(side_identifier) return prev.concat(idx  > 0 ? '%' : '', `${curr}_${side_identifier}`);
    return prev;
  }, '');
  const defaultAspect = _.get(checkInsLeft, "photos.aspect", _.get(checkInsRight, "photos.aspect", 1));
  return (
    <Slider> 
      {poses.map((i, j) => {
        const leftIdentifier = checkInsLeft && (_.get(checkInsLeft, `photos.${i}.identifier`, "") || _.get(checkInsLeft, "photos.identifier", ""));
        const rightIdentifier = checkInsRight && (_.get(checkInsRight, `photos.${i}.identifier`, "") || _.get(checkInsRight, "photos.identifier", ""));
        if(!_.get(checkInsLeft, `photos.${i}`, false) && !_.get(checkInsRight, `photos.${i}`, false)) return null;
        return (
          <div className='d-flex justify-content-between' key={i}>
            <div className='w-50 flex-grow-1'>
              <Images
                side={i}
                key={`${i}_${leftIdentifier}}`}
                img={checkInsLeft && _.get(checkInsLeft, `photos[${i}]`, {})}
                userId={docId}
                identifier={`${i}_${leftIdentifier}`}
                aspect={checkInsLeft && _.get(checkInsLeft, "photos.aspect", defaultAspect)}
                position={j}
                new_identifier={new_identifier_left}
                imageStyles={{borderRadius: '15px 0px 0px 15px'}}
              />
            </div>
            <div style={{ borderRight: "1px solid #FFF" }}></div>
            <div className='w-50'>
              <Images
                side={i}
                key={`${i}_${rightIdentifier}`}
                img={checkInsRight && _.get(checkInsRight, `photos[${i}]`, {})}
                userId={docId}
                identifier={`${i}_${rightIdentifier}`}
                new_identifier={new_identifier_right}
                aspect={checkInsRight && _.get(checkInsRight, "photos.aspect", defaultAspect)}
                position={j}
                imageStyles={{borderRadius: '0px 15px 15px 0px'}}
              />
            </div>
          </div>
      )})}
    </Slider>
  )
}

export default ProgressImages;
