export default collection => ({
  toggle: `${collection}.toggle`,
  set: `${collection}.set`,
  insert: `${collection}.insert`,
  update: `${collection}.update`,
  delete: `${collection}.delete`,
  request: `${collection}.request`,
  searching: `${collection}.searching`,
  searchList: `${collection}.searchList`,
  resetSearch: `${collection}.resetSearch`,
});
