import { MenuItem, OutlinedInput, Select } from '@material-ui/core';
import { FormTextField } from 'fitbud/components/form-fields';
import useTrainers from 'fitbud/hooks/useTrainers';
import FirebaseAuthProvider, { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import React, { useContext, useState } from 'react';
import _ from 'lodash';
import DropDownIcon from '@material-ui/icons/ExpandMore';

const TrainerSelector = (props) => {
  const { value, onChange } = props;
  const { cid, authUser: { uid } = {} } = useContext(FirebaseAuthContext);
  const { data: allTrainers } = useTrainers(cid);
  const [trainerId, setTrainerId] = useState(value);
  const handleChange = (e) => {
    const value = e.target.value;
    setTrainerId(value);
    onChange(value);
  };
  return (
    <FormTextField fullWidth label="Select Trainer">
      <Select
        IconComponent={DropDownIcon}
        input={
          <OutlinedInput
            classes={{
              root: 'medium',
              input: 'size_16_500 select-medium',
            }}
            type="trainer_id"
            value={trainerId}
            onChange={handleChange}
          />
        }>
        {allTrainers.map((trainer) => {
          const tId = _.get(trainer, '_id');
          return (
            <MenuItem key={tId} value={tId}>
              {_.get(trainer, 'data.name')}&nbsp;<span>{tId === uid && <span>(ME)</span>}</span>
            </MenuItem>
          );
        })}
      </Select>
    </FormTextField>
  );
};

export default TrainerSelector;
