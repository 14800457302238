import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { useTheme } from '@material-ui/core/styles';

export const SaveIcon = props => {
  const theme = useTheme();
  let stroke = '#37404D'; // theme.palette.action.active;
  const { color, ...rest } = props;
  if (color) {
    if (theme.palette[color]) stroke = theme.palette[color].main;
    else stroke = color;
  }
  return (
    <SvgIcon {...rest}>
      <path d="M21.6778 8.98889L15.0111 2.32222C14.9087 2.22228 14.788 2.14305 14.6555 2.08889C14.5178 2.03291 14.3709 2.00278 14.2222 2H5.33333C4.44928 2 3.60143 2.35119 2.97631 2.97631C2.35119 3.60143 2 4.44928 2 5.33333V18.6667C2 19.5507 2.35119 20.3986 2.97631 21.0237C3.60143 21.6488 4.44928 22 5.33333 22H18.6667C19.5507 22 20.3986 21.6488 21.0237 21.0237C21.6488 20.3986 22 19.5507 22 18.6667V9.77778C22.0008 9.63155 21.9728 9.48659 21.9175 9.35122C21.8622 9.21584 21.7807 9.09271 21.6778 8.98889ZM8.66666 4.22222H13.1111V6.44444H8.66666V4.22222ZM15.3333 19.7778H8.66666V16.4444C8.66666 16.1498 8.78372 15.8671 8.9921 15.6588C9.20047 15.4504 9.48309 15.3333 9.77777 15.3333H14.2222C14.5169 15.3333 14.7995 15.4504 15.0079 15.6588C15.2163 15.8671 15.3333 16.1498 15.3333 16.4444V19.7778ZM19.7778 18.6667C19.7778 18.9614 19.6607 19.244 19.4523 19.4523C19.244 19.6607 18.9613 19.7778 18.6667 19.7778H17.5555V16.4444C17.5555 15.5604 17.2044 14.7125 16.5792 14.0874C15.9541 13.4623 15.1063 13.1111 14.2222 13.1111H9.77777C8.89372 13.1111 8.04587 13.4623 7.42075 14.0874C6.79563 14.7125 6.44444 15.5604 6.44444 16.4444V19.7778H5.33333C5.03865 19.7778 4.75603 19.6607 4.54766 19.4523C4.33928 19.244 4.22222 18.9614 4.22222 18.6667V5.33333C4.22222 5.03865 4.33928 4.75603 4.54766 4.54766C4.75603 4.33929 5.03865 4.22222 5.33333 4.22222H6.44444V7.55556C6.44444 7.85024 6.5615 8.13286 6.76988 8.34123C6.97825 8.5496 7.26087 8.66667 7.55555 8.66667H14.2222C14.5169 8.66667 14.7995 8.5496 15.0079 8.34123C15.2163 8.13286 15.3333 7.85024 15.3333 7.55556V5.78889L19.7778 10.2333V18.6667Z" fill={stroke}/>
    </SvgIcon>
  );
};

