import React, { useContext } from "react";
import _ from "lodash";
import moment from "moment-timezone";
import {
  ListItem,
  ListItemText,
  ListItemAvatar,
  Badge,
  Typography,
  Tooltip
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import UserList from "fitbud/components/userList";
import { AvatarImage } from "fitbud/views/users/header";
import { isValidURL, getNameAndImage, getEmailFirstLetter, pluralize, getNonBreakingStr } from "fitbud/utils/helpers";
import { oprtnlDateFormat } from "fitbud/utils/constants";
import { durationInTypes, durationTypes } from "fitbud/views/schedulePlanner/helper";
import { isTrialing, getState, getTrialRemainingDays, getStateDisplayText, getSubsDurationType, isSubscriptionUser, isRenewalOverdue } from "fitbud/views/users/helpers"
import PhotoIcon from "@material-ui/icons/Photo";
import VideoIcon from "@material-ui/icons/Videocam";
import AudioIcon from "@material-ui/icons/Audiotrack";
import LinkIcon from "@material-ui/icons/Link";
import clsx from 'clsx';
import { ListStyling } from "fitbud/hooks/useListItemStyle";



const L2 = ({ docData, keyName, className }) => {
  let str = ''
  let timezone = _.get(docData, "time_zone", "UTC") || _.get(docData, "profile.time_zone", "UTC")
  let dateToday = moment.tz(timezone).startOf("day").format(oprtnlDateFormat);
  let startDate = moment(docData.startDate, oprtnlDateFormat);
  let endDate = moment(docData.endDate, oprtnlDateFormat);
  let duration = endDate.diff(startDate, 'days') + 1;
  let durationType = durationTypes(duration, "months")
  const dateDiff = moment(dateToday).diff(startDate, "day");
  let plan_name = _.get(docData, "currPurchase.pack.ref_name") || _.get(docData, "plan_assign.pack.ref_name") || "Custom";
  let week = parseInt(dateDiff / 7) + 1;
  let day = parseInt(dateDiff % 7) + 1;
  const converted_duration = !!duration ? Math.ceil(durationInTypes(duration, durationType)) : void 0;
  let durationStr = isSubscriptionUser(docData) ? getSubsDurationType(docData) : !!converted_duration ? `${converted_duration} ${durationType && _.capitalize(durationType.slice(0, -1))}${pluralize(converted_duration)}` : ''
  let lastChkinTS = (1000 * _.get(docData, "last_checkin_timestamp.seconds", 0));
  let last_checkin = _.upperFirst(moment.duration(moment().diff(moment(lastChkinTS))).humanize(false) + ' ago')
  let userState = getState(docData)
  let deactivatedOn = _.get(docData, "deactivated") ? moment(_.get(docData, "deactivatedOn")).format('DD MMM YYYY') : null;
  let endedOn = _.get(docData, "endDate") ? moment(_.get(docData, "endDate")).format('DD MMM YYYY') : null;

  switch (userState) {
    case "leads":
      str = `Signed Up - ${moment.tz(docData.onboarding_start_date, timezone).format('DD MMM YYYY')}`
      break;
    case "pendingSignIn":
    case "plansToAssign":
      str = `${`${startDate.format('DD MMM')} • `}${getNonBreakingStr(durationStr)} • ${plan_name}`
      break;
    case "active": {
      const p = isRenewalOverdue(docData);
      const r = getTrialRemainingDays(docData);
      if (p) {
        str = <><span style={{ color: "#F14E3F", fontWeight: 700 }}>Payment Overdue</span> • {`${p} day${pluralize(p)}`}</>
      } else if (isTrialing(docData) && r >= 0) {
        str = <><span style={{ color: "#F29900" }}>In-Trial</span>{` • ${r ? `${r} day${pluralize(r)} left until trial ends` : 'Ends today'}`}</>
      } else {
        str = `${dateDiff >= 0 ? `W${week} D${day} • ` : ''}${getNonBreakingStr(durationStr)} • ${plan_name}`
      }
      break;
    }
    case "inactive":
      str = `${deactivatedOn ? 'Deactivated' : 'Ended'} - ${deactivatedOn || endedOn}`
      break;
    default:
      break;
  }
  if(keyName === "checkins" && lastChkinTS) {
    return (
      <span className="d-block">{last_checkin}</span>
    );
  }
  return <>
    <span
      className={className}
      color="textSecondary"
    >
      {str}
    </span>
  </>
}

const styles = makeStyles({
  badge: {
    height: '6px',
    minWidth: '6px !important',
  },
  leads: {
    backgroundColor: "#4089F7",
  },
  // pendingSignIn: {
  //   backgroundColor: "#F2BD00",
  // },
  plansToAssign: {
    backgroundColor: "#FD8847",
  },
  active: {
    backgroundColor: "#05B715",
  },
  inactive: {
    backgroundColor: "#D73717",
  },
  broadcastListItem:{
    padding: '0px 20px',
    height: '60px',
  },
  avatarMedium:{
    height: "60px !important",
    width:"60px !important",
    fontSize:"15px !important"
  },
  avatarBadge: {
    right: 8,
    top: 42,
    boxShadow:'0px 0px 0px 2px #fff',
    height: '8px',
    borderRadius: '100%',
    width: '8px',
  },
  chatCountBadge: {
    root: {
      width: '100px',
      top: 0,
    }
  },
});

export const UserStateIndicator = ({ state, className, userDoc, showTooltip=false, children, classes={} }) => {
  const cls = styles();
  if(userDoc) state = getState(userDoc);
  return (
    <Tooltip title={showTooltip ? getStateDisplayText(state): ''} arrow classes={{ tooltip: 'bg-black', arrow: 'text-black' }}>
      <Badge
        className={className}
        badgeContent={" "}
        classes={{
          badge: clsx(cls[state], cls.badge, classes.badge),
        }}
        children={children}
        variant="dot"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal:'right',
        }}
      />
    </Tooltip>
  );
}

export const UserListInner = ({ cid, docs, query, onSelect, selected, keyName, hidePlanInfo, showTooltip, emailTextClasses }) => {
  const { userProfile } = useContext(FirebaseAuthContext);
  const onClick = (e, doc) => {
    if (!!onSelect) {
      let id = e.currentTarget.id;
      if (e.currentTarget.dataset.id)
        id = e.currentTarget.dataset.id;
      onSelect(id, doc);
    }
    else return null;
  }
  const getSecondaryText = (docData) => {
    if (keyName === "chats") {
      return lastMessageHint(
        docData.last_message_type,
        docData.last_message_body
      )
    }
    return (<L2 docData={docData} keyName={keyName} />)
  }
  
  const classes = styles();
  if (!docs) return null;
  return (
    <ListStyling>
      {docs.map(doc => {
        if (!doc || !doc.data) return null;
        else {
          const docData = doc.data;
          let { name, image_data, email, identifier } = getNameAndImage(doc, keyName, query);
          let _id = doc._id;
          if (_id === `${cid}:sample`) _id = 'sample_client';
          else if (userProfile && userProfile.email === email) _id = 'self_client';
          const showEmail = email && keyName !== "chats";
          if (!name && !email) return null;
          let unread_checkins, timeStamp, unread, flagged = false;
          if (keyName === "checkins") {
            let total_checkins = doc.data.total_checkins || 0;
            let checkin_read_count = (!!query ? doc.data.checkin_read_count : _.get(doc, `data.${cid}.checkin_read_count`)) || 0;
            unread_checkins = total_checkins - checkin_read_count;
          }
          else if (keyName === "chats") {
            flagged = docData.systags && docData.systags.length && docData.systags.includes('flagged');
            unread = docData.total_messages && !_.isNil(docData[cid] || docData["read_count"])
              ? docData.total_messages - (!_.isNil(docData[cid] && docData[cid]["read_count"]) ? docData[cid]["read_count"] : docData["read_count"])
              : 0;
            const ts = moment((((docData.last_message_timestamp && docData.last_message_timestamp.seconds) || 0) * 1000) || docData.last_message_timestamp);
            timeStamp = ts.isValid() ? ts.local(): null;
            if (!!timeStamp) {
              if (timeStamp.isBefore(moment(), "day"))
                timeStamp = timeStamp.format("DD/MM");
              else timeStamp = timeStamp.format("LT");
            }
          }
          return (
            <ListItem
              key={doc._id}
              id={_id}
              data-id={doc._id}
              selected={selected === doc._id}
              button
              onClick={(e) => onClick(e, doc)}
            >
              <ListItemAvatar>
                <UserStateIndicator userDoc={docData} classes={{badge: classes.avatarBadge}} showTooltip={showTooltip}>
                  <AvatarImage
                    src={identifier ? `user_profiles/${doc._id}/profile/original/${identifier}` : image_data}
                    name={name || getEmailFirstLetter(email)}
                    base64={!identifier}
                    alt={name || " "}
                    styles={{ fontSize: 13, fontWeight: 600 }}
                    size={showEmail || keyName === 'chats'? classes.avatarMedium:""}
                  />
                </UserStateIndicator>
              </ListItemAvatar>
              <ListItemText
                primary={(
                <>  
                <span className="d-flex align-items-center justify-content-between">
                  <span className="text-truncate">{name || getEmailFirstLetter(email)}</span>
                  <Typography color="textSecondary" className="font_12_500">{timeStamp}</Typography>
                </span>
                {showEmail && 
                <Typography className={`text-grey mt-5 font_11_500 ${emailTextClasses}`}>
                  {email}
                </Typography>}
                </>
                )}
                secondary={!hidePlanInfo && getSecondaryText(doc.data)}
                classes={{
                  secondary: keyName === "chats" && unread && 'fpr-30'
                }}
              />
              {keyName === "checkins" && (
                <div className="d-flex flex-column align-items-center">
                  {!!unread_checkins && (
                    <Badge
                      badgeContent={" "}
                      color="primary"
                      children={""}
                      variant="dot"
                    />
                  )}
                </div>
              )}
              {keyName === 'chats' && <ChatFlagBadge count={unread} flagged={flagged} className={classes.chatCountBadge}
                 active={docData.last_message_authorId === cid || selected === doc._id}/>}
            </ListItem>
          );
        }
      })}
    </ListStyling>
  );
};

const ChatFlagBadge = ({count, className, flagged, active}) => {
  if (!active && count)
    return <Badge badgeContent={count} color='primary' children='' style={{right: '10px', top: '14px'}} className={className}/>;
  if (flagged)
    return <i class='fas fa-star text-primary position-absolute inset-bottom-right mb-25 mr-20'/>;
  return null;
};

const UsersList = props => {
  const { selected, onSelect, page } = props;
  const cid = window.localStorage.getItem("cid");
  return (
    <UserList keyName={page} cid={cid} listProps={{ selected, onSelect }}>
      {({ docs, query, handleRefresh }) => {
        return (
          <UserListInner
            cid={cid}
            keyName={page}
            docs={docs}
            query={query}
            onSelect={onSelect}
            selected={selected}
            handleRefresh={handleRefresh}
            showTooltip={["chats", "checkins"].includes(page)}
          />
        );
      }}
    </UserList>
  );
};
export default UsersList;


export const lastMessageHint = (type, body = null) => {
  switch (type) {
    case "image":
      return <PhotoIcon />;
    case "video":
      return <VideoIcon />;
    case "audio":
      return <AudioIcon />;
    case "attachment":
      return <LinkIcon />;
    case "text":
      return isValidURL(body) ? <LinkIcon /> : body;
    case "deleted":
      return "Message Deleted";
    default:
      return "No Messages";
  }
};


