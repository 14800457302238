/*
 Log Screen logic : 2nd line has 2 parts-> x . y
  1. if serving_id is not dumb-unit:
    {quantity} {serving} . convertWRTTrainerUnitSystem({serving_size} {serving_type})
    If serving_size is 0, no second part
  2. if serving_id is dumb-unit:
     a.if serving_size is 0: 
     {quanity} {serving}
     b.if serving_size exists:
    convertWRTTrainerUnitSystem({serving_size} {serving_type})
*/
/* 
  Meal Screen logic:2nd line has 2 parts-> x . y
   1. if meal.serving_unit is dumb-unit:
    {serving_unit_quantity} {serving_unit}
   2. else:
    {serving_unit_quantity} {serving_unit} . convertWRTTrainerUnitSystem({serving_unit_quantity*serving_size} {serving_type})
    If serving_size is 0, no second part
*/
import _ from 'lodash';
import React, { useState,useEffect, useContext,useMemo, } from 'react';
import { withSnackbar } from 'notistack';
import { Typography, IconButton, Grid, MenuItem, Menu, Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MoreVert from '@material-ui/icons/MoreVert';
import { DUMB_UNITS, roundMacros, roundNumber } from 'fitbud/utils/helpers';
import { Conversion } from 'fitbud/providers/conversion';
import { EditorContext } from './foodItemsForm';
import ReorderDialog from 'fitbud/components/reorderDialog';
import { Avatar } from 'fitbud/components/avatar';
import { LinearPfc, Calories } from 'fitbud/views/foodRecipes/newUi/pfc';
import FoodRecipesInfo from "fitbud/views/foodRecipes/foodRecipesInfo";
import {DialogAppBar} from "fitbud/components/Dialog";
import clsx from 'clsx';
import useServings from 'fitbud/hooks/useServings';
import { getFactor } from '../foodRecipes/newUi/ingredientsEditor';
import { InputDropdown,findStandardUnit } from "fitbud/views/foodRecipes/newUi/inputDropdown";
import {mapDumbUnitToServingType} from "fitbud/utils/helpers";

;const useStyle = makeStyles(() => ({
  root: {
    width: '70px !important',
    marginRight: '10px',
    height:'36px !important '
  },
  textAlignRigth: {
    textAlign: 'right',
  },
  alternateContainer: {
    height: 26,
    border: '1px solid white',
    borderRadius: 30,
    backgroundColor: '#317FF5',
    color:"white",
    padding: '4px 8px',
    display: 'inline',
    fontSize: 14,
    fontWeight: 700,
    position: 'absolute',
    top: -18,
    left: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    lineHeight:18
  },
}));
const hidePartOne=(foodRecipeData)=>{
  if(foodRecipeData.type==="recipe") return false;
  const {serving_id}=foodRecipeData;
  // const serving=_.get(foodRecipeData, 'serving', '');
  if(!DUMB_UNITS.includes(serving_id)) return false;
  return true;
}

const getCalMacros = (macros, meal) => {
  const {quantity}=meal;
  let calMacros = {};
  ['protein', 'fat', 'carbs'].forEach((item, index) => {
    calMacros[item] = Number(macros[item]) * Number(quantity);
  });
  return calMacros;
};

const isRecipeFn=(data)=>data.type==="recipe";

const FoodRecipe = (props) => {
  const {
    foodRecipeData,
    meal,
    addFoodRecipes,
    itemIndex,
    length,
    altItemIndex,
    altFoodRecipes,
    error,
    isUser,
    altLength,
    alternativeitem,
    grpType
  } = props;
  const {
    editorMode: isEditMode,
    handleChange,
    handleQuantityChange,
    handleServingChange,
    handleDelete,
    handleReorder,
    storedFoodRecipes,
    serving:servingData,
    isImport,
    disableItemClick,
    meta={},
  } = useContext(EditorContext);
  //hooks
  const { convertor } = useContext(Conversion);
  const classes = useStyle();

  const [_sortServing,n,n2,dumbUnits] = useServings({servingData,separateFlOz:true});
  const isRecipe=useMemo(()=>isRecipeFn(foodRecipeData),[foodRecipeData.type])
  const stdUnit=useMemo(()=>{
    return findStandardUnit(foodRecipeData.serving_type,_sortServing)||{};
  },[foodRecipeData.serving_type,_sortServing])
  const macros=useMemo(()=>{
    return foodRecipeData.macros
  },[foodRecipeData]);
  const options=useMemo(()=>{
    if(isRecipe){
      return([{
        label:_.get(_sortServing.find(i=>i.id===meal.serving_unit),"value",""),
        value:meal.serving_unit,
      }])
    }
    else{
      //-------------serving options
      const _options= (foodRecipeData.serving_options||[]).map(i=>({
        label:i.label,
        value:i.id,
        selected:i.id===meal.serving_unit
      }));
      //-------------dumbunits
      if(foodRecipeData.serving_type){
        const filteredDumbUnits = dumbUnits
        .filter((i) => mapDumbUnitToServingType[i.id] === foodRecipeData.serving_type)
        .map((i) => ({
          label: i.value,
          value: i.id,
          selected: i.id===meal.serving_unit
        }));
        _options.push(...(filteredDumbUnits || []));
      }
      return _options;
    }
   },[foodRecipeData.serving_options,meal]);
  //state
  const [anchorEl, setAnchorEl] = useState(null);
  const [openReorder, setReorderDialog] = useState(false);
  const [open, setActionState] = useState(false);
  const [selectedFoodDetail, setSelectedFoodDetail] =  useState(null);

  const getServingUnitLabel=(serving)=>{
    if(!!isEditMode)
      return _.get(_sortServing.find(i=>i.id===serving),'value',"");
    else{
      //HACK
      if(serving==="volume_imperial_us") serving="volume_imperial"; 
      return _.get(_sortServing.find(i=>i.id===serving),'value',"");
    }
  }


  const getServingValue=()=>{
    if(!isUser){
      if(isRecipe){
          if(!!meal.serving_unit){
            //new structure
            const serving_unit_label=getServingUnitLabel(meal.serving_unit);
            return `${roundNumber(meal.serving_unit_quantity)} ${serving_unit_label}`;
          }
          else{
            //old structure
            const serving_unit_label=getServingUnitLabel(foodRecipeData.serving);
            return `${roundNumber(meal.quantity)} ${serving_unit_label}`;
          }
        
      }
      else{
        //food
        if(!!meal.serving_unit){
          //new structure
          const {serving_options=[],serving_type,serving_size}=foodRecipeData;
          if(DUMB_UNITS.includes(meal.serving_unit)){
            return `${roundNumber(meal.serving_unit_quantity)} ${getServingUnitLabel(meal.serving_unit)}`
          }
          else{
            const serving_option=_sortServing.find(i=>i.id===meal.serving_unit);
            if(serving_option){
              const serving_unit_label=serving_option.value;
              let servingOptionFactor=getFactor({data:{serving_options},serving:meal.serving_unit,fallback:false});
              if(!servingOptionFactor){
                //deleted case - continue to show deleted unit in preview
                const metaQuantity=_.get(meta,[meal.ref.id,'groups',grpType,'quantity'],1);
                servingOptionFactor=(metaQuantity*serving_size)/meal.serving_unit_quantity;
              }
              const valueInStdUnit=servingOptionFactor*meal.serving_unit_quantity;
              let returnStr=`${roundNumber(meal.serving_unit_quantity)} ${serving_unit_label}`;
              if(valueInStdUnit){
                return returnStr+=` • ${convertor.getDisplayValue(serving_type,valueInStdUnit)} ${convertor.getDisplayUnit(serving_type)}`;
              }
              return returnStr;
            }
          }
        }
        else{
          //old structure
          /* NOTE: 
          To avoid: 1gm . 100g .100cals
          Check hidePartOne logic
          */
          const isServingDumbUnit=hidePartOne(foodRecipeData);
          let returnStr="";
          const serving_unit_label=_.get(_sortServing.find(i=>i.id===foodRecipeData.serving),'value',"");
          if(isServingDumbUnit){
            if(foodRecipeData.serving_size){
              const valueInStdUnit=meal.quantity*foodRecipeData.serving_size;
              returnStr+=`${convertor.getDisplayValue(foodRecipeData.serving_type,valueInStdUnit)} 
              ${convertor.getDisplayUnit(foodRecipeData.serving_type)}`;
            }
            else{
              returnStr+=`${convertor.getDisplayValue(foodRecipeData.serving_type,1)} 
              ${convertor.getDisplayUnit(foodRecipeData.serving_type)}`;
            }
          }
          else{
            returnStr+=`${meal.quantity} ${serving_unit_label}`;
            if(foodRecipeData.serving_size){
              const valueInStdUnit=meal.quantity*foodRecipeData.serving_size;
              returnStr+=`• ${convertor.getDisplayValue(foodRecipeData.serving_type,valueInStdUnit)} 
                ${convertor.getDisplayUnit(foodRecipeData.serving_type)}`;
            }
          }
          return returnStr;
        }
      }
      
    }
    else{
      //Logging View
      const {serving_type="",selected_type="",quantity,serving_size,serving=""}=foodRecipeData;
      if(selected_type==="serving_size"){
        //Showing whatever app logged, in Trainer's unit-system
        return <span>
          <span className="font_13_600">{roundNumber(convertor.getDisplayValue(serving_type,serving_size))}</span>
          <span className="font_13_500">{" "}{convertor.getDisplayUnit(serving_type)}</span>
        </span>
      }
      else{
        const isServingStdUnit=hidePartOne({...foodRecipeData});
        const servingLabel=_.get(foodRecipeData, 'serving', '');
        if(isServingStdUnit){//g,ml,ounce,floz, us
          return (
            <>
              {!!serving_size ? (
                <>
                  <span className="font_13_600">{roundNumber(convertor.getDisplayValue(serving_type,serving_size))}</span>
                  <span className="font_13_500">{" "}{convertor.getDisplayUnit(serving_type)}</span>
                </>
              ) : (
                <>
                  <span className="font_13_600">{roundNumber(quantity)}</span>
                  <span className="font_13_500">{" "}{serving}</span>
                </>
              )}
            </>
          );
        }
        else{
          //household
          return (
            <>
              <span className="font_13_600">{roundNumber(_.get(foodRecipeData, "quantity", 1))||1}</span>
              <span className="font_13_500"> {servingLabel}</span>
              {!!serving_size && (
                <>
                <span className="font_13_600">{`• ${roundNumber(convertor.getDisplayValue(serving_type,serving_size))}`}</span>
                <span className="font_13_500">{" "}{convertor.getDisplayUnit(serving_type)}</span>
                </>
              )}
            </>
          );
        }
        
      }
    }
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setActionState(false);
  };

  let calories = 0;
  if ('calories' in props) {
    calories = roundMacros(props.calories);
  } else {
    if(isEditMode){
      const foodCalories=Number(_.get(macros, 'calories', 0));
      calories = roundMacros(foodCalories * Number(meal.quantity));
    }
    else{
      const foodCalories=Number(_.get(macros, 'calories', 0));
      if(!!_.get(meta,[meal.ref.id,'groups'])){
        const quantity=Number(_.get(meta,[meal.ref.id,'groups',grpType,'quantity']));
        calories=roundMacros(foodCalories*quantity)
      }
      else{
        //old user schedule-meals where percolation can't run
        calories = roundMacros(foodCalories * Number(meal.quantity));
      }
    }
  }
  const onDelete = () => {
    const type = altItemIndex !== undefined ? 'alternatives' : 'food';
    handleDelete({
      grpIndex: props['data-group'],
      altItemIndex: altItemIndex,
      itemIndex,
      type,
    });
    handleClose();
  };

  const onReorder = (list) => {
    let updatedFoodItems = Array.from(altFoodRecipes);
    updatedFoodItems = list.map((item) => updatedFoodItems.find((exercise) => item.ref.id === exercise.ref.id));
    handleReorder({
      grpIndex: props['data-group'],
      itemIndex,
      foodItems: updatedFoodItems,
    });
  };

  const foodItems =
    altFoodRecipes &&
    altFoodRecipes.map((item, i) => {
      const foodRecipeId = item.ref.id;
      const doc = _.find(storedFoodRecipes, (d) => foodRecipeId === d.id);
      return { ref: item.ref, name: doc.ref_name };
    });

  // if (!!error && !!error.quantity) {
  //   props.enqueueSnackbar(error.quantity, {
  //     variant: 'error',
  //   });
  // }

 //errors sets on handle change from parent, just showing msg here
  const handleBlur = (e) =>{
    let _value = Number(e.target.value);
    if (!_value)
      props.enqueueSnackbar("Please enter a valid quantity", {
        variant: 'error',
      });
  }


  const onCloseFoodDetail = () =>{
    setSelectedFoodDetail(null);
  }

  const getSpaceY = () =>{
   const isLastFoodItem =  itemIndex === length - 1;
    if(!!alternativeitem){
     //check weather it is last alternative item or not:
     const isLastAltItem = altItemIndex ===  altLength -1 ;
     if(!isLastAltItem) return "mb-10";
     //if it is last alt item and not last food items then space should be 20 other wise nothing:
     if(isLastAltItem && !isLastFoodItem) return "mb-20";
     if(isLastAltItem && isLastFoodItem) return "";
    }else{
      //if not alternative and not last then 20 spacing between them
      if(isLastFoodItem) return "";
      return "mb-20"
    }
  }
  return (
    <>
      <Grid
        container
        id="recipe_container"
        className={clsx(' position-relative', getSpaceY())}
        justify="space-between"
      >
        {/*---- LEFT SIDE -START ----*/}
        {altItemIndex !== undefined && altItemIndex > 0 && <div className={classes.alternateContainer}>OR</div>}
        <Grid item sm={isEditMode ? 6 : 8} className="fp-0 ">
          <div className={`d-flex justify-content-start align-items-center col fp-0 ${isUser ? '' : 'cursor-pointer'}`}
            onClick={disableItemClick || isUser ? null : ()=>setSelectedFoodDetail({id:foodRecipeData.id, type:foodRecipeData.type})}>
            <Avatar
              size="large"
              name={foodRecipeData.ref_name || foodRecipeData.title}
              src={foodRecipeData.thumbnail}
              alt={foodRecipeData.ref_name || ' '}
              className="border fmr-20"
            />
            <div>
              <Typography  className="fmb-5 font_15_600">
                {foodRecipeData.ref_name || foodRecipeData.title}
              </Typography>
              <Typography variant="body2" className="text-labels">
                <span>
                    <span className="font_13_600">{getServingValue()}</span>
                </span>
              </Typography>
            </div>
          </div>
        </Grid>
        {/*---- LEFT SIDE -END ----*/}
        {/*---- RIGHT SIDE -START ----*/}
        <Grid item sm={isEditMode ? 6 : 4} className="fp-0">
          <div className="d-flex h-100 fp-0 justify-content-end align-items-center col">
            {isImport && (
              <div>
                <Calories calories={calories} textClassName="font_16_600" calClassName="font_13_500" className={` ${classes.textAlignRigth} mb-5 `} />
                <LinearPfc className="font_14_500" data={getCalMacros(macros, meal)} />
              </div>
            )}
            {!isImport && (
              <>
                {isEditMode ? (
                  <div className="d-flex align-items-center">
                   <div>
                   <Calories calories={calories} textClassName="font_14_600" calClassName="font_14_500"  className={`${classes.textAlignRigth} mb-5  fmr-20 `} />
                    <LinearPfc
                      className="fmr-20 font_14_500"
                      data={getCalMacros(macros, meal)}
                    />
                   </div>
                    <div className="d-flex align-items-center">
                      <div style={{width:"150px"}}>
                        <InputDropdown 
                          name="quantity"
                          options={options} 
                          unit={meal.serving_unit}
                          error={!!error && !!error.quantity}
                          value={roundNumber(meal.serving_unit_quantity)}
                          onValueChange={(e)=>handleQuantityChange(e,meal,stdUnit)}
                          handleOptionChange={(e,v)=>handleServingChange(e,v,meal,stdUnit,{
                            'groupindex': props['data-group'],
                            'itemindex': itemIndex,
                            'altitemindex': altItemIndex,
                          })}
                          onBlur={handleBlur}
                          inputProps={{
                            'data-group': props['data-group'],
                            'data-itemindex': itemIndex,
                            'data-altitemindex': altItemIndex,
                            min: 1,
                            step: '0.01',
                          }}
                          data={foodRecipeData}
                          />
                      </div>
                    {/* <SimpleNumberInput
                      name="quantity"
                      type="number"
                      placeholder="0"
                      value={roundNumber(meal.quantity)}
                      autoComplete="off"
                      variant="outlined"
                      required
                      error={!!error && !!error.quantity}
                      onChange={handleChange}
                      classes={{
                        root: clsx(classes.root , "flex-shrink-0 flex-grow-0"),
                      }}
                      InputProps={{
                        inputProps: {
                          'data-group': props['data-group'],
                          'data-itemindex': itemIndex,
                          'data-altitemindex': altItemIndex,
                          min: 1,
                          step: '0.01',
                        },
                        classes: {
                          root: 'small',
                          input: 'font_16_600 text-center',
                        },
                      }}
                      onBlur={handleBlur}
                    /> */}
                    <IconButton
                      onClick={(e) => {
                        handleClick(e);
                        setActionState(true);
                      }}
                      style={{marginLeft:"18px"}}
                      >
                      <MoreVert />
                    </IconButton>
                    </div>
                  </div>
                ) : (
                  <Calories calories={calories}  textClassName="font_16_600" calClassName="font_13_500"/>
                )}
              </>
            )}
          </div>
        </Grid>
        {/*---- RIGHT SIDE -END ----*/}
      </Grid>
      {open && (
        <Menu id="action-sub-menu" anchorEl={anchorEl} open onClose={handleClose}>
          {!altItemIndex && (
            <MenuItem
              onClick={() => {
                handleClose();
                addFoodRecipes('alternatives', itemIndex);
              }}
            >
              Add Alternatives
            </MenuItem>
          )}
          {altItemIndex !== undefined && altItemIndex === 0 && (
            <MenuItem
              onClick={() => {
                handleClose();
                setReorderDialog(true);
              }}
            >
              Reorder
            </MenuItem>
          )}
          <MenuItem onClick={() => onDelete()}>Remove</MenuItem>
        </Menu>
      )}
      {openReorder && (
        <ReorderDialog
          open
          listData={foodItems}
          onClose={() => {
            handleClose();
            setReorderDialog(false);
          }}
          onSubmit={(list) => onReorder(list)}
          title="Reorder Alternatives"
        />
      )}
      {!!selectedFoodDetail && <FoodRecipesPopup type={selectedFoodDetail.type} id={selectedFoodDetail.id} onClose={onCloseFoodDetail} />}
    </>
  );
};



const FoodRecipesPopup = (props) =>{
  const { onClose, id, type } = props;
  if (!id) return;
  return (
    <Drawer anchor="right" open={true} onClose={onClose}>
      <DialogAppBar title={isRecipeFn(props) ? "Recipe":"Food"} onClose={onClose} titleFont='h3'/>
      <FoodRecipesInfo id={id} key={id} isPreview={true} type={type}/>
    </Drawer>
  );
}


export default withSnackbar(FoodRecipe);
