import React, { useContext, useState, useRef, useEffect } from 'react';
import { Dialog, DialogContent, Grid, FormControlLabel, Checkbox, Drawer } from '@material-ui/core';
import SelectorContextProvider, {
  SelectorContext,
  SELECTOR_LIB,
} from 'fitbud/components/catalogSelector/selectorContext';
import SelectorHeader from 'fitbud/components/catalogSelector/selectorHeader';
import SelectedList from 'fitbud/components/catalogSelector/selectedList';
import ExerciseInfo from 'fitbud/views/exercises/info';
import appRdxFns from 'fitbud/redux/app';
import { useDispatch } from 'react-redux';
import { getExercise } from 'fitbud/api';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import Confirmation from 'fitbud/components/confirmationDialog';
import { ClrdButton } from 'fitbud/components/form-fields';
import ExerciseSearchList from 'fitbud/components/ExerciseSearchList';
import { Typography } from '@material-ui/core';
import {SelectorList} from './listWrapper';
import {NoExerciseSelectorIcon} from "fitbud/icons/exerciseIcon";
import CatalogFilter from 'fitbud/components/catalog-filter';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {DialogAppBar} from "fitbud/components/Dialog";

const useClasses = makeStyles(()=>({
  addBtn:{
    padding: "11px 30px",
    backgroundColor:"#E0ECFE",
    cursor: "pointer",
    borderRadius:5
  }
}))

const SelectorContent = (props) => {
  const { query, addSelectedValues, selectedLib, addAsGroup, handleAddAsGroup, filterOpen, filterState, closeFilter, setFilterState, onQueryChange } = useContext(SelectorContext);
  const { cid } = useContext(FirebaseAuthContext);
  const d = useDispatch();
  const {insideGrp} = props
  const { showLoader, hideLoader } = appRdxFns(d);
  const [exerciseData, setExerciseData] = useState(null);
  const [existsFdcEx, setExistsFdcEx] = useState(null); //state for fitbudd lib ex which is already in own lib;
  const exFdcDataSnap = useRef(null);
  const [newExFormOpen, setNewExFormOpen] = useState(false);
  const [viewEx, setViewEx] = useState(null);
  const classes = useClasses();

  const clearExistsFdcEx = () => {
    setExistsFdcEx(null);
  };

  const onAddFitbuddLibItem = async (edcItem) => {
    showLoader();
    const { success, data: exerciseData, copy } = await getExercise(edcItem._id, cid);
    const _exOut = {
      ...exerciseData,
      source:"fitbudd",
      isDbUsed: true,
    };
    if (copy) {
      //case this exercise already exists in own library:: TODO :need to handle multiple results::
      const exFdcFirst = copy;
      exFdcDataSnap.current = exFdcFirst;
      setExistsFdcEx(_exOut);
      hideLoader();
      return;
    }

    if (!success) return; // FIXME should be handled
    setExerciseData({ ..._exOut });
    hideLoader();
  };

  const selectFdcEx = () => {
    const exFdcDoc = exFdcDataSnap.current;
    addSelectedValues({ _id: exFdcDoc.id, data: exFdcDoc.data() }); //select items
    setExistsFdcEx(null);
    exFdcDataSnap.current = null;
  };

  const createNewCopyOfFdcEx = () => {
    setExerciseData({ ...existsFdcEx }); //will open ex create form
    setExistsFdcEx(null); //will close confirmation dialog
  };

  const getEndAdornment = () => {
    if (insideGrp) return null;
    return (
      <FormControlLabel
        classes={{ root: 'fm-0' }}
        label={<Typography>Add as a group</Typography>}
        control={
          <Checkbox checked={addAsGroup} onChange={handleAddAsGroup} classes={{ root: 'fp-0 fmr-10' }} color="primary" />
        }
      />
    );
  }

  const handleClickOnSelectedItem = (e,l) =>{
    setViewEx(l);
  }

  const onDrawerClose = () =>{setViewEx(null)}

  const noSelectedPlaceHolder = () =>{
   return (
     <div className="flex-grow-1 h-100 fp-20 text-center d-flex align-items-center justify-content-center flex-column ">
       <NoExerciseSelectorIcon />
       <Typography  className="fmt-25 font_15_700">
         {`No Exercises Selected`}
       </Typography>
       <Typography className="fmt-10" style={{fontSize:"13px", fontWeight:400, color:"#37404D"}}>
         {`Select exercises from the left panel`}
       </Typography>
     </div>
   );
  }



   const renderNoResultFoundAction = () =>{
     return (
       <div style={{ width: 350 }} className="d-flex flex-column justify-content-center align-items-center fmt-10">
         {/* <Typography className="cursor-pointer" onClick={()=>{setNewExFormOpen(true)}} color="primary" >Click Heret &nbsp;</Typography>  */}
         <Typography className="font_13_400 text-dark-grey fmb-20">
           We can't find what you are looking for. Use the button below to add your own exercise.
         </Typography>
         <div className={classes.addBtn}   onClick={()=>{setNewExFormOpen(true)}}>
           <Typography color="primary" className="font_15_600">Add Exercise</Typography>
         </div>
       </div>
     );
   }

  return (
    <DialogContent className="d-flex fp-0">
      <Grid container className="flex-grow-1">
        <Grid item xs={7} className="h-100 d-flex position-relative" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
          {selectedLib === SELECTOR_LIB.MY_LIB && (
            <SelectorList noResultFoundAction={renderNoResultFoundAction} keyName="exercises" filterState={filterState} query={query} onClick={addSelectedValues} showMediaType={true} showDraftInd={true}/>
          )}
          {selectedLib === SELECTOR_LIB.FITBUDD_LIB && (
            <ExerciseSearchList noResultFoundAction={renderNoResultFoundAction} query={query} filterState={filterState} searchOff={true} onClick={onAddFitbuddLibItem} listClassName="fpy-0" />
          )}
        </Grid>
        <Grid className="h-100 d-flex" item xs={5}>
          <SelectedList onClick={handleClickOnSelectedItem} isDivider={!addAsGroup} endAdornment={getEndAdornment} keyName="Exercise" noSelectedPlaceholder={noSelectedPlaceHolder} />
        </Grid>
      </Grid>
      {!!exerciseData && (
        <ExerciseInfo
          id={'new'}
          key="new"
          onSave={(doc) => {
            const _id = doc.id;
            const data = doc.data();
            addSelectedValues({ _id: _id, data: data });
            setExerciseData(null);
          }}
          onCancel={() => {
            setExerciseData(null);
          }}
          exerciseData={exerciseData}
          draftFeature={false}
        />
      )}
      {!!existsFdcEx && (
        <Confirmation
          open={true}
          handleClose={clearExistsFdcEx}
          showCloseIcon={true}
          title={'Copy Already Exists'}
          msg={'A copy of this exercise already exists in your library'}
          cancelOption={'Select Existing'}
          handleCancel={selectFdcEx}
          confirmOption="Create New Copy"
          handleChange={createNewCopyOfFdcEx}
        />
      )}
      {filterOpen && <CatalogFilter
          filters={filterState}
          keyName="exercises"
          open={true}
          onSave={setFilterState}
          handleClose={closeFilter}
          ignoreFilter={selectedLib === SELECTOR_LIB.FITBUDD_LIB ? ["source"]:[]}
        />
      }
      {!!newExFormOpen &&
        <ExerciseInfo
          id={'new'}
          key="new"
          onSave={(doc) => {
            const _id = doc.id;
            const data = doc.data();
            addSelectedValues({ _id: _id, data: data });
            setNewExFormOpen(false);
            // delay(()=>{
            //   onQueryChange({target:{value: data.ref_name }})
            // },1000)
          }}
          onCancel={() => {
            setNewExFormOpen(false);
          }}
          draftFeature={false}
        />
      }
      {!!viewEx && <Drawer anchor="right" onClose={onDrawerClose} open={true}>
        <DialogAppBar title="Exercise" onClose={onDrawerClose} titleFont="h3"/>
        <ExerciseInfo
         id={viewEx._id}
         key={viewEx._id}
         isPreview={true}
        />
         

        </Drawer>}
    </DialogContent>
  );
};

const ExerciseSelectorHeader = (props) => {
  const { insideGrp, grpIndex, handleAdd, selectedExercise } = props;
  const { selectedValues, addAsGroup } = useContext(SelectorContext);
  const { addAllSelectedValues } = useContext(SelectorContext);
  const totalSelectedValues = (selectedValues || []).length
  useEffect(()=>{
    if(!!selectedExercise && !!selectedExercise.length){
      addAllSelectedValues(selectedExercise)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedExercise] )

  const saveAsAGroup = ({ type, grpIndex }) => {
    handleAdd && handleAdd({ type, data: selectedValues, grpIndex });
  };

  const handleSave = () => {
    if (insideGrp) {
      handleAdd && handleAdd({ type: 'individual', data: selectedValues, grpIndex });
    } else {
      if (addAsGroup) saveAsAGroup({ type: 'group' });
      else saveAsAGroup({ type: 'individual', grpIndex: null });
    }
  }

  const getHeaderActions = () => {
    return (
      <ClrdButton disabled={ insideGrp ? false : !totalSelectedValues} variant="contained" color="primary" className="f-medium" onClick={handleSave}>
        Save
      </ClrdButton>
    );
  };
  return <SelectorHeader title="Select Exercises" keyName="exercises" headerActions={getHeaderActions} {...props} />;
};

const CatalogSelector = (props) => {
  const { open } = props;
  return (
    <Dialog
      open={open}
      id="exercise_selector"
      scroll="paper"
      classes={{
        paper: 'width-800 height-70',
      }}
    >
      <SelectorContextProvider item="Exercise" {...props}>
        <ExerciseSelectorHeader {...props} />
        <SelectorContent {...props} />
      </SelectorContextProvider>
    </Dialog>
  );
};

export default CatalogSelector;
