import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const FilterIcon = props => (
    <SvgIcon
        {...props}
        viewBox="0 0 24 24"
    >
      <path d="M3.28123 4.8675C3.39372 5.01375 9.73831 13.1137 9.73831 13.1137V19.875C9.73831 20.4937 10.2445 21 10.8745 21H13.1356C13.7543 21 14.2718 20.4937 14.2718 19.875V13.1025C14.2718 13.1025 20.4476 5.205 20.7401 4.845C21.0326 4.485 20.9988 4.125 20.9988 4.125C20.9988 3.50625 20.4926 3 19.8626 3H4.13618C3.44997 3 3 3.54 3 4.125C3 4.35 3.0675 4.62 3.28123 4.8675Z" fill="#37404D"/>
    </SvgIcon>
);
