import React, { useState } from 'react';
import {
  MenuItem, Select,
  OutlinedInput, Typography
} from '@material-ui/core';
import { DEFAULT_VIDEO_CONSTRAINTS, SELECTED_VIDEO_INPUT_KEY } from 'fitbud/utils/constants';
import useDevices from './useDevices';
import useMediaStreamTrack from './useMediaStreamTrack';
import useVideoContext from './useVideoContext';
import DropDownIcon from "@material-ui/icons/ExpandMore";
import { FormTextField } from "fitbud/components/form-fields";


const VideoInputList = () => {
  const { videoInputDevices } = useDevices();
  const { localTracks } = useVideoContext();
  const localVideoTrack = localTracks.find(track => track.kind === 'video');
  const mediaStreamTrack = useMediaStreamTrack(localVideoTrack);

  const [storedLocalVideoDeviceId, setStoredLocalVideoDeviceId] = useState(
    window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY)
  );
  const localVideoInputDeviceId = mediaStreamTrack && mediaStreamTrack.getSettings().deviceId || storedLocalVideoDeviceId;

  const replaceTrack = (newDeviceId) => {
    // Here we store the device ID in the component state. This is so we can re-render this component display
    // to display the name of the selected device when it is changed while the users camera is off.
    setStoredLocalVideoDeviceId(newDeviceId);
    window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId);
    localVideoTrack.restart({
      ...(DEFAULT_VIDEO_CONSTRAINTS),
      deviceId: { exact: newDeviceId },
    });
  }

  return (
    <FormTextField classes={{ control: "fpb-0" }} fullWidth label="Video">
      <Select
        IconComponent={DropDownIcon}
        input={
          <OutlinedInput
            classes={{
              root: "height-40",
              input: "size_16_500 select-height-40",
            }}
            name="type"
            onChange={e => replaceTrack(e.target.value)}
            value={localVideoInputDeviceId || ''}
          />
        }
        required
      >
        {videoInputDevices.length > 0 ? (
          videoInputDevices.map(device => (
            <MenuItem value={device.deviceId} key={device.deviceId}>
              {device.label}
            </MenuItem>
          ))
        ) : (
          <MenuItem>
            {'No Local Video'}
          </MenuItem>
        )}
      </Select>
    </FormTextField>
  )
}

export default VideoInputList;