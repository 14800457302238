import React, { useState, useEffect } from "react";
import { CheckboxGrps } from "fitbud/components/CheckboxGrps";
import _ from "lodash";

const CheckboxFilter = (props) => {
  const { componentState, dispatch, type, fields, isMulti, defaultFields= [], prefix = null, isColumn = true } = props;
  const [state, setState] = useState(componentState || []) // state : string[]

  const handleChange = (e) => {
    const value = e.target.value;
    if (state && state.includes(value)) {
      dispatch({ type, payload: state.filter(s => s !== value) })
    } else {
      if (isMulti) dispatch({ type, payload: [...(state || []), value] })
      else dispatch({ type, payload: [value] })
    }
  }

  useEffect(() => {
    setState(componentState)
  }, [componentState])

  const FIELDS = defaultFields.concat(fields).map(f => {
      if(_.isObject(f)) {
        return { ...f, checked: state && state.includes(f.value)  }
      }else {
        return { label: `${prefix || ''}${f}`, value: f, checked: state && state.includes(f) }
      }
  })
  return (
    <CheckboxGrps
      handleChange={handleChange}
      column={isColumn}
      fields={FIELDS}
      labelClass="font_14_500"
    />)
}

export default CheckboxFilter