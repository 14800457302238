import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const LiveSessionsIcon = (props) => {
  const { stroke, ...rest } = props;
  return (
    <SvgIcon viewBox="0 0 20 20" classes={{ root: 'small empty' }} {...rest}>
        <path
          d="M18.2749 5.63416L15.4666 16.9092C15.2666 17.7508 14.5166 18.3341 13.6499 18.3341H2.6999C1.44157 18.3341 0.541584 17.1008 0.916584 15.8924L4.42491 4.62585C4.66658 3.84252 5.39159 3.30078 6.20826 3.30078H16.4582C17.2499 3.30078 17.9083 3.78412 18.1833 4.45079C18.3416 4.80912 18.3749 5.21749 18.2749 5.63416Z"
          stroke={stroke || "#8F969F" }
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M13.3333 18.3333H17.3166C18.3916 18.3333 19.2333 17.425 19.1583 16.35L18.3333 5"
          stroke={stroke || "#8F969F" }
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.06665 5.31871L8.93332 1.71875"
          stroke={stroke || "#8F969F" }
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.65 5.32367L14.4334 1.70703"
          stroke={stroke || "#8F969F" }
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.41669 10H13.0834"
          stroke={stroke || "#8F969F" }
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.58331 13.3359H12.25"
          stroke={stroke || "#8F969F" }
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
    </SvgIcon>
  );
};
