import React, { useEffect, useState, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import { Typography, Card, IconButton, TextField, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Clear';
import LaunchIcon from '@material-ui/icons/Launch';
import { useSnackbar } from 'notistack';
import PdfIcon , {LinkIcon} from 'fitbud/icons/pdfIcon';
import { CDN_DOCUMENT_BASE_URL, FILE_STATUS } from 'fitbud/utils/constants';
import _ from 'lodash';
import { FormTextField } from 'fitbud/components/form-fields'; //
import { UpArrowIcon } from "fitbud/icons/upArrow";


const DEFAULT_FILE_NAME = "File";
const FILE_ERROR_MSG = "Document uploading was interrupted or failed. Please try again."
const MAX_SIZE = 200; //size in mb

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const useClasses = makeStyles(() => ({
  uploadContainer: {
    width: 400,
    padding: '16px 20px',
    backgroundColor: '#FFFFFF',
    borderRadius: 6,
    cursor: 'pointer',
    '& .secondaryText': {
      color: '#65768C',
    },
    '& .uploadIcon':{
      height:40,
      width:40,
      backgroundColor: "#fff",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",  
    }
  },
  cardContainer: {
    width: 400,
    padding: '16px 20px !important',
    border: '1px solid #D3D9DB !important',
    backgroundColor:"#F0F3F5",
    borderRadius: '10px',
    '& .secondaryText': {
      color: '#65768C',
    },
  },
  iconSize: {
    height: '20px !important',
    width: '20px !important',
  },
  progress: {
    marginRight: '12px',
  },
  nameTextFiledContainer:{
    marginTop:16,
  }
}));

const PdfUploader = (props) => {
  const classes = useClasses();
  const { file: fileProps, handleRemove } = props;
  const [file, setFile] = useState(fileProps);
  const [error, setError] = useState(false);

  const _fileName = useMemo(()=>{
    const {name, type, url, file_original_name} = file || {}
    if(!!name) return name;
    else if(type === "file") return file_original_name || DEFAULT_FILE_NAME;
    else if(type === "link") return  url;
    return DEFAULT_FILE_NAME;
  },[file])


  useEffect(()=>{
    setFile(fileProps)
  },[fileProps])

  useEffect(() => {
    /* If File contains error then show error*/
    if (!fileProps) return;
    if(fileProps.status === FILE_STATUS.error){
      setError(true);
    }
  }, [fileProps]);



  if(error){
    return (
      <Card className={clsx(classes.cardContainer, 'd-flex align-items-center')}>
        <div className={clsx('flex-grow-1 overflow-hidden')}>
          {_fileName && (
            <Typography className="fmb-8 text-truncate" variant="h6">
              {_fileName}
            </Typography>
          )}
          <Typography className={clsx('font_13_500 secondaryText ')}>{FILE_ERROR_MSG}</Typography>
        </div>
      </Card>
    );
  }

  if (!!file) return <PdfPreview document={file} handleRemove={handleRemove} />;
  return null;
};

export default PdfUploader;

export const PdfInput = (props) => {
  const { getInputProps, ...rest } = props;
  return (
    <div {...rest}>
      <div className="flex-grow-1">
        <Typography className="fmb-8" variant="h6">
         Drop your file here or browse
        </Typography>
        <Typography className={clsx('font_13_500 secondaryText ')}>Max size 200 mb</Typography>
      </div>
      <div className="uploadIcon" >
        <UpArrowIcon style={{ fill: "none" }} />
      </div>
      <input {...getInputProps()} />
    </div>
  );
};

export const PdfPreview = (props) => {
  const { document, handleRemove, classes: propClasses = {}, noPreview = false } = props;
  const { size, type } = document;
  const classes = useClasses();

  const _documentUrl = useMemo(()=>{
    const {s3Path, url} = document || {}
    if(!!url){
      if(typeof url === "string"  && !url.startsWith('http')) return "https://".concat(url)
      else return url;
    }
    else if(!!s3Path) return `${CDN_DOCUMENT_BASE_URL}/${s3Path}`
    else return "";
  },[document])


  const _documentName = useMemo(()=>{
    const {name, type, url, file_original_name} = document || {}
    if(!!name) return name;
    else if(type === "file") return file_original_name;
    else if(type === "link") return  url;
    return "";
  },[document])

  if (!_documentUrl) return null;

  return (
    <div className="d-flex w-100 align-items-center">
      <Card className={clsx(classes.cardContainer, 'd-flex align-items-center fpr-15', propClasses.root)}>
        <div>
          {type === "link" ? <LinkIcon className="fmr-10" /> : <PdfIcon className="fmr-10" />}
        </div>
        <div className={clsx('flex-grow-1 overflow-hidden')}>
          {_documentName && (
            <Typography className="twoLineEllipsis" variant="h6">
              {_documentName}
            </Typography>
          )}
          {!!size && <Typography className={clsx('font_13_500 secondaryText fmt-8 ')}>{formatBytes(size || 0)}</Typography>}
        </div>
        {!!handleRemove && (
          <IconButton onClick={handleRemove}>
            <DeleteIcon />
          </IconButton>
        )}
        {/* Only clickable on launch Icon */}
      {!noPreview && (
        <div className="fml-10">
          <IconButton href={_documentUrl} onClick={(e)=>{
             e.stopPropagation();
          }} target="_blank">
            <LaunchIcon />
          </IconButton>
        </div>
      )}
      </Card>
    </div>
  );
};

const PdfLinkInput = (props) => {
  const { document, handleChange, errors: errorProps } = props;
  const [errors, setErrors] = useState({});
  const { url } = document || {};
  const urlError = _.get(errors, "url");
  useEffect(()=>{
    setErrors(errorProps)
  },[errorProps])

  const onUrlChange = (e) =>{
    const value = e.target.value;
    handleChange && handleChange("link", {...document, mimeType:"", url:value})
  };

  return (
    <div className="">
      <FormTextField fullWidth>
        <TextField
          id="url"
          autoComplete="off"
          defaultValue={url}
          variant="outlined"
          type="text"
          onChange={onUrlChange}
          error={!!urlError}
          helperText={urlError}
          placeholder="Link"
          InputProps={{
            classes: {
              root: 'medium',
              input: 'size_16_500 medium',
            },
          }}
        />
      </FormTextField>
    </div>
  );
};

export const DialogPdfUploader = (props) => {
  const { document, handleChange, classes: propClasses = {}, errors, isName=true } = props;
  const classes = useClasses();
  const { enqueueSnackbar } = useSnackbar();
  let accept = 'application/pdf';
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept,
    multiple: false,
  });
  const _document = document && document.length > 0 ? document[0] : null;
  const type = _.get(_document, 'type', 'file');
  const _url = _.get(_document, 'url', '') || _.get(_document, 's3Path', '');
  const isError = _.get(_document,'status') === FILE_STATUS.error;
  const nameError = _.get(errors, "name");
  const name = _.get(_document, 'name', '');

  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const size = file.size;
      if (size > 1000000 * MAX_SIZE) {
        enqueueSnackbar('File size must be less than 200MB', { variant: 'error' });
        return;
      }
      handleChange && handleChange(type, file);
    }
  }, [acceptedFiles]);

  const handleDocumentTypeChange = (e, val) => {
    handleChange(val, '');
  };


  const onNameChange = (e) =>{
    const value = e.target.value;
    handleChange && handleChange(type, {..._document, name:value}, true) //third arguments is true then only editing meta
  }

  const getControls = () => {
    if (type === 'file') {
      if (!_url || isError)
        return (
          <PdfInput
            isRemoveAlert={false}
            getInputProps={getInputProps}
            {...getRootProps({
              className: clsx(classes.uploadContainer, 'd-flex align-items-center border bg-grey-new', propClasses.root),
            })}
          />
        );
      else
        return (
          <PdfPreview
            noPreview={true}
            classes={propClasses}
            isRemoveAlert={false}
            document={_document}
            handleRemove={() => {
              handleChange(type,null)
            }}
          />
        );
    } else if (type === 'link') {
      return <PdfLinkInput document={_document} errors={errors} handleChange={handleChange} />;
    }
    return null;
  };
  return (
    <div>
      <RadioGroup value={type} className={`flex-row`} onChange={handleDocumentTypeChange}>
        <FormControlLabel
          value="file"
          control={<Radio className="p-0 mr-10" color="primary" />}
          className="fmr-30 fmb-0"
          classes={{
            label: 'font_16_500',
            root: "fm-0 fmr-30",
          }}
          label="Upload Document"
        />
        <FormControlLabel
          value="link"
          control={<Radio className="p-0 mr-10" color="primary" />}
          className="fmb-0"
          classes={{
            label: 'font_16_500',
            root: "fm-0 fmr-30",
          }}
          label="Add Link"
        />
      </RadioGroup>
      {!!isName && <FormTextField fullWidth label="Name" classes={{control:classes.nameTextFiledContainer}}>
        <TextField
          id="name"
          autoComplete="off"
          value={name}
          variant="outlined"
          type="text"
          onChange={onNameChange}
          error={!!nameError}
          helperText={nameError}
          InputProps={{
            classes: {
              root: 'medium',
              input: 'size_16_500 medium',
            },
          }}
        />
      </FormTextField>}
      <div className="mt-20">{getControls()}</div>
    </div>
  );
};
