import axnsBuilder from "./actions";

export const DEFAULT_STATE = {
  total_chats: 0,
  read_chats: 0,
  total_checkins: 0,
  read_checkins: 0
};

const axns = axnsBuilder();
export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case axns.update:
      return {
        ...state,
        ...action.payload,
        timestamp: Date.now()
      };
    default:
      return state;
  }
};
