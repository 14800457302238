import React, { useContext, useState } from 'react';
import { ContractContext } from 'fitbud/providers/contract';
import CheckoutDrawer from 'fitbud/components/pricing/checkoutDrawer';
import CheckoutDialog from 'fitbud/components/pricing/checkoutDialog';
import RemoveAddsOnDrawer from 'fitbud/components/pricing/removeAddsOnDrawer.js';
import { PLANS, useAcl } from './acl-provider';
import _ from 'lodash';
import { AnalyticsContext } from 'fitbud/providers/analytics';
import { bffUpdateHubspotProp } from 'fitbud/api';
import { HUBSPOT_PROPS } from 'fitbud/utils/constants';
import {useHistory} from "react-router-dom"
export const BillingContext = React.createContext({});

const BillingProvider = (props) => {
  const { activePlan, checkUpgradeOrAddOn, checkLimitedAccess } = useAcl();
  const { event } = useContext(AnalyticsContext);
  const { products, contract, addOnFeatures } = useContext(ContractContext);
  const [isCheckoutOption, setCheckoutOption] = useState(false);
  const [isRemoveAddsOnOpen, setRemoveAddsOnOpen] = useState(false);
  const [intent, setIntent] = useState({});
  const [intentCopy, setIntentCopy] = useState({});
  const history = useHistory(); 
  const isAnnualPlan = _.get(contract, 'meta.recurring_interval') === 'year';
  
  const openPricingOptions = () => {
    navigateToUpgrade();
  };
  const closePricingOptions = () => {
    if (history.length <= 2) {
      history.replace('/');
    } else history.goBack();
  };
  const openCheckoutOption = () => setCheckoutOption(true);
  const closeCheckoutOption = () => setCheckoutOption(false);
  const openRemoveAddsOn = () => setRemoveAddsOnOpen(true);
  const closeRemoveAddsOn = () => setRemoveAddsOnOpen(false);

  const initCheckout = (intent) => {
    setIntent(intent);
    openCheckoutOption();
  };

  const exitCheckout = () => {
    setIntent({});
    closeCheckoutOption();
  };

  const captureEvent = () => {
    event(HUBSPOT_PROPS.UPGRADE_NOW);
    bffUpdateHubspotProp(HUBSPOT_PROPS.UPGRADE_NOW).catch(console.error);
  };

  const navigateToUpgrade = (intent) =>{
    let url = `/upgrade`;
    history.push(url);
    setIntentCopy(intent);
  }

  const navigateToBillingConfig = () =>{
    history.push('/config/billing');
  };

  const handleUpgrade = (fkey) => {
    //methods for responsible  to handle upgrade, based on current plan.
    if (checkLimitedAccess()) {
      navigateToUpgrade();
      return;
    }
    const popularPlan = _.find(products, (product) => !!_.get(product, 'metadata.highlight'));
    const featureAddOn = _.find(addOnFeatures, (addOn) => {
      return _.get(addOn, 'metadata.fkey') === fkey;
    });
    // In case of trials...
    if (activePlan === PLANS.TRIAL) {
      //get most popular plan..
      const out = {
        type: 'upgrade',
        from: fkey || '',
        selectedAddOns: [],
        productId: _.get(popularPlan, "id"),  
        isYearly: !!isAnnualPlan,
      };
      if (!!featureAddOn && !!featureAddOn.id) out.selectedAddOns = [featureAddOn.id];
      navigateToUpgrade(out);
      captureEvent();
      return;
    }
    //check of feature key ...
    if (!!fkey) {
      const upgrade = checkUpgradeOrAddOn(fkey);
      if (upgrade === undefined) return; //checkUpgradeOrAddOn this redirect to the billing page... if  not feature found...
      if (upgrade) {
        //if upgrade required...,
        if (activePlan === PLANS.SILVER) {
          //select pro product  and initiate checkout..
          const out = {
            type: 'upgrade',
            from: fkey || '',
            selectedAddOns: [],
            productId: _.get(popularPlan, "id"),
            isYearly: isAnnualPlan,
            isBlockingScreen:true
          };
          if (!!featureAddOn && !!featureAddOn.id) out.selectedAddOns = [featureAddOn.id];
          navigateToUpgrade(out);
          captureEvent();
          return;
        } else {
          //if not silver
          navigateToUpgrade();
          return;
        }
      } else {
        // in case of add ons....
        const initParams = {
          type: 'addOn',
          from: fkey,
          productId: null,
          isYearly: !!isAnnualPlan,
        };
        if (!!featureAddOn) initParams.selectedAddOns = [featureAddOn.id];
        initCheckout(initParams);
        captureEvent();
        return;
      }
    }
    //if not trial and not checking against feature key, for safe purpose redirect to billing page..
    // window.location.href = `/config/billing`;
    navigateToBillingConfig();
  };

  return (
    <BillingContext.Provider
      value={{
        intent,
        intentCopy,
        openPricingOptions,
        closePricingOptions, //close pricing options dialog
        openCheckoutOption, //open pricing options dialog.
        closeCheckoutOption,
        initCheckout, //methods for initiate checkout drawer.
        exitCheckout, //close checkout drawer..
        openRemoveAddsOn,
        closeRemoveAddsOn,
        handleUpgrade, //methods for open upgrade options..
        setIntentCopy,
      }}>
      {props.children}
      {isCheckoutOption && <CheckoutDialog/>}
      {isRemoveAddsOnOpen && <RemoveAddsOnDrawer />}
    </BillingContext.Provider>
  );
};

export const useBillingContext = () => useContext(BillingContext);

export default BillingProvider;
