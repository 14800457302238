import _ from "lodash";
import firebase from "fitbud/firebase";
import AWS from "aws-sdk";
import * as Sentry from "@sentry/browser";
import { CDN_DOCUMENT_BASE_URL, DEFAULT_ERROR, FILE_STATUS } from "./constants";
import { store } from "fitbud/redux/store";
import fileUploadActionBuilder from "fitbud/redux/fileUpload/actions";
import uuidv4 from "uuid/v4";

const accessKeyId = process.env.REACT_APP_AWS_KEY;
const secretAccessKey = process.env.REACT_APP_AWS_SECRET;
const region = process.env.REACT_APP_AWS_REGION;
const vodBucket = process.env.REACT_APP_AWS_VOD_BUCKET;
const imageBucket = process.env.REACT_APP_AWS_IMAGE_BUCKET;
const pdfBucket = process.env.REACT_APP_AWS_FILE_BUCKET;

const fileUploadAction = fileUploadActionBuilder();

const s3 = new AWS.S3({
  accessKeyId,
  secretAccessKey,
  region,
  apiVersion: "2006-03-01",
  correctClockSkew:true
});

var ses = new AWS.SES({
  apiVersion: "2010-12-01",
  region,
  accessKeyId,
  secretAccessKey,
});

const vodUpload = async ({ id, docId, collection, path, file, media_bgoff=false }) => {
  try {
    if (!file) throw new Error("file not provided");
    const uploadParams = {
      Bucket: vodBucket,
      Key: path,
      Body: file,
      Metadata: { docId, collection, media_bgoff:media_bgoff?"media_bgoff":"" },
    };

    await s3
      .upload(uploadParams, { partSize: 10 * 1024 * 1024 })
      .on("httpUploadProgress", _.throttle((progress) => {
        var uploaded = parseInt((progress.loaded * 100) / progress.total);
        store.dispatch({
          type: fileUploadAction.updateFile,
          data: { id, progress: uploaded },
        });
      }, 5000))
      .promise();
    if(media_bgoff){
      await firebase.firestore().collection(collection).doc(docId).update({
        media_bgoff: { type: "video", status: FILE_STATUS.processing, s3Path: path }
      });
    }
    else{
      await firebase.firestore().collection(collection).doc(docId).update({
        media: [{ type: "video", status: FILE_STATUS.processing, s3Path: path }]
      });
    }
    return FILE_STATUS.processing;
  } catch (e) {
    Sentry.captureException(e);
    return FILE_STATUS.error;
  }
};

const upload = async ({ file, path, baseWidth }) => {
  try {
    if (!file) throw new Error("file not provided");

    const uploadParams = {
      Bucket: imageBucket,
      Key: path,
      Body: file,
    };

    await s3
      .upload(uploadParams, { partSize: 10 * 1024 * 1024 })
      .promise();

    return `https://bff.fitbudd.com/media/image/%2F${baseWidth || 240}%2F/${path}`;
    // the `%2F240%2F` part in the above URL is what app uses for thumboring ¯\_(ツ)_/¯
  } catch (e) {
    console.log("error: ", e);
    Sentry.captureException(e);
  }
};
export const uploadPdf = async ({file, cid }) => {
  if (!file) throw new Error('file not provided');
  const path = `${process.env.REACT_APP_FB_PROJECT}/${cid}/${uuidv4() + "." + (file.name || "").split(".").pop()}`
  const mimeType = file.type;
  try {
    const uploadParams = {
      Bucket: pdfBucket,
      Key: path,
      Body: file,
      ContentType: 'application/pdf',
    };

    await s3.upload(uploadParams, { partSize: 10 * 1024 * 1024 }).promise();
    return {
      file_original_name: file.name,
      size: file.size,
      s3Path: path,
      url: `${CDN_DOCUMENT_BASE_URL}/${path}`,
      status: FILE_STATUS.complete,
      type: 'file',
      mimeType,
    };
  } catch (e) {
    console.log('error: ', e);
    Sentry.captureException(e);
    throw new Error(e);
  }
};

const verifyEmail = async (email, enqueueSnackbar) => {
  try {
    await ses.verifyEmailIdentity({ EmailAddress: email }).promise();
    if (enqueueSnackbar) {
      enqueueSnackbar("Verification Mail Sent", { variant: "success" });
    }
  } catch (e) {
    console.log("error: ", e);
    if (enqueueSnackbar) {
      enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
    }
    Sentry.captureException(e);
  }
};

const getVerificationStatus = async (email, enqueueSnackbar) => {
  try {
    var params = {
      Identities: [email],
    };
    const status = await ses.getIdentityVerificationAttributes(params).promise();
    if(!!status.VerificationAttributes 
      && status.VerificationAttributes.hasOwnProperty(email) 
      && status.VerificationAttributes[email].VerificationStatus === "Success"
    ) {
      return true;
    }
    return false;
  } catch (e) {
    console.log("error: ", e);
    if (enqueueSnackbar) {
      enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
    }
    Sentry.captureException(e);
    return false;
  }
};

export { vodUpload, upload, verifyEmail, getVerificationStatus };
