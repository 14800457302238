import useVideoContext from './useVideoContext';
import useParticipants from './useParticipants';
import useSelectedParticipant from './useSelectedParticipant';

const UseMainParticipant = () => {
  const participants = useParticipants();
  const [selectedParticipant] = useSelectedParticipant();
  const {
    room: { localParticipant },
  } = useVideoContext();
  
  return selectedParticipant || participants[0] || localParticipant;
}

export default UseMainParticipant;
