import React, { useContext, useState } from "react";
import {
  TextField,
  MenuItem,
  InputAdornment,
  Select,
  OutlinedInput,
} from "@material-ui/core";
import DropDownIcon from "@material-ui/icons/ExpandMore";
import { FormTextField } from "fitbud/components/form-fields";
import Dialog from "fitbud/components/Dialog";
import Confirmation from "../../components/confirmationDialog"
import ImageFileUpload from "fitbud/components/imageSelector";
import {CustomConfirmation} from "fitbud/components/customConfirmation";
import { Conversion } from "fitbud/providers/conversion";
import { DurationWrapper } from "../workouts/components/amrapDurationInput";
import MinMaxField from "fitbud/components/minMaxField";
import { protectNumberInputs, roundMacros } from "fitbud/utils/helpers";

const handleV2vals = obj => {
  if (!obj) return 0;
  const { duration, durationv2 } = obj;
  let x = duration || 0;
  if (durationv2 || durationv2 === 0)
    x = durationv2;
  return x;
};

const CardioForm = props => {
  const { convertor } = useContext(Conversion);
  const [isConfirmationOpen, showConfirmation] = useState(null);
  const [dirty, setDirty] = useState(false);
  const {
    doc,
    errors,
    loading,
    isNew,
    onSubmit,
    handleChange,
    onCancel,
    handleRanges,
    handleDraft,
    handlePublish,
    draftFeature,
    isDraftAvailable
  } = props;

  const {
    title,
    ref_name,
    desc,
    sub_type,
    thumbnail,
    target_hr,
    warm_up,
    cool_down,
    steady_state,
    high_intensity,
    rounds,
    duration,
    distance,
    target_cals,
  } = doc;

  const handleChangeWrapper = e => {
    if(e.target.name==="rounds"){
      e.target.value = parseInt(e.target.value)
    }
    setDirty(true)
    handleChange(e)
  };

  const handlRangesWrapper = (name, title) => e => {
    setDirty(true)
    handleRanges(name, title, e);
  };

  const setDirtyOnChange = () => {
    setDirty(true)
  }

  const openConfirmationDialog = () => {
    dirty ? showConfirmation('cancel') : onCancel()
  }
  const handleSubmit=()=>{
    if(!isNew && !dirty && !isDraftAvailable){
      onCancel();
      return;
    }
    if(isNew||!draftFeature){
      onSubmit();
    }
    else{
      showConfirmation('save');
    }
  }

  return (
    <Dialog
      toolbarClass="height-60"
      buttonColor="primary"
      open
      onClose={openConfirmationDialog}
      onSave={handleSubmit}
      title={isNew ? "Add Cardio " : "Edit Cardio"}
      titleFont="h3"
      paperClass="width-600"
    >
      <div className="fmt-30 fmx-20 fmb-10">
        <div className="d-flex fmb-20">
          <div className="flex-1 fmr-20 d-flex flex-column justify-content-between">
            <FormTextField fullWidth label="Reference Name" required>
              <TextField
                id="ref_name"
                autoComplete="off"
                defaultValue={ref_name}
                onBlur={handleChange}
                onChange={setDirtyOnChange}
                error={!!errors.ref_name}
                helperText={errors.ref_name}
                variant="outlined"
                disabled={loading}
                required
                type="text"
                InputProps={{
                  classes: {
                    root: "medium ",
                    input: "size_16_500 medium"
                  }
                }}
              />
            </FormTextField>
            <FormTextField fullWidth label="Display Name" required classes={{control:"mb-0"}}>
              <TextField
                InputProps={{
                  classes: {
                    root: "medium",
                    input: "size_16_500 medium"
                  }
                }}
                id="title"
                onBlur={handleChange}
                onChange={setDirtyOnChange}
                defaultValue={title}
                error={!!errors.title}
                helperText={errors.title}
                variant="outlined"
                disabled={loading}
                required
              />
            </FormTextField>
          </div>
          <ImageFileUpload
            id="thumbnail"
            thumbnail={thumbnail}
            onChange={handleChangeWrapper}
            size="small"
          />
        </div>

        <div className="d-flex ">
          <div className="w-50 pr-10">
            <FormTextField fullWidth label="Cardio Type" classes={{ root: 'w-50 m-0' }}>
              <Select
                fullWidth
                IconComponent={DropDownIcon}
                input={
                  <OutlinedInput
                    classes={{
                      root: "medium",
                      input: "size_16_500 select-medium"
                    }}
                    name="sub_type"
                  />
                }
                value={sub_type}
                onChange={handleChangeWrapper}
                disabled={loading}
                required
              >
                <MenuItem value="liss">Low Intensity</MenuItem>
                <MenuItem value="hiit">High Intensity</MenuItem>
              </Select>
            </FormTextField>
          </div>
          <div className="w-50 pl-10">
            <FormTextField fullWidth label="Target Calories">
              <TextField
                fullWidth
                name="target_cals"
                type="number"
                placeholder="0"
                onChange={handleChangeWrapper}
                onKeyDown={(e) => protectNumberInputs(e, { allowDecimal: true })}
                value={Number.isFinite(target_cals) ? roundMacros(target_cals) : ""}
                error={!!errors.target_cals}
                helperText={errors.target_cals}
                variant="outlined"
                disabled={loading}
                required
                InputProps={{
                  inputProps: {
                    min: 0
                  },
                  endAdornment: (
                    <InputAdornment position="end">Cals</InputAdornment>
                  ),
                  classes: {
                    root: "medium",
                    input: "size_16_500"
                  }
                }}
              />
            </FormTextField>
          </div>
        </div>
        {sub_type === "liss" && (
          <div>
          <div className="d-flex">
            <div className="w-50 pr-10">
              <FormTextField fullWidth label="Distance">
                <TextField
                  fullWidth
                  name="distance"
                  type="number"
                  placeholder="0"
                  onChange={handleChangeWrapper}
                  value={!!distance && convertor.getDisplayValue('distance', distance)}
                  error={!!errors.distance}
                  helperText={errors.distance}
                  variant="outlined"
                  disabled={loading}
                  required
                  InputProps={{
                    inputProps: {
                      min: 0
                    },
                    endAdornment: (
                      <InputAdornment position="end">{convertor.getDisplayUnit('distance')}</InputAdornment>
                    ),
                    classes: {
                      root: "medium",
                      input: "size_16_500"
                    }
                  }}
                />
              </FormTextField>
            </div>
            <div className="w-50 pl-10">
              <FormTextField fullWidth label="Duration">
                <DurationWrapper
                  formTextFieldProps={{classes: { control: "m-0" }}}
                  name="duration"
                  className="w-100"
                  value={duration}
                  amrap={false}
                  onChange={handleChangeWrapper}
                  loading={loading}
                  error={!!errors.duration}
                  helperText={!!errors.duration && errors.duration} 
                />
              </FormTextField>
            </div>
          </div>
          <div className="w-50 pr-10">
            <MinMaxField
              value={target_hr}
              name='target_hr'
              errors={errors}
              onChange={handleChangeWrapper}
              label="Target Heart Rate"
            />
          </div>
          </div>
        )}

        {sub_type === "hiit" && (
          <>
            <div className="d-flex">
              <div className="w-50 fpr-10">
                <FormTextField fullWidth label="Rounds" required>
                  <TextField
                    fullWidth
                    placeholder="0"
                    name="rounds"
                    type="number"
                    onChange={handleChangeWrapper}
                    onKeyDown ={e=>{
                      if(e.key === "."){
                        e.preventDefault();
                      }
                    }}
                    value={rounds}
                    error={!!errors.rounds}
                    helperText={errors.rounds}
                    variant="outlined"
                    disabled={loading}
                    required
                    InputProps={{
                      inputProps: {
                        min: 0,
                        step:"1",
                      },
                      classes: {
                        root: "medium",
                        input: "size_16_500"
                      }
                    }}
                  />
                </FormTextField>
              </div>

              <div className="w-50 fpl-10">
                <FormTextField fullWidth label="Duration">
                  <DurationWrapper
                    formTextFieldProps={{classes: { control: "m-0" }}}
                    name="duration"
                    className="w-100"
                    disabled
                    value={duration}
                    amrap={false}
                    onChange={handleChangeWrapper}
                    loading={loading}
                  />
                </FormTextField>
              </div>
            </div>

            <div className="d-flex">
              <div className="w-50 fpr-10">
                <FormTextField fullWidth label="Warm Up" required>
                  <DurationWrapper
                    formTextFieldProps={{classes: { control: "m-0" }}}
                    amrap={false}
                    name="duration"
                    hideHr
                    onChange={handlRangesWrapper("warm_up", "Warm Up")}
                    loading={loading}
                    value={handleV2vals(warm_up)}
                    error={!!errors.warm_up}
                    helperText={!!errors.duration && errors.duration} 
                  />
                </FormTextField>
              </div>
              <div className="w-50 fpl-10">
                <FormTextField fullWidth label="Cool Down" required>
                  <DurationWrapper
                    formTextFieldProps={{classes: { control: "m-0" }}}
                    amrap={false}
                    name="duration"
                    hideHr
                    onChange={handlRangesWrapper("cool_down", "Cool Down")}
                    loading={loading}
                    value={handleV2vals(cool_down)}
                    error={!!errors.cool_down}
                    helperText={!!errors.duration && errors.duration} 
                  />
                </FormTextField>
              </div>
            </div>

            <div className="d-flex">
              <div className="w-50 fpr-10">
                <FormTextField fullWidth label="High Intensity" required>
                  <DurationWrapper
                    formTextFieldProps={{classes: { control: "m-0" }}}
                    amrap={false}
                    name="duration"
                    hideHr
                    onChange={handlRangesWrapper("high_intensity", "High Intensity")}
                    loading={loading}
                    value={(high_intensity && high_intensity.duration) || 0}
                    error={
                      !!errors.high_intensity &&
                      !!errors.high_intensity.duration
                    }
                    helperText={!!errors.duration && errors.duration} 
                  />
                </FormTextField>
              </div>
              <div className="w-50 fpl-10">
                <MinMaxField
                  value={high_intensity && high_intensity.target_hr}
                  name='target_hr'
                  onChange={handlRangesWrapper("high_intensity", "High Intensity")}
                  label="Heart Rate"
                  loading={loading}
                  errors={!!errors.high_intensity && errors.high_intensity}
                />
              </div>
            </div>

            <div className="d-flex">
              <div className="w-50 fpr-10">
                <FormTextField fullWidth label="Steady State" required>
                  <DurationWrapper
                    formTextFieldProps={{classes: { control: "m-0" }}}
                    name="duration"
                    value={(steady_state && steady_state.duration) || 0}
                    amrap={false}
                    hideHr
                    onChange={handlRangesWrapper("steady_state", "Steady State")}
                    loading={loading}
                    error={
                      !!errors.steady_state && !!errors.steady_state.duration
                    }
                    helperText={!!errors.duration && errors.duration} 
                  />
                </FormTextField>
              </div>
              <div className="w-50 fpl-10">
                <MinMaxField
                  value={steady_state && steady_state.target_hr}
                  name='target_hr'
                  onChange={handlRangesWrapper("steady_state", "Steady State")}
                  label="Heart Rate"
                  loading={loading}
                  errors={!!errors.steady_state && errors.steady_state}
                />
              </div>
            </div>
          </>
        )}

        <FormTextField fullWidth label="Description">
          <TextField
            multiline
            rows="2"
            rowsMax="10"
            id="desc"
            onBlur={handleChange}
            onChange={setDirtyOnChange}
            defaultValue={desc}
            error={!!errors.desc}
            helperText={errors.desc}
            variant="outlined"
            disabled={loading}
            required
            InputProps={{
              classes: {
                root: "large",
                input: "size_16_500"
              }
            }}
          />
        </FormTextField>
      </div>
      {isConfirmationOpen && (
        <>
        {(isNew||!draftFeature)?(
            <Confirmation
            open
            handleChange={() => onCancel()}
            handleCancel={() => showConfirmation(null)}
          />)
          :
            <CustomConfirmation
                  handleClose={() => showConfirmation(null)}
                  handleDiscard={onCancel}
                  handleDraft={handleDraft}
                  handlePublish={handlePublish}
                  dirty={dirty}
                  showDiscard={dirty}
                />
        }
      </>
      )}
    </Dialog>
  );
};

export default CardioForm;
