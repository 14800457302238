import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import {
  findBookingStatus,
  STATUS_ABNB,
  STATUS_AVAILABLE,
  STATUS_BOOKED,
} from 'fitbud/utils/scheduling';
import React, { useMemo } from 'react';
import { AppointmentTooltip } from './Tooltip';
import { get, find } from 'lodash';
import useTrainers from 'fitbud/hooks/useTrainers';
import peopleSvg from 'fitbud/images/group_appointment.svg';
import {useAppointmentSlotStyle, useClassesSlotStyles, getColorBySlotStatus2 } from "./styles";

export const AppointmentContent = (props) => {
  const { session, height: sessionHeight } = props;
  const { user, status } = session;
  const classes = useSlotStyles({ indentation : session.indentation || 0 });
  const slotStatus = findBookingStatus(session);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const trainerId = get(session, 'trainer_id');
  const gcTitle = get(session, 'title', '');
  const custom_link = get(session, 'custom_link', 'https://google.com');
  const isGroupClass = !!get(session, 'class');
  const { data: trainers } = useTrainers();
  const appointmentClasses = useAppointmentSlotStyle();
  const classesClasses = useClassesSlotStyles();

  const trainerDetail = useMemo(() => {
    if (trainerId) return find(trainers, (data) => data._id === trainerId) || {};
    return {};
  }, [trainerId, trainers]);

  const handleClick = (e) => {
    e.preventDefault();
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };
  const handleClose = (e) => {
    if (!!e && !!e.preventDefault) e.preventDefault();
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  if (status === STATUS_ABNB) return null;
  if (status === STATUS_BOOKED) {
    return (
      <React.Fragment>
        <div
          className={
            clsx( isGroupClass ? getColorBySlotStatus2(classesClasses, slotStatus) : getColorBySlotStatus2(appointmentClasses, slotStatus), classes.contentRoot, classes.hoverDark) +
            ' fpl-5 cursor-pointer'
          }
          style={{marginLeft:2, marginRight:2}}
          onClick={handleClick}>
          {isGroupClass && (
            <div>
              <div className="d-flex align-items-center">
                <img src={peopleSvg} style={{ height: 13 }} />
                <Typography className={clsx("font_11_600")} noWrap>
                  <span> {gcTitle} </span> • <span className={classes.timeSlotFont}>{session.slot[0]}</span>
                </Typography>
              </div>
              {sessionHeight > 60 && (
                <div className="mt-5">
                  <Typography className={'font_11_500 text-dark-grey mb-5'} noWrap>
                    {get(trainerDetail, `data.name`, gcTitle)}
                  </Typography>
                  <Typography
                    component={'a'}
                    target="_blank"
                    href={custom_link}
                    className={'font_11_500 text-dark-grey text-decoration-underline d-block'}
                    noWrap>
                    {custom_link}
                  </Typography>
                </div>
              )}
            </div>
          )}
          {!isGroupClass && (
            <Typography className={clsx("font_11_600")} noWrap>
              {user && user.name} • <span className={classes.timeSlotFont}>{session.slot[0]}</span>
            </Typography>
          )}
        </div>
        <AppointmentTooltip session={session} open={open} anchorEl={anchorEl} handleClose={handleClose} />
      </React.Fragment>
    );
  }
  return (
    <div
      className={clsx(
        getColorBySlotStatus(classes, slotStatus),
        classes.contentRoot,
        'd-flex justify-content-center align-items-center'
      )}
    />
  );
};

export const useSlotStyles = makeStyles((theme) => ({
  contentRoot: {
    height: '100%',
    maxWidth: '100%',
    paddingTop: '3px',
    flex: 1,
    borderRadius: '3px',
    // border: ({ isConflicted }) => isConflicted ? `0.5px solid #ddd` : 'none',
  },
  hoverDark: {
    '&:hover': {
      filter: 'brightness(95%)',
    },
  },
  userName: {
    fontSize: '11px',
    // textShadow: '0px 1px 2px rgb(190 189 189 / 65%)',
  },
  link: {
    fontSize: 9,
    fontWeight: 500,
  },
  timeSlotFont: {
    color: "#6F8099",
  },
  avatarSize: {
    width: '30px',
    height: '30px',
  },
  root: {
    maxHeight: '95vh',
    overflow: 'scroll',
  },
  appointmentContainer: {
    marginLeft: '3.1%',
    '&:hover': {
      filter: 'brightness(95%)',
    },
  },
  //container status
  available: {
    backgroundColor: '#FFF',
    '&:hover': {
      backgroundColor: '#FFF',
    },
  },
  notAvailable: {
    backgroundColor: '#FBE8E8',
    '&:hover': {
      backgroundColor: '#FBE8E8',
    },
  },
  upcoming: {
    backgroundColor: '#E6F0FE',
    border: '1px solid #317FF5',
    border: ({ noBorder }) => (noBorder ? 'none' : `1px solid #317FF5`),
    '&:hover': {
      backgroundColor: '#E7F1FE',
    },
  },
  missed: {
    backgroundColor: '#FBE8E8',
    border: ({ noBorder }) => (noBorder ? 'none' : `1px solid #FF6161`),
    '&:hover': {
      backgroundColor: '#FBE8E8',
    },
  },
  completed: {
    backgroundColor: '#E1F5E2',
    border: ({ noBorder }) => (noBorder ? 'none' : `1px solid #00A708`),
    '&:hover': {
      backgroundColor: '#E1F5E2',
    },
  },
  ongoing: {
    backgroundColor: '#FFF0DE',
    border: ({ noBorder }) => (noBorder ? 'none' : `1px solid #F29900`),
    '&:hover': {
      backgroundColor: '#FFF0DE',
    },
  },
  booked: { backgroundColor: '#E7F1FE' },
}));

export function getColorBySlotStatus(classes, slotStatus) {
  if (slotStatus === 'missed') return classes['missed'];
  if (slotStatus === 'ongoing') return classes.ongoing;
  if (slotStatus === 'upcoming') return classes.upcoming;
  if (slotStatus === 'completed') return classes.completed;
  if (slotStatus === STATUS_AVAILABLE) return classes.available;
}
