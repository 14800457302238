import React from "react";
import _, { get } from "lodash";
import moment from "moment";
import { formatNo, pluralize } from "fitbud/utils/helpers";
import { DurationToHMSComp } from "fitbud/views/workouts/helperfn";
import clsx from "clsx";
import { Typography } from "@material-ui/core";
import { getEmptySchedule,multiplier,validateWoMlSchedule,
  generateMetaMap, processScheduleMeta,trimScheduleData } from "fitbud/views/schedulePlanner/helper";
import uuid from "uuid/v4";
import { isFilterEmpty as checkIfEmpty } from "fitbud/utils/helpers";
import {roundNumber} from 'fitbud/utils/helpers';
import { durationInTypes } from 'fitbud/views/schedulePlanner/helper';

export const getPlanIdsFromAccess = (access, id) => {
  const challengeAccess = _.get(access, "challenges");
  const packsForChallenge = _.get(challengeAccess, `${id}.packs`, []); //all access packs
  const except = _.get(challengeAccess, `${id}.except`, []); //all except packs
  const packsToAllChallenges = _.get(access, "packs.all.challenges", []); //all packs which have all access.
  const allIds = [...packsToAllChallenges, ...packsForChallenge, ...except];
  return _.filter(allIds, (id) => id !== "all");
};

export const createUpdateBanner = ({ doc, id, oldBanner={} }) => {
  //Configurable keys
  return {
    enrolled:'enrolled' in oldBanner? oldBanner['enrolled']: 0, //no of people enrolled
    id,
    from: doc.startEnrolment,
    nonav:'nonav' in oldBanner? oldBanner['nonav']: false,
    shows_cd: 'shows_cd' in oldBanner? oldBanner['shows_cd']:true,
    // tag: doc.title || "",
    title: doc.title || "",
    thumbnail: doc.thumbnail || "",
    till: doc.endEnrolment,
    type: "challenges",
    launchDate: doc.start,
    tag:'Challenge',
    show_title:'show_title' in oldBanner ? oldBanner['show_title']:true
  };
};
//
export const end = ({ start, durationInDays, duration, durationType }) => {
  const _durationInDays = !_.isUndefined(durationInDays)
    ? durationInDays
    : duration * multiplier(durationType);
  const startInMoment = moment.isMoment(start)
    ? start
    : moment(start, "YYYYMMDD");
  return moment(startInMoment).add(_durationInDays, "day");
};
/*CASES:
 1.currStep:int | 'all' -> 'all' is only sent at time of activation
*/
export const formStepValid = (state, currStep, packAccess) => {
  //currStep: ['all',int]-> 'all' is sent on activation only
  let errors = {};
  //validations;
  if (currStep === 0 || currStep === "all") {
    const { ref_name, title } = state;
    if (!ref_name || !(ref_name || "").trim()) {
      errors["ref_name"] = "Please enter Reference name";
    }
    if (!title || !(title || "").trim()) {
      errors["title"] = "Please enter Title";
    }
  }
  if (currStep === 1 || currStep === "all") {
    const { start, end, startEnrolment, endEnrolment, durationType, duration } = state;
    const formatStart = moment(start).format("YYYYMMDD");
    const formatEnd = moment(end).format("YYYYMMDD");
    const formatStartEnrol = moment(startEnrolment).format("YYYYMMDD");
    const formatEndEnrol = moment(endEnrolment).format("YYYYMMDD");
    const today = moment().startOf("day").format("YYYYMMDD");
    //WHEN NOT TO RUN: after activation
    if(!(currStep!=='all' && !!state.active)){
    //All dates to be in present or future
      if (formatStart < today)
        errors["start"] = "Start date should not be in past";
      if (formatEnd < today) errors["end"] = "End date  should not be in past";
      if (formatStartEnrol < today)
        errors["startEnrolment"] = "Start Enrolment should not be in past";
      if (formatEndEnrol < today)
        errors["endEnrolment"] = "End Enrolment should not be in past";
    }
      
    //Duration validation
    if((durationType === 'days' && duration > 365) ||
    (durationType === 'weeks' && duration > 52) ||
    (durationType === 'months' && duration > 12)) {
      errors['duration']='Duration cannot be more than an year';
    }

    /* 
      startEnrolment <= start  <= end
      startEnrolment <= endEnrolment <= end
    */
    //Expected: start date of challenge <= end date of challenge
    if (formatStart > formatEnd) {
      errors["start"] =
        "Challenge Start Date cant be greater than Challenge  End Date";
    }
    //Expected: startEnrolment <= endEnrolment
    if (formatStartEnrol > formatEndEnrol) {
      errors["startEnrolment"] =
        "Start Enrolment cant be greater than End Enrolment";
    }
    //Expected: startEnrolment <= start date of Challenge
    if (formatStartEnrol > formatStart) {
      errors["startEnrolment"] =
        "Start Enrolment cant be greater than Challenge Start Date";
    }
    //Expected: endEnrolment <= end date of Challenge
    if (formatEndEnrol > formatEnd) {
      errors["endEnrolment"] =
        "End Enrolment cant be greater than Challenge End Date";
    }
  }
  if(currStep === 2 || currStep === 'all'){
    if(!!state.drop_in_enabled){
      const {price} = get(state,'pack',{});
      if(!Number(price)){
        errors["pack"] = "Please enter single challenge fee";
      }
    }
    if(!!packAccess && currStep!=='all'){
      if(!packAccess.access.includes('all') && !packAccess.access.length){
        errors["accessType"] = "Please select packs for specific access";
      }
    }
  }
  if(currStep==='all'){
    //schedule checks
      if(checkIfEmpty(get(state,['program','data'],{}))){
        errors['schedule']='Please add a workout schedule to this challenge before activation.'
      }
      const [schRestDayErr] = validateWoMlSchedule(
        {...state.program},
        "workout"
      );
      // //This block may never be in use, as per existing flow
      // if(!checkIfEmpty(schRestDayErr) && !errors['schedule']){
      //   errors['schedule']='Workout Schedule cannot have empty Days , please mark them as Rest Days';
      // }
    }
  return { ...errors };
};

export const formatLdrBoardColValue = (i, metric) => {
  const val = get(i, ["summary", metric]);
  if (metric === "duration")
    return <DurationToHMSComp list={true} duration={val} />;
  else if(metric==='calories')
    return !val?'-':`${roundNumber(val/1000,1)} k`
  return formatNo(val);
};
export const getDateRangeNDuration = (start, end) => {
  //YYYYMMDD
  let date = "",
    duration = "";
  start = moment(start, "YYYYMMDD").startOf("day");
  end = moment(end, "YYYYMMDD").endOf("day");
  const difference = end.diff(start, "days") + 1;
  date = (
    <>
      <b>{moment(start).format("DD")}</b>&nbsp;
      {`${moment(start).format("MMM")} - `}
      <b>{moment(end).format("DD")}</b>&nbsp;
      {moment(end).format("MMM YYYY")}
    </>
  );
  duration = difference;
  // duration=(<span>
  //     {difference}<span className='font-weight-500'> {pluralize(difference,'day')}</span>
  //     </span>);
  return [date, duration];
};
export const hasChallStarted = ({ active, start, end }) => {
  //start in 'YYYYMMDD'
  if (!active)
    return {
      status: "Draft",
      style: { backgroundColor: "#F299001F", color: "#F29900" },
    };
  else {
    const current = moment().format("YYYYMMDD");
    if (moment(current).isBefore(start)) {
      const x = moment(start)
        .startOf("day")
        .diff(moment().startOf("day"), "days");
      return {
        status: `Starts in ${x} ${pluralize(x, "day")}`,
        style: { backgroundColor: "#317FF51F", color: "#317FF5" },
      };
    }
    if (moment(current).isBetween(start, end, undefined, "[]"))
      return {
        status: "Started",
        style: { backgroundColor: "#00b33c1F", color: "#00b33c" },
      };
    if (moment(current).isAfter(end))
      return {
        status: "Ended",
        style: { backgroundColor: "#F14E3F1F", color: "#F14E3F" },
      };
  }
  return { msg: "", style: {} };
};
export const Info = ({ children, className, type = "info" }) => {
  if (type === "warning") {
    return (
      <div
        className={clsx(`p-15`, className)}
        style={{ borderRadius: 6, backgroundColor: "#FFF5E0" }}
      >
        <Typography className="font_14_500 text-0d0d0d">{children}</Typography>
      </div>
    );
  }
  return (
    <div
      className={clsx(`bg-info p-15`, className)}
      style={{ borderRadius: 6 }}
    >
      <Typography className="font_14_500 text-0d0d0d">{children}</Typography>
    </div>
  );
};
export const updateChallProg = (updatedDoc, program) => {
  let out = {
    ..._.omit(program,['id']),
    ..._.pick(updatedDoc,[...SCHD_N_CHALL_KEYS])
  };
  if(updatedDoc.duration<program.duration){
     //trim case
     out['data']=trimScheduleData(out['data'],updatedDoc.duration);
  }
  const [e, f] = validateWoMlSchedule(
    {...out},
    "workout"
  );
  if(!_.isEmpty(e)){
    //correct rest structure
    const weekKeys=Object.keys(e || {});
    weekKeys.forEach((weekKey,i)=>{
      const weekData = _.get(e, weekKey, []);
      Object.keys((weekData || {})).forEach((dayKey) => {
        out = _.set(out, ["data", weekKey, dayKey, 'wo'], ['rest']);
      });
    })
  }
  const itemsMeta = generateMetaMap(out, 'workout');
  const finalDoc=processScheduleMeta({...out,meta:{...itemsMeta}});
  return { ...finalDoc };
};
export const createNewChallengeProg = (updatedDoc,challengeId) => {
  return {
    ...getEmptySchedule("workout"),
    ..._.pick(updatedDoc,[...SCHD_N_CHALL_KEYS]),
    archive: true,
    challengeId
  };
};
export const createEditChallPaymntPck=(isNew,updatedDoc,pack)=>{
  const {price,currency}=pack||{};
  if(isNew){
    return {
      //copying from challenges
      title:updatedDoc.title,
      ref_name:updatedDoc.ref_name,
      description:updatedDoc.desc||'',
      image:updatedDoc.thumbnail||'',
      additional:updatedDoc.additional||[],
      fixed_start:updatedDoc.start,
      startEnrolment:updatedDoc.startEnrolment,
      endEnrolment:updatedDoc.endEnrolment,
      // --- 
      type:'add_on',
      add_on_type:'challenges',
      archive:true,
      challengeId:updatedDoc.challengeId,
      // hardcoded
      activeV2:!!updatedDoc.drop_in_enabled && !!updatedDoc.active,
      active_iosV2:true,
      index:0,
      program:updatedDoc.program,
      price_opts:[{
        mode:'one_time',
        activeV2:!!updatedDoc.drop_in_enabled && !!updatedDoc.active,
        active_androidV2:true,
        active_iosV2:true,
        //copying from drop-in (WEB UI)
        currency: currency||'USD',
        price:price ? Number(price) : 0,
        //copied from challenge doc
        duration:durationInTypes(updatedDoc.duration,updatedDoc.durationType),
        duration_type:updatedDoc.durationType,
        id:uuid()
      }],
    };
  }
  return({
     //copying from challenges
     title:updatedDoc.title,
     ref_name:updatedDoc.ref_name,
     description:updatedDoc.desc||'',
     image:updatedDoc.thumbnail||'',
     additional:updatedDoc.additional||[],
     fixed_start:updatedDoc.start,
     startEnrolment:updatedDoc.startEnrolment,
     endEnrolment:updatedDoc.endEnrolment,
     activeV2:!!updatedDoc.drop_in_enabled && !!updatedDoc.active,
     price_opts:[{
      ...pack,
      //copied from challenge doc
      duration:durationInTypes(updatedDoc.duration,updatedDoc.durationType),
      duration_type:updatedDoc.durationType,
      activeV2:!!updatedDoc.drop_in_enabled && !!updatedDoc.active,
    }],
  })
}

export const getPackCurrNPrice=(pack)=>{
  return({...get(pack,['price_opts',0],{})})
};

const SCHD_N_CHALL_KEYS=['desc','duration','durationType','ref_name','thumbnail','title'];
const PACK_N_CHALL_KEYS=['additional','desc','start','thumbnail','duration','durationType','ref_name','title','pack','drop_in_enabled','startEnrolment','endEnrolment','active'];


export const shallUpdate=(updatedDoc,oldDoc)=>{
  const obj= {
    schedule:false,
    pack:false
  }
  obj['schedule']=!_.isEqual(
      _.pick(updatedDoc,SCHD_N_CHALL_KEYS),
      _.pick(oldDoc,SCHD_N_CHALL_KEYS),
  );
  obj['pack']=!_.isEqual(
    _.pick(updatedDoc,PACK_N_CHALL_KEYS),
    _.pick(oldDoc,PACK_N_CHALL_KEYS),
  )
  return {...obj}
};



//----------------   ACCESS    ---------------------
export const getAccessType = (packAccess) => {
  const { access = [], except } = packAccess || {};
  if (access.includes("all")) {
    return "all";
  }
  else if(!!access.length){
    if(access[0]==='none'){
      return 'none';
    }
    return 'partial';
  }
};
const findElsePush = (arr, id) => { //changes arr by ref
  const index = arr.findIndex((i) => i === id);
  if (index === -1 ) {
    arr.push(id);
  }
};
const findNPop = (arr, id) => { //changes arr by ref
  const index = arr.findIndex((i) => i === id);
  if (index > -1) {
    arr.splice(index, 1);
  }
};
const updatePacks=(packs={},packId,packChAccess,packChExcept)=>{
    packs={
      ...packs,
      [packId]:{
        ...get(packs,[packId],{}),
        challenges:[...packChAccess],
        chExcept:[...packChExcept]
      }
    }
    return {...packs};
}
/*
 1.packsWAllChall-> packs having access to all challenges
 2.challWAllPacks-> challenge having access to all packs
 3.chPrPacks-> challenge previous packs access
 4.chPrExcept-> challenge previous except access
 5.chCurrPacks-> challenge current packs access
 6.chCurrExcept-> challenge current except access
 7.thisChallHasAllAccess-> current challenge has 'all' access?

 Case 1. If challenge has access to 'all' packs, then add it to ['all'] 
         else remove it.
 Case 2. Iterate through challenge-access-packs individual access(ch.access.forEach(i)),
         if they have 'all' access, remove chId from their except array, 
         else add chId in their challenges array
 Case 3. If current Challenge has specific access, enter ChId in 
         except array of Packs-With-all-Challenge-access
 Case 4. If this challenge has 'all' access with empty 'except' array, then remove ChId from
         except array of Packs-With-all-Challenge-access
 Case 5. previous challenge access might have packs that are removed from current challenge-access, 
         if these packs have 'all' access then ignore
         else remove chId from their access.
 Case 6. Iterate through challenge-except array, if these packs have 'all' access, add ChId in their except,
         else remove ChId from their access array.
 Case 7. previous challenge except might have packs that are removed from current challenge-except, 
         if these packs have 'all' access then remove chId from their except array
         else ignore
*/

export const getAccess = (chId, currentAccess, accessDoc) => {
  //main packages
  let packs = get(accessDoc, ["packs"], {});
  let challenges = get(accessDoc, ["challenges"], {});

  //entities with ALL access
  const packsWAllChall = get(packs, ["all", "challenges"], []); //1
  const challWAllPacks = get(challenges, ["all", "packs"], []); //2

  //previous challenge access state
  const chPrPacks = get(accessDoc, ["challenges", chId, "packs"], []); //3
  const chPrExcept = get(accessDoc, ["challenges", chId, "except"], []); //4

  //challenge current access state
  const chCurrPacks = get(currentAccess, ["access"], []); //5
  const chCurrExcept = get(currentAccess, ["except"], []); //6

  //questions
  const thisChallHasAllAccess = chCurrPacks.includes("all"); //7

  //Case 1:
  if (thisChallHasAllAccess) {
    findElsePush(challWAllPacks, chId);
  } else {
    findNPop(challWAllPacks, chId);
  }
  //update challenge access
  challenges = {
    ...challenges,
    [chId]: {
      packs: [...chCurrPacks],
      except: [...chCurrExcept],
    },
    all: {
      ...get(challenges, "all", {}),
      packs: [...challWAllPacks],
    },
  };

  //Case 2:
  const currChallSpcfcPacks = chCurrPacks.filter((i) => i !== "all");
  currChallSpcfcPacks.forEach((p) => {
    const packChAccess = get(packs, [p, "challenges"], []);
    const packChExcept = get(packs, [p, "chExcept"], []);
    const thisPackHasAllAccess = packChAccess.includes("all");

    if (thisPackHasAllAccess) {
      findNPop(packChExcept, chId);
    } else {
      findElsePush(packChAccess, chId);
    }
    //update challenge access
    packs={...updatePacks(packs,p,packChAccess,packChExcept)};
  });

  //Case 3:
  if (!!currChallSpcfcPacks.length) {
    packsWAllChall.forEach((p) => {
      if (chCurrPacks.includes(p)) return;
      const packChAccess = get(packs, [p, "challenges"], []);
      const packChExcept = get(packs, [p, "chExcept"], []);
      findElsePush(packChExcept, chId);
      //update challenge access
      packs={...updatePacks(packs,p,packChAccess,packChExcept)}
    });
  }

  //Case 4:
  if (thisChallHasAllAccess && !chCurrExcept.length) {
    packsWAllChall.forEach((p) => {
      const packChAccess = get(packs, [p, "challenges"], []);
      const packChExcept = get(packs, [p, "chExcept"], []);
      findNPop(packChExcept, chId);
      //update challenge access
      packs={...updatePacks(packs,p,packChAccess,packChExcept)}
    });
  }

  //Case 5:
  const chRemovedPacks = chPrPacks.filter((i) => 
    i !== "all" && !chCurrPacks.includes(i)
  );
  chRemovedPacks.forEach((p) => {
    const packChAccess = get(packs, [p, "challenges"], []);
    const packChExcept = get(packs, [p, "chExcept"], []);
    const thisPackHasAllAccess = packChAccess.includes("all");
    if (thisPackHasAllAccess) {
      //TODO: do nothing?
    } else {
      findNPop(packChAccess, chId);
    }

    //update challenge access
    packs={...updatePacks(packs,p,packChAccess,packChExcept)}
  });

  //Case 6:
  chCurrExcept.forEach((p) => {
    const packChAccess = get(packs, [p, "challenges"], []);
    const packChExcept = get(packs, [p, "chExcept"], []);
    const thisPackHasAllAccess = packChAccess.includes("all");
    if (thisPackHasAllAccess) {
      findElsePush(packChExcept, chId);
    } else {
      findNPop(packChAccess, chId);
    }
    //update challenge access
    packs={...updatePacks(packs,p,packChAccess,packChExcept)};
  });

  //Case 7:
  const chRemovedExcept = chPrExcept.filter((i) => !chCurrExcept.includes(i));
  chRemovedExcept.forEach((p) => {
    const packChAccess = get(packs, [p, "challenges"], []);
    const packChExcept = get(packs, [p, "chExcept"], []);
    const thisPackHasAllAccess = packChAccess.includes("all");
    if (thisPackHasAllAccess) {
      findNPop(packChExcept, chId);
    }
    //update challenge access
    packs={...updatePacks(packs,p,packChAccess,packChExcept)};
  });
  return {
    ...accessDoc,
    challenges,
    packs,
  };
};
