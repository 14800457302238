import React, { useState, useMemo } from "react";
import {
  TextField,
  InputAdornment,
  MenuItem,
  Select,
  OutlinedInput
} from "@material-ui/core";
import DropDownIcon from "@material-ui/icons/ExpandMore";
import { FormTextField } from "fitbud/components/form-fields";
import Dialog from "fitbud/components/Dialog";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment-timezone";
import ArrowIcon from "@material-ui/icons/KeyboardArrowDown";
import { durationInTypes } from "fitbud/views/schedulePlanner/helper";
import { oprtnlDateFormat } from "fitbud/utils/constants";
import NumUnitInput from "fitbud/components/numUnitInput";

export const DatePickerField = props => {
  const InputProps = {
    classes: {
      root: "medium",
      input: "size_16_500 medium"
    },
    ...props.InputProps,
    endAdornment: (
      <InputAdornment position="end">
        <ArrowIcon color="disabled" />
      </InputAdornment>
    )
  };
  return (
    <FormTextField fullWidth label= {!props.hideLabel && "Start Date"} >
      <TextField {...props} InputProps={InputProps} />
    </FormTextField>
  );
};
export const StartDateDurationField = ({
  planId,
  disabled = false,
  durationType = 0,
  handleDateChange,
  startDate,
  minDate,
  duration,
  handleDurationChange,
  errors = {},
  disableDuration = false,
  isSubscriptionMode= false,
}) => {
  return (
    <div className="d-flex">
      <DatePicker
        disabled={disabled}
        autoOk
        value={startDate.isValid() ? startDate : moment()}
        id="date-picker-dialog"
        inputVariant="outlined"
        onChange={handleDateChange}
        KeyboardButtonProps={{
          "aria-label": "change date"
        }}
        format="DD MMM YYYY"
        TextFieldComponent={DatePickerField}
        minDate={minDate}
      />

      {/* note the XXX comment below. We are forced to use the key-remount-hack because of that */}
      {!isSubscriptionMode && <NumUnitInput
        key={planId}
        ignoreInitial
        id="duration"
        label="Duration"
        unit={durationType}
        value={duration}
        error={!!errors.duration}
        helperText={errors.duration}
        onChange={handleDurationChange}
        variant="outlined"
        LabelProps={{classes: {control: "fml-20"}}}
        InputProps={{classes: {root: "medium", input: "size_16_500 medium"}}}
        required />}
    </div>
  );
};


export const PlanField = ({priceDoc, handleChangeWrapper, packDoc, selfPurchased = false, isSubscription = false}) => {
  let opt0 = 'Custom';
  const list = useMemo(() => {
    const out = [];
    if (!packDoc || !packDoc.length) return out;
    packDoc.forEach(plan => {
      if (plan.type === 'add_on' && plan.add_on_type !== 'membership') return;
      if (!plan || !plan.price_opts || !plan.price_opts.length) return;
      if (!plan.active && !plan.activeV2 && !plan.hidden) return;
      let hasSub = false, count = out.length;;
      plan.price_opts.forEach(pricing => {
        if (!pricing || !(pricing.active || pricing.activeV2) || !pricing.mode) return;
        if (pricing.mode === 'subscription') {
          hasSub = true;
          return;
        }
        out.push({ label: plan.ref_name + " - " + pricing.duration + " " + pricing.duration_type, pack_id: plan.pack_id, id: pricing.id });
      });
      if (count === out.length && hasSub)
        out.push({ label: plan.ref_name, pack_id: plan.pack_id, id: 'custom' });
    });
    return out;
  }, [packDoc]);
  if (selfPurchased) {
    opt0 = priceDoc.ref_name;
    if (isSubscription)
      opt0 += ' - Subscription';
    else
      opt0 += ` - ${durationInTypes(priceDoc.duration, priceDoc.duration_type)} ${priceDoc.duration_type.slice(0, -1)}`;
  }
  return(
    <FormTextField fullWidth label={selfPurchased ? "Purchased Plan" : "Select Plan"}>
      <Select
        IconComponent={DropDownIcon}
        value={priceDoc && priceDoc.pack_id && !selfPurchased ? `${priceDoc.id || 'custom'},${priceDoc.pack_id}` : "custom"}
        disabled={!!selfPurchased}
        className={!!selfPurchased ? 'text-body' : ''}
        input={
          <OutlinedInput
            classes={{
              root: "medium",
              input: "size_16_500 select-medium",
            }}
            name="pack"
          />
        }
        onChange={handleChangeWrapper}
        required
      >
        <MenuItem key="custom" value="custom">{opt0}</MenuItem>
        {list.map(({label, pack_id, id}, index) => {
          return (
            <MenuItem key={`${index}-${pack_id}-${id}`} value={id + ',' + (pack_id || '')}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
    </FormTextField>
  )
};

const CreateEditForm = props => {
  const {
    doc,
    errors,
    loading,
    isNew,
    onSubmit,
    handleChange,
    onCancel,
    packDoc,
    handleChangeWrapper,
    pricingDoc,
    isPlanChange,
  } = props;
  const {
    profile,
    duration: durationInDays,
    durationType: userDurationType,
    startDate: userStartDate,
    plan_assign,
    currPurchase,
    onboarding_complete,
  } = doc || {};
  const priceDoc = pricingDoc[0] || (plan_assign && plan_assign.pack) || (currPurchase && currPurchase.pack);
  const selfPurchased = !!currPurchase && !!currPurchase.pack;
  const { name, email, time_zone } = profile || {};
  const priceDocDurationType = !!isPlanChange && priceDoc ? priceDoc.duration_type : userDurationType;
  const minDate = moment.tz(time_zone).subtract(1, 'day');
  const duration = !!isPlanChange ? durationInDays : durationInTypes(durationInDays, userDurationType);
  console.log("duration",duration)
  const [startDate, updateStartDate] = useState(!isNew ? moment(userStartDate, oprtnlDateFormat) : moment());
  const isSubscriptionMode = isNew ? false :(priceDoc && priceDoc.mode === 'subscription') // to disable duration field if the plan is subscription

  function handleDateChange(date) {
    updateStartDate(date);
    handleChange({ startDate: date });
  }
  function handleDurationChange(e) {
    if (!e.target) return false;
    const {display, unit} = e.target;
    // XXX we're sending display value, not DB value; Coz users of this component are doing multiplication themself a.t.m :shrug:
    handleChange({duration: Number(display), durationType: unit});
  }

  return (
    <Dialog
      toolbarClass="height-60"
      buttonColor="primary"
      open
      onClose={onCancel}
      onSave={onSubmit}
      title={isNew ? "Add User " : "Edit User"}
      titleFont="h3"
      paperClass="width-600"
    >
      <div className="fp-20">
        {
          !onboarding_complete && <>
          <FormTextField fullWidth label="Name" required>
            <TextField
              id="name"
              autoComplete="off"
              value={name}
              onChange={handleChange}
              error={!!errors.name}
              helperText={errors.name}
              variant="outlined"
              disabled={loading}
              required
              type="text"
              InputProps={{
                classes: {
                  root: "medium",
                  input: "size_16_500 medium"
                }
              }}
            />
          </FormTextField>
          <FormTextField fullWidth label="Email" required>
            <TextField
              id="email"
              autoComplete="off"
              value={email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
              variant="outlined"
              disabled={loading || !isNew}
              required
              type="text"
              InputProps={{
                classes: {
                  root: "medium",
                  input: "size_16_500"
                }
              }}
            />
          </FormTextField>
          </>
        }
        <PlanField packDoc={packDoc} priceDoc={priceDoc} selfPurchased={selfPurchased}
          handleChangeWrapper={handleChangeWrapper} isSubscription={isSubscriptionMode} />
        <StartDateDurationField
          planId={(pricingDoc && pricingDoc.length && pricingDoc.id) || 'custom'}
          disabled={loading}
          durationType={userDurationType || priceDocDurationType || 'weeks'}
          startDate={startDate}
          handleDateChange={handleDateChange}
          minDate={minDate}
          duration={duration}
          handleDurationChange={handleDurationChange}
          errors={errors}
          disableDuration={!!doc["aplan"]}
          key={`duration-${priceDoc && priceDoc.id}`}
          isSubscriptionMode={isSubscriptionMode}
        />
      </div>
    </Dialog>
  );
};

export default CreateEditForm;
