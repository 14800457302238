import React from 'react';
import { Typography } from "@material-ui/core";

export const PublishAlert=({to_publish})=>{
    let msg='';
    if(to_publish){
        msg="Document will be published after Media Upload."
    }
    return(
        <div className="border border-primary p-15 text-0d0d0d rounded mb-20" style={{background:"#F0F8FD"}}>
            <Typography className="font_13_500">{msg}</Typography>
        </div>
    )
}