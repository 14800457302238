import React from "react";
import clsx from "clsx";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/CloseRounded';
import {  Typography } from "@material-ui/core";
import Loader from "fitbud/components/CircularLoader";
import {DISCARD_MSG} from "fitbud/utils/constants";

const Confirmation = ({
  open,
  handleClose,
  handleCancel,
  handleChange,
  title="Discard Changes",
  msg=DISCARD_MSG,
  submsg,
  confirmOption="Yes, Discard",
  cancelOption="Cancel",
  cancelActionClassName="f-sm-med",
  confirmActionClassName="f-sm-med",
  paperClass = "width-400",
  leftActionText, 
  leftActionClick,
  showCloseIcon = false,
  customAction=null,
  loading=false
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose || handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={Object.assign({}, { paper: clsx(paperClass, "p-20") })}
    >
      <Typography id="alert-dialog-title" className="font_18_600 mb-10">
        {title}
        {showCloseIcon && handleClose && (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            style={{
              position: "absolute",
              right: 8,
              top: 14,
              color: "#37404D",
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Typography>
      {/*---- content ----*/}
      {!!msg && <Typography className="font_15_500 text-65768C">{msg}</Typography>}
      {submsg && <Typography className="font_15_500 text-65768C">{submsg}</Typography>}
      {/*--- content end ---*/}
      {customAction||<DialogActions className="mt-20 p-0">
      {Boolean(handleCancel) && <Button
          variant="outlined"
          onClick={handleCancel}
          className={clsx(cancelActionClassName,!loading && 'border-primary')}
          color="primary"
          disableElevation
          classes={{ label: "font_15_700" }}
          disabled={loading}
        >
          {cancelOption}
        </Button>}
        <Button
          variant="contained"
          onClick={handleChange}
          className={clsx(confirmActionClassName, "ml-20 ",loading && "position-relative")}
          color="primary"
          autoFocus
          disableElevation
          classes={{ label: "font_15_700" }}
          disabled={loading}
        >
          {confirmOption}
          {loading && <Loader centerAlign={true} size={30}/>}
        </Button>
      </DialogActions>}
    </Dialog>
  );
};

export default Confirmation;
