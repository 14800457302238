import React, { useState, useContext, useEffect } from 'react';
import {
  IconButton,
  makeStyles,
  Typography,
  InputBase,
  InputAdornment,
  LinearProgress,
  Fade,
  Divider,
  List,
} from '@material-ui/core';
import MuiSearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/ClearRounded';
import { useSnackbar } from 'notistack';
import useDebounce from 'fitbud/hooks/useDebounce';
import { fetchData } from 'fitbud/api';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { handleError } from 'fitbud/utils/helpers';
import _ from 'lodash';
import { DumbList, Placeholder } from '.';
import {getLabel, getDType, getType} from "fitbud/components/catalog/util";


const styles = makeStyles({
  container: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 1,
    background: '#F2F4F7',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& .title': {
      fontSize: 18,
      fontWeight: 600,
      textTransform: 'capitalize',
    },
    '& .input': {
      borderRadius: '20px',
      height: 40,
      border: '1px solid #D3D9DB',
      background: '#fff',
      padding: '0px 10px',
    },
   
    '& .loading': {
      position: 'absolute',
      bottom: -2,
      left: 0,
      width: '100%',
    },
  },
});

const PER_PAGE = 15;

export const CatalogListSearch = (props) => {
  const {
    closeSearch,
    keyName,
    ...listProps
  } = props;
  const { cid } = useContext(FirebaseAuthContext);

  const [q, updateQ] = useState('');
  const [docs, setDocs] = useState([]);
  const [count, setCount] = useState(null);
  const [nextPage, setNextPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showCount, setShowCount] = useState(false);
  const classes = styles();
  const { enqueueSnackbar } = useSnackbar();
  const placeholder = `Search ${getLabel(keyName)}`;

  const debouncedSearchTerm = useDebounce(q, 500);

  function handleChange(e) {
    setShowCount(false);
    updateQ(e.target.value);
  }

  const queryFn = async ({ q, page, per_page = 15 }) => {
    const dtype = getDType(keyName);
    const _keyName = getType(keyName)
    const res = await fetchData(_keyName, cid, page, q, per_page, dtype, null);
    return res.data;
  };

  const fetchPage = async (query = q, page = nextPage) => {
    setLoading(true);
    try {
      const res = await queryFn({ q: query, page, per_page: PER_PAGE });
      setDocs((docs) => (page ? docs : []).concat(res.data));
      setNextPage(res.data.length === PER_PAGE ? page + 1 : null);
      if (!page) setCount(res.count);
      setShowCount(true);
    } catch (error) {
      handleError(error, enqueueSnackbar);
    }
    setLoading(false);
  };
  const handleScroll = _.debounce((e) => {
    if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 100) {
      if (nextPage) fetchPage();
    }
  }, 500);

  useEffect(() => {
    if (q) fetchPage(q, 0);
    else {
      setDocs([]);
      setShowCount(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const handleRefresh = () => {
    if (q) fetchPage(q, 0);
  };

  const isEmpty = !!q && !!debouncedSearchTerm && q === debouncedSearchTerm && !docs.length && !loading;
  return (
    <div className={classes.container}>
      <div className="fpx-20 fpy-15 position-relative">
        <div className="d-flex align-items-center justify-content-between fmb-10">
          <Typography className="title">
            Search {showCount && <span>({loading && !count ? '...' : count || 0})</span>}{' '}
          </Typography>
          <IconButton onClick={closeSearch}>
            <CloseIcon />
          </IconButton>
        </div>
        <InputBase
          value={q}
          onChange={handleChange}
          fullWidth
          autoFocus
          placeholder={placeholder}
          startAdornment={
            <InputAdornment position="start">
              <MuiSearchIcon className="text-black" />
            </InputAdornment>
          }
          className="input"
        />
        {loading && <LinearProgress className="loading" />}
      </div>
      <Divider className='dense' />
      {/* list-part */}
      <List
        disablePadding
        id={keyName}
        className="overflow-auto flex-1 bg-white"
        onScroll={(e) => {
          e.persist();
          handleScroll(e);
        }}
      >
        {!isEmpty && <DumbList
          docs={docs}
          {...listProps}   
          />}
        {!!isEmpty && !loading && <Placeholder keyName={keyName} isSearch={true} />}
        {!q && <Placeholder keyName={keyName} label={placeholder} initialSearch={true} />}
      </List>
    </div>
  );
};

