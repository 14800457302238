import clsx from "clsx";
import { ClrdButton } from "fitbud/components/form-fields";
import ImageFileUpload from "fitbud/components/imageSelector";
import { UpArrowIcon } from "fitbud/icons/upArrow";
import { captureFrame } from "fitbud/utils/services";
import { FrameSelectionDialog } from "fitbud/views/workouts/videoEditor";
import _ from "lodash";
import React, { useState } from "react";
import { uploaderStyles } from "./mediaUploader";

const thumbnailMsg = "Upload Thumbnail";
const thumbnailMsgSec = "1080x1080 • Max 5 mb";
export const ThumbnailUploader = (props) => {
  const { 
    onChange, mediaUrl, thumbnail, isUploadCase, setThumbnailLoad, 
    fetchThumbnail, uploadThumbnail, media, isThumbnailLoading,
    aspectRatio = 1
  } = props;
  const classes = uploaderStyles();
  const [openFrameSelector, setFrameSelector] = useState(false);

  const closeFrameSelector = () => {
    setFrameSelector(false);
  };

  const onFrameSelect = async () => {
    setThumbnailLoad(true);
    const thumbnail = await captureFrame(true);
    closeFrameSelector();
    if(thumbnail) await uploadThumbnail(thumbnail);
    setThumbnailLoad(false)
  };

  const isImage = _.get(media, '0.type', "") === "image";
  return (
    <div className="d-flex flex-column justify-content-between">
      <ImageFileUpload
        id="thumbnail"
        aspectRatio={aspectRatio}
        thumbnail={thumbnail}
        onChange={onChange}
        loading={isThumbnailLoading}
        size="small"
        text={thumbnailMsg}
        secondaryText={thumbnailMsgSec}
        UploadIconComponent={() => (
          <div className={clsx(classes.uploadIcon)}>
            <UpArrowIcon style={{ fill: "none" }} />
          </div>
        )}
      />
      {openFrameSelector && (
        <FrameSelectionDialog
          onClose={closeFrameSelector}
          selectFrame={onFrameSelect}
          media={media}
          actionText="Save"
        />
      )}
      {!isImage && mediaUrl &&
        <ClrdButton
          onClick={!isThumbnailLoading && (isUploadCase ? () => setFrameSelector(true) : fetchThumbnail)}
          color="light-primary"
          className="shadow-none mt-20"
          classes= {{ root: "f-height-36" }}
          style={{ width: '168px'}}
          disabled={isThumbnailLoading}
        >
          {isUploadCase ? "Select Frame" : `Import Thumbnail` }
        </ClrdButton>
      }
    </div>
  );
}
