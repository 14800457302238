import firebase from "fitbud/firebase";
import axios from "axios";

const getBFFClient = (token) => axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://consultoria-dot-fitbudd-prod-v1.uc.r.appspot.com',
  headers: {
    'fb-project': process.env.REACT_APP_FB_PROJECT,
    'fb-token': token
  }
});

export const genProgram = async (uid, force = false) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post('/consultoria/program', { uid, force });
};

export const switchConsultoria = async (uid, currentlyConsultoria) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post('/consultoria/switch', { uid, enable: !currentlyConsultoria });
};

export const refreshConsultoria = async (uid) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post('/consultoria/refresh', { uid });
};
