import { Button, makeStyles, Paper, Typography } from '@material-ui/core';
import Arrow from "@material-ui/icons/ArrowForward";
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import clsx from "clsx";
import Dialog from "fitbud/components/Dialog";
import { useToggle } from 'fitbud/hooks/form';
import { oprtnlDateFormat } from 'fitbud/utils/constants';
import { hextoRgb } from 'fitbud/utils/helpers';
import _ from "lodash";
import moment from 'moment';
import React from 'react';
import QResponses from "../QResponses";
import { DisplayMeasurements, Images } from '../subComponents';
import Slider from './slider';
import usePoses from 'fitbud/hooks/usePoses';

const DISPLAY_DATE_FORMAT = "DD MMM, YYYY";

export const NoAccessPlaceholder = ({ className = "", style={}, ...rest}) => {
  return (
    <div className={'border p-20 bg-grey-new ' + className} style={{ borderRadius: "10px", ...style }} {...rest}>
      <div className='d-flex align-items-center'>
        <WarningRoundedIcon htmlColor='#EC4A37'/>
        <Typography className='font_16_600 ml-10'>
          Check-in Photos
        </Typography>
      </div>
      <Typography className='mt-15 font_15_500 text-grey'>
        You don't have access to view check-in photos. Please contact your admin.
      </Typography>
    </div>
  )
}
export const getIdentifier = (currentDate, photosArray) => {
    let identifierString = "";
    photosArray.map((i, j) => {
      identifierString += `${i}_${_.get(currentDate, `photos.${i}.identifier`, currentDate.photos.identifier)}%`
      return identifierString;
    })
    return identifierString;
}

function mergeMeasurables(compData,userData){
  const userMeasurements=_.get(userData,"measurements",{});
  const compMeasurements=_.get(compData,"checkin_config.measurements",{});
  const obj={};
  for(let i in userMeasurements){
    const values=userMeasurements[i];
    if(!!values.tracking){
      obj[i]={
        ...values,
        optional:compMeasurements[i]?compMeasurements[i].optional:false
      }
    }
  }
  return obj;
}
const CheckinStepper = ({
    id,
    measurableTags,
    startDate,
    currentDate = {},
    last,
    unitSystem,
    unitDetails,
    measurableUnits,
    checkinQues,
    docId,
    userDoc,
    compData,
    hasImageAccess,
  }) => {
    let week = currentDate.tag === "00000000" ? 0 :
      moment(currentDate.tag, oprtnlDateFormat).diff(moment(startDate),"days") / 7;
    
    const classes = styles();
    const [qs, toggleQs] = useToggle(false);
    const poses = usePoses(compData && compData.checkin_config, userDoc && userDoc.overrides, currentDate);
    const showQs = checkinQues && checkinQues.questions && checkinQues.questions.length;
    const userMesaurablesTracked = (Object.keys(mergeMeasurables(compData, userDoc)))
      .filter((i) => userDoc.measurements[i].tracking)
      .sort((a, b) => userDoc.measurements[a].priority - userDoc.measurements[b].priority);
    const checkedInMesaurements = Object.keys(currentDate.measurements || {})
      .filter(a => !userMesaurablesTracked.includes(a))
      .sort((a,b) => a - b);
    return (
      <div id={id} >
        <div className="d-flex align-items-center fpl-10">
          {/* Ball */}
          <div
            className="rounded-circle bg-primary"
            style={{
              width: "12px",
              height: "12px",
              marginLeft: '-4px'
            }}
          />
          {/* Right side - Week and Date */}
          <div className="d-flex align-items-end fml-15">
            {(currentDate && userDoc && (currentDate.plan_id === userDoc.aplan)) ? (
              <div className='d-flex '>
                  <Typography className="fmr-10" variant="h6">
                    {!week || week <= 0 ? 'Day 1' : `Week ${Math.ceil(week)}`}
                  </Typography>
                  <Typography className="fmr-10" variant="body1" color='textSecondary'>
                    {moment(currentDate.date, oprtnlDateFormat).format(DISPLAY_DATE_FORMAT)}
                  </Typography>
                </div>
              ) : (
                <Typography variant="h6">
                  {moment(currentDate.date, oprtnlDateFormat).format(DISPLAY_DATE_FORMAT)}
                </Typography>
              )}
          </div>
          {/* Left side - Due on date text */}
          {currentDate.tag !== '00000000' && <div className="ml-auto">
            <Typography className="font_13_500" color="textSecondary">
              {`Due: ${moment(currentDate.tag, oprtnlDateFormat).format("DD MMM YYYY")}`}
            </Typography>
          </div>}
        </div>
        {/* Card start */}
        <div className={clsx("h-100 fpt-25 position-relative", !last && " fpb-35")}>
          <div style={{
            position:'absolute',
            borderLeft: "4px solid " + hextoRgb("#808080", 20),
            height:'101%',
            top:0, left:'10px',
            marginTop: "-3px",
            zIndex:0,
          }} />
          <Paper className="w-100 fp-20 bg-white d-flex flex-column position-relative rounded-10" style={{zIndex:3}}>
            {hasImageAccess ?
              <div className='mx-n1'><Slider num={3} className='mb-2' childClass='px-1'>
                {poses.map((i, j) => {
                  if(!currentDate || !currentDate.photos || !_.get(currentDate, `photos.${i}.image_data`, false) || !_.get(currentDate, `photos.${i}.identifier`, currentDate.photos.identifier))
                    return null;
                  const identifier = `${i}_${_.get(currentDate, `photos.${i}.identifier`, currentDate.photos.identifier)}`
                  let new_identifier = _.split(identifier, "_")[1];
                    return (
                      <div className='px-1' key={new_identifier+j}>
                        <Images
                          img={currentDate.photos && currentDate.photos[i]}
                          className={clsx("cursor-pointer", i !== "back" && "fmr-15")}
                          userId={docId}
                          identifier={identifier}
                          aspect={currentDate.photos && currentDate.photos.aspect}
                          position={j}
                          new_identifier={getIdentifier(currentDate, poses)}
                          imageStyles={{borderRadius: '8px'}}
                        />
                      </div>
                    )
                })}
              </Slider></div> : <NoAccessPlaceholder className='mb-20' />}
            {/* Measurement list */}
            <div style={{ flex: 1 }}>
              <div className={classes.measurementsContainer}>
                {userMesaurablesTracked &&
                userMesaurablesTracked.length > 0 &&
                userMesaurablesTracked.concat(checkedInMesaurements).map((i, j) => {
                  if (!currentDate.measurements || !currentDate.measurements[i] || !measurableTags[i]) 
                    return null;
                  let field_value =
                    currentDate.measurements && currentDate.measurements[i];
                  return (
                    <DisplayMeasurements
                      key={j}
                      label={measurableTags[i].value}
                      unit_type={measurableTags[i].unit_type}
                      unitSystem={unitSystem}
                      unitDetails={unitDetails}
                      measurableUnits={measurableUnits}
                      value={field_value}
                    />
                  );
                })}
              </div>
              {showQs && currentDate.answers && <Button className='fmt-20' onClick={toggleQs}>
                View Questionnaire  {' '}<Arrow />
              </Button>}
              <Dialog open={qs} onClose={toggleQs}
                fullWidth 
                maxWidth='sm' 
                title='Check In Questionnaire'>
                <div className='fp-20'>
                  <QResponses
                    onboarding_response={currentDate.answers}
                  ques={checkinQues ? checkinQues.questions : []}/>
                </div>
              </Dialog>
            </div>
          </Paper>
        </div>
      </div>
    );
  };
  
  const styles = makeStyles({
    measurementsContainer: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      gridAutoRows: "min-content",
      gridAutoFlow: 'row dense',
      gridRowGap: "20px",
      gridColumnGap: "10px",
    },
    ul: {
      listStyle: "none"
    },
  });

export default CheckinStepper;
