import React, { useMemo, useState, useContext, useCallback, useEffect } from 'react';
import {
  AppBar,
  IconButton,
  Typography,
  Toolbar,
  Divider,
  Tooltip,
  LinearProgress,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/CloseRounded';
import makeStyles from '@material-ui/core/styles/makeStyles';
import moment from 'moment';
import _ from 'lodash';
import MultiClientSelector from 'fitbud/components/multiClientSelector';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { useDispatch } from 'react-redux';
import appRdxFns from 'fitbud/redux/app';
import { bffGetUserProfileByIDs } from 'fitbud/api';
import { useSnackbar } from 'notistack';
import { DEFAULT_ERROR } from 'fitbud/utils/constants';
import { groupClassRegisterUser } from 'fitbud/api/gcFunctions';
import { OFFLINE_MODE_TEXT, ONLINE_MODE_TEXT, getLocalTimeFromUtc, getMode, getRegisterUserErrorText } from '../helper';
import { MenuList } from 'fitbud/components/menuList';
import searchIcon from 'fitbud/images/searchIcon.svg';

const menuOptions = [
  { label: `Add for ${ONLINE_MODE_TEXT} Class`, value: 'online' },
  { label: `Add for ${OFFLINE_MODE_TEXT} Class`, value: 'offline' },
];

export const useStyles = makeStyles(() => ({
  toolBar: {
    padding: '10px 20px 15px 20px',
    minHeight: 'unset',
  },
  chip: {
    height: 40,
    backgroundColor: 'white',
  },
  selectedChip: {
    color: '#317FF5',
    border: '1px solid #317FF5',
    cursor: 'pointer',
  },
}));

const ClientHeader = (props) => {
  const {
    data,
    onClose,
    staffId,
    usersMemo,
    openSearch,
    userFetching,
    instanceId,
    refreshClass,
    isValidAction,
    isDisabled,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [client_selector, setClientSelector] = useState(false);
  const [selected_mode, setSelectedMode] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [quotaChecked, setQuotaChecked] = useState(false);
  const { mode, users, time } = data || {};
  const { cid } = useContext(FirebaseAuthContext);
  const { showLoader, hideLoader } = appRdxFns(dispatch);
  const openClientSelector = () => setClientSelector(true);
  const closeClientSelector = () => setClientSelector(false);
  const closeSelectMenu = () => setAnchorEl(null);
  const { isHybrid } = getMode(mode);
  const local_time = getLocalTimeFromUtc(time, 'YYYYMMDDHHmm');
  const [_title, _time] = useMemo(() => {
    return [moment(local_time).format('ddd DD MMM'), moment(local_time).format('hh:mm a')];
  }, [local_time]);

  useEffect(() => {
    if (!client_selector) setQuotaChecked(false);
  }, [client_selector]);

  const handleQuotaChecked = (e) => {
    const value = e.target.checked;
    setQuotaChecked(value);
  };

  const onAddClick = (e) => {
    if (!isValidAction()) return;
    if (!isHybrid) return openClientSelector();
    else setAnchorEl(e.currentTarget);
  };

  const handleOptionClick = (e, option) => {
    e.stopPropagation();
    setSelectedMode(option);
    openClientSelector();
    closeSelectMenu();
  };

  const getArgsForRegisterUser = useCallback(
    (uid) => ({
      cid,
      instanceId,
      mode: selected_mode || mode,
      uid,
      staffId,
      checkPurchases: quotaChecked ? true : false,
    }),
    [cid, staffId, instanceId, mode, selected_mode, quotaChecked]
  );

  const handleClientSave = async (users) => {
    const userIds = (users || []).map((user) => user._id.split(':').pop());
    const promises = userIds.map((uid) =>
      groupClassRegisterUser(getArgsForRegisterUser(uid))
        .then((response) => {
          const { success, code } = _.get(response, 'data', {});
          return { success, code };
        })
        .catch(() => {
          return {
            success: false,
          };
        })
    );
    try {
      showLoader();
      const responses = await Promise.all(promises);
      const successCount = responses.filter((s) => s.success).length;
      const unSuccessCount = responses.filter((s) => !s.success).length;
      if (successCount && unSuccessCount) {
        //if some of them success and some unsuccess, then show count warning...
        enqueueSnackbar(`${unSuccessCount} out of ${responses.length} clients are not added.`, { variant: 'warning' });
      }
      if (!successCount) {
        if (unSuccessCount > 1) enqueueSnackbar(`No clients added.`, { variant: 'error' });
        else {
          //if only one response and which in unsuccess, then show exact error;
          const errorResponse = responses[0] || {};
          const { code } = errorResponse;
          if (!code) enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
          else {
            const txt = getRegisterUserErrorText(code);
            enqueueSnackbar(txt, { variant: 'error' });
          }
        }
        hideLoader();
        return;
      }
      const uids = userIds.map((i) => i.split(':').pop());
      const response = await bffGetUserProfileByIDs({ cid, uids, fields: ['name', 'email', 'image'] });
      const usersData = _.get(response, 'data.data');
      const map = usersData.reduce((prev, current) => {
        const id = current._id.split(':').pop();
        prev[id] = { ...current };
        return prev;
      }, {});
      usersMemo.current = { ...usersMemo.current, ...map }; //stored in map
      const successCb = () => {
        closeClientSelector();
        setSelectedMode(null);
      };
      if (refreshClass) {
        refreshClass(instanceId, successCb);
      } else {
        successCb();
      }
    } catch (error) {
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
    } finally {
      hideLoader();
    }
  };

  const getHeaderActions = () => {
    return (
      <div className="bg-info-box p-20">
        <FormControlLabel
          className="mb-0"
          control={<Checkbox color="primary" checked={quotaChecked} onChange={handleQuotaChecked} />}
          label={
            <span className="font_15_600 text-0d0d0d">
              Count this booking in client's quota{' '}
              <Tooltip title="Enabling this checkbox will deduct this booking from the client's overall quota allocation for the group classes.">
                <span>ⓘ</span>
              </Tooltip>
            </span>
          }
        />
      </div>
    );
  };

  return (
    <AppBar position="static" className="bg-grey-new">
      <div className="position-relative">
        <Toolbar classes={{ root: classes.toolBar }}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Typography className={'ml-8 flex-grow-1 font_18_600 text-0d0d0d'}>{`${_title}, ${_time}`}</Typography>
          <Tooltip title="Search">
            <IconButton onClick={openSearch}>
              <img alt="search-icon" src={searchIcon} />
            </IconButton>
          </Tooltip>
          <IconButton onClick={onAddClick} disabled={isDisabled}>
            <AddIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        {!!userFetching && <LinearProgress className="position-absolute w-100" style={{ bottom: 0 }} />}
      </div>
      {!!client_selector && (
        <MultiClientSelector
          title="Select Clients"
          keyName="browse"
          open
          onClose={closeClientSelector}
          actionText="Select"
          onSave={handleClientSave}
          primaryTextClass="text-truncate pl-5"
          headerActions={getHeaderActions}
          selectedUsers={Object.keys(users).map((id) => ({ _id: _.get(usersMemo.current, `${id}._id`, id) }))} //@TODO allow all user or not. because add user api is same it will allow to add all kind of users
        />
      )}
      <MenuList
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={closeSelectMenu}
        options={menuOptions}
        handleClick={handleOptionClick}
      />
    </AppBar>
  );
};

export default ClientHeader;
