import React,{useContext,useMemo} from 'react';
import Dialog from "fitbud/components/Dialog";
import { DialogContent, Divider, Typography, IconButton } from '@material-ui/core';
import ArrowIcon from '@material-ui/icons/ArrowForward';
import clsx from 'clsx';
import { ClrdButton } from 'fitbud/components/form-fields';
import { UserSchContext } from "fitbud/views/schedulev2";
import {Title} from "./fitnessOptions";
import { useToggle } from 'fitbud/hooks/form';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import MasterPill from '../schedulev2/editor/master-pill';
import Confirmation from 'fitbud/components/confirmationDialog';
import { DeleteIcon } from 'fitbud/icons/delete';

const SupplementOptions = (props) => {
  const [confirm, toggleConfrm] = useToggle();
  const { controller,opimport,dates,schCache,objCache } = useContext(UserSchContext);
  const {week, day,weekId}=opimport;
  const date=dates[opimport.day].format('ddd, D MMM');
  const { onClose, open } = props;

  const onSelect = (e) => {
    controller.importClick(e)
  };
  const isMaster=useMemo(()=> {
    const obj=controller.isMasterApplied({weekId,week,day,type:'sl'});
    if(!obj) return false;
    const {duration,weekday_align}=schCache[obj.id]||objCache[obj.id]||{};
    if(!!weekday_align && (duration % 7 ===0)){
      obj['repeating']=true;
    }
    return {...obj}
  },[weekId,week,day,schCache]);
  const config=useMemo(()=>({
    slms:{
      onClick:onSelect,
      "data-week":opimport.week,
      "data-weekid":opimport.weekId,
      "data-day":opimport.day,
      "data-purpose":"slms",
    },
  }),[opimport]);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={<Title date={date} {...opimport} />}
      titleFont="h3"
      paperClass="width-600"
    >
      <DialogContent className='p-0 overflow-hidden'>
        {!isMaster? (
            <div className='px-20 py-25'>
              <Typography className='font_18_600 mb-10' data-purpose='schedule'>
                Schedule
              </Typography>
              <Typography className='font_15_500 mb-15 text-dark-grey'>
                  Import a multi-week supplement schedule
              </Typography>
              <ClrdButton color="primary" variant="text"
                classes={{root:"ml-n8",label:"font_15_600"}} {...config['slms']}>
                Import Daily Supplement<ArrowIcon className='ml-3px'/>
              </ClrdButton>
            </div>
        ):(
          <div  style={{background:!isMaster.overridden?"#E8F4FD":"#F0F3F5"}} className='px-20 py-25'>
            <Typography className='mb-10 font_18_600 d-flex justify-content-between align-items-end'>
              {!isMaster.overridden?"Currently Active":"Broken Link"}
            </Typography>
            <div className='bg-white p-15 rounded mb-20 d-flex align-items-center'>
              {!isMaster.overridden ? <LinkIcon className='mr-15'/>:
              <LinkOffIcon className='mr-15'/>}
               {(isMaster.mode==="single"||isMaster.mode==="on_off") && <Typography className='font_15_500'>Daily Supplement -&nbsp;  
                <span className='font-weight-600'>{isMaster.name}</span>
                {isMaster.mode==="on_off" && <>. Rest Day Meal -&nbsp;</>}
                <span className='font-weight-600'>{isMaster.off_name}</span>
            </Typography>}
            </div>
            <MasterPill l2={date} closeParent={onClose} type="sl">
              {
                ({handleManage,unlink,unlinkingMsg,unlinkTitle})=>{
                  return(
                    <div className='d-flex justify-content-between align-items-center'>
                      <ClrdButton color="main" className="f-medium" onClick={handleManage}>
                        {!isMaster.overridden? "Manage":"Restore"}
                      </ClrdButton>
                      <IconButton onClick={toggleConfrm}>
                        <DeleteIcon/>
                      </IconButton>
                      {confirm && <Confirmation open={true} handleChange={unlink}
                        handleCancel={toggleConfrm} title={unlinkTitle} msg={unlinkingMsg}
                        confirmOption="Yes, Unlink"
                      />}
                    </div>
                  )
                }
              }
            </MasterPill>
          </div>
        )}
        {/* <Divider className={clsx("mb-25 mt-20")} style={{left:-20,width:'calc(100% + 40px)'}}/>
        <div className='px-20 pb-20'>
          <Typography className='font_18_600 mb-10'>
            Supplement
          </Typography>
          <Typography className='font_15_500 mb-20'>
            Add a supplement for a day
          </Typography>
          <ClrdButton color="primary" variant="text"
            classes={{root:"ml-n8",label:"font_15_600"}} {...config['supplement']}>
            Add Supplement Plan<ArrowIcon className='ml-3px'/>
          </ClrdButton>
        </div> */}
      </DialogContent>
      {/* {Object.keys(config).map((i,index)=>{
        const isLastEle=(Object.keys(config).length-1)===index;
        return(
          <>
            <div className={!config[i].visible && "d-none"}>
              <Typography className='font_18_600 mb-10'>
                  {config[i].heading}
              </Typography>
              <Typography className='font_15_500 mb-20'>
                {config[i].desc}
              </Typography>
              <ClrdButton color="primary" variant="text" className="ml-n8"  {...config[i]}>
                  <Typography className='font_15_600'>
                      {config[i].cta}
                      <ArrowIcon className='ml-3px'/>
                  </Typography>
              </ClrdButton>
            </div>
            {!isLastEle && !!config[i].visible && <Divider className="my-20 position-relative fmb-20" style={{left:-20,width:'calc(100% + 40px)'}}/>}
          </>
        )
      })} */}
    </Dialog>
  );
};
export default SupplementOptions;
