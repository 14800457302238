import React from 'react';
import _ from 'lodash';
import { FOOD_RECIPES_FILTER_CONFIG as FOOD_FILTERS } from './nutrition-filter-config';
import CheckboxFilter from 'fitbud/components/filterComps/CheckboxFilter';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ChipGroups from 'fitbud/components/catalog-filter/chipGroups';

const useClasses = makeStyles(() => ({
  checkBoxLabel: {
    fontSize: 15,
    fontWeight: 400,
    color: '37404D',
  },
  checkboxLabelClass: {
    width: '45%',
    marginRight: '0px',
    paddingRight: 0,
  },
}));

const FilterInner = (props) => {
  const { onSelectItem, filters, filterOptions } = props;
  const _filters = filterOptions;
  const classes = useClasses();
  return (
    <div className="d-flex p-0 h-100 w-100">
      <div className="overflow-auto fpx-20 fpy-25 w-100 " onScroll={(e) => e.stopPropagation()}>
        {_filters.map((selectedOption, index) => {
          const label = selectedOption.label;
          const subType = selectedOption.subType;
          const filterType=selectedOption.filterType;
          const subOptions = selectedOption.subOptions;
          const _isComponent = _.get(selectedOption, 'isComponent', false);
          const FComponent = _.get(selectedOption, 'Component');

          const _handleChange = (e) => {
            const value = e.target.name;
            onSelectItem({ subType, value,filterType });
          };

          if(!!_isComponent){
            return <FComponent
            key={_.get(selectedOption, 'type')}
            label={_.get(selectedOption, 'label')}
            subType={_.get(selectedOption, 'subType')}
            tag={_.get(selectedOption, 'tag')}
            onSelectItem={onSelectItem}
            filters={filters}
            isMinified = {true}
            type={selectedOption.type}
          />
          }

          const isSingleSelect=filterType==="single_select"
          return (
            <div className="fmb-10" key={"options_group" + index}>
              <ChipGroups
                label={label}
                handleChange={_handleChange}
                componentState={_.get(filters, subType, [])}
                fields={subOptions}
                isMulti={isSingleSelect ? false : true}
                isColumn={false}
                groupLabelClassName="font_16_600 text-black fmb-10"
                labelClass={classes.checkBoxLabel}
                checkboxLabelClass={classes.checkboxLabelClass}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FilterInner;
