import React, { createContext, useState, useEffect } from "react";
import { Room } from "twilio-video";
import firebase from "fitbud/firebase";
import * as Sentry from '@sentry/browser';
import { useLiveSessions } from "fitbud/providers/liveSessionsProvider";
import { SelectedParticipantProvider } from './useSelectedParticipant';
import useLocalTracks from "./useLocalTracks";
import useRoom from "./useRoom";
import moment from "moment";
import { date2hmm, STATUS_BOOKED, structureAppointment } from "fitbud/utils/scheduling";
import {DEFAULT_ERROR } from "fitbud/utils/constants";
import { useSnackbar } from 'notistack';

const defaultVideoContext = {
  room: Room,
  localTracks: [],
  isConnecting: false,
};

export const VideoContext = createContext(defaultVideoContext);

const VideoProvider = (props) => {
  const {
    localTracks,
    getLocalVideoTrack,
    removeLocalVideoTrack,
    removeLocalAudioTrack,
    getLocalAudioTrack,
    getAudioAndVideoTracks,
    getToggleNote,
    notes,
    getToggleView,
    isViewEnabled,
    getUserRef,
  } = useLocalTracks();
  const { enqueueSnackbar } = useSnackbar();
  const { room, isConnecting, connect, loading } = useRoom(localTracks);
  const { cid, openVideoScreen: book_id } = useLiveSessions();
  const [reJoin, setRejoin] = useState(false);
  const [noteValue, addNote] = useState({note: ""});
  const [sessionData, setSessionData] = useState(null)
  const [isDataLoading, setDataLoading] = useState(false)

  const handleDisconnect = (e) => {
    e.preventDefault()
    room.disconnect()
    localTracks.forEach(track => track.stop()) //call disconnect stop all tracks
    setRejoin(true)
  }

  const handleRejoin = (e) => {
    e.preventDefault()
    setRejoin(false)
  }

  const handleRHS = (e) => { //handle return home screen
    e.preventDefault()
    setRejoin(false)
  }

  useEffect(() => {
    if(!book_id) return;
    setDataLoading(true)
    firebase.firestore().doc(`companies/${cid}/bookings/${book_id}`).get().then((doc) => {
      let bookingData = doc.data();
      if(bookingData && bookingData.status !== STATUS_BOOKED) {
        return enqueueSnackbar("Booking has Cancelled", { variant: "error" });
      }
      let { startDate, user, duration, slot } = structureAppointment(doc.data(), 1, {withTimestamp: true})
      let out = {
        startDate,
        name: user.name,
        duration: duration,
        slot: date2hmm(startDate),
        date: moment(startDate).format("yyyy-MM-DD"),
        time: `${slot[0]} to ${slot[1]}`,
        uid: `${cid}:${user.uid}`,
        book_id,
      };
      setSessionData(out)
      setDataLoading(false)
    }).catch((err) => {
      setDataLoading(false)
      enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
      Sentry.captureException(err);
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[book_id, cid])

  
  if (!book_id || !sessionData) return null;
  return (
    <VideoContext.Provider
      value={{
        room,
        localTracks,
        isConnecting,
        getLocalVideoTrack,
        removeLocalVideoTrack,
        removeLocalAudioTrack,
        connect,
        getLocalAudioTrack,
        getAudioAndVideoTracks,
        getToggleNote,
        notes,
        getToggleView,
        isViewEnabled,
        sessionData,
        getUserRef,
        handleDisconnect,
        handleRejoin,
        handleRHS,
        reJoin,
        noteValue, 
        addNote,
        loading,
        isDataLoading
      }}
    >
      <SelectedParticipantProvider room={room}>{props.children}</SelectedParticipantProvider>
    </VideoContext.Provider>
  );
};

export default VideoProvider;
