import React from 'react';
import Dialog from 'fitbud/components/Dialog';
import SelectorCatalogList from 'fitbud/components/catalog/selectorCatalogList';
import ArrowIcon from '@material-ui/icons/ArrowForward';

const CatalogSingleSelector = (props) => {
  const { handleAdd, onClose, mealDoc, selectedMeal, open, title = 'Select Item', keyName = "wocardio", defaultFilter, today, isFilter=true, ...other } = props;
  function save() {}
  function handleClose() {
    onClose();
  }

  const onSelect = (doc) => {
    handleAdd([doc]);
  };

  return (
    <Dialog
      open={open}
      {...other}
      title={title}
      buttonColor="main"
      paperClass="width-600 height-70"
      toolbarClass="height-60"
      titleFont="h3"
      fullWidth
      onSave={save}
      onClose={handleClose}
      hideHeaderDivider={true}
      dialogContentClassName="d-flex flex-column"
      disableAction={true}>
      <div className="position-relative d-flex flex-column h-100">
        <SelectorCatalogList
          isFilter={isFilter}
          keyName={keyName}
          defaultFilter={defaultFilter}
          today={today}
          onClick={onSelect}
          showDraftInd={true}
          listProps={{
            ListItemSecondaryAction: () => (
              <span style={{ marginRight: 8 }}>
                <ArrowIcon />
              </span>
            ),
          }}
        />
      </div>
    </Dialog>
  );
};
export default CatalogSingleSelector;
