import React, { useContext } from 'react';
import ScheduleSelector from 'fitbud/views/schedulePlanner/scheduleSelector';
import WorkoutSelector from 'fitbud/views/schedules/workoutSelector';
import UserSchdMealSelector from 'fitbud/views/users/userSchdMealSelector';
import UserSchdSuppSelector from 'fitbud/views/users/userSchdSuppSelector';
import FitnessOptions from "fitbud/views/users/fitnessOptions";
import NutritionOptions from "fitbud/views/users/nutritionOptions";
import SupplementOptions from "fitbud/views/users/supplementOptions";
import WaterEditor from "fitbud/views/users/waterEditor";
import ActivityEditor from "fitbud/views/users/activityEditor";
import BMREditor from "fitbud/views/schedulev2/bmr/editors";
import MealPlanSelector from 'fitbud/views/users/mealSelector';
import SupplementSelector from 'fitbud/views/users/supplementSelector';
import { UserSchContext } from '../provider';

const Picker = () => {
  const { opimport, controller } = useContext(UserSchContext);
  if (!opimport) return null;
  const onSelect = (args,_opimport) => {
    controller.importSave(_opimport||opimport, args);
  };
  const { existing, purpose } = opimport;
  switch (purpose) {
    //------- WORKOUT--------
    case 'altWo':
    case 'wo':
      return <WorkoutSelector open onClose={controller.importClose} moduleName='schedule' handleAdd={onSelect} selectedWOs={existing} showDraftInd={true} title='Select Workouts'/>;
    case 'woSch':
      return <ScheduleSelector open onClose={controller.importClose} importType='woImport' handleAdd={onSelect}/>;
    // case 'altWo': return null//ALIA: WHERE IS ALT WO SELECTOR?
    
    //------- MEAL--------
    case "bmr": return <BMREditor open onClose={controller.importClose} handleAdd={onSelect}/>
    case "ml":
      return <MealPlanSelector open onClose={controller.importClose} handleAdd={onSelect} title='Select Meal Plan'/>
    case "mlms":
      return <UserSchdMealSelector open onClose={controller.importClose} handleAdd={onSelect} selectedMls={existing}/>;
    case 'mlSch':
      return <ScheduleSelector open onClose={controller.importClose} importType='mlImport' handleAdd={onSelect}/>;
    //------- SUPPLEMENT--------
    // case "sl":
      // return <SupplementSelector open onClose={controller.importClose} handleAdd={onSelect}/>;
    case "slms":
        return <UserSchdSuppSelector open onClose={controller.importClose} handleAdd={onSelect} selectedSupps={existing}/>
    //------- GENERIC--------
    case 'fitness': return <FitnessOptions open onClose={controller.importClose}/>;
    case 'nutrition': return <NutritionOptions open onClose={controller.importClose}/>
    case 'supplement': return <SupplementOptions open onClose={controller.importClose}/>
    case "water":return <WaterEditor  open onClose={controller.importClose}/>
    case "activity":return <ActivityEditor  open onClose={controller.importClose}/>
    default: return null;
  }
};

export default Picker;
