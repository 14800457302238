import React, { useContext, useEffect, useState } from 'react';
import { IconButton, makeStyles } from "@material-ui/core";
import { CrossIcon } from "fitbud/icons/cross";
import { PlayIcon } from 'fitbud/icons/play';
import ReactPlayer from "react-player";
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { validateVimeo } from 'fitbud/utils/services';
import { DEFAULT_ERROR } from 'fitbud/utils/constants';
import _ from "lodash";
import { VimeoPlaceholder } from './mediaPlaceholder';

const useStyles = makeStyles((theme) => ({
  closeBtn: {
    top: 0,
    right: "10px",
    zIndex: 1,
  },
}));

const VideoPlayer = props => {
  const {
    url,
    thumbnailUrl,
    aspectRatio,
    onCross,
    playerProps = {},//includes className
    muted = false,
    pause,
    playIconStyle={},
    mediaType,
  } = props;
  //--styling--
  const classes = useStyles({ aspectRatio: aspectRatio || 0 });
  //---state--
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const {comp, cid} = useContext(FirebaseAuthContext);
  const company = comp?comp.data(): {};
  const isVimeoLinked = _.get(company, `features.vimeo`);

  //--functions--
  const autoLoad = !!playerProps.onDuration || !!pause;
  const light = !pause && (autoLoad) ? false : (thumbnailUrl || true);
  const _playIconStyle={width:"unset",height:"unset",...playIconStyle};

  const validateUrl = async ()=>{
    if (mediaType !== 'vimeo') {
      setLoading(false);
      setError('');
    } else {
      if (!isVimeoLinked) {
        setLoading(false);
        setError('Your Vimeo account is not connected. Thus, the video playback is not supported.');
        return;
      }
      //if vimeo is linked then, then check for error if exists..
      try {
        const response = await validateVimeo(url, cid);
        if (!response) {
          setLoading(false);
          setError(DEFAULT_ERROR);
        } else {
          const responseData = response?.data;
          const { success, message } = responseData || {};
          if (!success) {
            setError(message);
            setLoading(false);
          } else {
            setError("");
            setLoading(false);
          }
        }
      } catch (err) {
        setLoading(false);
        setError(DEFAULT_ERROR);
      }
    }
  }

  useEffect(()=>{
    validateUrl();
  },[url, mediaType, isVimeoLinked])

  if (!url) return null;
  if(mediaType !== "vimeo" && loading) return null;
  if(mediaType === "vimeo" && (error || loading)) return <VimeoPlaceholder loading={loading} text={error} />

  return (
    <>
      {!!onCross && (
        <div className={`position-absolute ${classes.closeBtn}`}>
          <IconButton aria-label="close" onClick={onCross}>
            <CrossIcon />
          </IconButton>
        </div>
      )}
      <ReactPlayer
        playIcon={<PlayIcon style={{..._playIconStyle}} />}
        height={"100%"}
        width={"100%"}
        light={light}
        controls={true}
        url={url}
        playing={!autoLoad}
        muted={muted}
        {...playerProps}
      />
    </>
  );
};


export default VideoPlayer;
