import React from 'react';
import useVideoContext from './useVideoContext';
import AudioLevelIndicator from './audioLevelIndicator';

const LocalAudioLevelIndicator = () => {
  const { localTracks } = useVideoContext();
  const audioTrack = localTracks.find(track => track.kind === 'audio');

  return (
    <div style={{ position: "absolute", left: "15px" }}>
      <AudioLevelIndicator audioTrack={audioTrack} />
    </div>
  );
}

export default LocalAudioLevelIndicator;