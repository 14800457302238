import { TextField } from '@material-ui/core';
import firebase from 'firebase';
import Dialog from 'fitbud/components/Dialog';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { useSnackbar } from 'notistack';
import React, { useContext, useState } from 'react'
import { useDispatch } from 'react-redux';
import appRdxFns from "fitbud/redux/app";
import { DEFAULT_ERROR } from 'fitbud/utils/constants';

export const MessageDialog = ({open={}, onClose, uid }) => {
  const { show, msg='' } = open;
  const { cid } = useContext(FirebaseAuthContext);

  const [message, setMessage] = useState({type: 'text', body: msg || ''} || '');
  const [error, setError] = useState(false);
  const d = useDispatch();
  const { showLoader, hideLoader } = appRdxFns(d);
  const { enqueueSnackbar} = useSnackbar();
  const handleSave = async() => {
    if(!message || !message.body.trim()) return setError(true);
    if(!message.authorId) message.authorId = cid;
    if(!message.timestamp) message.timestamp = firebase.firestore.FieldValue.serverTimestamp();
    if (message.body) message.body = message.body.trim();
    if (message.body === "" && (message.type === 'text' || message.type === 'video')) return;
    showLoader();
    try {
      await firebase
      .firestore()
      .collection("user_profiles")
      .doc(`${cid}:${uid}`)
      .collection("messages")
      .add({ ...message });
      enqueueSnackbar("Message Sent", { variant: 'success' });
      hideLoader();
      onClose();
    } catch(err){
      console.log(err);
      hideLoader();
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
    };  
  };
  const handelChange = (e) => {
    const { value } = e.target;
    setMessage(prev => ({...prev, body: value}))
  };
  return (
    <Dialog 
      title='Send Message' 
      onSave={handleSave} 
      open={!!open || !!show} 
      maxWidth='xs' 
      fullWidth
      onClose={onClose}
      actionText='Send'
      appBarColor="bg-body"
      toolbarClass="height-60"
      >
      <div className='fp-10'>
        <TextField
          variant='outlined'
          placeholder='Type your message. . .'
          className='w-100'
          error={error}
          helperText={error && 'Please enter some text'}
          value={message.body}
          onChange={handelChange}
          multiline
          rowsMax={4}
        />
      </div>
    </Dialog>
  );
};

export default MessageDialog;