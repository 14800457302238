import { LinearProgress, Typography } from "@material-ui/core";
import Dialog from "./dialog";
import { withSnackbar } from "notistack";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import _ from "lodash";
import * as Sentry from "@sentry/browser";
import { ClrdButton } from "fitbud/components/form-fields";
import { useLiveSessions } from "fitbud/providers/liveSessionsProvider";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import { useSnackbar } from 'notistack';
import { bffGetRoomToken } from 'fitbud/api/index';
import { DEFAULT_ERROR } from 'fitbud/utils/constants';
import useVideoContext from './useVideoContext';

const LiveVideo100 = (props) => {
  const { handleCloseVideoScreen, openVideoScreen: book_id, bookings } = useLiveSessions();
  const { sessionData } = useVideoContext();
  const {name, time, duration} = sessionData;
  const { authUser, cid } = useContext(FirebaseAuthContext);
  
  const [ state, setState ] = useState('init');
  const {enqueueSnackbar} = useSnackbar()
  const windowRef = useRef(null);
  const tokenRef = useRef(null);

  function messageListener (event) {
    console.log('event listener', event.data);
    if (event.data?.type === 'joined') {
      setState('connected');
    }
    if (event.data?.type === 'left') {
      setState('disconnected');
    }
    if (event.data?.type === 'token') {
      console.log('sharing token again', event.data, event);
      if (windowRef.current) {
        windowRef.current.postMessage({ type: 'token', val: tokenRef.current }, '*');
        windowRef.current.postMessage({ type: 'name', val: authUser?.providerData?.[0].displayName || 'Trainer' }, '*');
      }
    }
  }

  const joinClick = (e) => {
    e.preventDefault();

    if (windowRef.current && !windowRef.current.closed) {
      windowRef.current.focus();
      return;
    }

    const _booking = _.find(bookings, ['book_id', book_id]);

    const child = window.open(process.env.REACT_APP_LIVE_URL, '_blank');
    windowRef.current = child;

    if (!child) {
      enqueueSnackbar("Please enable popups for this site", {variant:'error'});
      setState('popupfail');
      return;
    }
    setTimeout(() => { // change to trigger when child document is loaded
      // send token and name together
      child.postMessage({ type: 'token', val: tokenRef.current }, '*');
      child.postMessage({ type: 'name', val: authUser?.providerData?.[0].displayName || 'Trainer', callWith: _booking?.user?.name }, '*');
    }, 4000);
  }

  const onClose = useCallback(() => {
    if (windowRef.current) {
      try {
        windowRef.current.close();
      } catch (e) {
        /* nothing to do */
      }
    }
    if (state === 'connected' || state === 'disconnected')
      handleCloseVideoScreen(null, true);
    else
      handleCloseVideoScreen();
  }, [state, handleCloseVideoScreen]);

  useEffect(() => {
    if (state === 'disconnected')
      onClose();
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const _booking = _.find(bookings, ['book_id', book_id]);

    if (_booking?.provider === '100ms' && state === 'init') {
      // retrieve 100ms token

      const handleJoin = async () => {
        try {
          setState('connecting');
          const userToken = await authUser.getIdToken();
          const res = await bffGetRoomToken(userToken, cid, book_id, 'trainer')
          const { data } = res;
          if(!data.success){
            enqueueSnackbar(DEFAULT_ERROR, {variant:'error'});
            return;
          }

          tokenRef.current = data.data.token;
          setState('ready');
          
        } catch(err){
          enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
          Sentry.captureException(err);
        }
      };

      handleJoin();
    }

  }, [bookings, book_id, cid, authUser, enqueueSnackbar, state]);

  useEffect(() => {
    window.addEventListener('message', messageListener);
    return () => {
      console.info('cleanup')
      window.removeEventListener('message', messageListener);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let userName = _.truncate(name, {'length': 25});

  return (
    <Dialog fullScreen open={Boolean(book_id)} onClose={onClose} dialogContentClassName='position-relative align-content-center'>
      {!tokenRef.current && <div className='position-absolute w-100 inset-0'><LinearProgress/></div>}
      <div className='text-center'>
        <div>
          <Typography variant="h2" className="mb-20">
            Session with {userName}
          </Typography>
          <Typography variant="body1" className="mb-60">
            {duration} mins  •  {time}
          </Typography>
        </div>
        <ClrdButton className="f-large" variant="contained" color="primary"
          component='a' href='#' onClick={joinClick}
          disabled={!tokenRef.current}>
          {tokenRef.current ? 'Join Session' : 'Preparing Session'}
        </ClrdButton>
      </div>
    </Dialog>
  );
};

export default withSnackbar(LiveVideo100);
