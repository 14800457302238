import _ from 'lodash';
import React, { useContext, useState, useRef, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import * as Sentry from '@sentry/browser';
import {
  makeStyles, Button,
} from '@material-ui/core';
import { DEFAULT_ERROR, HUBSPOT_PROPS } from 'fitbud/utils/constants';
import { bffUpdateHubspotProp } from 'fitbud/api';
import plansRepo from 'fitbud/repo/plans';
import planRdxFns from 'fitbud/redux/plans';
import appRdxFns from 'fitbud/redux/app';
import Dialog from 'fitbud/components/Dialog';
import Confirmation from 'fitbud/components/confirmationDialog';
import { useToggle } from 'fitbud/hooks/form';
import { parsePack } from '../helper';
import { PlanContext } from '../planProvider';
import Stepper from './stepper';
import BasicEditor from './basic';
import PriceEditor from './pricing';
import VidCallsEditor from './calling';
import PointsEditor from './points';
import { AccessContext } from 'fitbud/providers/access-provider';

export const REGULAR_STEPS ={
  BASIC: 0,
  PRICING:1,
  VIDEO_CALL : 2,
  INCLUDES:3,
  HOW_IT_WORKS : 4
};
export const ADD_ON_STEPS = {
  BASIC: 0,
  PRICING:2,
  VIDEO_CALL : 1,
  INCLUDES:3,
  HOW_IT_WORKS : 4
};
export const REGULAR_STEPS_LABELS = ["Description", "Pricing Info", "Appointments Info" , "Plan Includes", "How it Works"];
export const ADD_ON_STEPS_LABELS = ["Description",  "Appointments Info","Pricing Info" ,"Plan Includes", "How it Works"];
export const DIALOG_REGULAR_TITLES = ["Description", "Pricing Info", "Manage Appointments", "Plan Includes", "How it Works"];
export const DIALOG_ADD_ON_TITLES = ["Description","Manage Appointments" ,"Pricing Info", "Plan Includes", "How it Works"];;


const Editor = (props) => {
  const {
    id = 'new', plans,
    data = {},
    mode, price, iap, play,
    onClose, onSave,
    showLoader, hideLoader,
    enqueueSnackbar,
  } = props;
  const history = useHistory();
  const {saveAccessChanges} = useContext(AccessContext);
  const { cid, packFlags, parseSinglePackForIapIds } = useContext(PlanContext);
  const [dirty, setDirty] = useState(false);
  const [state, setState] = useState(data);
  const [step, setStep] = useState(mode || 0);
  const [saving, setSaving] = useState(false);
  const [confirm, toggleConfirm, showConfirm] = useToggle(false);
  const fnRef = useRef(null);
  const isNew = (id === 'new');
  const wizard = (typeof mode === 'undefined');
  const css = useStyles({ wizard });
  const STEPS = useMemo(()=>{
    if(_.get(state, "type") === "add_on") return ADD_ON_STEPS;
    else return REGULAR_STEPS;
  },[state])

  const STEPS_LABELS = useMemo(()=>{
    if(_.get(state, "type") === "add_on") return ADD_ON_STEPS_LABELS;
    else return REGULAR_STEPS_LABELS;
  },[state])

  const DIALOG_TITLES = useMemo(()=>{
    if(_.get(state, "type") === "add_on") return DIALOG_ADD_ON_TITLES;
    else return DIALOG_REGULAR_TITLES;
  },[state])

  const close = (warnIfDirty = true) => {
    if (warnIfDirty && dirty) {
      if (confirm) showConfirm(false);
      else return showConfirm(true);
    }
    if (onClose) onClose();
    else {
      if(history.length<=1)
        history.replace("/plans");
      else
      history.goBack();}
  }

  const skip = () => {
    if (step < LAST_STEP) setStep(x => (x + 1));
  }

  const compareDirty = (changes) => {
    if (!changes) return;
    const keys = Object.keys(changes);
    const curr = _.pick(state, keys);
    //Omit null or undefined values from both source and comparisons.
    setDirty(current => {
      const isChanged = !_.isEqual(_.chain(curr).omitBy(_.isUndefined).omitBy(_.isNull).value(), _.chain(changes).omitBy(_.isUndefined).omitBy(_.isNull).value());
      if (isChanged) return true;
      return current;
    });
  };

  const proceed = () => {
    if (!fnRef.current) return;
    if (!fnRef.current(setState)) return;
    if (!wizard || step === LAST_STEP) {
      setSaving(true);
    } else {
      setStep(x => (x + 1));
    }
  };

  useEffect(() => {
    if (!saving) return;
    setSaving(false);
    if (!dirty) return close(false);
    showLoader();
    const data = _.omitBy(state, _.isUndefined);
    const isSubscription = (data.price_opts || []).some((opt) => (opt && opt.mode) === 'subscription');
    (isNew
      ? plansRepo(cid).create({...data, ...packFlags, index: plans.length}, enqueueSnackbar)
      : plansRepo(cid).update(id, data, enqueueSnackbar))
      .then(async doc => {
        if(isNew || mode === STEPS.VIDEO_CALL || (mode === STEPS.PRICING && data.add_on_type === 'one_to_one')) {
          await saveAccessChanges({ objId: doc.id });
        }
        hideLoader();
        if (!doc) return;
        if (onSave) onSave(doc.data());
        parseSinglePackForIapIds(doc);
        if (isNew) {
          props.planOps.set(_.orderBy([...plans, parsePack(doc)], ['index'], ['asc']), 0, true);
          history.replace(`/plans/${doc.id}`);
        } else {
          props.planOps.update(parsePack(doc));
          close(false);
        }
        if (isSubscription) bffUpdateHubspotProp(HUBSPOT_PROPS.PLAN_SUBSCRIPTION_CREATED);
        else bffUpdateHubspotProp(HUBSPOT_PROPS.PLAN_ONETIME_CREATED);
      }).catch(err => {
        hideLoader();
        enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
        Sentry.captureException(err);
      });
  }, [saving]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog open
    toolbarClass='height-60'
    buttonColor='primary'
    onClose={close}
    title={wizard ? (isNew ? 'Add Payment Plan' : 'Edit Payment Plan') : DIALOG_TITLES[mode]}
    titleFont='h3'
    paperClass={wizard ? 'width-780' : 'width-600'}
    actionText={wizard ? (step < LAST_STEP ? 'Next' : 'Save') : 'Save'}
    onSave={proceed}
    additionalActions={wizard && step > 0 && step < LAST_STEP && <Button
      color='primary' className='mr-1' onClick={skip}
    >Skip</Button>}>
      <div className={`d-flex h-100 overflow-auto flex-row ${css.root}`}>
        {wizard && <Stepper stepLabel={STEPS_LABELS} activeStep={step} setStep={setStep}/>}
        {step === STEPS.BASIC && <BasicEditor data={state} fnRef={fnRef} setDirty={compareDirty} isNew={isNew} />}
        {step === STEPS.PRICING && <PriceEditor id={price || (wizard ? 'new' : 'add')} data={state} iap={iap} play={play} fnRef={fnRef} setDirty={compareDirty} />}
        {step === STEPS.VIDEO_CALL && <VidCallsEditor data={state} fnRef={fnRef} compareDirty={compareDirty} id={id} isNew={isNew} setDirty={setDirty} />}
        {step === STEPS.INCLUDES && <PointsEditor src='plan_includes' data={state} fnRef={fnRef} setDirty={compareDirty} />}
        {step === STEPS.HOW_IT_WORKS && <PointsEditor src='how_it_work' data={state} fnRef={fnRef} setDirty={setDirty} />}
      </div>
      {confirm && <Confirmation open
        handleChange={close}
        handleCancel={toggleConfirm}
      />}
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: props => (props.wizard ? 540 : 'unset'),
  },
}));

export const LAST_STEP = 4;

const mapStateToProps = s => {
  return {plans: _.get(s, 'plans.docs', [])};
};

const mapDispatchToProps = d => {
  const { showLoader, hideLoader } = appRdxFns(d);
  const planOps = planRdxFns(d);
  return {
    planOps, showLoader, hideLoader
  };
};

export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(Editor)
);
