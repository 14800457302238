import _ from 'lodash';
import React, { createContext, useMemo, useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { ContractContext } from 'fitbud/providers/contract';
import GTM from 'fitbud/utils/gtm';
import TeeZed from 'fitbud/providers/tz';

const XXX = 'Unknown';
const YYY = 'Staff';
const CTYPE = { TRIAL: 'Trial', SILVER: 'Silver', GOLD: 'Gold', GTENT: 'GTent' };
const portal = process.env.REACT_APP_INTERCOM_DOMAIN || 'Development';

const AnalyticsContext = createContext({});
AnalyticsContext.displayName = 'AnalyticsContext';

function AnalyticsProvider({ children }) {
  const { cid, authUser: { uid, email } = {}, userProfile: { name }, isFBStaff, comp } = useContext(FirebaseAuthContext);
  const stats = useSelector((state) => state.home);
  const { contract } = useContext(ContractContext);
  const [dimensions, setDimensions] = useState(null);
  const { app, contract: compContract } = comp.exists ? comp.data() : {};
  const isStaff = useMemo(() => _isStaff(isFBStaff, email), [isFBStaff, email]);
  const ctype = useMemo(() => (isStaff ? YYY : _cType(app, contract, compContract)), [isStaff, app, contract, compContract]);
  const cycle = useMemo(() => _billingCycle(contract), [contract]);
  const clients = useMemo(() => _clients(stats), [stats])
  const event = (name, event_label = '') => {
    const eventDims = _.mapKeys(dimensions, (v, k) => (`event_${k}`));
    if (event_label) eventDims.event_label = event_label;
    if (typeof window === 'undefined') return;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({event: name, ...eventDims});
  }

  useEffect(() => {
    if (typeof window === 'undefined') return;
    window.dataLayer = window.dataLayer || [];
    const dims = { portal, cid, uid, ctype, cycle, clients };
    window.dataLayer.push(dims);
    setDimensions(dims);
  }, [cid, uid, email, isStaff, ctype, clients, cycle]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AnalyticsContext.Provider value={{ cid, uid, email, name, comp, dimensions, event }}>
      <GTM defer={!dimensions}/>
      {children}
      <TeeZed/>
    </AnalyticsContext.Provider>
  );
}

export { AnalyticsProvider, AnalyticsContext };

/* const path2title = path => {
  if (typeof path !== 'string') return 'unknown-page';
  if (path === '/') return 'home';
  return path.replace(/^\//, '').split('/').map(_.capitalize).join(' » ')
};*/

const _isStaff = (isStaff, email) => {
  if (isStaff) return true;
  if (email) {
    return email.endsWith('@appstreet.io') ||
      email.endsWith('@iappstreet.com') ||
      email.endsWith('@fitbudd.com') ||
      email.endsWith('@yopmail.com');
  }
  return false;
};

const _cType = (app, contract, compContract) => {
  if (app && app.android) return CTYPE.GOLD;
  if (app && app.appStoreId) return CTYPE.GOLD;
  if (compContract && compContract.gotGold) return CTYPE.GTENT;
  if (contract && contract.status === 'trial') return CTYPE.TRIAL;
  if (!contract) return XXX;
  return CTYPE.SILVER;
}

const _billingCycle = (contract) => _.get(contract, 'meta.recurring_interval', XXX);

const _clients = (stats) => {
  if (!stats) return 0;
  const { activeUsers, plansToAssign } = stats;
  return (activeUsers || 0) + (plansToAssign || 0);
};
