import React, { useContext, useEffect, useRef, useCallback, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { calculateHeight } from 'fitbud/utils/helpers';
import CatalogFilter from 'fitbud/components/catalog-filter';
import { TagsContext } from 'fitbud/providers/tagsProvider';
import { DumbList, ComponentListHeader, Placeholder, listSecondLine,SecondaryAction } from './';
import _,{ get, debounce, isEmpty } from 'lodash';
import { List } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { isFilterExists } from 'fitbud/components/catalog/util';
import useDidMountEffect from 'fitbud/hooks/useDidMountEffect';
import { getLabel, getCatalogRdxFn } from 'fitbud/components/catalog/util';
import {getExIcon} from "fitbud/utils/catalog";
import {VIEWS_CONSTANTS } from 'fitbud/utils/constants';

/* IMPORTANT NOTE:
  Default Filter(Filter applied on first mount) is written keeping in mind ->
  its mutually exclusive from Search.
*/

const getDefFilters=(keyName)=>{
  switch(keyName){
    case VIEWS_CONSTANTS.CHALLENGES:{
      return({
        challenge_filters: {
          status: ['active','draft']
        }
      });
    }
    default: return null;
  }
}
//Default Filters will be part of request calls and not Search
const ComponentList = ({ location, history, match, isFilter = false,keyName, ...rest }) => {
  const { tagName } = useContext(TagsContext);
  const defaultFilters=useMemo(()=>getDefFilters(keyName),[keyName]);
  const [isFilterOpen, toggleFilter] = useState(false);
  const [filterState, updateFilterState] = useState({...(defaultFilters||{})});
  const {
    loading,
    docs,
    count,
    searchResult,
    searchCount,
    showMediaType = false,
    hideHeader = false,
    listProps,
    searchNextPage,
    nextPage,
    showQwkFilter = false,
    onAdd
  } = rest;
  const placeholder = `Search ${getLabel(keyName)}`;
  const [q, setQ] = useState('');

  const dataLimit = useRef(0);
  const currentKeyRdxFns = rest[keyName];
  const isDefaultFltrSet=useMemo(()=>{
    return !!(_.isEqual(defaultFilters,filterState)) && !q;
  },[defaultFilters,filterState,q]);
  const listRef = useCallback((node) => {
    if (node !== null) {
      dataLimit.current = calculateHeight(node);
    }
  }, []);
  const applyFilter = (filter) => {
    updateFilterState(filter);
  };
  const USE_SEARCH_CONDITION_1=(!!q || !!isFilterExists(filterState)) && !isDefaultFltrSet;
  const USE_SEARCH_CONDITION_2=!_.isEmpty(defaultFilters) && !isDefaultFltrSet && !q;


  useDidMountEffect(() => {
    if (USE_SEARCH_CONDITION_1) {
      currentKeyRdxFns.searching(q, filterState);
    }
    else if(USE_SEARCH_CONDITION_2){
      currentKeyRdxFns.searching('',{},true);
    }
    else {
      currentKeyRdxFns.resetSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyName, filterState, q]);

  const scrollRef = useRef({ docs, count, searchResult, searchCount, q, filterState });

  useEffect(() => {
    scrollRef.current = {
      docs,
      count,
      searchResult,
      searchCount,
      q,
      filterState,
    };
  }, [docs, count, searchResult, searchCount, q, filterState]);

  const handleRefresh = () => {
    if (isFilterExists(filterState) || !!q) {
      currentKeyRdxFns.searching(q || '', filterState);
    } else {
      fetch(true);
    }
  };

  const handleChange = (e) => {
    let value = e.target.value;
    applyFilter({});
    setQ(value);
  };

  const handleScroll = debounce((e) => {
    if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 100) {
      const { docs, count, searchResult, searchCount, q, filterState } = scrollRef.current;
      if (USE_SEARCH_CONDITION_1) {
        if (!!searchResult) {
          if (searchCount > searchResult.length) {
            currentKeyRdxFns.searching(q, filterState);
          }
        }
      } else if(USE_SEARCH_CONDITION_2){
        if (!!searchResult) {
          if (searchCount > searchResult.length) {
            currentKeyRdxFns.searching('',{},true);
          }
        }
      } 
      else {
        if (!!docs) {
          if (count > docs.length) {
            currentKeyRdxFns.request(false,0,{...defaultFilters});
          }
        }
      }
    }
  }, 500);

  const fetch = (refresh = false) => {
    if (loading) return;
    currentKeyRdxFns.toggleLoader();
    currentKeyRdxFns.request(refresh, dataLimit.current, {...defaultFilters});
  };

  useEffect(() => {
    if ((!loading && !docs) || (docs && docs.length < 10)) fetch();
  }, []);

  const parseListData = (l) => {
    const obj = {
      primaryText: get(l, 'data.ref_name'),
      secondaryText: listSecondLine({ doc: l, keyName }, tagName),
      src: get(l, 'data.thumbnail'),
    };
    if (showMediaType) {
      const mediaType = get(l, 'data.media_type');
      obj['icon']=getExIcon({type:mediaType});
    }
    return { ...obj };
  };
  const handleClick = (e, doc) => {
    const { params = {}, url } = match || {};
    if (params.id) {
      history.replace(url.replace(params.id, doc._id));
    } else {
      history.push(url + '/' + doc._id);
    }
  };

  const openFilter = () => {
    toggleFilter(true);
  };
  const closeFilter = () => {
    toggleFilter(false);
  };

  const onCloseSearch = () =>{
    setQ("");
    if(isEmpty(filterState)) applyFilter({...defaultFilters});
  }
  const showSearchData = USE_SEARCH_CONDITION_1 || USE_SEARCH_CONDITION_2;
  const _count = showSearchData ? searchCount : count;
  const page = showSearchData ? searchNextPage : nextPage;
  const hideChildren = loading && (!page || page < 1) && showSearchData;
  const _isSearchEmpty = !loading &&  showSearchData && searchResult && searchResult.length === 0
  return (
    <div className="flex-1 d-flex flex-column overflow-x-hidden d-print-none">
      {!hideHeader && (
        <ComponentListHeader
          keyName={keyName}
          count={_count}
          loading={loading}
          placeholder={placeholder}
          handleChange={handleChange}
          openFilter={openFilter}
          closeFilter={closeFilter}
          onCloseSearch={onCloseSearch}
          q={q}
          isFilter={isFilter}
          appliedFilter={isFilterExists(filterState)}
          handleRefresh={handleRefresh}
          showQwkFilter={showQwkFilter}
          filter={filterState}
          setFilter={applyFilter}
          onAdd={onAdd}
        />
      )}
      {!hideChildren && (
        <ListPart
          ref={listRef}
          keyName={keyName}
          docs={showSearchData ? searchResult : docs}
          parseListData={parseListData}
          onClick={handleClick}
          selected={get(match, 'params.id')}
          loading={loading}
          onScroll={(e) => handleScroll(e)}
          filterExists={isFilterExists(filterState)}
          searchEmpty={_isSearchEmpty}
          empty={!(docs || []).length}
          ListItemSecondaryAction={(props)=><SecondaryAction keyName={keyName} {...props}/>}
          query={q}
          {...listProps}
        />
      )}
      {isFilterOpen && (
        <CatalogFilter
          filters={filterState}
          keyName={keyName}
          open={true}
          onSave={applyFilter}
          handleClose={closeFilter}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state, op) => {
  return {
    ...state[op.keyName],
  };
};

export const ComponentListWrapper = connect(mapStateToProps, getCatalogRdxFn)(withRouter(ComponentList));

const ListPart =React.forwardRef((props,ref)=>{
  const {  keyName, docs = [], loading, query = null, onScroll, searchEmpty, empty, ...rest }=props;
  return (
    <List
      ref={ref}
      disablePadding
      id={keyName}
      className="overflow-auto flex-1 bg-white"
      onScroll={(e) => {
        e.persist();
        onScroll(e);
      }}
    >
      <DumbList docs={docs} {...rest} />
      {!!searchEmpty && !loading && <Placeholder keyName={keyName} isSearch={true} />}
      {!!empty && !query && !loading && <Placeholder keyName={keyName} isSearch={false} />}
    </List>
  );
});
