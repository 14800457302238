import React from 'react';
import Publication from './publications';
import usePublications from './usePublications';
import useVideoContext from './useVideoContext';

export default function ParticipantTracks({
  participant,
  disableAudio,
  enableScreenShare,
  videoPriority,
  isLocalParticipant,
}) {
  const { room } = useVideoContext();
  const publications = usePublications(participant);
  const isLocal = participant === room.localParticipant;

  let filteredPublications;
  filteredPublications = publications.filter(p => p.trackName !== 'screen');
  
  return (
    <>
      {filteredPublications.map(publication => (
        <Publication
          key={publication.kind}
          publication={publication}
          isLocalParticipant={isLocalParticipant}
          participant={participant}
          isLocal={isLocal}
          disableAudio={disableAudio}
          videoPriority={videoPriority}
        />
      ))}
    </>
  );
}
