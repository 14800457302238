import React, { useContext, useEffect } from 'react';
import { withSnackbar } from 'notistack';
import firebase from 'fitbud/firebase';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { bffGetContractStatus } from 'fitbud/api';
import useAPI from 'fitbud/hooks/useAPI';
import {get} from 'lodash'

export const ContractContext = React.createContext({});

const ContractProvider = (props) => {
  const { cid, refreshco } = useContext(FirebaseAuthContext);
  const {data, loading, error, load} = useAPI(bffGetContractStatus);
  useEffect(()=>{
   if(error) throw new Error(error); //if getting error in contract then throw error, error boundary will handle this.
  },[error])
  const reload = (immediate = true,unsubscribeInterval=1) => {
    if (immediate) {
      load([cid], resp => {

        const out = resp.data;
        if (out.contract && out.contract.meta){
          out.contract.meta = {
            ...out.contract.meta,
            ...Object.assign({},out.contract.meta.current || out.contract.meta.future || {}),
            showFuturePlan:!!out.contract.meta.future
          };
        }
        else
          out.contract.meta = {};
        if(out.products){
          out.products=out.products.map(i=>({
            ...i,
            hasOffer:!!(get(i,"price.monthly.offer")||get(i,"price.yearly.offer"))
          }))
        }
        return out;
      });
    } else { // start listener on self contract and if an update comes then reload
      let count=unsubscribeInterval;
      const unsubscribe = firebase.firestore().doc(`contracts/${cid}`).onSnapshot(doc => {
        count--;
        if(!count){
          unsubscribe();
          reload();
        }
      });
    }
  }
  useEffect(() => {
    reload();
  }, [load, cid]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ContractContext.Provider value={{ ...data, loading, error, reload}}>
      {props.children}
    </ContractContext.Provider>
  );
}

export default withSnackbar(ContractProvider);
