import React,{useEffect,useState,useRef, useMemo} from 'react';
import {Avatar as MuiAvatar} from "@material-ui/core";
import clsx from "clsx";
import {getUiAvatar} from "fitbud/utils/helpers";
import { parseImgUrl } from "croppy";



const Avatar=({src,base64=false,size,variant="circular" ,width = 160,name,...props})=>{
    const {className,styles,...rest}=props;
    const errorCount = useRef(0);
    const [err, updateErr] = useState(false);
    const _imgSrc = useMemo(()=>{
    if(!src) return "";    
    const { parsed } = parseImgUrl(src, { width});
    return  parsed.href;
    },[src, width])

    useEffect(() => {
        errorCount.current = 0;
    }, [src])

    function handleErr(){
        if(!errorCount.current){
            errorCount.current += 1;
        }
        else{
            updateErr(true);
        }
    }
    if(!!_imgSrc && !err){
        return(
            <MuiAvatar
            {...rest}
            onError={handleErr}
            src={_imgSrc}
            className={
                clsx(className,rest.onClick && "cursor-pointer")
            }
            classes={{
               root: size
            }}
            variant={variant}
            />
        )
    }
    return(
        <MuiAvatar
        {...rest}
        className={
            clsx(className,rest.onclick && "cursor-pointer","text-uppercase")
        }
        classes={{
          root: size
        }}
        variant={variant}
        >
        {getUiAvatar(name)}
        </MuiAvatar>
    )
    
}

export default Avatar
