import React from "react";
import { Helmet } from "react-helmet";
import { withSnackbar } from "notistack";
import SplitDetailView from "fitbud/components/splitDetailView";
import GroupChatList from "./groupChatList";
import GroupChatView from "./groupChatView";
import { GroupChatPlaceholder } from "./placeholder";

const GroupChat = props => {
  const groupId = props.match.params.id;
  const fn = !!groupId ? props.history.replace : props.history.push;
  const onSelect = id => fn(`/messages/group-chats/${id}`);
  return (
    <>
      <Helmet>
        <title>FitBudd » Group Chat</title>
      </Helmet>
      <SplitDetailView detail={!!groupId}>
        <GroupChatList onSelect={onSelect} selected={groupId} />
        {!!groupId ? (
          <GroupChatView
            groupId={groupId}
            key={groupId}
            onSelect={onSelect}
          />
        ) : <GroupChatPlaceholder />
        }
      </SplitDetailView>
    </>
  );
};

export default withSnackbar(GroupChat);
