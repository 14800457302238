import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MainParticipant from './mainParticipant';
import ParticipantList from './participantList';
import Notes from './notes';
import useVideoContext from './useVideoContext';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    height: '100%',
    display: 'grid',
    gridTemplateRows: '100%',
    gridTemplateColumns: props => props.isViewEnabled ? props.isViewEnabled && props.notes ? '1fr 1fr 380px' : '1fr 1fr' :  props.notes ? '1fr 380px 380px' : '1fr 355px'
  }
}));

const Room = () => {
  const { isViewEnabled, notes } = useVideoContext();
  const props = {
    isViewEnabled: isViewEnabled,
    notes: notes,
  }
  const classes = useStyles(props);
  
  return (
    <div className={classes.container}>
      <MainParticipant />
      <ParticipantList />
      <Notes />
    </div>
  );
}

export default Room;