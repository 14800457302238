import React, { useState, useContext } from "react";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import firebase from "fitbud/firebase";
import { useSnackbar } from "notistack";
import Dialog from "fitbud/components/Dialog";
import WoDetails from "fitbud/views/workouts/form";
import * as Sentry from "@sentry/browser";
import {ImportSelector} from "fitbud/components/catalog";
import SelectorCatalogList from "fitbud/components/catalog/selectorCatalogList";
import ArrowIcon from '@material-ui/icons/ArrowForward';
import {PUBLISHED,DRAFT_STATES} from "fitbud/utils/constants";
import {get} from 'lodash';

const ImportWo = (props) => {
  const { handleClose, handleGroupAdd,workoutId } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { cid } = useContext(FirebaseAuthContext);
  const [step, setStep] = useState(1);
  const [woId, setWoId] = useState(null);
  const [grpData, setGrpData] = useState(null);
  const [checked, setChecked] = useState([]);
  const [grpIndex, setGrpIndex] = useState([]);
  const [groups, setGroups] = useState([]);
  const handleChecked = async (doc) => {
    const isDraft=get(doc,'data.publish_status',PUBLISHED)===DRAFT_STATES['DRAFT_ONLY'];
    if(isDraft) {
      enqueueSnackbar("Workout is yet to be published", {
        variant: "warning",
      });
      return
    }
    if (workoutId === doc._id) {
      enqueueSnackbar("Cannot import from the current workout", {
        variant: "warning",
      });
    } else {
      const woRef = await fetchWorkout(doc._id);
      const data = woRef.data()
      if(data.is_single_video || data.groups.length === 0) {
        enqueueSnackbar("Exercises are required", {
          variant: "warning",
        });
      }else{
        setWoId(doc._id);
        setStep(2);
      }
    }
  };

  const _handleClose = () => {
    step === 1 ? handleClose() : setStep(1);
    setChecked([])
    setGrpData(null)
  };

  const onSubmit = () => {
    if (!grpData) {
      enqueueSnackbar("Exercises are required", { variant: "error" });
    } else {
      handleGroupAdd(grpIndex, groups);
      handleClose();
      setGrpData(null);
    }
  };

  const handleSelectGrp = (grpData, groups) => {
    const newChecked = [...checked];
    const index = [...grpIndex];
    if (checked.findIndex((item) => item === grpData) === -1) {
      newChecked.push(grpData);
    } else {
      newChecked.splice(
        checked.findIndex((item) => item === grpData),
        1
      );
    }
    const iN = groups.findIndex((item) => item === grpData)
    index.push(iN)
    setGrpIndex(index.sort((a, b) => a - b))
    setChecked(newChecked);
    setGrpData(newChecked);
    setGroups(groups)
  };

  const fetchWorkout = async (docId) => {
    try {
      const data = await firebase
        .firestore()
        .doc(`companies/${cid}/workouts/${docId}`)
        .get();
      return data;
    } catch (err) {
      console.log(err);
      Sentry.captureException(err);
    }
  };
 
  return (
    <Dialog
      titleFont="h3"
      buttonColor="primary"
      fullWidth
      title={step === 1 ? "Select Workout" : "Select Segments"}
      showBack={step===2}
      open
      onSave={step === 1 ? null : onSubmit}
      toolbarClass="height-60"
      paperClass="width-600 height-70"
      onClose={_handleClose}
      dialogContentClassName="d-flex flex-column"
      hideHeaderDivider={true}
      actionText="Import"
    >
      <div className="position-relative d-flex flex-column h-100">
        {step === 1 && (
              <SelectorCatalogList 
                keyName="workouts"
                defaultFilter={{"wotype":["structured"]}}
                listProps={{
                  ListItemSecondaryAction: () => <span style={{marginRight:8}}><ArrowIcon /></span>,
                }}
                ignoreFilter={["wotype"]}
                onClick={handleChecked}
                showDraftInd={true}
                />
        )}
        {step === 2 && (
              <WoDetails
                grpData={grpData}
                selectedGps={handleSelectGrp}
                id={woId}
                edit={false}
                grpIndex={grpIndex}
                importWoView
              />
        )}
      </div>
    </Dialog>
  );
};

export default ImportWo; 
