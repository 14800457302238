import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const NoNotes = props => (
  <SvgIcon
    width="154" height="145" viewBox="0 0 154 145"
    {...props}
  >
    <g fill="none">
      <path opacity="0.5" d="M76 121C109.136 121 136 94.189 136 61C136 27.811 109.018 1 76 1C42.8639 1 16 27.811 16 61C16 94.189 42.8639 121 76 121Z" fill="#CED6E2" />
      <g filter="url(#filter0_d_8131_26497)">
        <path d="M111.082 105.576H128.263C130.421 105.576 132.271 103.731 132.271 101.578V21.6233C132.271 19.4706 130.421 17.6255 128.263 17.6255H111.615" fill="white" />
      </g>
      <g filter="url(#filter1_d_8131_26497)">
        <path d="M42.8675 105.576H26.2201C24.0621 105.576 22.2124 103.731 22.2124 101.578V21.6233C22.2124 19.4706 24.0621 17.6255 26.2201 17.6255H42.5246" fill="white" />
      </g>
      <path d="M33.7944 33.6167H42.1191" stroke="#D6DCE8" strokeWidth="1.96145" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M33.7944 44.3804H42.1191" stroke="#D6DCE8" strokeWidth="1.96145" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M33.7944 55.4507H42.1191" stroke="#D6DCE8" strokeWidth="1.96145" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M33.7944 66.521H42.1191" stroke="#D6DCE8" strokeWidth="1.96145" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M33.7944 88.0479H42.1191" stroke="#D6DCE8" strokeWidth="1.96145" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M119.631 33.6167H111.615" stroke="#D6DCE8" strokeWidth="1.96145" strokeMiterlimit="10" />
      <path d="M119.631 44.3804H111.615" stroke="#D6DCE8" strokeWidth="1.96145" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M119.631 55.4507H111.615" stroke="#D6DCE8" strokeWidth="1.96145" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M119.631 66.521H111.615" stroke="#D6DCE8" strokeWidth="1.96145" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M119.631 88.0479H111.615" stroke="#D6DCE8" strokeWidth="1.96145" strokeMiterlimit="10" strokeLinecap="round" />
      <g filter="url(#filter2_d_8131_26497)">
        <path d="M108.047 111.867H47.0067C44.8487 111.867 42.999 110.021 42.999 107.869V14.9978C42.999 12.8451 44.8487 11 47.0067 11H108.047C110.205 11 112.055 12.8451 112.055 14.9978V107.869C112.055 110.021 110.205 111.867 108.047 111.867Z" fill="white" />
      </g>
      <path d="M75.7755 26.4574H53.6755C52.9755 26.4574 52.3755 25.8574 52.3755 25.1574C52.3755 24.4574 52.9755 23.8574 53.6755 23.8574H75.7755C76.4755 23.8574 77.0755 24.4574 77.0755 25.1574C77.0755 25.8574 76.4755 26.4574 75.7755 26.4574Z" fill="#D5DDEA" />
      <path d="M75.7755 99.6H53.6755C52.9755 99.6 52.3755 99 52.3755 98.3C52.3755 97.6 52.9755 97 53.6755 97H75.7755C76.4755 97 77.0755 97.6 77.0755 98.3C77.0755 99 76.4755 99.6 75.7755 99.6Z" fill="#D5DDEA" />
      <path d="M65.2755 33.6576H53.6755C52.9755 33.6576 52.3755 33.0576 52.3755 32.3576C52.3755 31.6576 52.9755 31.0576 53.6755 31.0576H65.1755C65.8755 31.0576 66.4755 31.6576 66.4755 32.3576C66.4755 33.0576 65.8755 33.6576 65.2755 33.6576Z" fill="#D5DDEA" />
      <path d="M63.5327 75.1572C63.1127 75.1572 62.7766 74.9891 62.4406 74.7371C61.8525 74.149 61.8525 73.1409 62.4406 72.5528C66.3052 68.7722 71.4299 66.6719 76.8908 66.6719C82.3516 66.6719 87.4764 68.7722 91.341 72.5528C91.9291 73.1409 91.9291 74.149 91.341 74.7371C90.7529 75.3252 89.7447 75.3252 89.1566 74.7371C85.8801 71.4606 81.5115 69.6963 76.8908 69.6963C72.2701 69.6963 67.9014 71.4606 64.6249 74.7371C64.2889 74.9891 63.9528 75.1572 63.5327 75.1572Z" fill="#B7C2D7" />
      <path d="M68.8255 52.6417L70.8419 50.6254C71.4299 50.0373 71.4299 49.0292 70.8419 48.4411C70.2538 47.853 69.2456 47.853 68.6575 48.4411L66.6412 50.4574L64.6249 48.4411C64.0368 47.853 63.0287 47.853 62.4406 48.4411C61.8525 49.0292 61.8525 50.0373 62.4406 50.6254L64.4569 52.6417L62.4406 54.658C61.8525 55.2461 61.8525 56.2543 62.4406 56.8423C62.7766 57.1784 63.1127 57.2624 63.5327 57.2624C63.9528 57.2624 64.2889 57.0944 64.6249 56.8423L66.6412 54.826L68.6575 56.8423C68.9936 57.1784 69.3296 57.2624 69.7497 57.2624C70.1698 57.2624 70.5058 57.0944 70.8419 56.8423C71.4299 56.2543 71.4299 55.2461 70.8419 54.658L68.8255 52.6417Z" fill="#B7C2D7" />
      <path d="M89.2406 52.6417L91.2569 50.6254C91.845 50.0373 91.845 49.0292 91.2569 48.4411C90.6688 47.853 89.6606 47.853 89.0726 48.4411L87.0563 50.4574L85.0399 48.4411C84.4519 47.853 83.4437 47.853 82.8556 48.4411C82.2675 49.0292 82.2675 50.0373 82.8556 50.6254L84.8719 52.6417L82.8556 54.658C82.2675 55.2461 82.2675 56.2543 82.8556 56.8423C83.1917 57.1784 83.5277 57.2624 83.9478 57.2624C84.3678 57.2624 84.7039 57.0944 85.0399 56.8423L87.0563 54.826L89.0726 56.8423C89.4086 57.1784 89.7447 57.2624 90.1647 57.2624C90.5848 57.2624 90.9208 57.0944 91.2569 56.8423C91.845 56.2543 91.845 55.2461 91.2569 54.658L89.2406 52.6417Z" fill="#B7C2D7" />
      <defs>
        <filter id="filter0_d_8131_26497" x="89.5061" y="6.83752" width="64.3403" height="131.103" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="10.788" />
          <feGaussianBlur stdDeviation="10.788" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8131_26497" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8131_26497" result="shape" />
        </filter>
        <filter id="filter1_d_8131_26497" x="0.636475" y="6.83752" width="63.8071" height="131.103" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="10.788" />
          <feGaussianBlur stdDeviation="10.788" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8131_26497" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8131_26497" result="shape" />
        </filter>
        <filter id="filter2_d_8131_26497" x="21.4231" y="0.212036" width="112.208" height="144.019" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="10.788" />
          <feGaussianBlur stdDeviation="10.788" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8131_26497" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8131_26497" result="shape" />
        </filter>
      </defs>
    </g>
  </SvgIcon>
);
