import { useState, useEffect } from 'react';

const UseMediaStreamTrack = (track) => {
  const [mediaStreamTrack, setMediaStreamTrack] = useState(track && track.mediaStreamTrack);

  useEffect(() => {
    setMediaStreamTrack(track && track.mediaStreamTrack);

    if (track) {
      const handleStarted = () => setMediaStreamTrack(track.mediaStreamTrack);
      track.on('started', handleStarted);
      return () => {
        track.off('started', handleStarted);
      };
    }
  }, [track]);

  return mediaStreamTrack;
}

export default UseMediaStreamTrack;
