import _ from 'lodash';
import moment from 'moment';

const getRecurrence = (subs_duration_type) => {
  if (!subs_duration_type) return null;
  let interval = 'year';
  let intervalCount = 1;

  if (subs_duration_type === 'quarter') {
    interval = 'month';
    intervalCount = 3;
  } else if (subs_duration_type.match(/\d+\s+(month|week|year)/)) {
    [intervalCount, interval] = subs_duration_type.split(/\s+/);
    intervalCount = parseInt(intervalCount, 10);
  } else if (subs_duration_type.match(/\s*(month|week|year)/)) {
    interval = subs_duration_type.trim();
  }

  return { interval, intervalCount };
};

// const ACCESS_TYPE = {
//   MEMBERSHIP: 'membership',
//   SESSIONS: 'sessions',
// };

const PURCHASE_MODE = {
  SUBSCRIPTION: 'subscription',
  ONE_TIME: 'one_time',
};

// const PLAN_STATUS = {
//   EXPIRED: 'expired',
//   ACTIVATED: 'activated',
//   INACTIVE: 'inactive',
// };
// const PURCHASE_STATUS = {
//   ACTIVE: 'active',
//   CANCELLED: 'cancelled',
// };

const INVITE_PURCHASE_KEY = 'plan_assign';

// const ADDENNDUM_KEY = 'addendum';
const DEFAULT_ACCESS_TYPE = 'membership';
const PURCHASE_SRC_INVITE = 'invite';

// const getPurchaseKey = (packId, subId, txnId, accessType, src) => {
//   if (accessType === ACCESS_TYPE.SESSIONS || src === 'invite') return txnId;
//   return subId || packId || txnId;
// };
const getPurchaseStatus = (profileStatus, subStatus) => {
  if (subStatus === 'cancelled') return 'cancelled';
  if (profileStatus === 'activated') return 'active';
  else return profileStatus;
};

export const inferPastPurchases = (userProfile) => {
  if (!userProfile.currPurchase && !userProfile.plan_assign && !userProfile.purchases) return {};
  if (_.size(userProfile.purchases)) return _.cloneDeep(userProfile.purchases);
  const purchases = userProfile.purchases || {};
  const timezone = _.get(userProfile, 'profile.time_zone', 'UTC');
  const {
    currPurchase,
    subscription,
    plan_assign,
    startDate,
    trial,
    duration,
    vid_call,
    endDate,
    current_plan_status,
  } = userProfile;
  const lastPurchase = currPurchase || plan_assign;

  if (!lastPurchase || currPurchase?.pack?.type === 'add_on') return purchases;

  const mode = subscription ? PURCHASE_MODE.SUBSCRIPTION : PURCHASE_MODE.ONE_TIME;
  const pastPurchaseKey = subscription
    ? subscription.id
    : userProfile.plan_assign
    ? INVITE_PURCHASE_KEY
    : lastPurchase.pack.pack_id;
  const pastPurchase = {
    pack: lastPurchase.pack, // handle pack data to match format in company pack details
    src: plan_assign ? PURCHASE_SRC_INVITE : subscription?.src || null,
    app: !!lastPurchase.pack?.app, // boolean
    status: getPurchaseStatus(current_plan_status, subscription?.status), // active, expired, cancelled, unconsumed(for addendum only) ????
    dateLast: subscription ? moment(subscription.lastPaid) : moment.tz(startDate, 'YYYYMMDD', timezone), // get from userProfile
    dateFirst: moment.tz(startDate, 'YYYYMMDD', timezone), // get from userProfile
    dateExpiry: subscription?.renewsOn ? moment(subscription.renewsOn) : moment.tz(endDate, 'YYYYMMDD', timezone), // get from userProfile
    dateCancel: subscription?.cancelledOn ? moment(subscription.cancelledOn) : null,
    dateConversion: trial?.convertedOn ? moment(trial.convertedOn) : null,
    accessType: DEFAULT_ACCESS_TYPE, // for old data there is only membership type
    accessValue: duration,
    durationType: userProfile.durationType,
    mode,
    accessCalls: vid_call || null,
    trialStatus: trial?.status || null,
    subId: subscription ? subscription.id : null,
    txnId: null,
    productId: lastPurchase?.pack?.pack_id,
    recurrence: getRecurrence(userProfile.subs_duration_type),
  };
  // take union of purchases and inferred past purchase from plan_assign/currPurchase
  if (!purchases[pastPurchaseKey]) purchases[pastPurchaseKey] = pastPurchase;
  return purchases;
};
