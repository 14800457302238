import _ from "lodash";
import React, { useState, useCallback,useEffect,useMemo } from "react";
import {
  TextField,
  MenuItem,
  InputAdornment,
  Typography,
  Divider,
} from "@material-ui/core";
import { FormTextField } from "fitbud/components/form-fields";
import Dialog from "fitbud/components/Dialog";
import { ExpandMore as KeyDown } from "@material-ui/icons/";
import { MediaUploader } from "fitbud/components/media-uploader/mediaUploader";
import Confirmation from "../../components/confirmationDialog";
import useServings from "fitbud/hooks/useServings";
import { Alert } from "@material-ui/lab";
import { DialogPdfUploader as PdfUploader } from "fitbud/components/pdfUploader";
import clsx from "clsx";
import { SimpleNumberInput } from "fitbud/components/numberInput";
import { CustomConfirmation } from "fitbud/components/customConfirmation";
import * as Sentry from "@sentry/browser";
import { MEASURE_BY_OPTIONS } from "fitbud/utils/constants";
import { Macros } from "./foodForm";
import { mapIdToObject } from "../workouts/helperfn";
import AutoSuggest from "fitbud/components/autoSuggest";

const CIDS = ['silbe', 'tasdeekraza123'];

const RecipesForm = (props) => {
  const {
    cid,
    doc,
    errors,
    loading,
    type,
    onSubmit,
    handleChange,
    onCancel,
    setMedia,
    handleMedia,
    handleDocument,
    removeMedia,
    handleMacros,
    servingData,
    isNew,
    handlePrevMacros,
    handleIsDocument,
    handleCaloriesChange,
    draftFeature,
    handleDraft,
    handlePublish,
    videoStatusRefresh,
    isDraftAvailable,
    updateMediaMeta,
    nutritionTags=[],
  } = props;
  const [dirty, setDirty] = useState(false);
  const [isConfirmationOpen, showConfirmation] = useState(null);
  const {
    title,
    description,
    thumbnail,
    macros,
    media,
    document,
    ref_name,
    serving,
    serving_size = 100,
    serving_type,
    duration,
    noAutomaticCalories,
    noAutomaticMacros,
    systags
  } = doc || {};
  const _tags = useMemo(() => mapIdToObject(nutritionTags, systags), [nutritionTags, systags]);
  //NOTE:serving to exclude gm,ml,ounce
  const [sortServing, selectedServingId] = useServings({
    servingData,
    selected:serving,
    separateFlOz:true
  });
  const _duration = !!duration ? duration / 60 : "";

  useEffect(()=>{
    handlePrevMacros(noAutomaticMacros) 
  },[noAutomaticMacros])

  const handleSubmit = () => {
    if (!isNew && !dirty && !isDraftAvailable) {
      onCancel();
      return;
    }
    if (isNew || !draftFeature) {
      onSubmit();
    } else {
      showConfirmation("save");
    }
  };
  
  const handleChangeWrapper = (e, skipDirty = false) => {
    //for new id and recipe lock will always false to the handler
    const lock = !!isNew && type !== "fdc" ? false : !noAutomaticMacros; //in case of new it is always false ::
    handleChange(e, lock, { caloriesLock: !noAutomaticCalories, skipDirty });
    !skipDirty && setDirty(true);
  };
  const handleMacrosWrapper = (e, skipDirty = false) => {
    handleMacros(e, !noAutomaticCalories);
    !skipDirty && setDirty(true);
  };
  const handleCaloriesWrapper = (e, skipDirty = false) => {
    handleCaloriesChange(e);
    !skipDirty && setDirty(true);
  };
  const handleDocumentWrapper = (type, f, meta) =>{
    setDirty(true)
    handleDocument(type, f, meta)
  }
  const setMediaWrapper=(media)=>{
    setDirty(true)
    setMedia(media);
  }
  const handleMediaWrapper=(type, file)=>{
    setDirty(true)
    handleMedia(type, file);
  }
  const removeMediaWrapper=(type)=>{
    setDirty(true)
    removeMedia(type);
  }
  const handleMediaError=(err)=>{
    console.log("onError",err);
    Sentry.captureException(err);
  }
  const whenPlayerReady=useCallback((player)=>{
   try{
    const videoHeight=player.getInternalPlayer().videoHeight;
    if(!!videoHeight){
      updateMediaMeta({height:`${videoHeight}p`});
    }
   }
   catch(err){
    console.log("onReady err",err);
    Sentry.captureException(err);
   }
  },[doc.media])
  const setDuration=(secs)=>{
    const value = Math.round(secs);
    updateMediaMeta({duration:value});
  }
  const openConfirmationDialog = () => {
    dirty ? showConfirmation("cancel") : onCancel();
  };

  return (
    <Dialog
      toolbarClass="height-60"
      buttonColor="primary"
      open
      onClose={openConfirmationDialog}
      onSave={handleSubmit}
      title={`${isNew ? "Add" : "Edit"} Recipe`}
      titleFont="h3"
      paperClass="width-640"
      maxWidth={"md"}
      actionText={isNew && "Add to My Library"}
    >
      <div className="fp-20">
        <FormTextField fullWidth label="Reference Name" required>
          <TextField
            id="ref_name"
            autoComplete="off"
            value={ref_name}
            onChange={handleChangeWrapper}
            error={!!errors.ref_name}
            helperText={errors.ref_name}
            variant="outlined"
            disabled={loading}
            required
            type="text"
            InputProps={{
              classes: {
                root: "medium",
                input: "size_16_500 medium",
              },
            }}
          />
        </FormTextField>
        <FormTextField
          fullWidth
          label={"Display Name"}
          required
        >
          <TextField
            id="title"
            autoComplete="off"
            value={title}
            onChange={handleChangeWrapper}
            error={!!errors.title}
            helperText={errors.title}
            variant="outlined"
            disabled={loading}
            required
            type="text"
            InputProps={{
              classes: {
                root: "medium",
                input: "size_16_500 medium",
              },
            }}
          />
        </FormTextField>
        <FormTextField fullWidth label="Tags">
          <AutoSuggest
            placeholder="Add Tags . . ."
            onChange={value => handleChangeWrapper({ target: { name:"systags", value: value || [] }})}
            options={nutritionTags}
            value={_tags}
          />
        </FormTextField>
        <div className="d-flex" style={{ gap: "20px" }}>
          <FormTextField fullWidth label="Serving Name">
            <TextField
              fullWidth
              type="text"
              onChange={(e) => handleChangeWrapper(e)}
              value={selectedServingId}
              error={!!errors.serving}
              helperText={errors.serving}
              select
              variant="outlined"
              required
              SelectProps={{
                name: "serving",
                IconComponent: KeyDown,
                inputProps: {
                  classes: {
                    icon: "fpl-5",
                  },
                },
              }}
              InputProps={{
                classes: {
                  root: "medium",
                  input: "size_16_500 medium select-medium",
                },
              }}
            >
              {sortServing.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.value}
                </MenuItem>
              ))}
            </TextField>
          </FormTextField>
          <FormTextField fullWidth label="Cooking Time">
            <SimpleNumberInput
              fullWidth
              id="duration"
              type="number"
              placeholder="0"
              onChange={handleChangeWrapper}
              value={_duration}
              error={!!errors.duration}
              helperText={errors.duration}
              decimalLimit={0}
              variant="outlined"
              // required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Minutes</InputAdornment>
                ),
                classes: {
                  root: "medium",
                  input: "size_16_500",
                },
              }}
              inputProps={{
                min: 0,
              }}
            />
          </FormTextField>
        </div>
        <FormTextField fullWidth label="Description">
          <TextField
            multiline
            rows="2"
            rowsMax="10"
            id="description"
            fullWidth
            onChange={handleChangeWrapper}
            defaultValue={description}
            error={!!errors.description}
            helperText={errors.description}
            variant="outlined"
            disabled={loading}
            required
            InputProps={{
              classes: {
                root: "large",
                input: "size_16_500",
              },
            }}
          />
        </FormTextField>
        <Divider
          className="position-relative mb-25"
          style={{ left: -20, width: "calc(100% + 40px)" }}
        />
        <Typography className="font_18_600 mb-25">
          Nutrition & Serving info
        </Typography>
        <Macros
          macros={macros}
          noAutomaticCalories={noAutomaticCalories}
          handleMacrosChange={handleMacrosWrapper}
          handleCaloriesChange={handleCaloriesWrapper}
          handleCheckboxChange={handleChangeWrapper}
          errors={errors}
        />
        <Divider
          className="position-relative mt-16 mb-25"
          style={{ left: -20, width: "calc(100% + 40px)" }}
        />
        <MediaUploader
          label="Recipe Media"
          media={media}
          errors={errors}
          multiImage={CIDS.includes(cid)}
          setMedia={setMediaWrapper}
          handleMedia={handleMediaWrapper}
          removeMedia={removeMediaWrapper}
          featureType="recipes"
          showFeature={false}
          classes={{
            mediaBlock: "mb-20",
          }}
          videoStatusRefresh={videoStatusRefresh}
          playerProps={{
            onDuration: setDuration,
            onReady: whenPlayerReady,
            onError: handleMediaError,
          }}
          withThumbnail
          thumbnail={thumbnail}
          handleChange={(e) => {
            setDirty(true);
            handleChange(e);
          }}
          isNew={isNew}
        />
        <Divider
          className="mt-20 mb-25 position-relative"
          style={{ left: -20, width: "calc(100% + 40px)" }}
        />
        <Typography variant="h3" style={{ marginBottom: 16 }}>
          Attachment
        </Typography>
        <PdfUploader
          classes={{ root: "w-100 fb-border-radius-5" }}
          handleChange={handleDocumentWrapper}
          document={document}
          errors={errors && errors.document}
        />
      </div>
      {isConfirmationOpen && (
        <CustomConfirmation
            handleClose={() => showConfirmation(null)}
            handleDiscard={onCancel}
            handleDraft={handleDraft}
            handlePublish={handlePublish}
            dirty={dirty}
            showDiscard={dirty}
        />
      )}
    </Dialog>
  );
};
export default RecipesForm;
