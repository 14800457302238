import React, { useEffect } from 'react';
import { listSecondLine } from 'fitbud/components/catalog/index';
import { get } from 'lodash';
import _ from 'lodash';
import { useServices } from 'fitbud/providers/services-provider';
import CommonMultiSelector from './commonMultiSelector';

export const _parseListData = (l) => {
  return {
    primaryText: get(l, 'data.ref_name'),
    secondaryText: listSecondLine({ doc: l, keyName: 'services' }),
    src: get(l, 'data.thumbnail'),
  };
};
const ServiceMultiSelector = (props) => {
  const { services, loadServices, loading } = useServices();
  useEffect(() => {
    loadServices();
  }, []);
  return (
    <CommonMultiSelector
      keyName="services"
      parseListData={_parseListData}
      loading={loading}
      docs={services}
      {...props}
    />
  );
};

export default ServiceMultiSelector;
