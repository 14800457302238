import React, { useState, useMemo } from 'react';
import { MenuItem, Select, OutlinedInput, InputAdornment, makeStyles, Typography } from '@material-ui/core';
import Dialog from 'fitbud/components/Dialog';
import DropDownIcon from '@material-ui/icons/ExpandMore';
import { FormTextField } from 'fitbud/components/form-fields';
import { TimePicker } from '@material-ui/pickers';
import clsx from 'clsx';
import { date2hhmm } from 'fitbud/utils/scheduling';
import moment from 'moment';
import { useReminder } from 'fitbud/views/reminders/provider';
import _ from 'lodash';
import { hhMMToDate, WEEK_OPTIONS, DAYS_OPTIONS, getModeDay } from 'fitbud/views/reminders/helper';

const WEEK_OPTIONS_FOR_CHECK_IN = [...WEEK_OPTIONS, { value: 'automatic', label: 'Automatic' }];
const DAYS_OPTIONS_FOR_CHECK_IN = [...DAYS_OPTIONS, { value: 'automatic', label: 'Automatic' }];

const useStyles = makeStyles(() => ({
  timeInput: {
    minHeight: '48px',
    fontSize: '15px',
    fontWeight: 500,
  },
  infoBox: {
    borderRadius: 6,
    border: '1px solid #317FF5',
    backgroundColor: '#F0F8FD',
    padding: 15,
  },
}));

export const InfoBox = ({ text = '', className }) => {
  const classes = useStyles();
  if (!text) return null;
  return (
    <div className={clsx(classes.infoBox, className)}>
      <Typography className="text-dark-grey font_13_500">{text}</Typography>
    </div>
  );
};

const CheckInEditor = () => {
  const { reminders, setConfigKey, dirty, onSave, closeEdit, time_picker_step, checkInConfig, getCheckInsRccOptions } = useReminder();
  const checkIns = _.get(reminders, 'checkins', {});
  const CHECK_IN_OPTIONS = getCheckInsRccOptions();
  const data = useMemo(() => {
    return { ...checkInConfig, ...checkIns };
  }, [checkInConfig, checkIns]);
  const onChange = (key, value) => {
    setConfigKey('checkins', key, value);
  };
  const handleSubmit = () => {
    onSave('checkins');
  };

  return (
    <CheckInEditorDialog
      open={true}
      onChange={onChange}
      onSave={handleSubmit}
      time_picker_step={time_picker_step}
      onClose={closeEdit}
      data={data}
      CHECK_IN_OPTIONS={CHECK_IN_OPTIONS}
    />
  );
};
export default CheckInEditor;

export const CheckInEditorDialog = (props) => {
  const { onSave, open, data, onClose, time_picker_step, onChange, CHECK_IN_OPTIONS } = props;
  const [dirty, setDirty] = useState(false);
  const [checkins, setCheckIns] = useState(data || {});
  // const [errors, setErrors] = useState({});
  const { time, any_day, mode, automatic, day_of_month, day_of_week } = checkins || {};
  const classes = useStyles();
  const isMonthly = (mode || '').includes('month');
  const isDaily = (mode || '').includes('day');

  const updateCheckInState = (key, value) => {
    //TODO:: need to set dirty. numUnitInput fire on initially.
    setCheckIns((checkins) => ({ ...checkins, [key]: value }));
    onChange && onChange(key, value);
    setDirty(true);
  };

  // const handleChange = (e) => {
  //   const key = e.target.name || e.target.id;
  //   let value = e.target.value;
  //   if (e.target.type === 'number') value = Number(value);
  //   updateCheckInState(key, value);
  // };

  const handleModeChange = (e) => {
    let { name, value } = e.target;

    const out = {
      checkin_frequency: getModeDay(value, CHECK_IN_OPTIONS),
      [name]: value,
    };
    if (value.includes('month') && !day_of_month && !automatic) out.day_of_month = '7';
    else if (value.includes('week') && !day_of_week && !automatic) out.day_of_week = 'friday';
    setCheckIns((prev) => ({ ...prev, ...out }));

    onChange && onChange(false, { ...checkins, ...out });
  };

  const handleDayChange = (e) => {
    const key = e.target.name || e.target.id;
    const value = e.target.value;
    const out = {};
    if (value === 'automatic') {
      out.automatic = true;
      out.day_of_month = null;
      out.day_of_week = null;
    } else {
      out[key] = value;
      out.automatic = false;
    }
    setCheckIns((prev) => ({ ...prev, ...out }));
    onChange && onChange(false, { ...checkins, ...out });
  };

  const onTimeChange = (key) => (time) => {
    const t = moment(time);
    const hhmm = date2hhmm(t);
    updateCheckInState(key, hhmm);
  };

  // const handleTypeChange = (e, option) => {
  //   const value = option === 'any_day';
  //   updateCheckInState('any_day', value);
  // };

  // const isValid = () => {
  //   let out = true;
  //   return out;
  // };

  const handleSave = () => {
    onSave && onSave(checkins); //its responsibility to close dialog after save by caller.
  };

  return (
    <Dialog
      toolbarClass="height-60"
      buttonColor="primary"
      title="Check-In Reminder"
      open={open}
      withConfirmation
      isDirty={dirty}
      onClose={onClose}
      paperClass="width-400"
      titleFont="h3"
      onSave={handleSave}
      actionText="Save">
      <div className="px-20 pt-20">
        <InfoBox
          className="mb-20"
          text={
            any_day
              ? 'Reminders will be sent to the clients as per the settings below'
              : 'Reminders will be sent to the clients on the days their check-in is due'
          }
        />
        <FormTextField fullWidth label="Frequency">
          <Select
            IconComponent={DropDownIcon}
            input={
              <OutlinedInput
                classes={{
                  root: 'medium',
                  input: 'size_15_600 select-medium',
                }}
                name="mode"
              />
            }
            value={mode}
            onChange={handleModeChange}
            name="mode">
            {CHECK_IN_OPTIONS.map((op) => (
              <MenuItem key={op.value} value={op.id}>
                {op.label}
              </MenuItem>
            ))}
          </Select>
        </FormTextField>
        {!isDaily && (
          <FormTextField fullWidth label="Day">
            {!!isMonthly && (
              <Select
                IconComponent={DropDownIcon}
                input={
                  <OutlinedInput
                    classes={{
                      root: 'medium',
                      input: 'size_15_600 select-medium',
                    }}
                    name="day_of_month"
                  />
                }
                value={automatic ? 'automatic' : day_of_month}
                onChange={handleDayChange}
                name="day_of_month">
                {DAYS_OPTIONS_FOR_CHECK_IN.map((op) => (
                  <MenuItem key={op.value} value={op.value}>
                    {op.label}
                  </MenuItem>
                ))}
              </Select>
            )}
            {!isMonthly && (
              <Select
                IconComponent={DropDownIcon}
                input={
                  <OutlinedInput
                    classes={{
                      root: 'medium text-capitalize',
                      input: 'size_15_600 select-medium',
                    }}
                    name="day_of_week"
                  />
                }
                value={automatic ? 'automatic' : day_of_week}
                onChange={handleDayChange}
                name="day_of_week">
                {WEEK_OPTIONS_FOR_CHECK_IN.map((op) => (
                  <MenuItem key={op.value} value={op.value} className="text-capitalize">
                    {op.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormTextField>
        )}
        <FormTextField fullWidth label="Send reminder at">
          <TimePicker
            value={hhMMToDate(time)}
            format="hh:mm a"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <DropDownIcon />
                </InputAdornment>
              ),
              className: clsx(classes.timeInput, 'font_15_600'),
            }}
            inputVariant="outlined"
            onChange={onTimeChange('time')}
            minutesStep={time_picker_step}
          />
        </FormTextField>
      </div>
    </Dialog>
  );
};
