import * as _ from 'lodash';



export const dbToUi = (rawData, exploreConfig) => {
  // Will separate the data in a map as { [pack_id] : { nutrition, workout, resources }}
  const out = {};
  const keys = Object.keys(rawData);
  keys.forEach((item) => {
    const parent_type = exploreConfig[item].parent_type || item;
    if(!_.isEmpty(rawData[item])){
      const { access } = rawData[item];
      if(!_.isEmpty(access)){
        const packs = Object.keys(access);
        packs.forEach((plan_id) => {
          if (out[plan_id]) out[plan_id][item] = _.get(access, plan_id, {});
          else out[plan_id] = { [item]: _.get(access, plan_id, {}) };
          out[plan_id][item].parent_type = parent_type;
        })
      }
    }

  });
  return out;
};

export const getAccessStatus = (planData={}, summarize = true) => {
  if (_.isEmpty(planData)) return { workout: 'full', nutrition: 'full', resources: 'full' };
  let out = {};
  Object.keys(planData || {}).forEach((key) => {
    const { collections, schedules, all, on_demand } = _.get(planData, `${key}`, {});
    let result;
    if (all) result = 'full';
    else if (!all && !(collections || []).length && !(schedules || []).length && !on_demand) result = 'no';
    else result = 'partial';
    out[key] = result;
  });
  return out;
}

/*
Arg type::
statusMap : {workout:"full", nutrition:"no", stretching:"partial", ... and so on}
TABS_PARENT_TYPE_MAPPING : {workout : ["workout", "stretching"], nutrition:["nutrition"],resources: ["resources"]} and so on
key : is workout
,
*/
export const getParentTypeStatus = (statusMap,TABS_PARENT_TYPE_MAPPING, parentTypeKey ) =>{
  const allStatus = [];
  const parentTabKeys = TABS_PARENT_TYPE_MAPPING[parentTypeKey] || [];
  parentTabKeys.forEach((tabKey) => {
    allStatus.push(statusMap[tabKey] || "full"); //by default is full
  });
  //now check for all status ::
  if (!allStatus.length) return null;
  //possible values of status is full, partial and no:
  //if all status is all then all. if all is no then no, other wise partial..
  if (allStatus.every((status) => status === 'full')) return 'full';
  if (allStatus.every((status) => status === 'no')) return 'no';
  else return 'partial'; 
};

export const getStatusColorClass = (status) => {
  const obj = { full: "text-success", partial: "text-warning", no: "text-danger" };
  return obj[status];
};

export const getAccessKeyName = (key) => key === 'fitness' ? 'workout' : key;
export const INVALID_PAGE_DATA_IDS = {
  on_demand: true,
  space: true,
  programs: true,
};
