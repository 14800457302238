import React, { useEffect, useContext } from 'react';
import _ from 'lodash';
import { AccessContext } from 'fitbud/providers/access-provider';
import AddOnCalling from './addOnCalling';
import RegularCalling from './regularCalling';

const VideoCallEditor = (props) => {
  const { id, data = {} } = props;
  const {startEdit, updateTargetAccess} = useContext(AccessContext);
  useEffect(() => {
    const payload = {add_on_type: data.add_on_type || (data.type === 'add_on' ? 'one_to_one' : 'none')};
    if (id === 'new') updateTargetAccess('new', payload);
    else startEdit(payload);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const type = data.type;
  const isAddOn = type === 'add_on';
  const isSessionAddon = _.get(data, "type") === "add_on" && ['group_class', 'one_to_one'].includes(_.get(data, "add_on_type"));
  return (isAddOn && isSessionAddon) ? <AddOnCalling {...props} /> : <RegularCalling {...props} />;
};

export default VideoCallEditor;
