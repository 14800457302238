import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { useTheme } from '@material-ui/core/styles';

export const DeleteIcon = props => {
  const theme = useTheme();
  let stroke = '#37404D'; // theme.palette.action.active;
  const { color, ...rest } = props;
  if (color) {
    if (theme.palette[color]) stroke = theme.palette[color].main;
    else stroke = color;
  }
  return (
    <SvgIcon {...rest}>
      <path d="M3 6H5H21" stroke={stroke} fill='none' stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z"
        stroke={stroke} fill='none'
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M14 11V17"
        stroke={stroke} fill='none'
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M10 11V17"
        stroke={stroke} fill='none'
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
};

