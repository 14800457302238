import React , {useMemo} from 'react';
import FoodHeader from 'fitbud/views/foodRecipes/newUi/foodHeader';
import PFC, { Calories } from 'fitbud/views/foodRecipes/newUi/pfc';
import { Card, CardContent, IconButton, Divider, Typography, CardHeader, Grid, Chip } from '@material-ui/core';
import DetailEditIcon from 'fitbud/icons/detailEdit';
import { LinearPfc } from 'fitbud/views/foodRecipes/newUi/pfc';
import { MoreLessTextBtn } from 'fitbud/components/moreLessText';
import _ from 'lodash';
import { DetailPgStyling } from 'fitbud/hooks/useDetailPgStyle';
import clsx from 'clsx'
import {DraftBanner} from "fitbud/components/draftBanner";
import { DRAFT } from 'fitbud/utils/constants';
import { mapIdToObject } from 'fitbud/views/workouts/helperfn';
import PdfUploader from "fitbud/components/pdfUploader";

const MealDetails = (props) => {
  const { doc, handleEditMode, deleteItem, copyItem, handleMealEditMode,
    isPreview=false, disableActions,draftBannerProps={},showOnlyEdit=false, nutritionTags,
  } = props;
  const _calories = (doc && doc.macros && doc.macros.calories) || 0;
  const _description = _.get(doc,"description",'').trim();
  const isDraft=draftBannerProps.showingDoc===DRAFT;
  let _tags = useMemo(()=> mapIdToObject(nutritionTags, doc.systags).map(i=>i.label),[nutritionTags, doc.systags]);
  const [is_document, document] = useMemo(()=>{
    const type = _.get(doc, "type");
    const document = _.get(doc,'document.0',{})
    const docUrl = _.get(document, `url`,"");
    return [!!docUrl, document]
  },[doc])

  const getSubtitle = () => {
    if (doc.type === 'macros') return <div><Typography className={'text-grey font_15_500'}>Macros</Typography></div>;
    if (doc.type === 'food')
      return (
        <div className="d-flex">
          <Typography className={'text-grey font_15_500'}>Food&nbsp;•&nbsp;</Typography>
          <LinearPfc
            data={doc.macros}
            labelClassName="font_15_500"
            valueClassName ="font_15_600"
          />
        </div>
      );
  };
  return (
    <DetailPgStyling>
      {draftBannerProps.isDraftAvailable && !isPreview && <DraftBanner {...draftBannerProps} doc={doc}/>}
      <Card square elevation={0}>
        <FoodHeader
          doc={doc}
          handleEditMode={handleEditMode}
          onDelete={deleteItem}
          onCopy={copyItem}
          subtitle={getSubtitle()}
          isPreview={isPreview}
          keyName="meal-plans"
          isDraft={isDraft}
          disableActions={disableActions||showOnlyEdit}
        />
          <CardContent>
            {_description && <MoreLessTextBtn text={_description} className="desc fmt-25" btnClassName="viewMore"/>}
              <Grid item xs={12} className="tags-25 fmt-25">
                <Typography className={"field-nutrition-title"}>
                  Tags
                </Typography>
                <Typography className={clsx("field-value",!_tags.length && "mt-10")}>
                  {_tags.map((i) =>
                      <Chip className="chips mt-10 fmr-10" key={i} label={i} />
                    )
                  }
                  {!_tags.length && <span className='font_13_500'>Not Specified</span>}
                </Typography>
              </Grid>
          </CardContent>
      </Card>
      <Divider className="dense"/>
      <Card square elevation={0}>
        <CardHeader
          title="Nutrition Facts & Categories"
          action={isPreview ? null:
          (<IconButton onClick={handleMealEditMode}>
            <DetailEditIcon/>
            </IconButton>
          )}
          classes={{root:"sub-header-root",title:"sub-title",action:"margin-top-negative"}}
        />
        <CardContent>
          <Typography className="fmb-10 field-nutrition-title">Summary</Typography>
          <Calories calories={_calories || '0'} />
           <div className="mt-20 d-inline-block">
             <PFC macros={doc.macros} />
           </div>
           {is_document && (
            <div className="mt-25">
              <Typography className="fmb-10 field-nutrition-title">Attachment</Typography>
              <PdfUploader file={document} />
            </div>
          )}
        </CardContent>
      </Card>
    </DetailPgStyling>
  );
};

export default MealDetails;
