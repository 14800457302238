import { makeStyles } from "@material-ui/core/styles";

const useChipStyle = props => {
  return makeStyles(theme => ({
    root: {
      height: "36px",
      minWidth: "60px",
      borderColor:"#DDDDDD",
      "&:hover": {
        borderColor: "#42484b",
        backgroundColor: "#ffffff !important"
      },
      "&:focus": {
        backgroundColor: "#ffffff !important"
      }
    },
    clickable: {
      border: `2px solid ${theme.palette.primary.main} !important`
    }
  }))(props);
};

export default useChipStyle;
