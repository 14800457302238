import React, { createContext, useContext, useEffect, useRef, useMemo, useReducer } from 'react';
import { useSnackbar } from 'notistack';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';

import reducer from './reducer';
import StateMachine from './state-machine';
import AssignNewPlan from './assignNewPlan';
import Editor from './editor';

const UserSchContext = createContext(undefined);
UserSchContext.displayName = 'UserSchContext';

const UserSchProvider = ({ uid, userDoc, updateUMS,children,...rest}) => {
  const { cid, comp } = useContext(FirebaseAuthContext);
  const [state, dispatch] = useReducer(reducer, {
    cid,
    // set if parent gave us no aplan. i.e. editor's save OP will have to update parent with the new aplan generated by it
    // also set in case of re-assign & assing-new AXNs. If the editor is closed w/o save it gets reset
    buildCmd: null,
    // these 4 get set when the remote plan get loaded for the first time. They are expected to not change (mostly) during lifetime of this provider
    // additionally dayToday is updated every time the editor is opened. Just in case ¯\_(ツ)_/¯
    startDate: '20200101',
    dayToday: 0,      // the day number of today w.r.t startDate (0 based)
    weekToday: 0,     // the index of the week which 
    aplan: '',        // ID of the aplan
    ready: false,     // set when remote.plan gets set
    weekReady: false, // false'd when currWeek changes, true'd when week data is loaded
    currWeek: 0,      // index of week currently being viewed. Default is 0, but based on plan state and today this gets updated when remote.plan is first loaded
    currWeekId: '',   // week UUID corresponding to currWeek. XXX currWeek and currWeekId are common across remote and local
    remote: {         // ReadOnly copies of plan, weeks & masters
      plan: null,
      weeks: {},
      woms: [],       // these masters aren't same as wom/mlm/slm in planDoc. Those are maps, these are array - i.e. these are reversed representation of those
      mlms: [],
      slms: [],
    },
    local: {          // ReadWrite copies of plan, weeks & masters
      plan: null,
      weeks: {},
      woms: [],       // see above
      mlms: [],
      slms: [],
    },
    emm: {
      needed: true,   // migration is needed
      isDone: false,  // migration is done
    },
    dfrdOvrs: {       // deferred overrides. recorded during the process of migration and saved at the end
      workouts: [], meals: [] },
    lgcyOvrs: {       // legacy overrides. will only get saved if a copy paste happens
      workouts: [], meals: [] },
    bmrremote: null,
    bmrlocal: null,
    dates: [],        // RO | array of moment objects for each day in week. Only maintained so components don't need to compute from scratch
    editing: false,   // RW | is current mode edit or not, based on this we will be either reading from remote or read from/write to local 
    block: false,     // RW | show blocking loader or not
    halt: false,      // RW | extra block flag. Non blocking, but obstructs the trainer from accessing the week level CTAs
    opaddwks: false,  // RW | stuff needed to control the week adding LONG OP
    opcopy: false,    // RW | stuff needed to control the copy paste LONG OP
    opdelete: false,  // RW | stuff needed to control the delete with confirmation LONG OP
    opimport: false,  // RW | stuff needed to control the import LONG OP
    opmngalt: false,  // RW | stuff needed to control the manage alts long OP
    opconfirm: false, // RW | stuff needed for confirmations - discard changes, delete stuff etc.
    schCache: {},     // caches for schedules (WO & ML)
    objCache: {},     // caches for objects (WO & ML, supplements are also meals)
    ftAltWOs: false,  // does company support alternate WOs
    lookAheadWeeks: 1,// number of weeks into the future that get published
    weekPublished: 0, // currently published week. Is 0 if plan isn't activated
    // UI helper states
    isWkPublished: true,  // FIXME
    isWkPaidFor: true,    // FIXME
    isSubscription: true, // FIXME
    showCardDetails: null,
  });
  const { enqueueSnackbar } = useSnackbar();
  const sm = useRef(); // holds reference to instance of state machine

  const out = useMemo(() => {
    if (!sm.current) {
      sm.current = new StateMachine(cid, uid, dispatch, comp.data(), updateUMS, enqueueSnackbar);
      if (comp && comp.exists) {
        dispatch({ comp: comp.data() });
        return { ...state, controller: sm.current };
      }
    }
    sm.current.state = state;
    return { ...state, controller: sm.current };
  }, [cid, uid, state, comp, enqueueSnackbar, updateUMS]);

  useEffect(() => {
    if (!userDoc || !sm.current) return;
    sm.current.user = userDoc;
  }, [userDoc]);
  return (
    <UserSchContext.Provider value={{...out,...rest}}>
      {children}
      {!state.editing && state.reassign && <AssignNewPlan/>}
      {state.editing && <Editor {...userDoc}/>}
    </UserSchContext.Provider>
  );
};

export { UserSchContext, UserSchProvider };
