import React, { useContext, useState, useEffect, useCallback } from 'react';
import { TagsContext } from 'fitbud/providers/tagsProvider';
import { CircularProgress, InputBase, InputAdornment, Typography } from '@material-ui/core';
import ChipGroups from 'fitbud/components/catalog-filter/chipGroups';

import get from 'lodash/get';
import debounce from 'lodash/debounce';
import filter from 'lodash/filter';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';

const useClasses = makeStyles(() => ({
  checkBoxLabel: {
    fontSize: 15,
    fontWeight: 400,
    color: '37404D',
  },
  checkboxLabelClass: {
    width: '45%',
    marginRight: '0px',
    paddingRight: 0,
  },
  inputBase: {
    borderRadius: '20px',
    height: 40,
    border: '1px solid #D3D9DB',
  },
}));

/*
props : isMinified means this will not contain search input and it's height will increase as of its content
*/

const filterNutritionTags = (tags=[], type) => tags.filter((tag) => !tag[1].modules ? true : tag[1].modules.indexOf(type) !== -1);

const TagFilter = (props) => {
  const { tag, subType, onSelectItem, filters, label, isMinified = false, searchPlaceholder = "Search", type, append } = props;
  const { fetchTags, ...tags } = useContext(TagsContext);
  const [loading, setLoading] = useState(false);
  const [q, setQ] = useState('');
  const [tagOptions, setTagOptions] = useState([]);
  const [searchTagOptions, setSearchResult] = useState([]);
  const _tagValues =  get(tags, tag);
  const classes = useClasses();

  useEffect(() => {
    if (!_tagValues) return setTagOptions([]);
    // filter out nutrition tags on basis of modules inside nutrition tags
    const _out = (tag === 'nutrition' ? filterNutritionTags(_tagValues, type) : (_tagValues || [])).map((tag) => {
      return { value: tag[0], label: tag[1].value };
    });
    if (append && append.length) _out.push(...append);
    setTagOptions(_out);
  }, [_tagValues, tag, type, append]);

  const filterSearchResult = (value) => {
    value = (value || '').trim().toLocaleLowerCase();
    if (tagOptions && tagOptions.length) {
      let _out = filter(tagOptions, (op) => {
        let label = op.label.trim().toLocaleLowerCase();
        return (label || '').includes(value);
      });
      setSearchResult(_out);
    }
  };

  const _setSearch = useCallback(
    debounce((value) => {
      filterSearchResult(value);
    }, 300),
    [tagOptions]
  );

  const _handleSearchChange = function (e) {
    let value = e.target.value;
    setQ(value);
    _setSearch(value);
  };

  useEffect(() => {
    setLoading(true);
    const callback = (err) => {
      setLoading(false);
    };
    fetchTags(tag, { callback });
  }, []);

  const _handleChange = (e) => {
    const value = e.target.name;
    onSelectItem && onSelectItem({ subType, value });
  };

  if (loading)
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <CircularProgress />{' '}
      </div>
    );

  const _containerClassName= isMinified ? "fmb-10":"d-flex flex-column overflow-hidden h-100"  
  const _listContainerClassName = isMinified ? "":"flex-grow-1 overflow-auto"
  return (
    <div className={clsx(_containerClassName)}>
      <div>
        {!isMinified && <InputBase
          fullWidth
          className={clsx(`bg-white fpl-10 fmb-25`, classes.inputBase)}
          onChange={_handleSearchChange}
          value={q}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          placeholder={searchPlaceholder}
        />}
        {!!label && <Typography className="fmb-15 font_18_600">{label}</Typography>}
      </div>
      <div className={clsx(_listContainerClassName)}>
        {!!q && !(searchTagOptions || []).length && (
          <div className=" d-flex align-items-center justify-content-center h-100">
            <Typography variant="h2">No results found</Typography>
          </div>
        )}
        <ChipGroups
          handleChange={_handleChange}
          componentState={get(filters, subType, [])}
          fields={q ? searchTagOptions : tagOptions}
        />
      </div>
    </div>
  );
};

export default TagFilter;
