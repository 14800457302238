import React, { useContext, useMemo } from "react";
import { Helmet } from "react-helmet";
import { withSnackbar } from "notistack";
import SplitDetailView from "fitbud/components/splitDetailView";
import UsersList from "./list";
import UserDetails from "./detail";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import DefaultInfo from "fitbud/components/defaultInfo";
import { connect } from "react-redux";
import selectedScheduleRdxFns from "fitbud/redux/selectedSchedule";
import { RoleContext } from "fitbud/providers/roleProvider";
import { Conversion } from "fitbud/providers/conversion";
import activeusrRdxFns from "fitbud/redux/activeUsersList";
import inactiveusrRdxFns from "fitbud/redux/inactiveUsersList";
import chekinusrRdxFns from "fitbud/redux/checkinsList";
import chatusrRdxFns from "fitbud/redux/chatList";
import ptausrRdxFns from "fitbud/redux/plansToAssignList";
import leadsusrRdxFns from "fitbud/redux/leadsList";
import { useAcl } from "fitbud/providers/acl-provider";
import { PlanProvider } from 'fitbud/views/plan/planProvider';

//const ADD_USER_TEXT = "You'll be able to add clients after the client app is made available. Coming really soon :)";
const TITLES = {
  leads: 'Leads',
  plansToAssign: 'Plans To Assign',
  active: 'Active Clients',
  inactive: 'Inactive Clients',
  chats: 'Messages',
  checkins: 'Check-ins',
};


const Users = props => {
  const { clearSelectedSchedule, deleteFns, page, scope="clients", isDrawerMode = false, uid } = props;
  const { authUser, comp } = useContext(FirebaseAuthContext);
  const roleContextValues = useContext(RoleContext);
  const {status, checkLimitedAccess} = useAcl();
  const { convertor } = useContext(Conversion)
  const userId = uid || props.match.params.id;
  const userPage = page || props.match.params.page;
  const fn = isDrawerMode ? null : !!userId ? props.history.replace : props.history.push;
  const onSave = doc => fn(`/${scope}/${userPage}/${doc.id}`);
  const onDelete = uid => {
    if(!isDrawerMode) fn(`/${scope}/${userPage}`)
    if (uid) {
      Object.values(deleteFns).forEach(fn => fn(uid))
    }
  };
  const onSelect = id => {
    if(userId !== id) clearSelectedSchedule && clearSelectedSchedule();
    fn(`/${scope}/${userPage}/${id}`)
  };
  const openChat = id => fn(`/${scope}/chats/${id}`);
  const pop = () => {
    if(!!props.location.state && props.location.state.prevPath === "/"){
      props.history.replace(`/${scope}/leads`)
    }else{
      props.history.goBack()
    }
  };
  const viewProfile = id => fn(`/${scope}/${userPage}/${id}`);
  
  const isLimitedAccess = useMemo(()=>checkLimitedAccess(),[checkLimitedAccess]);

  if(isDrawerMode && !!userId){
    return (
      <UserDetails
        id={userId}
        key={userId}
        onCancel={pop}
        onSave={onSave}
        onDelete={onDelete}
        openChat={openChat}
        staff_id={authUser.uid}
        userPage={userPage}
        authUser={authUser}
        companyDoc={comp}
        convertor={convertor}
        viewProfile={viewProfile}
        {...roleContextValues}
      />
    );
  }
  return (
    <PlanProvider>
      <SplitDetailView
        toolbar={false}
        detail={!!userId}
        className_right=" position-relative  bg-white"
      >
        <div className="h-100 bg-white">
          <Helmet>
            <title>FitBudd » {TITLES[userPage] || 'Clients'}</title>
          </Helmet>
          <UsersList onSelect={onSelect} selected={userId} page={userPage} />
        </div>
        {(!!userId ? (
          <UserDetails
            id={userId}
            key={userId}
            onCancel={pop}
            onSave={onSave}
            onDelete={onDelete}
            openChat={openChat}
            staff_id={authUser.uid}
            userPage={userPage}
            authUser={authUser}
            companyDoc={comp}
            convertor={convertor}
            viewProfile={viewProfile}
            isLimitedAccess={isLimitedAccess}
            {...roleContextValues}
          />
        ) : (
          <DefaultInfo keyName={userPage}/>
        ))}
      </SplitDetailView>
    </PlanProvider>
  );
};

const mapDispatchToProps = d =>{
  let {clear : clearSelectedSchedule} = selectedScheduleRdxFns(d)
  const { delete: activeDel } = activeusrRdxFns(d);
  const { delete: inactiveDel } = inactiveusrRdxFns(d);
  const { delete: chatDel } = chatusrRdxFns(d);
  const { delete: checkinDel } = chekinusrRdxFns(d);
  const { delete: ptaDel } = ptausrRdxFns(d);
  const { delete: leadsDel } = leadsusrRdxFns(d);
  return {
    clearSelectedSchedule,
    deleteFns: {
      activeDel,
      inactiveDel,
      chatDel,
      checkinDel,
      ptaDel,
      leadsDel
    }
  }

}
export default withSnackbar(connect(void 0 , mapDispatchToProps)(Users));
