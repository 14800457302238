import { Card, IconButton, Menu, MenuItem, Typography, Tooltip } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import clsx from 'clsx';
import EditedCardIcon from 'fitbud/icons/editedCardIcon';
import React, {useMemo} from 'react';
import MacrosList from '../meals/macrosList';
import { scheduleCardStyles } from './scheduleWorkoutCard';
import { CopyOverlay } from './subComponents';


const ScheduleMlCard = ({ data, id, itemId, toggleAlternateDialog, minWidth, isPrimary, toggleDrawer,
  editable = true, className, minified, onDelete, onCopy, closeCopyMode, isCopied }) => {
  let { ref_name, is_wo_edited, macros } = data || {};
  const alternateCount = itemId.split('/').length;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const {calories, ...macroData} = useMemo(() => {
    if (!macros) return {calories: 0, protein: 0, fat: 0, carbs: 0};
    const {calories, protein, fat, carbs} = macros;
    return {
      calories: Math.round(calories || 0),
      protein: Math.round(protein || 0),
      fat: Math.round(fat || 0),
      carbs: Math.round(carbs || 0),
    };
  }, [macros]);
  const open = Boolean(anchorEl);

  const handleMoreClick = e => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };
  
  const handleOptionClick = (e, action) => {
    e.preventDefault();
    e.stopPropagation();
    if (action === 'delete') {
      handleClose(e);
      onDelete();
    }
    if (action === 'copy') {
      handleClose(e);
      return onCopy(e);
    }
    if (action === 'add alternate' || action === 'manage alternate') {
      handleClose(e);
      return toggleAlternateDialog();
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation()
    if(alternateCount > 1) toggleAlternateDialog();
    else toggleDrawer();
  };

  const classes = scheduleCardStyles({ id: alternateCount > 1 ? itemId.split('/').shift() : itemId, minified, minWidth, type: 'meal' });
  return (
    <Card
      onClick={handleClick}
      className={clsx(classes.card, classes.typeStyling, 'position-relative shadow-none cursor-pointer', className)}
      id={id} classes={{ root: "border-none" }}>
      <CopyOverlay isCopied={isCopied} type="individualItem" onClose={closeCopyMode} />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{ style: { minWidth: 130, boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.1)", borderRadius: '8px' } }}
      >
        {['copy', 'delete'].map((i, j) => (
          <MenuItem
            key={j}
            onClick={e => handleOptionClick(e, i)}
            className="text-capitalize"
          >
            {i}
          </MenuItem>
        ))}
      </Menu>
      {is_wo_edited && (
        <EditedCardIcon
          style={{
            position: "absolute",
            left: 0,
            top: 0,
          }}
        />
      )}
      <div className='h-100 d-flex w-100 flex-column fpx-10 fpy-10 '>
        <Tooltip title={ref_name}><Typography className='font_13_500' style={{ maxWidth: '90%' }} noWrap>
          {ref_name}
        </Typography></Tooltip>
        <Typography className='font_13_600 mb-15' style={{ maxWidth: '100%' }} noWrap>
          {calories ? `${Math.round(calories)} cals` : '-'}</Typography>
        {!editable && alternateCount > 1 &&
          <Typography className='font_11_500' style={{ maxWidth: '100%' }} noWrap color='textSecondary'>
            {`& + ${alternateCount - 1} More`}
          </Typography>}
        <div className='d-flex flex-1 w-100 align-items-end'>
          <MacrosList data={macroData} fontSize={!editable ? "small" : "normal"} />
          {!!editable && (
            <div className={clsx('position-absolute', classes.options)}>
              <IconButton onClick={handleMoreClick}>
                <MoreVert className='text-dark-grey' classes={{ root: 'small' }} />
              </IconButton>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default ScheduleMlCard;
