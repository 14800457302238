import * as React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const LinkBrokenIcon = props => (
 <SvgIcon
  width="16" height="16" viewBox="0 0 16 16"
  classes={{
   root: "small3"
  }}
  fill="none"
  {...props}
 >
    <g clip-path="url(#clip0_27838_39494)">
    <path d="M12 4.00078H9.56C9.14026 4.00078 8.8 4.34104 8.8 4.76078V4.76078C8.8 5.18052 9.14026 5.52078 9.56 5.52078H12C13.368 5.52078 14.48 6.63278 14.48 8.00078C14.48 9.14478 13.696 10.1048 12.632 10.3848L13.8 11.5528C15.104 10.8888 16 9.56078 16 8.00078C16 5.79278 14.208 4.00078 12 4.00078ZM11.2 8.00078C11.2 7.55895 10.8418 7.20078 10.4 7.20078H9.448L11.048 8.80078V8.80078C11.1319 8.80078 11.2 8.73273 11.2 8.64878V8.00078ZM0.507924 1.30886C0.227405 1.58938 0.227405 2.04419 0.507924 2.32471L2.488 4.30478C1.032 4.89678 0 6.32878 0 8.00078C0 10.2088 1.792 12.0008 4 12.0008H6.44C6.85974 12.0008 7.2 11.6605 7.2 11.2408V11.2408C7.2 10.821 6.85974 10.4808 6.44 10.4808H4C2.632 10.4808 1.52 9.36878 1.52 8.00078C1.52 6.72878 2.488 5.68078 3.728 5.54478L5.384 7.20078V7.20078C5.06147 7.20078 4.8 7.46225 4.8 7.78478V8.00078C4.8 8.44261 5.15817 8.80078 5.6 8.80078H6.984L8.8 10.6168V12.0008H10.184L12.8876 14.6976C13.1663 14.9756 13.6174 14.9753 13.8958 14.697V14.697C14.1743 14.4185 14.1743 13.967 13.8959 13.6884L1.52392 1.30901C1.24343 1.02835 0.788502 1.02828 0.507924 1.30886V1.30886Z" fill="#A2AEBF"/>
    </g>
    <defs>
    <clipPath id="clip0_27838_39494">
    <rect width="16" height="16" rx="0.8" fill="white"/>
    </clipPath>
    </defs>
 </SvgIcon>
);