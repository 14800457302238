import { makeStyles } from '@material-ui/core';

//status completed and missed is same
//other status are upcoming and ongoing

export const useClassesSlotStyles = makeStyles(() => ({
  past: {
    border: '1px solid #84B6FF',
    backgroundColor: '#fff',
  },
  ongoing:{
    border:"1px solid #317FF5",
    backgroundColor : "#E6F0FE"
  },
  upcoming: {
    border: '1px solid #317FF5',
    backgroundColor: '#fff',
  },
  toolTipHeader:{
    // border:"1px solid #317FF5",
    backgroundColor : "#E6F0FE"
  }

}));

export const useAppointmentSlotStyle = makeStyles(() => ({
  past: {
    border: '1px solid #FFC480',
    backgroundColor: '#fff',
  },
  ongoing:{
    border:"1px solid #F59B31",
    backgroundColor : "#FFF5E9"
  },
  upcoming: {
    border: '1px solid #F59B31',
    backgroundColor: '#fff',
  },
  toolTipHeader:{
    // border:"1px solid #F59B31",
    backgroundColor : "#FFF5E9"
  }
}));


export const getColorBySlotStatus2 = (classes, slotStatus) =>{
  if(slotStatus === 'missed' || slotStatus === 'completed') return classes.past;
  if(slotStatus === "upcoming") return classes.upcoming;
  if(slotStatus === "ongoing") return classes.ongoing
}