import { IconButton, LinearProgress, makeStyles, Typography } from '@material-ui/core'
import { ArrowBackIosSharp, ArrowForwardIosSharp } from '@material-ui/icons';
import clsx from 'clsx';
import { bffGetCatalogByIDs } from 'fitbud/api';
import { ClrdButton } from 'fitbud/components/form-fields';
import { DEFAULT_ERROR } from 'fitbud/utils/constants';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useMemo, useState } from 'react'
import { getNavigateRange, getWeekTitle, MAX_WEEK_VIEW } from './helper';
import { getAllIDsToFetch, getDataKey } from './helper';
import ScheduleMlCard from './scheduleMlCard';
import WorkoutCard from './scheduleWorkoutCard';
import { RestDayCard } from './subComponents';

const styles = makeStyles((theme) => ({
  dayContainer: {
    minHeight: '180px',
    minWidth: '184px',
    borderRadius: '3px',
  }
}))
const WoMlScheduleViewer = ({
  doc, fetchedItems, selectedRange, updateSelectedRange, 
  scheduleType, onEditClick, toggleDrawer, cid,
  updateFetchedItems, toggleAlternateDialog,
  isPreview=false, hideMetaInDetail=false
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const weeksToRender = useMemo(() => {
    const { data, duration } = doc || {};
    if(!data) return Array.from(Array(1).keys());
    const _weeksFromDuration = Math.ceil(duration / 7);
    // const _weeks = Object.keys(data);
    // const highestWeek = _weeks.reduce((prev, curr) => {
    //   const val = Number(curr.replace('w',''));
    //   if(val > prev) return prev = val;
    //   return prev;
    // }, 1);
    return Array.from(Array(_weeksFromDuration).keys());
  }, [doc]);
  const weekTitles = _.get(doc, "week_titles", {});
  const totalWeeks = weeksToRender.length;
  const navigate = (direction) => {
    const newRange = getNavigateRange(selectedRange, totalWeeks, direction);
    let allIds = getAllIDsToFetch(doc, scheduleType, [...newRange]);
    allIds = allIds.filter(o => !fetchedItems[o]);
    if(allIds.length > 0){
      const _items = {};
      setLoading(true);
      return bffGetCatalogByIDs({cid, [scheduleType+'s']: [ ...allIds ]})
        .then(({ data: _resData = { } }) => {
          _.get(_resData, scheduleType+'s', []).forEach((itemData) => {
            _items[itemData._id] = { ...itemData };
        });
        updateFetchedItems(prev => ({ ...prev, ..._items }));
        updateSelectedRange([...newRange]);
      })
      .catch((err) => enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' }))
      .finally(() => setLoading(false));
    }
    updateSelectedRange([...newRange]);
  };

  const classes = styles();
  const [start, end] = selectedRange;
  return (
    <div className={clsx(!hideMetaInDetail && 'border-top','overflow-hidden d-flex flex-column flex-1')}>
      <div
        className='d-flex align-items-center justify-content-between border-bottom border-top fpr-20 fpl-12 fpy-8 position-relative'
        style={{ borderWidth: '3px' }}>
        {/* Week Nav */}
        <div className='d-flex align-items-center'>
          <IconButton disabled={isLoading || selectedRange[0] === 1} onClick={(e) => navigate('prev')}>
            <ArrowBackIosSharp classes={{ root: 'small' }} />
          </IconButton>
          <Typography className='font_16_500' >
            Week {' '}
            <span className='font_16_600'>
              {selectedRange[0]} {`- ${(selectedRange[1] >= totalWeeks) ? selectedRange[1] - (selectedRange[1] - totalWeeks) : selectedRange[1]}`}
            </span>
            {' '}  of {totalWeeks}
          </Typography>
          <IconButton disabled={isLoading || (selectedRange[1] >= totalWeeks)} onClick={(e) => navigate('next')}>
            <ArrowForwardIosSharp classes={{ root: 'small' }} />
          </IconButton>
          {isLoading && <LinearProgress style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }} />}
        </div>

        {/* Edit Button */}
        {!isPreview && <ClrdButton color='main' className='f-sm-med shadow-none' onClick={onEditClick}>
          Edit
        </ClrdButton>}
      </div>
      <div className='flex-1 overflow-auto'>
        {/* Weeks Data */}
        {weeksToRender.map((i) => {
          if((i+1) < start || (i+1) > end) return null;;
          const weekKey = `w${i+1}`;
          const weekData = _.get(doc.data, weekKey, false);
          const wTitle = getWeekTitle(doc.wk_show_w_title, i + 1, _.get(weekTitles, `w${i+1}`));
          return (
            <div className='border-bottom border-top' key={weekKey + i}>
               <Typography className="font_18_600 fmy-20 fpl-20">{wTitle.primary}{wTitle.secondary && <span className="font_13_500 text-dark-grey ml-10">{wTitle.secondary}</span> }</Typography>
              <div className='d-flex fpl-20 fpb-20' style={{ overflowX: 'auto' }}>
                {Array.from(Array(7).keys()).map((j,index) => {
                  const dayKey = `d${j+1}`;
                  const idsToRender = _.get(weekData, `${dayKey}.${getDataKey(scheduleType)}`, []);
                  if((((i * 7) + j+1) > doc.duration)) return null;
                  return (
                    <div className={clsx(classes.dayContainer, "d-flex flex-column")} key={weekKey + dayKey + j}>
                      <Typography className={clsx('font_15_600 fmb-15', j > 0 &&  "fpl-12", "fmr-12")}>
                        Day {j + 1}
                      </Typography>
                      <div className={clsx(j < 6 && (((i * 7) + j+1) < doc.duration)  && 'border-right','h-100', !!index?"px-12":"pr-12")}>
                        {idsToRender.length ? idsToRender.map((id, i) => id === 'rest'  ? <RestDayCard minified key={dayKey+id+'view'} />
                          : scheduleType === 'workout' ?
                            <WorkoutCard
                              key={dayKey+id+'view'}
                              className={clsx(i < idsToRender.length - 1 && 'fmb-10')}
                              woData={fetchedItems[id.includes('/') ? id.split('/')[0] : id ]}
                              minified
                              itemId={id}
                              editable={false}
                              id={id}
                              toggleAlternateDialog={(e) => {e.stopPropagation();
                                toggleAlternateDialog({ type: "individualItem", source:`w${i+1}.d${j+1}`, itemId: id, sourceIndex: i, viewOnly: true })}
                                }
                              toggleDrawer={() => toggleDrawer({
                                ..._.get(fetchedItems, id.split('/')[0], {}), displayEditOption: false
                              })}
                            />
                            : <ScheduleMlCard
                                key={dayKey+id+'view'}
                                className={clsx(i < idsToRender.length - 1 && 'fmb-10')}
                                data={fetchedItems[id.includes('/') ? id.split('/')[0] : id ]}
                                minified
                                itemId={id}
                                editable={false}
                                id={id}
                                toggleDrawer={() => toggleDrawer({
                                  ..._.get(fetchedItems, id.split('/')[0], {}), displayEditOption: false
                                })}
                              />
                        ) : (
                          <div key={dayKey+'view'} 
                            className='border rounded d-flex align-items-center justify-content-center fmr-12' 
                            style={{height: '101.5px', minWidth: "160px"}}>
                            <Typography className='font_13_600 text-capitalize'>
                              No {scheduleType} Added
                            </Typography>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  )
};
export default WoMlScheduleViewer;