import React, { useEffect, useContext, useMemo, useState } from 'react';
import {
  List, ListItem, Typography, makeStyles,
  Tooltip, IconButton, Drawer,Button
} from '@material-ui/core';
import { PasteIcon } from 'fitbud/icons/paste2';
import { UserSchContext } from '../provider';
import { MenuSharp } from "@material-ui/icons";
import clsx from 'clsx';
import { ClrdButton } from 'fitbud/components/form-fields';

const styles = makeStyles(theme => ({
  list: {
    backgroundColor: '#fff',
    '& .MuiListItem-root': { height: 70 },
    '& .Mui-selected': { backgroundColor: '#F0F6FE' },
    '& .Mui-selected .MuiTypography-root': { color: theme.palette.primary.main, fontWeight: '600 !important' },
    '& .curr-week-dot': {
      position: 'absolute', top: 30, right: 16,
      backgroundColor: theme.palette.primary.main,
      height: 10, width: 10, borderRadius: 5,
    },
    '& .pasteico': {
      position: 'absolute', top: 17, right: 0,
    },
  },
  drawer:{
    // width:"260px"
  }
}));
const WeekListWrapper=({select=false,...props})=>{
  const classes = styles();
  const [weekExpanded,toggleWeek]=useState(false);
  const expandWeeks=()=>{
    toggleWeek(true);
  }
  const closeDrawer=()=>{
    toggleWeek(false);
  }
  if(select)
    return(
      <>
        <IconButton>
          <MenuSharp onClick={expandWeeks}/>
        </IconButton>
        {weekExpanded && <Drawer onClick={closeDrawer} open variant="temporary" anchor="left" classes={{paper:classes.drawer}}>
          <WeekList {...props}/>
        </Drawer>}
      </>
    )
  return(
    <WeekList {...props}/>
  )
}
const WeekList = ({ select = false, light = false }) => {
  const { opcopy, halt, currWeek, local, weekToday, dayToday, controller } = useContext(UserSchContext);
  const classes = styles();
  const { weeks } = local.plan;
  const l2s = useMemo(() => {
    return weeks.map((x, n) => controller.weekL2(n));
  }, [weeks, controller]);
  const [showPaste, excludeWeek] = useMemo(() => {
    if (!opcopy) return [];
    const { week: srcWeek, day, purpose } = opcopy;
    if (!day && purpose) return [true, srcWeek];
    return [];
	}, [opcopy]);

  const onClick = (e) => {
    const data = e.currentTarget.dataset;
    const toWeek=Number(data.x);
    if(toWeek===currWeek) return;
    controller.gotoWeek(toWeek);
  };
  // const onSelect = (e) => {
  //   controller.gotoWeek(Number(e.target.value));
  // };
  const onPaste = (e) => {
    e.preventDefault();
    e.stopPropagation();
    controller.copyPaste(e);
  };

  useEffect(() => { // we only want scroll to happen initially
    const x = window.document.getElementById('week-' + currWeek);
    if (x) x.scrollIntoView({behavior: 'smooth', block: 'start'});
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <List disablePadding className={` ${classes.list}`}>
      {weeks.map((weekId, n) => {
        const _paste = showPaste && n >= weekToday && n !== excludeWeek;
        const _dot = !_paste && dayToday >= 0 && n === weekToday;
        const selected=n === currWeek;
        return (
          <ListItem id={'week-' + n} button key={weekId} selected={selected} data-x={n}
            onClick={onClick} className='d-flex p-0 justify-content-between align-items-center'>
            <div className='d-flex flex-column align-items-start justify-content-center position-relative pl-20'>
              <Typography className={clsx('mb-5',selected ? "font_15_600" : "font_15_500" )}>{l2s[n]}</Typography>
              <Typography className={clsx( selected ? "text-black font_12_600" : "text-65768C font_12_500")}>Week {n + 1}</Typography>
              {/* {_paste && <Tooltip title={`Paste to Week ${n + 1}`} placement='right'>
                <IconButton className='pasteico' disabled={halt} onClick={onPaste} data-weekid={weekId} data-week={n}>
                  <PasteIcon/></IconButton></Tooltip>} */}
            </div>
            {_dot && <Tooltip title='Current Week'><div className='curr-week-dot'/></Tooltip>}
            {_paste && <Button color='primary' variant='outlined' data-weekid={weekId} data-week={n} disabled={halt} onClick={onPaste} className='mr-16'>Paste</Button>}
          </ListItem>
        );
      })}
    </List>
  );
};

export default WeekListWrapper;
