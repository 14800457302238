import React, { useState, useEffect } from "react";
import update from "immutability-helper";
import _ from "lodash";
import Dialog from "fitbud/components/Dialog";
import SortBy from "./sortBy";
import CategoryList from "./categoryList";
import moment from "moment";

const CategoryReorder = ({
  doc,
  mealCats,
  open,
  title,
  onClose,
  updateParent,
  enqueueSnackbar,
}) => {
  const { sort_by } = doc;
  const [sortValue, setSortValue] = useState(!!sort_by ? sort_by : "custom");
  const [catDoc, setCateDoc] = useState(doc);
  const [timeUpdate, setTimeUpdate] = useState(false);
  const [errors, setErrors] = useState({});
  const [groups, setGroups] = useState(doc.groups);

  const valid = () => {
    let out = true;
    let errors = {};
    const groups = catDoc.groups;
    errors.groups = [];

    if(groups.length === 0) {
      errors.category = `Please add category to complete the meal plan`;
      out = false;
    }

    groups.forEach((cat, i) => {
      errors.groups[i] = {};
      if (!cat.type) {
        errors.groups[i].type = `Please select category for each meal`;
        out = false;
      }
      if (!cat.iftime && sortValue === "time") {
        errors.groups[i].iftime = `Please select date for each meal`;
        out = false;
      }
    })
    setErrors(errors);
    return out;
  }

  if(!!errors.category) {
    const errorMsg = errors.category
    if (!!errorMsg) {
      enqueueSnackbar(errorMsg, { variant: "error", });
    }
  }

  const handleMealChange = index => e => {
    let updatedMeal;
    const key = [e.target.id || e.target.name];
    let value = e.target.value;
    updatedMeal = update(catDoc, {
      groups: {
        [index]: {
          [key]: {
            $set: value
          }
        }
      }
    });
    setCateDoc(updatedMeal)
    setGroups(updatedMeal.groups)
  }

  const handleMealTime = (index, date) => {
    if(!date) return ;
    let time = moment(date).format("HH:mm");
    let key = 'iftime'
    let updatedMeal;
    updatedMeal = update(catDoc, {
      groups: {
        [index]: {
          [key]: {
            $set: time
          },
        }
      }
    });
    setCateDoc(updatedMeal)
    setTimeUpdate(true);
  }

  const addMealCategory = () => {
    let data = {
      calories: 0,
      type: "",
      food_items: [],
      iftime : null
    };
    const newState = update(catDoc, {
      groups: {
        $push: [
          data
        ]
      }
    });
    setCateDoc(newState)
    setErrors({})
  };

  const handleSort = (value) => {
   let updatedMeal = update(catDoc, {
      sort_by : {
        $set: value
      }
    })
    if(value === "time") {
      const sortByTime = _.sortBy(updatedMeal.groups, ['iftime']);
      updatedMeal = update(updatedMeal, {
        groups: {
          $set: sortByTime,
        }
      });
    }
    setSortValue(value);
    setTimeUpdate(false);
    if(updatedMeal)  setCateDoc(updatedMeal)  
    setErrors({})
  }

  useEffect(() => {
    //apply sorting after time changes
    if(!!timeUpdate) {
      handleSort(sortValue)
    }
  },[timeUpdate]);

  const handleChange = (e) => {
    let updatedMeal;
    let key = [e.target.id || e.target.name];
    let value = e.target.value;
    updatedMeal = update(catDoc, {
      [key]: {
        $set: value,
      }
    });
    setCateDoc(updatedMeal)  
    setErrors({})
  }

  const handleDragCate = (params) => {
    let updatedMeal;
    updatedMeal = update(catDoc, {
      groups: {
        $set: params.groups,
      }
    });
    setCateDoc(updatedMeal)
  }

  const onRemove = index => {
    let updatedMeal;
    updatedMeal = update(catDoc, {
      groups: {
        $splice: [[index, 1]]
      }
    });
    setCateDoc(updatedMeal)
    setGroups(updatedMeal.groups)
  };

  const removeTimePicker = (index) => {
    let updatedMeal = update(catDoc, {
      groups: {
        [index]:{
          $merge:{
            iftime:null
          }
        }
      }
    });
    setCateDoc(updatedMeal)
  }

  const onSave = () => {
    if (!valid()) return;
    updateParent(catDoc);
    onClose();
  };
  
  return (
    <Dialog
      toolbarClass="height-60"
      buttonColor="primary"
      open={open}
      title={title}
      onClose={onClose}
      onSave={onSave}
      titleFont="h3"
      paperClass=" width-700 height-60"
    >
      <div className="p-20">
        <div className="mb-10">
          <SortBy
            handleSort={handleSort}
            value={sortValue}
            handleChange={handleChange}
          />
        </div>
        <CategoryList
          handleMealChange={handleMealChange}
          groups={groups}
          errors={errors.groups}
          mealCats={mealCats}
          mealCategories={catDoc.groups}
          handleMealTime={handleMealTime}
          onAddOptions={addMealCategory}
          removeTimePicker={removeTimePicker}
          sortBy={sortValue}
          updateParent={handleDragCate}
          onRemove={onRemove}
          dType="foodMealCategory"
        />
      </div>
    </Dialog>
  );
};

export default CategoryReorder;