import { LinearProgress, makeStyles, Typography } from '@material-ui/core';
import { bffGetRoomToken } from 'fitbud/api/index';
import {
  ClrdButton
} from "fitbud/components/form-fields";
import _ from "lodash";
import * as Sentry from '@sentry/browser';
import { Grid } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import { DEFAULT_ERROR } from 'fitbud/utils/constants';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { useLiveSessions } from "fitbud/providers/liveSessionsProvider";
import DeviceSelection from './deviceSelection';
import LocalAudioLevelIndicator from './localAudioLevelIndicator';
import NetworkDetector from './networkDetector';
import ToggleAudioButton from './toggleAudioButton';
import ToggleVideoButton from './toggleVideoButton';
import useVideoContext from './useVideoContext';
import VideoTrack from './videoTrack';

const useStyles = makeStyles(() => ({
  container: {
    height: '380px',
    width: '660px',
    borderRadius: '10px',
    background: 'black',
    marginTop: '45px!important',
    position: 'relative',
    marginBottom: '45px!important',
    overflow: 'hidden',
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
  },
  identityContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    position: 'absolute',
    right: 0,
    bottom: "25px",
    left: 0,
    zIndex: 1,
  },
  buttonContainer: {
    color: '#ffff',
    border: "1px solid",
  },
  textContainer: {
    marginTop: '30px',
  },
  textAlign: {
    textAlign: 'center',
  },
  videoContainer: {
    objectFit: 'fill',
    display: 'inline-block',
    width: '100%'
  }
}));

const LocalVideoPreview = (props) => {
  const classes = useStyles();
  const { openVideoScreen } = useLiveSessions();
  const { localTracks, connect, getAudioAndVideoTracks, sessionData, loading, room, isDataLoading } = useVideoContext();
  const {name, time, duration, book_id} = sessionData;
  const videoTrack = localTracks.find(track => track.name.includes('camera'))
  const { authUser, cid } = useContext(FirebaseAuthContext);
  const {enqueueSnackbar} = useSnackbar()
  const [step, setStep] = useState(false);
  
  useEffect(() => {
    if(openVideoScreen) {
      getAudioAndVideoTracks().catch(error => {
        console.log(error);
      });
      console.log("MOUNT")
    }
  }, [getAudioAndVideoTracks, step, openVideoScreen]);
  
  // call disconnected internet connection issue
  useEffect(() => {
    if(room.state === "disconnected") {
      enqueueSnackbar("Call has ended due to bad connectivity check your internet connection", {variant:'error'});
    }
  },[room.state])

  const handleJoin = async () => {
    if(props.isDisconnected) {
      enqueueSnackbar("Call could not be started due to bad connectivity, please check your internet connection", {variant:'error'});
      return;
    }

    try {
      const userToken = await authUser.getIdToken();
      const res = await bffGetRoomToken(userToken, cid, book_id, 'trainer')
      const { data } = res;
      if(!data.success){
        enqueueSnackbar(DEFAULT_ERROR, {variant:'error'});
        return;
      }
      connect(data.data);
      setStep(true);
    }catch(err){
      enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
      Sentry.captureException(err);
      setStep(false);
    }
  };

  let userName = _.truncate(name, {
       'length': 25,
     }
  );

  return (
    <div className={classes.textAlign}>
      {(!!loading || isDataLoading) && <LinearProgress /> }
      <div className={`${classes.textContainer}`}>
        <Typography variant="h2" className="fmb-20">
          Session with {userName}
        </Typography>
        <Typography variant="body1">
          {duration} mins  •  {time}
        </Typography>
      </div>
      <div className={`m-auto ${classes.container}`}>
        <div style={{ height: "100%" }}>
          {videoTrack ? (
            <VideoTrack track={videoTrack} isLocal={true} />
          ) : (
              <div className={classes.avatarContainer}>
                <Typography variant="h4">Camera is off</Typography>
              </div>
            )}
        </div>

        <div className={classes.identityContainer}>
          <ToggleAudioButton isLocal={true} />
          <ToggleVideoButton isLocal={true} />
          <LocalAudioLevelIndicator />
          <DeviceSelection isLocal={true} />
        </div>
      </div>
      <ClrdButton
        color='primary'
        classes={{
          root: "f-large"
        }}
        type='submit'
        className={`f-large`}
        variant="contained"
        onClick={handleJoin}
        disabled={loading}
      >
        Join Now
      </ClrdButton>
    </div>
  )
}

export default NetworkDetector(LocalVideoPreview);
