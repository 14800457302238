import React, { useContext, useCallback } from 'react';
import { InputBase, InputAdornment, IconButton, Badge } from '@material-ui/core';
import { ClrdButton } from 'fitbud/components/form-fields';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { SelectorContext, SELECTOR_LIB } from 'fitbud/components/catalogSelector/selectorContext';
import { FilterIcon } from 'fitbud/icons/filterIcon';
import { LineFilterIcon } from 'fitbud/icons/lineFilterIcon';
import { isFilterExists } from 'fitbud/components/catalog/util';
import { getFilterConfig } from '../catalog-filter';


export const useSearchInputStyles = makeStyles(() => ({
  inputBase: {
    borderRadius: '20px',
    height: 40,
    border: '1px solid #D3D9DB',
  },
  myLibBtn: { minWidth: 110, height: 40 },
  fitbuddLibBtn: { minWidth: 144, height: 40 },
}));

const SearchInput = (props) => {
  const classes = useSearchInputStyles();
  const {isFilter = true, hideLibrarySwitch, disableFilter = false} = props;
  const { selectedLib, onSelectLib, onQueryChange, query, openFilter, filterState } = useContext(SelectorContext);
  const isMyLibSelected = selectedLib === SELECTOR_LIB.MY_LIB;
  const isFitbuddLibSelected = selectedLib === SELECTOR_LIB.FITBUDD_LIB;
  const _appliedFilter = isFilterExists(filterState)
  const getButtonStyle = useCallback(
    (isSelected) => {
      return isSelected
        ? { borderRadius: '20px', border: '1px solid #4089f7' }
        : { borderRadius: '20px', border: '1px solid #D3D9DB' };
    },
    []
  );

  return (
    <div className="d-flex">
      <InputBase
        fullWidth
        className={clsx('bg-white fpl-10', classes.inputBase)}
        onChange={onQueryChange}
        value={query}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        placeholder={'Search by name, tags...'}
      />
      <div className=" d-flex fml-15">
        {!hideLibrarySwitch && <>
          <ClrdButton
          className="fmr-10"
          classes={{ root: classes.myLibBtn, label: 'font-weight-500' }}
          style={getButtonStyle(isMyLibSelected)}
          onClick={() => onSelectLib(SELECTOR_LIB.MY_LIB)}
          variant="outlined"
          disableElevation
          color={isMyLibSelected ? 'invert' : 'white-black'}
        >
          My Library
        </ClrdButton>
        <ClrdButton
          classes={{ root: classes.fitbuddLibBtn, label: 'font-weight-500' }}
          style={getButtonStyle(isFitbuddLibSelected)}
          onClick={() => onSelectLib(SELECTOR_LIB.FITBUDD_LIB)}
          variant="outlined"
          disableElevation
          color={isFitbuddLibSelected ? 'invert' : 'white-black'}
        >
          Fitbudd Library
        </ClrdButton>
         </>
       } 
        {/* filter is disabled in case of fitbudd lib :TODO */}
        {isFilter && getFilterConfig(props.keyName) && <IconButton className="fml-15 bg-white" disabled={disableFilter} onClick={openFilter}> 
        {!!_appliedFilter ? (
          <Badge color="primary" badgeContent={_appliedFilter}>
            <FilterIcon />
          </Badge>
          ) :
        <LineFilterIcon disabled={disableFilter} />
        }
      </IconButton> }
      </div>
      
    </div>
  );
};

export default SearchInput;
