import { useCallback, useRef, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { useSnackbar } from 'notistack';
import useVideoContext from './useVideoContext';

const useLocalVideoToggle = () => {
  const {
    room: { localParticipant },
    localTracks,
    getLocalVideoTrack,
    removeLocalVideoTrack,
    getUserRef
  } = useVideoContext();
  const {enqueueSnackbar} = useSnackbar()
  const videoTrack = localTracks.find(track => track.name.includes('camera'));
  
  const [isPublishing, setIspublishing] = useState(false);
  const toggleVideoEnabled = useCallback(() => {
    if (!isPublishing) {
      if (videoTrack) {
        const localTrackPublication = localParticipant && localParticipant.unpublishTrack(videoTrack);
        localParticipant && localParticipant.emit('trackUnpublished', localTrackPublication);
        removeLocalVideoTrack();
      } else {
        setIspublishing(true);
        getLocalVideoTrack()
          .then((track) => {
            const length = localParticipant && localParticipant.tracks.size; //check the tracks size
            if(length > 0) {
              localParticipant && localParticipant.publishTrack(track)
            }
          })
          .catch((err) => {
            console.log(err, 'ERRR')
            if(err) {
              enqueueSnackbar("Camera permission denied.", {
                variant: "error",
              });
              Sentry.captureException(err);
            }
          })
          .finally(() => setIspublishing(false));
      }
    }
  }, [videoTrack, getLocalVideoTrack, isPublishing, removeLocalVideoTrack]);
  return [!!videoTrack, toggleVideoEnabled];
}

export default useLocalVideoToggle;
