import React, { useEffect,useContext,useMemo } from 'react';
import {
  Typography, Tooltip, Button,
  Select, MenuItem, FormControlLabel, RadioGroup, Radio, Divider
} from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import Dialog from 'fitbud/components/Dialog';
import { useToggle, usePicker } from 'fitbud/hooks/form';
import { UserSchContext } from "fitbud/views/schedulev2";
import _ from 'lodash';

const getName=(type)=>type==='ml'? 'meal' : ( type==='sl' ? 'supplement': 'workout')

const CLR_DIS = {color: '#90A4AE'};
const unlinkingMsgFn=(type)=>`Unlinking will remove the linked ${type==="wo"?"workouts":type==="sl"?"supplement":"meal"} from this day onwards.`;

const Pill = ({weekId, week, day, data, l2, controller,children,closeParent,type="ml"}) => {
  const [opts, toggleOpts, setOpts] = useToggle();
  const [masterType, setMasterType] = usePicker(type+'m');
  const {wb, mb} = data;
  const {name, id, s, d, w, dow, len,mode} = data[masterType] || {};
  const [mWeek, setMWeek] = usePicker(w);
  const [mDOW, setMDOW] = usePicker(dow);
  const ml = masterType === 'mlm';
  // const hasBoth = data['wom'] && data['mlm'];
  const overriden = (!ml && Boolean(wb)) || (ml && Boolean(mb));
  const unlinkingMsg=useMemo(()=>unlinkingMsgFn(type),[type])
  useEffect(() => {
    setMWeek(w);
    setMDOW(dow);
  }, [opts, masterType]); // eslint-disable-line react-hooks/exhaustive-deps
  if ((week * 7 + day) < s) return null;
  if (d && (week * 7 + day) >= (s + d)) return null;
  if (7 * (w - 1) + dow > len) return null;
  const mwks = Math.ceil(len / 7);
  const ndslast = len % 7 || 7;
  const restore = () => {
    controller.removeOverride(weekId, week, day, type)
    setOpts(false);
    closeParent();
  };
  const unlink = () => {
    setOpts(false);
    controller.changeMasters(type, week, null, day,false);
    
    setMasterType(curr => curr === 'wom' ? 'mlm' : 'wom');
    closeParent();
  };
  const applyOffset = () => {
    const newMaster = {id, o: 7 * (mWeek - 1) + mDOW - 1}; // NOTE d is being ignored
    if (ml) newMaster.mode = 'per_day';
    controller.changeMasters(type, week, newMaster, day);
    setOpts(false);
    closeParent();
  };
  const handleManage=()=>{
    if(type==="ml"||type==="sl"){
      switch(mode){
        case "per_day":return toggleOpts();
        case "single":
        case "on_off":{
          const _e=_.set({},['currentTarget','dataset'],{
            purpose:type+"ms",
            week:String(week),
            weekid:weekId,
            day:String(day)
          });
          return controller.importClick(_e)
        }
        case "bmr":{
          const _e=_.set({},['currentTarget','dataset'],{
            purpose:'bmr',
            week:String(week),
            weekid:weekId,
            day:String(day)
          });
          return controller.importClick(_e)
        }
      }
    }
    else{
      toggleOpts();
    }
  }
  const unlinkTitle=`Remove Linked ${_.capitalize(getName(type))} schedule`;
  const btnClasses={root:"f-medium",label:"font_15_600"};
  console.log("data[masterType]",data[masterType])
  if(!data[masterType]) return null;
  return (
    <>
      {!!children && children({handleManage,unlink,unlinkingMsg,unlinkTitle})}
    {/* <Tooltip title='Master Schedule. Click to know more'>
      <div className='ml-10 d-flex flex-row align-items-center flex-shrink-1 px-2 py-1 cursor-pointer'
        onClick={toggleOpts} style={overriden ? CLR_DIS: {}}>
        {overriden ? <LinkOffIcon color='inherit'/> : <LinkIcon color='inherit'/>}
      </div>
    </Tooltip> */}
    {opts && <Dialog open onClose={toggleOpts} title='Linked Schedule'>
      <div className='px-20 py-25'>
        {/* {hasBoth && <RadioGroup value={masterType} onChange={setMasterType} className='d-flex flex-row fmtn-5 fmb-15'>
          <FormControlLabel value='wom' control={<Radio color='primary' />} label='Workout' className='my-0 lineht_1_2'  classes={{ label : masterType==="wom" ? "font_16_600" : 'font_16_500'}}/>
          <FormControlLabel value='mlm' control={<Radio color='primary' />} label='Nutrition' className='my-0 lineht_1_2'  classes={{ label : masterType==="mlm" ? "font_16_600" : 'font_16_500'}}/>
        </RadioGroup>
        } */}
        <div className='p-15 bg-offWhite rounded'>
            <div className='d-flex align-items-center' >
              <div className='d-flex flex-column flex-grow-1'>
                <span className='font_18_600 lineht_1_2 fmb-8'>{l2}</span>
                <span className='font_15_500 lineht_1_428 text-dark-grey'>of client’s schedule</span>
              </div>
              <div className='fmx-30'>
                {overriden ? <LinkOffIcon color='inherit' /> : <LinkIcon color='inherit' />}
              </div>
              <div className='d-flex flex-column flex-grow-1 align-items-end'>
                <span className='font_18_600 lineht_1_2 fmb-8'>Week {w} Day {dow}</span>
                <span className='font_15_500 lineht_1_428 text-dark-grey'>of {name}</span>
              </div>
            </div>
        </div>
        <Typography className='font_18_600 pt-25 mb-10 lineht_1_2'>
          {overriden ? 'Restore Link' : `Link a different day of`} { `${getName(type)} schedule`}
        </Typography>
        {!overriden && <Typography className='mb-20 font_15_500 lineht_1_428 text-dark-grey'>
          Select week & day to use for linking client’s schedule from this day onwards.
        </Typography>}
        {!overriden && <div className='d-flex flex-column'>
          <div className='mb-25 d-flex align-items-center'>
          <Select variant='outlined' value={mWeek} className='mr-20' onChange={setMWeek} classes={{root: 'height-40 font_15_500', select: 'select-height-40'}}
            style={{width:"120px"}}>
            {Array(mwks).fill().map((x, i) => <MenuItem key={i} value={i + 1}>Week {i + 1}</MenuItem>)}
          </Select>
          <Select variant='outlined' value={mDOW} className='mr-20' onChange={setMDOW} classes={{root: 'height-40 font_15_500', select: 'select-height-40'}}
            style={{width:"120px"}}>
            {Array(mWeek === mwks ? ndslast : 7).fill().map((x, i) => <MenuItem key={i} value={i + 1}>Day {i + 1}</MenuItem>)}
          </Select>
          <Button color='primary' variant='contained' className='py-15 fpx-20' onClick={applyOffset} classes={btnClasses}>Apply</Button>
          </div>
          <Divider className="position-relative" style={{left:-20,width:'calc(100% + 40px)'}}/>
          <div className='flex-grow-1'>
            <Typography className='font_18_600 pt-25 mb-10 lineht_1_2'>
              {unlinkTitle}
            </Typography>
            <Typography className='font_15_500 lineht_1_428 mb-20 text-dark-grey'>
                {unlinkingMsg}
            </Typography>
            <Button color='primary' className='bg-lightPrimary font_15_600 py-15 fpx-20' onClick={unlink} classes={btnClasses}>Unlink</Button>
          </div>
        </div>}
        {overriden && <Typography className='mb-20 font_15_500 lineht_1_428 text-dark-grey'>
          {`Restoring will remove any modifications to this day and add the ${getName(type)}s from linked schedule to this day.`}
        </Typography>}
        {overriden && <div className='d-flex flex-row'>
          <Button color='primary' variant='contained' className='font_15_700 py-15 fpx-20' onClick={restore} classes={btnClasses}>Restore</Button>
        </div>}
      </div>
    </Dialog>}
    </>
  );
};

const MasterPill = (props) => {
  const {opimport,dayToday,controller,local}=useContext(UserSchContext);
  const {week, day,weekId}=opimport;
  const data=_.get(local,['weeks',weekId,'data','d'+(day+1)],null);
  console.log("Data",data);
  if (!data) return null;
  if(!data.wom && !data.mlm && !data.slm) return null;
  if (week * 7 + day < dayToday) return null;
  return (
    <Pill weekId={weekId} week={week} day={day} data={data} controller={controller} {...props}/>
  );
};

export default MasterPill;
