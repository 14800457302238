import React,{useState,useRef} from 'react';
import {Helmet} from "react-helmet";
import { makeStyles, Link, Typography,TextField,LinearProgress } from '@material-ui/core';
import GTM from "fitbud/utils/gtm";
import { FormTextField } from "fitbud/components/form-fields";
import { useSnackbar } from 'notistack';
import {
    ClrdButton,
    PasswordField,
    isValidEmail,
  } from "fitbud/components/form-fields";
  import {removeSpace} from "fitbud/utils/helpers";
import {bffUpdateHubspot, signup} from "fitbud/api";
import ReCAPTCHA from "react-google-recaptcha";
import {Carousel} from "./carousel";
import moment from 'moment-timezone';
import clsx from 'clsx';
import Logo from "fitbud/components/logo";
import {Copyright} from "fitbud/components/copyright";
import {get} from 'lodash';

const PasswordMinLength=6;

const GTMevent = ({action, category, label}) => {
  const eventDims = {};
  if (label) eventDims.event_label = label;
  if (category) eventDims.event_category = category;
  if (typeof window === 'undefined') return;
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({event: action, ...eventDims});
};

const styles=makeStyles(theme=>({
   container:{
    height:"100vh",
    display:"grid",
    gridTemplateColumns:"1fr 40%",
    '& .grecaptcha-badge':{
      zIndex: 1000,
    },
    '& .logo':{
        height: 60,
        top: 0,
        left: 0,
        width: '60%',
        zIndex: 9,
        paddingLeft: 30,
    },
    '& form':{
        position:"relative",
        left:"50%",
        transform:"translate(-50%)",
        maxWidth:"460px"
    },
    '& .recaptcha-spacing':{
        marginBottom:"60px"
    },
    [theme.breakpoints.down("sm")]:{
        gridTemplateColumns:"1fr",
        '& .logo':{
            width: '100%',
        },
    },
    [theme.breakpoints.down("xs")]:{
        '& .logo':{
            paddingLeft: 20,
        },
        '& .grecaptcha-badge':{
          right: '-250px !important',
          '&:hover': {
            right: '0px !important',
          },
        },
    },
     '& .slick-list':{
        height:"100%",
        '& .slick-track':{
            height:"100%",
            '& .slick-slide':{
                '& div:first-child':{
                    height:"100%"
                }
            }
        }
    },
    '& .slick-dots':{
        position:"absolute",
        left:"40px",
        bottom:"40px",
        width:"unset",
        '& li button:before':{
            color:'#fff',
            opacity:"50%",
            height:"10px",
            width:'10px'
        },
        '& .slick-active button:before':{
            opacity:"100%"
        }
    }
   },
   heading:{
       marginBottom:"30px",
       fontSize:"24px",
       fontWeight:"700",
       [theme.breakpoints.down("sm")]:{
        fontSize:"20px",
       }
   },
   label:{
       color:"#06132D",
       fontSize: "12px!important",
   },
   verifiedSbdmn:{
       color:"green",
       backgroundColor:"#DAFFE2",
       borderRadius:"50%",
       padding: "5px",
       height: "30px",
       width: "30px",
   },
}));
const Signup=()=>{
    const { enqueueSnackbar } = useSnackbar();
    const classes=styles();
    const recaptchaRef=useRef();
    const [state,updateState]=useState({});
    const [errors,updateErrors]=useState({});
    const [loading,toggleLoading]=useState(false);
    const labelProps={
        classes:{
            labelTxt:classes.label
        }
    }
    const onBlur=(e)=>{
        let {name,value}=e.target;
        if (name === 'email') callHubspot(false);
        if (value) {
          GTMevent({
            action: 'sign_up_input',
            category: 'sign_up_form',
            label: name,
          });
        }
    }
    const handleChange=(e)=>{
        let {name,value}=e.target;
        const newVal={[name]:value};
        if(name==="password" || name==="instagram_id"){
            newVal[name]=removeSpace(value);
        }
        if(name==="email"){
            newVal['email']=value.toLowerCase();
        }
        updateState(o=>({
            ...o,
            ...newVal
        }));
    }
    const clientValidation=async()=>{
        const errors={};
        //required fields:
        const {fullName,email,password}=state;
        //fullName
        if(!fullName || (fullName && !fullName.trim())){
            errors['fullName']="Name is missing"
        }
        //email
        if(!email || (email && !email.trim())){
            errors['email']="Email is missing"
        }
        else if(!isValidEmail(email.trim())){
            errors['email']="Please provide a valid email";
        }
        //password
        if(!password || (password && !password.trim())){
            errors['password']="Password is missing";
        }
        else if(state.password.length<PasswordMinLength){
            errors['password']="Please select a stronger password"
        }
        updateErrors(errors);
        return !!Object.keys(errors).length;
        }
    const handleSubmit=async(e)=>{
        GTMevent({ action: 'sign_up_submit', category: 'sign_up_form', label: 'click' });
        e.preventDefault();
        const flag=await clientValidation();
        if (flag) {
            GTMevent({ action: 'sign_up_submit', category: 'sign_up_form', label: 'invalid' });
            return
        };
        try{
        const recaptcha = await recaptchaRef.current.executeAsync();
        const email = state.email.trim();
        const isTest = email.endsWith('@appstreet.io') ||
            email.endsWith('@iappstreet.com') ||
            email.endsWith('@fitbudd.com') ||
            email.endsWith('@yopmail.com');
        const name = state.fullName.trim();
        const payload=({
            email, trainer_name: name,
            company_name: name,
            // instagram_id :state.instagram_id ?state.instagram_id.trim():"",
            password:state.password.trim(),
            time_zone:moment.tz.guess(),
            recaptcha
        });
            toggleLoading(true);
            GTMevent({ action: 'sign_up_submit', category: 'sign_up_form', label: 'start' });
            const resp = await signup(payload);
            GTMevent({ action: 'sign_up_submit', category: 'sign_up_form', label: 'success' });
            const {token, cid}=resp.data;
            await callHubspot(true);
            window.location.href = window.location.origin + `/welcome?ref=${isTest ? 'test_' : ''}${cid}&token=${token}`;
        }
        catch(err){
            const msg = get(err,"response.data.message");
            GTMevent({ action: 'sign_up_submit', category: 'sign_up_form', label: `fail: ${msg}` });
            toggleLoading(false);
            recaptchaRef.current.reset();
            console.log(err);
            enqueueSnackbar(msg, {variant:"error"})
        }
    }
    const callHubspot = (completed = false) => {
        const { fullName, email, instagram_id } = state;
        if(!email || !email.trim() || !isValidEmail(email.trim())) return;
        let hbProps = { email, source_self_signup: true };
        if(!!fullName && !!fullName.trim()) hbProps['name'] = fullName;
        if(!!instagram_id && !!instagram_id.trim()) hbProps['instagram_handle'] = instagram_id;
        if(!!completed) hbProps.signup_complete = true;
        return bffUpdateHubspot({ ...hbProps,  }, true);
    };
    /* removing coz bad XP
    function alertUser(e){
        let currentState={};
        updateState(oldState=>{
            currentState={...oldState};
            return {...oldState}
        })
        if(!!Object.keys(currentState).length){
            e.preventDefault();
            e.returnValue = '';
        }
    }
    useEffect(() => {
         window.addEventListener('beforeunload', alertUser);
     return () => {
        window.removeEventListener('beforeunload', alertUser)
     }
    }, []);*/
    return(
        <div className={`${classes.container}`}>
            <Helmet>
              <title>FitBudd » Sign Up</title>
              <script type="text/javascript" id="hs-script-loader" async defer src="//js-na1.hs-scripts.com/9058640.js"></script>
            </Helmet>
            <GTM/>
            <div className='position-fixed bg-offWhite d-flex align-items-center logo'>
              <a href='https://www.fitbudd.com'><Logo color="fancy"/></a>
            </div>
            {loading && <LinearProgress className="position-absolute w-100" style={{zIndex:9}}/>}
            <div className="position-relative overflow-y-auto overflow-x-hidden fp-20 bg-offWhite">
                <form id="portal-signup" className="text-center d-flex flex-column justify-content-center" onSubmit={handleSubmit}>
                    <Typography className={clsx(classes.heading,"text-left")} style={{marginTop:50}}>Create your trainer account</Typography>
                    <FormTextField fullWidth label="Full Name" required {...labelProps}>
                        <TextField
                            name="fullName"
                            required
                            value={state.fullName}
                            onChange={handleChange}
                            onBlur={onBlur}
                            variant="outlined"
                            error={errors['fullName']}
                            helperText={errors['fullName']}
                            InputProps={{
                            classes: {
                            root: "medium",
                            input: "size_16_500 h-100 bg-white",
                            },
                            }}
                            disabled={loading}
                        />
                    </FormTextField>
                    <FormTextField fullWidth label="Email ID" required {...labelProps}>
                        <TextField
                            name="email"
                            required
                            value={state.email}
                            onChange={handleChange}
                            variant="outlined"
                            error={errors['email']}
                            helperText={errors['email']}
                            InputProps={{
                            classes: {
                            root: "medium",
                            input: "size_16_500 h-100 bg-white",
                            },
                            }}
                            onBlur={onBlur}
                            disabled={loading}
                        />
                    </FormTextField>
                    <FormTextField fullWidth label="Set Password" required {...labelProps}>
                        <PasswordField
                        name="password"
                        required
                        value={state.password}
                        onChange={handleChange}
                        onBlur={onBlur}
                        variant="outlined"
                        error={errors['password']}
                        helperText={errors['password']}
                        disabled={loading}
                        autoComplete='new-password'
                        rootClassName="bg-white"
                        inputClassName="bg-white"
                        />
                    </FormTextField>
                    <Typography variant="h4" className="fmy-20 font-weight-400">
                    By Clicking on create account, you agree to our <BoldLinks to={process.env.REACT_APP_TNC}> Terms </BoldLinks> & <BoldLinks to={process.env.REACT_APP_PRIVACY_POLICY}> Privacy Policy.</BoldLinks>
                    </Typography>
                    <ClrdButton color="main" type="submit" className="f-xxxlarge fmb-20" disabled={loading}
                        classes={{label:"font_16_600 font-weight-700"}}>
                        Create Account
                    </ClrdButton>
                    <Typography variant="h4" className="fmb-20 font-weight-400">*No credit card required. Cancel anytime.</Typography>
                    <Typography variant="body2" className="font-weight-400 recaptcha-spacing d-flex justify-content-center">
                        <span className='fmr-5'>Already have an account?</span> <BoldLinks to="/">Sign In</BoldLinks></Typography>
                    <Copyright className={"fmb-20"}/>
                </form>
                 <ReCAPTCHA
                    size="invisible" badge="bottomright" theme="dark"
                    sitekey={process.env.REACT_APP_RECAPTCHA_CLIENT_KEY}
                    ref={recaptchaRef}
                    />
            </div>
            <Carousel/>
        </div>
    )
}
export const BoldLinks=({children,to="/",target,...rest})=>{
    const props={
        component:"a",
        href:to,
        target:target||to==="/"?"_self":"_blank",
        className:"p-0 w-mn0 text-darkBlue font-weight-600",
        color: "primary",
        ...rest
  }
  return (
    <Link {...props}>{children}</Link>
  )
}

export default Signup;
