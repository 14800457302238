import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const HelpIcon = props => (
    <SvgIcon
        {...props}
        viewBox="0 0 20 20"
        classes={{
          root: "small"
        }}
    >
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 19C14.95 19 19 14.95 19 10C19 5.05 14.95 1 10 1C5.05 1 1 5.05 1 10C1 14.95 5.05 19 10 19Z"
        stroke="#8F969F"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.64919 11.8207C9.64919 9.62185 11.7782 9.31373 11.7782 7.70308C11.7782 6.80672 11.1371 6.19048 10.0484 6.19048C9.06855 6.19048 8.36694 6.58263 7.88306 7.29692L7 6.56863C7.66532 5.57423 8.71774 5 10.1331 5C11.875 5 13 5.93837 13 7.493C13 9.83193 10.8589 9.98599 10.8589 11.8207H9.64919ZM10.2661 15C9.80645 15 9.47984 14.5938 9.47984 14.1036C9.47984 13.5994 9.80645 13.2073 10.2661 13.2073C10.7137 13.2073 11.0403 13.5994 11.0403 14.1036C11.0403 14.5938 10.7137 15 10.2661 15Z"
        fill="#8F969F"
      />
    </svg>
    </SvgIcon>
);
