import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const SettingIcon = props => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 20"
    classes={{
      root: "small"
    }}
  >
    <path d="M2.50016 4.99998H17.5002V9.16665H19.1668V4.99998C19.1668 4.08331 18.4168 3.33331 17.5002 3.33331H2.50016C1.5835 3.33331 0.833496 4.08331 0.833496 4.99998V15C0.833496 15.9166 1.5835 16.6666 2.50016 16.6666H10.0002V15H2.50016V4.99998Z" />
    <path d="M12.5 9.99996L7.5 6.66663V13.3333L12.5 9.99996Z" />
    <path d="M18.9252 15.3583C18.9502 15.1166 18.9585 14.875 18.9335 14.6416L19.8252 13.9333C19.9085 13.8666 19.9252 13.7583 19.8752 13.6666L19.0168 12.175C18.9668 12.0833 18.8585 12.05 18.7585 12.0833L17.6918 12.5C17.5002 12.3583 17.2918 12.2416 17.0668 12.15L16.9002 11.0166C16.8835 10.9083 16.8002 10.8333 16.6918 10.8333H14.9668C14.8668 10.8333 14.7752 10.9083 14.7585 11.0083L14.5918 12.1416C14.3752 12.2333 14.1668 12.3583 13.9752 12.4916L12.9085 12.075C12.8085 12.0333 12.7002 12.075 12.6502 12.1666L11.7918 13.6583C11.7418 13.75 11.7585 13.8583 11.8418 13.925L12.7335 14.6416C12.7085 14.8833 12.7002 15.125 12.7252 15.3583L11.8335 16.0666C11.7502 16.1333 11.7335 16.2416 11.7835 16.3333L12.6418 17.825C12.6918 17.9166 12.8002 17.95 12.9002 17.9166L13.9585 17.5C14.1502 17.6416 14.3585 17.7583 14.5835 17.85L14.7502 18.9833C14.7668 19.0833 14.8502 19.1583 14.9585 19.1583H16.6835C16.7835 19.1583 16.8752 19.0833 16.8918 18.9833L17.0585 17.85C17.2752 17.7583 17.4835 17.6333 17.6752 17.5L18.7418 17.9166C18.8418 17.9583 18.9502 17.9166 19.0002 17.825L19.8585 16.3333C19.9085 16.2416 19.8918 16.1333 19.8085 16.0666L18.9252 15.3583ZM15.8335 16.25C15.1418 16.25 14.5835 15.6916 14.5835 15C14.5835 14.3083 15.1418 13.75 15.8335 13.75C16.5252 13.75 17.0835 14.3083 17.0835 15C17.0835 15.6916 16.5252 16.25 15.8335 16.25Z" />
  </SvgIcon>
);