import React, { useState } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { CopyIcon } from "fitbud/icons/copy2";
import Confirmation from "fitbud/components/confirmationDialog";
const CopyAction = (props) => {
  const [isCopyConfirmation, toggleCopyConfirmation] = useState(false);
  let {
    hide = false,
    onCopy,
    confirmDialogTitle = `Copy ${props.title}`,
    confirmDialogMsg = `Are you sure you want to create a duplicate copy of this ${props.title && props.title.toLowerCase()}?`,
    isDisabled = false,
  } = props;
  if(!!hide) return null;
  return (
    <>
      <Tooltip title={`Make a Copy of the ${props.title && props.title.toLowerCase()}`} placement="start-top">
        <IconButton onClick={() => toggleCopyConfirmation(true)} disabled={isDisabled} className='text-dark-grey'>
          <CopyIcon/>
        </IconButton>
      </Tooltip>
      {isCopyConfirmation && <Confirmation
        open={true}
        handleChange={onCopy}
        handleCancel={() => toggleCopyConfirmation(false)}
        title={confirmDialogTitle}
        msg={confirmDialogMsg}
        confirmOption="Yes, Create"
      />}
    </>
  );
};

export default CopyAction;
