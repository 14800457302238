import React, { useCallback } from 'react';
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import NoteIcon from '@material-ui/icons/Note';
import useVideoContext from './useVideoContext';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    border: "1px solid #E0E0E0",
    width: "50px",
    height: "50px",
    marginRight: "65px"
  },
  iconButton: {
    backgroundColor: "#4089F7!important",
    border: "none"
  }
}));

const ToggleNoteButton = () => {
  const {
    getToggleNote,
    notes
  } = useVideoContext();
  const classes = useStyles();

  const toggleNote = useCallback(() => {
    getToggleNote();
  }, [getToggleNote]);

  return (
    <Tooltip
      title={notes ? 'Turn off note editor' : 'Turn on note editor'}
      placement="top"
      PopperProps={{ disablePortal: true }}
    >
      <IconButton
        className={`${classes.buttonContainer} ${!!notes ? classes.iconButton : ""}`}
        style={{ color: !!notes ? "#fff" : "#221F1F" }}
        onClick={toggleNote}
      >
        <NoteIcon />
      </IconButton>
    </Tooltip>
  )
}

export default ToggleNoteButton;