import _ from "lodash"
import React, { useState, createContext, useEffect, useMemo } from 'react';
import { withSnackbar } from 'notistack';
import { makeStyles,List,Typography, ListItem ,Container,Card} from '@material-ui/core';
import { ClrdButton } from 'fitbud/components/form-fields';
import MealCategoryContainer from './mealCategoryContainer';
// import FoodRecipesSelector from "./foodRecipesSelector";
import FoodRecipesSelector from 'fitbud/components/catalogSelector/foodRecipeSelector';
import CategoryReorder from './categoryreorder';
import clsx from 'clsx';
import PFC, { Calories } from 'fitbud/views/foodRecipes/newUi/pfc';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import ImportMealDialog from "./importMealDialog";
import ImportIcon  from "fitbud/icons/importIcon";
import AddMeal from "./addMeal";
import {ListStyling} from "fitbud/components/catalog";
import { getGroupMacros } from "./helper";

export const EditorContext = createContext({});

const useStyles = makeStyles((theme) => ({
  container:{
    padding: "20px 5vw",
    display:"flex",
    gap:"20px",
    width:"fit-content"
  },
  editorContainer:{
    flex:2.2,
    minWidth:"710px"
  },
  summaryContainer: {
    flex:1,
    position: "sticky",
    top: "20px",
    height: "100%",
    minWidth:"380px"
  },
}));

const FoodItemsForm = (props) => {
  const classes = useStyles();
  const {
    editorMode,
    errors,
    doc,
    handleFoodRecipesAdd,
    storedFoodRecipes,
    loading,
    handleChange,
    handleQuantityChange,
    handleServingChange,
    addMealCategory,
    handleMealType,
    handleMealIfTime,
    handleDelete,
    handleReorder,
    mealCategories,
    serving,
    getServingUnit,
    removeTimePicker,
    updateParent,
    updateParentErrors,
    isImport,
    onSelectImportMeals,
    checkImportedMealGroup,
    handleImportedMealGroups,
    disableItemClick
  } = props;

  const [open, setOpen] = useState(false);
  const [grpIndex, setGrpIndex] = useState(null);
  const [type, setMealType] = useState(doc.type);
  const [itemIndex, setItemIndex] = useState(null);
  const [openCatReorder, setCatReorderDialog] = useState(false);
  const [openImportMealDialog, setImportMealDialog] = useState(false);
  const _calories = (doc && doc.macros && doc.macros.calories) || 0;

  // if (!!errors && errors.category) {
  //   const errorMsg = errors.category;
  //   if (!!errorMsg)
  //     props.enqueueSnackbar(errorMsg, {
  //       variant: 'warning',
  //     });
  // }

  const selectFoodRecipe = (grpIndex, type, index) => {
    setOpen(true);
    setGrpIndex(grpIndex);
    setMealType(type);
    setItemIndex(index);
  };
  const handleClose = () => {
    setOpen(false);
    updateParentErrors();
  };

  useEffect(() => {
    setOpen(false);
  }, [doc]);

  const getGroupFoodItems = (type) =>{
    if (grpIndex === undefined || grpIndex === null) return [];
    let selectedGrp = (doc && doc.groups && doc.groups[grpIndex]) || {};
    const foodItems = selectedGrp.food_items;
    if(!foodItems || !_.isArray(foodItems) || !foodItems.length) return []
    if (type === 'food') {
      const _out = (foodItems || []).map((f) => {
        if (f.type === 'alternative') {
          const alternativeFood = f.alternatives[0];
          const fId = alternativeFood.ref.id;
          const data = storedFoodRecipes && storedFoodRecipes.find((d) => fId === d.id);
          return { _id: fId, data, existsData: { ...f } };
        } else {
          const fId = f.ref.id;
          const data = storedFoodRecipes && storedFoodRecipes.find((d) => fId === d.id);
          return { _id: fId, data, existsData: { ...f } };
        }
      });
      return _out;
    } else if (type === 'alternatives') {
      let _items = [];
      if(!foodItems[itemIndex]) return [];
      const alterNatives = foodItems[itemIndex].alternatives ;
       _items = alterNatives ?  alterNatives : [foodItems[itemIndex]]
      const _out = (_items || []).map((f) => {
        const fId = f.ref.id;
        const data = storedFoodRecipes && storedFoodRecipes.find((d) => fId === d.id);
        return { _id: fId, data, existsData: { ...f } };
      });
      return _out;
    }

    return [];
    
  }

  const buttonProps=!isImport?{}:{
    disableRipple:true,
    button:true
  }

  const groupsMacros = useMemo(() => {
    if(!doc || !doc.groups || doc.type === "macros") return {};
    return doc.groups.map((meal) => {
      if(!doc.groupsMeta || !doc.groupsMeta[meal.type] || _.isEmpty(doc.groupsMeta[meal.type])){
        // Calculate group's macros here as only group.calories is saved in DB but we need to show combined PFC too
        return getGroupMacros(meal, storedFoodRecipes);
      };
      return _.get(doc, `groupsMeta.${meal.type}`, {});
    });
  }, [doc, storedFoodRecipes]);

  return (
    <EditorContext.Provider
      value={{
        editorMode,
        storedFoodRecipes,
        loading,
        errors,
        handleChange,
        handleQuantityChange,
        handleServingChange,
        handleDelete,
        handleReorder,
        serving,
        getServingUnit,
        isImport,
        onSelectImportMeals,
        checkImportedMealGroup,
        disableItemClick,
        meta:doc.meta||{}
      }}
    >
      {editorMode && (
        <>
          <Container 
           maxWidth="lg"
           className={classes.container}
          >
            <div className={classes.editorContainer}>
              {_.get(doc, "groups", []).map((meal, index) => {
                return (
                  <MealCategoryContainer
                    handleMealType={handleMealType}
                    handleMealIfTime={handleMealIfTime}
                    errors={errors && errors.groups && errors.groups[index]}
                    key={`group-${index + 1}`}
                    index={index}
                    type={doc.type}
                    meal={meal}
                    addFoodRecipes={(type, itemIndex) =>
                      selectFoodRecipe(index, type, itemIndex)
                    }
                    handleReorder={handleReorder}
                    mealCategories={mealCategories}
                    removeTimePicker={removeTimePicker}
                    groupsMacros={groupsMacros}
                  />
                );
              })}
              {doc.type === "food" && (
                <AddMeal
                  mealCats={mealCategories}
                  groups={doc.groups}
                  updateParent={updateParent}
                  doc={doc}
                />
              )}
            </div>
            {doc.type === "food" && (
                <div className={clsx(classes.summaryContainer)}>
                  {editorMode && (
                    <Card className={clsx("fpy-25 fpx-20")} style={{ borderRadius: "10px" }}>
                      <Typography className="fmb-10 font_13_600 text-primary">
                        NUTRITION FACTS
                      </Typography>
                      <Calories
                        calories={_calories}
                        textClassName="font_30_700"
                        className="fmb-25"
                        calClassName="text-grey font_16_400"
                      />
                      <PFC macros={doc.macros} />
                    </Card>
                  )}
                  <div>
                    <ClrdButton
                      classes={{
                        root: "f-xxxlarge",
                      }}
                      style={{
                        backgroundColor: "#ffff",
                        border: "1px solid #DDDDDD",
                        borderRadius: "10px",
                      }}
                      variant="outlined"
                      component="div"
                      className="shadow-none fmt-20"
                      onClick={() => {
                        setImportMealDialog(true);
                      }}
                    >
                      <ImportIcon color="primary" />
                      <Typography
                        className="fml-10"
                        variant="subtitle2"
                        color="primary"
                      >
                        Import Meals from other Meal Plans
                      </Typography>
                    </ClrdButton>
                  </div>
                  <ClrdButton
                    classes={{
                      root: "f-xxxlarge",
                    }}
                    style={{
                      backgroundColor: "#ffff",
                      border: "1px solid #DDDDDD",
                      borderRadius: "10px",
                    }}
                    variant="outlined"
                    component="div"
                    className="shadow-none fmt-20"
                    onClick={() => {
                      setCatReorderDialog(true);
                      updateParentErrors();
                    }}
                  >
                    <ImportExportIcon color="primary" fontSize="small" />
                    <Typography
                      className="fml-10"
                      variant="subtitle2"
                      color="primary"
                    >
                      Manage Meal Categories
                    </Typography>
                  </ClrdButton>
                </div>
            )}
          </Container>
          {openCatReorder && (
            <CategoryReorder
              open
              onClose={() => {
                setCatReorderDialog(false);
              }}
              errors={errors.groups}
              mealCats={mealCategories}
              title="Manage Categories"
              doc={doc}
              updateParent={updateParent}
              enqueueSnackbar={props.enqueueSnackbar}
            />
          )}
          {openImportMealDialog && (
            <ImportMealDialog
              open={true}
              handleClose={() => setImportMealDialog(false)}
              onSave={handleImportedMealGroups}
            />
          )}
        </>
      )}
      {!editorMode && (
        <ListStyling>
          <List component={Container} maxWidth="md" className="fp-0 mx-auto">
              {_.get(doc, "groups", []).map((meal, index) => {
                return (
                  <ListItem 
                    {...buttonProps}
                    divider={isImport} 
                    selected={isImport && checkImportedMealGroup(meal)} 
                    className={"fp-0"} 
                    style={{height:"unset"}}
                    >
                      <MealCategoryContainer
                        handleMealType={handleMealType}
                        handleMealIfTime={handleMealIfTime}
                        errors={errors && errors.groups && errors.groups[index]}
                        key={`group-${index + 1}`}
                        index={index}
                        type={doc.type}
                        macros={doc.macros}
                        meal={meal}
                        addFoodRecipes={(type, itemIndex) =>
                          selectFoodRecipe(index, type, itemIndex)
                        }
                        handleReorder={handleReorder}
                        mealCategories={mealCategories}
                        removeTimePicker={removeTimePicker}
                        groups={doc.groups || []}
                        isImport={isImport}
                        groupsMacros={groupsMacros}
                      />
                  </ListItem>
                );
              })}
          </List>
        </ListStyling>
      )}
      <FoodRecipesSelector
        doc={doc}
        handleClose={handleClose}
        open={open}
        handleAdd={(payload) => handleFoodRecipesAdd(payload)}
        grpIndex={grpIndex}
        type={type}
        itemIndex={itemIndex}
        selectedFoodItems={getGroupFoodItems(type)}
        existingItems={type === 'alternatives' ? getGroupFoodItems('food') : []}
      />
    </EditorContext.Provider>
  );
};

export default withSnackbar(FoodItemsForm);
