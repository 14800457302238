import React, { useContext, useEffect, useState } from 'react';
import { TextField, Typography, LinearProgress } from "@material-ui/core";
import { ClrdButton, FormTextField } from "fitbud/components/form-fields";
import { DEFAULT_DEBOUNCE_WAIT, VIMEO_VIDEO_URL_REGEX, YOUTUBE_VIDEO_URL_REGEX, ERROR_VIMEO_URL, ERROR_YOUTUBE_URL, DEFAULT_ERROR } from "fitbud/utils/constants";
import _ from "lodash";
import { uploaderStyles } from './mediaUploader';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { fetchVimeoVideo } from 'fitbud/api';
import appRdx from "fitbud/redux/app";
import {useDispatch} from "react-redux";
import clsx from 'clsx';

function getVimeoId(url) {
  const regex = /(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(?:[^\d]+)?(\d+)/;
  const match = url.match(regex);
  return match ? match[1] : null;
}


export const ConnectVimeo = (props) =>{
  const {text} = props;
  const {cid} = useContext(FirebaseAuthContext);
  return (
    <div className={clsx('info-box mb-20')}>
      <Typography className="font_15_500 lineht_1_5 text-0d0d0d mb-15">
        {text}
      </Typography>
      <ClrdButton component="a" href={`${process.env.REACT_APP_BFF_URL}/vimeo/${process.env.REACT_APP_FB_PROJECT}/${cid}/oauth`} className="f-height-36" variant="contained" color="primary" title="Connect Vimeo" />
    </div>
  );
};


export const LinkUploader = (props) => {
  const classes = uploaderStyles();
  const [error, setError] = useState(false);
  const {cid, comp} = useContext(FirebaseAuthContext);
  const company = !!comp ? comp.data() : {};
  const isVimeoConnected = _.get(company, `features.vimeo`, false);
  const d = useDispatch();
  const {showLoader, hideLoader} = appRdx(d);
  const [vimeoLoading, setVimeoLoading] = useState(false);
  const [vimeoError, setVimeoError] = useState(false);
  const {
    mediaType,
    mediaUrl,
    handleMedia,
    setDirtyOnChange,
    errors = {},
    is_type_specific
  } = props;
  const IS_VIMEO = mediaType === "vimeo";
  const IS_YOUTUBE = mediaType === "youtube";

  useEffect(()=>{
    if (IS_VIMEO && mediaUrl) {
      const getVimeoInfo = async () => {
        setVimeoLoading(true);
        try {
         const response =  await getVimeoApiResponse(mediaUrl);
         //verification flag set to prevent thumbnail fetch on mount
          if (!!response) handleMedia({ type: 'vimeo', verification:true, ...response });
        } catch (err) {
          setVimeoError(DEFAULT_ERROR);
        }
        setVimeoLoading(false);
      };
      getVimeoInfo();
    }
  },[])

  useEffect(()=>{
    setError("");
    setVimeoError("");
  },[mediaType])

  const getVimeoApiResponse = async (vimeoUrl) =>{
    if(!vimeoUrl) return null;
    const vimeoId = getVimeoId(vimeoUrl);
    if(!vimeoId){
      setError(ERROR_VIMEO_URL);
      return null;
    }else if(!!vimeoId){
      try{
       const response = await  fetchVimeoVideo(cid, vimeoId);
       const responseData = response?.data;
       const {success, message, data} = responseData || {};
       if(!success){
        setVimeoError(message);
        return null;
       }
       return data;
      }catch(err){
        setVimeoError(DEFAULT_ERROR);
        return null;
      }
    }
  }

  const debMediaLinkChange = _.debounce(async(v) => {
    setError(false);
    setVimeoError('');
    if (!v) return handleMedia({type:mediaType, url: v});
    if (IS_YOUTUBE) {
      const yt = v.match(YOUTUBE_VIDEO_URL_REGEX);
      if (!!yt) {
        handleMedia({ type: 'youtube', url: `https://www.youtube.com/watch?v=${yt[5]}` });
        return;
      }
      return setError(ERROR_YOUTUBE_URL);
    } else if (IS_VIMEO) {
      const vm = v.match(VIMEO_VIDEO_URL_REGEX);
      if (!!vm) {
        showLoader();
        setVimeoLoading(true);
        const response = await getVimeoApiResponse(v);
        if (!!response) handleMedia({ type: 'vimeo', ...response });
        else handleMedia({ type: 'vimeo', url: `https://vimeo.com/${vm[4]}`, error: true });
        setVimeoLoading(false);
        hideLoader();
        return;
      }
      return setError(ERROR_VIMEO_URL);
    } else {
      return handleMedia({ type: mediaType, url: v });
    }
  }, DEFAULT_DEBOUNCE_WAIT * 2);

  const onMediaLinkChange = (e) => {
    return debMediaLinkChange( e.target.value || "");
  };

  return (
    <>
      {IS_VIMEO && !isVimeoConnected && <ConnectVimeo text={mediaUrl ? "To display this video on client app, connect your Vimeo account. Ensure you have a Vimeo subscription of Standard plan or above." : "To add a Vimeo URL, connect your Vimeo account. Ensure you have a Vimeo subscription of Standard plan or above."} className="" />}
      <FormTextField fullWidth classes={{ control: `${classes.inputBox}` }}>
        <TextField
          key={`${mediaUrl}_media_link_text`}
          id="media_url"
          autoComplete="off"
          defaultValue={String(mediaUrl || "")}
          onChange={(args) => { setDirtyOnChange && setDirtyOnChange(...args); onMediaLinkChange(args) }}
          error={errors.mediaUrl || error}
          helperText={errors.mediaUrl || error}
          placeholder={mediaType ? `${_.capitalize(mediaType)} video link` : "Youtube/Vimeo video link"}
          variant="outlined"
          disabled={!!vimeoLoading}
          required
          type="text"
          InputProps={{
            classes: {
              root: "medium",
              input: "size_16_500 medium",
            },
          }}
        />
        {IS_VIMEO && vimeoLoading &&<LinearProgress />}
        {IS_VIMEO && !!vimeoError && <div className='info-red-box mt-20'><Typography className='font_15_500 text-0d0d0d lineht_1.5'>{vimeoError}</Typography></div>}
      </FormTextField>
    </>
  );
};
