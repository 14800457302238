import * as tus from 'tus-js-client';
import { store } from 'fitbud/redux/store';
import _ from 'lodash';
import * as Sentry from '@sentry/browser';
import { FILE_STATUS } from './constants';
import fileUploadActionBuilder from 'fitbud/redux/fileUpload/actions';
import firebase from 'fitbud/firebase';
import axios from 'axios';
const REMOVE_FILE = 'DELETE_FILE';
const BUNNYCDN_UPLOAD_URL = 'https://video.bunnycdn.com';

/* -----videoMeta structure------
{
 title:"title",  --ref_name
 duration:100   --in seconds
 resolution:"720p"
}
*/
const fileUploadAction = fileUploadActionBuilder();

export const uploadFileToBunnyCDN = async ({
  docId,
  file,
  cid,
  collection,
  media_bgoff,
  videoObject,
  isCompany,
  mediaParentKey = '',
}) => {
  const {
    libraryId,
    videoId,
    useTUS = true, // required stuff
    exp,
    sha, // required for TUS
    accessKey,
    enabledResolutions, // required for Non-TUS
  } = videoObject || {};
  if (!libraryId || !videoId) return FILE_STATUS.error;
  return new Promise((resolve, reject) => {
    if (!file) return reject(FILE_STATUS.error);
    //isCompany means, it is at company level ...
    const firebaseCollection = isCompany ? `companies` : `companies/${cid}/${collection}`;
    if (!!useTUS && !!exp && !!sha && tus.isSupported) {
      const upload = new tus.Upload(file, {
        endpoint: `${BUNNYCDN_UPLOAD_URL}/tusupload`,
        retryDelays: [1000, 5000, 10000],
        headers: {
          AuthorizationSignature: sha,
          AuthorizationExpire: exp,
          VideoId: videoId,
          LibraryId: libraryId,
        },
        metadata: {
          filetype: file.type,
          title: file.name,
        },
        onProgress: _.throttle((bytesUploaded, bytesTotal) => {
          const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
          console.log('browser upload percentage=', percentage + '%');
          store.dispatch({
            type: fileUploadAction.updateFile,
            data: { id: videoId, progress: percentage },
          });
        }, 5000),
        // onChunkComplete:(chunkSize,bytesAccepted,bytesTotal)=>{
        //   const percentage=(bytesAccepted / bytesTotal * 100).toFixed(2);
        //   console.log("server recieve percentage=",percentage+"%");
        // },
        onSuccess: () => {
          const status = FILE_STATUS.processing;
          const payload = createPayload({
            status,
            videoId,
            media_bgoff,
          });
          firebase.firestore().runTransaction(async (t) => {
            const docRef = firebase.firestore().collection(firebaseCollection).doc(docId);
            const data = await (await t.get(docRef)).data();
            const mediaPath = mediaParentKey ? `${mediaParentKey}.` : ''; // parentKye is path  where media found..
            const mediaObj = !media_bgoff
              ? _.get(data, `${mediaPath}media.0`, {})
              : _.get(data, `${mediaPath}media_bgoff`, {});
            //ASK :: what is mediaOff...
            if (mediaObj.videoId === videoId) {
              updateMedia(t, docRef, payload, mediaParentKey);
              resolve(status);
            } else {
              //updated as it is document.
              updateMedia(t, docRef, { ...data });
              resolve(REMOVE_FILE);
            }
          });
        },
        onError: (err) => {
          console.log('TUS Failed because: ' + err);
          const status = FILE_STATUS.error;
          Sentry.captureException(err);
          const payload = createPayload({
            status,
            videoId,
            media_bgoff,
          });
          firebase.firestore().runTransaction(async (t) => {
            const docRef = firebase.firestore().collection(firebaseCollection).doc(docId);
            const data = await (await t.get(docRef)).data();
            const mediaPath = mediaParentKey ? `${mediaParentKey}.` : ''; // parentKye is path  where media found..
            const mediaObj = !media_bgoff
              ? _.get(data, `${mediaPath}media.0`, {})
              : _.get(data, `${mediaPath}media_bgoff`, {});
            if (mediaObj.videoId === videoId) {
              updateMedia(t, docRef, payload, mediaParentKey);
              resolve(status);
            } else {
              updateMedia(t, docRef, { ...data });
              resolve(REMOVE_FILE);
            }
          });
        },
      });
      upload.start();
    } else {
      axios
        .request({
          method: 'PUT',
          url: `${BUNNYCDN_UPLOAD_URL}/library/${libraryId}/videos/${videoId}`,
          headers: {
            accept: 'application/json',
            accessKey,
            'Content-Type': 'application/octet-stream',
          },
          params: {
            enabledResolutions,
          },
          data: file,
        })
        .then(() => {
          const status = FILE_STATUS.processing;
          const payload = createPayload({
            status,
            videoId,
            media_bgoff,
          });
          firebase.firestore().runTransaction(async (t) => {
            const docRef = firebase.firestore().collection(firebaseCollection).doc(docId);
            const data = await (await t.get(docRef)).data();
            const mediaPath = mediaParentKey ? `${mediaParentKey}.` : ''; // parentKye is path  where media found..
            const mediaObj = !media_bgoff
              ? _.get(data, `${mediaPath}media.0`, {})
              : _.get(data, `${mediaPath}media_bgoff`, {});
            if (mediaObj.videoId === videoId) {
              updateMedia(t, docRef, payload, mediaParentKey);
              resolve(status);
            } else {
              //updated as it is document.
              updateMedia(t, docRef, { ...data });
              resolve(REMOVE_FILE);
            }
          });
        })
        .catch((err) => {
          console.log('Normal Upload Failed because: ' + err);
          const status = FILE_STATUS.error;
          Sentry.captureException(err);
          const payload = createPayload({
            status,
            videoId,
            media_bgoff,
          });
          firebase.firestore().runTransaction(async (t) => {
            const docRef = firebase.firestore().collection(firebaseCollection).doc(docId);
            const data = await (await t.get(docRef)).data();
            const mediaPath = mediaParentKey ? `${mediaParentKey}.` : ''; // parentKye is path  where media found..
            const mediaObj = !media_bgoff
              ? _.get(data, `${mediaPath}media.0`, {})
              : _.get(data, `${mediaPath}media_bgoff`, {});
            if (mediaObj.videoId === videoId) {
              updateMedia(t, docRef, payload, mediaParentKey);
              resolve(status);
            } else {
              //updated as it is document.
              updateMedia(t, docRef, { ...data });
              resolve(REMOVE_FILE);
            }
          });
        });
    }
  });
};
const updateMedia = (t, ref, payload, fieldPath) => {
  let out = {};
  if (fieldPath) {
    out[`${fieldPath}`] = {...payload}
  } else {
    out = {
      ...payload,
    };
  }
  t.set(ref, { ...out }, {merge:true});
};

const createPayload = ({ status, media_bgoff, videoId }) => {
  if (media_bgoff) {
    return {
      media_bgoff: {
        type: 'video',
        status,
        videoId,
      },
    };
  }
  return {
    media: [
      {
        type: 'video',
        status,
        videoId,
      },
    ],
  };
};
