import { useState, useCallback } from 'react';
import * as Sentry from '@sentry/browser';

const useAPI = (api) => {
  const [data, setData] = useState({});
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const load = useCallback((args, resolve, reject) => {
    setLoading(true);
    setError(undefined);
    api(...args).then(resp => {
      setData(resolve ? resolve(resp) : resp);
      setLoading(false);
    }).catch(err => {
      setError(err);
      setLoading(false);
      Sentry.captureException(err);
      if (reject) reject(err);
    });
  }, [api]);

  return {data, loading, error, load};
}

export default useAPI;
