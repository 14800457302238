import { makeStyles } from '@material-ui/core';
import React from 'react';
import FRONT_PLACEHOLDER from "fitbud/images/placeholders/checkin_front.svg";
import SIDE_PLACEHOLDER from "fitbud/images/placeholders/checkin_side.svg";

const placeHolderStyle = makeStyles(()=>({
 container:{
   position: "relative",
   height: 0,
   paddingTop:({height}) => height || 210,
   overflowY: "hidden",
   width: "100%",
   backgroundColor:'rgba(0,0,0,0.6)'
 },
 img:{
   width: "100%",
   height: "auto",
   top: 0,
   left: 0,
   objectFit: "contain",
 }
}));
const getImage = (side) => {
 const images = {
  front: FRONT_PLACEHOLDER,
  side: SIDE_PLACEHOLDER,
  back: FRONT_PLACEHOLDER,
 };
 return images[side] || images.front;
};

const CheckInPlaceHolderImg = ({ className, side='front', imageStyles={}, aspect }) => {
  const ht = 100.0 / aspect + "%";
 const classes = placeHolderStyle({ height : ht });
 return (
   <div
    className={`${classes.container} ${className || ""}`}
    style={{
      ...imageStyles,
    }}
   >
     <img
       src={getImage(side)}
       alt="default img"
       className={`${classes.img} position-absolute`}
     />
   </div>
 );
};
export default CheckInPlaceHolderImg;
