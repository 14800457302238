import React from 'react';
import CallHistory from "../callHistory";
import { AppBar, Drawer, IconButton, Toolbar, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const VideoCallsDrawer = ({ open, sessions, loadMore, ...rest }) => {
  return (
    <Drawer anchor="right" open={open} classes={{ paper: 'drawer-420' }} {...rest}>
      <AppBar
        position="sticky"
        color="primary"
      >
        <Toolbar
          classes={{
            root: "height-60"
          }}
          className={
            "d-flex bg-white justify-content-between align-items-center fpx-10"
          }
        >
          <div className='d-flex align-items-center'>
            <IconButton edge="end" color="inherit" onClick={rest.onClose}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h3" className='fml-20'>{"Live Sessions"}</Typography>
          </div>
        </Toolbar>
      </AppBar>
      <CallHistory
        sessions={sessions}
        loadMore={loadMore}
      />
    </Drawer>
  )
}
export default VideoCallsDrawer;
