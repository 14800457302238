import React, { useMemo, useState } from 'react';
import { Tab, Tabs,makeStyles } from '@material-ui/core';
import { DRAFT_STATES } from 'fitbud/utils/constants';
import { ClrdButton } from "fitbud/components/form-fields";
import{PUBLISHED,DRAFT} from "fitbud/utils/constants";
import clsx from 'clsx';
import Confirmation from "fitbud/components/confirmationDialog";

const styles=makeStyles(theme=>({
  content:{
    height: 40, 
    backgroundColor: "#F0F3F5",
    borderBottom:"1px solid #D8DCE0"
  },
  tabsRoot:{
    minHeight:"unset",
    minWidth:"unset"
  },
  scroller:{
    height:"100%"
  },
  indicator:{
    height:'3px',
    backgroundColor:theme.palette.primary.main
  },
  tab:{
    minHeight:"unset"
  },
  tabWrapper:{
    textTransform:"capitalize",
    fontSize:"15px",
    fontWeight:500,
    color:"#000000"
  },
  selected:{
    '& .MuiTab-wrapper':{
      fontWeight:600
    }
  }
}))

export const DraftBanner = ({ doc,showingDoc, toggleDoc, handlePublish, discardDraft,videoStatusRefresh,draftDoc={} }) => {
  const classes = styles();
  const [showConfirmation,toggleConfirmation]=useState(false);
  const { publish_status = "" } = doc;
  const {to_publish=false}=draftDoc||{};
  const isPublishDocAvailable = useMemo(() => {
    return (
      !publish_status ||
      publish_status === DRAFT_STATES["PUBLISHED"] ||
      publish_status === DRAFT_STATES["DRAFT_ASWELL"]
    );
  }, [publish_status]);
  const handleChange=(e,v)=>{
    toggleDoc(v);
  }
  const _discardDraft=()=>toggleConfirmation(true)
  const closeConfirmation=()=>toggleConfirmation(false)
  return (
    <div
      className={clsx("d-flex justify-content-between sticky-top",classes.content)}
    >
      <Tabs
        value={showingDoc}
        style={{ width: "max-content" }}
        className="ml-20"
        classes={{
          root: classes.tabsRoot,
          scroller: classes.scroller,
          indicator: classes.indicator,
        }}
        onChange={handleChange}
      >
        {isPublishDocAvailable && (
          <Tab
            value={PUBLISHED}
            label="Published"
            classes={{
              root: classes.tab,
              wrapper: classes.tabWrapper,
              selected: classes.selected,
            }}
          />
        )}
        <Tab
          value={DRAFT}
          label="Draft"
          classes={{
            root: classes.tab,
            wrapper: classes.tabWrapper,
            selected: classes.selected,
          }}
        />
      </Tabs>
      <div>
        <ClrdButton
          color="primary"
          className="text-nowrap mr-20"
          onClick={_discardDraft}
        >
          Discard Draft
        </ClrdButton>
        {!to_publish && <ClrdButton
          variant="contained"
          color="main"
          disableElevation
          className="h-100 rounded-0"
          onClick={handlePublish}
        >
          Publish
        </ClrdButton>}
        {to_publish && <ClrdButton
          variant="contained"
          color="main"
          disableElevation
          className="h-100 rounded-0"
          onClick={videoStatusRefresh}
        >
          Refresh
        </ClrdButton>}
      </div>
      {showConfirmation && <Confirmation
        open={true}
        handleChange={discardDraft}
        handleCancel={closeConfirmation}
        title="Discard Draft"
        msg="Are you sure you want to discard the Draft?"
      />}
    </div>
  );
};