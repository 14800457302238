import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const ExploreIcon = props => {
  const {stroke, ...rest} = props;
  return (
    <SvgIcon viewBox="0 0 20 20" classes={{root: 'small empty'}} {...rest}>
      <path  stroke={stroke || '#8F969F'} d="M17.5 10.4412C17.5 15.8333 15.8333 17.5 10 17.5C4.16667 17.5 2.5 15.8333 2.5 10C2.5 4.16667 4.16667 2.5 10 2.5C15.8333 2.5 17.5 4.16667 17.5 10.4412Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path  stroke={stroke || '#8F969F'} d="M8.66666 8.59243C9.16669 8.10016 13.3333 6.6665 13.3333 6.6665C13.3333 6.6665 11.9182 11.2498 11.3333 11.7776C10.7485 12.3054 6.66666 13.3332 6.66666 13.3332C6.66666 13.3332 8.16659 9.0847 8.66666 8.59243Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </SvgIcon>
  );
};
