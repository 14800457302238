import React, { useEffect, useRef, useState } from 'react';
import _ from "lodash";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DeviceSelection from './deviceSelection';
import useRoomState from './useRoomState';
import useVideoContext from './useVideoContext';
import { Typography, Grid, Hidden, IconButton } from '@material-ui/core';
import ToggleAudioButton from './toggleAudioButton';
import ToggleVideoButton from './toggleVideoButton';
import EndCallButton from './endCallButton';
import ToggleNoteButton from './toggleNoteButton';
import ToggleViewCarouselButton from './toggleViewCarouselButton';
import moment from "moment";

const useStyles = makeStyles(() => ({
  container: {
    left: '0',
    right: '0',
    bottom: '0',
    height: '72px',
    display: 'flex',
    padding: '0 1.43em',
    zIndex: '10',
    position: 'fixed',
    backgroundColor: '#FFFFFF',
    border: "1px solid rgba(0, 0, 0, 0.1)"
  },
}));

export const Timer = (props) => {
  const { initialMinute = 1, initialSeconds = 0 } = props;
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  const [nagativeTimer, setNegativeTimer] = useState(false);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        if (minutes === 0 || nagativeTimer) {
          let min = nagativeTimer === false ? 0 : minutes + 1;
          setNegativeTimer(true);
          setSeconds(59);
          setMinutes(min);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <div>
      {!!nagativeTimer ?
        <Typography className="fml-5" variant="body1" color="error"> -{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</Typography>
        :
        <Typography className="fml-5" variant="body1" color="textSecondary"> {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</Typography>
      }

    </div>
  )
}

export default function MenuBar() {
  const classes = useStyles();
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';
  const { room, sessionData } = useVideoContext();
  const {name, duration} = sessionData;

  let userName = _.truncate(
    name, {
       'length': 25,
     }
  );

  return (
    <>
      <footer className={classes.container}>
        <Grid container justify="space-around" alignItems="center">
          <Grid style={{ flex: 1 }}>
            <Typography variant="h5" className="fmb-5">
              Live Session with {userName} 
            </Typography>
            <Typography className="d-flex" variant="body1" color="textSecondary">
              Starts In: {moment(sessionData.slot, "hh:mm").format("hh:mm a")}  •  Duration: {duration} mins
            </Typography>
          </Grid>
          <Grid item>
            <Grid container justify="center">
              <ToggleAudioButton disabled={isReconnecting} />
              <EndCallButton />
              <ToggleVideoButton disabled={isReconnecting} />
            </Grid>
          </Grid>
          <Grid style={{ flex: 1 }}>
            <Grid container justify="flex-end">
              <ToggleViewCarouselButton />
              <ToggleNoteButton />
              <DeviceSelection />
            </Grid>
          </Grid>
        </Grid>
      </footer>
    </>
  );
}