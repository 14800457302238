import {cloneDeep, merge} from 'lodash';
const DEFAULT_ACCENT_COLOR = '#6F8099';

export const GROUP_ENUM={
  group:true,
  type: 'grid',
  direction: 'horizontal',
  mergeable: true,
  accent_color: DEFAULT_ACCENT_COLOR,
  draws_accent: true,
  columns: {
    sm: 2.2,
    md: 3.3,
    lg: 4.4,
  },
  kind: 'with_space',
  background: '#ffffff',
  common_item_config: {
    stroke: true,
    image_shape: 'rounded',
    type: 'text_below_image',
    image_aspect: {
      sm: 1,
    },
    alignment: 'left',
  },
  hidden: true,
};
export const circle_common_item_config={
  alignment:'center',
  image_shape:'circle',
  type:'text_below_image'
}
export const CIRCLE_GROUP_ENUM=cloneDeep(
  merge({},GROUP_ENUM,{
    common_item_config:{
      ...circle_common_item_config
    },
    columns:{
      sm:3.5
    }
  })
);
export const PILL_GROUP_ENUM=cloneDeep(
  merge({},GROUP_ENUM,{
    common_item_config:{
      type:'pill'
    }
  }))

const PROGRAMS_WORKOUT = {
  type: 'grid',
  direction: 'horizontal',
  mergeable: true,
  accent_color: DEFAULT_ACCENT_COLOR,
  draws_accent: true,
  columns: {
    sm: 1.08,
    md: 3.3,
    lg: 4.4,
  },
  kind: 'with_space',
  background: '#ffffff',
  common_item_config: {
    stroke: true,
    image_shape: 'rounded',
    type: 'text_over_image',
    image_aspect: {
      sm: 1.5,
    },
    alignment: 'center',
  },
  hidden: true,
};

const MEAL_PLANS_NUTRITION = {
  type: 'grid',
  direction: 'horizontal',
  mergeable: true,
  accent_color: DEFAULT_ACCENT_COLOR,
  draws_accent: true,
  columns: {
    sm: 1.08,
    md: 3.3,
    lg: 4.4,
  },
  kind: 'with_space',
  background: '#ffffff',
  common_item_config: {
    stroke: true,
    image_shape: 'rounded',
    type: 'text_over_image',
    image_aspect: {
      sm: 1.5,
    },
    alignment: 'center',
  },
  hidden: true,
};

const ON_DEMAND_WORKOUT = {
  type: 'grid',
  all: true,
  direction: 'horizontal',
  mergeable: true,
  accent_color: DEFAULT_ACCENT_COLOR,
  draws_accent: true,
  columns: {
    sm: 2.2,
    md: 3.3,
    lg: 4.4,
  },
  kind: 'with_space',
  background: '#ffffff',
  common_item_config: {
    stroke: true,
    image_shape: 'rounded',
    type: 'text_below_image',
    image_aspect: {
      sm: 1,
    },
    alignment: 'left',
  },
  hidden: true,
};
const ON_DEMAND_INSIGHTS = {
  type: 'grid',
  all: true,
  direction: 'horizontal',
  mergeable: true,
  accent_color: DEFAULT_ACCENT_COLOR,
  draws_accent: true,
  hidden: true,
  columns: {
    sm: 2.2,
    md: 3.3,
    lg: 4.4,
  },
  kind: 'with_space',
  background: '#ffffff',
  common_item_config: {
    stroke: true,
    image_shape: 'rounded',
    type: 'text_below_image',
    image_aspect: {
      sm: 0.66,
    },
    alignment: 'left',
  },
};

const ON_DEMAND_NUTRITION = {
  type: 'grid',
  all: true,
  direction: 'horizontal',
  mergeable: true,
  accent_color: DEFAULT_ACCENT_COLOR,
  draws_accent: true,
  columns: {
    sm: 2.2,
    md: 3.3,
    lg: 4.4,
  },
  kind: 'with_space',
  background: '#ffffff',
  common_item_config: {
    stroke: true,
    image_shape: 'rounded',
    type: 'text_below_image',
    image_aspect: {
      sm: 1,
    },
    alignment: 'left',
  },
  hidden: true,
};

const ALL_COLLECTION = {
  type: 'grid',
  direction: 'horizontal',
  mergeable: true,
  accent_color: DEFAULT_ACCENT_COLOR,
  draws_accent: true,
  columns: {
    sm: 2.2,
    md: 3.3,
    lg: 4.4,
  },
  kind: 'with_space',
  background: '#ffffff',
  common_item_config: {
    stroke: true,
    image_shape: 'rounded',
    type: 'text_below_image',
    image_aspect: {
      sm: 1,
    },
    alignment: 'left',
  },
  hidden: true,
};
const NUTRITION_COLLECTION = {
  type: 'grid',
  direction: 'horizontal',
  mergeable: true,
  accent_color: DEFAULT_ACCENT_COLOR,
  draws_accent: true,
  columns: {
    sm: 2.2,
    md: 3.3,
    lg: 4.4,
  },
  kind: 'with_space',
  background: '#ffffff',
  common_item_config: {
    stroke: true,
    image_shape: 'rounded',
    type: 'text_below_image',
    image_aspect: {
      sm: 1,
    },
    alignment: 'left',
  },
  hidden: true,
};
const RESOURCE_COLLECTION = {
  type: 'grid',
  direction: 'horizontal',
  mergeable: true,
  accent_color: DEFAULT_ACCENT_COLOR,
  draws_accent: true,
  columns: {
    sm: 2.2,
    md: 3.3,
    lg: 4.4,
  },
  kind: 'with_space',
  background: '#ffffff',
  common_item_config: {
    stroke: true,
    image_shape: 'rounded',
    type: 'text_below_image',
    image_aspect: {
      sm: 0.66,
    },
    alignment: 'left',
  },
  hidden: true,
};

const WORKOUT_SPACE = {
  accent_color: DEFAULT_ACCENT_COLOR,
  type: 'space',
  draws_accent: true,
  hidden: true,
};
const COLLECTION_SPACE = {
  accent_color: DEFAULT_ACCENT_COLOR,
  type: 'space',
  draws_accent: true,
  hidden: true,
};

//deprecated:
const NUTRITION_SPACE = {
  accent_color: DEFAULT_ACCENT_COLOR,
  type: 'space',
  draws_accent: true,
  hidden: true,
};

export {
  PROGRAMS_WORKOUT,
  MEAL_PLANS_NUTRITION,
  ON_DEMAND_WORKOUT,
  ON_DEMAND_NUTRITION,
  ON_DEMAND_INSIGHTS,
  ALL_COLLECTION,
  RESOURCE_COLLECTION,
  NUTRITION_COLLECTION,
  COLLECTION_SPACE,
  NUTRITION_SPACE,
  WORKOUT_SPACE
};

export const PRESENTATIONS={
  grid:{label:'Photos',value:'text_below_image'},
  pill:{label:'Tags',value:'pill'},
  circle:{label:'Round Photos',value:'circle'},
}

