import React, { useContext, useEffect, useState, useCallback } from 'react'
import _ from "lodash";
import moment from "moment";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import InfiniteScroll from "react-infinite-scroll-component";
import {
    ListItem,
    ListItemText,
    CircularProgress,
    Typography,
    Link
} from "@material-ui/core";
import ImgAspectFill from "fitbud/components/imgAspectHOC";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { getHeightWidthUisngFormula } from "fitbud/utils/helpers";
import Linkify from "linkifyjs/react";
import { AttachmentList } from 'fitbud/views/chats/chatView';
import ScrollToBottom from "fitbud/views/chats/scrollToBottom";
import { pluralize } from "fitbud/utils/helpers";
import { BroadcastMessageViewPlaceholder } from 'fitbud/views/broadcasts/placeholder';



const useStyles = makeStyles(theme => ({
    chatArea: {
        background: "#ffffff",
        flex: 1,
        padding: '0 20px',
        overflow: "scroll",
    },
    bubble: {
        width: "100%",
        whiteSpace: "pre-wrap",
        marginTop: 2
    },
    rightMsg: {
        width: "max-content",
        background: "#F0F3F5",
        borderBottom: 'none',
        maxWidth: "70%",
        wordWrap: "break-word",
        marginLeft: "auto",
        borderRadius: "15px 0 15px  15px"
    },
    mediaRight: {
        padding: 0,
        marginLeft: "auto",
        border: "none"
    },
    chip: {
        backgroundColor: "#F2F4F7",
        margin: '0 0 8px 8px',
        height: 25,
        color: '#65768C'
    },
}));



function BroadcastMessageListView(props) {
    const { messages, fetchMore, lastFetchedMsg, setMessageData, fetching, broadcastGrpId } = props;
    const { cid } = useContext(FirebaseAuthContext);
    const [show, setShow] = useState(false);
    const classes = useStyles()
    const view_width =
        document.getElementById("chatArea") &&
        document.getElementById("chatArea").getBoundingClientRect().width;


    const decideToShowUnread = useCallback(
        _.debounce(() => {
            const chatarea = document.getElementById("chatArea");
            const last_msg = document.getElementById(`broadcastMessagesEnd`);
            if (!chatarea || !last_msg) return;
            if (last_msg.getBoundingClientRect().bottom - chatarea.getBoundingClientRect().bottom <= 100) {
                setShow(false);
            } else {
                setShow((chatarea.getBoundingClientRect().bottom < last_msg.getBoundingClientRect().bottom))
            }
        }, 100),
        [],
    )

    useEffect(() => {
        const chatarea = document.getElementById("chatArea");
        chatarea.addEventListener("scroll", decideToShowUnread);
        return () => {
            chatarea && chatarea.removeEventListener("scroll", decideToShowUnread);
        };

    }, [decideToShowUnread]);

    return (
        <div id="chatArea" className={classes.chatArea}>
            <div className={clsx("mx-auto text-center pt-1 ", lastFetchedMsg && fetching ? 'visible' : 'd-none')}>
                <CircularProgress size={22} />
            </div>
            <InfiniteScroll
                dataLength={messages.length}
                next={fetchMore}
                hasMore={lastFetchedMsg}
                scrollableTarget="chatArea"
                inverse
            >
                {messages &&
                    messages.map((msg, index) => {
                        let msgData = msg.data;
                        let msgPayload = msgData.payload;
                        let height, width;
                        if (msgPayload.media) {
                            const aspect = msgPayload.media.aspect || 1;
                            let { height_new, width_new } = getHeightWidthUisngFormula(
                                view_width,
                                aspect
                            );
                            height = height_new;
                            width = width_new;
                        }

                        return (
                            <div
                                key={msg._id}
                                id={msg._id}
                                className="fmy-40 d-flex flex-column align-items-end text-right ml-auto"
                                style={{ width: '70%' }}
                            >
                                <div className={`${classes.bubble} fmb-10`}>
                                    <ListItem
                                        divider
                                        className={clsx(
                                            (msgPayload.type !== "text" && msgPayload.type !== "attachment") && msgPayload.type !== 'audio'
                                                ? `${classes.mediaRight} d-flex justify-content-end`
                                                : classes.rightMsg
                                        )}
                                        component="div"
                                    >
                                        {msgPayload.type === "text" && <ListItemText>
                                            <Linkify>{msgPayload.body}</Linkify>
                                        </ListItemText>
                                        }
                                        {(msgPayload.type === "attachment" || msgPayload.type === "audio" || msgPayload.type === "video") &&
                                            <AttachmentList id={props.id} attachmentData={msgPayload} key={msgPayload.attachment.identifier} />
                                        }
                                        {
                                            msgPayload.type === "image" && (<div
                                                style={{
                                                    height,
                                                    width
                                                }}
                                            >
                                                {msgPayload.media && (
                                                    <ImgAspectFill
                                                        authorId={cid}
                                                        authUserId={cid}
                                                        targetHeight={height}
                                                        targetWidth={width}
                                                        identifier={msgPayload.media.identifier}
                                                        completed={msgPayload.media.completed}
                                                        aspect={msgPayload.media.aspect || 1}
                                                        imageData={msgPayload.media.imageData}
                                                        storageFolderRef={`companies/${broadcastGrpId}/broadcasts/chats`}
                                                        bucketName="chat"
                                                        style={{ width: "100%", borderRadius: "10px", borderTopRightRadius: 0 }}
                                                        alt={"Broadcast Image"}
                                                        ext={"jpg"}
                                                    />
                                                )}
                                            </div>)
                                        }
                                    </ListItem>
                                </div>
                                <div className="d-flex justify-content-end align-items-center w-100">
                                    {msgData.recipientsV2 && msgData._sent  ? <Link
                                        component="button"
                                        variant="caption"
                                        underline="none"
                                        onClick={() => setMessageData(msgData)}
                                    >
                                        {`${(msgData.recipientsV2 || []).length} Recipient${pluralize((msgData.recipientsV2 || []).length)}`}
                                    </Link> :
                                        <Typography className="font_13_500 text-muted fml-15">
                                            Sending...
                                        </Typography>
                                    }
                                    {
                                        msgPayload.timestamp && <Typography className="font_13_500 text-muted fml-15">
                                            {moment(msgPayload.timestamp.toMillis ? msgPayload.timestamp.toMillis() : msgData.ts).format("DD MMM YYYY • HH:mm")}
                                        </Typography>
                                    }
                                </div>
                            </div>
                        );
                    })}
            </InfiniteScroll>
            {(!messages || !messages.length) && <BroadcastMessageViewPlaceholder />}
            <div id={`broadcastMessagesEnd`} />
            <ScrollToBottom
                show={show}
            />
        </div>
    )
}

export default BroadcastMessageListView
