import _ from "lodash";
import React, { useState,useMemo, useContext, useEffect } from "react";
import {
  Tooltip,
  IconButton,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Chip,
  Divider
} from "@material-ui/core";
import CropIcon from "@material-ui/icons/CropSharp";
import MuteIcon from "@material-ui/icons/VolumeOff";
import DetailEditIcon from "fitbud/icons/detailEdit";
import VerticalStepper from "fitbud/components/verticalStepper";
import { MoreLessTextBtn } from "fitbud/components/moreLessText";
import CopyAction from "fitbud/components/copyAction";
import DeleteAction from "fitbud/components/deleteAction";
import VideoPlayer from "fitbud/components/videoPlayer";
import { exTypeHelper, disabledIconDuringUpload, exCategoryHelper } from "fitbud/utils/helpers";
import { DRAFT, FILE_STATUS, TO_PUBLISH_WARNING_MSG } from "fitbud/utils/constants";
import { RoleContext } from "fitbud/providers/roleProvider";
import {DetailPgStyling} from "fitbud/hooks/useDetailPgStyle";
import {Avatar} from "fitbud/components/avatar";
import {EditorPlaceholder} from "./editorPlaceholder";
import clsx from 'clsx';
import addIcon from "fitbud/images/plus.svg";
import {GetDetailSecLine} from "fitbud/components/catalog/detailSecondLine";
import {MediaPlaceholder} from "fitbud/components/mediaPlaceholder";
import {mapIdToObject} from "fitbud/views/workouts/helperfn";
import {DraftBanner} from "fitbud/components/draftBanner";
import Confirmation from "fitbud/components/confirmationDialog";
import {PublishAlert} from "fitbud/components/publishAlert";
import {sanitizeBodyParts} from 'fitbud/views/workouts/helperfn';
import ExerciseSelector from "fitbud/components/catalogSelector/exerciseSelector";
import {AltExercises} from "./altExercises";
import {videoExists,mediaObjExist} from "fitbud/views/workouts/helperfn";
import { MAIN_VIDEO, TUT_VIDEO } from "./exerciseForm";
export const TUT_VIDEO_CIDS=['barmas','hfggx1nkxm','tasdeekraza321'];
const META="meta";
const EDITOR="editor";

const bps2taz = require("fitbud/views/exercises/bps2taz");

const ExerciseDetail = props => {
  const { catalogAdmin } = useContext(RoleContext);
  const [editAlts,setEditAlts]=useState(false);
  const [isPVideoPause, setPVideoPause ] = useState(false);//primary video
  const [isSVideoPause, setSVideoPause ] = useState(false);//secondary video
  const [showProgressWarning,toggleProgressWarning]=useState(null);//[null,'meta','editor']
  const [stopPublishLoader,toggleStopPublishLoader]=useState(false);
  const {
    id,
    handleEditMode,
    doc,
    muscleGroups,
    targetAreasData, 
    typesData,
    copyItem,
    deleteItem,
    uploadingError={},
    videoStatusRefresh,
    isPreview=false,
    editMode,
    draftBannerProps={},
    openStepEditor,
    draftDoc,
    isDraftAvailable,
    stopPublishPipeline,
    fetchedAlts,
    cid
  } = props;
  const media=_.get(doc,"media.0",{});
  const media_tutorial=_.get(doc,TUT_VIDEO,{});
  //hooks
  useEffect(()=>{
    if(!!editMode) setPVideoPause(true);
  },[editMode])
  const showPrimaryVideo=useMemo(()=>{
    return (!!mediaObjExist(media)||uploadingError[MAIN_VIDEO]);
  },[media,uploadingError]);
  const showSecondaryVid=useMemo(()=>{
    return !!TUT_VIDEO_CIDS.includes(cid) && 
    mediaObjExist(doc.media_tutorial)||uploadingError[TUT_VIDEO];
  },[cid,uploadingError,media_tutorial]);
  const isPVideoSuccessful=useMemo(()=>{
    return (videoExists(media) && media.status!==FILE_STATUS.error && !uploadingError[MAIN_VIDEO]);
  },[media,uploadingError])
  const isSVideoSuccessful=useMemo(()=>{
    return (videoExists(media_tutorial) && media_tutorial.status!==FILE_STATUS.error && !uploadingError[TUT_VIDEO]);
  },[media_tutorial,uploadingError])
  const musGrps = useMemo(()=>{
    return mapIdToObject(muscleGroups,doc.muscle_groups).map(i=>i.label);
  },[muscleGroups,doc.muscle_groups]);
  const targets=useMemo(()=>{
    const arr=[];
    const sanitizedBodyParts=sanitizeBodyParts(doc.body_parts,targetAreasData,bps2taz);
    sanitizedBodyParts.forEach(val => {
      const item = _.find(targetAreasData, d => d.value === val);
      if(!!item){
        arr.push(item.label);
      }
    });
    return arr;
  },[targetAreasData,doc.body_parts])
  //functions
  const handleEdit=(mode)=>{
    if(isDraftAvailable && !!_.get(draftDoc,'to_publish',false)){
      toggleProgressWarning(mode);
      return;
    }
    confirmEdit(mode);
  }
  const confirmEdit=async(mode=showProgressWarning)=>{
    if(isDraftAvailable && _.get(draftDoc,'to_publish',false)){
      toggleStopPublishLoader(true);
      const draftDoc=await stopPublishPipeline();
      toggleStopPublishLoader(false);
      if(!draftDoc) {
        return;
      }
    }
    mode===META?handleEditMode():openStepEditor();
    closeEditPopup();
  }
  const closeEditPopup=()=>{
    toggleProgressWarning(null);
  }
  const handleAddAlts=(v={})=>{
    props.handleAddAlts(v.data||[]);
    setEditAlts(false);
  }

  const stepsExist=_.get(doc,"steps.length",0);
  const alterNatesExists=!!_.get(doc,"alts",[]).length;
  const category=doc.category==="none"?undefined:_.get(typesData.find(i=>i.value===doc.category),"label",undefined);
  const secondLine=GetDetailSecLine('exercises',doc);
  const _description=_.get(doc,'description','').trim();
  const isDraft=draftBannerProps.showingDoc===DRAFT;
  const to_publish=_.get(doc,'to_publish',false);
  return (
    <DetailPgStyling>
      {draftBannerProps.isDraftAvailable && !isPreview && (
        <DraftBanner
          {...draftBannerProps}
          doc={doc}
        />
      )}
      <Card square elevation={0}>
      {to_publish && <PublishAlert to_publish={to_publish}/>}
        <CardHeader
          title={doc.title}
          subheader={secondLine}
          avatar={
            <Avatar
              src={doc.thumbnail}
              name={doc.title}
              size="large"
              alt={doc.title || " "}
            />
          }
          action={
            isPreview ? null : (
              <>
                <IconButton onClick={() => handleEdit(META)}>
                  <DetailEditIcon />
                </IconButton>
                {!isDraft && (
                  <CopyAction
                    title="Exercise"
                    onCopy={copyItem}
                    isDisabled={disabledIconDuringUpload(doc, uploadingError[MAIN_VIDEO]||uploadingError[TUT_VIDEO])}
                  />
                )}
                {catalogAdmin && !isDraft && (
                  <DeleteAction title="Exercise" onDelete={deleteItem} />
                )}
              </>
            )
          }
          classes={{
            root: "main-header-root",
            title: "main-title",
            subheader: !secondLine && "d-none",
          }}
        />
        <CardContent>
          {_description && (
            <MoreLessTextBtn
              text={_description}
              className="desc fmb-25"
              btnClassName="viewMore"
            />
          )}
          <Grid container>
            <Grid item xs={6}>
              <Typography className={clsx("fmb-10 field-title")}>
                Exercise Type
              </Typography>
              <Typography className="field-value">
                {category ? category : "Not specified"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={clsx("fmb-10 field-title")}>
                Tracking Fields
              </Typography>
              <Typography className="field-value">
                {exTypeHelper.typeToText(doc.type)}
              </Typography>
            </Grid>
            <Grid item xs={12} className="fmt-25">
              <Typography
                className={clsx(!musGrps.length && "fmb-10", "field-title")}
              >
                Muscle Groups
              </Typography>
              <Typography className={clsx(!musGrps.length && "field-value")}>
                {musGrps.length
                  ? musGrps.map((i) => (
                      <Chip className="chips fmt-10 fmr-10" key={i} label={i} />
                    ))
                  : "Not Specified"}
              </Typography>
            </Grid>
            <Grid item xs={12} className="fmt-25">
              <Typography
                className={clsx(!targets.length && "fmb-10", "field-title")}
              >
                Target Area
              </Typography>
              <Typography className={clsx(!targets.length && "field-value")}>
                {targets.length
                  ? targets.map((i) => (
                      <Chip className="chips fmt-10 fmr-10" key={i} label={i} />
                    ))
                  : "Not Specified"}
              </Typography>
            </Grid>
          </Grid>
          {/*---- media -----*/}
          {showPrimaryVideo && <div>
            <div className='d-flex align-items-center fmb-10 fmt-25'>
              <Typography className="field-title">
                Video / Gif
              </Typography>
              {doc.is_crop_square && <>
                <span className='mx-8 lineht_0'>•</span>
                <Tooltip title="Media is square-cropped">
                <CropIcon className="font_16_500"/>
              </Tooltip></>}
              {doc.is_mute_video && media.type !== 'image' && <>
                <span className='mx-8 lineht_0'>•</span>
                <Tooltip title="Media is muted">
                <MuteIcon className="font_16_500"/>
              </Tooltip></>}
            </div>
            {isPVideoSuccessful ? (
              <div className={clsx( "media-container",media.type === "image" ? "media-img" : "media-video")}>
              {media.type === "image" ? (
                <img
                  alt=""
                  src={media.url}
                  className={doc.is_crop_square ? "img-square" : "img"}
                />
              ):(
                <VideoPlayer
                  url={media.url || media.s3Url}
                  mediaType={media.type}
                  thumbnailUrl={media.thumbnailUrl}
                  aspectRatio={
                    doc.is_crop_square
                      ? 1
                      : !!parseInt(media.videoHeight) &&
                        !!parseInt(media.videoWidth)
                      ? parseInt(media.videoWidth) / parseInt(media.videoHeight)
                      : 0
                  }
                  muted={_.get(doc, "is_mute_video", false)}
                  pause={isPVideoPause}
                  playerProps={{
                    onPause: () => {
                      setPVideoPause(true);
                    },
                    onPlay: () => {
                      setPVideoPause(false);
                    },
                  }}
                />
              )}
            </div>
            ):(
            <MediaPlaceholder
            media={media}
            uploadingError={uploadingError[MAIN_VIDEO]}
            onRefresh={()=>videoStatusRefresh(MAIN_VIDEO)}
            />
          )}
          </div>}
          {/*---- media tutorial -----*/}
          {showSecondaryVid && <div className='fmt-25 d-flex flex-column'>
            <Typography className="field-title mb-10">
              Tutorial Video 
            </Typography>
            {isSVideoSuccessful ? (
            <div className={clsx( "media-container media-video")}>
              <VideoPlayer
                  url={media_tutorial.url || media_tutorial.s3Url}
                  mediaType={media_tutorial.type}
                  thumbnailUrl={media_tutorial.thumbnailUrl}
                  aspectRatio={
                    doc.is_crop_square
                      ? 1
                      : !!parseInt(media_tutorial.videoHeight) &&
                        !!parseInt(media_tutorial.videoWidth)
                      ? parseInt(media_tutorial.videoWidth) / parseInt(media_tutorial.videoHeight)
                      : 0
                  }
                  pause={isSVideoPause}
                  playerProps={{
                    onPause: () => {
                      setSVideoPause(true);
                    },
                    onPlay: () => {
                      setSVideoPause(false);
                    },
                  }}
                />
            </div>
            ):(
              <MediaPlaceholder
                media={media_tutorial}
                uploadingError={uploadingError[TUT_VIDEO]}
                onRefresh={()=>videoStatusRefresh(TUT_VIDEO)}
            />
            )}
          </div>}     
          {/*---- media end -----*/}
        </CardContent>
      </Card>
      {(!isPreview || (isPreview && !!stepsExist)) && (
        <>
          <Divider className="dense" />
          <Card square elevation={0}>
            <CardHeader
              title={"How to Perform"}
              action={
                isPreview ? null : (
                  <IconButton onClick={() => handleEdit(EDITOR)}>
                    {stepsExist ? (
                      <DetailEditIcon />
                    ) : (
                      <img src={addIcon} alt="" />
                    )}
                  </IconButton>
                )
              }
              classes={{
                root: "sub-header-root",
                title: "sub-title",
                action: "margin-top-negative",
              }}
            />
            <CardContent>
              {stepsExist ? (
                <VerticalStepper steps={doc.steps || []} className="desc" />
              ) : (
                <EditorPlaceholder
                  keyName="exercise-step-placeholder"
                />
              )}
            </CardContent>
          </Card>
        </>
      )}
      {showProgressWarning && (
        <Confirmation
          open
          handleClose={closeEditPopup}
          handleChange={()=>confirmEdit()}//intentional syntax(check default param in confirmEdit)
          handleCancel={closeEditPopup}
          handle
          title="Warning"
          msg={TO_PUBLISH_WARNING_MSG}
          confirmOption="Yes, Continue"
          loading={stopPublishLoader}
        />
      )}
      {(!isPreview || (isPreview && !!alterNatesExists)) && <>
          <Divider className="dense" />
          <Card square elevation={0}>
            <CardHeader
              title="Alternate Exercises"
              action={
                isPreview?null:alterNatesExists?(
                  <IconButton onClick={() => setEditAlts(true)}>
                    {<DetailEditIcon />}
                  </IconButton>
                ):(
                  <IconButton onClick={() => setEditAlts(true)}>
                    <img src={addIcon} alt="" />
                  </IconButton>
                )
              }
              classes={{
                root: clsx("sub-header-root",!alterNatesExists && "mb-0"),
                title: "sub-title",
                action: "margin-top-negative",
              }}
            />
            <CardContent>
              {alterNatesExists?(
                <AltExercises alts={doc.alts||[]} lib={fetchedAlts} isPreview={isPreview}/>
              ):(
                <EditorPlaceholder
                  keyName="exercise-alternate-placeholder"
                />
              )}
            </CardContent>
          </Card>
        </>}
      {
        editAlts && (
          <ExerciseSelector
            title="Manage Alternates"
            open
            handleClose={()=>setEditAlts(false)}
            keyName="exercises"
            handleAdd={handleAddAlts}
            selectedExercise={[
              ...Object.values(fetchedAlts)
            ]}
            insideGrp={true}
            primary={{_id:id,data:{...doc }}}
          />
        )
      }
    </DetailPgStyling>
  );
};

export default ExerciseDetail;
