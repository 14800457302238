import React, { useContext, useMemo } from 'react';
import { Typography } from '@material-ui/core';
import { formatNo } from 'fitbud/utils/helpers';
import { useToggle } from 'fitbud/hooks/form';
import { UserSchContext } from '../provider';
import { CSSS } from 'fitbud/views/schedulev2/editor/cards';
import Editor from './editors';
import { merge } from "lodash";

const BMRCard = ({ className, future, data }) => {
  const { bmrlocal, opcopy } = useContext(UserSchContext);
  const disabled = (Boolean(opcopy) || !future);
  const [edit, toggleEdit] = useToggle();
  const style=useMemo(()=>{
    const css=CSSS['ml']['on'];
    if(!future) return merge({}, css, CSSS.past);
    return css;
   },[disabled]);
  const [l1, l2, ready] = useMemo(() => {
   if(!!future){
    if (!bmrlocal) return ['', {},false];
    let l1='',l2='';
    const { p, f, c, meal } = bmrlocal;
    l2={
      protein:p,
      fat:f,
      carbs:c
    }
    if (!meal) return ['Click to edit',l2,false];
    const {macros} = meal;
    if (macros && macros.calories)
      l1 = formatNo(Math.round(macros.calories), {}, '');
    return [l1, l2, true];
   }
   else{
    if (!data) return ['', ''];
    let l1='',l2 = '';
    const {macros} = data;
    if(macros && macros.calories)
      l1 = formatNo(Math.round(macros.calories||0), {}, '0');
    l2={
      protein:Math.round(macros.protein),
      fat:Math.round(macros.fat),
      carbs:Math.round(macros.carbs)
    };
    return [l1, l2,true];
   }
  }, [bmrlocal,future,data]);
  if (!l1) return null;
  return (
    <>
      <div className={`d-flex flex-column justify-content-between px-15 py-10 cursor-pointer ${className || ''}`} style={style}  onClick={!!future && toggleEdit}>
        <div className='d-flex justify-content-between mr-20'>
          <Typography className='font_13_600'>P {l2.protein}<span className='font-weight-500'>%</span></Typography>
          <Typography className='font_13_600'>F {l2.fat}<span className='font-weight-500'>%</span></Typography>
          <Typography className='font_13_600'>C {l2.carbs}<span className='font-weight-500'>%</span></Typography>
        </div>
        <div className='flex-1 d-flex align-items-end justify-content-between'>
          <div>
            <span className="font_12_600">{l1}{" "}</span>
            <span className='font_12_500'>{ready ? 'cals': ''}</span>
          </div>
          <Typography className='mr-15'>
            <span className='font_10_600' color='inherit'>{"BMR"}</span>
          </Typography>
        </div>
        {/* <div className="d-flex">
          <Typography className='flex-1'>
            <span className="font_13_600">{l1}{" "}</span>
            <span className='font_13_500'>{ready ? 'cals': ''}</span>
          </Typography>
          <Typography>
            <span className='font_10_600' color='inherit'>{"BMR"}</span>
          </Typography>
        </div> */}
        {/* <div className='d-flex flex-row align-items-center text-dark-grey'>
        <div className='flex-1'>
          <Typography className="font_10_500 text-grey">Protien</Typography>
          <Typography className='font_12_600'>{l2.protein||"-"}</Typography>
        </div>
        <div className='flex-1'>
          <Typography className="font_10_500 text-grey">Fat</Typography>
          <Typography className='font_12_600'>{l2.fat||"-"}</Typography>
        </div>
        <div  className='flex-1'>
          <Typography className="font_10_500 text-grey">Carbs</Typography>
          <Typography className='font_12_600'>{l2.carbs||"-"}</Typography>
        </div>
        </div> */}
      </div>
      {edit && <Editor onClose={toggleEdit}/>}
    </>
  );
};


export default BMRCard;
