import React, { useMemo } from 'react';
import _ from "lodash";
import clsx from 'clsx';
import { Chip, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import { getChipLabelPrefixSuffix, isFilterEmpty } from "fitbud/utils/helpers";
import { getQuickFilters } from "fitbud/utils/constants";
import { UserStateIndicator } from "fitbud/views/users/list";
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        margin: 0,
        position: "relative",
        // padding: '0px 20px 7px 20px'//adds extra padding to user list- Not Required
    },
    chip: {
        margin: '0 4px 8px 0px',
        height: 28,
        backgroundColor: "#FFF",
        color: '#37404D',
        border: '1px solid #D3D9DB'
    },
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        maxWidth: 200,
        backgroundColor: theme.palette.common.black,
    },
    selectedChip: {
        background: '#FFF',
        border: '1px solid #317FF5',
    },
    selectedClickable: {
        cursor: 'pointer',
        color: '#317FF5',
        border: '1px solid #317FF5',
        '&:focus , &:hover': {
            backgroundColor: "#FFF",
        }
       
    },
    unselectedClickable: {
        cursor: 'pointer',
        '&:focus, &:hover': {
            backgroundColor: "#FFF",
            color: '#37404D'
        }
    },
}));


 const QuickFilters=React.memo((props)=> {
    const { keyName, filter, setFilter, extraProps = {},  overrides = {} } = props;
    const classes = useStyles();
    const planRedux = useSelector(s => s.plans);
    const qfMap = useMemo(() => (_.cloneDeep(getQuickFilters(keyName)) || []).reduce((obj, item) => ({ ...obj, [item.key]: item.label, [item.label]: item.key }), {}), [keyName]);
    const selectedChips = useMemo(()=>{
        if (isFilterEmpty(filter)) return [];
        return _.flattenDeep(Object.keys(filter).map(f => {
            const value = filter[f];
            if(f === 'plan'){
                // load plan name from redux as filter values will be [ids]
                const planMap = planRedux.docs.reduce((prev, curr) => {
                    prev[curr._id] = curr.data.ref_name;
                    return prev;
                }, {});
                planMap['custom'] = 'Custom';
                return value.map(v => ({ key: f, label: getChipLabelPrefixSuffix(f, v, {...extraProps, planMap }), value: v }));
            }
            if (_.isArray(value)) {
                return value.map(v => ({ key: f, label: getChipLabelPrefixSuffix(f, v, extraProps), value: v }))
            }
            if (_.isObject(value)) {
                if (f === "quick_filters") {
                    return Object.keys(value).map(v => ({ key: v, label: qfMap[v], value: !!value[v] })).filter(c => c.value)
                }
                else if(f==='challenge_filters'){
                    const values=_.get(filter,'challenge_filters.status',[]);
                    return values.map(i=>({
                        key:i,
                        label:i,
                        value:i
                    }))
                }
                return { key: f, label: getChipLabelPrefixSuffix(f, Object.values(value).join("-"), extraProps) }
            }
            return { key: f, label: value, value: value }
        }))
    },[filter,extraProps]);
    const chips = useMemo(()=>{
        const chips = _.cloneDeep(getQuickFilters(keyName, true));
        const newChips = []
        selectedChips.forEach(sc => {
            const index = _.findIndex(chips, c => (c.key === sc.key || c.key === sc.value))
            if (index >= 0) {
                chips[index].value = true
                chips[index].selected = true
            }
            else newChips.push(sc)
        })
        return chips.concat(newChips)
    },[keyName,filter,selectedChips]);
    const handleChipClick = (e, chip) => {
        e.stopPropagation();
        if (chip.selected || _.find(selectedChips, c => _.isEqual(c, _.omit(chip, ['helperText', 'selected'])))) {
            chip.selected = false
            handleChipRemove(chip, true)
        } else {
            if (_.isBoolean(chip.value)) {
                chip.value = true
                chip.selected = true
            }
            handleChipSelect(chip, true)
        }
    }
    const handleChipRemove = (chip) => {
        let { key, value, filterKey } = chip;
        let fvalues = _.get(filter,filterKey) || filter[key]
        if (filterKey === 'multi_list_filters'||filterKey==='challenge_filters.status') {
            const newValues=_.filter(fvalues, fv => fv !== key);
            const newFilters=_.set({},filterKey,[...newValues]);
            setFilter(s=>({...s,...newFilters}))
        } else {
            if (_.isArray(fvalues)) {
                value = _.filter(fvalues, fv => fv !== value)
                setFilter(s => ({ ...s, [key]: value }))
            } else {
                key = qfMap[key] ? `quick_filters.${key}` : key
                setFilter(s => (_.omit(s, [key])))
            }
        }
    }
   
    const handleChipSelect = (chip) => {
        let { key, value, filterKey } = chip;
        let fvalues =  _.get(filter,filterKey) || filter[key] || []
        if (_.isArray(fvalues)) {
            value = [...fvalues, value]
        }
        if(filterKey === 'multi_list_filters'||filterKey==='challenge_filters.status'){
            const newValues=[...fvalues,key];
            const newFilters=_.set({},filterKey,[...newValues]);
            setFilter(s=>({...s,...newFilters}))
        }
        else {
            if (qfMap[key]) {
                setFilter(s => ({ ...s, quick_filters: { ...(s.quick_filters || {}), [key]: true } }))
            } else {
                setFilter(s => ({ ...s, [key]: value }))
            }
        }
        
    }
    return (
        <div className={clsx(classes.container, overrides.container)} >
            <Collapse in className="w-100" >
                <div>{
                    chips.map((chip, idx) => {
                        const selected = chip.selected || _.find(selectedChips, c => _.isEqual(c, _.omit(chip, ['helperText', 'selected'])))
                        const label = chip.key !== 'multi_list_filters' ? chip.label : <><UserStateIndicator showTooltip state={chip.value.split('.')[0]}/> <span className='fml-5'>{chip.label}</span></>
                        return <Tooltip arrow title={chip.helperText || ''} key={chip.key + chip.label}
                            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}>
                            <Chip
                                size="medium"
                                label={label}
                                clickable
                                onClick={(e) => handleChipClick(e, chip)}
                                className="rounded-pill"
                                classes={{
                                    root: clsx(classes.chip, overrides.chip, selected && `${classes.selectedChip} ${overrides.selectedChip}`),
                                    label: clsx('font_13_500'),
                                    clickable: selected ? `${classes.selectedClickable} ${overrides.selectedClickable}` : `${classes.unselectedClickable} ${overrides.unselectedClickable}`
                                }} />
                        </Tooltip>;
                    })
                }</div>
            </Collapse>
        </div>
    );
});

export default QuickFilters;