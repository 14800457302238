import { TextField } from '@material-ui/core';
import Dialog from 'fitbud/components/Dialog'
import React, { useState } from 'react'
import firebase from 'firebase';
import { useSnackbar } from 'notistack';
import Confirmation from 'fitbud/components/confirmationDialog';
import moment from 'moment';

const NoteDialog = (props) => {
  const { open, onClose, userId, loader } = props;
  const [value, setValue] = useState(open.mode === 'edit' ? open.selected.text : '');
  const [isDirty, setDirty] = useState(false);
  const [openPromt, setPromt] = useState(false);
  const [error, setError] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  
  const handleClose = () => {
    if(isDirty) {
      return setPromt(true);
    };
    onClose(false);
  };

  const handleChange = (e) => {
    setError(false);
    setDirty(true);
    const { value: _v } = e.target;
    setValue(_v);
  };

  const handleSave = () => {
    if(!value.trim()) return setError(true);
    if (open.mode === 'edit') {
      const { id, text } = open.selected;
      if (text === value.trim()) return enqueueSnackbar('No changes to save', { variant: 'warning' });
      const docRef = firebase.firestore().doc(`user_profiles/${userId}/notes/${id}`);
      loader.showLoader();
      docRef.update({ text: value.trim() }).then(() => {
        loader.hideLoader();
        onClose('refresh');
      });
      return;
    };
    // New note saving
    const collRef = firebase.firestore().collection(`user_profiles/${userId}/notes`);
    loader.showLoader();
    collRef.add({ text: value, ts: moment().toDate() }).then(() => {
      loader.hideLoader();
      onClose('refresh');
    });
    return;
  };
  const closePromt = () => {
    setPromt(false);
  };
  return (
    <>
      <Confirmation
        open={openPromt}
        handleCancel={closePromt}
        handleChange={onClose}
        title='Warning'
      />
      <Dialog
        open={!!open}
        title={open.mode === 'edit' ? "Edit note" : 'Add a note'}
        onClose={handleClose}
        toolbarClass="height-60"
        onSave={handleSave}
        maxWidth='xs'
        fullWidth
      >
        <div className='fp-20'>
          <TextField
            error={error}
            helperText={!!error && "Please enter some text"}
            className='w-100'
            variant='outlined'
            multiline rowsMax={8} rows={3}
            InputProps={{
              classes:{root: 'fp-10'}
            }}
            value={value}
            placeholder={'Please write the client note here..'}
            onChange={handleChange} />
        </div>
      </Dialog>
    </>
  )
};

export default NoteDialog;
