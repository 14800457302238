import { useCallback, useEffect, useState } from 'react';
import Video from 'twilio-video';
import firebase from "fitbud/firebase";
import { DEFAULT_VIDEO_CONSTRAINTS, SELECTED_VIDEO_INPUT_KEY } from 'fitbud/utils/constants';
import { getDeviceInfo } from './getDeviceInfo';
import { useSnackbar } from "notistack";

export function useDevices() {
  const [devices, setDevices] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if(!navigator) return;
    const getDevices = () => !!navigator && !!navigator.mediaDevices && 
      navigator.mediaDevices.enumerateDevices().then(devices => setDevices(devices));
    if (!!navigator && !!navigator.mediaDevices) {
      navigator.mediaDevices.addEventListener('devicechange', getDevices);
    }else{
      enqueueSnackbar("Media device not supported", { variant: "error" });
    }
    getDevices();
    return () => {
      if(!!navigator && !!navigator.mediaDevices) 
        navigator.mediaDevices.removeEventListener('devicechange', getDevices);
    };
  }, []);

  return devices;
}

export function useVideoInputDevices() {
  const devices = useDevices();
  return devices.filter(device => device.kind === 'videoinput');
}

const useLocalTracks = () => {
  const [audioTrack, setAudioTrack] = useState();
  const [videoTrack, setVideoTrack] = useState();
  const [notes, setNote] = useState(false);
  const [isViewEnabled, setViewEnabled] = useState(false);

  const localVideoDevices = useVideoInputDevices();
  const hasVideo = localVideoDevices.length > 0;

  const getLocalAudioTrack = useCallback(() => {
    return Video.createLocalAudioTrack({}).then(newTrack => {
      setAudioTrack(newTrack);
      return newTrack;
    });
  }, []);

  const getLocalVideoTrack = useCallback(async () => {
    console.log("vid single")
    const selectedVideoDeviceId = window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY);

    const { videoInputDevices } = await getDeviceInfo();

    const hasSelectedVideoDevice = videoInputDevices.some(
      device => selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId
    );
    console.log(hasSelectedVideoDevice, "hasSelectedVideoDevice")
    const options = {
      ...(DEFAULT_VIDEO_CONSTRAINTS),
      name: `camera-${Date.now()}`,
      ...(hasSelectedVideoDevice && { deviceId: { exact: selectedVideoDeviceId } }),
    };

    return Video.createLocalVideoTrack(options).then(newTrack => {
      setVideoTrack(newTrack);
      return newTrack;
    });
    // return Video.createLocalVideoTrack({
    //   frameRate: 24,
    //   height: 720,
    //   width: 1280,
    //   name: `camera-${Date.now()}`,
    // }).then(newTrack => {
    //   setVideoTrack(newTrack);
    //   return newTrack;
    // });
  }, []);

  const removeLocalVideoTrack = useCallback(() => {
    if (videoTrack) {
      videoTrack.stop();
      setVideoTrack(undefined);
    }
  }, [videoTrack]);

  const removeLocalAudioTrack = useCallback(() => {
    if(audioTrack) {
      setAudioTrack(undefined);
    }
  },[audioTrack])

  const getToggleNote = useCallback(() => {
    setNote(!notes);
  }, [notes]);

  const getToggleView = useCallback(() => {
    setViewEnabled(!isViewEnabled);
  },[isViewEnabled])

  const getUserRef = async (participantIdentity) => {
    const userRef = await firebase
      .firestore()
      .collection("staff")
      .doc(participantIdentity)
      .get();
    return userRef;
  }

  const getAudioAndVideoTracks = useCallback( async() => {
    const { audioInputDevices, videoInputDevices, hasAudioInputDevices, hasVideoInputDevices } = await getDeviceInfo();
    if (audioTrack || videoTrack) return Promise.resolve();

    const selectedVideoDeviceId = window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY);

    const hasSelectedVideoDevice = videoInputDevices.some(
      device => selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId
    );

    const localTrackConstraints = {
      video: hasVideo && {
        ...(DEFAULT_VIDEO_CONSTRAINTS),
        name: `camera-${Date.now()}`,
        ...(hasSelectedVideoDevice && { deviceId: { exact: selectedVideoDeviceId } }),
      },
      audio: {},
    };

    return Video.createLocalTracks(localTrackConstraints)
      .then(tracks => {
        const videoTrack = tracks.find(track => track.kind === 'video');
        const audioTrack = tracks.find(track => track.kind === 'audio');
        if (videoTrack) {
          setVideoTrack(videoTrack);
          window.localStorage.setItem(
            SELECTED_VIDEO_INPUT_KEY,
            videoTrack.mediaStreamTrack.getSettings().deviceId
          );
        }
        if (audioTrack) {
          setAudioTrack(audioTrack);
        }
      })
      .finally();
  }, [hasVideo, audioTrack, videoTrack]);

  const localTracks = [audioTrack, videoTrack].filter(track => track !== undefined);
  return { localTracks, getLocalVideoTrack, removeLocalVideoTrack, getLocalAudioTrack, removeLocalAudioTrack, getAudioAndVideoTracks, getToggleNote, notes, getToggleView, isViewEnabled, getUserRef };
}

export default useLocalTracks;
