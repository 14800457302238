import React, { useState } from 'react';
import { Drawer, makeStyles, IconButton, Typography, LinearProgress, Tooltip, Divider } from '@material-ui/core';
import { useBillingContext } from 'fitbud/providers/billing-provider';
import CloseIcon from '@material-ui/icons/CloseRounded';
import clsx from 'clsx';
import { DeleteIcon } from 'fitbud/icons/delete';
import { ClrdButton } from '../form-fields';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '420px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  addOn: {
    borderRadius: 5,
    border: '1px solid #D3D9DB',
  },
}));

const addsOn = [
  { id: 'x', name: 'Team Login', description: 'LoremIpsum' },
  { id: 'x', name: 'Team Login', description: 'LoremIpsum' },
  { id: 'x', name: 'Team Login', description: 'LoremIpsum' },
];

const Content = () => {
  const { closeRemoveAddsOn } = useBillingContext();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  return (
    <div>
      <div className="bg-light-grey sticky-top d-flex align-items-center">
        <IconButton onClick={closeRemoveAddsOn} className={clsx('fp-10')} disabled={loading} id="close-dialogue">
          <CloseIcon className="text-black" />
        </IconButton>
        <Typography className="text-center font_16_600 font-weight-700 flex-1">Manage Adds On</Typography>
        {loading && <LinearProgress className="position-absolute" style={{ top: 44, left: 0, right: 0 }} />}
      </div>
      <div className={clsx('d-flex flex-column flex-grow-1 p-25')}>
        <div>
          {addsOn.map((addOn, index) => {
            const isLast = index === addsOn.length - 1;
            return (
              <div
                key={addOn.id}
                className={clsx(classes.addOn, 'd-flex px-20 py-15 justify-content-between align-items-center', !isLast && "mb-15")}>
                <Typography className="font_15_500 text-0d0d0d">
                  {addOn.name}
                  &nbsp;
                  <Tooltip title={addOn.description}>
                  <InfoOutlinedIcon style={{fontSize:18, color:"#0d0d0d"}} size="small" />
                  </Tooltip>
                </Typography>
                <IconButton size="small">
                  <DeleteIcon />
                </IconButton>
              </div>
            );
          })}
        </div>
        <Divider className='my-25' />
        <div className="info-box">
          <Typography className="font_14_500 text-dark-grey">
            You will have benefits of <span className="font_14_600">Video on Demand</span> subscription until the end of
            your current billing cycle on <span className="font_14_600">04-feb-2024</span>.
          </Typography>
        </div>
        <Divider className='my-25' />
        <ClrdButton style={{height:44}} title="Save" variant="contained" color="primary" />
      </div>
    </div>
  );
};

const RemoveAddsOnDrawer = (props) => {
  const { closeRemoveAddsOn } = useBillingContext();
  const classes = useStyles();

  return (
    <Drawer
      anchor="right"
      open
      onClose={closeRemoveAddsOn}
      classes={{
        paper: classes.paper,
      }}
      disableEscapeKeyDown>
      <Content />
    </Drawer>
  );
};
export default RemoveAddsOnDrawer;
