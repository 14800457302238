import React from 'react';
import { Typography, FormControlLabel, Switch, Card, CardContent } from '@material-ui/core';
import clsx from 'clsx';
import { Header } from './fitness';
import { useReminder } from 'fitbud/views/reminders/provider';
import { getOnlyOrdinalSuffix, renderTime } from 'fitbud/views/reminders/helper';
import _ from 'lodash';
import MeasurementEditor from '../editors/measurements';
import { useItemClass } from './fitness';
import { useStyles } from '.';

const Measurements = (props) => {
  const { onToggle } = props;
  const classes = useStyles();
  const { edit, setEdit, readOnlyReminders, getMeasurementRccOptById, preference } = useReminder();
  const itemClasses = useItemClass();
  const { day_of_week, mode, time, day_of_month } = _.get(readOnlyReminders, 'measurements', {});
  const enabled = _.get(preference, "measurements.enabled", false);
  const openEdit = () => setEdit('measurements');
  const isDaily = (mode || "").includes('day');
  const isMonthly = (mode || '').includes('month');
  const modeResolvedOption = getMeasurementRccOptById(mode) || {};

  const renderDay = () => {
    if (isMonthly) {
      if (day_of_month === '31') return 'Last Day';
      const suffix = getOnlyOrdinalSuffix(day_of_month);
      return (
        <spam className="font_15_500">
          <span className="font_15_600">{day_of_month}</span>
          {`${suffix}`}
        </spam>
      );
    }
    return <span className="text-capitalize font_15_600">{day_of_week}</span>;
  };

  const handleToggle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onToggle('measurements');
  };

  return (
    <div className="mt-15">
      <FormControlLabel
        control={<Switch color="primary" classes={{root: classes.reminderSwitch}} checked={!!enabled} onChange={handleToggle} />}
        className="mb-4x"
        label={<Typography className="font_18_600 text-0d0d0d">Measurements</Typography>}
      />
      <Card>
        <CardContent className="px-20 pb-20 pt-12">
          <Header title={modeResolvedOption.label} handleEdit={openEdit} />
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(170px, 1fr))',
            }}
          >
            {/* Mode */}
            {!isDaily && (
              <div className={clsx(itemClasses.container)} >
                <Typography className={clsx('font_13_500 text-light-grey-2 text-truncate mb-8')}>Day</Typography>
                <Typography className={clsx('text-0d0d0d text-capitalize')}>{renderDay()}</Typography>
              </div>
            )}

            <div className={clsx(itemClasses.container)} >
              <Typography className={clsx('font_13_500 text-light-grey-2 text-truncate mb-8')}>Time</Typography>
              <Typography className={clsx('text-0d0d0d')}>{renderTime(time)}</Typography>
            </div>
          </div>
        </CardContent>
      </Card>
      {edit === 'measurements' && <MeasurementEditor />}
    </div>
  );
};
export default Measurements;
