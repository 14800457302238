import React from "react";
import useSplitStyles from "fitbud/hooks/useSplit";
import { ToolbarSpace } from "fitbud/partials/appBar";
import clsx from 'clsx';

const SplitDetailView = props => {
  const {
    detail,
    children,
    className_right = "bg-white",
    className_left = "",
    toolbar = false,
    className = ''
  } = props;
  const classes = useSplitStyles({ show: !!detail ? "right" : "left" }, props);
  // eslint-disable-next-line no-unused-vars
  const [left, right, ...ignore] = React.Children.toArray(children);
  return (
    <div className={clsx(classes.root, className, "flex-grow-1 p-height-unset p-overflow-unset")}>
      {toolbar && <ToolbarSpace />}
      <div className={`${classes.split} shadow-lg d-flex p-height-unset p-overflow-unset d-print-block`}>
        <div className={`${classes.left} flex-column ${className_left} d-print-none`}>{left}</div>
        <div
          className={`${classes.right} ${className_right} flex-column flex-grow-1 minh-0 print-section`}
        >
          {right}
        </div>
      </div>
    </div>
  );
};

export default SplitDetailView;
