import React, { useState } from 'react';
import { RadioGroup, FormControlLabel, Radio, Typography } from '@material-ui/core';
import clsx from 'clsx';

const SortBy = (props) => {
  const { handleSort, handleChange, value, className } = props;
  const [sort, setSort] = useState(!!value ? value : 'custom');

  const handleSorting = (e) => {
    if (!e) return;
    let value = e.target.value;
    setSort(value);
    handleChange(e);
    handleSort(value);
  };

  return (
    <div className={clsx("d-flex align-items-center ", className)}>
      <Typography className={clsx('fp-0 mr-20 font_16_500')}>Sort By:</Typography>
      <RadioGroup value={sort} name="sort_by" className="flex-row fp-0" onChange={handleSorting}>
        <FormControlLabel
          className="fmr-30 mb-0"
          value="time"
          control={<Radio className="p-0 mr-10" color="primary" />}
          label="Time"
          classes={{ label: 'font_16_500' }}
        />
        <FormControlLabel
          className={'mb-0'}
          value="custom"
          control={<Radio className="p-0 mr-10" color="primary" />}
          label="Custom"
          classes={{ label: 'font_16_500' }}
        />
      </RadioGroup>
    </div>
  );
};

export default SortBy;
