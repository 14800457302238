import React, { useEffect, useRef, useState } from 'react';
import _ from "lodash";
import { IconButton, Typography } from "@material-ui/core";
import PlayIcon from "fitbud/images/play_icon.svg"
import StopIcon from "fitbud/images/stop_record.svg"
import {secondsToMinutes} from "fitbud/utils/helpers";
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  width: {
    width: "90%"
  }
});

const initialState = {
  audioPlayer: {
    loaded: false, state: 'pause', duration: 0
  },
  progress: 0
};

const AudioPlayer = (props) => {
  const classes = useStyles();
  const ref = useRef();
  const rangeRef = useRef();
  const { src, duration, minified } = props;
  const [state, setState] = useState(initialState)

  const toggleAudioState = () => {
    let audioPlayer = _.assign({}, state.audioPlayer);
    const audioEl = ref.current;

    if(audioPlayer.state === 'pause') {
      //Stop all audio tracks
      var sounds = document.getElementsByTagName('audio');
      for(let i=0; i<sounds.length; i++) sounds[i].pause();
      audioPlayer.state = 'play';
      audioEl.play();
    } else {
      audioPlayer.state = 'pause';
      audioEl.pause();
    }
    setState({audioPlayer: _.assign({}, state.audioPlayer, audioPlayer)});
  }

  const audioTimeUpdate = async () => {
    let audioPlayer = _.assign({}, state.audioPlayer),
        audioEl     = ref.current
    rangeRef.current.value = Math.floor(audioEl.currentTime);
    const rangeVal = (rangeRef.current.value - rangeRef.current.min) / (rangeRef.current.max - rangeRef.current.min);
    const percent = rangeVal * 100;
    rangeRef.current.style.backgroundImage = '-webkit-gradient(linear, left top, right top, ' +
    'color-stop(' + percent + '%, #000000), ' +
    'color-stop(' + percent + '%, #e1e1e1)' +
    ')'
    audioPlayer.duration = duration;
    setState({audioPlayer: _.assign({}, state.audioPlayer, audioPlayer), progress: Math.floor(audioEl.currentTime)});
  }

  const rangeChange = () => {
    const audioEl = ref.current;
    audioEl.currentTime = rangeRef.current.value;
    if(state.audioPlayer.state === 'play')
      audioEl.play();
  }

  const rangeInput = () => {
    ref.current.pause();
  }
  
  const { audioPlayer } = state;
  const totalAudioDuration = secondsToMinutes(duration);
  const _progress = secondsToMinutes(state.progress);

  useEffect(()=>{
    let audioEl = ref.current;
    
    let audioPlayer = _.assign({}, state.audioPlayer);
    audioEl.addEventListener('ended', (event) => {
      audioPlayer.state = 'pause';
      if(rangeRef && rangeRef.current) rangeRef.current.style.backgroundImage = '';
      setState({audioPlayer: {...audioPlayer}});
    });
    return ()=> audioEl.removeEventListener('ended', ()=> null);
  });
  useEffect(()=>{
    let audioEl = ref.current;
    let audioPlayer = _.assign({}, state.audioPlayer);
    audioEl.addEventListener('pause', (event) => {
      audioPlayer.state = 'pause';
      if(rangeRef && rangeRef.current) rangeRef.current.style.backgroundImage = '';
      setState({audioPlayer: {...audioPlayer}});
    });
    return ()=> audioEl.removeEventListener('pause', ()=> null);
  });
  if(!minified){
    return (
      <div className="w-100 d-flex flex-column align-items-center justify-content-center">
        <audio ref={ref} onTimeUpdate={audioTimeUpdate}>
          <source src={src} type="audio/mp3" />
        </audio>
          <IconButton onClick={toggleAudioState}>
            <img alt="Play Recording" src={audioPlayer.state === "pause" ? PlayIcon : StopIcon} />
          </IconButton>
          <div className={`d-flex justify-content-center align-items-center fmt-10 ${classes.width}`}>
            <span className="font_13_500 text-muted">{_progress}</span>
            <input className={`audioRange fml-20 fmr-20 ${classes.width}`} ref={rangeRef} type="range" min="0" max={audioPlayer.duration} step="1" onChange={rangeChange} onInput={rangeInput}/>
            <span className="font_13_500 text-muted">{totalAudioDuration}</span>
          </div>
      </div>
    );
  }
  return (
    <div className="w-100 d-flex align-items-center justify-content-center fp-5">
      <audio ref={ref} onTimeUpdate={audioTimeUpdate}>
        <source src={src} type="audio/mp3" />
      </audio>
        <IconButton onClick={toggleAudioState} style={{ marginLeft: '-16px'}} classes={{ root: "p-8" }}>
          <img alt="Play Recording" src={audioPlayer.state === "pause" ? PlayIcon : StopIcon} style={{ width: '50px' }}/>
        </IconButton>
        <div className={`d-flex justify-content-center align-items-center ${classes.width}`}>
          <span className="font_13_500 text-muted">{_progress}</span>
          <input className={`audioRange fml-20 fmr-20 ${classes.width}`} ref={rangeRef} type="range" min="0" max={audioPlayer.duration} step="1" onChange={rangeChange} onInput={rangeInput}/>
          <span className="font_13_500 text-muted">{totalAudioDuration}</span>
        </div>
    </div>
  )
}

export default AudioPlayer;