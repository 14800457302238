import React, { useState } from 'react';
import { MenuItem, Select, OutlinedInput, InputAdornment, makeStyles } from '@material-ui/core';
import Dialog from 'fitbud/components/Dialog';
import DropDownIcon from '@material-ui/icons/ExpandMore';
import { FormTextField } from 'fitbud/components/form-fields';
import { TimePicker } from '@material-ui/pickers';
import clsx from 'clsx';
import { date2hhmm } from 'fitbud/utils/scheduling';
import moment from 'moment';
import { useReminder } from 'fitbud/views/reminders/provider';
import _ from 'lodash';
import { SimpleNumberInput } from 'fitbud/components/numberInput';
import { useSnackbar } from 'notistack';
import { getWaterFrequencyLabel, hhMMToDate, WATER_FREQUENCY, WATER_MODE } from 'fitbud/views/reminders/helper';

const useStyles = makeStyles(() => ({
  timeInput: {
    minHeight: '48px',
    fontSize: '15px',
    fontWeight: 600,
  },
}));

const WaterEditor = () => {
  const { reminders, setConfigKey, dirty, onSave, closeEdit, water_tag_config, time_picker_step } = useReminder();
  const { count, interval, enabled, mode, from, to } = _.get(reminders, 'water', {});
  const { default_from, default_to, freqs, max_reminders } = water_tag_config || {};
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useState({});

  const setError = (key, error) => {
    setErrors((errors) => ({ ...errors, [key]: error }));
  };

  const handleChange = (e) => {
    const key = e.target.name || e.target.id;
    let value = e.target.value;
    if (e.target.type === 'number') {
      value = Number(value) || 0;
    }
    setConfigKey('water', key, value);
  };

  const onTimeChange = (key) => (time) => {
    const t = moment(time);
    const hhmm = date2hhmm(t);
    setConfigKey('water', key, hhmm);
    setError('from', '');
  };

  const handleSubmit = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (mode === 'count' || mode === "frequency") {
      // if(from === to){
      //   const time_error = "From and To time must be different"
      //   setError('from', time_error);
      //   enqueueSnackbar(time_error, { variant: 'error' });
      //   return null;
      // }
      const fromDate = hhMMToDate(from);
      const toDate = hhMMToDate(to);
      if (moment(toDate).isBefore(fromDate)) {
        const time_error = 'To must be greater than From';
        setError('from', time_error);
        enqueueSnackbar(time_error, { variant: 'error' });
        return null;
      }
    }
    onSave('water');
  };

  return (
    <Dialog
      toolbarClass="height-60"
      buttonColor="primary"
      title="Water Reminder"
      open
      withConfirmation
      isDirty={dirty}
      onClose={closeEdit}
      paperClass="width-400"
      titleFont="h3"
      actionText="Save"
      formId="water_reminder"
    >
      <form id="water_reminder" className="px-20 pt-20" onSubmit={handleSubmit}>
        {/* <Typography className="font_16_600 text-0d0d0d mb-20">Preferred Timing</Typography> */}
        <FormTextField fullWidth label="Send Reminders by">
          <Select
            IconComponent={DropDownIcon}
            input={
              <OutlinedInput
                classes={{
                  root: 'medium',
                  input: 'size_15_600 select-medium',
                }}
                name="mode"
              />
            }
            value={mode}
            onChange={handleChange}
            name="mode"
          >
            {WATER_FREQUENCY.map((op) => (
              <MenuItem key={op.value} value={op.value}>
                {op.label}
              </MenuItem>
            ))}
          </Select>
        </FormTextField>
        {mode === WATER_MODE.FREQUENCY && (
          <FormTextField fullWidth label="Every">
            <Select
              IconComponent={DropDownIcon}
              input={
                <OutlinedInput
                  classes={{
                    root: 'medium',
                    input: 'size_15_600 select-medium',
                  }}
                  name="interval"
                />
              }
              value={interval}
              onChange={handleChange}
              name="interval"
            >
              {(freqs || []).map((op) => (
                <MenuItem key={op} value={op}>
                  {getWaterFrequencyLabel(op)}
                </MenuItem>
              ))}
            </Select>
          </FormTextField>
        )}
        {mode === WATER_MODE.COUNT && (
          <FormTextField fullWidth label="Count" required>
            <SimpleNumberInput
              value={count}
              onChange={handleChange}
              id="count"
              type="number"
              required
              decimalLimit={0}
              inputProps={{
                min: 1,
                max: max_reminders || 20,
                step: 1,
              }}
              variant="outlined"
              InputProps={{
                // eslint-disable-line react/jsx-no-duplicate-props
                classes: {
                  root: 'medium',
                  input: 'size_15_600 medium',
                },
              }}
            />
          </FormTextField>
        )}
        <div className="d-flex">
          <FormTextField fullWidth label="From" classes={{ control: 'mr-20 mb-20' }}>
            <TimePicker
              format="hh:mm a"
              value={hhMMToDate(from || default_from)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <DropDownIcon />
                  </InputAdornment>
                ),
                className: clsx(classes.timeInput),
              }}
              inputVariant="outlined"
              onChange={onTimeChange('from')}
              error={errors.from}
              minutesStep={time_picker_step}
            />
          </FormTextField>
          <FormTextField fullWidth label="To">
            <TimePicker
              format="hh:mm a"
              value={hhMMToDate(to || default_to)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <DropDownIcon />
                  </InputAdornment>
                ),
                className: clsx(classes.timeInput),
              }}
              inputVariant="outlined"
              onChange={onTimeChange('to')}
              error={errors.to}
              minutesStep={time_picker_step}
            />
          </FormTextField>
        </div>
      </form>
    </Dialog>
  );
};
export default WaterEditor;
