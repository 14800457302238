import { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import useVideoContext from './useVideoContext';
import useIsTrackEnabled from './useIsTrackEnabled';

const useLocalAudioToggle = () => {
  const { localTracks } = useVideoContext();
  const {enqueueSnackbar} = useSnackbar()
  const audioTrack = localTracks.find(track => track.kind === 'audio');
  const isEnabled = useIsTrackEnabled(audioTrack);

  const toggleAudioEnabled = useCallback(() => {
    if (audioTrack) {
      audioTrack.isEnabled ? audioTrack.disable() : audioTrack.enable();
    }else{
      enqueueSnackbar("Audio permission denied.", {
        variant: "error",
      });
    }
  }, [audioTrack]);

  return [isEnabled, toggleAudioEnabled];
}

export default useLocalAudioToggle;