import React, { useMemo } from 'react';
import { MenuItem, Select, OutlinedInput, InputAdornment, makeStyles } from '@material-ui/core';
import Dialog from 'fitbud/components/Dialog';
import DropDownIcon from '@material-ui/icons/ExpandMore';
import { FormTextField } from 'fitbud/components/form-fields';
import { TimePicker } from '@material-ui/pickers';
import clsx from 'clsx';
import { date2hhmm } from 'fitbud/utils/scheduling';
import moment from 'moment';
import SpecificTimePicker from '../specific-time-picker.js';
import { useReminder } from 'fitbud/views/reminders/provider';
import _ from 'lodash';
import { hhMMToDate, weekNameMap, DAYS } from 'fitbud/views/reminders/helper';
import { InfoBox } from './checkins';

const SAME_TIME = 'time';
const DIFFERENT_TIMES = 'days';

const MODE_CONSTANT = [
  { label: 'Same Time', value: SAME_TIME },
  { label: 'Different Times', value: DIFFERENT_TIMES },
];

const useStyles = makeStyles(() => ({
  container: {
    '& .mr-n8': {
      marginRight: '-8px',
    },
  },
  timeInput: {
    minHeight: '48px',
    fontSize: '15px',
    fontWeight: 500,
  },
}));

const FitnessEditor = (props) => {
  const { reminders, setConfigKey, setFitnessDayTime, dirty, onSave, closeEdit, time_picker_step } =
    useReminder();
  const { mode, time, days } = _.get(reminders, 'fitness', {});
  const classes = useStyles();

  const sortedDays = useMemo(() => {
    return DAYS.reduce((acc, current) => {
      acc[current] = days[current] || false;
      return acc;
    }, []);
  }, [days]);

  const handleModeChange = (e) => {
    const value = e.target.value;
    setConfigKey('fitness', 'mode', value);
  };

  const onEveryDayTimeChange = (time) => {
    const t = moment(time);
    const hhmm = date2hhmm(t);
    setConfigKey('fitness', 'time', hhmm);
  };

  const onTimeChange = (key, time) => {
    const t = moment(time);
    const hhmm = date2hhmm(t);
    setFitnessDayTime(key, hhmm);
  };
  const handleCopy = (key, time) => {
    setFitnessDayTime(key, time);
  };

  const handleSubmit = () => {
    onSave('fitness');
  };

  return (
    <Dialog
      toolbarClass="height-60"
      buttonColor="primary"
      title="Workout Reminder"
      open
      withConfirmation
      isDirty={dirty}
      onClose={closeEdit}
      paperClass="width-400"
      titleFont="h3"
      onSave={handleSubmit}
      actionText="Save"
    >
      <div className={clsx(classes.container, 'px-20 pt-20 pb-10')}>
        {/* Sync Mode */}
        <InfoBox
          className="mb-20"
          text="Reminders will be sent to the clients on the days when there is a scheduled workout."
        />
        <FormTextField fullWidth label="Send Reminder at">
          <Select
            IconComponent={DropDownIcon}
            input={
              <OutlinedInput
                classes={{
                  root: 'medium',
                  input: 'size_16_500 select-medium',
                }}
                name="type"
              />
            }
            value={mode}
            onChange={handleModeChange}
          >
            {MODE_CONSTANT.map((op) => (
              <MenuItem key={op.value} value={op.value}>
                {op.label}
              </MenuItem>
            ))}
          </Select>
        </FormTextField>

        {mode === SAME_TIME && (
          <FormTextField fullWidth label="Time">
            <TimePicker
              format="hh:mm a"
              value={hhMMToDate(time)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <DropDownIcon />
                  </InputAdornment>
                ),
                className: clsx(classes.timeInput),
              }}
              inputVariant="outlined"
              onChange={onEveryDayTimeChange}
              minutesStep={time_picker_step}
            />
          </FormTextField>
        )}
        {mode === DIFFERENT_TIMES && (
          <SpecificTimePicker
            onTimeChange={onTimeChange}
            handleCopy={handleCopy}
            data={sortedDays}
            nameMapping={weekNameMap}
            minutesStep={time_picker_step}
          />
        )}
      </div>
    </Dialog>
  );
};

export default FitnessEditor;
