import React,{useContext} from 'react';
import { DialogActions } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import Confirmation from "fitbud/components/confirmationDialog";
import {FirebaseAuthContext} from "fitbud/providers/firebase-auth";
import _ from "lodash";

const MSG="There are changes that have not been saved yet. Please save them or discard them. You can also save and publish all your changes now.";
const PUBLISH_MSG="Last saved changes have not been published yet. Do you want to publish them now or later? ";
export const CustomConfirmation=({handleClose,draftFeature,...rest})=>{
  const {dirty,handleDiscard,msg}=rest;
  const {comp}=useContext(FirebaseAuthContext);
  const _draftFeature= _.isUndefined(draftFeature)?!!comp.data().features.draft:draftFeature;

  if(!_draftFeature){
    return(
      <Confirmation
        open
        handleClose={handleClose}
        handleChange={handleDiscard}
        handleCancel={handleClose}
        msg={msg}
      />
    )
  }
  return(
    <Confirmation
     open
     title={!!dirty?"Unsaved Changes":"Unpublished Changes"}
     msg={!!dirty?MSG:PUBLISH_MSG}
     showCloseIcon={true}
     handleClose={handleClose}
     customAction={
      <CustomConfirmationAction
        {...rest}
      />
    }
    />
  )
}


export const CustomConfirmationAction=({dirty,handleDiscard,handleDraft,handlePublish,disableBtns,disablePublishBtn,showDiscard=true})=>{
    if(!!dirty && showDiscard)
    return(
      <DialogActions className="mt-20 p-0 justify-content-between">
        <Button
          variant="text"
          color="primary"
          classes={{ label: "font_15_700" }}
          onClick={handleDiscard}
          className={"ml-n8"}
        >
          Discard
        </Button>
        <div>
          <Button 
            variant="outlined" 
            color="primary" 
            className={clsx('f-sm-med')}
            disableElevation
            classes={{ label: "font_15_700" }}
            onClick={handleDraft}
            disabled={disableBtns}
            >
              Save
          </Button>
          <Button
            variant="contained"
            className={"ml-20 f-sm-med"}
            color="primary"
            autoFocus
            disableElevation
            classes={{ label: "font_15_700" }}
            onClick={handlePublish}
            disabled={disableBtns||disablePublishBtn}
          >
            Save & Publish
          </Button>
        </div>
      </DialogActions>
    )
    else{
      return(
        <DialogActions className="mt-20 p-0 justify-content-between">
          <Button
            variant="text"
            color="primary"
            classes={{ label: "font_15_700" }}
            onClick={handleDraft}
            className={"ml-n8"}
            disabled={disableBtns}
            >
            Publish Later
          </Button>
          <Button
            variant="contained"
            className={"ml-20 f-sm-med"}
            color="primary"
            autoFocus
            disableElevation
            classes={{ label: "font_15_700" }}
            onClick={handlePublish}
            disabled={disableBtns||disablePublishBtn}
          >
            Publish Now
          </Button>
        </DialogActions>
      )
    }
  }