import React from 'react';
import { Typography, FormControlLabel, Switch, Card, CardContent, IconButton } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import DetailEditIconNew from 'fitbud/icons/detailEdit';
import clsx from 'clsx';
import FitnessEditor from 'fitbud/views/reminders/editors/fitness';
import { useReminder } from 'fitbud/views/reminders/provider';
import { weekNameMap, getDisplayTime, DAYS } from 'fitbud/views/reminders/helper';
import _ from 'lodash';
import { useStyles } from '.';

export const Header = (props) => {
  const { title, handleEdit, className, subTitle } = props;
  return (
    <div className={clsx(className, 'd-flex justify-content-between align-items-center')}>
      <Typography className="font_15_600 text-0d0d0d flex-grow-1">{title}</Typography>
      {!!subTitle && (
        <Typography color="primary" className="font_11_700 mr-10">
          {subTitle}
        </Typography>
      )}
      <IconButton edge="end" className="" onClick={handleEdit}>
        <DetailEditIconNew className="small" />
      </IconButton>
    </div>
  );
};

export const useItemClass = makeStyles(() => ({
  container: {
    width: 95,
    marginTop: 10,
    paddingRight: 20,
  },
}));

const Fitness = (props) => {
  const { onToggle } = props;
  const classes = useStyles();
  const { edit, setEdit, readOnlyReminders, preference } = useReminder();
  const openEdit = () => setEdit('fitness');
  const { mode, time, days = {} } = _.get(readOnlyReminders, 'fitness', {});
  const enabled = _.get(preference, "fitness.enabled", false);

  const isEveryDay = mode === 'time';
  const handleToggle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onToggle('fitness');
  };
  return (
    <div>
      <FormControlLabel
        control={<Switch color="primary" classes={{root: classes.reminderSwitch}} checked={!!enabled} onChange={handleToggle} />}
        className="mb-4x"
        label={<Typography className="font_18_600 text-0d0d0d">Workout</Typography>}
      />
      <Card>
        <CardContent className="px-20 pb-20 pt-12">
          <Header
            title={isEveryDay ? 'Every Day' : 'Different Times'}
            handleEdit={openEdit}
          />
          <div
            className={clsx('mt-n8')}
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(170px, 1fr))',
            }}
          >
            {!!isEveryDay && (
              <div style={{ marginTop: 12 }}>
                <Typography className={clsx('font_13_500 text-light-grey-2 mb-8')}>Monday to Sunday</Typography>
                <Typography className={clsx('text-0d0d0d')}>
                  <span className="font_15_700">{getDisplayTime(time).time}</span>&nbsp;
                  <span className="font_15_500">{getDisplayTime(time).m}</span>
                </Typography>
              </div>
            )}
            {!isEveryDay &&
              DAYS.map((key, index) => {
                const name = weekNameMap[key];
                const dt = days[key] ? getDisplayTime(days[key]) : null;
                return (
                  <div style={{marginTop: 20}}>
                    <Typography className={clsx('font_13_500 text-light-grey-2 mb-8 text-truncate')}>{name}</Typography>
                    <Typography className={clsx('text-0d0d0d')}>
                      {!!dt ? (
                        <>
                          <span className="font_15_700">{dt.time}</span>&nbsp;
                          <span className="font_15_500">{dt.m}</span>
                        </>
                      ) : (
                        <span className="font_15_700">-</span>
                      )}
                    </Typography>
                  </div>
                );
              })}
          </div>
        </CardContent>
      </Card>
      {edit === 'fitness' && <FitnessEditor />}
    </div>
  );
};

export default Fitness;
