import { useState, useEffect } from 'react';
import firebase from 'fitbud/firebase';

const usePreviewLink = (cid) => {
  const [link, setLink] = useState('');
  const [primary, setPrimary] = useState('');

  useEffect(() => {
    if(!cid) return;
    setPrimary(`${cid}.fitbudd.com`);
    const getDns = async () => {
      const snapShot = await firebase.firestore().collection('dns').where('cid', '==', cid).get();

      if (!!snapShot) {
        let idWithDot = '';
        let idWithoutDot = '';

        snapShot.docs.forEach((doc) => {
          const id = (doc && doc.id) || '';
          if (!!id.includes('.') && !idWithDot) {
            idWithDot = id;
            if (doc.data().override) setPrimary(id);
          } else if (!idWithoutDot) {
            idWithoutDot = id;
            if (doc.data().override) setPrimary(`${id}.fitbudd.com`);
          }
        });

        let _link = '';
        if (idWithDot) {
          //if any ids preset with . then consider it is link.
          _link = idWithDot;
        } else if (idWithoutDot) {
          //if ids . is not present then  add fitbudd domain
          _link = `${idWithoutDot}.fitbudd.com`;
        } else {
          _link = `${cid}.fitbudd.com`; //if no any ids found then use cid with fitbudd.com
        }
        setLink(_link);
      }
    };

    getDns();
  }, [cid]);

  return [link, primary];
}

export default usePreviewLink;
