import React, { useCallback, useMemo, useState, useContext } from 'react';
import { Tooltip, IconButton, Menu, MenuItem, Divider } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import { UserSchContext } from "fitbud/views/schedulev2";
import {get} from 'lodash';

const AORGN = {vertical: 'bottom', horizontal: 'center'};
const MORGN = {vertical: 'top', horizontal: 'right'};

const AddBtn = (props) => {
  const {controller}=useContext(UserSchContext);
  const { hidden, week, weekId, day, axn } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const tooltip = useMemo(() => {
    if (!weekId || day < 0 || day > 7) return undefined;
    return "Add entry for the day"
  }, [weekId, day]);
  const onIconClick = useCallback((e) => {
    if (!anchorEl) setAnchorEl(e.currentTarget);
    else {
      setAnchorEl(null);
      axn(e);
    }
  }, [anchorEl, axn]);
  const close = () => setAnchorEl(null);
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  if (hidden || !weekId || day < 0 || day > 7) return null;
  return (
    <>
      <Tooltip title={tooltip}>
        <IconButton className={`my-n2 ${open ? 'bg-light-primary' : ''}`}
          onClick={onIconClick} data-week={week} data-weekid={weekId} data-day={day} data-purpose='wo'>
          {open ? <CloseIcon fontSize='small' color='primary'/> : <AddIcon fontSize='small'/>}
        </IconButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={open} onClose={close} getContentAnchorEl={null} anchorOrigin={AORGN} transformOrigin={MORGN}>
      <MenuItem onClick={onIconClick} data-week={week} data-weekid={weekId} data-day={day} data-purpose='fitness'>Workouts</MenuItem>
      <MenuItem onClick={onIconClick} data-week={week} data-weekid={weekId} data-day={day} data-purpose='nutrition'>Meals</MenuItem>
      <MenuItem onClick={onIconClick} data-week={week} data-weekid={weekId} data-day={day} data-purpose='slms'>Supplements</MenuItem>
      <MenuItem onClick={onIconClick} data-week={week} data-weekid={weekId} data-day={day} data-purpose='water'>Water</MenuItem>
      <MenuItem onClick={onIconClick} data-week={week} data-weekid={weekId} data-day={day} data-purpose='activity'>Activities</MenuItem>
      {/* <Divider/> */}
      {/* <MenuItem onClick={onIconClick} data-week={week} data-weekid={weekId} data-day={day} data-purpose={isRestDay?'rest-off':'rest'}>{isRestDay?"Make it Active Day":"Make it Rest Day"}</MenuItem> */}
        
        {/* <MenuItem onClick={onIconClick} data-week={week} data-weekid={weekId} data-day={day} data-purpose='woSch'>Import Workout Schedule</MenuItem>
        {isPerDay && <MenuItem onClick={onIconClick} data-week={week} data-weekid={weekId} data-day={day} data-purpose='mlSch'>Import Meal Schedule</MenuItem>}
        <MenuItem onClick={onIconClick} data-week={week} data-weekid={weekId} data-day={day} data-purpose='wo'>Add a Workout</MenuItem>
        {isPerDay && <MenuItem onClick={onIconClick} data-week={week} data-weekid={weekId} data-day={day} data-purpose='ml'>Add a Meal</MenuItem>} */}
      </Menu>
    </>
  );
};

export default AddBtn;
