import React, { useState, useEffect, useReducer, useRef, useMemo } from 'react';
import { Typography, IconButton, Card, CardHeader, CardContent, Divider,Popover,Switch,InputAdornment, Tooltip, TextField } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import _ from 'lodash';
import clsx from 'clsx';
import FoodPngImg from 'fitbud/images/catalog/food.png';
import { PlaceholderContent } from 'fitbud/components/catalog/emptyListPlaceholder';
import EditIcon from 'fitbud/icons/detailEdit';
import IngredientsEditor from './ingredientsEditor';
import addIcon from 'fitbud/images/plus.svg';
import ImportExport from '@material-ui/icons/ImportExport';
import { ClrdButton } from 'fitbud/components/form-fields';
import ReorderDialog from 'fitbud/components/reorderDialog';
import { DRAFT,PUBLISHED,TO_PUBLISH_WARNING_MSG } from 'fitbud/utils/constants';
import OptionsIcon from '@material-ui/icons/MoreVert';
import { MenuList } from 'fitbud/components/menuList';
import Confirmation from 'fitbud/components/confirmationDialog';
import update from 'immutability-helper';
import {CustomConfirmation} from "fitbud/components/customConfirmation";
import prep_time_svg from "fitbud/images/prep_time.svg";
import { roundNumber} from "fitbud/utils/helpers";
import useServings from 'fitbud/hooks/useServings';
import ExpandMoreRounded from '@material-ui/icons/ExpandMoreRounded';
import { useInput, useToggle, useAnchor } from 'fitbud/hooks/form';

const ADD='add';
const EDIT='edit';
const SERVE_INFO="The ingredients will be adjusted proportionally based on the selected serving size. For eg. the ingredient quantities will be doubled if you double the serving size";

const useClasses = makeStyles(() => ({
  root: {
    '& .mt-16': {
      marginTop: '14px !important',
    },
  },
  ingredientsBullet: {
    height: 5,
    width: 5,
    backgroundColor: '#27AE60',
    borderRadius: '50%',
    marginRight: 10,
    marginTop: 8,
  },
}));

const dispatcher = (os, ns) => ({ ...os, ...ns });
const defaultState = {};

const Ingredients = (props) => {
  const {
    doc,
    isPreview,
    servingData,
    docId,
    draftFeature,
    handleDraft,
    handlePublish,
    showingDoc,
    toggleDoc,
    draftDoc,
    isDraftAvailable,
    stopPublishPipeline,
    handleServingChange
  } = props;
  const { ingredientsv2, ingredients: ingredientsV1,meta } = doc;
  const [state, setState] = useReducer(dispatcher, defaultState);
  const [ingredientEdit, setIngredientEdit] = useState(null);
  const [openReorder, setReorder] = useState(false);
  const [isConfirmationOpen,showConfirmation]=useState(null);
  const [confirmationParams,updateParams]=useState(null);
  const [showProgressWarning,toggleProgressWarning]=useState(null);//[null,{}]
  const { ingredients } = state;
  const classes = useClasses();
  const _hasIngredients = !!(ingredients && ingredients.length);
  const [anchorEl, setAnchorEl] = useState(null);
  const _selectedIIndex = useRef(null);
  const [isDeleteConfirmation, toggleDeleteConfirmation] = useState(false);
  const [dirty,toggleDirty]=useState(false);
  const [stopPublishLoader,toggleStopPublishLoader]=useState(false);


  const isFirstNoneCategory = useMemo(()=>{
    if(ingredients && ingredients.length > 1) return false;
    if(ingredients && ingredients.length === 1){
      const {title} = ingredients[0] || {};
      if(!title || title === "Category 1" ) return true;
    }
    return false;
  },[ingredients])

  useEffect(() => {
    let ingredients = ingredientsv2 || [];
    //for backward compatibility
    if (!ingredients.length && ingredientsV1 && ingredientsV1.length) {
      ingredients = [{ title: '', items: [...ingredientsV1] }];
    }
    ingredients = ingredients.map((ig, i) => ({ ...ig, title: ig.title || '' }));
    setState({ ingredients,meta });
  }, [ingredientsv2, ingredientsV1]);

  const handleSubmit=(payload={})=>{
    const {type,...rest}=payload;
    if(!!draftFeature){
      showConfirmation(true);
      updateParams(rest);
    }
    else{
      //save
      submit(PUBLISHED,rest)
    }
  }
  const saveIngredientCb=(cb)=>{
    if(!dirty && !isDraftAvailable){
      closeIngredientEditor();
      return;
    }
    if(!!draftFeature){
      updateParams(()=>cb);
      showConfirmation(true);
    }
    else{
      const _params=cb && cb();
      if(!_params) return;
      submit(PUBLISHED,_params);
    }
  }
  const closeIngredientCb=(cb)=>{
    if(dirty){
      updateParams(()=>cb);
      showConfirmation(true);
      return;
    }
    closeIngredientEditor();
  }
  const isFunction=(o)=>typeof(o)==='function';

  const customConfirmSaveCb=(docType=PUBLISHED)=>{
    let _params=confirmationParams && isFunction(confirmationParams)?confirmationParams():confirmationParams;
    if(!_params)return;
    submit(docType,_params);
  }
 
  const parsing=(_params)=>{
    if ('ingredientsv2' in _params) {
      const _ingredients = [];
      (_params.ingredientsv2 || []).forEach((ig) => {
        return _ingredients.push(...(ig.items || []));
      });
      _params.ingredients = _ingredients;
    }
    return _params;
  }
  
  const submit=(docType=PUBLISHED,_params)=>{
    //parsing
    _params=parsing(_params);
    //--
    if(docType===DRAFT){
      handleDraft(_params);
    }
    else if(docType===PUBLISHED){
      handlePublish(_params);
    }
    //callback
    closeIngredientEditor();
    setReorder(false);
    showConfirmation(null);
  }

  // const submit=(docType=PUBLISHED,params=null)=>{
  //   const _params=params||confirmationParams;
  //   //parsing
  //   if ('ingredientsv2' in _params) {
  //     const _ingredients = [];
  //     (_params.ingredientsv2 || []).forEach((ig) => {
  //       return _ingredients.push(...(ig.items || []));
  //     });
  //     _params.ingredients = _ingredients;
  //   }
  //   //--
  //   if(docType===DRAFT){
  //     handleDraft(_params);
  //   }
  //   else if(docType===PUBLISHED){
  //     handlePublish(_params);
  //   }
  //   //callback
  //   closeIngredientEditor();
  //   setReorder(false);
  //   showConfirmation(null);
  // }
  

  const handleIngredientEdit =async ({index=0}) => {
    if(isDraftAvailable && showingDoc!==DRAFT){
      await toggleDoc(DRAFT);
    }
    setIngredientEdit({ index });
  };

  const closeIngredientEditor = () => {
    setIngredientEdit(null);
    updateParams(null);
    toggleDirty(false);
  };

  const handleReorderList = (list,docType) => {
    const { ingredients } = state;
    const orderedIngredients = list.map((l) => {
      return ingredients[l.index];
    });
    if(!docType){
      handleSubmit({type:"reorder",...{ ingredientsv2: orderedIngredients }})
    }
    else{
      submit(docType,{ ingredientsv2: orderedIngredients });
    }
  };

  const handleIngredientOption = (e,index) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    _selectedIIndex.current = index;
  };

  const closeIngredientOption = () => {
    setAnchorEl(null);
  };

  const closeConfirmation = () => {
    toggleDeleteConfirmation(false);
    setAnchorEl(null);
    _selectedIIndex.current = null;
  };

  const handleDelete = () => {
    toggleDeleteConfirmation(true);
  };

  const handleCancelDelConfirmation = (cb) => {
    closeConfirmation();
  };

  const deleteIngredient = async (cb) => {
    if (_selectedIIndex.current === undefined || _selectedIIndex.current === null) {
      cb && cb();
    }
    let _newIngredients = [...ingredients];
    const index = _selectedIIndex.current;
    _newIngredients = update(_newIngredients, {
      $splice: [[index, 1]],
    });
    const out = {
      ingredientsv2: _newIngredients,
    };
    submit(showingDoc,out);
    if(cb) cb();
  };

  const handleDeleteConfirmation = () => {
    deleteIngredient(closeConfirmation);
  };

  const getReorderList = () => {
    return (ingredients || []).map((ingredient, index) => {
      return { name: ingredient.title, index };
    });
  };
  const handleEdit=({mode,...rest})=>{
    if(isDraftAvailable && !!_.get(draftDoc,'to_publish',false)){
      toggleProgressWarning({mode,...rest});
      return;
    }
    confirmEdit({mode,...rest});
  }
  const closeEditPopup=()=>{
    toggleProgressWarning(null);
  }
  
  const confirmEdit=async(params)=>{
    if(isDraftAvailable && _.get(draftDoc,'to_publish',false)){
      toggleStopPublishLoader(true);
      const draftDoc=await stopPublishPipeline();
      toggleStopPublishLoader(false);
      if(!draftDoc) {
        return;
      }
    }
    params=params||showProgressWarning;
    const {mode,...rest}=params;
    mode===ADD?addIngredients(rest):handleIngredientEdit(rest);
    closeEditPopup();
  }
  const addIngredients=async({initial=false})=>{
    if (isDraftAvailable && showingDoc !== DRAFT) {
      await toggleDoc(DRAFT);
    }
    setIngredientEdit({ addMore: 'true',initial });
  }
  const closeCustomConfirmation=()=>{
    showConfirmation(null);
    updateParams(null);
  }
  const discardChanges=()=>{
    closeCustomConfirmation();
    closeIngredientEditor();
  }
  const openReorderDialog=async()=>{
    if(isDraftAvailable && showingDoc!==DRAFT){
      await toggleDoc(DRAFT);
    }
    setReorder(true)
  }

  const renderPrepTime = (prep_time, className = "mr-20 align-self-center") =>{
    const _prep_time = prep_time ? prep_time / 60 : '';
    if (!!_prep_time) {
      return (
        <div className={className}>
          <img src={prep_time_svg} alt='Prep Time' className="mr-10" />
          <span className="font_16_700">{_prep_time}</span> <span className="font_16_500">min</span>{' '}
        </div>
      );
    }
    return null;
  }

  const getHeaderActions = () => {
    if (isPreview) return null;
    if (!!_hasIngredients) {
      const firstIg = ingredients[0];
      const {prep_time } = firstIg;
      return (
        <div className="d-flex">
          {!!isFirstNoneCategory && renderPrepTime(prep_time, "mr-10 align-self-center")}
          <ClrdButton
            className={classes.action_btn}
            color="primary"
            onClick={()=>handleEdit({mode:ADD})}
            classes={{ label: 'font_15_700' }}
          >
            Add Section
          </ClrdButton>
           {/* <IconButton onClick={openReorderDialog}>{<ImportExport />}</IconButton> */}
          {!!isFirstNoneCategory && (
            <IconButton onClick={() => handleIngredientEdit(0)}>
              <EditIcon />
            </IconButton>
          )}
          {ingredients && ingredients.length > 1 && (
            <IconButton onClick={openReorderDialog}>{<ImportExport />}</IconButton>
          )}
        </div>
      );
    } else {
      return (
        <IconButton
        onClick={()=>handleEdit({mode:ADD,initial:true})}
        >
          <img src={addIcon} alt="" />
        </IconButton>
      );
    }
  };
  return (
    <Card square elevation={0} classes={{ root: clsx(classes.root,"py-18") }}>
      <CardHeader
        title={<Title doc={doc} servingData={servingData} onSave={handleServingChange} isPreview={isPreview} hasIngredients={_hasIngredients}/>}
        action={getHeaderActions()}
        classes={{
          root: clsx('sub-header-root d-flex'),
          title: 'sub-title',
          action: 'margin-top-negative',
        }}
      />
      <CardContent>
        {!_hasIngredients ? (
          <PlaceholderContent
            labelClassName="font_16_600"
            subLabelClassName="font_13_500"
            className="align-items-center d-flex flex-column pb-30 pt-33"
            img={FoodPngImg}
            label={isPreview?"":"Add Ingredients"}
            subLabel={isPreview?"No ingredients added":"Click on ‘+’ icon to add ingredients"}
          />
        ) : (
          <div>
            {(ingredients || []).map((ig, i) => {
              const { title, items, prep_time } = ig;
              const _dividerStyle = {};
              const showTitle = i > 0 || !isFirstNoneCategory;
              return (
                <div key={'ingredients_detail' + i}>
                  {showTitle && <Divider style={_dividerStyle} className={clsx(i === 0 ? 'mt-16' : 'mt-20')} />}
                  <div className={clsx(!showTitle ? 'pt-15' : 'pt-20')}>
                    {showTitle && (
                      <div className="d-flex">
                        <Typography className={'font_16_600 mb-20 flex-grow-1 text-0d0d0d'}>{title || 'Main'}</Typography>
                        {renderPrepTime(prep_time)}
                        {!isPreview && (
                          <IconButton onClick={() => handleEdit({mode:EDIT,index:i})}>
                            <EditIcon />
                          </IconButton>
                        )}
                        {!isPreview && (
                          <IconButton onClick={(e) => handleIngredientOption(e, i)}>
                            <OptionsIcon />
                          </IconButton>
                        )}
                      </div>
                    )}
                    <LiItems servingData={servingData} index={i} isPreview={isPreview} items={items} meta={meta}/>
                    <MenuList
                      anchorEl={anchorEl}
                      onClose={closeIngredientOption}
                      options={[{ label: 'Delete', value: 'delete', dense: true }]}
                      handleClick={() => handleDelete()}
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </CardContent>
      {!!ingredientEdit && (
        <IngredientsEditor
          servingData={servingData}
          ingredients={ingredients}
          meta={_.cloneDeep(state.meta)}
          onSave={saveIngredientCb}
          // onSave={(p)=>handleSubmit({type:'edit',...p})}
          open={true}
          isFirstNoneCategory={isFirstNoneCategory}
          {...ingredientEdit}
          toggleDirty={toggleDirty}
          onClose={closeIngredientCb}
          N={(ingredients && ingredients.length) || 0}
        />
      )}
      {openReorder && (
        <ReorderDialog
          open
          listData={getReorderList()}
          onClose={() => {
            setReorder(false);
          }}
          hideDeleteBtn={false}
          onSubmit={handleReorderList}
          closeAfterSubmit={!draftFeature}
          showConfirmation={true}
          isDraftAvailable={isDraftAvailable}
        />
      )}
      {isDeleteConfirmation && (
        <Confirmation
          open={true}
          handleChange={handleDeleteConfirmation}
          handleCancel={handleCancelDelConfirmation}
          title={'Delete Ingredient'}
          msg={'Are you sure you want to delete this'}
          confirmOption="Yes, Delete"
        />
      )}
      {isConfirmationOpen && (
        <CustomConfirmation
         handleClose={closeCustomConfirmation}
         handleDraft={()=>customConfirmSaveCb(DRAFT)}
         handleDiscard={discardChanges}
         handlePublish={()=>customConfirmSaveCb(PUBLISHED)}
         showDiscard={dirty}
         dirty={dirty}
        />
      )}
      {showProgressWarning && <Confirmation
        open
        handleClose={closeEditPopup}
        handleChange={()=>confirmEdit()}//intentional syntax(check default param in confirmEdit)
        handleCancel={closeEditPopup}
        handle
        title="Warning"
        msg={TO_PUBLISH_WARNING_MSG}
        confirmOption="Yes, Continue"
        loading={stopPublishLoader}
        />}
    </Card>
  );
};
const getServingName=(_sortServing,unit)=>{
  return _.lowerCase((_sortServing.find(i=>i.id===unit)||{}).value);
}
const LiItems = (props) => {
  const { servingData, items,meta={}, index } = props;
  const [_sortServing=[]] = useServings({servingData});
  const classes = useClasses();
  const getServingValues = (id,serving_size, serving_unit) => {
    if(!meta[id]) return "";
    const serving_type=_.get(meta,`${id}.serving_type`,'');
    let _size = !!serving_size ? roundNumber(serving_size) : '';
    let _unit=serving_unit?getServingName(_sortServing,serving_unit):"";
    if (!_size && !_unit) return '';
    return (
      <span className="font-weight-600">
        {_size} {_unit}
        {!!_unit && <span>&nbsp;•&nbsp;</span>}
      </span>
    );
  };
  return (
    <div>
      {(items || []).map((item, i) => {
        const { name } = item;
        const data=_.get(meta,item.ref_id,{});
        const isLast = i === items.length - 1;
        return (
          <div key={'ingredient ' + index + '-' + i} className={clsx('d-flex', !isLast && 'mb-10')}>
            <div className={classes.ingredientsBullet}></div>
            <Typography className="desc">
              {getServingValues(item.ref_id,item.serving_size, item.serving_unit)}
              <span className="text-capitalize ">{data.name||name}</span>
            </Typography>
          </div>
        );
      })}
    </div>
  );
};

const Title=({doc,servingData,onSave,isPreview,hasIngredients})=>{
  const [_sortServing=[]] = useServings({servingData});
  const [anchorEl, onClick, closeEditor] = useAnchor();
  const _serving=useMemo(()=>{
    return getServingName(_sortServing, doc.serving);
  },[doc.serving,_sortServing]);
  if (isPreview && !hasIngredients) return null;
  return (
    <div className="d-flex align-items-baseline">
      Ingredients&nbsp;&nbsp;
      <span className="font_13_500">for&nbsp;&nbsp;</span>
      <span className={isPreview ? '' : 'cursor-pointer'} onClick={isPreview ? null : onClick}>
        <span className="font_18_600">{doc.serves || 1}</span>
        <span className="font_13_500">&nbsp;&nbsp;{_serving}</span>
        {!isPreview && <ExpandMoreRounded/>}
      </span>
      <Serves anchorEl={anchorEl} onClose={closeEditor} value={doc.serves} serving={_serving} onSave={onSave} hasIngredients={hasIngredients}/>
    </div>
  );
}

const Serves = ({ anchorEl, onClose, value, serving, onSave, hasIngredients}) => {
  const {parsed: serves, props, isValid} = useInput(value || 1, 'number * 0.1:min .1');
  const [adjustIngredients, toggleAdjust] = useToggle();
  if (!anchorEl) return null;
  const save = (e) => {
    if (!isValid()) return;
    onSave({ serves, adjustIngredients });
    onClose();
  };
  return (
    <Popover open={true} onClose={onClose}
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      classes={{ paper: `fb-border medium px-20 py-30`, list: "fp-0" }}>
      <Typography className="font_15_700 mb-20">Serves</Typography>
      <TextField {...props} variant='outlined' fullWidth InputProps={{
        classes: { root: "medium", input: "size_16_500" },
        endAdornment: <InputAdornment position="end">{serving || ''}</InputAdornment>
      }}/>
      {hasIngredients && <Typography className="mt-20 d-flex justify-content-between align-items-center font_13_600">
        Adjust quantity of ingredients
        <Tooltip title={SERVE_INFO}><span>ⓘ</span></Tooltip>
        <Switch id="adjustIngredients" checked={adjustIngredients} color="primary" onChange={toggleAdjust}/>
      </Typography>}
      <ClrdButton color="main" className="f-xxxlarge mt-20" onClick={save}>
        Save
      </ClrdButton>
    </Popover>
  );
};

export default Ingredients;
