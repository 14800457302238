import { Divider, IconButton, TextField, Typography, Tooltip } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { FormTextField } from 'fitbud/components/form-fields';
import { TimePick } from 'fitbud/views/liveSessions/masterSettings/SlotPicker';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { oprtnlDateFormat } from 'fitbud/utils/constants';
import {
  convertGclsSlotsFromDB,
  dateToHHmm,
  isPrevious,
  getMode,
  MORNING_10AM,
  DEFAULT_GC_SCHEDULE,
  isPreviousDay,
  hasDuplicate,
  checkDuplicateOnDifferentDays,
  ONLINE_MODE_TEXT,
  OFFLINE_MODE_TEXT,
} from '../helper';
import CloseIcon from '@material-ui/icons/CloseRounded';
import 'moment-timezone';
import { useSnackbar } from 'notistack';
import SlotConfig from '../components/slotConfigEditor';
import NumberInput from 'fitbud/components/numUnitInput';
import { useLocations } from 'fitbud/providers/gcLocationsProvider';

export const DatePickerField = (props) => {
  const InputProps = {
    classes: {
      root: 'medium',
      input: 'size_16_500 medium',
    },
    ...props.InputProps,
  };
  return <TextField {...props} InputProps={InputProps} />;
};


/*
extendedDate: this props passed in case of expire class, where expend date options performed, value will be : "x";
*/
const SchedulingEditor = (props) => {
  const { getLocation } = useLocations();
  const { data, fnRef, locations = [], cid, setDirty, scheduleId, extendedDate } = props;
  const isNew = props.isNew || !scheduleId; //either is new or  schedule id not available then treat as a new
  let { slotsConfig: _slotsConfig = null, schedule, meta } = data;
  const { temp_schedule } = meta || {};
  if (!schedule) schedule = { ...DEFAULT_GC_SCHEDULE, ...temp_schedule }; //incase of copy gc classes schedule will be null.
  const markDirty = () => setDirty(true);
  const [errors, setErrors] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const recurMode = _.get(schedule, 'rcr_mode', 'none');
  const mode = _.get(meta, 'mode', 'offline');
  const duration = _.get(meta, 'duration');
  const { isOffline, isOnLine, isHybrid } = getMode(mode);
  const { location_offline, location_online } = _.get(schedule, 'common_conf', {});
  const offlineLocationDetail = location_offline ? getLocation(location_offline) : null;
  const onlineLocationDetail = location_online ? getLocation(location_online) : null;
  const [start, setStart] = useState(() => {
    if (extendedDate && _.get(extendedDate, 'start')) return extendedDate.start;
    if (!schedule.start) return moment().add(60, 'minute').startOf('hour').toDate();
    //in case of editing and  one time then start should be combination of start date and rcr_time.
    if (schedule.rcr_mode === 'none' && !!schedule.rcr_time) {
      return moment(schedule.start + schedule.rcr_time, 'YYYYMMDDHHmm');
    } else return moment(schedule.start, oprtnlDateFormat) || moment().toDate();
  });
  const [end, setEnd] = useState(() => {
    if (extendedDate && _.get(extendedDate, 'end')) return extendedDate.end;
    //incase of one time ie recurMode is none, start and end time is same other wise x if not decided or selectee value.
    return recurMode === 'none'
      ? moment(schedule.start, oprtnlDateFormat).toDate()
      : schedule.end === 'x'
      ? 'x'
      : moment(schedule.end, oprtnlDateFormat).toDate();
  });
  const [conf, setConf] = useState(() => {
    const capacity_offline_default = _.get(offlineLocationDetail, 'data.capacity', 0);
    const capacity_online_default = _.get(onlineLocationDetail, 'data.capacity', 0);
    const out = { ..._.get(schedule, 'common_conf', {}) };
    if (!out.capacity_offline) out.capacity_offline = capacity_offline_default || 0;
    if (!out.capacity_online) out.capacity_online = capacity_online_default || 0;
    out.buffer_offline = out.buffer_offline || 0;
    out.buffer_online = out.buffer_online || 0;
    return out;
  });
  const [slotsConfig, setSlotConfig] = useState(() => {
    //corner case if it is new or user navigate around wizard and already set in sate then use it, other wise prepare it..
    if (isNew && !_.isEmpty(props.slotsConfig)) return props.slotsConfig;
    //convert db values to local state for slot config.
    // if rcr_dtow is present and rct_time is not null ie it is same.
    const convertedSlotsConfig = convertGclsSlotsFromDB(schedule);
    return {
      different_slots: _.get(convertedSlotsConfig, 'different_slots', false),
      days: convertedSlotsConfig.days || {},
      same_rcr_time: isNew ? [dateToHHmm(MORNING_10AM)] : convertedSlotsConfig.same_rcr_time,
    };
  });

  useEffect(() => {
    if (!scheduleId) markDirty(); // in case of copy classes: when user save without change..
  }, [scheduleId]);

  useEffect(() => {
    if (recurMode !== 'none' && isNew) setEnd('x');
  }, [isNew, recurMode]);

  //validations
  const isValid = () => {
    let out = true;
    const errors = {};
    if (!!isHybrid || !!isOffline) {
      const {capacity_offline = 0 } = conf || {};
      if (!capacity_offline) {
        out = false;
        errors.capacity_offline = 'Capacity Must required.';
      }
    }
    if (!!isHybrid || !!isOnLine) {
      const { capacity_online } = conf || {};
      if (!capacity_online) {
        out = false;
        errors.capacity_online = 'Capacity Must required.';
      }
    }
    //in case of recurring
    if (recurMode === 'week') {
      const { days, same_rcr_time, different_slots } = slotsConfig;
      const slotError = {};
      const dayValues = _.chain(days).values().value();
      if (!dayValues.length) {
        enqueueSnackbar('Please Select at least one day of week ', { variant: 'error' });
        out = false;
      } else {
        //check for if all days are false,
        const isAllFalse = dayValues.every((value) => value === false);
        if (isAllFalse) {
          out = false;
          enqueueSnackbar('Please select at least a day of week', { variant: 'error' });
        } else {
          //ie some days are selected.......
          //case 1 : if same time slots
          if (!different_slots) {
            if (!same_rcr_time || !same_rcr_time.length) {
              slotError.same_rcr_time = 'Recurring time required';
              enqueueSnackbar('Please Add at least a time for a day', { variant: 'error' });
              out = false;
            } else {
              //if length found then check for duplicates...
              const isSame = hasDuplicate(same_rcr_time);
              if (isSame) {
                out = false;
                slotError.same_rcr_time = 'same time added in slot';
                enqueueSnackbar('Please Add different times in slots', { variant: 'error' });
              }
            }
          } else {
            const isDuplicateOnDay = checkDuplicateOnDifferentDays(days);
            if (isDuplicateOnDay) {
              out = false;
              enqueueSnackbar('Please add different time at particular days.', { variant: 'error' });
            }
          }
        }
      }
    }
    if (recurMode === 'none' && isPrevious(start)) {
      //in case of new item and one time class then do not create in previous time:
      out = false;
      errors.start = 'Can not create class in previous time';
    }
    setErrors(errors);
    return out;
  };

  useEffect(() => {
    const _start = moment(start).format(oprtnlDateFormat);
    const _end =
      recurMode === 'none'
        ? moment(start).format(oprtnlDateFormat)
        : end === 'x'
        ? 'x'
        : moment(end).format(oprtnlDateFormat);
    const updatedSchedule = {
      rcr_mode: recurMode,
      start: _start,
      end: _end,
      rcr_time: recurMode === 'none' ? moment(start).format('HHmm') : null,
    };
    fnRef.current = (setState) => {
      if (!setState) return false;
      if (!isValid()) return false;
      setState((curr) => ({
        ...curr,
        slotsConfig,
        schedule: { ...curr.schedule, ...updatedSchedule, common_conf: { ...conf } },
        meta: { ...curr.meta, start: _start, end: _end, rcr_mode: recurMode, status: _.get(curr, 'meta.status') }, // incase of extend date what ever is status, make it deactivate.
      }));
      return true;
    };
  }, [fnRef, recurMode, conf, start, end, slotsConfig, locations, mode, isHybrid]);

  //now handlers.
  const handleSlotConfigChange = (value) => {
    setSlotConfig(value);
    markDirty();
  };

  const handleStartChange = (time) => {
    const _start = moment(time);
    setStart(_start);
    setErrors((errors) => ({ ...errors, start: '' }));
    markDirty();
  };

  const handleEndChange = (time) => {
    const _end = moment(time);
    setEnd(_end);
    markDirty();
  };

  const handleConfChange = (e) => {
    let { name, value } = e.target;
    if (e.target.type === 'number' && value) {
      value = Number(value);
      if (value < 0) value = '0';
    }
    setConf((prev) => ({ ...prev, [name]: value }));
    markDirty();
  };

  const handleRemoveEndDate = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setEnd('x');
    markDirty();
  };

  return (
    <div className="flex-1 overflow-auto">
      <div className="px-20 my-25">
        <Typography className="font_18_600 text-0d0d0d mb-20">
          <span className="text-capitalize">{mode}</span>&nbsp;•&nbsp;
          <span>{recurMode === 'none' ? 'One Time' : 'Recurring'}</span>
        </Typography>
        <div className="d-flex">
          <FormTextField fullWidth label={recurMode === 'none' ? 'Event Date' : 'Start Date'}>
            <DatePicker
              allowKeyboardControl
              animateYearScrolling={false}
              disablePast={!!isNew}
              onChange={handleStartChange}
              value={start}
              name="start"
              format="MMM D, YYYY"
              inputVariant="outlined"
              TextFieldComponent={DatePickerField}
              keyboardButtonProps={{
                'aria-label': 'date',
              }}
              // disabled={!isNew}
              disabled={!!isPreviousDay(start)}
            />
          </FormTextField>
          {recurMode === 'none' ? (
            <FormTextField fullWidth label="Time" classes={{ control: 'mb-0 ml-20' }}>
              <TimePick
                value={start}
                format="hh:mm a"
                onChange={handleStartChange}
                name="time"
                disablePast
                error={errors.start}
                helperText={errors.start}
                mintime={moment().toDate()}
                minutesStep={5}
              />
            </FormTextField>
          ) : (
            <FormTextField fullWidth label="End Date" classes={{ control: 'mb-0 ml-20' }}>
              <DatePicker
                allowKeyboardControl
                animateYearScrolling={false}
                disablePast
                onChange={handleEndChange}
                value={end}
                name="end"
                format={end === 'x' ? '-' : 'MMM D, YYYY'}
                inputVariant="outlined"
                TextFieldComponent={DatePickerField}
                InputProps={{
                  endAdornment:
                    end === 'x' ? null : (
                      <IconButton size="small" onClick={handleRemoveEndDate}>
                        <CloseIcon />
                      </IconButton>
                    ),
                }}
                error={false}
                helperText={''}
                KeyboardButtonProps={{
                  'aria-label': 'date',
                }}
              />
            </FormTextField>
          )}
        </div>
        {(isHybrid || isOffline) && (
          <div className="d-flex mb-20 ">
            <NumberInput
              fullWidth
              label={
                <span>
                  {isHybrid ? OFFLINE_MODE_TEXT : ''} Session Capacity{' '}
                  <Tooltip
                    title={
                      isHybrid
                        ? 'maximum number of offline participants allowed in a particular class or session'
                        : 'maximum number of participants allowed in a particular class or session'
                    }>
                    <span className="cursor-pointer">ⓘ&nbsp;</span>
                  </Tooltip>
                </span>
              }
              LabelProps={{ classes: { control: ' mb-0 mr-20' }, required: true }}
              required
              name={'capacity_offline'}
              value={conf.capacity_offline}
              onChange={handleConfChange}
              autoComplete="off"
              variant="outlined"
              type="number"
              error={_.get(errors, 'capacity_offline')}
              helperText={_.get(errors, 'capacity_offline')}
              noFloat
              ignoreInitial
              InputProps={{ classes: { root: 'medium', input: 'size_16_500 medium' } }}
            />
            <NumberInput
              fullWidth
              label={
                <span>
                  {isHybrid ? OFFLINE_MODE_TEXT : ''} Waitlist Capacity{' '}
                  <Tooltip
                    title={
                      isHybrid
                        ? 'limit on the number of individuals allowed to join the offline waitlist after the offline session capacity is reached'
                        : 'maximum number of individuals who can join a waitlist once the session capacity is reached'
                    }>
                    <span className="cursor-pointer">ⓘ&nbsp;</span>
                  </Tooltip>
                </span>
              }
              required
              LabelProps={{ classes: { control: 'mb-0' } }}
              name={'buffer_offline'}
              value={conf.buffer_offline}
              onChange={handleConfChange}
              autoComplete="off"
              variant="outlined"
              type="number"
              error={_.get(errors, 'buffer_offline')}
              helperText={_.get(errors, 'buffer_offline')}
              noFloat
              ignoreInitial
              InputProps={{ classes: { root: 'medium', input: 'size_16_500 medium' } }}
            />
          </div>
        )}
        {(isHybrid || isOnLine) && (
          <div className="d-flex mb-20 ">
            <NumberInput
              fullWidth
              label={
                <span>
                  {isHybrid ? ONLINE_MODE_TEXT : ''} Session Capacity{' '}
                  <Tooltip
                    title={
                      isHybrid
                        ? 'maximum number of online participants allowed in a particular class or session '
                        : 'maximum number of participants allowed in a particular class or session'
                    }>
                    <span className="cursor-pointer">ⓘ&nbsp;</span>
                  </Tooltip>
                </span>
              }
              LabelProps={{ classes: { control: 'mb-0 mr-20' }, required: true }}
              required
              name={'capacity_online'}
              value={conf.capacity_online}
              onChange={handleConfChange}
              autoComplete="off"
              variant="outlined"
              type="number"
              noFloat
              ignoreInitial
              error={_.get(errors, 'capacity_online')}
              helperText={_.get(errors, 'capacity_online')}
              InputProps={{ classes: { root: 'medium', input: 'size_16_500 medium' } }}
            />
            <NumberInput
              label={
                <span>
                  {isHybrid ? ONLINE_MODE_TEXT : ''} Waitlist Capacity{' '}
                  <Tooltip
                    title={
                      isHybrid
                        ? 'limit on the number of individuals allowed to join the online waitlist after the online session capacity is reached'
                        : 'maximum number of individuals who can join a waitlist once the session capacity is reached'
                    }>
                    <span className="cursor-pointer">ⓘ&nbsp;</span>
                  </Tooltip>
                </span>
              }
              fullWidth
              LabelProps={{ classes: { control: 'mb-0' } }}
              name={'buffer_online'}
              value={conf.buffer_online}
              onChange={handleConfChange}
              autoComplete="off"
              variant="outlined"
              required
              type="number"
              noFloat
              ignoreInitial
              error={_.get(errors, 'buffer_online')}
              helperText={_.get(errors, 'buffer_online')}
              InputProps={{ classes: { root: 'medium', input: 'size_16_500 medium' } }}
            />
          </div>
        )}
      </div>
      {recurMode === 'week' && <Divider />}
      {recurMode === 'week' && (
        <SlotConfig slotsConfig={slotsConfig} setSlotConfig={handleSlotConfigChange} duration={duration} />
      )}
    </div>
  );
};

export default SchedulingEditor;
