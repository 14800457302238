import React, { useContext } from "react";
import Intercom from 'react-intercom';
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";

const Intercomm = (props) => {
  const { cid, authUser, userProfile: user } = useContext(FirebaseAuthContext);
  const { name, email } = user;

  if (process.env.NODE_ENV !== 'production') return null;

  return <Intercom appID='yycazhfb'
    hide_default_launcher={true}
    name={name} email={email} CID={cid}
    Domain={process.env.REACT_APP_INTERCOM_DOMAIN || 'Other'}
    user_id={authUser.uid} />
};

export const openIntercom = () => {
  const com = window.Intercom;
  if (!com) return;
  var x = document.getElementById('intercom-container');
  if (x) x.classList.remove('invisible');
  com('show');
}

export default Intercomm;

