import React from 'react';
import SelectorCatalogList from 'fitbud/components/catalog/selectorCatalogList';

import ArrowIcon from '@material-ui/icons/ArrowForward';

export const SelectorList = ({ state, keyName, defaultFilter, ...rest }) => {
  const { query, onClick, filterState, noResultFoundAction,noDataFoundAction, dataType,showMediaType,showDraftInd } = rest; //query is a prop not from redux state
  const handleClick = (doc) => {
    if(!!dataType){ //if dataType is provided then add type to the doc ie final structure will be {id, type, data }
      doc = doc || {};
      doc.type = dataType
    }
    onClick(doc);
  };

  return (
    <SelectorCatalogList
      keyName={keyName}
      listProps={{
        ListItemSecondaryAction: () => (
          <span style={{ marginRight: 8 }}>
            <ArrowIcon />
          </span>
        ),
      }}
      onClick={handleClick}
      searchHeader={false}
      query={query}
      filterState={filterState}
      noResultFoundAction={noResultFoundAction}
      noDataFoundAction={noDataFoundAction}
      defaultFilter={defaultFilter}
      showMediaType={showMediaType}
      showDraftInd={showDraftInd}
    />
  );
};
