import {bindActionCreators} from "redux";
import axnxBuilder from "./actions";
const axns = axnxBuilder();


const actionsCreator = {
    setUser: doc =>({type: axns.setUser, doc}),
    setPlan: doc => ({type: axns.setPlan, doc}),
    updateUMS: (user, plan) =>({type: axns.updateUMS, user, plan}),
    setSchedule: doc =>({type: axns.setSchedule, doc}),
    resetSchedule: () =>({type: axns.resetSchedule}),
    clear: doc=>({type:axns.clear}),
    setScheduleDetail : doc =>({type : axns.setScheduleDetail, doc})
}


export default dispatch =>bindActionCreators(actionsCreator,dispatch)
