import React, { useEffect, useCallback, useContext } from 'react';
import {
  FormControlLabel,
  Typography,
  RadioGroup,
  Radio,
  Divider,
} from '@material-ui/core';
import _ from 'lodash';
import { usePicker } from 'fitbud/hooks/form';
import clsx from 'clsx';
import GcAccess from './gcAccess';
import ServiceAccess from './servicesAccess';
import { useSnackbar } from 'notistack';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { AccessContext } from 'fitbud/providers/access-provider';

const AddOnCalling = (props) => {
  const { fnRef, compareDirty, data = {}, setDirty, isNew } = props;
  const {comp} = useContext(FirebaseAuthContext)
  const {editAccess, updateTargetAccess, editVidCall} = useContext(AccessContext);
  const company = comp ? comp.data() : {};
  const onTypeToggle = useCallback((add_on_type) => updateTargetAccess('new', { add_on_type }), [updateTargetAccess]);
  const [add_on_type, setVideoPackType] = usePicker(data.add_on_type || 'one_to_one', onTypeToggle);
  const isOneToOneCall = add_on_type === 'one_to_one';
  const { enqueueSnackbar } = useSnackbar();
  const isGroupClass = Boolean(_.get(company, "features.group_class.enabled", false));

  useEffect(() => {
    fnRef.current = (setState) => {
      if (!setState) return false;
      if (add_on_type === 'group_class' && (!editAccess || !editAccess.classes || !editAccess.classes.length)) {
        enqueueSnackbar('Select at least one group class', { variant: 'error' });
        return false;
      }
      if (add_on_type === 'one_to_one' && (!editAccess || !editAccess.services || !editAccess.services.length)) {
        enqueueSnackbar('Select at least one service', { variant: 'error' });
        return false;
      }
      setState((curr) => ({...curr, vid_call: editVidCall, add_on_type}));
      return true;
    };
  }, [fnRef, compareDirty, add_on_type, editAccess, enqueueSnackbar]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={clsx('p-20 w-100')}>
      {isNew && isGroupClass && (
        <div>
          <RadioGroup
            className={clsx('d-flex flex-row')}
            style={{ marginTop: '-4px', marginBottom: '16px' }}
            value={add_on_type}
            onChange={setVideoPackType}>
            <FormControlLabel
              value={'one_to_one'}
              control={<Radio color="primary" />}
              classes={{ label: 'font_16_500' }}
              className="mr-30 mb-0"
              label="1 on 1 Sessions Pack"
              disabled={!isNew}
            />
            <FormControlLabel
              value={'group_class'}
              control={<Radio color="primary" />}
              classes={{ label: 'font_16_500' }}
              className="mb-0"
              label={'Group Classes Pack'}
              disabled={!isNew}
            />
          </RadioGroup>
          <Divider className="mb-25 mt-16 ml-n20 mr-n20" />
        </div>
      )}
      <div className="p-15 info-box-blue mb-20">
        <Typography className="font_14_500">
          {isOneToOneCall ? (isGroupClass ?
            'The clients on this payment plan can book appointments for the 1 on 1 sessions specified below. The quota, however, is defined by the number of sessions in the pricing option.' :
            'Set 1 on 1 session duration for the clients on this payment plan. The quota, however, is defined by the number of sessions in the pricing option.')
            : 'The clients on this plan can book any of the group classes as specified below. The quota, however, is defined by the number of calls in the pricing option.'}
        </Typography>
      </div>
      <div>
        {!!isOneToOneCall && <ServiceAccess setDirty={setDirty}/>}
        {!isOneToOneCall && <GcAccess setDirty={setDirty}/>}
      </div>
    </div>
  );
};

export default AddOnCalling;
