import React from "react";
import ReactDOM from "react-dom";
import "fitbud/css/fonts.scss";
import "fitbud/css/bootstrap/full.scss";
import "fitbud/css/print.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/browser";

if(process.env.NODE_ENV === 'production'){
  const toIgnoreRegex = [
    /Loading chunk \d+ failed/,
    /RuntimeError: memory access out of bounds/g,
    /RuntimeError: Out of bounds memory access.*vmsg/g,
    /Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing./,
    /tus: failed to upload/,
  ];
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV_NAME,
    beforeSend(event) {
      const { exception } = event;
      if(!!exception.values[0]){
        const { value } = exception.values[0];
        let ignore = toIgnoreRegex.some((reg) => value.match(reg));
        if(ignore) return null;
        return event;
      };
      return event;
    },
  });
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
