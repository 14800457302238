import _ from 'lodash';
import { makeStyles,Grid } from '@material-ui/core';
import React, { useContext, useMemo } from 'react';
import FoodHeader from './foodHeader';
import { Card, Typography, CardContent, Chip } from '@material-ui/core';
import { Conversion } from 'fitbud/providers/conversion';
import { DetailPgStyling } from 'fitbud/hooks/useDetailPgStyle';
import clsx from 'clsx';
import PFC , {Calories}from './pfc';
import Img from 'react-image';
import { MoreLessTextBtn } from 'fitbud/components/moreLessText';
import { DRAFT } from 'fitbud/utils/constants';
import {DraftBanner} from "fitbud/components/draftBanner";
import { mapIdToObject } from 'fitbud/views/workouts/helperfn';
import {getDisplayValue,getPreferredUnit} from "fitbud/views/foodRecipes/newUi/inputDropdown";
import useServings from 'fitbud/hooks/useServings';
import { roundNumber } from 'fitbud/utils/helpers';
const useStyles = makeStyles(() => ({
  serving: {
    marginLeft: 100,
  },
  macrosContainer:{
    display: "inline-block !important"
  },
  servingOptions:{
    paddingInlineStart:"14px",
    '& li::marker':{
      color:'#27AE60',//TODO: define in theme
    }
  }
}));


function FoodDetails(props) {
  //props
  const { doc, handleEditMode, servingData, copyItem, deleteItem, isPreview=false,draftBannerProps, nutritionTags } = props;
  //
  const classes = useStyles();
  const [n,n2,n3,dumbUnits] =useServings({servingData});
  const { unitFactors } = useContext(Conversion);
  const preferred_unit = getPreferredUnit(doc);
  const preferred_unit_name = useMemo(() => {
    if(!!dumbUnits.length && !!preferred_unit){
      return !!dumbUnits && dumbUnits.find((i) => i.id === preferred_unit).value;
    }
    return "";
  }, [preferred_unit, dumbUnits]);
  const _calories = (doc && doc.macros && doc.macros.calories) || 0;
  const getServingSize = () => {
    const size = getDisplayValue(unitFactors, doc.serving_size||0, preferred_unit);
    return `${roundNumber(size)} ${_.lowerCase(preferred_unit_name)}`;
  };
  const serving_options=_.get(doc,"serving_options",[]);
  const _tags = useMemo(()=> mapIdToObject(nutritionTags, doc.systags).map(i=>i.label),[nutritionTags, doc.systags]);
  const _description = _.get(doc,"description",'').trim();
  const isDraft=draftBannerProps.showingDoc===DRAFT;
  return (
    <DetailPgStyling>
      {draftBannerProps.isDraftAvailable && !isPreview && (
        <DraftBanner {...draftBannerProps} doc={doc} />
      )}
      <Card square elevation={0}>
        <FoodHeader
          doc={doc}
          handleEditMode={handleEditMode}
          onDelete={deleteItem}
          onCopy={copyItem}
          isPreview={isPreview}
          keyName="food"
          isDraft={isDraft}
        />
        <CardContent>
          {_description && (
            <MoreLessTextBtn
              text={_description}
              className="desc fmb-25"
              btnClassName="viewMore"
            />
          )}
          <Grid container>
            <Grid item xs={12}>
              <Typography className="field-nutrition-title">
                Tags
              </Typography>
              <Typography className={clsx("field-value",!_tags.length && "mt-10")}>
                {_tags.map((i) => (
                    <Chip className="chips fmt-10 fmr-10" key={i} label={i} />
                ))}
                {!_tags.length && <span className='font_13_500'>Not Specified</span>}
              </Typography>
            </Grid>
            <Grid item xs={12} className="fmt-25">
            <Typography className="fmb-10 field-nutrition-title">
                Nutrition Info
            </Typography>
            <div className="d-inline-block">
                <div className="d-flex align-items-center justify-content-between">
                  <Calories calories={_calories || "0"} />
                  <Typography className={clsx(classes.serving, "font_15_600")}>
                    per {getServingSize()}
                  </Typography>
                </div>
                <div className="fmt-20">
                  <PFC macros={doc.macros} />
                </div>
              </div>
            </Grid>
            {!!serving_options.length && <Grid item xs={12} className="fmt-25">
              <Typography className="fmb-10 field-nutrition-title">
                Serving Info
              </Typography>
              <ul className={classes.servingOptions}>
              {serving_options.map((s)=>(
                <li className='mb-10' key={s.value}>
                  <Typography>
                    <span className="font_15_700">1 {s.label}</span>
                    <span className="mx-4px">•</span>
                    <span className="font_15_500">
                      {roundNumber(getDisplayValue(unitFactors,s.serving_size,doc.preferred_unit))}
                      {" "}{preferred_unit_name} 
                    </span>
                  </Typography>
                </li>
              ))}
              </ul>
            </Grid>}
            {doc.thumbnail && <Grid item xs={12} className="fmt-25">
              <Typography className="fmb-10 field-nutrition-title">
                Media
              </Typography>
              <div className="media-container media-img">
                <Img
                  src={[
                    doc.thumbnail,
                    doc.thumbnail.replace("240", "original"),
                  ]}
                  className="img"
                />
              </div>
            </Grid>}
          </Grid>
        </CardContent>
      </Card>
    </DetailPgStyling>
  );
}

export default FoodDetails;
