import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { ClrdButton } from "fitbud/components/form-fields";

const useStyles = makeStyles(() => ({
  textContainer: {
    marginTop: '30px',
  },
  textAlign: {
    textAlign: 'center',
  },
}));

const ReJoin = (props) => {
  const classes = useStyles();
  const { handleRejoin, handleRHS } = props
  return(
    <div className={classes.textAlign}>
      <div className={`${classes.textContainer}`}>
        <Typography variant="h1" className="fmb-30">
          You left the meeting
        </Typography>
        <ClrdButton
          color='primary'
          classes={{
            root: "f-small"
          }}
          type='submit'
          className={`f-large fmr-10`}
          style={{minWidth: "100px"}}
          variant="outlined"
          onClick={handleRejoin}
        >
          Rejoin
        </ClrdButton>
        <ClrdButton
          color='primary'
          classes={{
            root: "f-large"
          }}
          type='submit'
          className={`f-large`}
          variant="contained"
          onClick={handleRHS}
        >
          Go back
        </ClrdButton>
      </div>
    </div>
  )
}

export default ReJoin