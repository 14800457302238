import React, { useReducer, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import delay from "lodash/delay";
import {DRAFT_STATES, PUBLISHED,EMPTY_DOC_WARN_MSG} from 'fitbud/utils/constants';
import {get,isBoolean,isNumber} from 'lodash';

export const SelectorContext = React.createContext(null);

export const SELECTOR_LIB = {
  MY_LIB: 'my_lib',
  FITBUDD_LIB: 'fitbudd_lib',
};

const reducer = (os, ns) => {
  return ({ ...os, ...ns });
};
const INITIAL_STATE = {
  selectedLib: SELECTOR_LIB.MY_LIB,
  query: '',
  selectedValues: [],
  addAsGroup: true, //for ex selector,
  filterOpen: false,
};
const SelectorContextProvider = (props) => {
  const [state, setState] = useReducer(reducer, INITIAL_STATE);
  const { children, item, hidePreSelected = 0,keyName,allowDuplicate=false,primary={},addLimit=null,handleSelectCb } = props;
  const { selectedValues } = state;
  const { enqueueSnackbar } = useSnackbar();
  const onSelectLib = useCallback((value) => {
    setState({ selectedLib: value });
    setState({ query: "" });
    if(value === SELECTOR_LIB.FITBUDD_LIB){
      setFilterState({})
    }
  }, []);

  const onQueryChange = useCallback((e) => {
    let value = e.target.value;
    setState({ query: value });
  }, []);

  const addAllSelectedValues = (selectedValues) => {
    //for ex
    setState({ selectedValues: selectedValues });
  };

  const addSelectedValues = (doc, existingItems = []) => {
    const ignoreAddLimit=!isNumber(addLimit);
    if(!ignoreAddLimit && selectedValues.length>=addLimit) return;
    const _out = [...selectedValues];
    const publish_status=get(doc,'data.publish_status',PUBLISHED);
    if(publish_status===DRAFT_STATES['DRAFT_ONLY']){
      enqueueSnackbar(`This ${item || "Item"} is yet to be published`, { variant: 'warning' });
      return;
    }
    if(primary && primary._id===doc._id){
      enqueueSnackbar(`Can't select, this is the primary ${item||"item"}`,{variant:"warning"});
      return;
    }
    if(get(doc,'data.isEmpty',false)){
      //no keyName available here
      const msg=EMPTY_DOC_WARN_MSG(keyName);
      enqueueSnackbar(msg, { variant: 'warning' });
      return;
    }
    
    if (doc._id.match(/^vidcall_\d+$/)) { // legacy service case
      // check if some other vidcall is already present. Replace it if it is
      const selected = _out.findIndex((x, n) => (n >= hidePreSelected && x._id.match(/^vidcall_\d+$/)));
      const existing = existingItems.findIndex((x, n) => (n >= hidePreSelected && x._id.match(/^vidcall_\d+$/)));
      if (selected >= 0) {
        _out.splice(selected, 1, doc);
        // } else if (existing >= 0) {
      } else _out.push(doc);
    } else if(!allowDuplicate){
      //--check for already added items
      if (
        _out.findIndex((item) => item._id === doc._id) === -1 &&
        existingItems.findIndex((item) => item._id === doc._id) === -1
      ) {
        if(handleSelectCb) {
          return handleSelectCb(doc);//cb after all validation
        }
        _out.push(doc);
      } else {
        enqueueSnackbar(`This ${item || "Item"} is already selected`, { variant: 'warning' });
      }
      //--
    } else {
      if(handleSelectCb) return handleSelectCb(doc);//cb after all validation
      _out.push(doc);
    }
    delay(()=>{
      const el = document.getElementById(`selected_list_`+ doc._id);
      if(!el) return ;
      el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
    },100);
    if(!handleSelectCb){
      setState({ selectedValues: _out });
    }
  };

  const removeSelectedValue = (index) => {
    const _out = [...selectedValues];
    _out.splice(index + (hidePreSelected || 0), 1); //if  hidePreSelected  enable ie some number then, add it's value to the index before delete
    setState({ selectedValues: _out });
  };

  const handleAddAsGroup = () => {
    //For ex selector only
    const { addAsGroup } = state;
    setState({ addAsGroup: !addAsGroup });
  };

  const openFilter = () => {
    setState({ filterOpen: true });
  };
  const closeFilter = () => {
    setState({ filterOpen: false });
  };

  const setFilterState = (filter) => {
    setState({ filterState: filter });
  };

  return (
    <SelectorContext.Provider
      value={{
        ...state,
        onSelectLib,
        onQueryChange,
        addSelectedValues,
        removeSelectedValue,
        handleAddAsGroup,
        addAllSelectedValues,
        openFilter,
        closeFilter,
        setFilterState,
        primary
      }}
    >
      {(children)}
    </SelectorContext.Provider>
  );
};

export default SelectorContextProvider;
