import React, { useMemo, useState } from "react";
import {
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Typography
} from "@material-ui/core";
import { FormTextField } from "fitbud/components/form-fields";
import Dialog from "fitbud/components/Dialog";
import Confirmation from "../../components/confirmationDialog"
import ImageFileUpload from "fitbud/components/imageSelector";
import {CustomConfirmation} from "fitbud/components/customConfirmation";
import AutoSuggest from "fitbud/components/autoSuggest";
import { mapIdToObject } from "../workouts/helperfn";
import { DialogPdfUploader as PdfUploader } from "fitbud/components/pdfUploader";


const CreateEditForm = props => {
  const {
    doc,
    errors,
    loading,
    isNew,
    onSubmit,
    handleChange,
    onCancel,
    handleChangeType,
    draftFeature,
    handleDraft,
    handlePublish,
    isDraftAvailable=false,
    nutritionTags,
    handleDocument
  } = props;

  const { ref_name, title, description, systags, type, thumbnail, document } = doc;
  const _tags = useMemo(() => mapIdToObject(nutritionTags, systags), [nutritionTags, systags]);
  const [isConfirmationOpen, showConfirmation] = useState(null);
  const [dirty, setDirty] = useState(false);
  const handleSubmit=()=>{
    if(!isNew && !dirty && !isDraftAvailable){
      onCancel();
      return;
    }
    if(isNew || !draftFeature){
      onSubmit();
    }
    else{
      showConfirmation('save');
    }
  }
  const handleChangeWrapper = e => {
    setDirty(true)
    handleChange(e)
  };
  const handleChangeTypeWrapper = e => {
    setDirty(true)
    handleChangeType(e)
  };
  const handleDocumentWrapper = (type, f, meta) =>{
    setDirty(true)
    handleDocument(type, f, meta)
  }
  const openConfirmationDialog = () => {
    if (dirty) {
      showConfirmation('cancel');
    } else {
      onCancel();
    }
  }
  return (
    <Dialog
      toolbarClass="height-60"
      buttonColor="primary"
      open
      onClose={openConfirmationDialog}
      onSave={handleSubmit}
      title={isNew ? "Add Meal Plan" : "Edit Meal Plan"}
      titleFont="h3"
      paperClass="width-600"
    >
      <div className="fpb-20 px-20 pt-8">
        <RadioGroup value={type} name="type" className={`flex-row flex mb-12`} onChange={handleChangeTypeWrapper}>
          <FormControlLabel
            value="food"
            control={<Radio color="primary" />}
            className="fmr-30 fmb-0"
            classes={{
              label: 'font_16_500',
            }}
            label="Food Based"
          />
          <FormControlLabel
            value="macros"
            control={<Radio color="primary" />}
            className="fmb-0"
            classes={{
              label: 'font_16_500',
            }}
            label="Macros Based"
          />
        </RadioGroup>
        <div className="d-flex fmb-20">
          <div className="flex-1 fmr-20 d-flex flex-column justify-content-between">
            <FormTextField fullWidth label="Reference Name" required>
              <TextField
                id="ref_name"
                autoComplete="off"
                value={ref_name}
                onChange={handleChangeWrapper}
                error={!!errors.ref_name}
                helperText={errors.ref_name}
                variant="outlined"
                disabled={loading}
                required
                type="text"
                InputProps={{
                  classes: {
                    root: "medium",
                    input: "size_16_500 medium"
                  }
                }}
              />
            </FormTextField>
            <FormTextField fullWidth label="Display Name" required classes={{control:"mb-0"}}>
              <TextField
                id="title"
                autoComplete="off"
                value={title}
                onChange={handleChangeWrapper}
                error={!!errors.title}
                helperText={errors.title}
                variant="outlined"
                disabled={loading}
                required
                type="text"
                InputProps={{
                  classes: {
                    root: "medium",
                    input: "size_16_500 medium"
                  }
                }}
              />
            </FormTextField>
          </div>
          <ImageFileUpload
            thumbnail={thumbnail}
            id={"thumbnail"}
            onChange={handleChangeWrapper} 
            size="small"
          />
        </div>
        <FormTextField fullWidth label="Tags">
          <AutoSuggest
            placeholder="Add Tags . . ."
            onChange={value => handleChangeWrapper({ target: { name:"systags", value: value || [] }})}
            options={nutritionTags}
            value={_tags}
            disabled={loading}
          />
        </FormTextField>
        <FormTextField fullWidth label="Description">
          <TextField
            multiline
            rows="2"
            rowsMax="10"
            id="description"
            fullWidth
            onChange={handleChangeWrapper}
            value={description}
            error={!!errors.description}
            helperText={errors.description}
            variant="outlined"
            disabled={loading}
            required
            InputProps={{
              classes: {
                root: "large",
                input: "size_16_500"
              }
            }}
          />
        </FormTextField>
        <Divider className="mb-20 position-relative" style={{ left: -20, width: "calc(100% + 40px)" }}/>
            <Typography variant="h3" style={{ marginBottom: 16 }}>
              Attachment
            </Typography>
            <PdfUploader
              classes={{ root: "w-100 fb-border-radius-5" }}
              handleChange={handleDocumentWrapper}
              document={document}
              errors={errors && errors.document}
            />          
      </div>
      {isConfirmationOpen && (
            <>
              {(isNew || !draftFeature)? (
                <Confirmation
                  open
                  handleChange={onCancel}
                  handleCancel={() => showConfirmation(null)}
                />
              ) : (
                <CustomConfirmation
                  handleClose={() => showConfirmation(null)}
                  handleDiscard={onCancel}
                  handleDraft={handleDraft}
                  handlePublish={handlePublish}
                  dirty={dirty}
                  showDiscard={dirty}
                />
              )}
            </>
          )}
    </Dialog>
  );
};

export default CreateEditForm;
