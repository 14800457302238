import { IconButton, makeStyles, TextField, Typography } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import { Close } from '@material-ui/icons';
import { getImageDownloadUrl, getItemSvg } from './chatView';

const useStyles = makeStyles((theme) => ({
  targetMsgContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
    overflow: 'hidden',
    columnGap: 10,
  },
  targetMsg: {
    borderRadius: 10,
    backgroundColor: '#0000000D',
    border: '1px solid #D3D9DB',
    fontSize: 14,
    fontWeight: 500,
    transition: 'height 0.2s ease-in-out',
    display: 'flex',
    alignItems: 'center',
    columnGap: 10,
  },
  text: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    textOverflow: 'ellipsis',
    marginBottom: 0,
    whiteSpace: 'normal',
    margin: 0,
    color: '#37404D',
  },
  attachmentImg: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: 36,
    height: 36,
    borderRadius: 5,
  },
  showTargetMsg: {
    animation: '$showAnime 0.2s ease-in-out',
  },
  hideTargetMsg: {
    animation: '$hideAnime 0.2s ease-in-out',
    overflow: 'hidden',
  },
  '@keyframes showAnime': {
    from: {
      maxHeight: 0,
    },
    to: {
      maxHeight: '100px',
    },
  },
  '@keyframes hideAnime': {
    from: {
      maxHeight: '100px',
    },
    to: {
      maxHeight: 0,
    },
  },
}));

export default function TargetMessagePreview({ msgData, className, onClose, propsId, userName }) {
  const classes = useStyles();
  const containerRef = useRef(null);
  const [msg, setMsg] = useState({...msgData, userName});
  const [attachmentUrl, setAttachmentUrl] = useState(null);
  const attachmentTitle = (function () {
    if (!msg) return null;
    if (msg.type === 'image') return 'Photo';
    if (msg.type === 'attachment') return msg.body;
    if (msg.type === 'audio') return 'Audio';
    if (msg.type === 'video') return 'Video';
    return null;
  })();

  function handleAnimationEnd(e) { 
    if (!msgData) {
      setMsg(null);
      setAttachmentUrl(null);
    }
  }


  useEffect(() => {
    if (msgData) {
      setMsg({...msgData, userName});

      if (msgData.type === 'image') {
        const ext = msgData.media.identifier.split('.').pop();
        const url = `data:image/${ext};base64, ${msgData.media.imageData}`;
        setAttachmentUrl(url);
      }
    }
  }, [msgData, userName]);

  useEffect(() => {
    async function fetchImageUrl() {
      const image_base_path = _.get(msg, 'media.base_path', false);

      const storageFolderRef = !!image_base_path ? `${image_base_path}/chats` : `user_profiles/${propsId}/chats`;
      const url = await getImageDownloadUrl({ identifier: msg.media.identifier, storageFolderRef });
      setAttachmentUrl(url);
    }
    if (msg?.type === 'image') fetchImageUrl();
    if (msg?.type === 'attachment') {
      const identifier = msg.attachment.identifier;
      const ext = identifier.split('.').pop().toLowerCase();
      const iconUrl = getItemSvg(ext);

      setAttachmentUrl(iconUrl);
    }
    if (msg?.type === 'audio') {
      setAttachmentUrl(getItemSvg('audio'));
    }
    if (msg?.type === 'video') {
      const { imageData } = msg.attachment;
      const fileUrl = imageData && `data:image/png;base64,${imageData.replace(/\s/g, '')}`;
      setAttachmentUrl(fileUrl);
    }
    if (msg?.type === 'text') {
      setAttachmentUrl(null);
    }
  }, [msg, propsId]);

  if (!msg) return null;
  return (
    <div
      ref={containerRef}
      className={clsx(
        classes.targetMsgContainer,
        msgData ? classes.showTargetMsg : classes.hideTargetMsg
      )}
      onAnimationEnd={handleAnimationEnd}
    >
      <div className={clsx(classes.targetMsg, 'py-10', msg?.type === 'text' ? 'px-15' : 'px-10', className)}>
        {msg?.type === 'text' ? (
          <div>
            <p className={clsx(classes.text, "font_13_500")}>{msg?.body}</p>
            {msg.userName && <Typography className='font_11_500 mt-6 text-grey'>{msg.userName}</Typography>}
          </div>
        ) : (
          <>
             <div
              style={{
                backgroundImage: `url(${attachmentUrl})`,
                backgroundSize: ['image', 'video'].includes(msg?.type) ? 'cover' : 'contain',
                backgroundColor: msg?.type === 'video' ? '#666' : 'transparent',
              }}
              className={classes.attachmentImg}
            />
            <div>
              <p className="text-truncate m-0 font_13_500 text-dark-grey">{attachmentTitle}</p>
              {msg.userName && <Typography className='font_11_500 text-grey'>{msg.userName}</Typography>}
            </div>
          </>
        )}
      </div>
      <IconButton className="mr-10" onClick={onClose}>
        <Close />
      </IconButton>
    </div>
  );
}
