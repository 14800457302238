import React, { useState } from "react";
import { DialogContent } from "@material-ui/core";
import Dialog from "fitbud/components/Dialog";
import MultilineInput from 'fitbud/components/multilineInput';
import Confirmation from "fitbud/components/confirmationDialog";
import {CustomConfirmation} from "fitbud/components/customConfirmation";
import {DRAFT,PUBLISHED} from "fitbud/utils/constants";

const DragDropEditForm = props => {
  const { onClose, addMoreLabel, title,confirmation=false,options:propsOptions=[],showCustomConfirmation=false,isNew=false,isDraftAvailable=false } = props;
  const [isdirty,setDirty]=useState(false)
  let defaultOptions = (Array.isArray(propsOptions) && propsOptions.length)?[...propsOptions]:[""];
  const [options, setOptions] = useState(defaultOptions);
  const [isConfirmationOpen,showConfirmation]=useState(null);
  const onSubmit = (type) => {
    let updatedOptions = options.filter(option => !!option.trim());
    props.onSubmit(updatedOptions,type);
    onClose();
  };
  const handleChange=(value)=>{
    setOptions(value);
    setDirty(true);
  }
  const handleCloseWrapper=()=>{
    if(isdirty && confirmation){
      showConfirmation('cancel');
    }
    else handleClose();
  }
  const handleClose=()=>{
    if(onClose) onClose();
  }
  const handleSave=()=>{
    if(!isNew && !isdirty && !isDraftAvailable){
      onClose();
      return;
    }
    if(!showCustomConfirmation){
      onSubmit(PUBLISHED);
    }
    else{
      showConfirmation('save');
    }
  }
  return (
    <>
      <Dialog
        toolbarClass="height-60"
        buttonColor="primary"
        open={props.open}
        onClose={handleCloseWrapper}
        onSave={handleSave}
        title={title}
        titleFont="h3"
        paperClass="width-600 height-60"
      >
        <DialogContent className="p-20">
          <MultilineInput
            addMoreLabel={addMoreLabel}
            options={options}
            onChange={handleChange}
          />
        </DialogContent>
      </Dialog>
      {isConfirmationOpen && (
        <>
          {!showCustomConfirmation ? (
            <Confirmation
              open
              handleClose={() => showConfirmation(null)}
              handleChange={() => handleClose()}
              handleCancel={() => showConfirmation(null)}
            />
          ) : (
            <CustomConfirmation 
              handleClose={() => showConfirmation(null)}
              handleDiscard={handleClose}
              handleDraft={()=>onSubmit(DRAFT)}
              handlePublish={()=>onSubmit(PUBLISHED)}
              dirty={isdirty}
              showDiscard={isdirty}
            />
          )}
        </>
      )}
    </>
  );
};

export default DragDropEditForm;
