import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import Mic from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import Tooltip from '@material-ui/core/Tooltip';
import useLocalAudioToggle from './useLocalAudioToggle';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    border: "1px solid #E0E0E0",
    width: "50px",
    height: "50px"
  },
  iconButton: {
    backgroundColor: "#D9322E!important", 
    border: "none"
  }
}));

const ToggleAudioButton = props => {
  const classes = useStyles(props);
  const [isAudioEnabled=true, toggleAudioEnabled] = useLocalAudioToggle();
  return (
    <Tooltip
      title={isAudioEnabled ? 'Turn off microphone' : 'Turn on microphone'}
      placement="top"
      PopperProps={{ disablePortal: true }}
    >
      <IconButton
        className={`${classes.buttonContainer} fmr-20 ${!!isAudioEnabled ? "" : classes.iconButton}`}
        onClick={toggleAudioEnabled}
        style={{color: props.isLocal ? "#fff" : !!isAudioEnabled ? "#221F1F" : "#fff"}}
      >
       {isAudioEnabled ?  <Mic /> : <MicOffIcon />}
      </IconButton>
    </Tooltip>
  );
}

export default ToggleAudioButton;
