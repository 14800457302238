import _ from "lodash";

export  const DEFAULT_GROUP = [
  { type: "c19c9e78-9332-4aac-b799-1430944a6afc", order: 1 },
  { type: "e72cde6b-c1b3-46b6-9022-9a33b7601316", order: 3 },
  { type: "72a24eed-7afb-47f9-8b44-f60a812ed331", order: 4 }
];

export const getFoodItemMacros = (item, storedFoodRecipes) => {
  let calories = 0, protein = 0, fat = 0, carbs = 0;
  if (item.type === "food") {
    const doc = _.find(storedFoodRecipes, (d) => item.ref.id === d.id);
    const macrosData = doc.macros;
    const qty = Number(item.quantity);
    calories = calories + (macrosData.calories * qty);
    fat = fat + (macrosData.fat * qty);
    carbs = carbs + (macrosData.carbs * qty);
    protein = protein + (macrosData.protein * qty);
  } else {
    let cals = [], fats = [], proteins = [], _carbs = [];
    item.alternatives.forEach((alternativeItem) => {
      const doc = _.find(storedFoodRecipes, (d) => alternativeItem.ref.id === d.id);
      const macrosData = doc.macros;
      const qty = Number(alternativeItem.quantity);
      cals.push(macrosData.calories * qty);
      fats.push(macrosData.fat * qty);
      proteins.push(macrosData.protein * qty);
      _carbs.push(macrosData.carbs * qty);
    });
    calories = calories + _.mean(cals);
    fat = fat + _.mean(fats);
    carbs = carbs + _.mean(_carbs);
    protein = protein + _.mean(proteins);
  }
  return { calories, protein, fat, carbs };
};

export const calculateMealMacros = (storedFoodRecipes, doc) => {
  let groups = [];
  let groupsMeta = {}; 
  const mealMacros = doc.groups.reduce((out, group, index) => {
    const groupMacros = getGroupMacros(group, storedFoodRecipes);
    let groupData = { ...group, calories: groupMacros.calories };
    groups.push(groupData);
    groupsMeta[group.type] = { ...groupMacros };
    out.calories = out.calories + groupMacros.calories;
    out.fat = out.fat + groupMacros.fat;
    out.carbs = out.carbs + groupMacros.carbs;
    out.protein = out.protein + groupMacros.protein;
    return out;
  }, { calories: 0, fat: 0, carbs: 0, protein: 0 });
  return { ...doc, macros: mealMacros, groups, groupsMeta };
};

export const getGroupMacros = (meal, storedFoodRecipes) => {
  let out = { calories: 0, protein: 0, carbs: 0, fat: 0 };
  if (!meal.food_items || !meal.food_items.length) return out;
  meal.food_items.forEach((item) => {
    const { calories: _cals, fat: _fat, protein: _protein, carbs: _carbs } = getFoodItemMacros(item, storedFoodRecipes);
    out.calories = out.calories + _cals;
    out.fat = out.fat + _fat;
    out.carbs = out.carbs + _carbs;
    out.protein = out.protein + _protein;
  });
  return out;
};

export const autoMacrosCal = (autoCal, macros) => {
  let { fat, protein, carbs } = macros;
  switch(autoCal) {
    case "protein":
      return {
        usedCals: carbs * 4 + fat * 9,
        valid: !!carbs && !!fat
      }
    case "fat":
      return {
        usedCals: carbs * 4 + protein * 4,
        valid: !!protein && !!carbs
      }
    default:
      return {
        usedCals: protein * 4 + fat * 9,
        valid: !!protein && !!fat
      }
  }
}

export const changeCaloriesCalMacros = (autoCal, previousCals, macros) => {
  let { fat, protein, calories } = macros;
  switch (autoCal) {
    case "fat": {
      return {
        value: (calories - previousCals + macros[autoCal] * 9) / 4 + protein,
        macrosKey: "protein"
      }
    }
    default:
      return {
        value: ((calories - previousCals) + (macros[autoCal] * 4)) / 9 + fat,
        macrosKey: "fat"
      }
  }
}

export const changeCaloriesCalLastField = (autoCal, previousCals, macros) => {
  let { fat, protein, calories, carbs } = macros;
  switch (autoCal) {
    case "carbs": {
      return {
        val: (calories - previousCals + carbs * 4 + fat * 9) / 4 + protein,
        lastFieldKey: "protein"
      }
    }
    default:
      return {
        val: (calories - previousCals + protein * 4 + fat * 9) / 4 + carbs,
        lastFieldKey: "carbs"
      }
  }
}
// export const processMetaData = (doc, foodRecipes) => {
//   if(!doc || !doc.groups || !doc.groups.length) return doc;
//   const groupsMeta = doc.groups.reduce((prev, group) => {
//     if(!group) return prev;
//     // groupsMeta will be updated as we are already calculating each group's meta onChange in FE
//     let groupMeta = _.get(doc, `groupsMeta.${group.type}`, null);
//     // calculate if not particular groupMeta is not there 
//     if(!groupMeta || _.isEmpty(groupMeta)) groupMeta = getGroupMacros(group, foodRecipes);
//     return { ...prev, [group.type] : { ...groupMeta } };
//   }, {});
//   const meta = foodRecipes.reduce((prev, item) => {
//     return { ...prev, [item.id]: { macros: { ...item.macros } }};
//   }, {});
//   return {...doc, meta, groupsMeta };
// };
export const processMetaData=(currentDoc,storedFoodRecipeDocs)=>{
  if(!currentDoc || !currentDoc.groups || !currentDoc.groups.length) return currentDoc;
  //iterate doc.groups
  const meta={};
  const groupsMeta={};
  currentDoc.groups.forEach((grp)=>{
    const { food_items: grpFoods } = grp;
    if(grp){
      let groupMeta = _.get(currentDoc, `groupsMeta.${grp.type}`, null);
      if(!groupMeta || _.isEmpty(groupMeta)) groupMeta = getGroupMacros(grp, storedFoodRecipeDocs);
      groupsMeta[grp.type]={...groupMeta}
    }

    grpFoods.forEach((item) => {
      if(item.type==="food"){
        const id=item.ref.id;
        if(!meta[id]){
          const foodData=storedFoodRecipeDocs.find(i=>i.id===id);
          meta[id]={
            macros:{
              ...foodData.macros
            },
            groups:{}
          }  
        }
        if(!meta[id]["groups"][grp.type]){
          meta[id]["groups"][grp.type]={
            quantity:item.quantity
          }
        }
      }
      else{//alts
        item.alternatives.forEach((altItem)=>{
          const id=altItem.ref.id
          if(!meta[id]){
            const foodData=storedFoodRecipeDocs.find(i=>i.id===id);
            meta[id]={
              macros:{
                ...foodData.macros
              },
              groups:{}
            } 
          }
          if(!meta[id]["groups"][grp.type]){
            meta[id]["groups"][grp.type]={
              quantity:altItem.quantity
            }
          }
        });
      }
    });
  });
  return {...currentDoc,meta,groupsMeta}
}

export const migrateDietType = (publishedDoc) => {
  if(!publishedDoc || !publishedDoc.diet_type || publishedDoc.systags) return publishedDoc;
  let newDoc = _.cloneDeep(publishedDoc);
  newDoc = _.set(newDoc, 'systags', [_.get(publishedDoc, 'diet_type', "")]);
  return newDoc;
}
