import _ from "lodash";
import React from "react";
import { Typography, Chip } from "@material-ui/core";
import { hasLength } from "fitbud/utils/helpers";
import { calculateValues } from "fitbud/views/users/subComponents";
import moment from "moment";
import NoProfile from "fitbud/images/no_profile.svg";
import {
  QUES_TYPE_PROFILE, QUES_TYPE_MEASUREABLE, QUES_TYPE_TARGET, QUES_TYPE_SCALE,
} from "fitbud/utils/constants";
import clsx from 'clsx';

const IGNORE = [QUES_TYPE_PROFILE, QUES_TYPE_MEASUREABLE, QUES_TYPE_TARGET];

const QResponses = (props) => {
  const {
    measurableTags,
    measurableUnits,
    onboarding_response,
    unitSystem = "metric",
    unitDetails={},
    ques = [],
    overrides,
    companyBMR
  } = props;
  const { questionnaire } = overrides || {};
  function convertMeasurables(value, question) {
    switch (question.type) {
      case QUES_TYPE_TARGET:
      case QUES_TYPE_MEASUREABLE: {
        if (!hasLength(measurableTags)) return;
        return calculateValues({
          value,
          unitSystem,
          unitDetails,
          measurableUnits,
          unit: measurableTags[question.sub_type].unit_type
        });
      }
      case QUES_TYPE_SCALE: {
        return `${value} / ${question.step || 3}`;
      }
      case "start_date": {
        return moment(value).format("DD MMM, YYYY");
      }
      case QUES_TYPE_PROFILE: {
        switch (question.sub_type) {
          case "height": {
            return calculateValues({
              value,
              unitSystem,
              unitDetails,
              measurableUnits,
              unit: question.sub_type
            });
          }
          case "dob": {
            return moment(value).format("DD MMM, YYYY");
          }
          default:
            return (value || "").trim();
        }
      }
      default:
        return value;
    }
  }
  const quesSource = questionnaire ? questionnaire.questions : ques ; // Feed question from overrides if present
  const questions = _.map(quesSource, (q, i) => {
    if (!onboarding_response || !onboarding_response[q.id] || IGNORE.includes(q.type))
      return null;
    else
      return q
    }).filter(Boolean)

  if (!questions || !questions.length || !onboarding_response || _.isEmpty(onboarding_response)) {
    return (
      <div className='w-100 py-5 px-3 h-100 bg-white flex-1 d-flex flex-column align-items-center justify-content-center'>
        <img src={NoProfile} alt="No Profile" />
        <Typography variant="h5" align='center' className="fmt-20">
          Nothing to see here right now
        </Typography>
      </div>
    );
  }
  return questions.map((q, i) => {
    let onBoardingValue = Array.isArray(onboarding_response[q.id]) ?
      onboarding_response[q.id][0] :
      onboarding_response[q.id];
    if ((typeof onBoardingValue) === 'object')
      onBoardingValue = onBoardingValue.text;
    else if (q.sub_type && q.sub_type.startsWith('bmr'))
      onBoardingValue = ''; // treat a malformed BMR answer as unanswered
      if (q.type === "photos") return null; //skip questionnaire when question type photos
      return (
        <div className={clsx(i === (questions.length - 1) ? "" : "fmb-20")} key={`${i}-${q.id || 'wtf'}`}>
          <Typography
            color="textSecondary"
            className="fmb-10 font_13_500"
          >
            {q.sub_type && q.sub_type.startsWith('bmr') ? _.get(companyBMR[q.sub_type], "text", "") : q.text}&nbsp;
            {q.type === 'scale' && q.min && q.max ? `(Rate between \u201C${q.min}\u201D and \u201C${q.max}\u201D)` : ''}
          </Typography>
          {q.type === "multi_select" ? (
            <div>
              {onboarding_response[q.id].map((opt, l) => (
                <Chip
                  key={l}
                  classes={{
                    root: `small fmr-15 fpy-5`,
                    label: "fpx-10"
                  }}
                  style = {{ height: "auto", marginTop: l > 0 ? '5px'  : '0px' }}
                  label={
                    <Typography className="font_14_500 text-wrap text-capitalize">
                      {opt}
                    </Typography>
                  }
                />
              ))}
            </div>
          ) : (
            <Typography
              variant="body1"
              style={{ whiteSpace: "pre-wrap" }}
            >
              {convertMeasurables( onBoardingValue, q)}
            </Typography>
          )}
        </div>
      );
  });
};

export default QResponses;
