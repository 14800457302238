import makeStyles from '@material-ui/core/styles/makeStyles';

const WARNING_COLOR = '#F29900';
const DANGER_COLOR = '#F14E3F';
const BG_WARNING = '#FFFBF4';
const BG_DANGER = '#FFF6F5DD';

export const useStyles = makeStyles(() => ({
  rootMD: {
    padding: '10px 20px',
    minHeight: '60px',
  },
  rootSM: {
    minHeight: 58,
    paddingLeft: 20,
    paddingRight: 12,
  },
  bgWarning: {
    backgroundColor: BG_WARNING,
  },
  bgDanger: {
    backgroundColor: BG_DANGER,
    margin: 'auto',
    minHeight: '30vh',
  },
  statusChipContainer: {
    borderRadius: 3,
    padding: ' 6px 12px ',
  },
  warningChipContainer: {
    padding: '6px 12px',
    backgroundColor: 'rgba(242, 153, 0, 0.1)',
    color: WARNING_COLOR,
  },
  dangerChipContainer: {
    padding: '6px 12px',
    background: '#F14E3F1A',
    color: DANGER_COLOR,
  },
  warningDivider: {
    height: 2,
    backgroundColor: WARNING_COLOR,
  },
  danderDivider: {
    height: 2,
    backgroundColor: DANGER_COLOR,
  },
  actionButton: {
    '& a': {
      "&:hover":{
        color: 'white',
      }
    },
  },
}));

export const useTrailProgressStyles = makeStyles(() => ({
  root: {
    height: 5,
    borderRadius: 100,
  },
  primary: {
    backgroundColor: WARNING_COLOR,
  },
  secondary: {
    backgroundColor: '#E5E5E5',
  },
  bar: {
    borderRadius: 100,
  },
}));
