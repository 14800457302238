import React, { useState, useEffect, useMemo, useRef, useContext } from 'react';
import { getLabel, getDType, getType } from 'fitbud/components/catalog/util';
import { DumbList, Placeholder, ComponentListSearch, listSecondLine } from '.';
import { useSnackbar } from 'notistack';
import useDebounce from 'fitbud/hooks/useDebounce';
import { fetchData } from 'fitbud/api';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { List, LinearProgress } from '@material-ui/core';
import { get, debounce, delay } from 'lodash';
import errIndicator from 'fitbud/images/errIndicator.svg';
import ArrowIcon from '@material-ui/icons/ArrowForward';
import CatalogFilter ,{getFilterKey} from 'fitbud/components/catalog-filter';
import { isFilterExists, getStoreKey } from 'fitbud/components/catalog/util';
import { DRAFT_STATES,PUBLISHED,EMPTY_DOC_WARN_MSG } from 'fitbud/utils/constants';
import { getExIcon } from 'fitbud/utils/catalog';
/*
supported kyeName:
meals : for meal  including food and macros based
foodMeals : for meal only  food based
workouts : for workouts only
cardio : for cardio only
schedules : for schedules only
wocardio : for workout and cardio
foodRecipesSupps : for food & recipes
mlSchedules : for meal schedules
supps : for supplements only
supplements : for daily supplements  plan
*/

const PER_PAGE = 15;

export const _parseListData = (keyName, renderSecondLine, showMediaType,showDraftInd) => {
  return (l) => {
    const obj = {
      primaryText: get(l, 'data.ref_name')||get(l,'data.title'),
      secondaryText: !!renderSecondLine ? renderSecondLine({ doc: l, keyName }) : listSecondLine({ doc: l, keyName }),
      src: get(l, 'data.thumbnail')
    };
    if (showMediaType) {
      const mediaType = get(l, 'data.media_type');
      obj['icon']=getExIcon({type:mediaType});
    }
    if(showDraftInd){
      const isDraft=get(l,'data.publish_status',PUBLISHED)===DRAFT_STATES['DRAFT_ONLY']
      if(!!isDraft){
        obj['icon']=errIndicator;
      }
    }
    return { ...obj };
  };
}


const SelectorCatalogList = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { keyName, showMediaType = false,showDraftInd=false, listProps, onClick, isFilter = true, renderSecondLine, searchHeader = true, query:queryProp='', filterState: filterStateProp, noResultFoundAction,noDataFoundAction, defaultFilter, ignoreFilter, today= null } = props;
  const [q, updateQ] = useState('');
  const [docs, setDocs] = useState([]);
  const [count, setCount] = useState(null);
  const [nextPage, setNextPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const { cid } = useContext(FirebaseAuthContext);
  const [isFilterOpen, toggleFilter] = useState(false);
  const [filterState, updateFilterState] = useState({});

  const debouncedSearchTerm = useDebounce(q, 500);

  const listRef = useRef({
    count,
    q,
    nextPage,
  });

  //for the components where search query come form props::
  useEffect(()=>{
    updateQ(queryProp)
  },[queryProp])

  //for the components where filter come from props
  useEffect(()=>{
    if(!!filterStateProp){
      applyFilter(filterStateProp)
    }
  },[filterStateProp])

  useEffect(() => {
    listRef.current = {
      count,
      q,
      nextPage,
      filterState,
    };
  }, [count, q, nextPage, filterState]);

  const _dType = useMemo(() => {
    return getDType(keyName);
  }, [keyName]);

  const _keyName = useMemo(() => {
    return getType(keyName);
  }, [keyName]);

  const queryFn = async ({ q, page, filters }) => {
    const _filterKey = getFilterKey(keyName);
    let _fOut = {...filters};
    if(defaultFilter){
      const appliedFilter = get(filters, _filterKey) || {};
      _fOut = {[_filterKey]: {...appliedFilter, ...defaultFilter }}
    }

    const res = await fetchData(_keyName, cid, page, q, PER_PAGE, _dType, getStoreKey(keyName), today, null, null, _fOut);
    return res.data;
  };

  const applyFilter = (filter) =>{
    updateFilterState(filter);
    delay(()=>{
        fetchPage(q,0);
    },500)
  };

  const fetchPage = async (query = q, page = nextPage) => {
    setLoading(true);
    const {filterState} = listRef.current;
    try {
      const res = await queryFn({ q: query, page, filters: filterState});
      const data = res.data;
      setDocs((docs) => (page ? docs : []).concat(res.data));
      setNextPage(res.data.length === PER_PAGE ? page + 1 : null);
      setCount(res.count || 0);
    } catch (err) {
      console.log('>>error in fetching list', err);
    }
    setLoading(false);
  };

  //handle query change and make network call, it will also load initial data
  useEffect(() => {
    fetchPage(debouncedSearchTerm, 0);
  }, [debouncedSearchTerm]);

  //handle query change
  const handleQueryChange = (value) => {
    updateQ(value);
  };

  //creating list data ui
  const parseListData = _parseListData(keyName, renderSecondLine, showMediaType,showDraftInd);
  const handleClick = (e, doc) => {
    //validation for Fitness Sched,Nutrition Sched Imports
    const publish_status=get(doc,'data.publish_status',PUBLISHED);
    if(publish_status===DRAFT_STATES['DRAFT_ONLY']){
      enqueueSnackbar(`This Item is yet to be published`, { variant: 'warning' });
      return;
    }
    if(get(doc,'data.isEmpty',false)){
      const msg=EMPTY_DOC_WARN_MSG(keyName);
      enqueueSnackbar(msg, { variant: 'warning' });
      return;
    }
    onClick(doc);
  };



  //handle scroll functionality
  const handleScroll = debounce((e) => {
    if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 100) {
      if (nextPage) fetchPage();
    }
  }, 500);


  const openFilter = () => {
    toggleFilter(true);
  };
  const closeFilter = () => {
    toggleFilter(false);
  };

  const _isEmpty = !loading && (!!debouncedSearchTerm || isFilterExists(filterState) ) && (!docs.length )
  const _isInitialEmpty = !loading && !debouncedSearchTerm && (!docs.length ) && !isFilterExists(filterState);


  return (
    <div className="flex-1 d-flex flex-column position-relative overflow-x-hidden">
      {!!searchHeader && <ComponentListSearch
        openFilter={openFilter}
        closeFilter={closeFilter}
        selector={true}
        loading={loading}
        isFilter={isFilter}
        keyName={keyName}
        callback={handleQueryChange}
        appliedFilter={isFilterExists(filterState)}
      /> 
      }
      {/* if search header is off then show loader here other wise ComponentListWrapper will handle this */}
      {loading && !searchHeader && <LinearProgress style={{zIndex:200}} className="position-absolute w-100 " />}
      {docs && !!docs.length && (
        <List
          disablePadding
          id={keyName}
          className="overflow-auto flex-1 bg-white"
          onScroll={(e) => {
            e.persist();
            handleScroll(e);
          }}
        >
          <DumbList {...listProps} parseListData={parseListData} onClick={handleClick} docs={docs} />
        </List>
      )}
      {!!_isInitialEmpty && <Placeholder noResultFoundAction={noDataFoundAction} keyName={keyName} isSearch={true} /> }
      {!!_isEmpty && <Placeholder noResultFoundAction={noResultFoundAction} keyName={keyName} isSearch={true} /> }
      {isFilterOpen && (
        <CatalogFilter
          filters={filterState}
          keyName={keyName}
          open={true}
          onSave={applyFilter}
          handleClose={closeFilter}
          ignoreFilter={ignoreFilter}
        />
      )}
    </div>
  );
};

export default SelectorCatalogList;


