import React from "react";
import { Typography } from "@material-ui/core";
// import NoMeal from "fitbud/images/no_meal.svg";
// import NoCardio from "fitbud/images/no_cardio.svg"; //Deprecated
// import NoExercise from "fitbud/images/no_exercise.svg"; //Deprecated
// import NoSchedule from "fitbud/images/no_schedule.svg";
import NoTrainer from "fitbud/images/trainer_no_schedule.svg";
import NoUser from "fitbud/images/no_selected_user.svg";
import BroadcastIcon from "fitbud/images/megaphone.svg";
import NoPlans from "fitbud/images/no_plans.svg";
import CardioImg from "fitbud/images/catalog/cardio.png";
import ExerciseImg from "fitbud/images/catalog/exercise.png";
import FoodImg from "fitbud/images/catalog/food.png";
import ScheduleImg from "fitbud/images/catalog/schedule.png";
import SupplementImg from "fitbud/images/catalog/supplements.png";
import NoExplore from "fitbud/images/no_explore.svg";
import ChallengesImg from "fitbud/images/catalog/challenges.png";
import ServiceImg from "fitbud/images/catalog/service.png";

import {VIEWS_CONSTANTS} from "fitbud/utils/constants";


const USER_TABS=['leads','plansToAssign','active','inactive'];

const DefaultInfo = props => {
  let {keyName,isDetailPg=false, label=""}=props;
  if(USER_TABS.includes(keyName)){
    keyName='user';
  }
  const getNoItemSvg = () => {
    let svg = "";
    switch (keyName) {
      case "foodRecipes":
      case VIEWS_CONSTANTS.MEAL:
      case VIEWS_CONSTANTS.MEAL_SCHEDULE:
      case VIEWS_CONSTANTS.FOOD:    
      case VIEWS_CONSTANTS.RECIPE: {
        svg = FoodImg;
        break;
      }   
      case VIEWS_CONSTANTS.SUPPLEMENT:
      case VIEWS_CONSTANTS.SUPPLEMENTS:{
        svg = SupplementImg;
        break;
      }
      case  VIEWS_CONSTANTS.EXERCISE:
      case  VIEWS_CONSTANTS.WORKOUT:{
        svg = ExerciseImg;
        break;
      }
      case VIEWS_CONSTANTS.CARDIO:{
        svg = CardioImg;
        break;
      }
      case VIEWS_CONSTANTS.SCHEDULE:{
        svg = ScheduleImg;
        break;
      }
      case VIEWS_CONSTANTS.EXPLORE_WORKOUT:{
        svg = NoExplore;
        break;
      }
      case VIEWS_CONSTANTS.EXPLORE_NUTRITION:{
        svg = NoExplore;
        break;
      }
      case "user":
      case "chats":
      case "checkins":{
        svg = NoUser;
        break;
      }
      case "trainer":{
        svg = NoTrainer;
        break;
      }
      case VIEWS_CONSTANTS.PLAN:{
        svg = NoPlans;
        break;
      }
      case "broadcast":{
        svg=BroadcastIcon;
        break;
      }
      case VIEWS_CONSTANTS.CHALLENGES:{
        svg=ChallengesImg;
        break;
      }
      case VIEWS_CONSTANTS.SERVICES:{
        svg=ServiceImg;
        break;
      }
      default:
        svg = "";
    }
    return svg;
  };

  const getLabel = (keyName) =>{
    switch (keyName) {
      case VIEWS_CONSTANTS.EXERCISE:
        return {
          heading: 'Select an Exercise to View',
          subHeading: 'Please select an exercise from the list to view the details',
        };
      case VIEWS_CONSTANTS.WORKOUT:
        return {
          heading: 'Select a Workout to View',
          subHeading: 'Please select a workout from the list to view the details',
        };
      case VIEWS_CONSTANTS.CARDIO:
        return {
          heading: 'Select a Cardio to View',
          subHeading: 'Please select a cardio from the list to view the details',
        };
      case VIEWS_CONSTANTS.SCHEDULE:
      case VIEWS_CONSTANTS.MEAL_SCHEDULE:  
        return {
          heading: 'Select a Schedule to View',
          subHeading: 'Please select a schedule from the list to view the details',
        };
      case VIEWS_CONSTANTS.FOOD:
        return {
          heading: 'Select a Food Item to View',
          subHeading: 'Please select a food item from the list to view the details',
        };
      case VIEWS_CONSTANTS.RECIPE:
        return {
          heading: 'Select a Recipe  to View',
          subHeading: 'Please select a recipe  from the list to view the details',
        };
      case VIEWS_CONSTANTS.MEAL:
        return {
          heading: 'Select a Meal Plan to View',
          subHeading: 'Please select a meal plan from the list to view the details',
        };
      case VIEWS_CONSTANTS.SUPPLEMENT:
        return {
          heading: 'Select a Supplement  to View',
          subHeading: 'Please select a supplement  from the list to view the details',
        };
      case VIEWS_CONSTANTS.SUPPLEMENTS:
        return {
          heading: 'Select a Supplement Plan to View',
          subHeading: 'Please select a supplement plan from the list to view the details',
        };
      case VIEWS_CONSTANTS.EXPLORE_WORKOUT:
        return {
          heading: 'Select a Workout Program/ Collection to View',
          subHeading: 'Please select a Workout Program/ Collection from the list to view the details',
        };
      case VIEWS_CONSTANTS.EXPLORE_NUTRITION:
        return {
          heading: 'Select a Nutrition Plan/ Collection to View',
          subHeading: 'Please select a Nutrition Plan/ Collection from the list to view the details',
        }
      case VIEWS_CONSTANTS.USER:
      case VIEWS_CONSTANTS.CHECKINS:
        return {
          heading: 'Please select a user from the list.',
        };
      case VIEWS_CONSTANTS.CHATS:
        return {
          heading: 'Please select a message thread to view.',
        };
      case VIEWS_CONSTANTS.BROADCAST:
        return {
          heading: 'Send messages to multiple clients at once.',
          subHeading:'To start sending, select the audience from the left panel.'
        };
      case VIEWS_CONSTANTS.PLAN:
        return { heading: 'Select a Payment Plan to View', subHeading: 'Please select a payment plan from the list to view the details' };
      case VIEWS_CONSTANTS.CHALLENGES:
        return { heading: 'Challenges & Leaderboards', subHeading: 'Introducing the Challenges. Empower your training sessions, foster community spirit, and lead with measurable success.' };
      case VIEWS_CONSTANTS.SERVICES:
        return { heading: '1 on 1 Sessions: Customize and Offer More', subHeading: 'Offer more than just fitness — provide personalized services like consultations and specialized therapies. Customize your offerings to meet each client’s needs, with easy payment options built right in.' };
      default:
        return { heading: '', subHeading: '' };
    }
   
  }

  const _label = getLabel(keyName);

  return (
    <div className=" h-100 text-center d-flex align-items-center justify-content-center flex-column ">
      <img alt="noitem" src={getNoItemSvg()} />
      <Typography className="font_18_600 fmt-30 w-50">
      {!!label ? label : 
        <>
          {_label.heading}
          {isDetailPg && <Typography className="font_14_500 fmt-5 text-dark-grey">{_label.subHeading}</Typography>}
        </>
      }
        
      </Typography>
    </div>
  );
};

export default DefaultInfo;
