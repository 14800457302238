import { Typography, MenuItem, Select, OutlinedInput, makeStyles } from '@material-ui/core';
import Dialog from 'fitbud/components/Dialog';
import React, { useState, useMemo, useEffect, useContext } from 'react';
import { FormTextField } from 'fitbud/components/form-fields';
import DropDownIcon from '@material-ui/icons/ExpandMore';
import { fakeEvent, getBrowserTimeZone } from 'fitbud/utils/helpers';
import _ from 'lodash';
import { DatePicker } from '@material-ui/pickers';
import { DatePickerField } from 'fitbud/views/groupClasses/createEditForm/scheduling';
import { isPreviousDay } from 'fitbud/views/groupClasses/helper';
import moment from 'moment';
import appRdxFun from 'fitbud/redux/app';
import { useDispatch } from 'react-redux';
import { oprtnlDateFormat } from 'fitbud/utils/constants';
import { useSnackbar } from 'notistack';
import { bffInvitePurchase } from 'fitbud/api';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { RoleContext } from 'fitbud/providers/roleProvider';
import clsx from 'clsx';
import { AccessContext } from 'fitbud/providers/access-provider';

const CUSTOM_OPTION = { duration: 6, duration_type: 'weeks', id: 'custom', mode: 'one_time' };

export const getPricingOption = (id, pack_id, options) => {
  const pack = _.find(options, (p) => p.pack_id === pack_id);
  let option = _.find(pack?.price_opts || [], (o) => o.id === id);
  if (!option) {
    //ie case of subscription packs with custom id.
    option = {
      id: CUSTOM_OPTION.id,
      duration: CUSTOM_OPTION.duration,
      duration_type: CUSTOM_OPTION.duration_type,
      mode: 'one_time',
      ref_name: pack?.ref_name,
      title: pack?.title,
      pack_id,
    };
  }

  return { pack, option };
};

const GrantAccessDialog = (props) => {
  const { id, onClose, packOptions, userProfileId, data, onSuccess } = props;
  const isNew = id === 'new';
  const dispatch = useDispatch();
  let { userProfile, comp } = useContext(FirebaseAuthContext);
  const [pricingOption, setPricingOption] = useState(() => {
    const pack = data?.pack;
    if (_.isEmpty(pack) || isNew) return pack;
    const { id, pack_id } = pack;
    return { pack_id, id };
  });
  const [validity, setValidity] = useState(data?.dateExpiry ? new Date(data.dateExpiry) : new Date());
  const { showLoader, hideLoader } = appRdxFun(dispatch);
  const { enqueueSnackbar } = useSnackbar();
  const company = comp ? comp.data() : {};
  const companyTz = _.get(company, 'profile.time_zone');
  const loggedUserTz = _.get(userProfile, 'time_zone', '');

  const onPlanChange = (e) => {
    const value = e.target.value;
    const [id, pack_id] = value.split(',');
    setPricingOption({ id, pack_id });
    const { pack, option } = getPricingOption(id, pack_id, packOptions);
    const { duration, duration_type } = option;
    let validity_date = new Date();
    if (option.mode === 'one_time') {
      validity_date = moment().add(duration, duration_type).subtract(1, "days");
    }
    setValidity(validity_date);
  };
  const handleValidity = (time) => {
    const _start = moment(time);
    setValidity(_start);
  };

  const onSubmit = async () => {
    const start_date = data?.start_date || moment().format(oprtnlDateFormat);
    const end_date = moment(validity).format(oprtnlDateFormat);
    const pack_id = pricingOption?.pack_id;
    const price_id = pricingOption?.id;
    const pid = userProfileId;
    let payload = { pid, pack_id, price_id, start_date, end_date };
    if (!isNew) payload.purchase_key = id;
    // return;
    showLoader();
    try {
      const time_zone = loggedUserTz || companyTz || getBrowserTimeZone() || '';
      const response = await bffInvitePurchase(payload, time_zone);
      const { success, message } = response?.data;
      if (success) {
        if (onSuccess) await onSuccess();
        enqueueSnackbar(message, { variant: 'success' });
        hideLoader();
        onClose && onClose();
      } else {
        throw new Error(message);
      }
    } catch (err) {
      console.log('>>>> error in submit', err);
    }
  };

  return (
    <Dialog
      open
      toolbarClass="height-60"
      paperClass={'width-600'}
      buttonColor="primary"
      buttonSize="f-medium"
      appBarColor="bg-grey-new"
      titleFont="h3"
      onClose={onClose}
      onSave={onSubmit}
      actionText="Save"
      title={isNew ? 'Grant Access' : 'Edit Access'}>
      <div className="p-20">
        <div className="info-box p-15 mb-15">
          <Typography className="font_13_500 lineht_1_5">
           Grant clients access to a membership plan, add-on, or session pack, providing them with immediate access to on-demand content, group classes, and 1 on 1 sessions included in the package.
          </Typography>
        </div>
        <PlanField
          disabled={!isNew}
          allowCustom={false}
          priceDoc={pricingOption}
          noCustom
          packOptions={packOptions}
          onChange={onPlanChange}
        />
        <FormTextField label="Validity" fullWidth>
          <DatePicker
            allowKeyboardControl
            animateYearScrolling={true}
            disablePast={true}
            onChange={handleValidity}
            value={validity}
            name="validity"
            format="MMM D, YYYY"
            inputVariant="outlined"
            TextFieldComponent={DatePickerField}
            keyboardButtonProps={{
              'aria-label': 'date',
            }}
            // disabled={!isNew}
            disabled={!!isPreviousDay(validity)}
          />
        </FormTextField>
      </div>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  height_unset: {
    height: 'unset !important',
  },
}));

//TODO: Same as Crete user time, that was too dependent to create user. should  make common ?
export const PlanField = ({ priceDoc, onChange, packOptions, disabled }) => {
  const classes = useStyles();
  const { gcEnabled } = useContext(RoleContext);
  const list = useMemo(() => {
    const out = [];
    if (!packOptions || !packOptions.length) return out;
    packOptions.forEach((plan) => {
      if (!plan || !plan.price_opts || !plan.price_opts.length) return;
      if (!plan.active && !plan.activeV2 && !plan.hidden) return;
      if (!gcEnabled && plan.type === 'add_on' && plan.add_on_type !== 'membership') return; //if group class disabled then add on type group class pack will be ignore.
      let hasSub = false,
        count = out.length;
      plan.price_opts.forEach((pricing) => {
        if (!pricing || !(pricing.active || pricing.activeV2) || !pricing.mode) return;
        if (pricing.mode === 'subscription') {
          hasSub = true;
          return;
        }
        out.push({
          label: plan.ref_name,
          pack_id: plan.pack_id,
          id: pricing.id,
          duration: pricing?.duration,
          duration_type: pricing?.duration_type,
        });
      });
      if (count === out.length && hasSub)
        out.push({
          label: plan.ref_name,
          pack_id: plan.pack_id,
          id: 'custom',
          duration: CUSTOM_OPTION.duration,
          duration_type: CUSTOM_OPTION.duration_type,
        });
    });
    return out;
  }, [packOptions, priceDoc]);

  useEffect(() => {
    if (_.size(list) && _.isEmpty(priceDoc)) {
      //if list ready and not one selected than set by default one.
      const opt = list[0];
      const { pack_id, id } = opt;
      const value = id + ',' + pack_id;
      _.delay(() => {
        onChange(fakeEvent('pack', value));
      }, 200);
    }
  }, [list, priceDoc]);

  const renderValue = (value) => {
    const [id, pack_id] = value.split(',');
    const { pack, option } = getPricingOption(id, pack_id, packOptions || []);
    const _option = {
      label: pack?.title || "Custom",
      duration: option?.duration,
      duration_type: option?.duration_type,
      pack_id,
      id,
    };
    return <PackOption pack={_option} className={clsx("py-12")} />;
  };
  return (
    <FormTextField fullWidth label={'Select Pack'}>
      <Select
        IconComponent={DropDownIcon}
        value={`${priceDoc?.id},${priceDoc?.pack_id}`}
        renderValue={renderValue}
        disabled={!!disabled}
        input={
          <OutlinedInput
            classes={{
              root: clsx(classes.height_unset),
            }}
            name="pack"
          />
        }
        onChange={onChange}
        required>
        {list.map((option, index) => {
          const { pack_id, id } = option;
          return (
            <MenuItem className={clsx(classes.height_unset)} key={`${index}-${pack_id}-${id}`} value={id + ',' + (pack_id || '')}>
              <PackOption pack={option} className="py-2" />
            </MenuItem>
          );
        })}
      </Select>
    </FormTextField>
  );
};

const PackOption = (props) => {
  const { pack, className } = props;
  const { label, pack_id, id, duration, duration_type } = pack;
  const {access} = useContext(AccessContext)
  const _subText = useMemo(() => {
    const service_config = _.get(access, `packs.${pack_id}.service_config`,{});
    const config = _.get(service_config, id) || _.get(service_config, "all") || {};
    const {count , frequency} = config; 
    const _d = duration + ` ` + duration_type;
    const _call = count ? `${count} sessions ${frequency === "plan"?"" :`per ${frequency}`}` : "";
    return  `${_call ? _call + " • " :""} ${_d}`;
  }, [pack_id, id, duration, duration_type, access]);
  return (
    <div className={clsx(className)}>
      <div>
        <Typography className="font_15_500 text-0d0d0d mb-6">{label}</Typography>
        <Typography className="font_13_500 text-grey">{_subText}</Typography>
      </div>
    </div>
  );
};

export default GrantAccessDialog;
