import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import UsePublications from './usePublications';
import UseTrack from './useTrack';
import UseParticipantIsReconnecting from './useParticipantIsReconnecting';
import AudioLevelIndicator from './audioLevelIndicator';
import AvatarContainer from './avatarContainer';
import useVideoContext from './useVideoContext';
import UseIsTrackEnabled from './useIsTrackEnabled';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      height: '100%',
      display: 'flex',
      overflow: 'hidden',
      position: 'relative',
      // background: '#202124',
      alignItems: 'center',
      paddingTop: 'calc(56.25% - 2px)',
      borderRadius: (props) => (props.isViewEnabled ? "" : "10px")
    },
    innerContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    infoContainer: {
      position: 'absolute',
      zIndex: 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      width: '100%',
      background: 'transparent',
      top: (props) => (props.isViewEnabled ? "-66px" : 0)
    },
    avatarContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1
    },
    reconnectingContainer: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(40, 42, 43, 0.75)',
      zIndex: 1,
    },

    identity: {
      color: 'white',
      padding: '0.18em 0.3em',
      margin: 0,
      display: 'flex',
      alignItems: 'center',
    },
    infoRowBottom: {
      display: 'flex',
      justifyContent: 'space-between',
      width: "100%",
      alignItems: "center",
      position: 'absolute',
      bottom: 0,
      left: 0,
    },

    hideParticipant: {
      display: 'none',
    },
  })
);

const ParticipantInfo = ({
  participant,
  onClick,
  isSelected,
  children,
  isLocalParticipant,
  hideParticipant
}) => {
  const [name, setName] = useState("");
  const publications = UsePublications(participant);
  const {
    getUserRef,
    sessionData,
    isViewEnabled,
  } = useVideoContext();

  const audioPublication = publications.find(p => p.kind === 'audio');
  const videoPublication = publications.find(p => p.trackName.includes('camera'));

  const isVideoEnabled = Boolean(videoPublication);

  const videoTrack = UseTrack(videoPublication);

  const audioTrack = UseTrack(audioPublication);
  const isParticipantReconnecting = UseParticipantIsReconnecting(participant);
  const participantIdentity = participant.identity;

  const isTrackEnabled = UseIsTrackEnabled(videoTrack);
  const props = {
    isViewEnabled: isViewEnabled,
  };

  const classes = useStyles(props);

  useEffect(() => {
    Promise.all([
      getUserRef(participantIdentity),
    ])
      .then(([userDoc]) => {
        if(userDoc.data()) {
          setName(userDoc.data().name)
        }else{
          setName(sessionData.name)
        }
      })
      .catch((err) => {

      });
  }, [participantIdentity]);

  let userName = _.truncate(
    name, {
       'length': 25,
     }
  );

  return (
    <div className={clsx(classes.container, {
      [classes.hideParticipant]: hideParticipant,
    })}
    onClick={onClick}
    data-cy-participant={participant.identity}
    >
      <div className={classes.infoContainer}>
        <div className={classes.infoRowBottom}>
          <span className={classes.identity}>
            <AudioLevelIndicator audioTrack={audioTrack} />
            <Typography variant="body1" className={classes.typeography} component="span">
              {isLocalParticipant && !isSelected ? 'You' : props.isViewEnabled && userName}
            </Typography>
          </span>
        </div>
      </div>
      <div className={classes.innerContainer}>
        {(!isTrackEnabled) && (
          <div className={classes.avatarContainer}>
            <AvatarContainer isLocal={isLocalParticipant} identity={userName} audioTrack={audioTrack} />
          </div>
        )}
        {isParticipantReconnecting && (
          <div className={classes.reconnectingContainer}>
            <Typography variant="body1" className={classes.typeography}>
              Reconnecting...
            </Typography>
          </div>
        )}
        {children}
      </div>
    </div>
  );
}

export default ParticipantInfo;
