import React, { useEffect,useContext, useState, useReducer, useMemo } from 'react';
import _ from 'lodash';
import update from 'immutability-helper';
import { ClrdButton } from 'fitbud/components/form-fields';
import Dialog from 'fitbud/components/Dialog';
import {
  TextField,
  DialogContent,
  Typography,
  Divider, Tooltip,
  InputAdornment
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { FormTextField } from 'fitbud/components/form-fields';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import IngredientInput from './ingredients-input';
import { reorder,DUMB_UNITS } from 'fitbud/utils/helpers';
import { Add as AddIcon, SentimentVeryDissatisfied } from '@material-ui/icons/';
import { useSnackbar } from 'notistack';
import { SimpleNumberInput } from 'fitbud/components/numberInput';
import {RecipeContext} from "fitbud/views/foodRecipes/foodRecipesInfo";
import {roundNumber} from "fitbud/utils/helpers";
// import {IngredientForm} from "./ingredientForm";
import { DEFAULT_ERROR ,PUBLISHED} from 'fitbud/utils/constants';
import appRdxFns from "fitbud/redux/app";
import { useDispatch } from 'react-redux';
import {findStandardUnit} from "fitbud/views/foodRecipes/newUi/inputDropdown";
import useServings from 'fitbud/hooks/useServings';
import * as Sentry from '@sentry/browser';
import { Conversion } from 'fitbud/providers/conversion';
import FoodRecipeSelector from 'fitbud/components/catalogSelector/foodRecipeSelector';
import Loader from 'fitbud/components/CircularLoader';
import FoodInfo from "fitbud/views/foodRecipes/foodRecipesInfo";
import {getDbValue} from "fitbud/views/foodRecipes/newUi/inputDropdown";

export const getFactor=({data,serving,fallback=true})=>{//data is fb-doc not meta
  const {serving_options=[]}=data;
  const found=serving_options.find(i=>i.id===serving);
  if(!!found){
    return found.serving_size||(fallback?1:0);
  }
}

export const toggleServingType=(serving_type)=>{
  if(serving_type==="meal_weight") return "meal_volume";
  else if(serving_type==="meal_volume") return "meal_weight";
}

const default_ingredient = {
  title: '',
  items:[]
};

const IngredientsEditor = (props) => {
  const d = useDispatch();
  const {
    ingredients,
    meta={},
    index: ingredientIndex,
    onSave,
    onClose,
    initial: isNew = false,
    servingData,
    addMore,
    isCategoriesRecipes,
    toggleDirty,
    isFirstNoneCategory,
    N,
  } = props;
  const { unitFactors } = useContext(Conversion);
  const [_sortServing,n,n2,dumbUnits] = useServings({servingData,separateFlOz:true});
  const {fetchedIngredients,updateFetchedIngredients,fetch}=useContext(RecipeContext);
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useReducer((os, ns) => ({ ...os, ...ns }), {});
  const [mounted,toggleMount]=useState(false);
  const [metaState, setMetaState] = useState({...meta})
  const [errors, setErrors] = useState([]);
  const [titleError, setTitleError] = useState('');
  const [isServingOptionsFormOpen,toggleServingOptionsForm]=useState(null);
  const [fetchLoader,toggleFetchLoader]=useState(false);
  const [isFoodSelectorOpen,toggleFoodSelector]=useState(false);
  const { title, items=[], prep_time } = state;
  const _prep_time = !!prep_time ? prep_time / 60 : "";
  const checkIfServingStale=(doc,value)=>{
    if(!doc) return true;
    if(!value) return false;//intentionally selected none
    return !(getDataWExtraServingOptions(doc).serving_options).find(i=>i.id===value);
  }
  const additionalServingOptions=useMemo(()=>{
    //creating dumbunits factors
    if(!!unitFactors && !!dumbUnits){
      return dumbUnits.map(i=>({
        id:i.id,
        label:i.value,
        serving_size:getDbValue(unitFactors,1,i.id)
      }));
    }
    return [];
  },[unitFactors,dumbUnits]);
  const getDataWExtraServingOptions=(data={})=>{
    return {
      ...data,
      serving_options:[
        ...(data.serving_options||[]),
        ...(additionalServingOptions||[])
      ]
    }
  }
  useEffect(()=>{
    //sanitize 
    if(!!mounted) return;
    if(state.items && meta){
      const needFetchedIng=state.items.find(i=>!!i.ref_id);
      if(needFetchedIng && !Object.keys(fetchedIngredients).length) return;
      toggleMount(true);
      const latestItems=[];
      items.forEach((i)=>{
        const metaData=_.get(meta,i.ref_id,null);
        const docData=_.get(fetchedIngredients,i.ref_id,null);
        const updatedItem={
          ...i,
          name:docData?docData.title:i.name,
        };
        if(!!i.ref_id){
          const isServingStale=checkIfServingStale(docData,i.serving_unit);
          if(!!isServingStale){
          //fallback
          const standardUnit=findStandardUnit(docData.serving_type,_sortServing);
          const toggleStandardUnit=findStandardUnit(toggleServingType(docData.serving_type),_sortServing);
          let serving_size=i.serving_size;
          let serving_unit=i.serving_unit;
          if(i.serving_unit===""){}
          else if(i.serving_unit===standardUnit.id){}
          else if(i.serving_unit===toggleStandardUnit.id){
            //change in serving_type
            serving_unit=standardUnit.id;
            toggleDirty(true);
          }
          else{
              let factor=_.get(metaData,`options.${i.serving_unit}.factor`,null);
              if(factor===0) factor=1;//for calculations only
              if(factor){
                serving_size=i.serving_size*factor;
                serving_unit=standardUnit.id;
                toggleDirty(true);
              }
          }
          updatedItem['serving_size']=serving_size;
          updatedItem['serving_unit']=serving_unit;
          }
        }
        latestItems.push(updatedItem);
      });
      setState({items:[...latestItems]});
    }
  },[state.items])
  const isTitleRequired = useMemo(()=>{
    if (isNew) return false;
    if (N > 1) return true;
    if (isFirstNoneCategory && ingredientIndex === 0 && N <= 1) return false;
    return true;
  },[isNew, N, ingredientIndex, isFirstNoneCategory ])


  useEffect(() => {
    if (addMore) {
      //action for add new one
      setState({ ...default_ingredient, title : '' }); //NEW
    } else {
      const ingredient = _.get(ingredients, ingredientIndex, default_ingredient);
      setState({ ...ingredient });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const valid = () => {
    let out = true;
    let errors = [];
    if (isTitleRequired) {
      if (!state.title) {
        const errMsg = 'Please provide a title';
        setTitleError(errMsg);
        enqueueSnackbar(errMsg, {
          variant: 'error',
        });
        out = false;
      }
    }
    if(!(state.items||[]).length){
      const errMsg = 'Please provide an ingredient';
      out = false;
      enqueueSnackbar(errMsg, {
        variant: 'error',
      });
    }
    items.forEach((item, i) => {
      errors[i] = {};
      if (!item.name) {
        const errMsg = 'Please provide an ingredient';
        errors[i].name = errMsg;
        out = false;
        enqueueSnackbar(errMsg, {
          variant: 'error',
        });
      }
      if ( !!item.serving_unit &&  !item.serving_size) {
        const errMsg = 'Please specify the quantity of the each ingredient';
        errors[i].serving_size = errMsg;
        out = false;
        enqueueSnackbar(errMsg, {
          variant: 'error',
        });
      }
    });
    setErrors(errors);
    return out;
  };
  
  const handleFoodCb=(values)=>{
    if(!!values){
      const {ref_id,index}=isServingOptionsFormOpen;
      const item=items[index];
      const {serving_options=[]}=values;
      updateFetchedIngredients({[ref_id]:{...values}});
      //updateMeta-update factors in meta in case of change in food factors
      const metaFoodOptions=metaState[ref_id].options||{};
      if(!!Object.keys(metaFoodOptions).length){
        const latestMetaFoodOptions=_.cloneDeep(metaFoodOptions);
        Object.keys(metaFoodOptions).forEach(m=>{
          const servingOption=serving_options.find(i=>i.id===m);
          if(!!servingOption){
            latestMetaFoodOptions[m].factor=servingOption.serving_size;
          }
        });
        setMetaState(o=>({
          ...o,
          [ref_id]:{
            ...o[ref_id],
            options:{...latestMetaFoodOptions}
          }
        }));
      }
      const isCurrentServingUnitUsed=serving_options.find(i=>i.id===item.serving_unit);
      if(!isCurrentServingUnitUsed){
        const standardUnit=findStandardUnit(values.serving_type,_sortServing);
        handleServingChange(index,standardUnit,standardUnit.id);
      }
    }
    toggleServingOptionsForm(null);
  }
  
  const onSubmit = () => {
    if (!valid()) return;
    let _updatedIngredients;
    const isInvalidIngredients = !state.items || !state.items.length; //if not have items or items is empty
    if (addMore) {
      _updatedIngredients = update(ingredients, {
        $push: [{ ...state }],
      });
    } else if (isInvalidIngredients) {
      _updatedIngredients = update(ingredients, {
        $splice: [[ingredientIndex, 1]],
      });
    } else if (ingredientIndex !== undefined) {
      _updatedIngredients = update(ingredients, {
        $splice: [[ingredientIndex, 1, { ...state }]],
      });
    }
    //sanitize meta
    const _updatedIngFoodIds={};
    _updatedIngredients.forEach(i=>{
      const items=i.items||[];
      items.forEach(j=>{
        if(j.ref_id){
          if(!_updatedIngFoodIds[j.ref_id]){
            _updatedIngFoodIds[j.ref_id]=[j.serving_unit];
          }
          else{
            _updatedIngFoodIds[j.ref_id].push(j.serving_unit);
          }
        }
      });
    });
    let updatedMetaState=_.pick(metaState,[...Object.keys(_updatedIngFoodIds)]);
    Object.keys(updatedMetaState).forEach(i=>{
      updatedMetaState[i].options=_.pick(updatedMetaState[i].options,_updatedIngFoodIds[i]);
    })
    const out = { ingredientsv2: _updatedIngredients,meta:updatedMetaState };
    return out;
  };

  const handleChange = (e) => {
    const key = e.target.id || e.target.name;
    let val = e.target.value;
    if (key === 'title') setTitleError('');
    if(key === "prep_time"){
      val = Math.max(0, parseInt(val || 0)) * 60
    }
    setState({ [key]: val });
    toggleDirty(true);
  };

  const handleAdd = () => {
    toggleFoodSelector(true);
  };
  const closeFoodForm=()=>{
    toggleFoodSelector(false);
  }

  const fetchItem=async(ids=[])=>{
    try{
      const promise=ids.map(i=>fetch(i));
      const docs=await Promise.all(promise);
      return docs;
    }
    catch(err){
      //add sentry log
      enqueueSnackbar("Unable to fetch selected item",{variant:"error"});
      Sentry.captureException(err);
    }
  }
  //DONE
  const handleSelectItem=async({data:options=[]},
    // isEdit=false
    )=>{
    toggleFoodSelector(false);//close food form
    toggleFetchLoader(true);//set loader
    const newIds=options.filter(o=>!items.some(i=>i.ref_id===o._id));
    //fetch Data
    const fetchedDocs=await fetchItem(newIds.map(i=>i._id));//fetch full data
    const newItems=[];
    const newMeta={...metaState};
    const newFetchedDocs={}
    newIds.forEach((n,i)=>{
      const data=fetchedDocs[i].data();
      const {title,serving_options=[],serving_type,type}=data;
      const standardUnitId=findStandardUnit(serving_type,_sortServing).id;
      const serving=_.get(serving_options,0,null);
      //data
      const obj={
        name:title,
        serving_size:1,
        serving_unit:serving?serving.id:standardUnitId,//default -- check for empty case or gm case
        ref_id:n._id
      }
      newItems.push(obj);
      newFetchedDocs[n._id]={...data}
      //meta
      if(!newMeta[n._id]){
        newMeta[n._id]={
          name:title,
          type,
          serving_type,
          options:{}
        }
      }
      if(!!serving){
        _.set(newMeta,`${n._id}.options.${serving.id}.factor`,serving.serving_size);
      }
    });
    const updatedItems=update(state.items,{
      $push:[...newItems]
    });
    setState({ items: updatedItems });
    setMetaState(o=>({
      ...o,
      ...newMeta
    }));
    updateFetchedIngredients({...newFetchedDocs});
    toggleFetchLoader(false);
    toggleDirty(true);
  }
  const handleServingChange=(index,standardUnit={},v,_servingSize)=>{
    let item={...state.items[index]};
    const metaData=_.get(metaState,item.ref_id,{});
    const fetchedData=_.get(fetchedIngredients,item.ref_id,{});
    const oldServingUnit=item.serving_unit;
    //-----conditions----
    if(oldServingUnit===v) return;
    if(v==="others"){
      //open edit form
      toggleServingOptionsForm({
        action:"edit",
        ref_id:item.ref_id,
        index
      });
      return;
    }
    if (v === "" || oldServingUnit === "") {
      item = {
        ...item,
        serving_size: _servingSize,
        serving_unit: v,
      };
      if (!!v && !DUMB_UNITS.includes(v)) {
        _.set(metaData, `options.${v}.factor`, getFactor({data:fetchedData, serving:v, fallback:false}));
      }
    }
    else {
      //serving to serving
      const unitValue = item.serving_size * getFactor({data:getDataWExtraServingOptions(fetchedData),serving:oldServingUnit});//value in gm/ml
      const newFactor = getFactor({data:getDataWExtraServingOptions(fetchedData), serving:v,fallback:false});
      item = {
        ...item,
        serving_unit: v,
        serving_size: unitValue / (newFactor||1),
      };
      if(!DUMB_UNITS.includes(v)){
        _.set(metaData, `options.${v}.factor`, newFactor);
      }
    }
    const updatedItems=update(state.items,{
      $splice:[[index,1,item]]
    });
    setState({items:[...updatedItems]});
    setMetaState(o=>({
      ...o,
      [item.ref_id]:{...metaData}
    }));
    toggleDirty(true);
    setErrors([]);
  }
//only handles serving_size
  const onChange = (index,e,standardUnit) => {
    const chng = { [e.target.name]: e.target.value };
    const { items } = state;
    let _items;
    if (e.target.type === 'number' && e.target.value === '') {
      e.target.value = 0;
    }
    if (e.target.name === 'serving_size') {
      const metaData=_.get(metaState,items[index].ref_id,{});
      const value = Number(e.target.value);
      _items = update(items, {
        [index]: {
          serving_size: {
            $set: value,
          },
        },
      });
    } else {
      _items = update(items, {
        [index]: {
          $merge: chng,
        },
      });
    }

    setState({ items: _items });
    setErrors([]);
    toggleDirty(true);
  };

  const onRemove = (index) => {
    const { items } = state;
    let _items = update(items, {
      $splice: [[index, 1]],
    });
    setState({ items: _items });
    toggleDirty(true);
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const { items } = state;
    const _items = reorder(items, result.source.index, result.destination.index);
    setState({ items: [..._items] });
    toggleDirty(true);
  };
  // const closeIngForm=()=>toggleServingOptionsForm(null);
  
  const selectedFoodItems=useMemo(()=>{
    const arr=[];
    items.forEach(i=>{
      if(i.ref_id){
        arr.push({
          data:{..._.get(fetchedIngredients,i.ref_id,{})},
          _id:i.ref_id,
          disabled:true
        });
      }
    });
    return arr;
  },[items,fetchedIngredients]);
  return (
    <Dialog
      toolbarClass="height-60"
      buttonColor="primary"
      open
      onClose={()=>onClose(onSubmit)}
      onSave={()=>onSave(onSubmit)}
      title="Ingredients"
      titleFont="h3"
      appBarTextColor="text-black"
      paperClass="width-780 height-60"
    >
      <DialogContent className=" h-100 p-0 position-relative">
      {fetchLoader && <Loader size={24} centerAlign={true} style={{zIndex:9}}/>}
        <div className="px-20 py-20 d-flex align-items-center">
          <FormTextField fullWidth label={`Section Title ${isTitleRequired ? '' : '(optional)'}`} classes={{control:"mr-20"}}>
            <TextField
              id="title"
              name="title"
              value={title}
              placeholder="Eg. 'For the Gravy' or 'For the Icing'"
              onChange={handleChange}
              required
              type="text"
              variant="outlined"
              error={titleError}
              InputProps={{
                classes: {
                  root: 'medium',
                  input: 'size_16_500 medium',
                },
              }}
            />
          </FormTextField>
          <FormTextField fullWidth label="Preparation Time (optional)" classes={{control: "mr-0"}}>
            <SimpleNumberInput
              id="prep_time"
              name="prep_time"
              type="number"
              placeholder="0"
              onChange={handleChange}
              value={_prep_time}
              decimalLimit={0}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Minutes</InputAdornment>
                ),
                classes: {
                  root: "medium",
                  input: "size_16_500",
                },
              }}
            />
          </FormTextField>
          <Tooltip title='If your recipe has more than one parts, like "Cake & Icing", then you can provide separate Title and Prep Time for each section of ingredients'>
            <InfoIcon className='ml-10 mt-20 text-adornments'/></Tooltip>
        </div>
        <Divider />
        <div className="p-20">
          <div className="mb-20">
            <Typography className="font_16_600">Add Ingredients</Typography>
          </div>
          <ItemsEditor
            onChange={onChange}
            errors={errors}
            _sortServing={_sortServing}
            dumbUnits={dumbUnits}
            items={items}
            handleAdd={handleAdd}
            // onServingChange={onServingChange}
            onRemove={onRemove}
            handleOnDragEnd={handleOnDragEnd}
            handleSelectItem={handleSelectItem}
            handleServingChange={handleServingChange}
            // handleCreateNewIng={handleCreateNewIng}
            metaState={metaState}
            loading={fetchLoader}
          />
          <div className="d-flex w-100 justify-content-center">
            <ClrdButton
              classes={{ label: 'font_15_600' }}
              variant="contained"
              color="invert"
              className="f-meduim border-0 shadow-none"
              onClick={handleAdd}
            >
              <AddIcon /> {!items.length?" Add Ingredients":" Add More"}
            </ClrdButton>
          </div>
        </div>
        {isServingOptionsFormOpen && (
          <FoodInfo
          id={isServingOptionsFormOpen.ref_id}
          foodServingFormOnly={true}
          handleFoodOnlyCb={handleFoodCb}
          />
          // <IngredientForm 
          // onClose={closeIngForm} 
          // onSave={handleSaveIngr}
          // servingData={servingData}
          // {...isServingOptionsFormOpen}
          // />
        )}
         {isFoodSelectorOpen && <FoodRecipeSelector
          handleClose={closeFoodForm}
          open={true}
          handleAdd={(obj)=>handleSelectItem(obj)}
          selectedFoodItems={selectedFoodItems}
          defaultFilter={{ dtype: ['food'] }}
          filterKeyName="foods"
        />}
      </DialogContent>
    </Dialog>
  );
};

const ItemsEditor = (props) => {
  const { items, metaState, _sortServing,dumbUnits, errors, onChange, onRemove,
     handleOnDragEnd,handleSelectItem,handleServingChange,
    //  handleCreateNewIng,
     loading } = props;
  return (
    <div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => {
            return (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {items.map((item, index) => {
                  const ref_id=_.get(item,'ref_id','');
                  return (
                    <Draggable draggableId={`__dragId${index}`} key={`__dragId${index}`} index={index}>
                      {(provided, snapshot) => (
                          <IngredientInput
                          loading={loading}
                          error={errors[index]}
                          key={item.ref_id?item.ref_id:index}
                          item={item}
                          index={index}
                          _sortServing={_sortServing}
                          dumbUnits={dumbUnits}
                          onSelect={handleSelectItem}
                          onServingSizeChange={onChange}
                          onRemove={onRemove}
                          inputProps={{
                            'data-index': index,
                          }}
                          enqueueSnackbar={props.enqueueSnackbar}
                          provided={provided}
                          snapshot={snapshot}
                          data={_.get(metaState,ref_id,{})}
                          handleServingChange={handleServingChange}
                          // handleCreateNewIng={handleCreateNewIng}
                          />
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default IngredientsEditor;
