import NoMeal from 'fitbud/images/no_meal.svg';
import NoCardio from 'fitbud/images/no_cardio.svg'; 
import NoExercise from 'fitbud/images/no_exercise.svg';
import NoSchedule from 'fitbud/images/no_schedule.svg';
import NoPlans from 'fitbud/images/no_plans.svg';
import SchedulesPlaceholder from "fitbud/images/scheduleEditorPlaceholder.svg";
import NoResources from "fitbud/images/no_resources.svg";
import NoExMlSchedule from "fitbud/images/no_explore_ml_schedules.svg";
import NoChallenges from "fitbud/images/no_challenges.svg";
import {
  cardioRdxFns, 
  exRdxFns,
  mealRdxFns,
  planRdxFns,
  frsRdxFns,
  suppsRdxFns,
  dailySuppRdxFns,
  woRdxFns,
  woCardioRdxFns,
  schRdxFns,
  mlSchRdxFns,
  frRdxFns,
  foodRdxFns,
  recipeRdxFns,
  chllngsRdxFn
} from "fitbud/redux/exports";
//Deprecated unused svg

import CardioImg from "fitbud/images/catalog/cardio.png";
import ExerciseImg from "fitbud/images/catalog/exercise.png";
import FoodImg from "fitbud/images/catalog/food.png";
import ScheduleImg from "fitbud/images/catalog/schedule.png";
import SupplementImg from "fitbud/images/catalog/supplements.png";

export const isFilterExists = (filterState) => {
  const filterValues = Object.values(filterState || {}); //filter structure will be like {[key<server_key>]:{key:[values]}}
  if (!filterValues || !filterValues.length) return 0;
  const _selectedValues = Object.values(filterValues[0]);
  const _selectedFilters = [];
  (_selectedValues || []).forEach((values) => {
    _selectedFilters.push(...values);
  });
  return (_selectedFilters || []).length;
};

export const getLabel = (key) => {
  switch (key) {
    case 'foodRecipes':
    case 'foodRecipesSupps':
      return 'food & recipes';
    case 'foods':return 'Food Items';
    case 'supps':
      return 'supplements';
    case 'wocardio':
      return 'workouts';
    case 'meals':
    case 'foodMeals':  
      return 'meal plans';
    case 'supplements':
      return 'supplement plans';
    case 'mlSchedules':
      return 'schedules';

    // case "workouts":
    // case "exercises":
    // case "cardio":
    // case "schedules":
    // case "plans":
    default:
      return key;
  }
};

export const getNoItemSvg = (key) => {
  let svg = '';
  switch (key) {
    case 'food':
    case 'foodRecipesSupps':
    case 'foodRecipes':
    case 'meals':
      svg = FoodImg;
      break;
    case 'supps':
      case 'supplements':
        svg = SupplementImg;
        break;
    case 'workouts':
    case 'exercises':
      svg = ExerciseImg;
      break;
    case 'cardio':
      svg = CardioImg;
      break;
    case 'schedules':
      svg = ScheduleImg;
      break;
    case 'mlSchedules':
      svg = FoodImg;
      break;
    case 'plans':
      svg = NoPlans;
      break;
    case 'explore_schedules':
      svg = SchedulesPlaceholder  
      break;
    case 'explore_workout':
      svg = NoExercise;
      break;  
    case 'explore_ml_schedules':
      svg = NoExMlSchedule;
      break;  
    case 'resources':
      svg = NoResources;
      break;  
    case 'challenges':
      return NoChallenges;  
    default:
      svg = '';
  }
  return svg;
};


export const getType = (keyName) =>{
  if(keyName === "supps" ||keyName === "foodRecipesSupps" ) return  "foodRecipes";
  if(keyName === "cardio" || keyName === "wocardio") return "workouts";
  if(keyName === "foodMeals") return "meals";
  else return keyName;

};

export const getStoreKey= (keyName) =>{
  if( keyName === "recipes" || keyName ==="foods" || keyName ==="foodRecipes") return "foodRecipesSupps";
  if(keyName === "foodMeals") return "meals"
  if(keyName === "workouts") return "wocardio"
  return keyName;
  // if(keyName === "foodRecipesSupps" || keyName === "recipes" || keyName ==="foods") return "foodRecipesSupps";
  // if(keyName === "meals") return "meals";
  // if(keyName === "supps") return "supps";
  // if(keyName === "supplements") return "supplements";
  // if(keyName === "mlSchedules") return "mlSchedules";
  // if(keyName === "exercises") return "exercises";
  // if(keyName === "workouts") return "workouts";
  // if(keyName === "cardio") return "cardio";
  // if(keyName === "schedules") return "schedules";
}



export const getDType = (keyName) =>{
  if(keyName === "foodRecipesSupps") return ["food", "recipe", "fdc"];
  else if (keyName === "supps") return ["supplement"];
  else if (keyName === "cardio") return ["cardio"];
  else if (keyName === "workouts") return ["workout"];
  else if(keyName === "foodMeals") return ["food"];
  else return null;
}


export const  getCatalogRdxFn=(d)=>{
  return{
      supps: { ...suppsRdxFns(d) },
      foodRecipes: { ...frRdxFns(d) },
      foodRecipesSupps: { ...frsRdxFns(d) },
      exercises: { ...exRdxFns(d) },
      cardio: { ...cardioRdxFns(d) },
      meals: { ...mealRdxFns(d) },
      supplements: { ...dailySuppRdxFns(d) },
      workouts: { ...woRdxFns(d) },
      wocardio: { ...woCardioRdxFns(d) },
      schedules: { ...schRdxFns(d) },
      mlSchedules: { ...mlSchRdxFns(d) },
      plans: { ...planRdxFns(d) },
      foods:{...foodRdxFns(d)},
      recipes:{...recipeRdxFns(d)},
      challenges:{...chllngsRdxFn(d)}
  }
}