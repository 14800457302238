import _ from "lodash";
import React, { useState } from "react";
import {
  TextField,
  MenuItem,
  Grid,
} from "@material-ui/core";
import { FormTextField } from "fitbud/components/form-fields";
import Dialog from "fitbud/components/Dialog";
import ImageFileUpload from "fitbud/components/imageSelector";
import { ExpandMore as KeyDown } from "@material-ui/icons/";
import Confirmation from "../../components/confirmationDialog"
import { roundNumber } from "fitbud/utils/helpers";
import useServings from "fitbud/hooks/useServings";
import NumberInput from "fitbud/components/numUnitInput";
import {CustomConfirmation} from "fitbud/components/customConfirmation";

const SupplementForm = props => {
  const [isConfirmationOpen, showConfirmation] = useState(null);
  const [dirty, setDirty] = useState(false);
  const {
    doc,
    errors,
    loading,
    type,
    onSubmit,
    handleChange,
    onCancel,
    servingData,
    isNew,
    handleServingUnit,
    handleDraft,
    handlePublish,
    draftFeature,
    isDraftAvailable
  } = props;
  const {
    title,
    ref_name,
    description,
    thumbnail,
    serving,
    link,
    serving_size,
    serving_type
  } = doc;

  const [sortServing, selectedServingId, selectedHouseholdName] = useServings({servingData, selected:serving,separateFlOz:true});

  const handleChangeWrapper = e => {
    setDirty(true)
    handleChange(e)
  };

  const setDirtyOnChange = () => {
    setDirty(true)
  }

  const openConfirmationDialog = () => {
    dirty ? showConfirmation('cancel') : onCancel();
  }
  const handleSubmit=()=>{
    if(!isNew && !dirty && !isDraftAvailable){
      onCancel();
      return;
    }
    if(isNew|| !draftFeature){
      onSubmit();
    }
    else{
      showConfirmation('save');
    }
  }
  return (
    <Dialog
      toolbarClass="height-60"
      buttonColor="primary"
      open
      onClose={openConfirmationDialog}
      onSave={handleSubmit}
      title={`${isNew ? "Add" : "Edit"} ${_.capitalize(type)}`}
      titleFont="h3"
      paperClass="width-600"
    >
      <div className="fp-20">
        <div className="d-flex fmb-20">
          <div className="flex-1 fmr-20 d-flex flex-column justify-content-between">
            <FormTextField fullWidth label="Reference Name" required>
              <TextField
                id="ref_name"
                autoComplete="off"
                defaultValue={ref_name}
                onBlur={handleChange}
                onChange={setDirtyOnChange}
                error={!!errors.ref_name}
                helperText={errors.ref_name}
                variant="outlined"
                disabled={loading}
                required
                type="text"
                InputProps={{
                  classes: {
                    root: "medium",
                    input: "size_16_500 medium",
                  },
                }}
              />
            </FormTextField>
            <FormTextField fullWidth label="Display Name" required classes={{control:"mb-0"}}>
              <TextField
                id="title"
                autoComplete="off"
                defaultValue={title}
                onBlur={handleChange}
                onChange={setDirtyOnChange}
                error={!!errors.title}
                helperText={errors.title}
                variant="outlined"
                disabled={loading}
                required
                type="text"
                InputProps={{
                  classes: {
                    root: "medium",
                    input: "size_16_500 medium",
                  },
                }}
              />
            </FormTextField>
          </div>
          <ImageFileUpload
            thumbnail={thumbnail}
            id="thumbnail"
            onChange={handleChangeWrapper}
            size="small"
          />
        </div>
        <Grid container className="fp-0">
          <Grid item xs={6} className="fp-0 fpr-10">
            <FormTextField fullWidth label="Serving Name">
              <TextField
                type="text"
                onChange={handleChangeWrapper}
                value={selectedServingId}
                select
                error={!!errors.serving}
                helperText={errors.serving}
                variant="outlined"
                required
                SelectProps={{
                  name: "serving",
                  IconComponent: KeyDown,
                  inputProps: {
                    classes: {
                      icon: "fpl-5",
                    },
                  },
                }}
                InputProps={{
                  classes: {
                    root: "medium",
                    input: "size_16_500 medium select-medium",
                  },
                }}
              >
                {sortServing.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.value}
                  </MenuItem>
                ))}
              </TextField>
            </FormTextField>
          </Grid>
          <Grid item xs={6} className="fp-0 fpl-10">
            <NumberInput  
              fullWidth 
              label={`1 ${selectedHouseholdName} equals (Optional)`}
              id="serving_size"
              autoComplete="off"
              placeholder="N/A"
              value={roundNumber(serving_size)}
              error={!!errors.serving_size}
              helperText={errors.serving_size}
              onChange={handleChangeWrapper}
              onUnitChange={handleServingUnit}
              variant="outlined"
              unit={serving_type || "meal_weight"}
              required
              ignoreInitial={true}
              InputProps={{
                inputProps: {
                  min: 0,
                },
                classes: {
                  root: "medium",
                  input: "size_16_500",
                },
              }}    
            />
          </Grid>
        </Grid>

        <FormTextField fullWidth label="Link">
          <TextField
            id="link"
            onBlur={handleChange}
            onChange={setDirtyOnChange}
            defaultValue={link}
            error={!!errors.link}
            helperText={errors.link}
            variant="outlined"
            disabled={loading}
            required
            InputProps={{
              classes: {
                root: "medium",
                input: "size_16_500",
              },
            }}
          />
        </FormTextField>
        <FormTextField fullWidth label="Description" classes={{control:"mb-0"}}>
          <TextField
            multiline
            rows="2"
            rowsMax="10"
            id="description"
            fullWidth
            onBlur={handleChange}
            onChange={setDirtyOnChange}
            defaultValue={description}
            error={!!errors.description}
            helperText={errors.description}
            variant="outlined"
            disabled={loading}
            required
            InputProps={{
              classes: {
                root: "large",
                input: "size_16_500",
              },
            }}
          />
        </FormTextField>
      </div>
      {isConfirmationOpen && (
        <>
          {(isNew||!draftFeature)?
            <Confirmation
            open
            handleClose={() => showConfirmation(null)}
            handleChange={() => onCancel()}
            handleCancel={() => showConfirmation(null)}
          />:
          <CustomConfirmation
            handleClose={() =>showConfirmation(null)}
            handleDiscard={onCancel}
            handleDraft={handleDraft}
            handlePublish={handlePublish}
            dirty={dirty}
            showDiscard={dirty}
          />
          }
        </>
      )}
    </Dialog>
  );
};

export default SupplementForm;
