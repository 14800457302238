import React, {useContext} from "react";
import _ from "lodash";
import { Tabs, Tab, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import {
  USER_VIEW_ACTIVATION_TABS,
  INITIAL_USER_VIEW_TABS,
  USER_VIEW_ASSIGN_TABS
} from "fitbud/utils/constants";
import { isPlansToAssignUser, isSelfSignupUser } from "./helpers";

const styles = makeStyles(theme => ({
  indicator: {
    height: "4px"
  },
  text: {
    fontSize: "15px",
    fontWeight: 500
  },
  tab: {
    minWidth: "unset",
    maxWidth: "fit-content",
    padding: "6px 15px"
  },
  tabs:{
    height: '44px',
  }
}));

const merge = (a, b, i=0) => {
  return a.slice(0, i).concat(b, a.slice(i));
}

const SlideButton = ({ value, handleChange, userDoc, loading }) => {
  //view Checkin Tab display if plan assigned
  const TABS = !userDoc.aplan ? INITIAL_USER_VIEW_TABS : merge(INITIAL_USER_VIEW_TABS, [...USER_VIEW_ASSIGN_TABS, ...USER_VIEW_ACTIVATION_TABS], 4);
  const classes = styles();
  return (
    <Tabs
      value={value}
      onChange={handleChange}
      indicatorColor="primary"
      textColor="primary"
      aria-label="action tabs example"
      variant="scrollable"
      scrollButtons="auto"
      classes={{
        root: clsx("flex-grow-1", ),
        flexContainer: clsx(classes.tabs),
        indicator: loading ? "d-none" : classes.indicator
      }}
    >
      {TABS.map(tab => (
        <Tab
          disableRipple
          key={tab.i}
          value={tab.i}
          label={tab.name}
          className="text-capitalize size_15_500"
          classes={{
            root: clsx(classes.tab, "fpy-8"),
            wrapper: clsx(classes.text, (tab.i === value) ? 'text-primary font-weight-600' : 'text-black'),
          }}
        />
      ))}
    </Tabs>
  );
};
export default SlideButton;
