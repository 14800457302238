import { useState, useCallback } from 'react';

const useStateUpdate = (initialValue) => {
  const [state, setState] = useState(initialValue);
  const updateKeys = useCallback((...args) => setState(x => {
    if (!args || !args.length) return;
    if (args.length % 2 !== 0) throw new Error('Wrong invocation');
    const out = {...x};
    for (let i = 0; i < args.length; i += 2) {
      const key = args[i];
      const valOrCB = args[i+1];
      out[key] = (typeof valOrCB === 'function') ? valOrCB(x[key]) : valOrCB;
    }
    return out;;
  }), [setState]);
  return [state, updateKeys, setState];
};

export default useStateUpdate;
