import React, { useContext, useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import {
  Card, CardContent, Typography, Divider, IconButton, LinearProgress,
} from '@material-ui/core';
import {DEFAULT_PFC, DEFAULT_MEAL_GRPS} from 'fitbits/bmr/constants';
import { TagsContext } from 'fitbud/providers/tagsProvider';
import {useToggle} from 'fitbud/hooks/form';
import EditIcon from 'fitbud/icons/detailEdit';
import BMRForm from 'fitbud/components/bmrForm';

const getMeal = (editable, fbUser, data) => {
  if (editable) {
    if (fbUser && fbUser.bmr)
      return {
        mode: '%', sort_by: fbUser.bmr.mealOrder,
        groups: fbUser.bmr.mealSplit,
        ...fbUser.bmr.macroSplit,
      };
  } else {
    const {groups, macros = {}} = data || {};
    const {calories: cals, protein: p, fat: f, carbs: c} = macros;
    if (cals && p && f && c)
      return {
        mode: 'abs', cals, p, f, c,
        groups: groups || DEFAULT_MEAL_GRPS,
      };
  }
  return {
    mode: '%',
    ...DEFAULT_PFC,
    sort_by: 'time',
    groups: DEFAULT_MEAL_GRPS.map((type, index) => {
      return {
        type, order: index + 1, calories: 33.33,
      };
    }),
  };
};

const BMRMealInfo = (props) => {
  const {fbUser, data} = props;
  const [edit, toggleEdit] = useToggle();
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const editable = useMemo(() => fbUser && fbUser.bmr && fbUser.bmr.hasBMR, [fbUser]);
  const [meal, setMeal] = useState(() => {
    return getMeal(true, fbUser, data);
  });
  const updateSplits = (data, activityOverride, goalOverride, calsOverride) => {
    if (activityOverride) fbUser.bmr.overrideActivity(activityOverride);
    if (goalOverride && goalOverride.length) fbUser.bmr.overrideGoal(...goalOverride);
    fbUser.bmr.overrideCals(calsOverride);
    fbUser.bmr.updateSplits(data);
    setMeal(getMeal(true, fbUser, null));
    toggleEdit();
  };
  const { mealCategories, fetchTags } = useContext(TagsContext);

  useEffect(() => {
    if (mealCategories && mealCategories.length)
      return setLoading(false);
    fetchTags('mealCategories');
  }, [mealCategories, fetchTags]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return (
      <LinearProgress/>
    );
  }

  const { cals, mode, p, f, c, groups } = meal;
  return (
    <div className='fpx-30 fpt-30 fpb-20'>
      <Calculations fbUser={fbUser} />
      <div className='d-flex align-items-center justify-content-between fmt-10 fpy-15'>
        <Typography caption='h6' component='div' className='fp-0 fpr-10'>
          Macro Split
        </Typography>
        <Divider className='flex-1 fp-0' />
        {editable && <IconButton className='fmr-n10' onClick={toggleEdit}><EditIcon/></IconButton>}
      </div>
      <div className='d-flex flex-wrap fpt-10'>
        {!editable && mode === 'abs' && <Macro value={cals} title='Calories' />}
        <Macro value={p} mode={mode} title='Protien' />
        <Macro value={f} mode={mode} title='Fat' />
        <Macro value={c} mode={mode} title='Carbs' />
      </div>
      <div className='d-flex align-items-center justify-content-between fmt-10 fpy-15'>
        <Typography caption='h6' component='div' className='fp-0 fpr-10'>
          Meal Split
        </Typography>
        <Divider className='flex-1 fp-0' />
        {editable && <IconButton className='fmr-n10' onClick={toggleEdit}><EditIcon/></IconButton>}
      </div>
      <div className='d-flex flex-column fpt-10'>
        {groups.map((grp, index) => {
          return (<Meal
            key={`${index}-${grp.type || '?'}`}
            mealCategories={mealCategories}
            meal={grp} mode={mode}
          />);
        })}
      </div>
      {edit && <BMRForm onClose={toggleEdit} 
        onSubmit={updateSplits} compBMR={fbUser.comp.data().bmr}
        noDetails={fbUser.comp.data().features.bmr === 'laura252'}
        data={meal} fbBMR={fbUser.bmr}
        enqueueSnackbar={enqueueSnackbar}
        />}
    </div>
  );
};

const Macro = ({mode, title, value}) => {
  const v = Math.round(value || 0);
  return (
    <div className='d-flex flex-column fmr-30 fpr-20'>
      <Typography variant='caption' color='primary' className='fpb-10'>
        {title}
      </Typography>
      <div className='d-flex align-items-center fmb-10'>
      <Typography variant='h2'>{v} {mode === '%' ? '%' : 'g'}</Typography>
      </div>
    </div>
  );
};

const Meal = ({mode, mealCategories, meal}) => {
  const { type, ifTime, calories } = meal || {};
  const mealName = useMemo(() => {
    if (!mealCategories || !mealCategories.length) return '';
    for (let i = 0; i < mealCategories.length; i++) {
      const [id, { value }] = mealCategories[i];
      if (id === type) return value;
    }
    return '';
  }, [mealCategories, type]);
  if (!mealName) return null;
  return (
    <Card className='fp-30 fmb-30'>
      <CardContent className='fp-0 d-flex justify-content-between align-items-center position-relative row'>
        <div className='d-flex align-items-baseline'>
          <Typography variant='h4' className='fpl-30 mr-3' component='div'>
            {mealName}
          </Typography>
          {ifTime && <Typography variant='caption' color='textSecondary' >
            <i className='far fa-clock mr-1' aria-hidden='true'></i>
            {moment(ifTime, ['HH.mm']).format('hh:mm a')}
            </Typography>}
        </div>
        <Typography variant='h5' className='fpr-30'>
          {Math.round(calories)} {mode === '%' ? '%' : 'g'}
        </Typography>
      </CardContent>
    </Card>
  );
}

const Calculations = ({ fbUser }) => {
  const targetCals = (!fbUser || !fbUser.bmr || !fbUser.bmr.hasBMR) ? false :
    fbUser.bmr.targetMeal.macros.calories;

  if (!targetCals) {
    return (
      <Typography variant='h4' className='fmb-10 p-3 warning-red'>
        Client's profile is incomplete, prompt them to complete their profile and answer activity and goal questions{' '}
        in order for BMR calculations to kick in.
      </Typography>
    );
  }

  return (
    <div className='d-flex align-items-center justify-content-between'>
      <Typography className='font_16_600'>Calculated Target Calories</Typography>
      <div className='flex-1' />
      <Typography className='font_16_600'>{Math.round(targetCals)} cals</Typography>
    </div>
  );
};

export default BMRMealInfo;
