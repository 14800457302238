import { bindActionCreators } from "redux";
import axnsBuilder from "./actions";
const axns = axnsBuilder();
const actionCreators = {
  showLoader: () => ({ type: axns.showLoader }),
  hideLoader: () => ({ type: axns.hideLoader }),
  setCID: cid => ({ type: axns.setCID, cid }),
  setProfile: doc => ({ type: axns.setProfile, doc }),
  showEnqueueSnackBar: (notifications) => ({type: axns.showEnqueueSnackBar, notifications}),
};

export default dispatch => bindActionCreators(actionCreators, dispatch);
