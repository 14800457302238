import React, { useState } from "react"
import {
  IconButton, makeStyles, Tooltip, Menu, MenuItem, Select,
  OutlinedInput,
} from '@material-ui/core';
import useDevices from './useDevices';
import CircularLoader from "fitbud/components/CircularLoader";
import DropDownIcon from "@material-ui/icons/ExpandMore";
import { SettingIcon } from "fitbud/icons/settingIcon";
import { FormTextField } from "fitbud/components/form-fields";
import AudioInputList from "./audioInputList";
import AudioOutputList from "./audioOutputList";
import VideoInputList from "./videoInputList";

const useStyles = makeStyles(() => ({
  buttonContainer: {
    border: "1px solid #E0E0E0",
    width: "50px",
    height: "50px"
  },
  backgroundColor: {
    '&:hover': {
      backgroundColor: 'transparent!important',
    }
  }
}));

const SettingMenu = props => {
  const classes = useStyles(props);
  const { onClose, ...rest } = props;

  return (
    <Menu 
      {...rest} 
      onClose={onClose} 
      PaperProps={{ style:{ width: 210 }}} 
      MenuListProps={{ className: 'p-15' }}
    >
      <AudioInputList className='mb-15' />
      <AudioOutputList className='mb-15' />
      <VideoInputList />
    </Menu>
  );
};

const DeviceSelection = (props) => {
  const { isLocal } = props;
  const classes = useStyles(props);
  const [profileEl, setProfileEl] = useState(null);
  const toggleSettingMenu = e => {
    setProfileEl(prev => (!!prev ? null : e.currentTarget));
  }


  return (
    <div style={{ position: "absolute", right: "0px" }}>
      <Tooltip
        title={'Settings'}
        placement="top"
        PopperProps={{ disablePortal: true }}
      >
        <IconButton
          className={`${classes.buttonContainer} fmr-20`}
          onClick={toggleSettingMenu}
          style={{ color: isLocal ? "#fff" : "#221F1F" }}
        >
          <SettingIcon />
        </IconButton>
      </Tooltip>

      <SettingMenu
        anchorEl={profileEl}
        open={!!profileEl}
        onClose={toggleSettingMenu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: -18, horizontal: "right" }}
        transformOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </div>
  )
}

export default DeviceSelection;