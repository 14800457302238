import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const DownloadIcon = (props) => (
  <SvgIcon
    width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1.33301 9.83331V14.8333C1.33301 15.2753 1.5086 15.6993 1.82116 16.0118C2.13372 16.3244 2.55765 16.5 2.99967 16.5H12.9997C13.4417 16.5 13.8656 16.3244 14.1782 16.0118C14.4907 15.6993 14.6663 15.2753 14.6663 14.8333V9.83331" stroke="#317FF5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.99967 11.5L10.9163 8.58333M7.99967 1.5V11.5V1.5ZM7.99967 11.5L5.08301 8.58333L7.99967 11.5Z" stroke="#317FF5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </SvgIcon>
);

export default DownloadIcon;
