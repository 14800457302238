import _ from "lodash";
import React, {useState, useEffect, useMemo, useContext} from "react";
import { connect } from "react-redux";
import * as Sentry from '@sentry/browser';
import firebase from "fitbud/firebase";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import staffRdxFns from 'fitbud/redux/staff';
import LoaderError from "fitbud/components/loaders-errors";
import Convertor from 'fitbud/utils/convertor';
import configRdxFns from "fitbud/redux/config";

export const Conversion = React.createContext(undefined);
Conversion.displayName = 'ConversionContext';

const render = (config, retry, children) => {
  if (!config)
    return <LoaderError className="min-vh-100" />;
  if (_.isError(config))
    return <LoaderError className="min-vh-100" error retry={retry}/>;
  return children;
}

const ConversionProvider = (props) => {
  const { userProfile: user } = useContext(FirebaseAuthContext);
  if (_.isEmpty(props.selfStaffDoc))
    props.setStaff(user)
  const {
    unit_system: userUnitSystem,
    unit_details: userUnitPrefs
  } = props.selfStaffDoc || {};
  const [unitFactors, setFactors] = useState(undefined);
  const [unitOptions, setOptions] = useState(undefined);
  const fetch = () => {
    let unmounted = false;
    if(unmounted) return;
    if (!unitFactors) setFactors(undefined);
    Promise.all([
      firebase.firestore().collection("config").doc("unit_options_custom").get(),
      firebase.firestore().collection("config").doc("units").get()
    ]).then(([options, factors]) => {
      if(unmounted) return;
      setOptions(options.data())
      setFactors(factors.data())
      props.insertConfig(factors.data());
    }).catch(err => {
      if(unmounted) return;
      setFactors(err);
      Sentry.captureException(err);
    })
    return () => unmounted = true;
  }
  useEffect(fetch, []);
  const convertor = useMemo(() => new Convertor(unitFactors, unitOptions, userUnitSystem, userUnitPrefs),
    [unitFactors, unitOptions, userUnitSystem, userUnitPrefs]);
  return (
    <Conversion.Provider value={{convertor,unitFactors}}>
      {render(unitFactors, fetch, props.children)}
    </Conversion.Provider>
  );
}

const mapStateToProps = s => ({selfStaffDoc: s.staff.doc});
const mapDispatchToProps = d => {
  const { insert: insertConfig } = configRdxFns(d);
  return ({...staffRdxFns(d), insertConfig})
};  
export default connect(mapStateToProps, mapDispatchToProps)(ConversionProvider);
