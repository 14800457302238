import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const StoreFrontIcon = props => {
  const {stroke, ...rest} = props;
  return (
    <SvgIcon viewBox="0 0 20 20" classes={{root: 'small empty'}} {...rest}>
      <path stroke={stroke || '#8F969F'} d="M6.66669 17.5H13.3334" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path stroke={stroke || '#8F969F'} d="M10 14.1667V17.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path stroke={stroke || '#8F969F'} d="M16.6667 2.5H3.33335C2.41288 2.5 1.66669 3.24619 1.66669 4.16667V12.5C1.66669 13.4205 2.41288 14.1667 3.33335 14.1667H16.6667C17.5872 14.1667 18.3334 13.4205 18.3334 12.5V4.16667C18.3334 3.24619 17.5872 2.5 16.6667 2.5Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </SvgIcon>
  );
};
