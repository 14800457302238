import React from 'react';
import { Card, CardContent, IconButton, CardHeader, Divider } from '@material-ui/core';
import { MoreLessTextBtn } from 'fitbud/components/moreLessText';
import { DetailPgStyling } from 'fitbud/hooks/useDetailPgStyle';
import FoodHeader from 'fitbud/views/foodRecipes/newUi/foodHeader';
import DetailEditIcon from 'fitbud/icons/detailEdit';
import addIcon from 'fitbud/images/plus.svg';
import { EditorPlaceholder } from 'fitbud/views/exercises/editorPlaceholder';
import SupplementsForm from './supplementsForm';
import { get } from 'lodash';
import clsx from 'clsx';
import {DraftBanner} from "fitbud/components/draftBanner";
import { DRAFT } from "fitbud/utils/constants";


function Details(props) {
  const {
    doc,
    oldDoc,
    scheduleDoc,
    displayEditOption,
    foodRecipes,
    mealCategories,
    serving,
    supplementEditorMode,
    isImport = false,
    checkImportSupplementGroup,
    onSelectImportSupplements,
    handleEditMode,
    disableActions,
    onDelete,
    onCopy,
    setSupplementEditorMode,
    draftBannerProps={},
    isPreview
  } = props;
  const _description = get(doc, 'description', '').trim();
  const groups = get({ ...oldDoc, ...scheduleDoc }, 'groups', []);
  const isDraft=draftBannerProps.showingDoc===DRAFT;
  return (
    <DetailPgStyling>
      {draftBannerProps.isDraftAvailable && !isPreview && <DraftBanner {...draftBannerProps} doc={doc} />}
      {!isImport && (
        <Card square elevation={0}>
          <FoodHeader
            doc={{ ...oldDoc, ...scheduleDoc }}
            handleEditMode={handleEditMode}
            // handleMealEditMode={() =>
            //       this.setState({ supplementEditorMode: true })
            // }
            onDelete={onDelete}
            onCopy={onCopy}
            displayEditOption={displayEditOption}
            disableActions={disableActions}
            keyName="supplement-plans"
            isDraft={isDraft}
            isPreview={isPreview}
            // isPreview={!displayEditOption}
          />
          {_description && <CardContent>
           <MoreLessTextBtn text={_description} className="desc" btnClassName="viewMore"/>
          </CardContent>}
        </Card>
      )}
      {!isImport &&  <Divider className="dense" />}
      <Card square elevation={0} className={clsx(isImport && "p-0")}>
        {!isImport && (
          <CardHeader
            title="Categories"
            action={
              (!displayEditOption||isPreview) ? null : groups.length ? (
                <IconButton onClick={setSupplementEditorMode}>{<DetailEditIcon />}</IconButton>
              ) : (
                <IconButton onClick={setSupplementEditorMode}>
                  <img src={addIcon} alt="" />
                </IconButton>
              )
            }
            classes={{ root: 'sub-header-root', title: 'sub-title', action: 'margin-top-negative' }}
          />
        )}
        <CardContent>
          {groups.length ? (
            <>
              {!isImport && <Divider className="fmb-25" />}
              <SupplementsForm
                editorMode={supplementEditorMode}
                doc={{ ...oldDoc, ...scheduleDoc }}
                storedSupplements={foodRecipes}
                mealCategories={mealCategories}
                serving={serving}
                isImport={isImport}
                checkImportSupplementGroup={checkImportSupplementGroup}
                onSelectImportSupplements={onSelectImportSupplements}
              />
            </>
          ) : (
            <EditorPlaceholder keyName="supplement" centerAlign={!_description && !groups.length} />
          )}
        </CardContent>
      </Card>
    </DetailPgStyling>
  );
}

export default Details;
