import React, { useState, useMemo, useEffect } from "react";
import { TextField } from "@material-ui/core";
import { FormTextField } from "fitbud/components/form-fields";
import { countDecimals } from "fitbud/utils/helpers";

const NumberInput = (props) => {
  const {
    label,
    value: defaultValue,
    onChange: onChangeHandler,
    formTextFiledProps = {},
    decimals = 2,
    required = false,
    ...rest
  } = props;
  const [value, setValue] = useState(defaultValue);
  const step = useMemo(() => {
    return 1 / Math.pow(10, decimals);
  }, [decimals]);
  useEffect(()=>{
    setValue(defaultValue) 
  },[defaultValue])
  const handleChange = (e) => {
    let value = e.target.value;
    if (countDecimals(value) > decimals) return;
    setValue(value);
    onChangeHandler && onChangeHandler(e);
  };
  return (
    <FormTextField fullWidth required={required} label={label} {...formTextFiledProps}>
      <TextField
        type="number"
        onChange={handleChange}
        value={value}
        inputProps={{ min: 0, step }}
        {...rest}
      />
    </FormTextField>
  );
};

export const SimpleNumberInput = (props) => {
  const { value: defaultValue, onChange: onChangeHandler, decimalLimit = 2, ...rest } = props;
  const [value, setValue] = useState(defaultValue);
  useEffect(()=>{
    setValue(defaultValue) 
  },[defaultValue])
  const handleChange = (e) => {
    let value = e.target.value;
    if (countDecimals(value) > decimalLimit) return;
    setValue(value);
    onChangeHandler && onChangeHandler(e);
  };
  return (
    <TextField
      value={(value || 0).toString()}
      onChange ={handleChange}
      {...rest}
    />
  );
};

export default NumberInput;
