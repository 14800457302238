import React, { useState, useLayoutEffect } from "react";
import _ from "lodash";
import {
    InputAdornment,
    CircularProgress,
    Chip,
    Box,
    LinearProgress,
    IconButton,
    InputBase
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Clear from '@material-ui/icons/CloseRounded';
import SearchIcon from '@material-ui/icons/Search';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import useDebounce from 'fitbud/hooks/useDebounce';
import CheckboxFilter from "fitbud/components/filterComps/CheckboxFilter";


const useStyles = makeStyles(theme => ({
    input: {
        '&::placeholder': {
            textOverflow: 'ellipsis !important',
            fontSize: 14,
            fontWeight: 500
        }
    }
}));

const SearchCheckboxFilter = (props) => {
    const classes = useStyles()
    const { componentState, dispatch, type, isMulti, fetchData, dataParser = (data) => data, placeholder = "Search...", defaultFields = [] } = props;
    const [searchQuery, setSearchQuery] = useState('')
    const [hideSearch, setHideSearch] = useState(true)
    const [fields, setFields] = useState(componentState || [])
    const [loading, setLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);

    const debouncedSearchTerm = useDebounce(searchQuery, 500);
    const onChange = (e) => {
        setSearchQuery(e.target.value)
    }

    const handleDelete = (value) => {
        if (componentState && componentState.includes(value)) {
            dispatch({ type, payload: componentState.filter(s => s !== value) })
        } else {
            if (isMulti) dispatch({ type, payload: [...(componentState || []), value] })
            else dispatch({ type, payload: [value] })
        }
    }

    useLayoutEffect(() => {
        if (!fetchData) return;
        if (!debouncedSearchTerm) setLoading(true)
        setSearchLoading(true)
        fetchData(debouncedSearchTerm)
            .then((resp) => {
                const data = dataParser(resp)
                if (!debouncedSearchTerm && _.get(resp, "data.count") > 10) {
                    setHideSearch(false)
                }
                setFields(data)
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false)
                setSearchLoading(false)
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchTerm])

    if (loading) return <div className="d-flex justify-content-center align-items-center h-100"><CircularProgress /> </div>
    return (
        <div className="position-relative">
            {/* {componentState && componentState.length > 0 && (
                <Box mt={3}>
                    {componentState.map((item) => (
                        <Chip
                            style={{ marginBottom: 8, marginRight: 8 }}
                            key={item}
                            label={item}
                            onDelete={() => handleDelete(item)}
                            deleteIcon={<HighlightOffIcon fontSize="small" />}
                            variant="outlined"
                        />
                    ))}
                </Box>
            )} */}
            {!hideSearch && <InputBase
                fullWidth
                value={searchQuery}
                className="bg-white fpl-10"
                style={{
                    height: 40,
                    borderRadius: "20px",
                    border: "solid 0.6px #d7d7d7"
                }}
                onChange={onChange}
                startAdornment={
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                }
                endAdornment={
                    <InputAdornment position="end">
                        {!!searchQuery && (
                            <IconButton
                                style={{
                                    padding: "8px"
                                }}
                                onClick={() => setSearchQuery("")}
                            >
                                <Clear />
                            </IconButton>
                        )}
                    </InputAdornment>
                }
                placeholder={placeholder}
                classes={{
                    input: classes.input
                }}
            />
            }
            { searchLoading && <LinearProgress className="fmt-5 position-absolute w-100 height-2" style={{ top: 40 }} />}
            <CheckboxFilter {...props} fields={fields} defaultFields={defaultFields} className="fmt-10"/>
        </div>
    )
}

export default SearchCheckboxFilter