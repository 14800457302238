import React, { useContext, useState, useRef, useEffect } from 'react';
import { Dialog, DialogContent, Grid, Typography, Drawer } from '@material-ui/core';
import SelectorContextProvider, {
  SelectorContext,
  SELECTOR_LIB,
} from 'fitbud/components/catalogSelector/selectorContext';
import _ from "lodash";
import {useSnackbar} from "notistack";
import SelectorHeader from 'fitbud/components/catalogSelector/selectorHeader';
import SelectedList from 'fitbud/components/catalogSelector/selectedList';
import appRdxFns from 'fitbud/redux/app';
import { useDispatch } from 'react-redux';
import firebase from 'fitbud/firebase';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import Confirmation from 'fitbud/components/confirmationDialog';
import FoodRecipesInfo from 'fitbud/views/foodRecipes/foodRecipesInfo';
import { ClrdButton } from 'fitbud/components/form-fields';
import ListFDCItems from "fitbud/components/ListFDCItems";
import {SelectorList} from './listWrapper';
import {NoFoodRecipeSelector} from "fitbud/icons/foodNRecipeIcon";
import CatalogFilter from 'fitbud/components/catalog-filter';
import makeStyles from "@material-ui/core/styles/makeStyles";
import get from "lodash/get";
import clsx from "clsx";
import {DialogAppBar} from "fitbud/components/Dialog";
import { fetchFoodDetail } from "fitbud/api";
import { DEFAULT_ERROR } from 'fitbud/utils/constants';


const useClasses = makeStyles(()=>({
  addBtn:{
    padding: "11px 30px",
    backgroundColor:"#E0ECFE",
    cursor: "pointer",
    borderRadius:5,
    width: 160,
  }
}))






const FoodSelectorContent = (props) => {
  const {defaultFilter,dataType,filterKeyName="foodRecipesSupps",existingItems=[]}=props;
  const { query, addSelectedValues, selectedLib,filterOpen, filterState, closeFilter, setFilterState } = useContext(SelectorContext);
  const { cid } = useContext(FirebaseAuthContext);
  const d = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const { showLoader, hideLoader } = appRdxFns(d);
  const [foodRecipesData, setFoodRecipesData] = useState(null);
  const [existsFdcFood, setExistsFdcFood] = useState(null); //state for fitbudd lib ex which is already in own lib;
  const foodFdcDataSnap = useRef(null);
  const [newFdFormOpen, setNewFdFormOpen] = useState(null);
  const [viewFd, setViewFd] = useState(null);
  const classes = useClasses();


  const getFoodRecipesByFdcId = async (id) => {
    const exerciseRef = await firebase
      .firestore()
      .collection(`companies/${cid}/foodRecipes`)
      .where('fdcId', '==', id)
      .get();
    return exerciseRef.docs;
  };

  const clearExistsFdcFood = () => {
    setExistsFdcFood(null);
  };

  const onAddFitbuddLibItem = async (fdcItem) => {
    showLoader();
    //new code
     try{
      const { success, data, copy } = await fetchFoodDetail(fdcItem.fdcId, cid);
      hideLoader();
      if (!success) throw new Error("Api Not Success"); //
      if(copy && copy.id){
        foodFdcDataSnap.current = copy.id; 
        setExistsFdcFood(data); //save id and data  in case trainer want to create new one from old.
      }else{
        if(_.isEmpty(data)) throw new Error("Api Response is Empty.")
        setFoodRecipesData({...data, source:"fitbudd"});
      }
     }catch(err){
       hideLoader();
      enqueueSnackbar(DEFAULT_ERROR,{variant:"error"})
      setFoodRecipesData(null);
     }
  };

  const selectFoodFdc = () => {
    const fId = foodFdcDataSnap.current;
    addSelectedValues({ _id: fId, data: {...existsFdcFood} }); //select items
    setExistsFdcFood(null);
    foodFdcDataSnap.current = null;
  };

  const createNewCopyOfFdcFood = () => {
    setFoodRecipesData({ ...existsFdcFood }); //will open ex create form
    setExistsFdcFood(null); //will close confirmation dialog
    foodFdcDataSnap.current = null
  };


  const noSelectedPlaceHolder = () =>{
    return (
      <div className="flex-grow-1 h-100 fp-20 text-center d-flex align-items-center justify-content-center flex-column ">
        <NoFoodRecipeSelector />
        <Typography  className="fmt-25 font_15_700">
          {`No Food Items Selected`}
        </Typography>
        <Typography className="fmt-10" style={{fontSize:"13px", fontWeight:400, color:"#37404D"}}>
          {`Select food items from the left panel`}
        </Typography>
      </div>
    );
   }

   const handleClickOnSelectedItem = (e,l) =>{
    setViewFd(l);
  }

  const onDrawerClose = () =>{setViewFd(null)}



   const renderNoResultFoundAction = () =>{
    return (
      <div style={{ width: 350 }} className="d-flex flex-column justify-content-center align-items-center fmt-10">
        <Typography className="font_13_400 text-dark-grey fmb-20">
          We can't find what you are looking for. Use the button below to add your own food item.
        </Typography>
        <div className="d-flex">
         {filterKeyName!=="recipes" && <div
            className={clsx(classes.addBtn, 'fmr-15')}
            onClick={() => {
              setNewFdFormOpen({
                open:true,
                type:"food"
              });
            }}
          >
            <Typography color="primary" className="font_15_600">
              Add Food
            </Typography>
          </div>}
          {filterKeyName!=="foods" && <div
            className={classes.addBtn}
            onClick={() => {
              setNewFdFormOpen({
                open:true,
                type:"recipe"
              });
            }}
          >
            <Typography color="primary" className="font_15_600">
              Add Recipe
            </Typography>
          </div>}
        </div>
      </div>
    );
  }
  return (
    <DialogContent className="d-flex fp-0">
      <Grid container className="flex-grow-1">
        <Grid item xs={7} className="h-100 d-flex position-relative" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
          {selectedLib === SELECTOR_LIB.MY_LIB && (
            <SelectorList 
              noResultFoundAction={renderNoResultFoundAction}  
              query={query} 
              filterState={filterState} 
              onClick={(d) => addSelectedValues(d, existingItems)} 
              keyName="foodRecipesSupps" 
              showDraftInd={true}
              dataType={dataType}
              defaultFilter={defaultFilter}
              />
          )}
          {selectedLib === SELECTOR_LIB.FITBUDD_LIB && (
            <ListFDCItems noResultFoundAction={renderNoResultFoundAction} query={query} searchOff={true} onClick={onAddFitbuddLibItem} listClassName="fpy-0" />
          )}
        </Grid>
        <Grid className="h-100" item xs={5}>
          <SelectedList onClick={handleClickOnSelectedItem} keyName="Items" noSelectedPlaceholder={noSelectedPlaceHolder} />
        </Grid>
      </Grid>
      {!!foodRecipesData && (
        <FoodRecipesInfo
          id={'new'}
          key="new"
          type="fdc" //in previous code it was hardcoded
          onSave={(doc) => {
            const _id = doc.id;
            const data = doc.data();
            addSelectedValues({ _id: _id, data: data });
            setFoodRecipesData(null);
          }}
          onCancel={() => {
            setFoodRecipesData(null);
          }}
          fdcData={foodRecipesData}
          draftFeature={false}
        />
      )}
      {!!existsFdcFood && (
        <Confirmation
          open={true}
          handleClose={clearExistsFdcFood}
          showCloseIcon={true}
          title={'Copy Already Exists'}
          msg={'A copy of this food item already exists in your library'}
          cancelOption={'Select Existing'}
          handleCancel={selectFoodFdc}
          confirmOption="Create New Copy"
          handleChange={createNewCopyOfFdcFood}
        />
      )}
      {filterOpen && <CatalogFilter
          filters={filterState}
          keyName={filterKeyName}
          open={true}
          onSave={setFilterState}
          handleClose={closeFilter}
        />
      }
      {!!newFdFormOpen && (
        <FoodRecipesInfo
          id={'new'}
          key="new"
          type={newFdFormOpen.type}
          onSave={(doc) => {
            const _id = doc.id;
            const data = doc.data();
            addSelectedValues({ _id: _id, data: data });
            setNewFdFormOpen(null);
          }}
          onCancel={() => {
            setNewFdFormOpen(null);
          }}
          draftFeature={false}
        />
      )}
      {!!viewFd && <Drawer anchor="right" open={true} onClose={onDrawerClose}>
        <DialogAppBar title={get(viewFd, "data.dtype") === "recipe" ? "Recipe":"Food"} onClose={onDrawerClose} titleFont="h3"/>
        <FoodRecipesInfo id={viewFd._id} type={get(viewFd, "data.dtype")} key={viewFd._id} isPreview={true} />
      </Drawer> }
    </DialogContent>
  );
};

const FoodRecipeSelectorHeader = (props) => {
  const {type, handleAdd, grpIndex, itemIndex, selectedFoodItems} = props;  
  const { selectedValues, addAllSelectedValues, selectedLib } = useContext(SelectorContext);
  const isInitialExSet = useRef(false)

  useEffect(()=>{
    if(!!selectedFoodItems && !!selectedFoodItems.length && !isInitialExSet.current ){
      addAllSelectedValues(selectedFoodItems);
      isInitialExSet.current = true;
    }
  }, [addAllSelectedValues, selectedFoodItems] )


  const onSave = () => {
    handleAdd({ data: selectedValues, grpIndex, type, itemIndex });
  };
  const getHeaderActions = () => {
    return (
      <ClrdButton variant="contained" color="primary" className="f-medium" onClick={onSave}>
        Save
      </ClrdButton>
    );
  };
  return <SelectorHeader disableFilter={selectedLib === SELECTOR_LIB.FITBUDD_LIB} title="Select Food Items" keyName="foodRecipesSupps" headerActions={getHeaderActions} {...props} />;
};

const FoodRecipeSelector = (props) => {
  const { open } = props;
  return (
    <Dialog
      open={open}
      id="exercise_selector"
      scroll="paper"
      classes={{
        paper: 'width-800 height-70',
      }}
    >
      <SelectorContextProvider item="Food & Recipe">
        <FoodRecipeSelectorHeader {...props} />
        <FoodSelectorContent {...props}/>
      </SelectorContextProvider>
    </Dialog>
  );
};

export default FoodRecipeSelector;
