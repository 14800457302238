import { IconButton, Tooltip, Typography } from "@material-ui/core";
import StartRecordIcon from "fitbud/images/start_record.svg";
import StopRecordIcon from "fitbud/images/stop_recording.svg";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import { secondsToMinutes } from "fitbud/utils/helpers";
import _ from "lodash";
import moment from "moment";
import React, { useContext } from "react";


export default function RecorderControls({ recorderState, handlers }) {
  const { comp } = useContext(FirebaseAuthContext);
  const maxDuration = comp && (_.get(comp.data(), "chat_config.company_max_audio_duration", 90));
  const { recordingSeconds, initRecording } = recorderState;
  const { startRecording, saveRecording } = handlers;
  const totalAudioDuration = secondsToMinutes(maxDuration);
  
  return (
    <div className="d-flex flex-column w-100 justify-content-center align-items-center">
      {initRecording ? (
        <>
          <Tooltip title={"Stop Recording"} placement="top">
            <IconButton
              onClick={saveRecording}
              disabled={!initRecording}
              className="fmtn-8"
            >
              <img alt="Stop Recording" src={StopRecordIcon} />
            </IconButton>
          </Tooltip>
          <div>
            <Typography variant="h4" className="fmt-30 d-flex w-100">
              <div className="currentTime w-100">{moment().startOf('day').add(recordingSeconds,'seconds').format('mm:ss')}</div> / <div className="endTime w-100">{totalAudioDuration}</div>
            </Typography>
          </div>
        </>
      ) : (
        <>
        <Tooltip title={"Start Recording"} placement="top">
            <IconButton onClick={startRecording} style={{ marginTop : '-8px' }}>
              <img alt="Start Recording" src={StartRecordIcon} />
            </IconButton>
          </Tooltip>
          <Typography variant="h4" className="fmt-30">Start Recording</Typography>
        </>
      )}
    </div>
  );
}
