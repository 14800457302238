import React, {  useMemo } from "react";
import CatalogMultiSelector from "fitbud/components/catalogSelector/catalogMultiSelector";
import {filter} from "lodash"
import { Typography } from '@material-ui/core';

import {NoExerciseSelectorIcon} from "fitbud/icons/exerciseIcon";

const WorkoutSelector = props => {
  const {
    handleAdd,
    open,
    onClose,
    selectedWOs,
    keyName="wocardio",
    showDraftInd=false,
    title
  } = props;
  const _selectedWo = useMemo(()=>{
    const _out = (selectedWOs || []).filter(v => !!v).map(v=>{
      return {_id :(v.id || v._id), data:v, hideRemoveIcon:true} //add pre selected values disabled.
    })
    return _out;
  },[selectedWOs])

  const noSelectedPlaceHolder = () =>{
    return (
      <div className="flex-grow-1 h-100 fp-20 text-center d-flex align-items-center justify-content-center flex-column ">
        <NoExerciseSelectorIcon />
        <Typography  className="fmt-25 font_15_700">
          {`No Exercise Selected`}
        </Typography>
        <Typography className="fmt-10" style={{fontSize:"13px", fontWeight:400, color:"#37404D"}}>
          {`Select exercises from the left panel`}
        </Typography>
      </div>
    );
   }

 
  const _handleAdd = (wos) =>{
    const _filterList = filter(wos, (d)=>!d.hideRemoveIcon);
    handleAdd(_filterList);
  }
  

  return (
    <CatalogMultiSelector 
      noSelectedPlaceHolder={noSelectedPlaceHolder} 
      title={title||`Select Workout and Cardio`} 
      handleAdd={_handleAdd} 
      item="Workout"
      selected={_selectedWo || []} 
      open={open} 
      keyName={keyName} 
      handleClose={onClose}
      showDraftInd={showDraftInd}
    />)
   
};

export default WorkoutSelector;
