import React, { useEffect, useMemo, useRef, useState } from 'react';
import Dialog from 'fitbud/components/Dialog';
import { date2hhmm } from 'fitbud/utils/scheduling';
import moment from 'moment';
import SpecificTimePicker from '../specific-time-picker.js';
import { useReminder } from 'fitbud/views/reminders/provider';
import _ from 'lodash';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { InfoBox } from './checkins';
import clsx from 'clsx';
import { Button, Divider, IconButton, MenuItem, OutlinedInput, Select, Typography } from '@material-ui/core';
import DropDownIcon from '@material-ui/icons/ExpandMore';
import { TimePicker } from '@material-ui/pickers';
import { CopyIcon } from 'fitbud/icons/copy2';
import { TrashIcon } from 'fitbud/icons/trashIcon.js';
import { DEFAULT_NUTRITION_CONFIG, hhMMToDate } from '../helper.js';

const useStyles = makeStyles(() => ({
  container: {
    '& .mr-n8': {
      marginRight: '-8px',
    },
  },
  timeInput: {
    minHeight: '48px',
    fontSize: '15px',
    fontWeight: 500,
  },
  categoryInput: {
    paddingRight: '38px !important',
  },
}));

const DEFAULT_MEAL_VALUE = {
  id: 'select-category',
  time: null,
};

const MealForm = (props) => {
  const { addMoreOptions, minutesStep, toggleShowMealForm, onTimeChange, getDefaultTimeForMeal } = props;
  const [mealValue, setMealValue] = useState(DEFAULT_MEAL_VALUE);
  const { id, time } = mealValue;
  const classes = useStyles();

  const handleChange = (key, value) => {
    let out = {};
    if (key === 'id') {
      const time = getDefaultTimeForMeal(value);
      out = {
        id: value,
        time: hhMMToDate(time),
      };
    } else {
      out = { [key]: value };
    }
    setMealValue((prev) => ({ ...prev, ...out }));
  };

  const saveMeal = () => {
    onTimeChange(id, time);
    toggleShowMealForm();
  };

  const handleDelete = () => {
    toggleShowMealForm();
  };

  useEffect(() => {
    if (id !== DEFAULT_MEAL_VALUE.id && time) saveMeal();
  }, [id, time]);

  return (
    <div className="d-flex mb-20 align-items-center">
      <Select
        IconComponent={DropDownIcon}
        style={{ width: 200 }}
        input={
          <OutlinedInput
            classes={{
              root: 'medium',
              input: clsx('size_16_500 select-medium', classes.categoryInput),
            }}
            name="id"
          />
        }
        value={id}
        onChange={(e) => handleChange('id', e.target.value)}>
        <MenuItem key="select-category" value="select-category">
          Select Category
        </MenuItem>
        {Object.entries(addMoreOptions).map(([key, value]) => (
          <MenuItem key={key} value={key}>
            {value}
          </MenuItem>
        ))}
      </Select>
      <TimePicker
        style={{ width: 200 }}
        className="fml-20"
        format="hh:mm a"
        value={time}
        inputVariant="outlined"
        defaultValue="Select Time"
        inputProps={{
          className: clsx(classes.timeInput),
          ...(!time && { value: 'Select Time' }),
        }}
        onChange={(value) => handleChange('time', value)}
        minutesStep={minutesStep}
      />
      <div className="d-flex">
        <IconButton component="div" disabled className="ml-12" style={{ marginRight: '-8px' }}>
          <CopyIcon fontSize="small" />
        </IconButton>

        <IconButton component="div" style={{ marginRight: '-8px' }} className="ml-12" onClick={handleDelete}>
          <TrashIcon fontSize="small" />
        </IconButton>
      </div>
    </div>
  );
};

const NutritionEditor = (props) => {
  const {
    reminders,
    dirty,
    mlNames,
    handleMealCheck,
    setMealTime,
    onSave,
    closeEdit,
    time_picker_step,
    setMealCategories,
    defaultMealCategories,
    allMeals,
    reminder_tag_config,
  } = useReminder();
  const firstMount = useRef(true);
  const nutrition = _.get(reminders, 'nutrition', {});
  const [showMealForm, setShowMealForm] = useState(false);
  const classes = useStyles();

  const categories = useMemo(() => {
    let categories = _.get(nutrition, 'categories');
    if (firstMount.current) {
      // for just first mount, if categories is empty then set to default categories if found.
      if (_.isEmpty(categories)) categories = defaultMealCategories || {};
      firstMount.current = false;
    }
    return categories;
  }, [nutrition, defaultMealCategories]);
  const CN = Object.keys(categories || {}).length;

  const getDefaultTimeForMeal = (id) => {
    return _.get(
      allMeals,
      `${id}.time`,
      _.get(reminder_tag_config, 'nutrition.default_time', DEFAULT_NUTRITION_CONFIG.default_time)
    );
  };

  const addMoreOptions = useMemo(() => {
    const filterKeys = Object.keys(categories);
    const filteredOptions = _.omit(mlNames, filterKeys);

    return filteredOptions;
  }, [categories, mlNames]);

  const toggleShowMealForm = () => {
    setShowMealForm((prev) => !prev);
  };

  const onTimeChange = (key, time) => {
    const t = moment(time);
    const hhmm = date2hhmm(t);
    setMealTime(key, hhmm);
  };
  const handleCopy = (key, value) => {
    setMealTime(key, value);
  };

  const handleDelete = (key) => {
    handleMealCheck(key, false);
  };

  const updateCategory = (oldKey, newKey, time) => {
    const t = moment(time);
    const hhmm = date2hhmm(t);
    const mealKeys = Object.keys(categories);
    const position = mealKeys?.findIndex((value) => value === oldKey);
    mealKeys.splice(position, 1, newKey);

    const newCategories = {};

    for (const key of mealKeys) {
      let mealTime = categories[key];
      if (key === newKey) mealTime = hhmm;
      newCategories[key] = mealTime;
    }
    setMealCategories(newCategories);
  };

  const handleSubmit = () => {
    onSave('nutrition');
  };

  useEffect(() => {
    const sortedKeys = Object.entries(categories).sort((a, b) => {
      const aDate = hhMMToDate(a[1]);
      const bDate = hhMMToDate(b[1]);
      return new Date(aDate) - new Date(bDate);
    });
    const sortedCategories = {};

    for (const key of sortedKeys) {
      sortedCategories[key[0]] = categories[key[0]];
    }
    setMealCategories(sortedCategories, false);
  }, []);

  return (
    <Dialog
      toolbarClass="height-60"
      buttonColor="primary"
      title="Nutrition Reminder"
      open
      withConfirmation
      isDirty={dirty}
      onClose={closeEdit}
      paperClass="width-540"
      titleFont="h3"
      onSave={handleSubmit}
      actionText="Save">
      <div className={clsx(classes.container, 'px-20 pt-25')}>
        {/* Sync Mode */}
        <InfoBox
          className="mb-25"
          text="Reminders will be sent to the clients at the specified meal times."
        />
        {(showMealForm || !!CN) && (
          <div className="d-flex fmb-10" style={{ columnGap: 60 }}>
            <Typography className="font_13_500" style={{ color: '#6F8099' }}>
              Preferred Meal Category
            </Typography>
            <Typography className="font_13_500" style={{ color: '#6F8099' }}>
              Preferred Timings
            </Typography>
          </div>
        )}
        <SpecificTimePicker
          onTimeChange={onTimeChange}
          handleCopy={handleCopy}
          title="Select Weekdays"
          data={{ ...categories }}
          nameMapping={mlNames}
          minutesStep={time_picker_step}
          handleDelete={handleDelete}
          showDropdown
          dropdownOptions={addMoreOptions}
          handleMealChange={updateCategory}
        />
        {showMealForm && (
          <MealForm
            data={{ ...categories }}
            addMoreOptions={addMoreOptions}
            minutesStep={time_picker_step}
            toggleShowMealForm={toggleShowMealForm}
            onTimeChange={onTimeChange}
            getDefaultTimeForMeal={getDefaultTimeForMeal}
          />
        )}
      </div>
      <Divider style={{ margin: '0 20px' }} />
      <div className="d-flex justify-content-center pb-20  pt-15">
        <Button
          variant="text"
          color="primary"
          disabled={showMealForm}
          classes={{ text: '' }}
          onClick={toggleShowMealForm}>
          {CN === 0 ? 'Add meal timings' : 'Add More'}
        </Button>
      </div>
    </Dialog>
  );
};

export default NutritionEditor;
