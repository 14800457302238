import React, { useMemo, useContext } from 'react';
import {
    Typography,
    makeStyles,
    InputBase,
    TextField,
  } from "@material-ui/core";
import clsx from 'clsx';
import { FormTextField } from "fitbud/components/form-fields";
import _ from "lodash";
import { secondsToDuration} from "fitbud/components/durationInput";
import {Conversion} from "fitbud/providers/conversion";


const styles=makeStyles({
    amrapInput:{
        borderRadius: 3,
        border: ({error}) =>error ? "1px solid #f44336" : "1px solid #DDDDDD",
        height: 36, 
        width: 90,
        minWidth:90,
        '& .id':{
            flex:1.2
        },
        '& .sec':{
            flex:1
        },
    },
    durationInput:{
        display: "flex",
        alignItems: "center",
        width:"100%",
        height:"100%",
        padding: "0px 8px",
    },
    displayValueInput:{
        padding: "0px!important",
        height: 36,
        '& .id':{
            flex:1.2
        },
        '& .sec':{
            flex:1
        },
        '& .endAdornment':{
            // overflow:"visible"
        }
    },
    mainInput: {
        display: "flex",
        gap:"5px",
        alignItems: "center",
        padding: "0px 8px",
        "& .endAdornment": {
          color: "#6F8099",
          fontSize: 13,
          fontWeight: 500,
          width:'fit-content'
        },
        "& input": {
          fontSize: 16,
          fontWeight: 600,
          color: "#0D0D0D",
          padding: 0,
          display: "flex",
          textAlign: "right",
          marginRight: 2,
          maxWidth: 21,
        },
      },
})
export const AmrapDurationInput=(props)=>{
  const {convertor} = useContext(Conversion);
    const {name,value,onChange,loading,disabled,hideHr,hideWhen0=false,
        error,type="amrap",readOnly }=props;
    const {hour,min,sec}=useMemo(()=>{
        const parsedDuration=secondsToDuration(value);
        const [hour,min,sec]=parsedDuration.split(" : ").map(i=>Number(i));
        return {hour,min,sec};
    },[value]); 
    function _handleChange(e){
      let {value:eValue,name:eName}=e.target;
      eValue=Number(eValue);
      let calculatedValue=0;
      if(eName==='hour'){
          if(hideHr) return;
          if(eValue > 60) return;
          calculatedValue = (eValue * 3600)+(min*60)+sec;
      }
      else if(eName==='min'){
        if(eValue>59) return;
        calculatedValue = (hour*3600)+(eValue * 60)+sec;
      }
      else if(eName==='sec'){
        if( eValue>59) return;
        calculatedValue = (hour*3600)+(min * 60)+eValue;
      }
      if(onChange){
        onChange({
            target:{
                name,
                value: calculatedValue,
            }
          });
      }
    }
    const focus=(e)=>{
        if(!e.target.id && !disabled && !loading){
            const ele = e.target.querySelector(hideHr ? '#min' : '#hour');
            if(!ele) return;
            ele.focus();
        }
    }
    const classes=styles({error});
    const className=useMemo(()=>{
        if(type==="amrap")return "amrapInput";
        else if(type==="duration") return "durationInput";
        else if(type==="displayValue") return "displayValueInput";
        else if(type==="pace") return "displayValueInput";
    },[type])
    const hideMin=useMemo(()=>{
        if(hideWhen0 && !min) return true;
        return false;
    },[hideWhen0,min]);
    const hideSec=useMemo(()=>{
        if(hideWhen0 && !sec) return true;
        return false;
    },[hideWhen0,sec]);
    const secondsUnit=useMemo(()=>{
        return type!=="pace"?"s":`${convertor.getDisplayUnit("pace")}`;
    },[type,convertor])
    return(
        <div className={clsx(classes.mainInput,classes[className])} onClick={focus}>
            {!hideHr && <InputBase
                endAdornment={<Typography className="endAdornment">h</Typography>}
                className="align-items-baseline"
                value={hour.toString()}
                id="hour"
                name="hour"
                type="number"
                onChange={_handleChange}
                disabled={loading||disabled}
                inputProps={{
                    min:0
                }}
            />}
            {!hideMin && <InputBase
                endAdornment={<Typography className="endAdornment">m</Typography>}
                className={clsx("align-items-baseline")}
                value={min.toString()}
                id="min"
                name="min"
                type="number"
                onChange={_handleChange}
                disabled={loading||disabled}
                readOnly={readOnly}
                inputProps={{
                    min:0,
                    max:hideHr && 59 //or 0 :TODO: Test
                }}
            />}
            {!hideSec && <InputBase
                endAdornment={<Typography className="endAdornment">{secondsUnit}</Typography>}
                className={clsx("align-items-baseline")}
                value={sec.toString()}
                id="sec"
                name="sec"
                type="number"
                onChange={_handleChange}
                disabled={loading}
                readOnly={readOnly}
                inputProps={{
                    min:0
                }}
            />}
      </div>
    );
}
export const DurationWrapper=(props)=>{
    const {convertor} = useContext(Conversion);
    const {value,name,handleChange,label,formTextFieldProps={},classes={},type="duration",unit,...rest}=props;
    const _classes=_.merge({
        root: "medium",
        input: "size_16_500 medium",
    },classes);
    const onChange=e=>{
        const {value}=e.target;
        if(unit==="pace"){
            const newValue=convertor.getDBValue("pace",value);
            handleChange(_.merge(e,{target:{unit,value:newValue}}))
        }
        else{
            handleChange(_.merge(e,{target:{unit}}))
        }
    }
    const _value=useMemo(()=>{
        return convertor.getDisplayValue(unit,value);
    },[value])
    return (
        <FormTextField fullWidth label={label} {...formTextFieldProps}>
            <TextField
                fullWidth={true}
                onChange={onChange}
                name={name}
                variant="outlined"
                value={_value}
                InputProps={{
                    inputComponent:AmrapDurationInput,
                    classes:_classes,
                    inputProps:{
                        ...rest,
                        type
                    }
                }}
                {...rest}
            />
        </FormTextField>
    )
}