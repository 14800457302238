import React, { useMemo } from 'react';
import { useLiveSessions } from 'fitbud/providers/liveSessionsProvider';
import clsx from 'clsx';
import { ClrdButton } from 'fitbud/components/form-fields';
import { useServices } from 'fitbud/providers/services-provider';
import { useLocations } from 'fitbud/providers/gcLocationsProvider';
import { getClassJoinUrl } from 'fitbud/views/groupClasses/helper';

export const JoinButton = ({ session, className }) => {
  const { handleOpenVideoScreen } = useLiveSessions();
  const {getServiceById} = useServices();
  const {getLocation} = useLocations();
  const location_offline = session?.location_offline;
  const service = session?.service;

  const [mode, joinUrl] = useMemo(()=>{
    if(!service) return ["online", ""];
    const service_info = getServiceById(service);
    const _mode = service_info?.data?.mode; 
    if(_mode === "offline"){
      const offlineDetail = getLocation(location_offline);
      const url = getClassJoinUrl({mode : "offline"}, offlineDetail)
      return ["offline", url]
    }
    return ["online", undefined]
  },[location_offline, service, getLocation, getServiceById, session])

  function handleClick(e) {
    if(mode === "offline"){
      window.open(joinUrl, '_blank');
      return;
    }
    e.preventDefault();
    handleOpenVideoScreen(session);
  }
  return (
    <ClrdButton
      variant="contained"
      color="primary"
      fullWidth
      onClick={handleClick}
      className={clsx('f-large flex-grow-1', className)}>
      {mode ===  "offline"? "Appointment Started" : "Join Appointment"}
    </ClrdButton>
  );
};
