import {BrandingIcon} from "./brandingIcon";
import {CheckinsIcon} from "./checkinsIcon";
import {ClientsIcon} from "./clientsIcon";
import {FitnessIcon} from "./fitnessIcon";
import {HelpIcon} from "./helpIcon";
import {LiveSessionsIcon} from "./liveSessionsIcon";
import {MsgsIcon} from "./msgsIcon";
import {NutritionIcon} from "./nutritionIcon";
import {PaymentsIcon} from "./paymentsIcon";
import {SettingsIcon} from "./settingsIcon";
import {TtsIcon} from "./ttsIcon";
import {OverviewIcon} from "./overviewIcon";
import {HomeIcon} from "./homeIcon";
import {SetupGuideIcon} from "./setupGuideIcon";
import {StoreFrontIcon} from "./storeFrontIcon";
import {ExpandIcon} from "./expandIcon";
import {CompressIcon} from "./compressIcon";
import {ExploreIcon} from "./exploreIcon";
import {ResourcesIcon} from "./resourcesIcon";
import { MoreIcon } from "./moreIcon";
import { ChallengesIcon } from "./challengesIcon";

export  {
    BrandingIcon,
    CheckinsIcon,
    ClientsIcon,
    FitnessIcon,
    HelpIcon,
    LiveSessionsIcon,
    MsgsIcon,
    NutritionIcon,
    PaymentsIcon,
    SettingsIcon,
    TtsIcon,
    OverviewIcon,
    HomeIcon,
    SetupGuideIcon,
    StoreFrontIcon,
    ExpandIcon,
    CompressIcon,
    ExploreIcon,
    ResourcesIcon,
    MoreIcon,
    ChallengesIcon
}