import axnsBuilder from "./actions";
import _ from "lodash";
export const DEFAULT_STATE = {
  loaderCount: 0,
  cid: null,
  profile: undefined,
  notifications:undefined
};

const axns = axnsBuilder();
export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case axns.showLoader:
      return {
        ...state,
        loaderCount: state.loaderCount + 1,
        timestamp: Date.now()
      };
    case axns.hideLoader:
      return {
        ...state,
        loaderCount: Math.max(0, state.loaderCount - 1),
        timestamp: Date.now()
      };
    case axns.setCID:
      return {
        ...state,
        cid: action.cid,
        timestamp: Date.now()
      };
    case axns.setProfile:
      return {
        ...state,
        profile: _.cloneDeep(action.doc),
        timestamp: Date.now()
      };
    case axns.showEnqueueSnackBar:
      return {
        ...state,
        notifications: action.notifications
      };

    default:
      return state;
  }
};
