import React, { useState, useMemo } from "react";
import CatalogMultiSelector from "fitbud/components/catalogSelector/catalogMultiSelector";
import {filter} from "lodash"
import { Typography } from '@material-ui/core';
import SupplementImg from "fitbud/images/catalog/supplements.png";




const SupplementsSelector = props => {
  const {
    handleAdd,
    grpIndex,
    doc = [],
    handleClose,
    open,
    type,
    itemIndex,
    storedSupplements
  } = props;


  let checkedDoc = [];
  if (doc.groups && doc.groups[grpIndex])
    checkedDoc = doc.groups[grpIndex].supplements;
  

    const _selectedWo = useMemo(()=>{
      
      const suppls = (checkedDoc || []).map((suppl)=>{
        let id = suppl.ref.id;
        const doc = storedSupplements && storedSupplements.find((d) => id === d.id);
        return { _id: id, data: { ...doc }, disabled:true };
      })

      return suppls;


    },[checkedDoc, storedSupplements])

    const noSelectedPlaceHolder = () =>{
      return (
        <div className="flex-grow-1 h-100 fp-20 text-center d-flex align-items-center justify-content-center flex-column ">
          <img alt="noitem" src={SupplementImg} />
          <Typography  className="fmt-25 font_15_700">
            {`No Supplements Selected`}
          </Typography>
          <Typography className="fmt-10" style={{fontSize:"13px", fontWeight:400, color:"#37404D"}}>
            {`Select supplements from the left panel`}
          </Typography>
        </div>
      );
     }

    const _handleAdd = (wos) =>{
      const _filterList = filter(wos, (d)=>!d.disabled);
      handleAdd({ data: _filterList, grpIndex, type, itemIndex });    }


    return <CatalogMultiSelector item="Supplement" noSelectedPlaceHolder={noSelectedPlaceHolder} title={`Select Supplements`} handleAdd={_handleAdd} selected={_selectedWo || []} open={open} keyName={"supps"} handleClose={handleClose} showDraftInd={true}/>

};

export default SupplementsSelector;
