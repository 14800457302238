import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const ReportsNStatsIcon = props => (
    <SvgIcon
        {...props}
        viewBox="0 0 20 20"
        classes={{
          root: "small"
        }}
    >
      <g id="I-c-o-n-s" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="statistics" fill="#8F969F" fillRule="nonzero">
          <path d="M2.671875,8.4375 C3.09732054,8.4375 3.48239137,8.26679992 3.76693727,7.99269103 L5.32795714,8.77313232 C5.32012939,8.83671571 5.30859375,8.89920042 5.30859375,8.96484375 C5.30859375,9.8371582 6.01831055,10.546875 6.890625,10.546875 C7.76293945,10.546875 8.47265625,9.8371582 8.47265625,8.96484375 C8.47265625,8.72135926 8.41278076,8.49339295 8.31404113,8.28712462 L10.4316559,6.16950988 C10.6379242,6.26824951 10.8658905,6.328125 11.109375,6.328125 C11.9816895,6.328125 12.6914062,5.6184082 12.6914062,4.74609375 C12.6914062,4.58184814 12.6591339,4.42652892 12.612442,4.27752686 L14.4523773,2.89791872 C14.7034149,3.06559754 15.004303,3.1640625 15.328125,3.1640625 C16.2004395,3.1640625 16.9101562,2.4543457 16.9101562,1.58203125 C16.9101562,0.709716797 16.2004395,0 15.328125,0 C14.4558105,0 13.7460938,0.709716797 13.7460938,1.58203125 C13.7460938,1.74627686 13.7783661,1.90159608 13.825058,2.05059814 L11.9851227,3.43020628 C11.7340851,3.26252746 11.433197,3.1640625 11.109375,3.1640625 C10.2370605,3.1640625 9.52734375,3.8737793 9.52734375,4.74609375 C9.52734375,4.98957824 9.58721924,5.21754455 9.68595887,5.42381287 L7.56834412,7.54142762 C7.3620758,7.44268799 7.13410949,7.3828125 6.890625,7.3828125 C6.46517946,7.3828125 6.08010862,7.55351258 5.79556273,7.82762147 L4.23454286,7.04718018 C4.24237061,6.98359679 4.25390625,6.92111208 4.25390625,6.85546875 C4.25390625,5.9831543 3.54418945,5.2734375 2.671875,5.2734375 C1.79956055,5.2734375 1.08984375,5.9831543 1.08984375,6.85546875 C1.08984375,7.7277832 1.79956055,8.4375 2.671875,8.4375 Z" id="Path"></path>
          <path d="M17.4726562,16.9453125 L16.9101562,16.9453125 L16.9101562,5.80078125 C16.9101562,5.50936891 16.6742248,5.2734375 16.3828125,5.2734375 L14.2734375,5.2734375 C13.9820252,5.2734375 13.7460938,5.50936891 13.7460938,5.80078125 L13.7460938,16.9453125 L12.6914062,16.9453125 L12.6914062,8.96484375 C12.6914062,8.67343141 12.4554748,8.4375 12.1640625,8.4375 L10.0546875,8.4375 C9.76327516,8.4375 9.52734375,8.67343141 9.52734375,8.96484375 L9.52734375,16.9453125 L8.47265625,16.9453125 L8.47265625,13.1835938 C8.47265625,12.8921814 8.23672484,12.65625 7.9453125,12.65625 L5.8359375,12.65625 C5.54452516,12.65625 5.30859375,12.8921814 5.30859375,13.1835938 L5.30859375,16.9453125 L4.25390625,16.9453125 L4.25390625,11.0742188 C4.25390625,10.7828064 4.01797484,10.546875 3.7265625,10.546875 L1.6171875,10.546875 C1.32577516,10.546875 1.08984375,10.7828064 1.08984375,11.0742188 L1.08984375,16.9453125 L0.52734375,16.9453125 C0.235931414,16.9453125 0,17.1812439 0,17.4726562 C0,17.7640686 0.235931414,18 0.52734375,18 L17.4726562,18 C17.7640686,18 18,17.7640686 18,17.4726562 C18,17.1812439 17.7640686,16.9453125 17.4726562,16.9453125 Z" id="Path"></path>
        </g>
      </g>
    </SvgIcon>
);
