import React from "react";
import { withSnackbar } from "notistack";
import {
  TextField,
  LinearProgress,
  Typography,
  Button,
} from "@material-ui/core";
import {
  ClrdButton,
  PasswordField,
  isValidEmail,
} from "fitbud/components/form-fields";
import { withStyles } from "@material-ui/core/styles";
import Logo, { Icon } from "fitbud/components/logo";
import firebase from "fitbud/firebase";
import Firebase from "firebase";
import ResetPassword from "./resetPwd";
import { FormTextField } from "fitbud/components/form-fields";

const gauth = new Firebase.auth.GoogleAuthProvider();

const useStyles = (theme) => ({
  info: {
    maxWidth: 450,
    fontSize: "15px",
    fontWeight: "500",
    color: "#70829b",
  },
  textAlignCenter: {
    textAlign: "center",
  },
  heading: {
    fontSize: "40px",
  },
  subHeading: {
    fontSize: "20px",
    fontWeight: "500",
  },
  rightPart: {
    flex: "1 1",
    [theme.breakpoints.up("sm")]: {
      minWidth: 360,
    },
  },
  leftPart: {
    flex: "2 1",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  zIndex: {
    zIndex: 1,
  },
});

class Login extends React.Component {
  state = {
    mail: "",
    pwd: "",
    pwdReset: false,
    reset: false,
    errors: {},
    loading: false,
  };

  handleChange = (e) => this.setState({ [e.target.id]: e.target.value });

  handlePaste = (e) => {
    if (!e || !e.clipboardData || !e.clipboardData.getData) return;
    const txt = e.clipboardData.getData('text');
    if (!txt) return;
    const trimmed = txt.trim();
    if (txt !== trimmed)
      window.setTimeout(() => this.setState({pwd: trimmed}), 0);
  }

  valid = () => {
    let out = true;
    let errors = {};
    const { mail, pwd } = this.state;
    if (!isValidEmail(mail)) {
      errors.mail = "Please provide a valid email";
      out = false;
    } else if (!pwd && !this.state.reset) {
      errors.pwd = "Please provide a password";
      out = false;
    }
    this.setState({ errors });
    return out;
  };

  onStartAuth = () => {
    this.setState({ loading: true });
    if (!!this.snack)
      // dismiss any previous error
      this.props.closeSnackbar(this.snack);
  };

  handleAuthError = (err) => {
    this.setState({ loading: false });
    this.snack = this.props.enqueueSnackbar(
      err.code === "auth/network-request-failed"
        ? err.message
        : "The email and/or password do not match.",
      { variant: "error" }
    );
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (!this.valid()) return;
    this.onStartAuth();
    const { mail, pwd } = this.state;
    if (this.state.reset) {
      firebase
        .auth()
        .sendPasswordResetEmail(mail)
        .then(() => {
          this.snack = this.props.enqueueSnackbar(
            "Success. Please check your mail",
            { variant: "success" }
          );
          this.setState({ loading: false, reset: false });
        })
        .catch(this.handleAuthError);
    } else {
      firebase
        .auth()
        .signInWithEmailAndPassword(mail, pwd)
        .catch(this.handleAuthError);
    }
  };

  onGoogle = () => {
    this.onStartAuth();
    firebase.auth().signInWithPopup(gauth).catch(this.handleAuthError);
  };

  toggleReset = () => {
    this.setState({ reset: !this.state.reset });
  };

  renderInner = () => {
    const { mail, pwd, errors, loading } = this.state;
    const { classes } = this.props;
    return (
      <div className='position-relative w-100 mw-450'>
        <FormTextField fullWidth label='Email Address' required>
          <TextField
            id='mail'
            type='email'
            value={mail}
            onChange={this.handleChange}
            className='mb-3'
            error={!!errors.mail}
            helperText={errors.mail}
            autoComplete='email'
            disabled={loading}
            variant='outlined'
            required
            InputProps={{
              classes: {
                root: "medium",
                input: "size_16_500 h-100",
              },
            }}
          />
        </FormTextField>
        {!this.state.reset && (
          <FormTextField fullWidth label='Password' required>
            <PasswordField
              id='pwd'
              value={pwd}
              onChange={this.handleChange}
              onPaste={this.handlePaste}
              className={`mb-3`}
              autoComplete='current-password'
              error={!!errors.pwd}
              helperText={errors.pwd}
              disabled={loading}
              variant='outlined'
              required
            />
          </FormTextField>
        )}
        {!this.state.reset && (
          <div className='px-2 mb-4'>
            <Typography
              variant='h4'
              component='h4'
              className={`${classes.info}`}
              align='center'
            >
              By logging in, you agree to our{" "}
              <Button
                component='a'
                href={process.env.REACT_APP_TNC}
                rel='noopener noreferrer'
                target='_blank'
                className='p-0'
              >
                Terms & Conditions
              </Button>{" "}
              and{" "}
              <Button
                href={process.env.REACT_APP_PRIVACY_POLICY}
                target='_blank'
                rel='noopener noreferrer'
                className='p-0'
                component='a'
              >
                {" "}
                Privacy Policy
              </Button>
            </Typography>
          </div>
        )}
        <div className='d-flex w-100 align-items-baseline'>
          <ClrdButton
            color='black-white'
            type='submit'
            className='f-xxxlarge'
            disabled={loading}
          >
            {this.state.reset ? "Proceed" : "Login"}
          </ClrdButton>
        </div>
      </div>
    );
  };

  render() {
    const { pwdReset, loading } = this.state;
    const { classes } = this.props;
    return (
      <div className='vw-100 vh-100 d-flex'>
        {loading && (
          <LinearProgress
            className={`w-100 position-absolute ${classes.zIndex}`}
          />
        )}
        <div
          className={`${classes.leftPart} d-none d-sm-flex flex-column align-items-center justify-content-center position-relative bg-login`}
        >
          <Icon id='logo1' height={96} color='fancy' />
          <Typography
            variant='h1'
            className={`text-white text-center m-4 ${classes.heading}`}
          >
            Train. Transform. Grow
          </Typography>
          <Typography
            variant='h3'
            className={`text-white text-center mx-4 mb-4 ${classes.subHeading}`}
          >
            Give your Fitness Business a New Dimension
          </Typography>
          <Button
            variant='outlined'
            className='text-white border-white f-medium'
            component='a'
            target='_blank'
            rel='noopener noreferrer'
            href='https://www.fitbudd.com/#free-trial'
          >
            Start Free Trial
          </Button>
        </div>
        <div className={`${classes.rightPart} col bg-white`}>
          <form onSubmit={this.onSubmit} novalidate='novalidate'>
            <div className='w-100 vh-100 px-2 d-flex flex-column justify-content-center align-items-center align-items-sm-start'>
              <Typography variant='h1' className='mb-3'>
                {this.state.reset || pwdReset ? "Reset Password" : "Log in"}
              </Typography>
              {!pwdReset && (
                <Typography variant='h4' className={`mb-4 ${classes.info}`}>
                  {this.state.reset
                    ? "Instructions to reset the password will be sent on the email provided below"
                    : "Log in with your email and password to access Fitbudd trainer portal"}
                </Typography>
              )}
              {!!pwdReset ? (
                <ResetPassword enqueueSnackbar={this.props.enqueueSnackbar} />
              ) : (
                this.renderInner()
              )}
              <div className={`w-100 mw-450 ${classes.textAlignCenter}`}>
                <div className={`mt-3 ${classes.info}`}>
                  Don’t have an account?
                  <Button
                    classes={{ label: "default" }}
                    component='a'
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://www.fitbudd.com/#contact-section'
                  >
                    Contact us
                  </Button>
                </div>
                <div className='mt-2'>
                  <Button
                    onClick={this.toggleReset}
                    classes={{ label: "default" }}
                  >
                    {this.state.reset ? "❬ Back" : "Forgot password ❭"}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <LogoTop />
        <Typography
          variant='caption'
          onClick={this.onGoogle}
          className='position-absolute text-muted p-2 cursor-pointer'
          style={{ bottom: 0, left: 0 }}
        >
          Fitbudd Inc &copy; {new Date().getFullYear()}
        </Typography>
      </div>
    );
  }

  componentDidMount() {
    if (window.location.pathname === "/reset-password")
      this.setState({ pwdReset: true });
  }
}

const LogoTop = () => (
  <>
    <Logo
      id='logo2'
      height={48}
      color='fancy-white'
      align='center'
      bp='sm'
      className='position-absolute p-4'
    />
    <Logo
      id='logo3'
      height={48}
      color='fancy-black'
      align='center'
      className='d-sm-none position-absolute p-4 w-100'
    />
  </>
);

export default withSnackbar(withStyles(useStyles)(Login));
