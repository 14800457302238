import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Typography, makeStyles, IconButton, InputBase, InputAdornment, Collapse, Paper } from '@material-ui/core';
import clsx from 'clsx';
import { CardText } from './index';
import _ from 'lodash';
import { useToggle } from 'fitbud/hooks/form';
import ClearIcon from '@material-ui/icons/Clear';
import searchIcon from 'fitbud/images/searchIcon.svg';
import { replaceAllSafe } from 'fitbud/utils/helpers';
import NoResultFound from 'fitbud/components/catalog/NoResultFound';

const useClasses = makeStyles(() => ({
  paper: {
    borderRadius: 6,
  },
  input: {
    borderRadius: '6px',
    height: 40,
    // border: '1px solid #D3D9DB',
    background: '#fff',
    padding: '0px 10px',
  },
  icon16: {
    height: 16,
    width: 16,
  },
  noResult: {
    paddingTop: 105, // to keep gap 105 + 15
    paddingBottom: 120,
  },
}));

const SearchInput = (props) => {
  const classes = useClasses();
  const { className, ...rest } = props;
  return (
    <Paper elevation={1} className={clsx(className, classes.paper)}>
      <InputBase
        fullWidth
        autoFocus
        startAdornment={
          <InputAdornment position="start">
            <img alt="search-icon" src={searchIcon} className={classes.icon16} />
          </InputAdornment>
        }
        className={classes.input}
        {...rest}
      />
    </Paper>
  );
};

const ExerciseProgress = (props) => {
  const { data, handleViewSelect, view, mCount } = props;
  const [searchTxt, setSearchTxt] = useState('');
  const [search, toggleSearch] = useToggle();
  const [displayList, setDisplayList] = useState([]);
  const debounceRef = useRef(null);
  const classes = useClasses();
  const scrollPotion = useRef(0);
  const listMaxHeight = useRef(0);

  const list = useMemo(() => {
    const o= _.chain(data)
      .values()
      .map(({key,name}) => ({ key: key, title: name }))
      .sortBy(['title'])
      .value();
      return o;
  }, [data]);

  const N = list.length;
  const displayCount = displayList.length;

  const getDisplayList = useMemo(() => {
    const fn = _.debounce((q, list) => {
      let out = [];
      q = (q || '').trim();
      q = q.toLowerCase();
      if (!q) out = list;
      else
        out =
          list.filter(({ title } = {}) => {
            title = title.toLowerCase();
            return title.includes(q);
          }) || [];
      setDisplayList(out);
    }, 300);
    debounceRef.current = fn;
    return fn;
  }, []);

  useEffect(() => {
    getDisplayList(searchTxt, list);
  }, [searchTxt, list]);

  const handleToggleSearch = () => {
    setSearchTxt('');
    if (!search) {
      const el = document.getElementById('progress_options');
      if (!!el && scrollPotion.current) el.scrollTo({ top: scrollPotion.current, behavior: 'smooth' });
    }
    toggleSearch();
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setSearchTxt(value);
  };

  useEffect(() => {
    //calculate starting scroll Position:
    let sp = 15 + 60 + 60 + 15; //top padding, checking and compare action.
    if (mCount) {
      sp = sp + 25 + 19; // measurement  title
      sp = sp + mCount * (60 + 15);
    }
    // sp = sp + 25; //progress top margin
    scrollPotion.current = sp + 10; //10 extra for top alignment

    //calculate list maxHeight:
    listMaxHeight.current = N * 75;
  }, [mCount, N]);

  useEffect(() => {
    return () => {
      debounceRef.current && debounceRef.current.cancel();
    };
  }, []);

  if (!N) return null;
  return (
    <div>
      <div className="d-flex align-items-center mb-15 mt-25" id="exercise_progression">
        <Typography className="flex-grow-1">Progression</Typography>
        <IconButton size="small" edge="end" onClick={handleToggleSearch}>
          {search ? <ClearIcon /> : <img src={searchIcon} className={classes.icon16} />}
        </IconButton>
      </div>
      <Collapse in={search} unmountOnExit>
        <SearchInput placeholder="Search exercise name" className="mb-15" value={searchTxt} onChange={handleChange} />
      </Collapse>
      <div style={{ minHeight: listMaxHeight.current }}>
        {displayList &&
          displayList.map(({ key, title }, index) =>
            data[key] ? (
              <CardText
                type={'ex'}
                data={data[key]}
                key={key + index}
                title={title}
                className={index > 0 && 'fmt-15'}
                view={view}
                handleViewSelect={handleViewSelect}
                keyword={key}
              />
            ) : null
          )}
        {!!searchTxt && !displayCount && <NoResultFound labelClassName="font_15_600" className={classes.noResult} />}
      </div>
    </div>
  );
};

export default ExerciseProgress;
