import React from 'react';
import ImageFileUpload from 'fitbud/components/imageSelector';
import { UpArrowIcon } from 'fitbud/icons/upArrow';
import { uploaderStyles } from './mediaUploader';

const thumbnailMsg = 'Upload Image';
const thumbnailMsgSec = '1080x1080 • Max 5 mb';
const MultiImageUploader = ({
  media,
  setMedia,
  maxImage,
  imgAspect = 1,
  size = 'small',
  secPlaceholderText = thumbnailMsgSec,
}) => {
  const classes = uploaderStyles();
  const imgs = (media || []);
  const count = imgs.length;
  const isCountLimit = maxImage === undefined  || maxImage === null ? false : true;
  const isEmpty = (!imgs || !imgs.length || !imgs[0].url);
  const onChange = (e) => {
    if (!setMedia) return;
    const copy = [...imgs].filter(x => (x && x.url && x.type === 'image'));
    const target = e.target.name;
    const url = e.target.value;
    if (target === 'upload') {
      copy.push({ type: 'image', url });
    } else {
      const index = Number(target.split('-').pop());
      if (url) copy.splice(index, 1, { type: 'image', url });
      else copy.splice(index, 1);
    }
    setMedia(copy);
  };
  return (
    <div className='d-flex flex-row flex-wrap mx-n10 mt-n20'>
      {imgs.map((x, i) => {
        if (!x || !x.url || x.type !== 'image') return null;
        return (<ImageFileUpload key={i + x.url} name={'img-' + i}
        aspectRatio={1.0} size={size} onChange={onChange}
        thumbnail={x.url} className='mt-20 mx-10'/>);
      })}
      {(!isCountLimit || !!isEmpty || maxImage > count ) &&  <ImageFileUpload key={'upload' + (isEmpty ? 0 : imgs.length)} name='upload' /* resetting the key to reset the component for next upload*/
        aspectRatio={imgAspect || 1.0} onChange={onChange} size='small'
        text={thumbnailMsg} secondaryText={secPlaceholderText}
        className='mt-20 mx-10'
        UploadIconComponent={() => (
          <div className={classes.uploadIcon}>
            <UpArrowIcon style={{ fill: 'none' }} />
          </div>
        )}/>}
    </div>
  );
};

export default MultiImageUploader;
