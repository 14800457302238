import { CircularProgress, Fade } from '@material-ui/core';
import { hasLength } from 'fitbud/utils/helpers';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useUserContext } from '../detail';
import { findPlanPeriod } from '../helpers';
import { dateRangeToDisplay } from '../progress/timeLineNav';
import { useProgressTrendsContext } from '../progTrendsProvider';
import { ActivityInfoCard, CheckinsInfoCard, ProgNMeasurementsInfoCard,  WeeklyTrendsCards } from './infoCards';

const LeftSection = React.memo(({ compChkinConf, loadCheckinsAggreagate, checkinsAggregate=[], checkinAggPhotosArray=[], hasCheckinAccess=false }) => {
  const { planStartDate, planTotalDuration, aplan, changeView, docId, userDoc  } = useUserContext();
  const { loadProgressData, calculateProgress, complianceConfig,  
  measurementsAggregate, loadAggMeasurementsData, measurableTags, loadExerciseData, exerciseData } = useProgressTrendsContext();
  const [isLoading, setLoading] = useState(false);
  const [progressDataSum, setProgressDataSum] = useState({});
  const [selectedPeriod, setSelectedPeriod] = useState(false)

  useEffect(() => {
    let unMount = false;
    if(!planStartDate || !planTotalDuration) return;
    let promises = [loadProgressData(), loadAggMeasurementsData(), loadExerciseData()];
    if(!!loadCheckinsAggreagate && (!checkinsAggregate || !checkinsAggregate.length))
      promises.push(loadCheckinsAggreagate());
    const _selectedPeriod = findPlanPeriod(moment().toDate(), planStartDate, planTotalDuration , 'weekly');
    setLoading(true);
    Promise.all(promises).then(()=> {
      if(unMount) return;
      setSelectedPeriod({
        ..._selectedPeriod,
        text: dateRangeToDisplay(_selectedPeriod.startDate, _selectedPeriod.endDate, "DD", "DD MMM YYYY"),
      });
      setProgressDataSum(calculateProgress(_selectedPeriod));
      setLoading(false);
    })
    return () => unMount = true;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[userDoc, calculateProgress, aplan]);

  if(!aplan) return null;
  if(isLoading){
    return (
      <div className={` d-flex h-100 position-relative align-items-center justify-content-center`}>
        <CircularProgress />
      </div>
    )
  }
  return (
    <Fade in={!isLoading}>
      <div>
        {!!hasCheckinAccess && !!checkinsAggregate && !!checkinsAggregate.length && (
          <div className='fmb-15'>
            <CheckinsInfoCard
              compChkinConf={compChkinConf}
              setLoading={setLoading}
              data={checkinsAggregate}
              userDoc={userDoc}
              docId={docId}
              checkinAggPhotosArray={checkinAggPhotosArray}
              goTo={(e) => changeView(e, 'progress')}
            />
          </div>
        )}
        {!!progressDataSum && (
          <WeeklyTrendsCards
            data={{
              workout: progressDataSum['workout'],
              nutrition: progressDataSum['nutrition'],
              water: progressDataSum['water'],
            }} 
            selectedPeriod={selectedPeriod} 
            complianceConfig={complianceConfig}
            goTo={(e) => changeView(e, 'trends')}
          />
        )}
        {!!measurementsAggregate && hasLength(measurementsAggregate) && (
          <div className='fmt-15'>
            <ProgNMeasurementsInfoCard 
              data={measurementsAggregate}
              type='mea'
              selectedPeriod={selectedPeriod} 
              measurableTags={measurableTags}
              goTo={(e) => changeView(e, 'progress')}
            />
          </div>
        )}
        {!!exerciseData && hasLength(exerciseData) && (
          <div className='fmt-15'>
            <ProgNMeasurementsInfoCard 
              data={exerciseData}
              type='prog'
              selectedPeriod={selectedPeriod} 
              measurableTags={measurableTags}
              goTo={(e) => changeView(e, 'progress')}
            />
          </div>
        )}
        {!!progressDataSum && (
          <div className='fmt-15'>
            <ActivityInfoCard 
              data={{
                steps: progressDataSum['steps'],
                energy: progressDataSum['energy'],
                distance: progressDataSum['distance'],
                flights: progressDataSum['flights'],
              }} 
              selectedPeriod={selectedPeriod}
              complianceConfig={complianceConfig['activity']}
              goTo={(e) => changeView(e, 'trends')}
            />
          </div>
        )}
      </div>
    </Fade>
  );
});
export default LeftSection;
