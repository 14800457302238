import React from 'react';
import { useSelector } from 'react-redux';
import FileUploadProgress from 'fitbud/components/fileUploadProgress';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
// import { NextSessionBar } from 'fitbud/views/liveSessions/NextSessionBar';
import NextSessionBar from 'fitbud/views/liveSessions/nextSessionBarV2';
import AppAlertContextProvider, {useAppAlertContext} from 'fitbud/providers/appAlertProvider';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  container: {
    bottom: 100,
    right: '0px',
    zIndex: theme.zIndex.drawer + 3,
  },
}));

const AppAlert = (props) => {
  return (
    <AppAlertContextProvider>
      <AppAlertContent {...props} />
    </AppAlertContextProvider>
  );
};

const AppAlertContent = (props) => {
  const { isVideoCalling } = props;
  const files = useSelector((state) => state.fileUpload.files);
  const {isOverTheTop } = useAppAlertContext();
  let _styles = {};
  if(isOverTheTop) _styles = {zIndex:3000}

  const classes = useStyles();
  return (
    <div className={clsx(classes.container, 'position-fixed')} style={_styles}>
      <div>
        {isVideoCalling && <NextSessionBar />}
        {files && Array.isArray(files) && files.length > 0 && <FileUploadProgress />}
      </div>
    </div>
  );
};

const useAlertStyles = makeStyles(() => ({
  container: {
    padding: '8px 8px 8px 10px',
    borderTopLeftRadius: 15,
    borderBottomLeftRadius: 15,
    backgroundColor: '#37404D',
    right: '-45px',
    zIndex: 1001,
    transition: 'right 0.2s',
    '& .expand_icon': {
      // transition: 'transform 0.2s',
      position: 'absolute',
      left: 5,
    },
    '&:hover': {
      right: 0,
      '& .expand_icon': {
        // transform: "rotate(180deg)",
        display: 'none',
      },
    },
  },
}));

const Icon1 = (props) => {
  return (
    <svg width="5" height="12" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M6 13L1 7L6 1" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export const AppAlertIndicator = (props) => {
  const { children, onClick, className } = props;
  const classes = useAlertStyles();
  const { isOverTheTop } = useAppAlertContext();
  let _styles = {};
  if(isOverTheTop) _styles = {zIndex:3001}
  return (
    <div onClick={onClick} className={clsx('fmb-20  cursor-pointer position-absolute', className, classes.container)} style={_styles}>
      <div className="d-flex align-items-center">
        <Icon1 className="expand_icon" />
        <IconButton style={{ padding: '12px' }} >
          {children}
        </IconButton>
      </div>
    </div>
  );
};

export default AppAlert;
