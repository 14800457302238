import React, { useContext, useState } from 'react';

export const AppAlertContext = React.createContext({});

const AppAlertContextProvider = (props) => {
  const { children } = props;
  const [expendedAlertType, setExpandedAlertType] = useState(null);
  const [isOverTheTop, setIsOverTheTop] = useState(false);
  return (
    <AppAlertContext.Provider
      value={{
        expendedAlertType,
        setExpandedAlertType,
        isOverTheTop,
        setIsOverTheTop,
      }}
    >
      {children}
    </AppAlertContext.Provider>
  );
};

export default AppAlertContextProvider;

export const useAppAlertContext = () => {
  return useContext(AppAlertContext);
};
