import React from "react";
import { connect } from "react-redux";
import {
  withStyles,
  CircularProgress,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import appRdxFns from "../redux/app/";

const BlockingLoader = (props) => {
  const { classes } = props;
  return (
    <Dialog
      id="dialog-loading"
      open={props.loaderCount > 0}
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ root: classes.root, paper: classes.dialogPaper }}
      onClose={props.hideLoader}
    >
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        <CircularProgress
          classes={{
            colorPrimary: classes.CircularProgress,
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

const styles = (theme) => ({
  root: {
    zIndex: "1301!important",
  },
  dialogPaper: {
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  dialogContentRoot: {
    backgroundColor: "transparent",
  },
  CircularProgress: {
    color: "white",
  },
});

const mapStateToProps = (state) => {
  return {
    loaderCount: state.app.loaderCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { hideLoader } = appRdxFns(dispatch);
  return {
    hideLoader,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(BlockingLoader));
