import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/messaging';
import  "firebase/functions";

// Initalize Firebase.
firebase.initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE_CONF));

const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null

if(!!messaging) {
  messaging.usePublicVapidKey(process.env.REACT_APP_FCM_KEY || "BN7tGjd2OIZQmC6-UXzND_RgHYm6lyV_k1M3FfpiFNM2YwMmYiqKUao4vMzRNVXsLHciQRWbDwggg6bqLObRTzc");
}

// export Firebase.
export default firebase;
export const functions = firebase.functions()
export const logout = () => firebase.auth().signOut().then(res => !!window && window.localStorage.removeItem("cid"), !!window && window.history.pushState('', '', '/')).finally(err => !!window && window.location.reload());

export { messaging }
