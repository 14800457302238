const buckets = {
  chat: [640, 1280, 1920],
  checkins: [640, 1280, 1920]
};
const dp = (s, bucketName) => {
  const bucketSizes = buckets[bucketName];
  if(!bucketSizes) return null;
  return Math.min(
    Math.max(...bucketSizes),
    Math.round(s * (window.devicePixelRatio || 1))
  );
};

const isThumborable = value => {
  if (!value || typeof value !== "string") return null;
  const tmp = value.trim();
  if (tmp.length === 0) return null;
  if (tmp.startsWith("http://") || tmp.startsWith("https://")) return null;
  return tmp;
};

const getThumborUrlByAspect = (value, w = 0, aspect = 0, bucketName = null) => {
  const tmp = isThumborable(value);
  if (!tmp) return value;
  const cantCompute = !bucketName || !w || !aspect || w <= 0 || aspect <= 0;
  if (cantCompute) return null;
  const bucketSizes = buckets[bucketName];
  if(!bucketSizes) return null;
  let size;
  if (aspect < 1) {
    const h = Math.round(dp(w, bucketName) / aspect);
    if (h > Math.max(...bucketSizes)) {
      size = Math.max(...bucketSizes);
    } else {
      size = bucketSizes.find(s => s >= h);
    }
  } else {
    if (dp(w, bucketName) > Math.max(...bucketSizes)) {
      size = Math.max(...bucketSizes);
    } else {
      size = bucketSizes.find(s => s >= dp(w, bucketName));
    }
  }
  return `${size}/${tmp}`;
};

export { isThumborable, getThumborUrlByAspect };
