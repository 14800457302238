import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const NutritionIcon = props => {
  const {stroke, ...rest} = props;
  return (
    <SvgIcon viewBox="0 0 20 20" classes={{root: 'small empty'}} {...rest}>
      <path stroke={stroke || '#8F969F'} d="M16.5 17V11.5M16.5 11.5V4.11117C16.5 3.55432 15.967 3.15228 15.4316 3.30526V3.30526C13.9923 3.71649 13 5.03201 13 6.52889V11.5H16.5Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path stroke={stroke || '#8F969F'} d="M6.5 3V17M3.5 3V6.5C3.5 8.15685 4.84315 9.5 6.5 9.5V9.5C8.15685 9.5 9.5 8.15685 9.5 6.5V3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </SvgIcon>
  );
};
