import React, { useState, useContext, useEffect } from 'react';
import firebase from "fitbud/firebase";
import _ from 'lodash';
import { Drawer } from '@material-ui/core';
import { UserSchContext } from './provider';
import { WorkoutTracker, MealTracker } from "../users/schedulePlanTracker";
import SupplementInfo from "fitbud/views/supplements/info";
import { DrawerHeaderInScheduleEditor } from "fitbud/views/users/subComponents";

const UserTracker = () => {
    const { aplan, showCardDetails, controller, objCache, } = useContext(UserSchContext);
    const [data, setData] = useState(showCardDetails);
    const [aggrs, setAggrs] = useState(null);
    const [dayWiseData, setDayWiseData] = useState({});
    const closeDrawer = controller.hideDetail;
    const isManualWorkout = _.get(showCardDetails, 'meta.type') === 'manual';
    const isWorkout = (_.get(showCardDetails, 'meta.type') === 'workout'|| _.get(showCardDetails, 'meta.dtype') === 'workout') || (_.get(showCardDetails, 'meta.type') === 'cardio'||_.get(showCardDetails, 'meta.dtype') === 'cardio') || isManualWorkout;
    const isSupplement=_.get(showCardDetails,'meta.type')==="supplement"||_.get(showCardDetails,'meta.dtype')==="supplement";
    const [cid, uid] = aplan.split(':')
    const daywiseRef = firebase.firestore().collection(`user_profiles/${cid}:${uid}/daywise`);
    const type = isWorkout ? 'workout' : 'nutrition';
    useEffect(() => {
        if (showCardDetails) {
            const { past, meta, todo } = showCardDetails;
            if(isSupplement){//case:supplement->always pick from meta
                setData({...meta});
                return;
            }
            const aggregate = isWorkout ? showCardDetails.log : showCardDetails.done;
            const aggregateExists=_.isObject(aggregate)?!!Object.keys(aggregate).length:!!aggregate;
            if (!aggregateExists) {
                if (!past) {
                    const id = isWorkout ? showCardDetails.id : todo && todo.id;
                    if(id==='bmr'){
                        setData(todo || dayWiseData);
                        return;
                    }
                    if (id) {
                        const collection=isWorkout?'workouts':'meals';
                        firebase.firestore().doc(`companies/${cid}/${collection}/${id}`).get().then(doc => {
                            setData({...doc.data(),id});
                        }).catch(e => {
                            console.log(e);
                        });
                    } 
                } else {
                    setData(isWorkout ? meta : todo);
                }

            } else {
                const key = isWorkout ? showCardDetails.log.id : showCardDetails.date.format('YYYYMMDD');
                daywiseRef.doc(`${key}:${type}:${aplan}`).get()
                    .then(snap => {
                        const dayWiseData = snap.data()||{};
                        setDayWiseData(dayWiseData);
                        if (isWorkout){
                            if(isManualWorkout)
                                setData({...(dayWiseData||{}),...(dayWiseData.meta||{})})
                                // setData({...(dayWiseData.meta||{}),...(showCardDetails.meta||{})});
                            else setData(dayWiseData.meta || showCardDetails.meta);
                        }
                        else
                            setData(todo || dayWiseData);
                    })
            }
            setAggrs(aggregate);
        } else {
            if (data) setData(null);
            if (dayWiseData) setDayWiseData({});
            if (aggrs) setAggrs(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showCardDetails]);
    if (!data) return null;
    const { date: dayDate } = showCardDetails || dayWiseData || {};
    return <Drawer open={!!showCardDetails} anchor="right" onClose={closeDrawer}>
        {!isWorkout && !isSupplement && <MealTracker isUser
                data={data}
                dayWiseData={dayWiseData}
                dayDate={dayDate}
                objCache={objCache}
                closeDrawer={closeDrawer}
                docId={data && data.id}
            />
        }
        {isWorkout && <WorkoutTracker isUser
            data={data}
            dayDate={dayDate}
            aggregate={aggrs}
            dayWiseData={dayWiseData}
            closeDrawer={closeDrawer}
            objCache={objCache}
        />}
        {isSupplement && <>
         <DrawerHeaderInScheduleEditor
            closeDrawer={closeDrawer}
            title={"supplement"}
        />
        <SupplementInfo
            id={data.id}
            scheduleDoc={data}
            // scheduleSaveSuppEdit={saveEdit}
            // edit={editable}
            disableActions
            objCache={objCache}
            // createNew
            userProfileId={uid}
            isPreview={true}
            // displayEditOption={editable}
        /></>
        } 
    </Drawer>
        ;
};

export default UserTracker;
