import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {
  IconButton, Dialog,
  DialogContent, DialogTitle,
  withMobileDialog, withStyles,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseRounded';
import { withSnackbar } from "notistack";
import firebase from "fitbud/firebase";
import {doesHashMatch, removeLocationHash} from 'fitbud/utils/RouterHash';
import {
  PasswordField,
  SubmitButton
} from 'fitbud/components/form-fields';

class ChangePasswordDialog extends Component {
  constructor(props) {
    super(props)
    this.state = this.initialState()
  }

  initialState = () => {
    return {
      ...INITIAL_STATE,
    }
  }

  render() {
    const {classes} = this.props;
    const {fullScreen} = this.props;
    const {
      loading, old_pwd, new_pwd, err_old_pwd, err_new_pwd
    } = this.state;
    return (
      <Dialog id='dialog-changePassword'
        open={doesHashMatch(this.props, 'change-password')}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        onClose={this.onClose}
        fullScreen={fullScreen}>
        <DialogTitle disableTypography className='text-center'>
          <IconButton color='primary'
            disabled={loading}
            onClick={this.onClose}
            style={{position: 'absolute', top: 0, left: 0}}>
            <CloseIcon/>
          </IconButton>
          <Typography className='font-weight-bold'
            align='center' variant='title'>
            Change Password
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.root} style={this.fixFormStyle()}>
          <form onSubmit={this.doApiCall} className='fpb-10'>
            <PasswordField required id='old_pwd' label='Old Password' fullWidth
              className='mb-3' autoComplete='current-password'
              disabled={loading} variant="outlined"
              error={!!err_old_pwd}
              helperText={err_old_pwd}
              value={old_pwd} onChange={this.handleChange}/>
            <PasswordField required id='new_pwd' label='New Password' fullWidth
              className='mb-3' autoComplete='new-password'
              disabled={loading} variant="outlined"
              error={!!err_new_pwd}
              helperText={err_new_pwd}
              value={new_pwd} onChange={this.handleChange}/>
            <SubmitButton variant='contained' color='primary'
              loading={loading} fullWidth size='large' className='f-height-36'>
              Update
            </SubmitButton>
          </form>
        </DialogContent>
      </Dialog>
    )
  }

  doApiCall = (e) => {
    e.preventDefault()
    if (!this.validate())
      return
    this.setState({loading: true})
    const {old_pwd, new_pwd} = this.state;
    var user = firebase.auth().currentUser;
    var credential = firebase.auth.EmailAuthProvider.credential(user.email, old_pwd);
    user.reauthenticateWithCredential(credential)
      .then(() => user.updatePassword(new_pwd))
      .then(() => {
        this.props.enqueueSnackbar("Password updated", { variant: "success" });
        this.onClose();
      })
      .catch((e) => {
        this.props.enqueueSnackbar(e.message, { variant: "error" });
        this.setState(INITIAL_STATE);
      })
  }

  validate = () => {
    const {old_pwd, new_pwd} = this.state
    const errors = {...CLEAR_ERRS}
    let out = true
    if (!old_pwd) {
      errors.err_old_pwd = 'Please provide a valid password'
      out = false
    }
    if (!new_pwd) {
      errors.err_new_pwd = 'Please provide a valid password'
      out = false
    }
    this.setState(errors)
    return out
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    })
  }

  onClose = () => {
    this.setState(this.initialState())
    removeLocationHash(this.props);
  }

  fixFormStyle = () => {
    return {maxWidth: (this.props.fullScreen ? '100%' : '360px')}
  }
}

const CLEAR_ERRS = {
  err_old_pwd: undefined,
  err_new_pwd: undefined
}
const CLEAR_BOOLS = {
  loading: false,
}
const INITIAL_STATE = {
  old_pwd: '',
  new_pwd: '',
  ...CLEAR_BOOLS,
  ...CLEAR_ERRS
}
const styles = (theme) => ({})


export default withRouter(withSnackbar(
    withStyles(styles, {withTheme: true})(
      withMobileDialog({breakpoint: 'xs'})(
        ChangePasswordDialog))));
