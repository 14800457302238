import React, {useState } from "react";
import {
  TextField,
} from "@material-ui/core";
import { FormTextField } from "fitbud/components/form-fields";
import Dialog from "fitbud/components/Dialog";
import Confirmation from "../../components/confirmationDialog"
import ImageFileUpload from "fitbud/components/imageSelector";
import { CustomConfirmation } from "fitbud/components/customConfirmation";




const CreateEditForm = props => {
  const [isConfirmationOpen, showConfirmation] = useState(false);
  const [dirty, setDirty] = useState(false);
  const {
    doc,
    errors,
    loading,
    isNew,
    onSubmit,
    handleChange,
    onCancel,
    handleDraft,
    handlePublish,
    draftFeature,
    isDraftAvailable
  } = props;

  const { ref_name = '', title = '', thumbnail = '', description = '' } = doc;
  const handleChangeWrapper = e => {
    setDirty(true)
    handleChange(e)
  };

  const openConfirmationDialog = () => {
    if (dirty) {
      showConfirmation('cancel');
    } else {
      onCancel();
    }
  }
  const handleSubmit=()=>{
    if(!isNew && !dirty && !isDraftAvailable){
      onCancel();
      return;
    }
    if(isNew || !draftFeature){
      onSubmit();
    }
    else{
      showConfirmation('save');
    }
  }

  return (
    <Dialog
      toolbarClass="height-60"
      buttonColor="primary"
      open
      onClose={openConfirmationDialog}
      onSave={handleSubmit}
      title={isNew ? "Add Supplement Plan" : "Edit Supplement Plan"}
      titleFont="h3"
      paperClass="width-600"
    >
      <div className="fp-20">
        <div className="d-flex fmb-20">
          <div className="flex-1 fmr-20 d-flex flex-column justify-content-between">
            <FormTextField fullWidth label="Reference Name" required>
              <TextField
                id="ref_name"
                autoComplete="off"
                value={ref_name}
                onChange={handleChangeWrapper}
                error={!!errors.ref_name}
                helperText={errors.ref_name}
                variant="outlined"
                disabled={loading}
                required
                type="text"
                InputProps={{
                  classes: {
                    root: "medium ",
                    input: "size_16_500"
                  }
                }}
              />
            </FormTextField>
            <FormTextField fullWidth label="Display Name" required classes={{control:"mb-0"}}>
              <TextField
                InputProps={{
                  classes: {
                    root: "medium",
                    input: "size_16_500"
                  }
                }}
                id="title"
                onChange={handleChangeWrapper}
                value={title}
                error={!!errors.title}
                helperText={errors.title}
                variant="outlined"
                disabled={loading}
                required
              />
            </FormTextField>
          </div>
          <ImageFileUpload
            thumbnail={thumbnail}
            id="thumbnail"
            onChange={handleChangeWrapper}
            size="small"
          />
        </div>
        <FormTextField fullWidth label="Description" classes={{control:"mb-0"}}>
          <TextField
            multiline
            rows="2"
            rowsMax="10"
            id="description"
            fullWidth
            onChange={handleChangeWrapper}
            value={description}
            error={!!errors.description}
            helperText={errors.description}
            variant="outlined"
            disabled={loading}
            required
            InputProps={{
              classes: {
                root: "large",
                input: "size_16_500"
              }
            }}
          />
        </FormTextField>
      </div>
      {isConfirmationOpen && (
        <>
          {(isNew ||!draftFeature)?(
            <Confirmation
            open
            handleChange={onCancel}
            handleCancel={() => showConfirmation(null)}
            />
          ):(
            <CustomConfirmation
              handleClose={() => showConfirmation(null)}
              handleDiscard={onCancel}
              handleDraft={handleDraft}
              handlePublish={handlePublish}
              dirty={dirty}
              showDiscard={dirty}
            />
          )

          }
        </>
      
      )}
    </Dialog>
  );
};

export default CreateEditForm;
