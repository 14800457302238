import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { withSnackbar } from 'notistack';
import update from 'immutability-helper';
import {
  TextField,
  Typography,
  MenuItem,
  Grid,
  InputAdornment,
  Select,
  OutlinedInput,
  Divider,
  FormControl,
  FormLabel,
} from '@material-ui/core';
import Confirmation from "fitbud/components/confirmationDialog"
import {CustomConfirmation} from "fitbud/components/customConfirmation";
import { FormTextField } from 'fitbud/components/form-fields';
import Dialog from 'fitbud/components/Dialog';
import { roundNumber, countDecimals } from 'fitbud/utils/helpers';
import moment from 'moment';
import SortBy from './sortBy';
import CategoryList from './categoryList';

import { ExpandMore as KeyDown } from '@material-ui/icons/';
import { PUBLISHED,DRAFT } from 'fitbud/utils/constants';

const _defaultGroup = [
  { type: 'c19c9e78-9332-4aac-b799-1430944a6afc', order: 1 },
  { type: 'e72cde6b-c1b3-46b6-9022-9a33b7601316', order: 3 },
  { type: '72a24eed-7afb-47f9-8b44-f60a812ed331', order: 4 },
];

const calMacrosPerValue = (restMacros, calories, autoCal) => {
  const fatPer = parseFloat((restMacros.fat * 9 * 100) / calories);
  const proteinPer = parseFloat((restMacros.protein * 4 * 100) / calories);
  const carbsPer = parseFloat((restMacros.carbs * 4 * 100) / calories);
  switch (autoCal) {
    case 'protein':
      return 100 - (fatPer + carbsPer);
    case 'fat':
      return 100 - (proteinPer + carbsPer);
    default:
      return 100 - (fatPer + proteinPer);
  }
};

const Macros = (props) => {
  const { macros, handleMacros, errors, units, perValue, item, index, autoCal, enqueueSnackbar } = props;
  let { calories, ...restMacros } = macros;
  const { fat, protein, carbs } = restMacros;
  const handleMacrosChange = (e) => {
    let value = Number(e.target.value);
    if (countDecimals(value) > 2) return;

    if (units === 'percentage') {
      const cals = e.target.id === 'fat' ? 9 : 4;
      value = Number(((value * calories) / (cals * 100)).toFixed(1));
    }

    if (['protein', 'fat', 'carbs'].includes(e.target.id)) {
      let usedCals = 0;
      if (e.target.id === 'fat') {
        const val = autoCal === 'carbs' ? protein : carbs;
        usedCals = usedCals + (value * 9 + val * 4);
      } else if (e.target.id === 'protein') {
        const val = autoCal === 'carbs' ? fat * 9 : carbs * 4;
        usedCals = usedCals + (value * 4 + val);
      } else if (e.target.id === 'carbs') {
        const val = autoCal === 'fat' ? protein * 4 : fat * 9;
        usedCals = usedCals + (value * 4 + val);
      }
      const remainingValue = Number(((calories - usedCals.toFixed(0)) / 4).toFixed(1));
      if (remainingValue < 0 && calories > 0)
        return enqueueSnackbar(
          'The sum total of calories from Protein, Fat & Carbs cannot exceed the total Calories.',
          {
            variant: 'error',
          }
        );
    }
    handleMacros(e, autoCal);
  };

  return (
    <Grid item xs className={`fp-0 ${index !== props.thisArg.length - 1 ? 'fpr-20' : ''}`}>
      <FormControl fullWidth className="fpb-20">
        <FormLabel className="fmb-10">
          <div className="d-flex justify-content-between">
            <Typography variant={'body2'} color="textSecondary">
              {_.capitalize(item)}
            </Typography>
            {!isNaN(perValue) && perValue > 0 && (
              <Typography variant={'body2'} color="textPrimary">
                {units === 'percentage' ? roundNumber(props.value) : roundNumber(perValue)}
                {units === 'percentage' ? 'g' : '%'}
              </Typography>
            )}
          </div>
        </FormLabel>
        <TextField
          fullWidth
          id={item}
          type="number"
          onChange={handleMacrosChange}
          value={units !== 'percentage' ? roundNumber(props.value) : roundNumber(perValue)}
          error={!!errors && !!errors[item]}
          helperText={errors && errors[item]}
          variant="outlined"
          required
          disabled={item === autoCal}
          InputProps={{
            inputProps: {
              min: 0,
            },
            endAdornment: <InputAdornment position="end">{units === 'percentage' ? '%' : 'g'}</InputAdornment>,
            classes: {
              root: 'medium',
              input: 'size_16_500',
            },
          }}
        />
      </FormControl>
    </Grid>
  );
};

const MacrosForm = (props) => {
  const { doc, errors, loading, onSubmit, handleChange, handleMacros, onCancel, updateParent, mealCats,isNew,draftFeature,
    handleDraft,handlePublish,dirty,isDraftAvailable } = props;

  const { macros, units, sort_by, auto_calculate } = doc;
  let { calories, ...restMacros } = macros;

  let groups = props.doc.groups;
  if (!groups.length) {
    groups = _defaultGroup;
  }

  const [mealCategories, setMealCategories] = useState(groups);
  const [sortValue, setSortValue] = useState(sort_by || 'custom');
  const [timeUpdate, setTimeUpdate] = useState(false);
  const [autoCal, setAutoCal] = useState(auto_calculate || 'carbs');
  const [isConfirmationOpen, showConfirmation] = useState(null);

  const onRemove = (index) => {
    let updatedCategories = [...mealCategories];
    updatedCategories.splice(index, 1);
    // set the last category item  to manual
    if (updatedCategories.length && updatedCategories[updatedCategories.length - 1].manual) {
      updatedCategories = update(updatedCategories, {
        [updatedCategories.length - 1]: {
          manual: {
            $set: false,
          },
        },
      });
    }
    const { updatedMealCategories } = handleCaloriesChange(updatedCategories, calories);
    setMealCategories(updatedMealCategories, macros.calories);
    updateParent({ groups: updatedMealCategories });
  };

  const handleCaloriesChange = ([...items], calories) => {
    let usedCalories = 0;

    usedCalories = items.reduce((a, b) => {
      if (b.manual) {
        return a + b.calories;
      }
      return a;
    }, 0);

    let remainingCals = calories - usedCalories;

    const unChangesGrps = items.filter((a) => !a.manual);
    // If remain cals is negative then divide the cals equally in manual case
    if (remainingCals < 0) {
      const changesGrps = items.filter((a) => a.manual);
      const manualCals = calories / changesGrps.length;
      items.forEach((item, i, thisArg) => {
        if (item.manual) {
          items = update(items, {
            [i]: {
              calories: {
                $set: manualCals,
              },
            },
          });
        }
      });
    }

    remainingCals = remainingCals > 0 ? remainingCals : 0;

    remainingCals = remainingCals / unChangesGrps.length;

    if (unChangesGrps.length) {
      items.forEach((item, i) => {
        if (!item.manual) {
          items = update(items, {
            [i]: {
              calories: {
                $set: remainingCals,
              },
            },
          });
        }
      });
    }
    return { updatedMealCategories: items, remainingCals };
  };

  const onAddOptions = (e) => {
    let updatedCategories = [...mealCategories];
    let calories = '-----';
    for (let i = 0; i < mealCategories.length; i++) {
      if (mealCategories[i].manual || mealCategories[i].calories >= 0) {
        calories = 0;
        break;
      }
    }
    updatedCategories = [...updatedCategories, { calories, type: '', manual: false }];
    const { updatedMealCategories } = handleCaloriesChange(updatedCategories, macros.calories);

    setMealCategories(updatedMealCategories);
    updateParent({ groups: updatedMealCategories });
  };

  const removeTimePicker = (index) => {
    let out = update(mealCategories, {
      [index]: {
        $merge: {
          iftime: null,
        },
      },
    });

    const { updatedMealCategories } = handleCaloriesChange(out, macros.calories);
    setMealCategories(updatedMealCategories);

    updateParent({ groups: updatedMealCategories });
  };

  const handleMealTime = (index, date) => {
    if (!date) return;
    let time = moment(date).format('HH:mm');
    let key = 'iftime';
    let updatedMeal;
    updatedMeal = update(mealCategories, {
      [index]: {
        [key]: {
          $set: time,
        },
      },
    });
    const { updatedMealCategories } = handleCaloriesChange(updatedMeal, macros.calories);
    setMealCategories(updatedMealCategories);
    updateParent({ groups: updatedMealCategories });
    setTimeUpdate(true);
  };

  useEffect(() => {
    //apply sorting after time changes
    if (!!timeUpdate) {
      handleSort(sortValue);
    }
  }, [timeUpdate]);

  const handleSort = (value) => {
    let updatedMeal;
    if (value === 'time') {
      const sortByTime = _.sortBy(mealCategories, ['iftime']);
      updatedMeal = update(mealCategories, {
        $set: sortByTime,
      });
      const { updatedMealCategories } = handleCaloriesChange(updatedMeal, macros.calories);
      setMealCategories(updatedMealCategories);
      updateParent({ groups: updatedMealCategories });
    }
    setSortValue(value);
    setTimeUpdate(false);
  };

  const handleMealChange = (index) => (e) => {
    let updatedMeal;
    const key = [e.target.id || e.target.name];

    if (e.target.name === 'calories') {
      if (e.target.value === '' || isNaN(Number(e.target.value))) {
        e.target.value = 0;
      }

      let value = Number(e.target.value);
      updatedMeal = update(mealCategories, {
        [index]: {
          [key]: {
            $set: value,
          },
          manual: {
            $set: true,
          },
        },
      });
      // in case when the current cals sum is greater than previous cals
      const manualTotalCals = updatedMeal.reduce((a, b) => {
        if (b.manual) {
          return a + b.calories;
        }
        return a;
      }, 0);
      //if cals value greater than total cals and manual total cals
      if (value > calories || manualTotalCals > calories) return;
    } else {
      if (e.target.value === 'select') return;
      let order = mealCats.findIndex((cat) => cat[0] === e.target.value);
      order = order === -1 ? 1 : order + 1;
      updatedMeal = update(mealCategories, {
        [index]: {
          [key]: {
            $set: e.target.value,
          },
          order: {
            $set: order,
          },
        },
      });
      updatedMeal = updatedMeal.sort((a, b) => a.order - b.order);
      updatedMeal = update(updatedMeal, {
        [updatedMeal.length - 1]: {
          manual: {
            $set: false,
          },
        },
      });
    }
    const { updatedMealCategories } = handleCaloriesChange(updatedMeal, macros.calories);
    setMealCategories(updatedMealCategories);

    updateParent({ groups: updatedMealCategories });
  };

  const handleCalories = (e) => {
    e.target.value = Number(e.target.value);
    if (e.target.value.length > 10) return;
    if (countDecimals(e.target.value) > 2) return;
    const { updatedMealCategories } = handleCaloriesChange(mealCategories, e.target.value);
    setMealCategories(updatedMealCategories);
    handleMacros(e, autoCal);
    updateParent({ groups: updatedMealCategories });
  };

  const handleAutoCal = (e) => {
    const val = e.target.value;
    handleChange(e);
    setAutoCal(val);
  };
  const handleSubmit=(docType)=>{
    if (mealCategories.length <= 0) {
      props.enqueueSnackbar('Please add category to complete the meal plan', {
        variant: 'error',
      });
      showConfirmation(null);
      return;
    }
    if(docType===DRAFT)handleDraft();
    else handlePublish();
  }

  const handleSubmitClick=()=>{
    if(!dirty && !isDraftAvailable){
      onCancel();
      return;
    }
    if (mealCategories.length <= 0) {
      props.enqueueSnackbar('Please add category to complete the meal plan', {
        variant: 'error',
      });
    }
    else{
      if(draftFeature){
        showConfirmation('save');
      }
      else{
        handleSubmit(PUBLISHED);
      }
    }
  }
  const openConfirmationDialog=()=>{
    if (dirty) {
      showConfirmation('cancel');
    } else {
      onCancel();
    }
  }
  return (
    <Dialog
      toolbarClass="height-60"
      buttonColor="primary"
      open
      onClose={openConfirmationDialog}
      onSave={handleSubmitClick}
      title="Edit Macros"
      titleFont="h3"
      paperClass="width-700"
      // showBack={true}
    >
      <div className="py-20">
        <Grid container className="px-20">
          <Grid item xs={12} sm={6} className="fp-0 fpr-20">
            <FormTextField fullWidth label="Specify in">
              <Select
                fullWidth
                IconComponent={KeyDown}
                input={
                  <OutlinedInput
                    classes={{
                      root: 'medium',
                      input: 'size_16_500 select-medium',
                    }}
                  />
                }
                name="units"
                value={units}
                onChange={handleChange}
                required
              >
                <MenuItem key="percentage" value="percentage">
                  Percentage
                </MenuItem>
                <MenuItem key="gms" value="gms">
                  Grams
                </MenuItem>
              </Select>
            </FormTextField>
          </Grid>
          <Grid item xs className="fp-0 fpr-20">
            <FormTextField fullWidth label="Calories">
              <TextField
                fullWidth
                id="calories"
                type="number"
                onChange={handleCalories}
                value={roundNumber(macros.calories)}
                error={!!errors.macros && !!errors.macros.calories}
                helperText={errors.macros && errors.macros.calories}
                variant="outlined"
                disabled={loading}
                required
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                  endAdornment: <InputAdornment position="end">Cals</InputAdornment>,
                  classes: {
                    root: 'medium',
                    input: 'size_16_500',
                  },
                }}
              />
            </FormTextField>
          </Grid>
          <Grid item xs className="fp-0">
            <FormTextField fullWidth label="Auto Calculate">
              <Select
                fullWidth
                IconComponent={KeyDown}
                input={
                  <OutlinedInput
                    classes={{
                      root: 'medium',
                      input: 'size_16_500 select-medium',
                    }}
                  />
                }
                name="auto_calculate"
                value={autoCal}
                onChange={handleAutoCal}
                required
              >
                {['protein', 'fat', 'carbs'].map((item, index) => {
                  return (
                    <MenuItem key={index} value={item}>
                      {_.capitalize(item)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormTextField>
          </Grid>
        </Grid>
        <Grid container className="fpx-20">
          {['protein', 'fat', 'carbs'].map((item, i, thisArg) => {
            let perValue = 0;
            if (calories > 0) {
              if (item === autoCal && restMacros[autoCal] > 0) {
                perValue = calMacrosPerValue(restMacros, calories, autoCal);
              } else {
                const cals = item === 'fat' ? 9 : 4;
                perValue = (restMacros[item] * cals * 100) / calories;
                if (perValue > 100) perValue = 100;
              }
            }

            return (
              <Macros
                key={i}
                index={i}
                item={item}
                macros={macros}
                errors={errors.macros}
                handleMacros={handleMacros}
                enqueueSnackbar={props.enqueueSnackbar}
                units={units}
                perValue={perValue}
                value={restMacros[item]}
                thisArg={thisArg}
                autoCal={autoCal}
              />
            );
          })}
        </Grid>
        <Divider className="mb-20" />
        {/* <div className="d-flex align-items-center  fpb-10">
          <Typography caption="h6" component="div" className="col-3 fp-0">
            Meal Categories
          </Typography>
          <Divider className="col-9 fp-0" />
        </div> */}

        <div className="px-20">
          <Typography className="mb-10 font_18_600">Meal Categories</Typography>
          <SortBy  className="py-10" handleSort={handleSort} handleChange={handleChange} value={sort_by} />
          <CategoryList
            handleMealChange={handleMealChange}
            groups={props.doc.groups}
            errors={errors.groups}
            mealCats={mealCats}
            units={units}
            onRemove={onRemove}
            onAddOptions={onAddOptions}
            mealCategories={mealCategories}
            handleMealTime={handleMealTime}
            removeTimePicker={removeTimePicker}
            sortBy={sortValue}
            updateParent={updateParent}
          />
        </div>
      </div>
      {isConfirmationOpen && (
        <>
          {(isNew || !draftFeature)? (
            <Confirmation
              open
              handleChange={onCancel}
              handleCancel={() => showConfirmation(null)}
            />
          ) : (
            <CustomConfirmation
              handleClose={() => showConfirmation(null)}
              handleDiscard={onCancel}
              handleDraft={()=>handleSubmit(DRAFT)}
              handlePublish={()=>handleSubmit(PUBLISHED)}
              dirty={dirty}
              showDiscard={dirty}
            />
          )}
        </>
      )}
    </Dialog>
  );
};

export default withSnackbar(MacrosForm);
