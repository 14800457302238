import React, { useContext, useMemo, useState } from 'react';
import moment from 'moment';
import { TextField, InputAdornment, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import Dialog from 'fitbud/components/Dialog';
import { DatePicker } from '@material-ui/pickers';
import { multiplier } from 'fitbud/views/schedulePlanner/helper';
import { ToggleLabel } from 'fitbud/components/toggles';
import { usePicker, useInput } from 'fitbud/hooks/form';
import { UserSchContext } from '../provider';

const DTypes = [{value: 'weeks', label: 'Weeks'}, {value: 'months', label: 'Months'}, {value: 'days', label: 'Days'}];

const AddWeeksDialog = () => {
  const { opaddwks, startDate, local, controller } = useContext(UserSchContext);
  const { weeks = [] } = local.plan || {};
  const durationOriginal = 7 * weeks.length;
  const endDateOriginal = moment(startDate, 'YYYYMMDD').add(durationOriginal - 1, 'days');
  const [saving, setSaving] = useState(false);
  const [mode, setMode] = usePicker('duration');
  const {parsed, props, isValid} = useInput(1, 'number * 1:min 112:max');
  const [durationType, setDurType] = useState('weeks');
  const [endDate, setEndDate] = useState(endDateOriginal);
  const weeks2add = useMemo(() => {
    let weeks2add = 0;
    if (mode === 'duration') {
      const days2add = parsed * multiplier(durationType);
      weeks2add = Math.ceil(days2add / 7);
    } else {
      const days2add = endDate.diff(endDateOriginal, 'days');
      weeks2add = Math.ceil(days2add / 7);
    }
    return weeks2add;
  }, [mode, parsed, durationType, endDate, endDateOriginal]);
  const submit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isValid()) return false;
    setSaving(true);
    controller.addWeeksSave(weeks2add);
    setSaving(false);
  };
  if (!opaddwks) return null;
  return (
    <Dialog open onClose={controller.addWeeksCancel} formId='addweeksform' loading={saving}
      title='Extend Plan' actionText='Add' fullScreen={false} toolbarClass='height-60'>
      <form id='addweeksform' onSubmit={submit}>
        <div className='p-20'>
          <RadioGroup value={mode} onChange={setMode} className='d-flex flex-row'>
            <FormControlLabel className='p-0 mr-30'
              value='duration' label={'By Duration'}
              control={<Radio color='primary'/>}
              classes={{label: 'font_15_600'}}/>
            <FormControlLabel className='p-0'
              value='endDate' label={'Till Date'}
              control={<Radio color='primary'/>}
              classes={{label: 'font_15_600'}}/>
          </RadioGroup>
          {mode === 'duration' && <TextField {...props} fullWidth variant='outlined' className='standard'
            InputProps={{endAdornment: (
              <InputAdornment position='end'>
                <ToggleLabel size='small' value={durationType} options={DTypes} onChange={setDurType}/>
              </InputAdornment>
            )}}/>}
          {mode === 'endDate' && <DateField value={endDate} onChange={setEndDate} minDate={endDateOriginal}/>}
        </div>
      </form>
    </Dialog>
  );
};

const DatePickerField = (props) => (<TextField {...props} fullWidth className='standard' />);
const DateField = (props) => {
  const {
    onChange, value, minDate,
  } = props;
  return (
    <DatePicker autoOk
      value={value}
      inputVariant='outlined'
      onChange={onChange}
      format='DD MMM YYYY'
      TextFieldComponent={DatePickerField}
      minDate={minDate}/>
  );
};

export default AddWeeksDialog;
