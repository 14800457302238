import React, { useContext, useMemo, useReducer, useState } from "react";
import {
  DialogContent,
  Typography,
  Switch
} from "@material-ui/core";
import Dialog from "fitbud/components/Dialog";
import { UserSchContext } from "fitbud/views/schedulev2";
import { WCACInput } from "fitbud/views/schedulev2/editor/rhs";
import { get } from "lodash";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import { showActivityMetric } from 'fitbud/views/users/helpers';

const UNIT="activity";
const DEFAULT_ACTIVITY={value:{},required:false};
const ActivityEditor = (props) => {
  const { onClose, open } = props;
  const {
    currWeekId:weekId,
    weekToday,
    dayToday,
    local,
    controller,
    opimport:{day,week},
    aggrs,
    remote
  } = useContext(UserSchContext);
  const { comp } = useContext(FirebaseAuthContext);
  const defaultActivity=comp && get(comp.data(),'defaults.activity',{});
  const app_config_activities=comp && get(comp.data(),'app_config.activities');
  const future=(week * 7 + day) >=dayToday ;
  const activity= useMemo(() => {
    let activity={...DEFAULT_ACTIVITY};
    if(!!future){
      activity= get(local, ['plan', 'ac'], {});
      if((!activity || !Object.keys(activity) && !!Object.keys(defaultActivity).length).length) return {...activity,value:defaultActivity}
      return {...activity}
    }
    else{
      if(aggrs){
        const _day="day_"+( week * 7 + day + 1 );
        activity.value=get(aggrs,['activity_target',_day],null);
      }
      if(!activity.value){
        if(week===weekToday){
          activity.value=get(remote, ['weeks', weekId, 'activity','value'], {})
        }else{
          activity.value=get(local,['weeks',weekId,'activity','value'],{});
        }
      }
      return activity;
    }
  }, [future,local,weekId]);
  const [showSteps,showDis,showFlights,showEnergy]=useMemo(()=>{
    return showActivityMetric(app_config_activities);
  },[app_config_activities])
  //state
  const [state, updateState] = useState(activity);
  const [error,setError]=useState('');
  const {steps = 0, distance = 0, flights = 0, energy = 0} = state.value || {};
  //functions
  const handleChange=(dbVal,unit)=>{
    updateState(o=>({
      ...o,
      value:{
        ...o.value,
        [unit]:dbVal
      }
    }))
  };
  const handleToggle=(e,v)=>updateState(o=>({...o,required:v}));
  const handleSave=()=>{
    if(!!state.required && (!state.value || !state.value.steps)){
      setError("Please enter valid value");
      return;
    }
    setError('');
    controller.setWCAC(UNIT, state,week,day);
    onClose();
  }
  return (
    <Dialog
      open={open}
      onClose={onClose}
      onSave={handleSave}
      title="Activity"
      paperClass="width-400"
      titleFont="h3"
      actionText="Save"
    >
      <DialogContent className="p-20">
        <div className="p-15 mb-20" style={{backgroundColor:'#E8F4FD',borderRadius:6}}>
          <Typography className="font_14_500">Set daily targets for the client's activities, effective from today onwards.</Typography>
        </div>
        <div className="d-flex  mb-20 align-items-center">
          <Switch checked={state.required} color="primary" edge='start' className='my-n12 mr-8' onChange={handleToggle}/>
          <Typography className="font_15_600">Set Targets</Typography>
        </div>
        {/*----- STEPS -----*/}
        {showSteps && <div className="mb-20">
          <Typography className="font_15_600 mb-12">
            Steps
          </Typography>
          <WCACInput required={true} controller={controller} value={steps} unit='steps' handleChange={handleChange} error={error} disabled={!state.required}/>
        </div>}
        {/*----- DISTANCE -----*/}
        {showDis && <div className="mb-20">
          <Typography className="font_15_600 mb-12">
            Distance
          </Typography>
          <WCACInput controller={controller} value={distance} unit='distance'  handleChange={handleChange} disabled={!state.required}/>
        </div>}
        {/*----- FLIGHTS -----*/}
       {showFlights && <div className="mb-20">
          <Typography className="font_15_600 mb-12">
            Flights
          </Typography>
          <WCACInput controller={controller} value={flights} unit='flights' handleChange={handleChange} disabled={!state.required}/>
        </div>}
        {/*----- ENERGY -----*/}
        {showEnergy && <div>
          <Typography className="font_15_600 mb-12">
            Energy
          </Typography>
          <WCACInput controller={controller} value={energy} unit='energy' handleChange={handleChange} disabled={!state.required}/>
        </div>}
      </DialogContent>
    </Dialog>
  );
};
export default ActivityEditor;
