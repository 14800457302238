import React, { useEffect, useRef } from 'react';
import { TextField, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add as AddIcon } from '@material-ui/icons';
import { ClrdButton } from 'fitbud/components/form-fields';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { DeleteIcon } from 'fitbud/icons/delete';
import _ from 'lodash';
import useStateWithCallback from 'fitbud/hooks/useStateWithCallback';
import clsx from 'clsx';
import {DragIcon} from "fitbud/icons/drag";
import {reorder} from "fitbud/utils/helpers";


const useStyles = makeStyles((theme) => ({
  disabled:{
    color:"unset!important"//for unedittable textfield
  },
  dragIconButton:{
    position:"absolute",
    right:-13,
    padding:0
  },
  dragIcon:{
    height:"28px !important",
    width:"28px !important"
  },
  readOnlyField:{
    padding: "18.5px 14px",
    border: "1px solid rgba(0, 0, 0, 0.26)",
    backgroundColor: "#F2F4F7",
    borderRadius:4
  }
}));

//In case of we need to render complex ui in each row: use case catalog schedule week reorder
const ReadOnlyField = (props) =>{
  const {
    onRemove,
    provided,
    snapshot,
    hideDeleteBtn,
    className,
    disableDeleteBtn,
    option,
    renderOption,
    ...restProps
  } = props;
  const classes = useStyles();
  const optionName = typeof (option || '') === 'string' ? option : option.name;
  return (
    <div ref={provided.innerRef} {...provided.draggableProps} className={clsx(className, 'd-flex align-items-center')}>
      <div className="d-flex align-items-center flex-1 position-relative bg-white">
        <div className={clsx(classes.readOnlyField, 'w-100')} {...restProps}>
          {!!renderOption && typeof renderOption === 'function' && renderOption(option)}
          {!renderOption && <Typography>{optionName}</Typography>}
        </div>
        <IconButton component="div" {...provided.dragHandleProps} className={classes.dragIconButton}>
          <DragIcon className={classes.dragIcon} />
        </IconButton>
      </div>
      {!hideDeleteBtn && (
        <IconButton disabled={disableDeleteBtn} onClick={onRemove} className="ml-16" edge="end">
          <DeleteIcon classes={{ root: 'small' }} />
        </IconButton>
      )}
    </div>
  );

};

const InputField = (props) => {
  const { onRemove, provided, snapshot,hideDeleteBtn,className,readOnly,disableDeleteBtn, ...restProps } = props;
  const classes = useStyles();
  return (
    <div ref={provided.innerRef} {...provided.draggableProps} className={clsx(className,"d-flex align-items-center")}>
      <div className="d-flex align-items-center flex-1 position-relative bg-white">
        <TextField disabled={readOnly} fullWidth required variant="outlined" type="text" multiline rowsMax={4} autoFocus {...restProps} InputProps={{
          classes:{disabled:readOnly && classes.disabled}
        }}/>
        <IconButton component="div" {...provided.dragHandleProps} className={classes.dragIconButton}>
          <DragIcon className={classes.dragIcon}  />
        </IconButton>
      </div>
      {!hideDeleteBtn && <IconButton disabled={disableDeleteBtn} onClick={onRemove} className="ml-16" edge="end">
        <DeleteIcon classes={{root:"small"}} />
      </IconButton>}
    </div>
  );
};

const MultiLineInput = (props) => {
  const { addMoreLabel, onChange,hideAddBtn=false,hideDeleteBtn=false,readOnly=false, enableLastDelete= false, noTextFiled = false, renderOption } = props;
  const inputRefs = useRef([]);
  const [options, setOptions] = useStateWithCallback(() => {
    if (!props.options || !props.options.length) {
      return [''];
    } else return props.options;
  });

  const updateOptions=(value)=>{
    setOptions(value);
    if(onChange){
      onChange(value);
    }
  }

  // useEffect(() => {
  //   onChange && onChange(options);
  // }, [options]);

  const handleChange = (e) => {
    let value = e.target.value;
    let index = e.target.dataset && e.target.dataset.index;
    let updatedOptions = [];
    updatedOptions = [...options];
    updatedOptions[index] = value;
    updateOptions(updatedOptions);
  };

  const handlePaste = (e) => {
    let index = e.target.dataset && e.target.dataset.index; //index of selected input
    const cursorPosition = e.target.selectionStart; //cursor position at selected input;
    index = Number(index);
    const clipboardData = e.clipboardData || window.clipboardData;
    const copiedText = clipboardData.getData('Text');
    let stringifyCopiedText = copiedText.toString().split('\n');
    stringifyCopiedText = _.without(stringifyCopiedText, '');

    let currentInputValue = (options[index] || '').trim();
    const out = [...options];

    if (!currentInputValue) {
      //if current input value is empty then
      out.splice(index, 1, ...stringifyCopiedText);
    } else {
      const postPasteString = currentInputValue.substring(cursorPosition, currentInputValue.length);
      if (!postPasteString) {
        //if pasting string after end value
        stringifyCopiedText[0] = currentInputValue + ' ' + stringifyCopiedText[0];
        out.splice(index, 1, ...stringifyCopiedText);
      } else {
        //if pasting between middle value;
        const prePastingString = currentInputValue.substring(0, cursorPosition - 1);
        const postPasteString = currentInputValue.substring(cursorPosition, currentInputValue.length);

        if ((stringifyCopiedText || []).length > 1) {
          stringifyCopiedText[0] = prePastingString + ' ' + stringifyCopiedText[0];
          stringifyCopiedText[stringifyCopiedText.length - 1] =
            stringifyCopiedText[stringifyCopiedText.length - 1] + ' ' + postPasteString;
        } else {
          stringifyCopiedText[0] = prePastingString + ' ' + stringifyCopiedText[0] + ' ' + postPasteString;
        }
        out.splice(index, 1, ...stringifyCopiedText);
      }
    }

    updateOptions(out, () => {
      const focusable_input = inputRefs.current[index + (stringifyCopiedText || []).length];
      focusable_input && focusable_input.focus();
    });
    e.preventDefault();
    e.stopPropagation();
  };

  const handleOnBlur = (e) =>{
    const index = e.target.dataset && e.target.dataset.index;
    const currentValue = options[index];
    if(Number(index) !== 0 && !currentValue){ //to handle if options is empty then always show one input::
      const out = [...options];
      out.splice(Number(index),1);
      updateOptions(out);
    }

  }

  const handleEnterPress = (e) => {
    if (e.key !== 'Enter') return;
    const index = e.target.dataset && e.target.dataset.index;
    const out = [...options];
    e.preventDefault();
    if(Number(index)===(out.length-1)){
      //last option
      if(!out[index]) return "";
      out.splice(Number(index) + 1, 0, '');
      updateOptions(out, () => {
        inputRefs.current[Number(index) + 1].focus();
      });
    }
    else{
      //non-last option
      inputRefs.current[Number(index) + 1].focus();
    }
  };

  const onAddOptions = (e) => {
    updateOptions([...options, '']);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(options, result.source.index, result.destination.index);
    updateOptions(items);
  };

  const handleDelete = (index) => {
    if (!enableLastDelete &&  (options.length === 1)) {
      return updateOptions([]);
    }
    let updatedOptions = [...options];
    updatedOptions.splice(index, 1);
    updateOptions(updatedOptions);
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => {
            return (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {options.map((option, index, thisArg) => {
                  option = option || '';
                  const optionName = typeof option === 'string' ? option : option.name;
                  const _disableDeleteIcon = !enableLastDelete && thisArg.length === 1;
                  return (
                    <Draggable draggableId={`__dragId${index}`} key={`__dragId${index}`} index={index}>
                      {(provided, snapshot) => {
                        return noTextFiled ? (
                          <ReadOnlyField
                            snapshot={snapshot}
                            provided={provided}
                            option={option}
                            onPaste={handlePaste}
                            onRemove={() => {
                              handleDelete(index);
                            }}
                            hideDeleteBtn={hideDeleteBtn }
                            className={index < options.length - 1 ? 'mb-20' : 'mb-0'}
                            disableDeleteBtn={_disableDeleteIcon}
                            renderOption={renderOption}
                          />
                        ) : (
                          <InputField
                            snapshot={snapshot}
                            provided={provided}
                            value={optionName.trimLeft()}
                            onChange={handleChange}
                            onPaste={handlePaste}
                            onRemove={() => {
                              handleDelete(index);
                            }}
                            hideDeleteBtn={hideDeleteBtn || _disableDeleteIcon} // incase on one item is left remove delete instead of disable.
                            onBlur={handleOnBlur}
                            onFocus={function (e) {
                              //To force cursor to the end of value : hacks
                              var val = e.target.value;
                              e.target.value = '';
                              e.target.value = val;
                            }}
                            inputProps={{
                              'data-index': index,
                              onKeyPress: handleEnterPress,
                              ref: (el) => {
                                inputRefs.current[index] = el;
                              },
                            }}
                            readOnly={readOnly}
                            className={index < options.length - 1 ? 'mb-20' : 'mb-0'}
                            disableDeleteBtn={_disableDeleteIcon}
                          />
                        );
                      }}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
        {!hideAddBtn && <div className='d-flex justify-content-center align-item-center fmt-20'>
        <ClrdButton
          variant="text"
          color="invert"
          className="fpt-20 fpb-20 f-medium border-0 shadow-none"
          onClick={onAddOptions}
          disabled={!!options.length && !(options[options.length - 1].trim())} //if last one is empty then disable button
        >
          {/* <AddIcon />  */}
          <Typography variant="h6">{addMoreLabel}</Typography>
        </ClrdButton>
        </div>}
      </DragDropContext>
    </>
  );
};

export default MultiLineInput;
