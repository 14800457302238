import { Divider, FormHelperText, TextField, Typography } from '@material-ui/core';
import { protectNumberInputs } from 'fitbud/utils/helpers';
import React from 'react'
import { FormTextField } from './form-fields';


/*

  MIN - MAX Range Input Fields Component
  props :
  value - an array with index 0 as min value and index 1 as max
  name - key in store in DB


*/

const MinMaxField = ({ value=[], name="range", label="Range Input", errors=null, loading, onChange, textFieldProps, labelClasses={} }) => {
  const [minValue, maxValue] = value;

  const handleChange = (e) => {
    e.preventDefault();
    let newValue = [...value];
    let [name, index] = e.target.name.split("-");
    newValue[index] = Number(e.target.value);
    return onChange({ target: { value: newValue, name }});
  };

  return (
    <FormTextField fullWidth label={label} classes={{ ...labelClasses }}>
      <div className="d-flex">
        <TextField
          name={`${name}-0`}
          placeholder="0"
          value={minValue || ""}
          onChange={handleChange}
          error={!!errors && !!errors[name]}
          variant="outlined"
          disabled={loading}
          onKeyDown={protectNumberInputs}
          type="number"
          InputProps={{
            inputProps: {
              min: 0
            },
            endAdornment: <Typography className="text-grey">min</Typography>,
            classes: {
              root: "medium",
              input: "size_16_500"
            }
          }}
          {...textFieldProps}
        />
        <Divider
          style={{ width: "10px" }}
          className="fmx-15 fmt-25"
          component="div"
        />
        <TextField
          name={`${name}-1`}
          placeholder="0"
          value={maxValue || ""}
          onChange={handleChange}
          onKeyDown={protectNumberInputs}
          error={!!errors && !!errors[name]}
          variant="outlined"
          disabled={loading}
          required
          type="number"
          InputProps={{
            inputProps: {
              min: 0
            },
            endAdornment: <Typography className="text-grey">max</Typography>,
            classes: {
              root: "medium",
              input: "size_16_500"
            }
          }}
          {...textFieldProps}
        />
      </div>
      {!!errors && !!errors[name] &&
        <FormHelperText error>
          {errors[name]}
        </FormHelperText>}
    </FormTextField>
  )
}

export default MinMaxField;