import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Collapse,
  List,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  ListItem,
  ListItemText,
  ListItemIcon,
  CircularProgress,
  ListItemSecondaryAction,
  Card
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/CloseRounded';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import ErrorIcon from "@material-ui/icons/Error";
import ReplayIcon from "@material-ui/icons/Replay";
import { FILE_STATUS } from "fitbud/utils/constants";
import fileUploadRdxFns from "fitbud/redux/fileUpload";
import FileUploadingIcon from "fitbud/icons/fileUploading";
import { useAppAlertContext } from "fitbud/providers/appAlertProvider";
import {AppAlertIndicator} from "fitbud/components/appAlert";

export const ArrowIcon = () =>{
  return (
    <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 13L6 7L1 1" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}

const ALERT_TYPE = "file";

const useStyles = makeStyles(() => ({
  fileUploadContainer: {
    width: '360px',
    position: "fixed",
    bottom:0,
    right: 0
  },
  appBar: {
    cursor: "pointer",
    backgroundColor: "#37404D", 
    borderRadius: "5px 5px 0px 0px",
  },
  iconColor: {
    color: "#323232",
  },
  collapseContainer: {
    maxHeight: "180px",
    overflow: "auto",
  },
  listItemPadding: {
    paddingTop: '4px',
    paddingBottom: '3px'
  },
  listItemTextRoot: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  listItemTextPrimary: {
    fontSize: '16px',
  },
  listItemTextSecondary: {
    fontSize: '13px',
  }
}));

const FileUploadProgress = ({ files, uploadFile, clearFile, clearFiles,uploadBunnyFile }) => {
  const [collapse, toggleCollapse] = useState(true);
  const classes = useStyles();
  const {expendedAlertType, setExpandedAlertType } = useAppAlertContext();
  const prevFileLength = useRef(0) //variable to save previous file length

  const onUnload = useCallback((e) => {
    e.preventDefault();
    e.returnValue = "file uploading is in progress";
  }, []);

  useEffect(()=>{
    setTimeout(()=>{
      toggleCollapse(true); //for handling the case when video session alert open and suddenly file upload in progress::
      setExpandedAlertType(ALERT_TYPE);
    }, 100)
    prevFileLength.current = (files || []).length; //set initial value
  },[])

  useEffect(()=>{
    if(expendedAlertType && expendedAlertType !== ALERT_TYPE){
      toggleCollapse(false);
    }

  },[expendedAlertType])

  useEffect(() => {
    if (files.some((f) => f.status === FILE_STATUS.uploading)) {
      window.addEventListener("beforeunload", onUnload);
    } else {
      window.removeEventListener("beforeunload", onUnload);
    }
  }, [files, onUnload]);

  useEffect(()=>{ //if file uploading is minimize and new file being uploaded then reset state
    if(files.length > prevFileLength.current){
      toggleCollapse(true);
      setExpandedAlertType(ALERT_TYPE);
    }
    prevFileLength.current = files.length;
  },[files.length])

  const handleToggle = ()=>{
    setExpandedAlertType(!collapse ? ALERT_TYPE : null)
    toggleCollapse(!collapse)
  }

  return (
    <div style={{minHeight:70}} className="d-flex flex-column align-items-end position-relative">
      {!collapse && (
       <AppAlertIndicator onClick={handleToggle}>
          <FileUploadingIcon />
       </AppAlertIndicator>
      )}
      {collapse && <Collapse in={collapse}>
        <Card className={clsx(classes.fileUploadContainer,"fmb-15 fmr-30")}>
          <AppBar position="static" color="primary" className={classes.appBar}>
            <Toolbar
              onClick={handleToggle}
              style={{ minHeight: '60px' }}
              className={clsx('d-flex justify-content-between align-items-center', 'fpx-20 fpr-15 fpy-15')}
            >
              <div>
                <Typography className="text-white" variant="h6" >
                  File Upload Progress
                </Typography>
              </div>

              <div>
                <ArrowIcon   className="text-white" />
                {files && files.every((f) => f.status === FILE_STATUS.processing || f.status === FILE_STATUS.complete) && (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      clearFiles();
                    }}
                    className="fml-20 p-0"
                  >
                    <CloseIcon  className="text-white" />
                  </IconButton>
                )}
              </div>
            </Toolbar>
          </AppBar>
          <div
            in={true}
            classes={{
              container: `bg-white border-left border-right ${classes.collapseContainer}`,
            }}
          >
            <List disablePadding>
              {files &&
                files.map((file, index) => {
                  return (
                    <ListItem
                      button
                      component={Link}
                      to={file.path}
                      key={file.id}
                      divider
                      classes={{ root: classes.listItemPadding }}
                    >
                      <ListItemIcon>
                        {file.status === FILE_STATUS.uploading ? (
                          <CircularProgress
                            size={25}
                            thickness={5}
                            color="primary"
                            variant={file.progress ? 'static' : 'indeterminate'}
                            value={file.progress}
                          />
                        ) : (file.status === FILE_STATUS.processing || file.status === FILE_STATUS.complete) ? (
                          <CheckCircleIcon className="text-success" />
                        ) : (
                          <ErrorIcon className="text-danger" />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={file.docName}
                        secondary={file.file.name}
                        classes={{
                          root: classes.listItemTextRoot,
                          primary: classes.listItemTextPrimary,
                          secondary: classes.listItemTextSecondary,
                        }}
                      />
                      {file.status === FILE_STATUS.error && (
                        <ListItemSecondaryAction>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              clearFile(file.id);
                              file.videoId?uploadBunnyFile({...file}):uploadFile({ ...file });
                            }}
                            edge="end"
                            aria-label="replay"
                          >
                            <ReplayIcon className={classes.iconColor} />
                          </IconButton>
                        </ListItemSecondaryAction>
                      )}
                      {(file.status === FILE_STATUS.processing || file.status === FILE_STATUS.complete) && (
                        <ListItemSecondaryAction>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              clearFile(file.id);
                            }}
                            edge="end"
                            aria-label="close"
                          >
                            <CancelIcon className={classes.iconColor} />
                          </IconButton>
                        </ListItemSecondaryAction>
                      )}
                    </ListItem>
                  );
                })}
            </List>
          </div>
        </Card>
      </Collapse>
      }
    </div>
  );
};

const mapStateToProps = (state) => ({
  files: state.fileUpload.files,
});

const mapDispatchToProps = (d) => {
  const { uploadFile, clearFile, clearFiles,uploadBunnyFile } = fileUploadRdxFns(d);
  return { uploadFile, clearFile, clearFiles,uploadBunnyFile };
};

export default connect(mapStateToProps, mapDispatchToProps)(FileUploadProgress);
