import { SvgIcon } from "@material-ui/core";
import React from "react";


export const FlashIcon = props => (
  <SvgIcon
  viewBox="0 0 16 16"
  width="16" height="16" 
  classes={{
    root: "small"
  }}
  {...props}
  >
    <svg  fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="7.5" fill="#6F8099" stroke="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99001 8.37172H5.41372C5.09656 8.37172 4.92809 7.94359 5.13919 7.72108L9.34878 3.28434C9.47564 3.14433 9.71319 3.28189 9.6364 3.45104L7.99001 7.14657H10.5632C10.8924 7.14657 11.074 7.42856 10.8657 7.66772L6.37495 12.7306C6.28738 12.829 6.12045 12.7362 6.17053 12.617L7.99001 8.37172Z" fill="white" stroke="white" stroke-width="0.5" />
    </svg>
  </SvgIcon>
);
