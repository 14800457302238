import React from 'react';
import { Typography, FormControlLabel, Switch, Card, CardContent, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { Header } from './fitness';
import { useReminder } from 'fitbud/views/reminders/provider';
import { WATER_MODE, renderTime } from 'fitbud/views/reminders/helper';
import _ from 'lodash';
import WaterEditor from '../editors/water';
import { useItemClass } from './fitness';
import { getWaterFrequencyLabel } from 'fitbud/views/reminders/helper';
import { useStyles } from '.';

const Water = (props) => {
  const { onToggle } = props;
  const classes = useStyles()
  const { edit, setEdit, readOnlyReminders, preference } = useReminder();
  const itemClasses = useItemClass();
  const { count, interval, mode, from, to } = _.get(readOnlyReminders, 'water', {});
  const enabled = _.get(preference, "water.enabled", false);

  const openEdit = () => setEdit('water');
  const isFrequency = mode === WATER_MODE.FREQUENCY;

  const handleToggle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onToggle('water');
  };

  const renderFrequencyCount = () => {
    if (isFrequency) {
      return <span className="font_15_600">{getWaterFrequencyLabel(interval)}</span>;
    } else {
      return (
        <span className="font_15_500">
          <span className="font_15_700">{count}</span> times
        </span>
      );
    }
  };

  return (
    <div className="mt-15">
      <FormControlLabel
        control={<Switch color="primary" classes={{root: classes.reminderSwitch}} checked={!!enabled} onChange={handleToggle} />}
        className="mb-4x"
        label={<Typography className="font_18_600 text-0d0d0d">Water</Typography>}
      />
      <Card>
        <CardContent className="px-20 pb-20 pt-12">
          <Header title={isFrequency ? 'Interval' : 'Count'} handleEdit={openEdit} />
          <div 
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(170px, 1fr))',
            }}
          >
            {/* Mode */}
            <div className={clsx(itemClasses.container, isFrequency && 'w-auto')} >
              <Typography className={clsx('font_13_500 text-light-grey-2 text-truncate mb-8')}>
                {isFrequency ? 'Every' : 'Count'}
              </Typography>
              <Typography className={clsx('text-0d0d0d')}>{renderFrequencyCount()}</Typography>
            </div>
            {/* From , to Time */}
            <div className={clsx(itemClasses.container)} >
              <Typography className={clsx('font_13_500 text-light-grey-2 text-truncate mb-8')}>From</Typography>
              <Typography className={clsx('text-0d0d0d')}>{renderTime(from)}</Typography>
            </div>
            <div className={clsx(itemClasses.container)} style={{ minWidth: 150}}>
              <Typography className={clsx('font_13_500 text-light-grey-2 text-truncate mb-8')}>To</Typography>
              <Typography className={clsx('text-0d0d0d')}>{renderTime(to)}</Typography>
            </div>
          </div>
        </CardContent>
      </Card>
      {edit === 'water' && <WaterEditor />}
    </div>
  );
};
export default Water;
