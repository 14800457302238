import { AppBar, Drawer, IconButton, makeStyles, Toolbar, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from "fitbud/icons/detailEdit";
import { Conversion } from 'fitbud/providers/conversion';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { DEFAULT_UNIT_SYSTEM } from 'fitbud/utils/constants';
import { getEmailFirstLetter } from 'fitbud/utils/helpers';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useStore } from 'react-redux';
import { useUserContext } from '../detail';
import { AvatarImage } from '../header';
import { UserStateIndicator } from '../list';
import QResponses from '../QResponses';
import EditQuestionsDialog from './editQuestionsDialog';

const styles = makeStyles((theme) => ({
  avatarBadge:{
    right: '15px',
    top:'60px',
    boxShadow:'0px 0px 0px 2px #fff',
    height: '10px !important',
    borderRadius: '100%',
    width:'10px !important',
  },
  dp:{
    position: 'absolute',
    right: '20px',
  }
}))
const ProfileDrawer =  ({ open, questions, ...rest }) => {
  const { userDoc, measurableTags, docId, toggleEditProfile, updateUserDoc } = useUserContext();
  const { comp, cid } = useContext(FirebaseAuthContext);
  const store = useStore().getState();
  const measurableUnits = store.config && store.config.docs && store.config.docs[0];
  const unitSystem =  (store.staff && store.staff.doc && store.staff.doc.unit_system) || DEFAULT_UNIT_SYSTEM;
  const unitDetails = (store.staff && store.staff.doc && store.staff.doc.unit_details) || {};
  const { convertor } = useContext(Conversion);
  const { bmr } = comp.data();
  const { profile={} } = userDoc || {} ;
  const classes = styles();
  const [openQuestionDialog, setQuestionDialog] = useState(false);
  const toggleQuestionsDialog = (action=true) => setQuestionDialog(action);
  const [height, hu] = convertor.getDisplayWithUnit('height', !!profile && profile.height, { fancy: true });
  const weight = convertor.getDisplayValue('weight', profile.weight);
  return (
    <>
      {!!openQuestionDialog && (
        <EditQuestionsDialog
          open={!!openQuestionDialog}
          onClose={() => toggleQuestionsDialog(false)}
          userDoc={userDoc}
          docId={docId}
          measurableTags={measurableTags}
          measurableUnits={measurableUnits}
          unitSystem={unitSystem}
          unitDetails={unitDetails}
          cid={cid}
          ques={questions}
          updateUserDoc={updateUserDoc}
          companyBMR={bmr || {}}
        /> 
      )}
      <Drawer anchor="right" open={!!open} {...rest} classes={{ paper: 'drawer-420' }}>
        <AppBar
          position="sticky"
          color="primary"
        >
          <Toolbar
            classes={{
              root: "height-60"
            }}
            className={
              "d-flex flex-row bg-white justify-content-between align-items-center border-bottom fpx-10"
            }
          >
            <div className='d-flex align-items-center'>
              <IconButton edge="end" color="inherit" onClick={rest.onClose}>
                <CloseIcon />
              </IconButton>
              <Typography variant="h3" className='fml-20'>{"Client Profile"}</Typography>
            </div>
          </Toolbar>
        </AppBar>
        {!!profile && <div className='bg-light-grey'>
          <div className='d-flex justify-content-between align-items-center fpr-12'>
            <Typography variant='h6' className='fmt-15 fmb-15 fml-20'>
              Profile Details
            </Typography>
            <div>
              <IconButton onClick={toggleEditProfile}>
                <EditIcon />
              </IconButton>
            </div>
          </div>
          <div className='fp-20 bg-white'>
            <div>
              {profile.image || profile.image_data ?
              <div className={classes.dp}>
                <UserStateIndicator userDoc={userDoc} classes={{badge: classes.avatarBadge}}>
                <AvatarImage
                  src={profile.image ? `user_profiles/${docId}/profile/original/${profile.image}` : profile.image_data}
                  name={profile.name || getEmailFirstLetter(profile.email)}
                  base64={!profile.image}
                  alt={profile.name || " "}
                  size={module === 'user' ? 'vlarge' : 'midlarge'}
                  styles={{ height: 80, width: 80, fontSize: 20, fontWeight: 500 }}
                />
              </UserStateIndicator>
              </div> : null}
            </div>
            {!!profile && !!profile.name && <LabelValue className={!profile.email && 'm-0'} label='Full Name' value={profile.name} />}
            {!!userDoc && !!userDoc.nick_name && <LabelValue className={!profile.email && 'm-0'} label='Nick Name' value={userDoc.nick_name} />}
            {!!profile && !!profile.email && <LabelValue className={!profile.dob && 'm-0'} label='Email' value={profile.email} />}
            {!!profile && !!profile.dob && <LabelValue className={!profile.gender && 'm-0'} label='Date of Birth' value={moment(profile.dob, 'YYYYMMDD').format('DD MMM, YYYY')} />}
            {!!profile && !!profile.gender && <LabelValue className={!profile.height && 'm-0'} label='Gender' value={profile.gender} />}
            {!!profile && !!profile.height && <LabelValue className={!profile.weight && 'm-0'} label='Height' value={`${height} ${hu}`} />}
            {!!profile && !!profile.weight && <LabelValue className='m-0' label='Weight' value={`${weight} ${convertor.getDisplayUnit('weight')}`} />}
          </div>
        </div>}
        <div className='bg-light-grey'>
          <div className='d-flex justify-content-between align-items-center fpr-12'>
            <Typography variant='h6' className='fmt-15 fmb-15 fml-20'>
              Onboarding Questionnaire
            </Typography>
            <div>
              <IconButton onClick={toggleQuestionsDialog}>
                <EditIcon />
              </IconButton>
            </div>
          </div>
          <div className='fp-20 bg-white'>
            <QResponses
              {...userDoc}
              docId={docId}
              measurableTags={measurableTags}
              measurableUnits={measurableUnits}
              unitSystem={unitSystem}
              unitDetails={unitDetails}
              cid={cid}
              ques={questions}
              companyBMR={bmr || {}} />
          </div>
        </div>
      </Drawer>
    </>
  )
};
const LabelValue = ({label="Label", value='Value', customValue, className }) => {
  return (
    <div className={className || 'fmb-20 '}>
      <Typography className='fmb-10 font_13_500' color='textSecondary'>
        {label}
      </Typography>
      {!!customValue ? customValue :
        <Typography className='font_15_500'>
          {value}
        </Typography>
      }
    </div>
  )
}
export default ProfileDrawer;
