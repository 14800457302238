import axnsBuilder from "./actions";

const getDefaultState = () =>({
  user: {},
  plan: {},
  schedule: {},
  scheduleDetail: {}, //This key is for Schedule from fitness
})

const axns = axnsBuilder();

export default (state = getDefaultState(), action) => {
  switch (action.type) {
    case axns.setUser:
      return {
        ...state,
        user: action.doc,
      };
    case axns.setPlan:
      return {
        ...state,
        plan: action.doc,
      };
    case axns.updateUMS: {
      const user = action.user ? { ...(state.user || {}), ...action.user } : state.user;
      const plan = action.plan ? { ...(state.plan || {}), ...action.plan } : state.plan;
      return { ...state, user, plan };
    }
    case axns.setSchedule:
      return {
        ...state,
        schedule: Object.assign(state.schedule,action.doc),//TODO :: need to merge or override ?
      };
    case axns.resetSchedule:
      return {
        ...state,
        schedule: {}
      };
    case axns.setScheduleDetail:
      return {
        ...state,
        scheduleDetail: action.doc,
      };
    case axns.clear:
      return getDefaultState();
    default:
      return state;
  }
};
