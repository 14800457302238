import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const ClipboardIcon = props => (
  <SvgIcon
    {...props}
    viewBox="0 0 18 20"
    // classes={{
    //   root: "small"
    // }}
  >
  <g fill="none">
    <path d="M14.25 1.5H11.115C10.8 0.63 9.975 0 9 0C8.025 0 7.2 0.63 6.885 1.5H3.75C2.925 1.5 2.25 
    2.175 2.25 3V15C2.25 15.825 2.925 16.5 3.75 16.5H14.25C15.075 16.5 15.75 15.825 15.75 15V3C15.75 
    2.175 15.075 1.5 14.25 1.5ZM9 1.5C9.4125 1.5 9.75 1.8375 9.75 2.25C9.75 2.6625 9.4125 3 9 3C8.5875 
    3 8.25 2.6625 8.25 2.25C8.25 1.8375 8.5875 1.5 9 1.5ZM14.25 15H3.75V3H5.25V5.25H12.75V3H14.25V15Z" 
    fill="black"/>
  </g>

  </SvgIcon>
);
