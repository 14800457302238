import React, { Component } from "react";
import { EditorState } from "draft-js";
import Editor from "draft-js-plugins-editor";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";

import createToolbarPlugin, { Separator } from "draft-js-static-toolbar-plugin";
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  CodeButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
  UnorderedListButton,
  OrderedListButton,
  BlockquoteButton,
  CodeBlockButton
} from "draft-js-buttons";

class HeadlinesPicker extends Component {
  componentDidMount() {
    setTimeout(() => {
      window.addEventListener("click", this.onWindowClick);
    });
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.onWindowClick);
  }

  onWindowClick = () =>
    // Call `onOverrideContent` again with `undefined`
    // so the toolbar can show its regular content again.
    this.props.onOverrideContent(undefined);

  render() {
    const buttons = [HeadlineOneButton, HeadlineTwoButton, HeadlineThreeButton];
    return (
      <div
        className="d-flex"
        style={{
          marginBottom: "5px"
        }}
      >
        {buttons.map((
          Button,
          i // eslint-disable-next-line
        ) => (
          <Button key={i} {...this.props} />
        ))}
      </div>
    );
  }
}

class HeadlinesButton extends Component {
  onClick = () => this.props.onOverrideContent(HeadlinesPicker);

  render() {
    return (
      <div className="headlineButtonWrapper">
        <button onClick={this.onClick} className="headlineButton">
          H
        </button>
      </div>
    );
  }
}

const toolbarPlugin = createToolbarPlugin();
const { Toolbar } = toolbarPlugin;
const plugins = [toolbarPlugin];

export default class DraftEditor extends Component {
  constructor(props) {
    // super(props);
    // const contentState = stateFromHTML(this.props.text);
    // this.state = {
    //   editorState: props.text
    // };
    super(props);
    const contentState = stateFromHTML(this.props.text);
    this.state = {
      editorState: EditorState.createWithContent(contentState)
    };
    this.editor = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    const isEmpty = this.isEmpty(this.state.editorState);
    if (
      this.isEmpty(prevState.editorState) !== isEmpty &&
      this.props.setEditorEmpty
    ) {
      this.props.setEditorEmpty(isEmpty);
    }
  }

  onChange = editorState => {
    this.setState({
      editorState
    });
    const content = this.getText();
    this.props.handleTCNPP(this.props.name, stateToHTML(editorState.getCurrentContent()));
  };

  getText = () => {
    const summaryState = this.state.editorState.getCurrentContent();
    return stateToHTML(summaryState);
  };

  isEmpty(editorState) {
    return !editorState
      .getCurrentContent()
      .getPlainText()
      .trim();
  }

  focus = () => {
    this.editor.focus();
  };

  render() {
    return (
      <div style={this.props.isNote &&  {width: "355px"}}>
        <Toolbar>
          {// may be use React.Fragment instead of div to improve perfomance after React 16
          externalProps => (
            <div className="d-flex">
              <BoldButton {...externalProps} />
              <ItalicButton {...externalProps} />
              <UnderlineButton {...externalProps} />
              <CodeButton {...externalProps} />
              <Separator {...externalProps} />
              <HeadlinesButton {...externalProps} />
              <UnorderedListButton {...externalProps} />
              <OrderedListButton {...externalProps} />
              <BlockquoteButton {...externalProps} />
              <CodeBlockButton {...externalProps} />
            </div>
          )}
        </Toolbar>
        <div className={"editor"} onClick={() => this.editor.current.focus()}>
          <Editor
            editorState={this.state.editorState}
            onChange={this.onChange}
            plugins={plugins}
            ref={this.editor}
          />
        </div>
      </div>
    );
  }
}
