import React, { useContext, useMemo } from 'react';
import { Typography, Chip } from '@material-ui/core';
import { SCHEDULE_MAX_VALID_DAYS } from 'fitbud/utils/constants';
import { AccessContext } from 'fitbud/providers/access-provider';
import { multiplier } from 'fitbud/views/schedulePlanner/helper';
import _ from 'lodash';
import { PlanContext } from './planProvider';
import AppleIcon from 'fitbud/images/apple_icon.svg';

export const RenderPlanChip = (props) => {
  const { getPackAccess } = useContext(AccessContext);
  const { iosPackMode, androidPackMode } = useContext(PlanContext);
  const { id, ios = false, android = false, pricesIOS = [], pricesPlay = [] } = props;
  const hasAppointments = useMemo(() => {
    if (!id) return false;
    const {hasClasses, hasServices} = getPackAccess(id);
    return !!hasClasses || !!hasServices;
  }, [getPackAccess, id]);

  const yesOnAND = useMemo(() => {
    if (ios || android) return true; // nothing to check, since we are in specific mode
    if (
      _.findIndex(pricesPlay, (x) => {
        return !!x.play_product_id && getActiveStatus(x, "active_android");
      }) >= 0
    )
      return false; // if this pack has an actual IAP, then that overrides everything else
    if (androidPackMode === 'all') return true;
    if (androidPackMode === 'video_calls') return hasAppointments;
    return false;
  }, [android, ios, androidPackMode, pricesPlay, hasAppointments]);

  const yesOnIOS = useMemo(() => {
    if (ios || android) return true; // nothing to check, since we are in specific mode
    if (
      _.findIndex(pricesIOS, (x) => {
        return !!x.iap_id && x.method_ios === 'iap' && getActiveStatus(x, "active_ios");
      }) >= 0
    )
      return false; // if this pack has an actual IAP, then that overrides everything else
    if (iosPackMode === 'all') return true;
    if (iosPackMode === 'video_calls') return hasAppointments;
    return false;
  }, [android, ios, iosPackMode, pricesIOS, hasAppointments]);

  if (ios || android) {
    const prices = ios ? pricesIOS : pricesPlay;
    if (!prices || !prices.length) return null;
    const label = ios ? 'iOS' : 'Android';
    return (
      <div className="d-flex flex-row align-items-center">
        <Typography color="textSecondary" variant="caption" className="font-weight-500 fml-20">
          Available only on
        </Typography>
        <Chip
          className="fml-10 rounded-pill text-black bg-grey-new"
          label={<Typography style={{ fontWeight: 500, fontSize: 11 }}>{label}</Typography>}
        />
      </div>
    );
  }

  return (
    <div className="d-flex flex-row align-items-center">
      <Typography color="textSecondary" variant="caption" className="font-weight-500 fml-20">
        Available on
      </Typography>
      <Chip
        className="fml-10 rounded-pill text-black bg-grey-new"
        label={<Typography style={{ fontWeight: 500, fontSize: 11 }}>Web</Typography>}
      />
      {yesOnAND && (
        <Chip
          className="fml-10 rounded-pill text-black bg-grey-new"
          label={<Typography style={{ fontWeight: 500, fontSize: 11 }}>Android</Typography>}
        />
      )}
      {yesOnIOS && (
        <Chip
          className="fml-10 rounded-pill text-black bg-grey-new"
          label={<Typography style={{ fontWeight: 500, fontSize: 11 }}>iOS</Typography>}
        />
      )}
    </div>
  );
};

export const pricingValidation = (state) => {
  const { modeType = '' } = state || {};
  let err = {};
  let out = true;
  if (!state.duration || parseInt(state.duration) !== state.duration) {
    err.duration = modeType === 'subscription' ? 'Please choose a billing cycle' : 'Please enter a valid duration';
    out = false;
  }
  if (!state.price) {
    err.price = 'Please enter a valid price';
    out = false;
  }

  if (!!state.trial_duration_type) {
    if (!state.trial_duration_type) {
      err.trial_duration = 'Please enter a valid duration';
      out = false;
    }
  }

  if (!!state.enable_trial_pack && !state.trial_duration_type) {
    err.trial_duration = 'Please select a valid duration';
    out = false;
  }

  if (!!state.discount_enabled) {
    if (!state.discounted_price) {
      err.discounted_price = 'Please enter a valid discounted price';
      out = false;
    } else if (state.discounted_price >= state.price) {
      err.discounted_price = 'Discounted price cannot be more than the actual price';
      out = false;
    }
  }

  const durationInDays = state.duration * multiplier(state.duration_type);
  if (durationInDays > SCHEDULE_MAX_VALID_DAYS) {
    err.duration = `A plan of more than ${SCHEDULE_MAX_VALID_DAYS} days cannot be created`;
    out = false;
  }
  return { out, err };
};

export const disableWhenChallengePlan = (doc) => {
  if (!getActiveStatus(doc, "active")) { 
    return { disable: true };
  } else {
    const listIndex = _.findIndex(doc.price_opts, ['mode', 'challenge']);
    return { listIndex, disable: listIndex === -1 ? false : true };
  }
};

export const renderInsertData = (doc) => {
  const data = doc.data();
  return {
    ref_name: data.ref_name,
    image: data.image,
    active: getActiveStatus(data,"active"),
    vidCall: data.vid_call,
    iap: data.iap_ids,
    price_opts: data.price_opts,
  };
};

export const renderHeading = ({ mode, title }) => {
  switch (mode) {
    case 'subscription':
      return `${title} Subscription`;
    case 'one_time':
      return `One Time Payment • ${title}`;
    case 'challenge':
      return `Challenge • ${title}`;
    default:
      return `One Time Payment • ${title}`;
  }
};

export const PriceAvatar = (props) => {
  const { discount, currency, isIAP = false, isPlay = false } = props;
  return (
    <div
      className="bg-offWhite rounded-lg d-flex flex-column justify-content-center align-items-center"
      style={{ fontSize: 20, width: 90, height: 60 }}>
      {isIAP && <img alt="Apple In-App Purchase" src={AppleIcon} />}
      {isPlay && <i className="fab fa-google-play" />}
      {!isIAP && !isPlay && (
        <>
          <Typography variant="body1" className="font-weight-700">
            {new Intl.NumberFormat(`${currency}`).format(_.round(discount, 2))}
          </Typography>
          <Typography variant="caption" className="font-weight-500">
            {currency}
          </Typography>
        </>
      )}
    </div>
  );
};

export const PricingDefaultValue = () => {
  return {
    duration: 0,
    price: 0,
    discounted_price: 0,
    currency: 'USD',
    mode: 'one_time',
    duration_type: 'weeks',
    discount_enabled: false,
    activeV2: true,
  };
};

export const ReorderInputText = (item) => {
  let title = '';
  if (item.mode === 'subscription' && item.subs_duration_type) title = `${_.capitalize(item.subs_duration_type)}ly`;
  if (item.mode === 'one_time') {
    return `${item.duration} ${item.duration_type}`;
  } else {
    return `${item.iap_id ? `${item.mode} (${item.iap_id})` : `${title} ${item.mode}`}`;
  }
};

export const VideoCallDefaultValue = () => {
  return {
    duration: 0,
    frequency: 'week',
    includes_calls: false,
    num_sessions: 0,
  };
};

export const parsePack = (doc) => {
  if (!doc || !doc.data) return null;
  const data = doc.data();
  if (data.archive) return null;
  return {
    _id: doc.id,
    index: data.index || 0,
    data,
  };
};

export const orderPacks = (docs) => {
  const activePacks = [];
  const hiddenPacks = [];
  const inactivePacks = [];
  (docs || []).forEach((doc) => {
    if (getActiveStatus(doc.data, "active")) activePacks.push(doc);
    else if (!!doc.data.hidden) hiddenPacks.push(doc);
    else inactivePacks.push(doc);
  });
  return [
    ..._.sortBy(activePacks, ['data.index']),
    ..._.sortBy(hiddenPacks, ['data.index']),
    ..._.sortBy(inactivePacks, ['data.index']),
  ];
};

export const parsePacks = (docs, isGroupClass) => {
  const out = [];
  docs.forEach((doc) => {
    const object = parsePack(doc);
    if(!object) return;
    if(!isGroupClass && ['group_class', 'one_to_one'].includes(_.get(object, "data.add_on_type"))) return;
    out.push(object);
  });
  return orderPacks(out);
};

export const getCopyOfPlan = (doc = {}, index) => {
  const obj={
    //basics
    index: index,
    title: doc.title,
    ref_name: `Copy of ${doc.ref_name}`,
    image: doc.image || null,
    description: doc.description || '',
    active: false,
    activeV2:false,
    active_ios: false,
    active_iosV2:false,
    //pricing:
    iap_ids: [],
    pc_ids: [],
    price_opts: [],
    //calling
    vid_call: doc.vid_call || {},
    //points
    how_it_work: doc.how_it_work || [],
    plan_includes: doc.plan_includes || [],
    //automation
    automation: doc.automation || {},
  }
  if (doc.type) obj.type = doc.type;
  if (doc.add_on_type) obj.add_on_type = doc.add_on_type;
  return {...obj};
};


export const getActiveStatus = (data, key) =>{
  const keyV2 = key + "V2";
  return _.get(data, keyV2) === undefined ? _.get(data, key) : _.get(data, keyV2);
}



export const getServiceConfig = (data) =>{
  const {add_on_type, price_opts, vid_call} = data;
  if(!vid_call?.includes_calls) return {service_config:{}}; //if call not enabled than, return {};
  const all = {
    count: vid_call?.num_sessions || 1,
    frequency:vid_call?.frequency || "plan"
  }
  let out = {all};
  if(add_on_type === "one_to_one"){
    //if adds on then, add config for each pricing id.
    const pIdsConfig = (price_opts || []).reduce((accumulator, currentValue)=>{
      accumulator[currentValue.id]={
        count : currentValue?.num_sessions || 1,
        frequency:"plan", //here hard code. UI has no options to add more frequency. 
      }
      return accumulator;
    },{})
    out = _.assign(out,pIdsConfig )
  }
  return {service_config:out };
};
