import _get from 'lodash/get';
import React, { useContext, useMemo } from 'react';
import {
  IconButton, Button, Hidden,
  Typography, Tooltip,
} from '@material-ui/core';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import CloseIcon from '@material-ui/icons/Close';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { UserSchContext } from '../provider';
import ImportBtn from './importBtn';
import CopyBtn from './copyBtn';
import WeekList from './week-list';
import { DateNav } from './dateNav';
import { getCopyingData } from "./";
import clsx from 'clsx';
import { ClrdButton } from 'fitbud/components/form-fields';

const AxnBar = ({ rhs, toggleRHS }) => {
  const { isAdmin } = useContext(FirebaseAuthContext);
	const { local, weekToday, dayToday, currWeek, currWeekId, opcopy, ready, controller } = useContext(UserSchContext);
  // const isPerDay = useMemo(() => {
  //   return _get(local, ['weeks', currWeekId, 'ml', 'mode']) === 'per_day';
  // }, [local, currWeekId]);

	const [copying, copyTitle, target] = useMemo(() => {
    return getCopyingData(opcopy,currWeek,weekToday);
	}, [currWeek, weekToday, opcopy]);

	if (!ready || typeof currWeek === 'undefined') return null;

	// if (copying) { // copy operation bar

		// return (
		// 	<div className='bar flex-shrink-0 d-flex flex-row border-bottom-2 px-25 align-items-center bg-vdark-grey'>
    //     <Hidden smUp><WeekList select light/></Hidden>
    //     <Hidden xsDown><Typography className='font_18_600 text-white'>{copyTitle}</Typography></Hidden>
    //     <div className='flex-grow-1'/>
    //     {target && <Button variant='contained' className='bg-white text-black' data-weekid={currWeekId} data-week={currWeek} onClick={controller.copyPaste}>
    //       Paste to Week {currWeek + 1}</Button>}
    //     <Hidden smUp><IconButton onClick={controller.copyClose} className='text-white ml-2'><CloseIcon/></IconButton></Hidden>
    //     <Hidden xsDown><Button onClick={controller.copyClose} variant='contained' className='bg-white text-black ml-3'>Done</Button></Hidden>
		// 	</div>
		// );
	// }

	return ( // standard bar
		// <div className='bar flex-shrink-0 d-flex flex-row border-bottom-2 pl-10 pl-md-20 pr-1 pr-md-16 align-items-center'>
		// 	<Hidden smUp><WeekList select/></Hidden>
		// 	<Hidden xsDown><Typography className='font_18_600'>Week {currWeek + 1}</Typography></Hidden>
    //   {dayToday >= 0 && currWeek !== weekToday && <Tooltip title='Jump to Today'><Button color='primary' onClick={controller.thisWeek} className='ml-12'>Today</Button></Tooltip>}
		// 	<div className='flex-grow-1'/>
      // {isAdmin && <Tooltip title='Add Weeks'><IconButton onClick={controller.addWeeksClick}><PlaylistAddIcon/></IconButton></Tooltip>}
    //   <ImportBtn isPerDay={isPerDay} hidden={rhs || currWeek < weekToday} week={currWeek} weekId={currWeekId} axn={controller.importClick}/>
    //   <CopyBtn hidden={rhs} isPerDay hasWOs hasMLs week={currWeek} weekId={currWeekId} axn={controller.copyClick}/>
		// 	<Hidden smUp><IconButton onClick={toggleRHS}>{rhs ? <CloseIcon/> : <MenuOpenIcon/>}</IconButton></Hidden>
		<div className='bar flex-shrink-0 d-flex justify-content-between align-items-center border-bottom-2 px-10 pl-md-25 pr-md-30 '>
      <div className="flex-1 d-flex justify-content-between align-items-center">
  			<Hidden smUp><WeekList select/></Hidden>
      </div>
      <div className='d-flex align-items-center'>
        {dayToday >= 0 && currWeek !== weekToday && <Button color='primary' onClick={controller.thisWeek} className='mr-30'>Go to Today</Button>}
        <DateNav/>
      </div>
      {/* <ImportBtn isPerDay={isPerDay} hidden={rhs || currWeek < weekToday} week={currWeek} weekId={currWeekId} axn={controller.importClick}/> */}
      <div className={clsx("flex-1 d-flex justify-content-end align-items-center")}>
        {isAdmin && <Tooltip title='Add Weeks'><IconButton onClick={controller.addWeeksClick}><PlaylistAddIcon/></IconButton></Tooltip>}
        {!copying && <CopyBtn hidden={rhs} isPerDay hasWOs hasMLs week={currWeek} weekId={currWeekId} axn={controller.copyClick}/>}
        {copying && target && <Typography className="text-primary font_15_600 cursor-pointer"  data-weekid={currWeekId} data-week={currWeek} onClick={controller.copyPaste}>Paste to Week {currWeek + 1}</Typography>}
      </div>
			{/* <Hidden xsDown><Typography className='font_18_600'>Week {currWeek + 1}</Typography></Hidden> */}
      {/* <ImportBtn isPerDay={isPerDay} hidden={rhs || currWeek < weekToday} week={currWeek} weekId={currWeekId} axn={controller.importClick}/> */}
      
		</div>
	);
};

export default AxnBar;
