import React, { useState } from 'react';
import {
  Typography,
  makeStyles,
  LinearProgress,
  InputBase,
  InputAdornment,
  IconButton,
  Badge,
  Collapse
} from '@material-ui/core';
import clsx from 'clsx';
import searchIcon from 'fitbud/images/searchIcon.svg';
import { FilterIcon } from 'fitbud/icons/filterIcon';
import { LineFilterIcon } from 'fitbud/icons/lineFilterIcon';
import Clear from '@material-ui/icons/CloseRounded';
import { getLabel } from 'fitbud/components/catalog/util';
import RefreshIcon from "fitbud/icons/refreshIcon";
import _ from "lodash";
import { NavIcon } from "fitbud/partials/appBar";
import QuickFilters from "fitbud/components/quick-filters";
import { AddIcon } from "fitbud/icons/addIconRounded";

const styles = makeStyles({
  container: {
    position: 'relative',
    background: '#F2F4F7',
    padding:'0px 20px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '2px solid #D8DCE0',
    '& .topContainer':{
      display:'flex',
      justifyContent:"space-between",
      alignItems:"center",
      height:56,
      '& .actionBtnsContainer':{
        display:'flex',
        marginRight:'-8px',
        '& .closeSvg':{
          fill:"#37404d"//mui icon, using same color as custom icon
        }
      }
    },
    '& .input': {
      borderRadius: '20px',
      height: 40,
      border: '1px solid #D3D9DB',
      background: '#fff',
      padding: '0px 10px',
      marginBottom:15
    },
    '& .title': {
      fontSize: 18,
      fontWeight: 600,
      textTransform: 'capitalize',
    },
    '& .loading': {
      position: 'absolute',
      bottom: -2,
      left: 0,
      width: '100%',
      height: 2,
    },
  },
});
export const ComponentListHeader = ({
  keyName,
  count,
  loading,
  openFilter,
  closeFilter,
  isFilter,
  appliedFilter,
  handleRefresh,
  onCloseSearch,
  showQwkFilter= false,
  filter={},
  setFilter,
  ...rest
}) => {
  const { q, handleChange, placeholder,onAdd=null } = rest;
  const classes = styles();
  const title = getLabel(keyName);
  const [showReload, setShowReload] = useState(false);
  const [isSearchOpen, toggleSearch] = useState(false);
  const openSearch = () => {
    toggleSearch(true);
  };
  const closeSearch = () => {
    toggleSearch(false);
    onCloseSearch && onCloseSearch();
  };

  return (
    <div
      className={clsx(classes.container)}
      onMouseEnter={() => setShowReload(true)}
      onMouseLeave={() => setShowReload(false)}
    >
      <div className="topContainer">
        <NavIcon/>
        <Typography variant="h3" className="flex-grow-1 text-capitalize">
          {title} {!_.isNil(count) && <span className="font-weight-500">{`(${count || 0})`}</span>}
        </Typography>
        <div className="actionBtnsContainer">
          {showReload && (
            <IconButton onClick={handleRefresh}>
              <RefreshIcon />
            </IconButton>
          )}
          {isFilter && (
            <IconButton onClick={openFilter}>
              <Badge color="primary" badgeContent={appliedFilter}>
                {appliedFilter?<FilterIcon />:<LineFilterIcon />}
              </Badge>
            </IconButton>
          )}
          {isSearchOpen ? (
            <IconButton onClick={closeSearch}>
              <Clear className="closeSvg"/>
            </IconButton>
          ) : (
            <IconButton size="medium" onClick={openSearch}>
              <img alt="search-icon" src={searchIcon} />
            </IconButton>
          )}
          {onAdd && <IconButton onClick={onAdd}>
            <AddIcon/>
          </IconButton>}
        </div>
      </div>
      <Collapse in={isSearchOpen} timeout="auto" unmountOnExit>
            <InputBase
              value={q}
              onChange={handleChange}
              fullWidth
              autoFocus
              placeholder={placeholder}
              startAdornment={
                <InputAdornment position="start">
                  <img alt="search-icon" src={searchIcon} />
                </InputAdornment>
              }
              className="input"
            />
      </Collapse>
      <Collapse in={showQwkFilter && !isSearchOpen}>
        <QuickFilters keyName={keyName}
        filter={filter} setFilter={setFilter} 
        />
      </Collapse>
      {loading && <LinearProgress className="loading" />}
    </div>
  );
};
