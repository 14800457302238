import React, { useState, useReducer, useContext, useEffect, useCallback } from 'react';
import _ from 'lodash';
import * as Sentry from '@sentry/browser';
import Dialog from 'fitbud/components/Dialog';
import { useSnackbar } from 'notistack';
import { FormTextField } from 'fitbud/components/form-fields';
import { TextField, Divider } from '@material-ui/core';
import ImageFileUpload from 'fitbud/components/imageSelector';
import update from "immutability-helper";
import { Typography } from '@material-ui/core';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import firebase from 'fitbud/firebase';
import { useDispatch } from 'react-redux';
import appRdxFns from 'fitbud/redux/app';
import { MediaUploader } from "fitbud/components/media-uploader/mediaUploader";
import { DialogPdfUploader as PdfUploader } from 'fitbud/components/pdfUploader';
import { isValidURL } from "fitbud/utils/helpers";
import Confirmation from "fitbud/components/confirmationDialog";
import {uploadPdf} from "fitbud/utils/aws";
import {
  DEFAULT_ERROR,
  YOUTUBE_VIDEO_URL_REGEX,
  VIMEO_VIDEO_URL_REGEX,
  FILE_STATUS,
  ERROR_URL,
  ERROR_YOUTUBE_URL,
  ERROR_VIMEO_URL,
  ERROR_REF_NAME,
  DEFAULT_VID_UPLOAD_ERROR,
} from 'fitbud/utils/constants';
import fileUploadRdxFns from 'fitbud/redux/fileUpload';
import { createVidObject } from 'fitbud/api';

const reducer = (os, ns) => ({ ...os, ...ns });
const INITIAL_STATE = { title: '', ref_name: '', description: '' };

const getTitle = (type) => {
  switch (type) {
    case 'video':
      return 'Video';
    case 'link':
    case 'externalLink':
      return 'Link';
    case 'document':
      return 'Document';
    default:
      return '';
  }
};

const AddDialog = (props) => {
  const { type, handleClose, onSave, data: dataProps = {}, isEdit = false, id } = props;
  const dispatch = useDispatch();
  const { cid } = useContext(FirebaseAuthContext);
  const [state, setState] = useReducer(reducer, INITIAL_STATE);
  const [errors, setErrors] = useState({});
  const [dirty, setDirty] = useState(false);
  const { showLoader, hideLoader } = appRdxFns(dispatch);
  const { uploadBunnyFile } = fileUploadRdxFns(dispatch);
  const { enqueueSnackbar } = useSnackbar();
  const [isConfirmationOpen, showConfirmation] = useState(false);

  useEffect(() => {
    if (Object.keys(dataProps).length) {
      setState({ ...dataProps });
    }
  }, [dataProps]);

  const _handleClose = () => {
    handleClose();
  };

  const valid = () => {
    let out = true;
    const errs = {};
    if (!(state.title || "").trim()) {
      out = false;
      errs.title = 'Display name required';
    }
    if (!(state.ref_name || "").trim()) {
      out = false;
      errs.ref_name = ERROR_REF_NAME;
    }
    if(type === "link" || type === "externalLink"){
      if(!state.link){
        out =false;
        enqueueSnackbar("Link Required", {variant:"error"});
      }
      if(!!state.link){
        if(!isValidURL(state.link)){
          out = false;
          errs.link = ERROR_URL;
        }
      }
    }
    if(type ===  "document"){
      const _document = _.get(state, "document.0", {});
      const {type : documentType, url} = _document || {};
      if(!url){
        out = false;
        enqueueSnackbar("Document Required", {variant:"error"});
      }
      const documentErrors={};
      if((documentType === "link" || documentType === "externalLink") && !!url){
        if(!isValidURL(url)){
          documentErrors.url = ERROR_URL;
          out = false;
        }
      }
      errs.document = documentErrors;
    }
    if(type === "video"){
      const url = _.get(state, "media.0.url", "");
      const type = _.get(state, "media.0.type", "");
      if(!url){
        out = false;
        enqueueSnackbar("Video Required", {variant:"error"});
      }
      if(!!url){
        if(type === "youtube"){
          if(!YOUTUBE_VIDEO_URL_REGEX.test(url)){
            errs.mediaUrl = ERROR_YOUTUBE_URL;
            out = false;
          }
        }else if (type === "vimeo"){
          if(!VIMEO_VIDEO_URL_REGEX.test(url)){
            errs.mediaUrl = ERROR_VIMEO_URL;
            out = false;
          }
        }

      }
    }
    setErrors(errs);
    return out;
  };

  const createVideoObject = async({ id, duration, title, resolution }) => {
    try{
      const collection = "resources";
      const resp=await createVidObject({
        cid: cid,
        docId: id,
        path: `companies/${cid}/${collection}/${id}`,
        collection,
        media_bgoff: false,
        video:{
          title,
          duration,
          resolution,
        }
      });
      return await resp.data;
    }
    catch(err){
      enqueueSnackbar(_.get(err,"response.data.message",DEFAULT_VID_UPLOAD_ERROR),{variant:"error"});
      console.log("err",err);
      return null;
    }
  }
  const uploadVideoToBunnyCDN = async({ id, file, docName, ...videoObject }) => {
    uploadBunnyFile({
      docId: id,
      cid: cid,
      file: file.url,
      collection: "resources",
      media_bgoff:false,
      path: `/explore/resources/${id}`,
      docName,
      videoObject,
    });
  }

  const _onSubmit = async () => {
    let out = { ...state };
    if (!valid()) return;
    out.type = type;
    const { media, document } = out;
    //this will validate
    showLoader();
    try {
      /* video related task prepare meta data for video */
      let firebaseId;
      let newMediaAvailable = !!_.get(media,"0.url.name", null);
      if (media && media.length && media[0].url && media[0].url.name) {
         // It'a manual file upload
        if(newMediaAvailable){
          firebaseId = !isEdit ? firebase
          .firestore()
          .collection(`companies/${ cid}/resources`)
          .doc().id : id ;
          //create video obj
          const resp = await createVideoObject({
            id: firebaseId,
            duration: _.get(media,"0.duration"),
            title: _.get(media,"0.url.name",""),
            resolution: _.get(media,"0.height","")
          });
          if(!resp){
            hideLoader();
            return;
          }
          newMediaAvailable = resp;
          out = update(out, {
            media:{
              $set:[{
                type:"video",
                status:resp ? FILE_STATUS.uploading : FILE_STATUS.error,
                videoId:_.get(resp,'videoId',"")
              }]
            }
          });
        }
      } else if (media && media[0] && ['youtube', 'vimeo'].includes(media[0].type) && media[0].url) {
        out.media = [{ type: media[0].type, url: media[0].url, ...(media[0].error && {error: media[0].error}) }];
      }
      /* video related task end */
      /* document/Pdf related task  */
      const _document = document && document[0];
      const _document_type = _.get(_document, 'type', '');
      const _isLocalFile = _.get(_document, 'isLocal', false);
      if (_document_type === 'file' && _isLocalFile && _document.url) {
         try {
           const _docResponse = await uploadPdf({ cid, file: _document.url });
           out.document = [_docResponse];
         } catch (err) {
           hideLoader();
           enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
           Sentry.captureException(err);
           return;
         }
      }
      /* Pdf related task end */
      let _d;
      if (isEdit) {
        const resourceDocRef = firebase
          .firestore()
          .collection(`companies/${cid}/resources`)
          .doc(id || '');
        await resourceDocRef.update(out);
        _d = await resourceDocRef.get();
      } else {
        const createNewId = !newMediaAvailable;
        const resourceColRef = firebase.firestore().collection(`companies/${cid}/resources`);
        const ref = createNewId ? await resourceColRef.add(out) : await resourceColRef.doc(firebaseId).set(out);
        _d = createNewId ? await ref.get() : await resourceColRef.doc(firebaseId).get();
      }
      const data = _d.data();
      /* if video is available then upload video */
      if (newMediaAvailable) {
        uploadVideoToBunnyCDN({
          id: firebaseId || id,
          file: media[0],
          docName: data.ref_name,
          ...newMediaAvailable,
        });
      }
      const _out = { id: _d.id, data };
      onSave && onSave(_out);
      handleClose();
    } catch (err) {
      console.log(err);
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
      Sentry.captureException(err);
    } finally { hideLoader() };
  };

  const handleMedia = (media) => {
    const {type} = media;
    let outMedia = [];
    if (['youtube', 'vimeo', 'video', 'image'].includes(type)) {
      setDirty(true);
      outMedia = [media];
      setState({ media:outMedia });
    }
  };

  const removeMedia = (type) => {
    let media = [{ type, url: '' }];
    setState({ media });
  };

  const handleDocument = (type, file, meta = false) => {
    const _docName = _.get(state, 'document.0.name', '');
    let _document = [];
    if (type === 'file') {
      //incase of file
      _document = [{ type, name: _docName }];
      if (!!file) {
        if (meta) _document = [{ type, ...(file || {}) }];
        //if meta is true ie only meta fields changes like name
        else
          _document = [
            { url: file, file_original_name: file.name, size: file.size, type, isLocal: true, name: _docName },
          ];
      }
    } else if (type === 'link' || type === 'externalLink') {
      //incase iof link
      _document = [{ type, name: _docName, ...(file || {}) }];
    }
    setState({ document: _document });
  };

  const handleChange = (e) => {
    const type = e.target.name || e.target.id;
    let _value = e.target.value;
    if(type === "link") _value = (_value || "").trim();
    const _out = { [type]: _value };
    setState(_out);
    setDirty(true);
  };

  const updateMediaMeta = (args={}) => {
    const { media } = state;
    if(media && !!_.isMatch(media[0],args)) return;
    const newState= update(state,{
      media:{
        0:{
          $merge:{
            ...args
          }
        }
      }
    });
    setState(newState);
  }
  const openConfirmationDialog = () => {
    dirty ? showConfirmation(true) : _handleClose();
  };


  const { ref_name, title, thumbnail, link } = state || {};
  return (
    <Dialog
      toolbarClass="height-60"
      buttonColor="primary"
      open
      onClose={openConfirmationDialog}
      onSave={_onSubmit}
      title={`${isEdit ? 'Edit' : 'Add'} ${getTitle(type)} Resource`}
      titleFont="h3"
      paperClass="width-640"
      maxWidth="md"
    >
      <div>
        <div className="p-20">
          <div className="d-flex fmb-20">
            <div className="flex-1 d-flex flex-column justify-content-between">
              <FormTextField fullWidth label="Display Name" required classes={{ control: '' }}>
                <TextField
                  id="title"
                  autoComplete="off"
                  defaultValue={title}
                  onChange={handleChange}
                  error={!!errors.title}
                  helperText={errors.title}
                  variant="outlined"
                  required
                  type="text"
                  InputProps={{
                    classes: {
                      root: 'medium',
                      input: 'size_16_500 medium',
                    },
                  }}
                />
              </FormTextField>
              <FormTextField fullWidth label="Reference Name" required classes={{ control: 'mb-0' }}>
                <TextField
                  id="ref_name"
                  autoComplete="off"
                  defaultValue={ref_name}
                  onChange={handleChange}
                  error={!!errors.ref_name}
                  helperText={errors.ref_name}
                  variant="outlined"
                  required
                  type="text"
                  InputProps={{
                    classes: {
                      root: 'medium',
                      input: 'size_16_500 medium',
                    },
                  }}
                />
              </FormTextField>
            </div>
            {type !== 'video' && <ImageFileUpload
              aspectRatio={2/3} thumbnail={thumbnail}
              id={'thumbnail'} onChange={handleChange}
              size="small" className="ml-20" />}
          </div>
        </div>
        <Divider className="mb-20" />
        {(type === 'link' || type === 'externalLink') && <LinkInput handleChange={handleChange} error={errors.link} link={link} />}
        {type === 'video' && (
          <VideoInput
            handleChange={handleChange}
            data={state}
            handleMedia={handleMedia}
            errors={errors}
            removeMedia={removeMedia}
            updateMediaMeta={updateMediaMeta}
            isNew={!isEdit}
          />
        )}
        {type === 'document' && <PdfInput data={state} handleDocument={handleDocument} errors={errors.document || {}} />}
      </div>
      {isConfirmationOpen && (
        <Confirmation
          open
          handleClose={() => showConfirmation(false)}
          handleChange={() => _handleClose()}
          handleCancel={() => showConfirmation(false)}
        />
      )}
    </Dialog>
  );
};

const PdfInput = (props) => {
  const { data, errors, handleDocument } = props;
  const { document } = data;
  return (
    <div className="px-20 pb-20">
      <Typography className="font_18_600 mb-20">Document
      <span aria-hidden="true" class="MuiFormLabel-asterisk"> *</span>
      </Typography>
      <PdfUploader
        classes={{ root: 'w-100 fb-border-radius-5' }}
        handleChange={handleDocument}
        document={document}
        errors={errors}
        isName={false}
      />
    </div>
  );
};

const VideoInput = (props) => {
  const { data, errors, handleChange, handleMedia, removeMedia, updateMediaMeta, isNew } = props;
  const { media, thumbnail } = data;
  
  const setDuration=(secs)=>{
    const value = Math.round(secs);
    updateMediaMeta({duration:value});
  }
  const whenPlayerReady = useCallback((player)=>{
    const videoHeight=player.getInternalPlayer().videoHeight;
    if(!!videoHeight){
      updateMediaMeta({height:`${videoHeight}p`});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data.media]);

  return (
    <div className="px-20 pb-20">
      <MediaUploader
        label={'Media'}
        media={media}
        errors={errors}
        handleChange={handleChange}
        handleMedia={handleMedia}
        removeMedia={removeMedia}
        doc={data}
        required
        hideYoutube
        is_type_specific={true}
        withThumbnail
        thumbnail={thumbnail}
        thumbaspect={2/3}
        isNew={isNew}
        playerProps={{
          onDuration:setDuration,
          onReady: whenPlayerReady,
        }}
      />
    </div>
  );
};

const LinkInput = (props) => {
  const { link, error, handleChange } = props;
  return (
    <div className="px-20 pb-20">
      <Typography className="font_18_600 mb-20">Link
      <span aria-hidden="true" class="MuiFormLabel-asterisk"> *</span>
      </Typography>
      <TextField
        id="link"
        value={link}
        onChange={handleChange}
        fullWidth
        error={!!error}
        helperText={error}
        variant="outlined"
        type="text"
        InputProps={{
          classes: {
            root: 'medium',
            input: 'size_16_500 medium',
          },
        }}
      />
    </div>
  );
};

export default AddDialog;
