import React from 'react';
import { withStyles,Tooltip } from '@material-ui/core';
export const DarkTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: 'rgb(66, 72, 75)',
      color: 'white',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      padding:"8px",
      fontSize:"12px"
    },
  }))(Tooltip);
  