import React, { useContext, useEffect, useState } from 'react';
import firebase from 'fitbud/firebase';
import { AppAlertIndicator } from 'fitbud/components/appAlert';
import moment from 'moment';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import {
  STATUS_CANCEL,
  sortByStartDate,
  structureAppointment,
  structureInstance,
  filterActiveInstances,
} from 'fitbud/utils/scheduling';
import { Card, makeStyles, Typography, Collapse, AppBar, Toolbar } from '@material-ui/core';
import { BookingDetails } from './subComps/bookingsDetails';
import CameraIcon from 'fitbud/icons/camera';
import clsx from 'clsx';
import { useAppAlertContext } from 'fitbud/providers/appAlertProvider';
import { ArrowIcon } from 'fitbud/components/fileUploadProgress';
import _ from 'lodash';
import { GroupClassToolTipAction, OneToOneToolTipAction } from 'fitbud/views/liveSessions/scheduler/Tooltip';

const useStyles = makeStyles((theme) => ({
  appBar: {
    cursor: 'pointer',
    backgroundColor: '#37404D',
    borderRadius: '5px 5px 0px 0px',
  },
  contentContainer: {
    position: 'fixed',
    right: '0px',
    bottom: '0px',
  },
  box: {
    width: '360px',
  },
  ongoing: {
    height: '185px',
  },
  upcoming: {
    height: '244px',
  },
}));

const DELAY_UNIT = 'seconds';
const ALERT_TYPE = 'videoSession';

const NextSessionBar = (props) => {
  const { cid, authUser: { uid } = {}, comp } = useContext(FirebaseAuthContext);
  const company = comp ? comp.data() : {};
  const isGroupClass = Boolean(_.get(company, "features.group_class.enabled", false));
  const [bookings, setBooking] = useState([]);
  const [classes, setClasses] = useState([]);
  const styles = useStyles();
  const { expendedAlertType, setExpandedAlertType, setIsOverTheTop } = useAppAlertContext();
  const [refresh, setRefresh] = useState(false);
  const [currentSessions, setCurrentSessions] = useState([]);
  const [showContent, setContent] = useState(true);
  useEffect(() => {
    if (expendedAlertType && expendedAlertType !== ALERT_TYPE) {
      setContent(false);
    }
  }, [expendedAlertType]);

  function hideBar() {
    // if(!status.ongoing) setHide(true)
  }

  function onExpand() {
    setContent(!showContent);
    setExpandedAlertType(!showContent ? ALERT_TYPE : null);
  }

  //effect for loading one to one booking..
  useEffect(() => {
    //get one hour before, classes.
    const start = moment().subtract(1, 'hour').toDate();
    const end = moment(start).endOf('day').toDate();
    let query = firebase
      .firestore()
      .collection(`/companies/${cid}/bookings`)
      .where('class', '==', null)
      .where('trainer_id', '==', uid)
      .where('time', '>=', start)
      .where('time', '<', end)
      .orderBy('time', 'desc');
    const unsubscribe = query.onSnapshot(
      (snapshot) => {
        const changes = snapshot.docChanges();
        if (!changes.length) return;
        const out = [];
        changes.forEach(({ doc }, index) => {
          const data = doc.data();
          let appointment = { ...data, book_id: doc.id, _id: doc.id };
          appointment = structureAppointment(appointment, index, { withTimestamp: true, includeCancelled: true });
          if (appointment) out.push(appointment);
        });
        setBooking((prev) => {
          if (!prev || !prev.length) return out;
          const result = [...prev];
          out.forEach((booking) => {
            const book_id = booking.book_id;
            const index = _.findIndex(result, (b) => b.book_id === book_id);
            if (index > -1) {
              //ie found...
              if (booking.status === STATUS_CANCEL) {
                //status cancel then remove it.
                result.splice(index, 1);
              } else {
                //other wise update
                result.splice(index, 1, { ...booking });
              }
            } else if (index === -1) {
              //ie not found than add to it.
              result.push(booking);
            }
          });
          return result;
        });
      },
      (error) => {
        console.log('>>>error on snapshot of one to one', error);
      }
    );

    return () => unsubscribe();
  }, [refresh]);
  //effect for loading instances
  useEffect(() => {
    if (!isGroupClass) return;
    const utcStartTime = moment.utc();
    const start = utcStartTime.subtract(1, 'hour').format('YYYYMMDDHHmm');
    const end = moment().endOf('day').format('YYYYMMDDHHmm');
    const query = firebase
      .firestore()
      .collection(`/companies/${cid}/gcInstances`)
      .where('trainer_id', '==', uid)
      .where('time', '>=', start)
      .where('time', '<=', end)
      .orderBy('time', 'desc');
    const unsubscribe = query.onSnapshot({ includeMetadataChanges: true }, (snapshot) => {
      const changes = snapshot.docChanges();
      const updates = [];
      changes.forEach((snap) => {
        const data = snap.doc.data();
        if (data.mode === 'offline') return;
        const instance = { _id: snap.doc.id, ...data, book_id: snap.doc.id };
        const appointment = structureInstance(instance);
        if (appointment) updates.push(appointment);
      });
      setClasses((prev) => {
        if (!prev || !prev.length) return filterActiveInstances(updates);
        const instances = [...prev];
        updates.forEach((instance) => {
          const index = _.findIndex(instances, (i) => i.book_id === instance.book_id);
          if (index > -1) instances.splice(index, 1, { ...instance });
          else instances.push(instance);
        });
        return filterActiveInstances(instances);
      });
    });
    return () => unsubscribe && unsubscribe();
  }, [isGroupClass, refresh]);

  const checkUpcomingBooking = (bookings) => {
    const out = [];
    const now = moment();
    bookings.forEach((booking) => {
      const { startDate, endDate, status } = booking;
      if (status === STATUS_CANCEL) return; // skipped canceled booking..
      if (now.diff(moment(startDate), 'minutes') >= -10 && moment(endDate).diff(now, 'minutes') > 0) {
        out.push(booking);
      }
    });
    setCurrentSessions(out);
  };

  useEffect(() => {
    let allBookings = [...bookings, ...classes];
    allBookings = sortByStartDate(allBookings);
    if (!allBookings || !allBookings.length) return;
    checkUpcomingBooking(allBookings);
    //now checking for bookings which is about to end about to begin..
    const interval = setInterval(() => {
      checkUpcomingBooking(allBookings);
    }, 1000 * 60);
    return () => clearInterval(interval);
  }, [bookings, classes]);

  if (currentSessions && currentSessions.length) {
    return (
      <div style={{ minHeight: 70 }} className={clsx('d-flex flex-column align-items-end position-relative')}>
        {!showContent && (
          <AppAlertIndicator onClick={onExpand}>
            <CameraIcon />
          </AppAlertIndicator>
        )}
        {showContent && (
          <Collapse classes={{ root: clsx(styles.contentContainer) }} in={!!showContent}>
            <Card className={clsx('fmb-15 fmr-30', styles.box)} id="box">
              <MinimizedBar onExpand={onExpand} onClose={hideBar} expand={showContent} />
              <div className={clsx('overflow-auto')} style={{ maxHeight: 500 }}>
                <ExpandedContent sessions={currentSessions} />
              </div>
            </Card>
          </Collapse>
        )}
      </div>
    );
  }

  return null;
};

function MinimizedBar(props) {
  const { onExpand, expand } = props;
  const classes = useStyles();
  return (
    <AppBar position="static" color="primary" className={classes.appBar}>
      <Toolbar
        onClick={onExpand}
        style={{ minHeight: '60px' }}
        className={clsx('d-flex justify-content-between align-items-center', 'fpx-20 fpr-15 fpy-15')}>
        <div>
          <Typography className="text-white" variant="h6" noWrap>
            Upcoming Session
          </Typography>
        </div>
        <div>
          {!expand ? (
            <ArrowIcon fontSize="small" className="text-white " />
          ) : (
            <ArrowIcon fontSize="small" className="text-white " id="expand-icon" />
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
}

const useClasses = makeStyles(() => ({
  divider: {
    borderBottom: '1px solid #D3D9DB',
  },
}));

function ExpandedContent({ sessions }) {
  const classes = useClasses();
  if (!!sessions && !!sessions.length) {
    return sessions.map((session, index) => {
      const isGroupClass = !!session.class;
      return (
        <div className={clsx(`bg-white fpx-20 fpy-20`, classes.divider)}>
          <BookingDetails metaOnly={true} className="mb-20" session={session} />
          {isGroupClass && <GroupClassToolTipAction session={session} />}
          {!isGroupClass && <OneToOneToolTipAction session={session} />}
        </div>
      );
    });
  }

  return null;
}

export default NextSessionBar;
