import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const SettingsIcon = props => (
    <SvgIcon
        {...props}
        viewBox="0 0 20 20"
        classes={{
          root: "small"
        }}
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.3389 6.35298L16.8202 5.4529C16.3814 4.6913 15.4089 4.42856 14.6463 4.86556V4.86556C14.2832 5.07942 13.8501 5.14009 13.4422 5.0342C13.0344 4.92832 12.6855 4.66456 12.4723 4.30111C12.3352 4.07009 12.2616 3.80696 12.2588 3.53833V3.53833C12.2711 3.10765 12.1087 2.6903 11.8083 2.38136C11.508 2.07241 11.0954 1.89818 10.6646 1.89836H9.61956C9.19745 1.89836 8.79274 2.06656 8.49498 2.36575C8.19722 2.66494 8.03096 3.07046 8.03299 3.49256V3.49256C8.02048 4.36407 7.31038 5.06397 6.43879 5.06388C6.17016 5.06109 5.90703 4.98742 5.67601 4.85031V4.85031C4.91336 4.41331 3.94091 4.67604 3.5021 5.43764L2.94527 6.35298C2.50699 7.11363 2.76615 8.08547 3.52498 8.52689V8.52689C4.01823 8.81167 4.32209 9.33796 4.32209 9.90751C4.32209 10.4771 4.01823 11.0034 3.52498 11.2881V11.2881C2.76711 11.7266 2.50767 12.6961 2.94527 13.4544V13.4544L3.47158 14.3621C3.67719 14.7331 4.02215 15.0069 4.43014 15.1228C4.83813 15.2388 5.27551 15.1874 5.6455 14.98V14.98C6.00921 14.7677 6.44264 14.7096 6.84943 14.8185C7.25622 14.9273 7.60268 15.1942 7.81178 15.5597C7.94889 15.7907 8.02257 16.0538 8.02536 16.3225V16.3225C8.02536 17.2029 8.73911 17.9167 9.61956 17.9167H10.6646C11.5421 17.9167 12.2546 17.2076 12.2588 16.3301V16.3301C12.2567 15.9067 12.424 15.5 12.7234 15.2006C13.0229 14.9012 13.4295 14.7339 13.853 14.7359C14.121 14.7431 14.383 14.8164 14.6158 14.9495V14.9495C15.3764 15.3877 16.3482 15.1286 16.7897 14.3698V14.3698L17.3389 13.4544C17.5514 13.0895 17.6098 12.655 17.501 12.2469C17.3922 11.8389 17.1252 11.4911 16.7592 11.2805V11.2805C16.3931 11.0699 16.1261 10.7221 16.0173 10.3141C15.9085 9.90605 15.9669 9.47148 16.1794 9.1066C16.3177 8.86524 16.5178 8.66513 16.7592 8.52689V8.52689C17.5134 8.08571 17.772 7.11954 17.3389 6.3606V6.3606V6.35298Z" stroke="#8F969F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <ellipse cx="10.1459" cy="9.90751" rx="2.1968" ry="2.1968" stroke="#8F969F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </SvgIcon>
);
