import React from "react";
import _ from "lodash";
import { Chip } from "@material-ui/core";
import AsyncCreatableSelect from 'react-select/async-creatable';
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from '@material-ui/icons/CloseRounded';
import clsx from "clsx";
import { fetchData } from "fitbud/api";
import { TAG_INDEX } from "fitbud/utils/constants";


const useChipStyle = makeStyles(theme => ({
    root: {
      height: 22,
      border: 0,
      marginRight: 8,
      marginBottom: 8,
      borderRadius: 40,
      backgroundColor: "#F2F4F7",
      "&:focus": {
        backgroundColor: "#F2F4F7"
      },
      "& .MuiChip-deleteIcon": {
        display: "none"
      },
      "&:hover": {
        "& .MuiChip-deleteIcon": {
          display: "block"
        },
        backgroundColor: "none"
      }
    },
    deleteIcon: {
       border: 0,
       boxShadow: "none",
       color: "#000",
       "&:hover": {
         color: "#000"
       },
       width: 12,
       height: 12
    },
  }));

export const TagsList = props => {
  const chipClasses = useChipStyle()
  const { tags =[], setTags = () => {}, editable = false, noWrap, cid = window.localStorage.getItem("cid") } = props;
  const handleDelete = tagIndexToDelete => () => {
    setTags(tags.filter((tag, index) => index !== tagIndexToDelete));
  };
  const handleChange = (newValue, actionMeta) => {
    console.group("Value Changed");
    console.log(newValue);
    if (newValue) {
      const tagValue = newValue.value;
      if (tags.indexOf(tagValue) < 0) {
        setTags([...tags, tagValue]);
      }
      return;
    }
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  };
  //API needed
  const handleInputChange = (inputValue, actionMeta) => {
    console.group("Input Changed");
    console.log(inputValue, "2");
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  };

  const debouncedFetch = _.debounce((inputValue, callback) => {
    fetchData(TAG_INDEX, cid, 0, inputValue, 15)
      .then(({ data }) => {
        callback((data.data || []).map(d => ({ label: d.data.name, value: d.data.name })))
      })
      .catch((error) => callback(null));
  }, 500);

  return (
    <div  className={clsx(
      "d-flex m-0 p-0",
      !noWrap && "flex-wrap"
    )}>
      {props.children}
      {tags.map((tag, index) => {
        return (
          <Chip
            classes={{
              root: chipClasses.root,
              deleteIcon: chipClasses.deleteIcon,
              label: `${chipClasses.label} font_12_500`,
              labelSmall: `${chipClasses.labelSmall} font_12_500`
            }}
            variant="outlined"
            key={index}
            size="small"
            label={tag}
            deleteIcon={<ClearIcon/>}
            onDelete={editable ? handleDelete(index) : undefined}
          />
        );
      })}
      {editable && (
        <AsyncCreatableSelect
          blurInputOnSelect
          placeholder="+ Add Tag"
          value={null}
          isClearable
          onChange={handleChange}
          onInputChange={handleInputChange}
          cacheOptions
          defaultOptions={false}
          loadOptions={debouncedFetch}
          styles={{
            container: base => ({
              ...base,
              height: 22
            }),
            control: base => ({
              ...base,
              flex: 1,
              minHeight: 22,
              height: 22,
              width: 150,
              border: 0,
              boxShadow: "none"
            }),
            valueContainer: base => ({
              ...base,
              height: 22,
              padding: 0,
            }),
            dropdownIndicator: base => ({
              ...base,
              display: "none"
            }),
            indicatorSeparator: base => ({
              ...base,
              display: "none"
            }),
            placeholder: (base, props) => {
              return {
                ...base,
                fontSize: 12,
                fontWeight: 500,
                height: 22,
                lineHeight: "22px",
                // position: 'fixed',
                top: 'unset',
                transform: 'unset',
                WebkitTransform: 'unset',
                display: props.isFocused ? 'none': 'inherit'
              }
            },
            input: base => ({
              ...base,
              height: 22,
              fontSize: 12,
              fontWeight: 500,
              lineHeight: "22px",
              '& input': {
                fontSize: 12,
                fontWeight: 500,
                color: 'rgba(0, 0, 0, 0.87)',
              },
            })
          }}
        />
      )}
    </div>
  );
};
