import React from 'react';
import ParticipantInfo from './participantInfo';
import ParticipantTracks from './participantTracks';

const Participant = ({ participant, isLocalParticipant, hideParticipant, onClick, isSelected }) => {
  return (
    <ParticipantInfo participant={participant} isLocalParticipant={isLocalParticipant} hideParticipant={hideParticipant} onClick={onClick} isSelected={isSelected}>
      <ParticipantTracks participant={participant} isLocalParticipant={isLocalParticipant}/>
    </ParticipantInfo>
  );
}



export default Participant;