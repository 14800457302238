import React, { Component } from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Typography,
  Tooltip,
} from "@material-ui/core";
import VisOnIcon from "@material-ui/icons/Visibility";
import VisOffIcon from "@material-ui/icons/VisibilityOff";
import moment from "moment";
import { DatePicker } from "@material-ui/pickers";
import { isMobile } from "fitbud/utils/device";
import clsx from 'clsx';

export const FormTextField = props => {
  let { label, info, children, fullWidth, classes = {}, style, required, error, wordCount = "", btnProps,formControlProps={} } = props;
  return (
    <FormControl fullWidth={fullWidth} className={classes.control || "fmb-20"} error={!!error} style={style} {...formControlProps}>
     {!!(label || wordCount || btnProps) && <div className="d-flex justify-content-between">
        {label && <FormLabel className={clsx("font_13_500 fmb-10",classes.label)} for="" required={required}>
          {label}{Boolean(info) && <Tooltip title={info}><i className='fas fa-info-circle ml-5'/></Tooltip>}
        </FormLabel>}
        {wordCount &&
          <Typography className={clsx("text-grey font_13_500")}>
            {wordCount}
          </Typography>
        }
        {btnProps && <ClrdButton {...btnProps}/>}
      </div>}
      {children}
    </FormControl>
  );
};
const ClrdButton = props => {
  const {
    variant,
    loading,
    children,
    disabled,
    color,
    className,
    style = {},
    title,
    ...rest
  } = props;
  let styling = "";
  let _var = "contained";
  let _clr = "inherit";
  let _stl = {
    ...style
  };
  const greyout = loading || disabled;
  switch (color) {
    case "success":
      styling = "bg-success text-white";
      break;
    case "danger":
      styling = "bg-danger text-white";
      break;
    case "warning":
      styling = "bg-warning text-dark";
      break;
    case "info":
      styling = "bg-info text-white";
      break;
    case "fancy":
      styling = "bg-fancy text-white";
      break;
    case "main":
      styling = "bg-primary text-white";
      break;
    case "invert":
      styling = "bg-white text-primary";
      break;
    case "white-black":
      styling = "bg-white text-dark";
      break; 
    case "black-white":
      styling = "bg-dark text-white";
      break;  
    case "light-primary":
      styling="bg-lightPrimary text-primary";
      break;
    case "black":
      styling="bg-black text-white"
      break;
    default:
      if (!!color && color.match(/^#[0-9abcdef]{3,6}$/i)) {
        if (greyout) {
          _stl.backgroundColor = "";
        } else {
          _stl.backgroundColor = color;
        }
      } else {
        _var = variant;
        _clr = color;
      }
      break;
  }
  const override = `${greyout ? "" : styling} ${className || ""}`;
  return (
    <Button
      variant={_var}
      disabled={greyout}
      color={_clr}
      className={override}
      style={_stl}
      {...rest}
    >
      {loading ? <CircularProgress color="primary" size={24} /> : children||title}
    </Button>
  );
};
const PhoneField = props => {
  const { value, onChange, ...rest } = props;
  const onChangeInternal = e => {
    if (onChange) {
      e.target.value = to10DigitNumber(e.target.value);
      e.currentTarget.value = to10DigitNumber(e.target.value);
      onChange(e);
    }
  };
  const startAdornment = <InputAdornment position="start">+91</InputAdornment>;
  const commonProps = {
    onChange: onChangeInternal,
    value: toPhoneNumber(value || ""),
    placeholder: "999-999-9999",
    InputProps: { startAdornment }
  };
  const commonNativeProps = {
    title: "10 digit phone number",
    pattern: "[1-9][0-9]{2}-[0-9]{3}-[0-9]{4}"
  };
  if (window.Modernizr.inputtypes.tel) {
    return (
      <TextField
        {...rest}
        {...commonProps}
        type="tel"
        inputProps={{ minLength: 12, maxLength: 12, ...commonNativeProps }}
      />
    );
  } else {
    return (
      <TextField {...rest} {...commonProps} inputProps={commonNativeProps} />
    );
  }
};
const DateField = props => {
  const useNative = window.Modernizr.inputtypes.date && isMobile;
  const { dob, value, ...rest } = props;
  const max = dob ? moment18YrsAgo().format("YYYY-MM-DD") : "";
  if (useNative) {
    return (
      <TextField
        {...rest}
        type="date"
        value={value || max}
        inputProps={{ max }}
      />
    );
  } else {
    const onChangeInternal = date => {
      if (props.onChange) {
        const id = rest.id || "";
        const name = rest.name || "";
        const value = date.format("YYYY-MM-DD");
        props.onChange({
          target: { id, name, value },
          currentTarget: { id, name, value }
        });
      }
    };
    return (
      <DatePicker
        {...rest}
        allowKeyboardControl
        animateYearScrolling={false}
        autoOk
        disableFuture={!!dob}
        format="MMM DD, YYYY"
        value={value || max}
        onChange={onChangeInternal}
        maxDate={max}
      />
    );
  }
};
class PasswordField extends Component {
  state = { show: false };
  show = () => this.setState({ show: !this.state.show });
  render() {
    const {inputClassName,rootClassName}=this.props;
    return (
      <TextField
        {...this.props}
        type={this.state.show ? "text" : "password"}
        InputProps={{
          classes: {
            root: clsx("medium",rootClassName),
            input: clsx("size_16_500 h-100",inputClassName)
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={this.show}>
                {!this.state.show ? <VisOnIcon /> : <VisOffIcon />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    );
  }
}

const SubmitButton = (props) => {
  const {loading, children, disabled, ...rest} = props
  return (<Button {...rest} type='submit' disabled={loading || disabled}>
    {loading ? <CircularProgress color='primary' size={22}/> : children}
  </Button>)
}

// see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#Validation
const MDN_EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
const isValidEmail = value => !!value && MDN_EMAIL_REGEX.test(String(value));
const VALID_DATE_REGEX = /^\d\d\d\d-\d\d-\d\d$/;
const isValidDate = value => !!value && VALID_DATE_REGEX.test(String(value));
const VALID_PHONE_REGEX = /^\d{10,12}$/;
const isValidPhone = value => !!value && VALID_PHONE_REGEX.test(String(value));
const to10DigitNumber = value => {
  if (!value) return value;
  return value.replace(/\D/g, "").replace(/.*(\d{10})/, "$1");
};
const toPhoneNumber = value => {
  const cleaned = to10DigitNumber(value);
  if (!cleaned) return "";
  const N = cleaned.length;
  if (N < 3) return cleaned;
  if (N <= 6) return cleaned.replace(/(\d{3})(\d{1,3})/, "$1-$2");
  return cleaned.replace(/(\d{3})(\d{3})(\d{1,4})/, "$1-$2-$3");
};
const moment18YrsAgo = () => {
  var out = moment();
  out.year(out.year() - 18);
  return out;
};
export {
  ClrdButton,
  PhoneField,
  DateField,
  PasswordField,
  SubmitButton,
  isValidEmail,
  isValidDate,
  isValidPhone,
  moment18YrsAgo,
  to10DigitNumber,
  toPhoneNumber
};
