import * as React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const styles=makeStyles({
    root:{
        fill:"none!important"
    }
})

export const LayersIcon = props =>{
    const classes=styles();
    return(
        <SvgIcon
         width="16" height="16" viewBox="0 0 16 16"
         classes={{
          root: clsx("small3",classes.root)
         }}
         {...props}
        >
           <g clip-path="url(#clip0_27743_138400)">
           <path d="M1.3335 11.332L8.00016 14.6654L14.6668 11.332" stroke="#A2AEBF" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
           <path d="M1.3335 8L8.00016 11.3333L14.6668 8" stroke="#A2AEBF" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
           <path d="M8.00016 1.33203L1.3335 4.66536L8.00016 7.9987L14.6668 4.66536L8.00016 1.33203Z" stroke="#A2AEBF" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
           </g>
           <defs>
           <clipPath id="clip0_27743_138400">
           <rect width="16" height="16" fill="white"/>
           </clipPath>
           </defs>
        </SvgIcon>
       );
} 