import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const MoreIcon = (props) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 20"
    classes={{
      root: 'small',
    }}
  >
    <svg
      stroke="#8F969F"
      fill="none"
      stroke-width="2"
      viewBox="0 0 24 24"
      stroke-linecap="round"
      stroke-linejoin="round"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="1"></circle>
      <circle cx="19" cy="12" r="1"></circle>
      <circle cx="5" cy="12" r="1"></circle>
    </svg>
  </SvgIcon>
);
