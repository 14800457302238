import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { minutesToHours } from 'fitbud/views/users/progress/helpers';

export const DAYS = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export const getWaterFrequencyLabel = (minutes) => {
  const [h, m] = minutesToHours(minutes);
  let str = '';
  if (!h && !m) return str;
  if (!!h) str += `${h} hr `;
  if (!!m) str += `${m} mins`;
  return str;
};

export const DEFAULT_FITNESS_CONFIG = {
  default_time: '13:45',
};

export const DEFAULT_NUTRITION_CONFIG = {
  default_time: '09:00',
};

export const DEFAULT_WATER_CONFIG = {
  default_from: '09:00',
  default_TO: '20:00',
  max_reminders: 10,
};

export const DEFAULT_MEASUREMENT_CONFIG = {
  default_time: '17:00',
};

const DEFAULT_SELECTED_TIME = '10:00';
export const DEFAULT_TIME_PICKER_STEP = '15';
export const DEFAULT_WATER_FREQUENCY = 60;

export const DEFAULT_REMINDER_TAG = {
  measurements: DEFAULT_MEASUREMENT_CONFIG,
  fitness: DEFAULT_FITNESS_CONFIG,
  water: DEFAULT_WATER_CONFIG,
  nutrition: DEFAULT_NUTRITION_CONFIG,
  time_picker_step: DEFAULT_TIME_PICKER_STEP,
}

export const getDefaultPref  = () =>{
  return {
    fitness: {
      enabled: true,
    },
    nutrition: {
      enabled: true,
    },
    measurements: {
      enabled: true,
    },
    water: {
      enabled: true,
    },
    checkins: {
      enabled: true,
    },
  };
}

export const getDefaultReminder = (reminder_tag_config) => {
  const {
    fitness = DEFAULT_FITNESS_CONFIG,
    nutrition = DEFAULT_NUTRITION_CONFIG,
    water = DEFAULT_WATER_CONFIG,
    measurements = DEFAULT_MEASUREMENT_CONFIG,
  } = reminder_tag_config || {};
  const reminders = { enabled: false };
  reminders.fitness = {
    days: DAYS.reduce((acc, current) => {
      acc[current] = fitness.default_time;
      return acc;
    }, {}),
    mode: 'time',
    sc_sync: false,
    time: fitness.default_time,
  };
  reminders.nutrition = {
    sc_sync: false,
    categories: {},
  };
  reminders.water = {
    count: water.max_reminders,
    interval: DEFAULT_WATER_FREQUENCY,
    to: water.default_to,
    from: water.default_from,
    mode: WATER_MODE.FREQUENCY,
  };
  reminders.checkins = {
    day_of_month: '7',
    day_of_week: 'friday',
    mode: 'week_1',
    time: DEFAULT_SELECTED_TIME,
  };
  reminders.measurements = {
    day_of_week: 'friday',
    day_of_month: '7',
    mode: 'week_1',
    time: measurements.default_time,
  };
  return reminders;
};

export const weekNameMap = {
  sunday: 'Sunday', //n
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
};

export const hhMMToDate = (hhMM) => {
  return new Date(`2021-01-01T${hhMM}:00`);
};

export const getDisplayTime = (hhMM) => {
  const date = hhMMToDate(hhMM);
  const m = moment(date);
  return {
    time: m.format('h:mm'),
    m: m.format('a'),
  };
};

export const WATER_MODE = {
  FREQUENCY: 'frequency',
  COUNT: 'count',
};

export const WATER_FREQUENCY = [
  {
    label: 'Count',
    value: WATER_MODE.COUNT,
  },
  {
    label: 'Interval',
    value: WATER_MODE.FREQUENCY,
  },
];

export const getIntervalLabelObj = (interval) => {
  const totalMin = Math.round(interval / 60);
  const hr = Math.floor(totalMin / 60);
  const min = Math.floor(totalMin % 60);
  return {
    hr,
    min,
  };
};

export const getIntervalLabel = (interval) => {
  const totalMin = Math.round(interval / 60);
  const hr = Math.floor(totalMin / 60);
  const min = Math.floor(totalMin % 60);
  if (!hr) return `${min} mins`;
  if (!!hr) {
    let out = `${hr} hr`;
    if (!!min) out += ` ${min} mins`;
    return out;
  }
};

export const getHrMin = (interval) => {
  const totalMin = Math.round(interval / 60);
  const hr = Math.floor(totalMin / 60);
  const min = Math.floor(totalMin % 60);
  return { hr, min };
};

//deprecated..
export const CHECK_IN_OPTIONS = [
  { label: 'Every Week', value: 'week' },
  { label: 'Once in 2 Week', value: '2_week' },
  { label: 'Once in 4 Week', value: '4_week' },
  { label: 'Once in 6 Week', value: '6_week' },
  { label: 'Every Month', value: 'month' },
];

export const getUnitDay = (type) =>{
  if(type === "day") return 1;
  else if (type === "week") return 7;
  else if (type === 'month') return 30;
  else return 1; //for safe case... 
}

export const getModeDay = (value, CHECK_IN_OPTIONS)=>{
  if(!CHECK_IN_OPTIONS || !CHECK_IN_OPTIONS.length) return 0;
  const option = _.find(CHECK_IN_OPTIONS, (opt)=>opt.id === value) || {};
  const {type, unit} = option || {};
  if(!type || !unit) return 0;
  return Number(unit) * getUnitDay(type)
}

export const WEEK_OPTIONS = DAYS.map((d) => ({ label: d, value: d }));

export const getOnlyOrdinalSuffix = (i) => {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return 'st';
  }
  if (j == 2 && k != 12) {
    return 'nd';
  }
  if (j == 3 && k != 13) {
    return 'rd';
  }
  return 'th';
};

export const ordinalSuffix = (i) => {
  if (i === 31) return 'Last Day';
  const suffix = getOnlyOrdinalSuffix(i);
  return i + `${suffix}`;
};

export const DAYS_OPTIONS = Array(31)
  .fill(null)
  .map((_, i) => {
    const v = i + 1;
    return { label: ordinalSuffix(v), value: String(v) };
  });

//deprecated..  
export const getCheckInModeLabel = (mode) => {
  if (!mode) return '';
  if (mode === 'everyday') return 'Every Day';
  if (mode === 'month') return 'Every Month';
  if (mode === 'week') return 'Every Week';
  if (mode === '2_week') return 'Once in 2 Week';
  if (mode === '4_week') return 'Once in 4 Week';
  if (mode === '6_week') return 'Once in 6 Week';
  return '';
};

export const renderTime = (time) => {
  if (!time) return null;
  const dt = getDisplayTime(time);
  return (
    <>
      <span className="font_15_700">{dt.time}</span>&nbsp;
      <span className="font_15_500">{dt.m}</span>
    </>
  );
};

export const MEASUREMENTS_MODE_OPTIONS = [
  { label: 'Every Day', value: 'everyday' },
  { label: 'Every Week', value: 'week' },
  { label: 'Once in 2 Week', value: '2_week' },
];


export const getCheckInReminderAndConfig = (config, options) =>{
  //extract all reminder checkIn config and rest are checkInConfig
  const { check_in_tag_config } = options || {}
  const {recurrence : CHECK_IN_RECURRENCE } = check_in_tag_config || {}
  const { day_of_month, day_of_week, mode, time, any_day, automatic, enabled,  ...rest } = config || {};
  const checkInReminder = {
    day_of_month,
    day_of_week,
    mode,
    time,
    any_day : !!any_day,
    automatic : !!automatic,
    enabled : !!enabled,
  };
  const checkInConfig = {...rest};
  if(automatic){
    checkInConfig.enable_specific_day_checkins = false;
    checkInConfig.specific_day_checkins = [];
    checkInConfig.day_of_month = null;
  }else if (!!day_of_week) {
    checkInConfig.enable_specific_day_checkins = true;
    checkInConfig.specific_day_checkins = [day_of_week];
  }else if(!!day_of_month) 
  checkInConfig.day_of_month = Number(day_of_month); //@TODO need to verify this.
  checkInConfig.checkin_frequency = getModeDay(mode, CHECK_IN_RECURRENCE);
  checkInConfig.any_day = !!any_day;
  return {checkInReminder, checkInConfig};
}