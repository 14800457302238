import React from "react";
import { withSnackbar } from "notistack";
import LiveVideo from "./liveVideo";
import LiveVideo100 from "./liveVideo100";
import VideoProvider from "./videoProvider";
import { useLiveSessions } from "fitbud/providers/liveSessionsProvider";
import _ from "lodash";

const LiveSession = (props) => {
  const { openVideoScreen: book_id, bookings } = useLiveSessions();
  const _booking = _.find(bookings, ['book_id', book_id]);
  if (!book_id) return null;
  return (
    <VideoProvider>
      {
        _booking?.provider === '100ms' ?
          <LiveVideo100 /> :
          <LiveVideo />
      }
    </VideoProvider>
  );
};

export default withSnackbar(LiveSession);
