import React from 'react';
import {
  makeStyles, Stepper, Step, StepLabel, StepIcon
} from '@material-ui/core';
import clsx from 'clsx';

const Steps = (props) => {
  const {setStep, stepLabel,  ...rest} = props;
  const classes = useStyles();
  const onStepClick = (e) => {
    if (!setStep) return;
    if (!e || !e.currentTarget || !e.currentTarget.dataset ||
      typeof e.currentTarget.dataset.step === 'undefined')
      return;
    const step = Number(e.currentTarget.dataset.step);
    if (step >= props.activeStep) return;
    setStep(step);
  };
  return (
    <div className='w-25 flex-shrink-0 bg-grey-new'>
      <Stepper {...rest} classes={{root: `bg-grey-new ${classes.root}`}} orientation='vertical'>
        {stepLabel.map((label, index) => (
          <Step key={label} className={clsx(classes.step)} data-step={index} onClick={onStepClick}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiStepConnector-vertical': {
      marginLeft: 11,
      padding: 0,
      '& .MuiStepConnector-lineVertical': {
        minHeight: 35,
        borderLeftWidth: 2,
        color: "#D3D9DB !important",
        // "& .MuiStepConnector-line":{
        //   color: "#D3D9DB"
        // }
      },
     
    },
  },
  step:{
    // '& .MuiSvgIcon-root':{
    //   color:"#D3D9DB"
    // },
    // '& .MuiStepIcon-active':{
    //   color:"#4089f7 !important"
    // },
  }
}));


export default Steps;