import React, {useContext} from "react";
import { withRouter } from 'react-router-dom';
import {
  makeStyles,
  AppBar,
  Toolbar,
  IconButton, Icon,
} from "@material-ui/core";
import { NavContext } from 'fitbud/providers/appNav';
import CloseIcon from "@material-ui/icons/CloseRounded";
import Typography from "@material-ui/core/Typography";
import { ClrdButton } from "fitbud/components/form-fields";

const NavIcon = withRouter(props => {
  const {navIcon, toggleDrawer} = useContext(NavContext);
  const {history} = props;
  if (navIcon.none) return null;
  return (
    <IconButton edge='start' className={props.className || ''} onClick={navIcon.hamburger ? toggleDrawer : history.goBack}>
      <Icon className={`${navIcon.faicon} fa-fw`} />
    </IconButton>
  );
});

const AppBarPrimary = props => {
  const {drawerState} = useContext(NavContext);
  return (
    <AppBar position="fixed" style={{
      marginLeft: drawerState.drawerSpace,
      width: `calc(100% - ${drawerState.drawerSpace}px)`}}>
      <Toolbar className="px-0">
        <NavIcon className='text-white'/>
        {props.children}
        <div className="flex-grow-1" />
      </Toolbar>
    </AppBar>
  );
};

const ToolbarSpace = props => {
  return <Toolbar style={{ opacity: 0 }} />;
};

const useDialogAppBarStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#f5f5f5"
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#1b1b1b",
    minHeight: "60px!important"
  }
}));

export const DialogAppBar = ({
  position = "sticky",
  title,
  onClose,
  onSave,
  headerActions
}) => {
  const classes = useDialogAppBarStyles();

  return (
    <AppBar position={position} className={classes.root}>
      <Toolbar className={`${classes.toolbar} fpx-20`}>
        <IconButton edge="start" onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h3">{title}</Typography>
        {headerActions ? (
          headerActions()
        ) : (
          <ClrdButton
            style={{ visibility: onSave === undefined ? "collapse" : "" }}
            variant="contained"
            color="primary"
            onClick={onSave}
          >
            Save
          </ClrdButton>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default AppBarPrimary;
export { NavIcon, ToolbarSpace };
