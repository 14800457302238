import React, { useState, useContext } from "react";
import {
  DialogContent,
  makeStyles
} from "@material-ui/core";
import Dialog from "fitbud/components/Dialog";
import MultiLineInput from "./multilineInput";
import { CustomConfirmation } from "./customConfirmation";
import {FirebaseAuthContext} from "fitbud/providers/firebase-auth";
import { PUBLISHED,DRAFT } from "fitbud/utils/constants";

// const useStyles = makeStyles(() => ({
//   root: {
//     border: "1px solid #D8D8D8",
//     marginBottom: "20px",
//     borderRadius: "5px"
//   },
//   droppableContainer:{
//     padding:"20px !important",
//     maxHeight:"calc(100vh - 64px)", //default max height of mui Dialog
//     overflowY:"auto"
//   }
// }));
const ReorderDialog = props => {
  // const classes = useStyles();
  const {comp}=useContext(FirebaseAuthContext);
  const draftFeature= !!comp.data().features.draft;
  const {
    onClose,
    listData = [],
    title,
    hideAddBtn = true,
    hideDeleteBtn = true,
    readOnly = true,
    paperClass = "width-600 height-60",
    closeAfterSubmit=true,
    showConfirmation=false,
    isDraftAvailable=false,
    ...rest
  } = props;
  const defaultOptions=(Array.isArray(listData) && listData.length)?[...listData]:[""];
  const [list, setLists] = useState(defaultOptions);
  const [dirty,setDirty]=useState(false);
  const [isConfirmationOpen,toggleConfirmation]=useState(false);
  const onChange=(result)=>{
    setDirty(true);
    setLists(result);
  }

  const onSubmit = () => {
    if(!dirty && !isDraftAvailable){
      onClose();
      return;
    }
    if(dirty && draftFeature){
      toggleConfirmation(true);
    }
    else{
      props.onSubmit(list);
      setLists(listData);
      if(closeAfterSubmit) onClose();
    }
    
  };
  
  const handleClose=()=>{
    if(dirty && showConfirmation){
      toggleConfirmation(true);
    }
    else{
      onClose();
    }
  }
  
  return (
    <Dialog
      toolbarClass="height-60"
      buttonColor="primary"
      buttonSize="f-medium"
      open={props.open}
      onClose={handleClose}
      onSave={onSubmit}
      title={title}
      titleFont="h3"
      paperClass={paperClass}
      disablePrimaryAction={!listData.length}
    >
      {/* <DialogContent className="p-0 h-100">
        <div className={classes.droppableContainer}>
          <MultiLineInput onChange={onChange}
          options={[...list]}
          hideAddBtn={hideAddBtn}
          hideDeleteBtn={hideDeleteBtn}
          readOnly={readOnly}
          {...rest}
          />
        </div>
      </DialogContent> */}
      <DialogContent className={`p-20 h-100 ${hideDeleteBtn ? 'pr-30' : ''}`}>
        <MultiLineInput onChange={onChange}
        options={[...list]}
        hideAddBtn={hideAddBtn}
        hideDeleteBtn={hideDeleteBtn}
        readOnly={readOnly}
        {...rest}
        />
      </DialogContent>
      {isConfirmationOpen && 
        <CustomConfirmation
          handleClose={()=>toggleConfirmation(false)}
          dirty={dirty}
          showDiscard={dirty}
          handleDiscard={onClose}
          draftFeature={draftFeature}
          handleDraft={()=>{
            props.onSubmit(list,DRAFT);
          }}
          handlePublish={()=>{
            props.onSubmit(list,PUBLISHED)
          }}
        />}
    </Dialog>
  );
};

export default ReorderDialog;
