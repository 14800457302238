import { makeStyles, Slider } from '@material-ui/core';
import React from 'react';

const styles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
  },
  thumb: {
    height: '28px',
    width: '28px',
    backgroundColor: ({isValue }) => !isValue ? '#fff' : theme.palette.primary.main,
    border: '1px solid #D3D9DB',
    marginTop: '-11px',
    marginLeft: '-14px',
    boxShadow: '0px 4px 6px 0px rgba(55, 65, 81, 0.05)',
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  track: {
    height: '6px',
    borderRadius: 4,
  },
  rail: {
    width: '101%',
    height: '6px',
    borderRadius: 4,
  },
  mark:{
    height: '6px',
    width: '6px',
    borderRadius: '100%',
    backgroundColor: '#000',
    opacity: 0.3,
    marginTop:'0px',
    marginLeft:'0px',
  },
  markActive:{
    backgroundColor: '#fff',
    opacity: 1
  },
  active:{}
}));
const CustomSlider = ({ classesProp, ...props}) => {
  const classes = styles({ isValue: !!props.value });
  return (
    <Slider classes={{...classes, ...classesProp }} {...props} />
  );
}
export default CustomSlider;