import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const ActiveClientsIcon = props => (
    <SvgIcon
        {...props}
        viewBox="0 -1 12 14"
        classes={{
            root: "small"
        }}
    >
        <g fill="#8F969F" fillRule="nonzero">
            <path d="M6 6C7.6575 6 9 4.6575 9 3C9 1.3425 7.6575 0 6 0C4.3425 0 3 1.3425 3 3C3 4.6575 4.3425 6 6 6ZM6 7.5C3.9975 7.5 0 8.505 0 10.5V12H12V10.5C12 8.505 8.0025 7.5 6 7.5Z" fill="#8F969F" />
        </g>
    </SvgIcon>
);
