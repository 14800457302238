import React, { useContext } from 'react';
import { Typography, Divider, List, IconButton } from '@material-ui/core';
import { SelectorContext } from 'fitbud/components/catalogSelector/selectorContext';
import {DumbList} from "fitbud/components/catalog";
import CloseIcon from '@material-ui/icons/ClearRounded';
import _ from 'lodash';



const SelectedList = (props) => {
  const { endAdornment, isDivider, noSelectedPlaceholder, onClick, hidePreSelected, ...rest } = props;
  const { selectedValues, removeSelectedValue,primary={} } = useContext(SelectorContext);
  const selectedCount = (selectedValues || []).length;
  const currentSelectedCount=selectedCount - (hidePreSelected || 0);
  return (
    <div className="h-100 w-100">
      <div className=" h-100 d-flex flex-column">
        {!_.isEmpty(primary) && <>
          <DumbList docs={[primary]}/>
          <Divider/>  
          </>
        }
        <div className="d-flex fpx-20 fpt-20 align-items-center justify-content-between">
          {!!currentSelectedCount && <Typography>
            <span className={"font_15_700"}>{currentSelectedCount} &nbsp;</span> 
            <span className='font_15_500'>Selected</span>
          </Typography>}
          <div className="">
            {endAdornment && endAdornment()}
          </div>  
        </div>
        <div className="overflow-auto flex-grow-1">
          {!selectedCount && noSelectedPlaceholder &&  noSelectedPlaceholder() }
          {currentSelectedCount > 0 && (
              <List disablePadding>
                <DumbList
                  idPrefix="selected_list_"
                  docs={selectedValues.slice(hidePreSelected || 0)}
                  ListItemSecondaryAction={({index, doc})=>{
                    const {hideRemoveIcon=false,disabled=false} = doc||{};
                    if(!!hideRemoveIcon) return null;
                    return (
                      <IconButton disabled={disabled} onClick={(e)=>{e.stopPropagation();removeSelectedValue(index)}}>
                        <CloseIcon />
                      </IconButton>
                    )
                  }}
                  onClick={onClick}
                  {...rest}
                />
            </List>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectedList;
