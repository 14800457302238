import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  FormGroup,
  Typography,
  Checkbox
} from "@material-ui/core";

export const CheckboxGrps = props => {
  return (
    <FormControl component="fieldset">
      <FormLabel focused={false} className="fm-0">
        <Typography variant="caption" component="div">
          {props.label}
        </Typography>
      </FormLabel>
      <FormGroup className="d-flex justify-content-between" row={!props.column}>
        {props.fields.map((f, i) => {
          return (<>
            <FormControlLabel
              key={`_check${i}`}
              className="fmb-0 fmr-30 fpr-20"
              control={
                <Checkbox
                  name={f.id || f.label || f.value}
                  color="primary"
                  checked={!!f.checked}
                  onChange={props.handleChange}
                  value={f.value}
                  disabled={f.disabled}
                />
              }
              label={<Typography className={`font_14_600 ${props.labelClass || ''}`}>{f.label}</Typography>}
            />
            {props.helperText && <FormHelperText className="fml-30 font_12_500" style={{ marginTop: -5, paddingLeft: 2 }}>{f.helperText}</FormHelperText>}
            </>
          );
        })}
      </FormGroup>
    </FormControl>
  );
};
