import React,{useContext} from 'react';
import {DraftTag} from "../draftTag";
import {get} from 'lodash';
import { VIEWS_CONSTANTS,PUBLISHED } from 'fitbud/utils/constants';
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import { useStyles } from 'fitbud/views/exploreV2/listItems/styles';
import clsx from 'clsx';

export const SecondaryAction=({keyName,index,doc})=>{
    const {comp}=useContext(FirebaseAuthContext);
    const badgeClass=useStyles();
    const draftFeature=comp && comp.data() && get(comp.data(),'features.draft',false);
    switch(keyName){
        case VIEWS_CONSTANTS.EXERCISE:
        case VIEWS_CONSTANTS.CARDIO:
        case VIEWS_CONSTANTS.WORKOUT:
        case VIEWS_CONSTANTS.SCHEDULE:
        case VIEWS_CONSTANTS.FOOD:
        case VIEWS_CONSTANTS.RECIPE:
        case VIEWS_CONSTANTS.SUPPLEMENT:
        case VIEWS_CONSTANTS.MEAL_SCHEDULE:
        case VIEWS_CONSTANTS.MEAL:
        case VIEWS_CONSTANTS.SUPPLEMENT:
        case VIEWS_CONSTANTS.SUPPLEMENTS:{
            const publish_status=get(doc,'data.publish_status',PUBLISHED);
            if(publish_status.includes('draft') && draftFeature){
                return (<DraftTag/>)
            }
            return null;
        }
        // case VIEWS_CONSTANTS.CHALLENGES:{
        //     if(!!get(doc,'data.active',false))
        //         return(
        //             <span className={clsx('ml-10', badgeClass.activeBadge )}>&#9679;</span>
        //         )
        //     return null;
        // }
        default:return null;
    }
}