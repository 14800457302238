import React, { useCallback } from 'react';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import useVideoContext from './useVideoContext';
import UseParticipants from './useParticipants';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    border: "1px solid #E0E0E0",
    width: "50px",
    height: "50px"
  },
  iconButton: {
    backgroundColor: "#4089F7!important",
    border: "none"
  }
}));

const ToggleViewCarouselButton = () => {
  const classes = useStyles();
  const participants = UseParticipants();
  const { getToggleView, isViewEnabled } = useVideoContext();

  const toggleView = useCallback(() => {
    getToggleView();
  }, [getToggleView]);

  const disabled = participants.length === 0;

  return (
    <Tooltip
      title={isViewEnabled ? 'Turn off view mode' : 'Turn on view mode'}
      placement="top"
      PopperProps={{ disablePortal: true }}
    >
      <IconButton
        className={`${classes.buttonContainer} ${!!isViewEnabled ? classes.iconButton : ""} fmr-20`}
        style={{ color: !!isViewEnabled ? "#fff" : disabled ? "rgba(0,0,0,.26)" : "#221F1F" }}
        onClick={toggleView}
        disabled={disabled ? true : false}
      >
        <ViewCarouselIcon />
      </IconButton>
    </Tooltip>
  )
}

export default ToggleViewCarouselButton;