import React, { useContext } from "react";
import { Drawer } from "@material-ui/core";
import { DrawerHeaderInScheduleEditor } from "fitbud/views/users/subComponents";
import WoDetails from "fitbud/views/workouts/form";
import CardioDetails from "fitbud/views/cardio/info";
import MealInfo from "fitbud/views/meals/mealInfo";
// import BMRMealInfo from "fitbud/views/users/bmrMealInfo";
import SupplementInfo from "fitbud/views/supplements/info";
import { UserSchContext } from '../provider';



export const CardEditor = () => {
    const { showCardDetails, controller, objCache, opcopy } = useContext(UserSchContext);
    if (!showCardDetails || !showCardDetails.data) return null;
    const { editable, data, id, weekDayMeta, ...rest } = showCardDetails || {};
    let { type, dtype } = data || {};
    type = dtype || type;
    const { hideDetail, showDetail, uid } = controller;
    const saveEdit = (updateData, newDocId, oldId) => {
        if(!updateData.id) updateData['id']=newDocId;
        showDetail({ ...showCardDetails, data: updateData, id: newDocId});
        const { weekId, day, week } = weekDayMeta
        controller.handleEdit(weekId, week, day, type, newDocId, updateData, rest, oldId);
    }
    
    return <Drawer open anchor="right" onClose={hideDetail}>
        <DrawerHeaderInScheduleEditor
            closeDrawer={hideDetail}
            title={type}
        />
        {type === "workout" && (
            <WoDetails
                id={id}
                scheduleSaveWoEdit={saveEdit}
                scheduleDoc={!dtype ? data: null}
                isPreview={opcopy ? true : !editable}
                disableActions
                minified
                objCache={objCache}
                createNew
                userProfileId={uid}
            />
        )}
        {type === "cardio" && (
            <CardioDetails
                id={id}
                scheduleSaveCardioEdit={saveEdit}
                scheduleDoc={!dtype ? data: null}
                edit={editable}
                disableActions
                objCache={objCache}
                createNew
                userProfileId={uid}
                isPreview={opcopy ? true : !editable}
            />
        )}
        {/* {isBMR && <BMRMealInfo fbUser={{}} data={data} /> } */}
        {(type === "food" || type === "macros") &&  (
            <MealInfo
                id={id}
                scheduleDoc={!dtype ? data: null}
                scheduleSaveMealEdit={saveEdit}
                edit={editable}
                disableActions
                isPreview={opcopy ? true : !editable}
                minified={true}
                objCache={objCache}
                createNew
                userProfileId={uid}
            />
        )}
        {type === "supplement" && (
            <SupplementInfo
                id={id}
                scheduleDoc={!dtype ? data: null}
                scheduleSaveSuppEdit={saveEdit}
                edit={editable}
                disableActions
                objCache={objCache}
                createNew
                userProfileId={uid}
                isPreview={opcopy ? true : !editable}
                displayEditOption={editable}
            />
        )}
    </Drawer>
}