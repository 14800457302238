import React, { useMemo } from 'react';
import { Grid, Typography, Avatar, makeStyles } from '@material-ui/core';
import { getEmailFirstLetter } from 'fitbud/utils/helpers';
import { AvatarImage } from 'fitbud/views/users/header';
import peopleSvg from 'fitbud/images/group_appointment.svg';
import useTrainers from 'fitbud/hooks/useTrainers';
import _ from 'lodash';
import { useServices } from 'fitbud/providers/services-provider';
import { OFFLINE_MODE_TEXT, ONLINE_MODE_TEXT } from 'fitbud/views/groupClasses/helper';

const useStyles = makeStyles(() => ({
  groupAvatar: {
    backgroundColor: '#F0F3F5',
    borderRadius: '50%',
    '& .MuiAvatar-img': {
      height: 24,
      width: 24,
    },
  },
}));

export const BookingDetails = (props) => {
  const { user, slot, duration, provider, title, trainer_id, startDate, service } = props.session;
  const { className } = props;
  const { getServiceById } = useServices();
  const classes = useStyles();
  const { data: trainers } = useTrainers();
  const trainerDetail = useMemo(() => {
    if (trainer_id) return _.find(trainers, (data) => data._id === trainer_id) || {};
    return {};
  }, [trainer_id, trainers]);
  const isGroupClass = !!props.session.class;
  const _title = useMemo(() => {
    if (isGroupClass) return title; // instance title,
    //if appointments then user name.
    return title ||  user?.name || '';
  }, [isGroupClass, title, user]);

  const _sub_title = useMemo(() => {
    if (isGroupClass) {
      //return trainer name...
      return _.get(trainerDetail, 'data.name');
    }
    //if service appointment then session name or nothing...
    if (service) {
      const service_detail = getServiceById(service);
      const _name = _.get(service_detail, 'data.title', '');
      const _mode = _.get(service_detail, "data.mode");
      const str =  `${_name} (${_mode === "offline"? OFFLINE_MODE_TEXT : ONLINE_MODE_TEXT})`
      return str
    }
    return '';
  }, [isGroupClass, trainerDetail, service]);

  return (
    <Grid container item zeroMinWidth wrap="nowrap" className={className} style={{ minWidth: 300 }}>
      <Grid item style={{ marginRight: 16 }}>
        {isGroupClass ? (
          <Avatar className={classes.groupAvatar} src={peopleSvg} />
        ) : (
          <AvatarImage
            src={user && user.image}
            className="border"
            name={(user && user.name) || getEmailFirstLetter(user && user.email)}
            base64={true}
            alt={(user && user.name) || 'USER_IMAGE'}
            size="size40"
          />
        )}
      </Grid>
      <Grid item className="w-100">
        <div className="d-flex align-items-center">
          <div className="flex-grow-1">
            <Typography variant="h6" noWrap style={{ maxWidth: '200px' }}>
              {_title}
            </Typography>
            <Typography className="font_13_500 text-grey mb-6 text-wrap">
              {_sub_title}
            </Typography>
            <React.Fragment>
              <Typography color="textSecondary" className={`font_13_500 mt-10`} noWrap>
                {duration} mins • {slot && slot[0]} - {slot && slot[1]}
              </Typography>
            </React.Fragment>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
