import React from 'react';

export const ResourcePlaceholder = () => {
  return (
    <svg width="126" height="109" viewBox="0 0 126 109" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M66.487 100C94.3837 100 117 77.6575 117 50C117 22.3425 94.3837 0 66.487 0C38.5904 0 15.9741 22.3425 15.9741 50C15.9741 77.6575 38.5904 100 66.487 100Z"
        fill="#EAEEF9"
      />
      <g filter="url(#filter0_d_17197_80675)">
        <path
          d="M113.313 30.0186H26.9334C25.0404 30.0186 23.5459 28.5423 23.5459 26.6722V7.47927C23.5459 5.60919 25.0404 4.13281 26.9334 4.13281H113.214C115.107 4.13281 116.601 5.60919 116.601 7.47927V26.6722C116.701 28.5423 115.107 30.0186 113.313 30.0186Z"
          fill="white"
        />
      </g>
      <path
        d="M44.4686 17.0264C44.4686 19.1918 43.4723 21.1603 41.9778 22.5382C40.6826 23.7193 38.8892 24.4083 36.9963 24.4083C35.1033 24.4083 33.3099 23.7193 32.0147 22.5382C30.4206 21.1603 29.5239 19.1918 29.5239 17.0264C29.5239 12.991 32.9114 9.64453 36.9963 9.64453C41.0811 9.64453 44.4686 12.991 44.4686 17.0264Z"
        fill="#DCE1EE"
      />
      <path
        d="M68.9775 14.8616H49.8483C49.3502 14.8616 49.0513 14.4679 49.0513 14.0742V11.6136C49.0513 11.1214 49.4498 10.8262 49.8483 10.8262H68.9775C69.4756 10.8262 69.7745 11.2199 69.7745 11.6136V14.0742C69.7745 14.5663 69.4756 14.8616 68.9775 14.8616Z"
        fill="#CED6E2"
      />
      <path
        d="M91.6933 23.2268H49.8483C49.3502 23.2268 49.0513 22.8331 49.0513 22.4394V19.9788C49.0513 19.4867 49.4498 19.1914 49.8483 19.1914H91.6933C92.1915 19.1914 92.4904 19.5851 92.4904 19.9788V22.4394C92.4904 22.9316 92.0919 23.2268 91.6933 23.2268Z"
        fill="#CED6E2"
      />
      <g filter="url(#filter1_d_17197_80675)">
        <path
          d="M113.313 94.587H26.9334C25.0404 94.587 23.5459 93.1106 23.5459 91.2405V72.0476C23.5459 70.1775 25.0404 68.7012 26.9334 68.7012H113.214C115.107 68.7012 116.601 70.1775 116.601 72.0476V91.2405C116.701 93.1106 115.107 94.587 113.313 94.587Z"
          fill="white"
        />
      </g>
      <path
        d="M44.4686 81.5948C44.4686 83.7601 43.4723 85.7286 41.9778 87.1066C40.6826 88.2877 38.8892 88.9767 36.9963 88.9767C35.1033 88.9767 33.3099 88.2877 32.0147 87.1066C30.4206 85.7286 29.5239 83.7601 29.5239 81.5948C29.5239 77.5593 32.9114 74.2129 36.9963 74.2129C41.0811 74.2129 44.4686 77.5593 44.4686 81.5948Z"
        fill="#DCE1EE"
      />
      <path
        d="M68.9775 79.5276H49.8483C49.3502 79.5276 49.0513 79.1339 49.0513 78.7402V76.2796C49.0513 75.7875 49.4498 75.4922 49.8483 75.4922H68.9775C69.4756 75.4922 69.7745 75.8859 69.7745 76.2796V78.7402C69.7745 79.1339 69.4756 79.5276 68.9775 79.5276Z"
        fill="#CED6E2"
      />
      <path
        d="M91.6933 87.8929H49.8483C49.3502 87.8929 49.0513 87.4992 49.0513 87.1055V84.6448C49.0513 84.1527 49.4498 83.8574 49.8483 83.8574H91.6933C92.1915 83.8574 92.4904 84.2511 92.4904 84.6448V87.1055C92.4904 87.4992 92.0919 87.8929 91.6933 87.8929Z"
        fill="#CED6E2"
      />
      <g filter="url(#filter2_d_17197_80675)">
        <path
          d="M98.6679 62.3018H12.3875C10.4945 62.3018 9 60.8255 9 58.9554V39.7625C9 37.8924 10.4945 36.416 12.3875 36.416H98.6679C100.561 36.416 102.055 37.8924 102.055 39.7625V58.9554C102.155 60.8255 100.561 62.3018 98.6679 62.3018Z"
          fill="white"
        />
      </g>
      <path
        d="M54.4316 47.1448H35.3024C34.8043 47.1448 34.5054 46.7511 34.5054 46.3574V43.8968C34.5054 43.4046 34.9039 43.1094 35.3024 43.1094H54.4316C54.9297 43.1094 55.2286 43.5031 55.2286 43.8968V46.3574C55.2286 46.8495 54.9297 47.1448 54.4316 47.1448Z"
        fill="#CED6E2"
      />
      <path
        d="M77.1474 55.6097H35.3024C34.8043 55.6097 34.5054 55.216 34.5054 54.8222V52.3616C34.5054 51.8695 34.9039 51.5742 35.3024 51.5742H77.0478C77.546 51.5742 77.8449 51.9679 77.8449 52.3616V54.8222C77.9445 55.216 77.546 55.6097 77.1474 55.6097Z"
        fill="#CED6E2"
      />
      <path
        d="M30.0223 49.3077C30.0223 51.3746 29.1256 53.2447 27.7308 54.6226C27.6312 54.7211 27.6312 54.7211 27.5315 54.8195C26.2363 56.0006 24.443 56.6896 22.55 56.6896C20.657 56.6896 18.9633 56.0006 17.6681 54.9179C17.6681 54.9179 17.6681 54.9179 17.5684 54.9179C15.9743 53.54 14.978 51.5715 14.978 49.3077C14.978 45.2722 18.3655 41.9258 22.4504 41.9258C26.6349 41.9258 30.0223 45.2722 30.0223 49.3077Z"
        fill="#DCE1EE"
      />
      <path
        d="M20.4575 50.7832C20.5572 50.8816 20.6568 50.9801 20.7564 51.0785C20.6568 50.9801 20.5572 50.8816 20.4575 50.7832Z"
        fill="#3973F4"
      />
      <defs>
        <filter
          id="filter0_d_17197_80675"
          x="14.5459"
          y="0.132812"
          width="111.06"
          height="43.8857"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5" />
          <feGaussianBlur stdDeviation="4.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.17 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_17197_80675" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_17197_80675" result="shape" />
        </filter>
        <filter
          id="filter1_d_17197_80675"
          x="14.5459"
          y="64.7012"
          width="111.06"
          height="43.8857"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5" />
          <feGaussianBlur stdDeviation="4.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.17 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_17197_80675" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_17197_80675" result="shape" />
        </filter>
        <filter
          id="filter2_d_17197_80675"
          x="0"
          y="32.416"
          width="111.06"
          height="43.8857"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5" />
          <feGaussianBlur stdDeviation="4.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.17 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_17197_80675" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_17197_80675" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

