import React, { useEffect, useContext} from "react";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import {withRouter} from "react-router";
import { Feedback } from '@ivoviz/feedback.js';
import {
  removeLocationHash,
  getLocationHash,
  doesHashStartsWith
} from "fitbud/utils/RouterHash";

const openFeedback = async (user, props)=> {
  const userToken = await user.getIdToken();
  const feedback = new Feedback({
    footnote: "Your feedback, additional info, and email will be sent to feedback@fitbudd.com.",
    endpoint : `https://bff.fitbudd.com/rpc/sendFeedback?fb-project=${process.env.REACT_APP_FB_PROJECT}&fb-token=${userToken}`
  },{
    allowTaint: "true"
  });
  feedback.open();
  const cancel = document.querySelector("#feedback-js > div.fb-form-container > form > div.fb-actions > div:nth-child(2) > button");
    const close = document.querySelector("#feedback-js > div.fb-form-container > div > div > div:nth-child(2) > button");
    console.log(cancel, close)
    if(cancel){
      cancel.addEventListener("click", removeLocationHash(props));
    }
    if(close){
      close.addEventListener("click", removeLocationHash(props));
    }
}
const FeedbackModal = (props)=> {
  const { authUser } = useContext(FirebaseAuthContext);
  const hash = props && getLocationHash(props);
  const shouldFeedbackOpen = doesHashStartsWith(props, "feedback");
  
  useEffect(() => {
    if(hash && shouldFeedbackOpen) {
      openFeedback(authUser, props);
    }  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash, shouldFeedbackOpen, authUser])

  return <div></div>;
}

export default withRouter(FeedbackModal);
