import React, { useContext, useMemo } from "react";
import _ from "lodash";
import { withSnackbar } from "notistack";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";

export const RoleContext = React.createContext(undefined);

const RoleProvider = (props) => {
  const { cid, comp, acl, isAdmin } = useContext(FirebaseAuthContext);
  const {
    isOwner = false, isTrainer = false,
    tEnabled = false, isTrainerRole = false,
    allClientAccess = false,
    catalogAdmin = false,
    clientAdmin = false,
    gcEnabled = false
  } = useMemo(() => {
    if (!comp || !comp.exists) return {};
    const out = {tEnabled: _.get(comp.data(), 'features.trainers', false), gcEnabled : _.get(comp.data(), 'features.group_class.enabled', false) };
    if (isAdmin) {
      out.isOwner = true;
      out.allClientAccess = true;
      out.clientAdmin = true;
      out.catalogAdmin = true;
    } else {
      if (!acl || !acl[cid] || !acl[cid].length) return out;
      const roles = acl[cid];
      roles.forEach(role => {
        if (role === 'owner') {
          out.isOwner = true;
          out.allClientAccess = true;
          out.clientAdmin = true;
          out.catalogAdmin = true;
        }
        if (role === 'trainer') out.isTrainer = true;
        if (role === 'support') out.allClientAccess = true;
        if (role === 'catalogAdmin') out.catalogAdmin = true;
        if (role === 'clientAdmin') {
          out.clientAdmin = true;
          out.allClientAccess = true;
        }
      });
    }
    if (out.isTrainer && !out.isOwner) out.isTrainerRole = true; // XXX this means that user is trainer ONLY
    return out;
  }, [isAdmin, cid, comp, acl]);
  
  return (
    <RoleContext.Provider value={{
      isTrainerRole, isOwner, isTrainer, tEnabled,
      allClientAccess, clientAdmin, catalogAdmin,
      gcEnabled,
    }}>
      {props.children}
    </RoleContext.Provider>
  );
}

export default withSnackbar(RoleProvider);
