import React, { useEffect } from "react";
import _ from "lodash";
import Participant from "./participant";
import useVideoContext from "./useVideoContext";
import VideoTrack from "./videoTrack";
import { makeStyles } from "@material-ui/core/styles";
import AvatarContainer from "./avatarContainer";
import UseParticipants from "./useParticipants";
import UseMainParticipant from "./useMainParticipant";
import useSelectedParticipant from './useSelectedParticipant';

const useStyles = makeStyles(() => ({
  root: {
    zIndex: "5",
    gridArea: (props) => (props.isViewEnabled ? "auto" : "1 / 2 / 1 / 3"),
    overflowY: "hidden",
    position: "relative",
    padding: (props) => (props.isViewEnabled ? "" : "20px"),
    display: (props) => (props.isViewEnabled ? "flex" : "block"),
    alignItems: "center",
    justifyContent: "center",
    background: (props) => (props.isViewEnabled ? "#202124" : "")
  },
  container: {
    height: "0",
    display: "flex",
    overflow: "hidden",
    position: "relative",
    background: "#202124",
    alignItems: "center",
    paddingTop: "calc(56.25% - 2px)",
    borderRadius: "10px",
  },
  innerContainer: {
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    position: (props) =>
      props.isViewEnabled ? (props.isViewEnabled && props.localTracks ? "absolute" : "relative") : "absolute",
  },
  widthHeight: {
    width: "100%",
    height: "100%",
  },
  infoContainer: {
    position: "absolute",
    zIndex: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    width: "100%",
    background: "transparent",
    top: 0,
  },
  avatarContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    color: "#fff",
    padding: "30px"
  },
}));

const ParticipantList = () => {
  const participants = UseParticipants();
  const {
    localTracks,
    isViewEnabled,
    room,
    sessionData,
    getToggleView,
  } = useVideoContext();
  const mainParticipant = UseMainParticipant();
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();
  
  const localParticipant = room.localParticipant
  const videoTrack = localTracks.find((track) => track.name.includes("camera"));
  const props = {
    isViewEnabled: isViewEnabled,
    localTracks: videoTrack,
  };

  useEffect(() => {
    if(participants.length === 0 && isViewEnabled) {
      getToggleView()
    }
  },[participants])
  
  const classes = useStyles(props);

  let userName = _.truncate(
    sessionData.name, {
       'length': 25,
     }
  );

  if (participants.length === 0)
    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.infoContainer}>
            <div className={classes.avatarContainer}>Waiting for {userName} to join</div>
          </div>
        </div>
      </div>
    ); // Don't render this component if there are no remote participants.
  
  return (
    <div className={classes.root}>
      <div className={!!isViewEnabled ? classes.widthHeight : classes.container}>
        <div className={classes.innerContainer}>
          <Participant {...(selectedParticipant ? {key: participants[0].sid} : {})}  participant={selectedParticipant ? participants[0] : room.localParticipant} isLocalParticipant={selectedParticipant ? false : true} isSelected={selectedParticipant === localParticipant} onClick={() => setSelectedParticipant(localParticipant)} />
          {/* {participants.map((participant) => {
            console.log(participant, "participant")
            const isSelected = participant === selectedParticipant;
            const hideParticipant = participant === mainParticipant && !isSelected;
            return <Participant key={participant.sid} participant={participant} hideParticipant={hideParticipant} />;
          })} */}
        </div>
      </div>
    </div>
  );
};

export default ParticipantList;
