import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const SameBlueArrowIcon = (props) => {
  return (
    <SvgIcon classes={{
        root: "small"
      }} width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} style={{fill:"none"}}>
      <path d="M4.8001 4.80005L1.6001 8.00005L4.8001 11.2" stroke="#317FF5" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11.1999 4.80005L14.3999 8.00005L11.1999 11.2" stroke="#317FF5" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.6199 8H2.3999" stroke="#317FF5" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </SvgIcon>
  );
};
