import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

const Notifier = () => {
  const notifications = useSelector(
    (store) => store.app.notifications || ""
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useEffect(() => {
    if(!!notifications) {
      enqueueSnackbar(notifications, { variant: "error" });
    }
  }, [notifications, closeSnackbar, enqueueSnackbar]);

  return null;
};

export default Notifier;
