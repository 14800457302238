import React, { useReducer, useRef, useContext } from "react";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import { useSnackbar } from 'notistack';
import Dialog from "fitbud/components/Dialog";
import _ from "lodash";
import {
  Button,
  Badge,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core";
import { getQuickFilters,ATTRIBUTE_FILTER_MAP } from "fitbud/utils/constants";
import { isFilterEmpty as checkIfEmpty } from "fitbud/utils/helpers";

function reducer(state, action) {
  switch (action.type) {
    case "clear-all":
      return {}
    default:
      return { ...state, [action.type]: action.type === 'quick_filters' ? _.pickBy(action.payload): action.payload }
  }
}

const FilterInner = (props) => {
  const { filter, dispatch, keyName, filter_opts: FILTER_OPTIONS, errors, ...restProps } = props
  const filterKeys = Object.keys(FILTER_OPTIONS)
  const quick_filters =  getQuickFilters(keyName)
  const { cid } = useContext(FirebaseAuthContext);
  
  const [selectedFilterMenu, setselectedFilterMenu] = React.useState(!!quick_filters.length ? filterKeys[0] : filterKeys[1]);

  const handleListItemClick = (event, selected) => {
    setselectedFilterMenu(selected);
  };

  const FComponent = FILTER_OPTIONS[selectedFilterMenu].component
  const extraProps = (FILTER_OPTIONS[selectedFilterMenu].extraProps && FILTER_OPTIONS[selectedFilterMenu].extraProps({ cid, restProps })) || {}
  return (
    <div className="d-flex p-0 h-100">
      <div className="col-4 p-0 border-right d-flex flex-column justify-content-between bg-body">
        <List component="nav" className="p-0 overflow-auto" onScroll={e => e.stopPropagation()}>
          {
            filterKeys.map((filter_opts) => {
              if(!FILTER_OPTIONS[filter_opts].component) return null;
              const selected = selectedFilterMenu === filter_opts
              return (
                <ListItem
                  button
                  key={filter_opts}
                  selected={selected}
                  onClick={(event) => handleListItemClick(event, filter_opts)}
                  className={selected ? "bg-white" : "bg-body"}
                  style={{
                    height: "60px"
                  }}
                >
                  <ListItemText 
                    primary={FILTER_OPTIONS[filter_opts].text}
                    className={selected ? "text-primary" : "text-muted"}
                    primaryTypographyProps={{ variant: selected ? "subtitle2": "body2" }}
                  />
                  {!checkIfEmpty(filter[filter_opts]) && <Badge
                    variant="dot"
                    color={ checkIfEmpty(_.get(errors, ["current", filter_opts])) ? "primary" : "error"}
                  />
                  }
                </ListItem>
              )
            })
          }
        </List>
        <Button className="p-3 m-0 bg-white" color="primary" onClick={() => dispatch({ type: "clear-all" })}>Clear All</Button>
      </div>
      <div className="col-8 overflow-auto fpx-20 fpy-10" onScroll={e => e.stopPropagation()}>
        {selectedFilterMenu && (
          <FComponent {...props} {...extraProps} componentState={filter[selectedFilterMenu]} type={selectedFilterMenu} key={selectedFilterMenu} cid={cid} />
        )}
      </div>
    </div>
  )

}
const initialiseState=({filter,isClient})=>{
  if(isClient){
    const KEYS=ATTRIBUTE_FILTER_MAP.map(i=>i.value);
    const attribute_filters=_.pick(filter,KEYS);
    return {...(_.omit(filter,[KEYS])||{}),attribute_filters};
  }
  return {...filter};
}

const FilterView = ({
  open,
  filter = {},
  setFilter,
  handleClose,
  keyName,
  filter_opts = {},
  title,
  actionText,
  isClient=false,
  ...restProps
}) => {
  const [state, dispatch] = useReducer(reducer,{filter,isClient}, initialiseState)
  const { enqueueSnackbar } = useSnackbar();
  const filterErrors = useRef({});
  
  const onSave = () => {
    const noError = checkIfEmpty(filterErrors.current); //  && !checkIfEmpty(state);
    if (noError) {
      let newState={...state};
      if(isClient){
        newState=_.omit({...state,...(state['attribute_filters']||{})},['attribute_filters']);
      }
      setFilter(newState);
      handleClose()
    } else {
      enqueueSnackbar('Please provide valid filter values', { variant: "error" });
    }
  }
  return (<Dialog
    toolbarClass="height-60 border-bottom"
    buttonColor="primary"
    open={open}
    onClose={handleClose}
    onSave={onSave}
    title={title || "Filter"}
    titleFont="h3"
    paperClass="width-600 height-70"
    actionText={actionText || "Apply"}
  >
    <FilterInner filter={state} dispatch={dispatch} keyName={keyName} filter_opts={filter_opts} errors={filterErrors} {...restProps}/>
  </Dialog>
  );
};

export default FilterView;
