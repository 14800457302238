import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const TrainerIcon = props => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 20"
    classes={{
      root: "small"
    }}
  >
    <path d="M18.5 17.1959V15.6186C18.4995 14.9197 18.2715 14.2406 17.852 13.6882C17.4324 13.1358 16.8449 12.7412 16.1818 12.5665" stroke="#8F969F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.8636 17.1959V15.6186C13.8636 14.7819 13.538 13.9795 12.9583 13.3879C12.3786 12.7963 11.5925 12.464 10.7727 12.464H4.5909C3.77114 12.464 2.98496 12.7963 2.4053 13.3879C1.82565 13.9795 1.5 14.7819 1.5 15.6186V17.1959" stroke="#8F969F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.0909 3.10254C13.7557 3.27628 14.345 3.67093 14.7659 4.22426C15.1867 4.7776 15.4151 5.45814 15.4151 6.15861C15.4151 6.85908 15.1867 7.53962 14.7659 8.09295C14.345 8.64629 13.7557 9.04093 13.0909 9.21468" stroke="#8F969F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.68185 9.30931C9.3889 9.30931 10.7727 7.89692 10.7727 6.15465C10.7727 4.41239 9.3889 3 7.68185 3C5.97479 3 4.59094 4.41239 4.59094 6.15465C4.59094 7.89692 5.97479 9.30931 7.68185 9.30931Z" stroke="#8F969F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </SvgIcon>
);
