import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { WorkerContext } from 'fitbud/providers/workerProvider';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import CheckinStepper from './checkinStepper';
import firebase from "fitbud/firebase";
import { CircularProgress, Typography } from '@material-ui/core';
import _ from "lodash";
import NoProgress from "fitbud/images/trainer_no_schedule.svg";
import clsx from 'clsx';

const CheckinNotFound = () => {
  return (
    <div className="h-100 text-center d-flex align-items-center justify-content-center flex-column">
      <img alt="no progress" src={NoProgress} className="fmb-20" />
      <Typography variant="h2" className={`fmb-10`}>
        No Checkin Found
      </Typography>
    </div>
  )
}

const CheckinView = (props) => {
  const {
    measurableTags,
    docId,
    checkins: imagesArray,
    loadMore,
    userDoc = {},
    measurableUnits,
    unitSystem = "metric",
    unitDetails,
    scrollContainerId
  } = props;
  const imagesRef = useRef(imagesArray);
  const { cid, comp, userProfile, features } = useContext(FirebaseAuthContext);
  const { markRead } = useContext(WorkerContext);
  const [loading, updateLoader] = useState(false);
  const [checkinQues, setCheckinQues] = useState(null);
  let startDate = userDoc.startDate || null;

  useEffect(() => {
    imagesRef.current = imagesArray;
  }, [imagesArray]);

  useEffect(() => {
    let unMount = false;
    let list = document.querySelector(`#${scrollContainerId}`);
    list.addEventListener("scroll", handleScroll);
    markRead({ cid, keyName: "checkins", user: { ...userDoc, _id: docId } });

    firebase.firestore().doc(`companies/${cid}/questionnaire/checkin`)
      .get().then(doc => {
        if (!doc.exists|| unMount) return;
        const qs = doc.data().questions;
        if (!qs || !_.find(qs, ['visible', true]) ) return;
        setCheckinQues(doc.data());
      }).catch(e => console.error(e));

    return () => {
      list.removeEventListener("scroll", handleScroll);
      unMount=true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScroll = _.debounce(() => {
    let imgs = imagesRef.current;
    if (imgs && imgs.length && imgs.length % 5 !== 0) {
      let list = document.querySelector(`#${scrollContainerId}`);
      list.removeEventListener("scroll", handleScroll);
    }
    var lastLi = document.querySelector(`#item_${imgs.length - 1}`);
    if (lastLi) {
      if (lastLi.getBoundingClientRect().top <= window.innerHeight) {
        updateLoader(true);
        loadMore(updateLoader);
      }
    }
  }, 500);
  const hasImageAccess = useMemo(() => !features.privacy || !!_.get(userProfile, `meta.${cid}.checkin`, false), [userProfile, cid, features.privacy]);
  return (
    <div className={clsx('h-100', imagesArray && imagesArray.length && 'fmt-25 fpx-20 ')}>
      {imagesArray && imagesArray.length  ? imagesArray.map((i, j) => {
        i = i.data();
        return (
          <CheckinStepper
            id={`item_${j}`}
            key={j}
            startDate={startDate}
            currentDate={i}
            last={j === imagesArray.length - 1}
            measurableTags={measurableTags}
            measurableUnits={measurableUnits}
            unitSystem={unitSystem}
            unitDetails={unitDetails}
            checkinQues={checkinQues}
            docId={docId}
            userDoc={userDoc}
            hasImageAccess={hasImageAccess}
            compData={comp.data()}
          />
        );
      }): <CheckinNotFound />}
      {loading && (
        <div className="w-100 fpy-10 d-flex justify-content-center align-items-center">
          <CircularProgress size={30} />
        </div>
      )}
    </div>
  )
}

export default CheckinView;