import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const ChallengesIcon = props => (
    <SvgIcon
        {...props}
        viewBox="0 0 20 20"
        classes={{
          root: "small"
        }}
    >
    <path d="M16.5337 16.2102V5.59019C16.5337 4.2402 15.9577 3.7002 14.5267 3.7002H13.5907C12.1597 3.7002 11.5837 4.2402 11.5837 5.59019V16.2102" stroke="#8F969F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3.93408 16.2102V10.0902C3.93408 8.7402 4.51008 8.2002 5.94108 8.2002H6.87708C8.30808 8.2002 8.88408 8.7402 8.88408 10.0902V16.2102" stroke="#8F969F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1 16.2998H19" stroke="#8F969F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </SvgIcon>
);