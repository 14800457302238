import _ from "lodash";
import React, { useContext, useEffect, useState, useCallback, useRef } from 'react'
import moment from "moment";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import {
  CircularProgress,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Drawer,
  Hidden,
  AppBar,
  Toolbar,
  useTheme,
  useMediaQuery,
  Popover,
  ClickAwayListener,
} from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {  downloadFile } from "fitbud/utils/helpers";
import { ShowDate, getDownloadUrl, getImageDownloadUrl, isMessageReacted } from 'fitbud/views/chats/chatView';
import { GroupChatMessagePlaceholder } from 'fitbud/views/groupChat/placeholder';
import firebase from 'firebase';
import { ArrowBackIos, Close } from '@material-ui/icons';
import Confirmation from 'fitbud/components/confirmationDialog';
import Users from "fitbud/views/users";
import { NavContext } from "fitbud/providers/appNav";
import { DEFAULT_ERROR, DrawerWidth, DrawerWidthMinified } from "fitbud/utils/constants";
import { LEFT } from "fitbud/hooks/useSplit";
import { useSnackbar } from "notistack";
import * as Sentry from '@sentry/browser';
import GrpMessage from './grpMessage';
import { Picker } from "emoji-mart";

const useStyles = makeStyles(theme => ({
  chatArea: {
    background: "#ffffff",
    flex: 1,
    padding: '0 20px',
    overflow: "scroll",
    [theme.breakpoints.down("xs")]: {
      height: "calc(100vh - 187px)"
    }
  },
  rightMsg: {
    maxWidth: "max-content",
    minWidth: "100px",
    background: "#FFF",
    border: '2px solid #F0F3F5',
    wordWrap: "break-word",
    marginLeft: "auto",
    borderRadius: "10px 10px 0px 10px",
  },
  mediaRight: {
    maxWidth: "max-content",
    padding: 0,
    marginLeft: "auto",
    border: "none",
    borderRadius: "10px 10px 0px 10px",
  },
  leftMsg: {
    maxWidth: "max-content",
    minWidth: "100px",
    background: "#F0F3F5",
    wordWrap: "break-word",
    border: '2px solid #F0F3F5',
    position: "relative",
    borderRadius: "10px 10px 10px 0px"
  },
  mediaLeft: {
    maxWidth: "max-content",
    padding: 0,
    marginRight: "auto",
    border: "none",
    borderRadius: "10px 10px 10px 0px"
  },
  replyRight: {
    flexDirection: "row-reverse"
  },
  messageAvatar:{
    position: 'absolute',
    left: 0,
    bottom: 0,
  },
  menu:{
    padding: 0
  },
  negMargin:{
    marginTop: '-1px',
  },
  normalMargin:{
    marginTop: "5px",
  },
  inChatLoader:{
    position: 'absolute',
    left: "50%",
    transform: "translate(-50%)",
  },
  ltContainer:{
    whiteSpace: "pre-wrap",
    paddingRight: "30%",
    maxWidth: '100%',
  },
  rtContainer:{
    whiteSpace: "pre-wrap",
    paddingLeft: "30%",
    maxWidth: '100%',
  },
  focusedMessage: {
    "& .msgBubble": {
      backgroundColor: "#E0ECFE",
    }
  },
  toggleButton : {
    position: "absolute",
    left: "-50px",
    top: "10px",
    zIndex: theme.zIndex.drawer + 2,
  },
  reactionPicker: {
    "& .emoji-mart-preview": {
      display: "none"
    }
  },
  drawerPaper : {
    overflow: "visible",
    maxWidth: "100%",
    width: ({ isExpanded = false, isMobile }) => isMobile ? "100%" :`calc(100% - ${isExpanded ? DrawerWidth + LEFT : DrawerWidthMinified + LEFT}px)`,
  },
  drawerRoot: {
    overflow: "visible",
    zIndex: `${theme.zIndex.drawer} !important`,
    width: ({ isExpanded = false, isMobile }) => isMobile ? "100%" :`calc(100% - ${isExpanded ? DrawerWidth + LEFT : DrawerWidthMinified + LEFT}px)`,
    maxWidth: ({ isExpanded = false, isMobile }) => isMobile ? "100%" :`calc(100% - ${isExpanded ? DrawerWidth + LEFT : DrawerWidthMinified + LEFT}px)`,
  },
}));

function GroupChatMessageListView(props) {
  const {
    messages,
    fetchMore,
    fetchNext,
    lastFetchedMsg,
    latestFetchedMsg,
    fetching,
    groupId,
    profileCache,
    showLoader,
    hideLoader,
    msgScroll,
    setReplyData,
    setChatLoading,
    loadSecondaryMsgs,
    setShowScrollBtn,
    group
  } = props;
  const { cid, userProfile } = useContext(FirebaseAuthContext);
  const { drawerState } = useContext(NavContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [anchorEl, setAnchorEl] = useState(null);
  const [reactAnchorEl, setReactAnchorEl] = useState(null);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [view_width, setViewWidth] = useState(0);
  const [userDrawer, setUserDrawer] = useState(false);
  const [fetchDir, setFetchDir] = useState("prev")
  const [downloadResource, setDownloadResource] = useState({type: '', data: {}, id: ''});
  const closeUserDrawer = () => setUserDrawer(false);
  const { enqueueSnackbar } = useSnackbar()
  const focusedMsg = useRef(null);

  const classes = useStyles({ isExpanded: drawerState.expanded, isMobile });

  async function beginDownload() {
    showLoader();
    try {
      const { type, data, id } = downloadResource;
      let downloadUrl;
      if (type === 'image') {
        downloadUrl = await getImageDownloadUrl(data);
      } else {
        downloadUrl = await getDownloadUrl(props.id, data);
      }
      if (!downloadUrl) throw new Error('invalid url');
      await downloadFile(downloadUrl, type === 'other' ? data.body : id);
      hideLoader();
    } catch (error) {
      Sentry.captureException(error);
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
      hideLoader();
    }
  }

  const decideToShowUnread = useCallback(
    _.debounce(() => {
      const chatarea = document.getElementById("groupChatArea");
      const last_msg = document.getElementById(`groupMessagesEnd`);
      if (!chatarea || !last_msg) return;
      if (last_msg.getBoundingClientRect().bottom - chatarea.getBoundingClientRect().bottom <= 100) {
        setShowScrollBtn(false);
      } else {
        setShowScrollBtn((chatarea.getBoundingClientRect().bottom < last_msg.getBoundingClientRect().bottom))
      }
    }, 100),
    [],
  );

  useEffect(() => {
    const chatarea = document.getElementById("groupChatArea");
    function handleResize() {
      const chatAreaWidth = chatarea.getBoundingClientRect().width;
      setViewWidth(chatAreaWidth)
    }
    
    if(chatarea) {
      setViewWidth(chatarea.getBoundingClientRect().width)
      window.addEventListener("resize", handleResize)
    }
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  useEffect(() => {
    const chatarea = document.getElementById("groupChatArea");
    chatarea.addEventListener("scroll", decideToShowUnread);
    return () => {
      chatarea && chatarea.removeEventListener("scroll", decideToShowUnread);
    };

  }, [decideToShowUnread]);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  }

  const handleOpenMessageOption = (e, msg) => {
    const msgData = msg.data();

    let resource = { id: msg.id, type: "text" };
    if (msgData.media) {
      resource.type = "image";
      resource.data = {
        identifier: msgData.media.identifier,
        storageFolderRef: `companies/${groupId}/groupChats/chats`,
      };
    } else if (
      msgData.type === "attachment" ||
      msgData.type === "audio" ||
      msgData.type === "video"
    ) {
      resource.type = "other";
      resource.data = msgData;
    }

    focusedMsg.current = {id: msg.id, ...msgData};
    setDownloadResource(resource)
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.target);
  }

  const handleDeleteMessage = (e) => {
    e.preventDefault();
    if(deleteConfirm){
      showLoader();
      firebase.firestore()
        .doc(`groupChats/${groupId}/messages/${deleteConfirm}`)
        .update( { deleted: true, _uat: firebase.firestore.FieldValue.serverTimestamp() }).then(() => {
          setDeleteConfirm(false);
          hideLoader();
        });
    }
  }

  const reactMessage = (e) => {
    const emoji = e.native;
    const id = focusedMsg.current.id;
    firebase.firestore().doc(`groupChats/${groupId}/messages/${id}`)
    .update({ 
      [`react.${userProfile.uid}`]: {
      text: emoji,
      _uat: firebase.firestore.FieldValue.serverTimestamp()
      },
      _uat: firebase.firestore.FieldValue.serverTimestamp()
    })
    setReactAnchorEl(null);
  }

  const unReactMessage = ( id) => {
    firebase.firestore().doc(`groupChats/${groupId}/messages/${id}`)
    .update({ 
      [`react.${userProfile.uid}`]: firebase.firestore.FieldValue.delete(), 
      _uat: firebase.firestore.FieldValue.serverTimestamp() 
    })
  }

  const handleReact = (e, msgData) => {
    if (msgData.react && msgData.react?.[userProfile.uid]) {
      unReactMessage(msgData.id);
    } else {
      setReactAnchorEl(e.currentTarget);
    }
  }

  const handlePrivateReply = () => {
    setUserDrawer(`${cid}:${focusedMsg.current.authorId}`);
  };

  let show_date = false;
  let date1;
  if (!!messages  && !!messages.length) {
    show_date = true;
    let timestamp_current = (messages[0].data().timestamp && messages[0].data().timestamp.toMillis())|| new Date();
    date1 = moment(timestamp_current).local();
  }

  const handleScroll = (e) => {
    if (fetching) return;
    if(e.target.scrollTop <= 0) {
      //scroll previous messages
      fetchMore();
      setFetchDir("prev")
    };
    if(e.target.scrollTop >= e.target.scrollHeight - e.target.clientHeight - 1) {
      fetchNext();
      setFetchDir("next")
    }
  };

  function handleReply() {
    const target = messages.find(msg => msg.id === focusedMsg.current.id);
    setReplyData({ ...target.data(), replyId: target.id, reply: null });
  }

   const scrollToItem = ({scrollToId, block, behavior}) => {
    if (!!scrollToId) {
      document.getElementById(scrollToId).scrollIntoView({ behavior, block});
    }
  };

  const scrollToMessage = async (id) => {
    if(!id) return;
    const msg = messages.find(msg => msg.id === id);

    if(msg) {
      scrollToItem({scrollToId:id, block: 'center', behavior: 'smooth'});
    } else{
      setChatLoading(true);
      const groupChatArea = document.getElementById("groupChatArea");

      if(groupChatArea.scrollTop >= groupChatArea.scrollHeight - groupChatArea.clientHeight) {
        groupChatArea.scrollTop -= 2;
      }
      const msgDoc = await firebase.firestore().collection(`groupChats/${groupId}/messages`).doc(id).get();
      
      if(!msgDoc.exists) {
        enqueueSnackbar("This message is deleted", { variant: 'error' });
        setChatLoading(false);
        return;
      }
      
      const prevMessages = await firebase
      .firestore()
      .collection(`groupChats/${groupId}/messages`)
      .orderBy("timestamp", "desc")
      .startAfter(msgDoc)
      .limit(5)
      .get();

      const nextMessages = await firebase
      .firestore()
      .collection(`groupChats/${groupId}/messages`)
      .orderBy("timestamp", "asc")
      .startAfter(msgDoc)
      .limit(5)
      .get();
      const [prev, next] = await Promise.all([prevMessages, nextMessages]);

      loadSecondaryMsgs([...prev.docs.reverse(), msgDoc, ...next.docs], id);
      setChatLoading(false);
    }

    const msgNode = document.getElementById(id);
    if(msgNode) {
      msgNode.classList.add(classes.focusedMessage)
      setTimeout(() => {
        msgNode.classList.remove(classes.focusedMessage)
      }, 1000)
    }
  };

  useEffect(() => {
    scrollToItem({
      scrollToId: msgScroll.id, 
      block: msgScroll.block, 
      behavior:msgScroll.behavior})
  }, [messages.length]);
  return (
    <div className={clsx(classes.chatArea, !(messages && messages.length) && 'd-flex align-items-center')} id="groupChatArea" onScroll={handleScroll}>
      <div 
        className={clsx(
          "mx-auto text-center position-absolute", 
          (lastFetchedMsg || latestFetchedMsg) && fetching ? 'visible' : 'd-none',
          classes.inChatLoader,
        )}
        style={{...(fetchDir === 'prev' ? { top: 10 } : { bottom: 80 })}}
      >
        <CircularProgress size={22} />
      </div>
      {show_date && <ShowDate date={date1} />}
      {!!messages && !!messages.length ? messages.map((msg, index) => {
        return(
          <GrpMessage msg={msg} key={msg.id} index={index} messages={messages}
            groupId={groupId} view_width={view_width} classes={classes} scrollToMsg={scrollToMessage}
            profileCache={profileCache} id={props.id} onOptionClick={handleOpenMessageOption}/>
        )
      }) : <GroupChatMessagePlaceholder hideDescription={group && group.deleted} /> }
      <div id="groupMessagesEnd" />
      <Confirmation
        title="Please confirm"
        confirmOption="Yes, Delete"
        msg="Are you sure you want to delete the message?"
        open={!!deleteConfirm}
        handleCancel={() => setDeleteConfirm(false)}
        handleChange={handleDeleteMessage} 
      />
      <Menu
        anchorEl={anchorEl}
        elevation={4}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        classes={{
          paper: `fb-border`,
          list: "p-0"
        }}
        id="delete_pop_up"
        onClick={handleCloseMenu}
      > 
        <MenuItem onClick={(e) => handleReact(e, focusedMsg.current)}>
            {isMessageReacted(focusedMsg.current, userProfile.uid) ? "Remove Reaction" : "Add Reaction"}
        </MenuItem>
        <MenuItem onClick={handleReply}>
          Reply
        </MenuItem>
        {focusedMsg.current?.authorId !== cid &&
          <MenuItem onClick={handlePrivateReply}>
            Reply Privately
          </MenuItem>}
        {downloadResource.type !== 'text' && (
          <MenuItem onClick={beginDownload}>
            Download
          </MenuItem>
        )}
        <MenuItem onClick={() => setDeleteConfirm(focusedMsg.current?.id)}>
          Delete
        </MenuItem>
      </Menu>
      <Popover 
          open={Boolean(reactAnchorEl)}
          anchorEl={reactAnchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <ClickAwayListener onClickAway={() => setReactAnchorEl(null)}>
            <span className={classes.reactionPicker}>
              <Picker onSelect={reactMessage} showPreview={false} />
            </span>
          </ClickAwayListener>
        </Popover>
      {!!userDrawer &&
          <Drawer variant="temporary" open={!!userDrawer} anchor="right" classes={{ root: classes.drawerRoot, paper: classes.drawerPaper}}
            ModalProps={{ BackdropProps: { onClick: closeUserDrawer } }}>
            <Hidden smDown>
              <IconButton onClick={closeUserDrawer} className={clsx(classes.toggleButton, "bg-grey-new")}>
                <Close />
              </IconButton>
            </Hidden>
            <Hidden mdUp>
                <AppBar position="sticky" color="primary">
                  <Toolbar classes={{ root: "height-60" }} className={"d-flex flex-row bg-white justify-content-between align-items-center border-bottom fpx-10"}>
                    <div className='d-flex align-items-center'>
                      <IconButton edge="end" color="inherit" onClick={closeUserDrawer}>
                        <ArrowBackIos />
                      </IconButton>
                      <Typography variant="h3" className='fml-20'>{"Back to Group Chat"}</Typography>
                    </div>
                  </Toolbar>
                </AppBar>
              </Hidden>
            {!!userDrawer && <Users isDrawerMode page={"chats"} scope={"chats"} uid={userDrawer} />}
          </Drawer>
        }
    </div>
  )
};

export default GroupChatMessageListView;
