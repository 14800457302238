import { bindActionCreators } from "redux";
import axnxBuilder from "./actions";
const axns = axnxBuilder();

const actionsCreator = {
  uploadFile: (data) => ({ type: axns.uploadFile, data }), //for video
  clearFile: (id) => ({ type: axns.clearFile, id }),
  clearFiles: () => ({ type: axns.clearFiles }),
  uploadBunnyFile:(data)=>({type:axns.uploadBunnyFile,data})//for bunny-cdn video
};

export default (dispatch) => bindActionCreators(actionsCreator, dispatch);
