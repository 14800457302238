import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { makeStyles, Card, Typography,Hidden } from "@material-ui/core";
import {bubbleTail} from "fitbud/images/onboarding";

const styles = makeStyles({
  testimonial: {
    paddingBottom: "100px",
    paddingLeft: "40px",
    color: "#fff",
    zIndex: 9,
    height: "auto!important",
    "& .msg": {
      color: "#000",
      padding: "20px",
      maxWidth: "60%",
      height: "auto!important",
      borderRadius: "10px",
      fontSize: "16px",
      lineHeight: "19.5px",
      overflow:"visible",
      position:"relative",
      margin:"0px auto",
      marginBottom:"40px",
      '& .bubbleTail':{
        position:"absolute",
        bottom:"-19px",
        right:"50px"
      }
    },
    "& .name": {
      fontSize: "72px",
      lineHeight: "55px",
      fontWeight: 700,
      marginBottom: "20px",
    },
    "& .company": {
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "23px",
    },
  },
});

const TESTIMONIALS = [
  {
    name: "Jani Dittman",
    company: "Fitditts",
    msg: "Love all the features and that you can brand the app to look like it really is your training app.",
    img: "https://fitbudd-images.b-cdn.net/fitbudd-prod-v1/fitditts/c95f4781-71a5-4fac-a3d2-b8b1d233fc42.png",
  },
  {
    name: "Jeremy Sry",
    company: "Pursuit by SRY",
    msg: "I've tried different platforms, I've tried creating my own app from scratch, and through it all, it wasn't until Fitbudd reached out that I really got to create something that was tailored towards my actual training and practices!",
    img: "https://fitbudd-images.b-cdn.net/fitbudd-prod-v1/jeremy/33f37e52-154e-403d-840a-db866c5305d4.png",
  },
  {
    name: "Pelegrina Dora",
    company: "Amazon Girl Training",
    msg: "My clients really appreciate that the app is user-friendly and everything is transparent and easy to find. I, as a trainer, am very happy with FitBudd support.",
    img: "https://fitbudd-images.b-cdn.net/fitbud-b5c08/pdora/a49e0c6b-e469-4890-9e21-1ccaab0e3776.jpg",
  }
];

export const Carousel = () => {
  const classes = styles();
  const settings = {
    autoplay: true,
    dots: true,
    autoplaySpeed: 3000,
    arrows:false
  };
  return (
    <Hidden smDown>
      <Slider {...settings}>
        {TESTIMONIALS.map((i) => {
          return (
            <div>
              <div
                className="d-flex flex-column justify-content-end"
                style={{
                  backgroundImage: `url(${i.img})`,
                  backgroundSize: "cover",
                }}
              >
                <div className={classes.testimonial}>
                  <Card className="msg fp-20">
                    <Typography variant="h4">{i.msg}</Typography>
                    <img src={bubbleTail} alt="" className="bubbleTail"/>
                  </Card>
                  <Typography className="name">{i.name}</Typography>
                  <Typography className="company">{i.company}</Typography>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </Hidden>
  );
};
