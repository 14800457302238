import React from "react";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import {
  List,
  ListItem,
  ListItemText,
  LinearProgress,
  InputBase,
  IconButton,
  Checkbox,
  Divider,
  InputAdornment,
  Typography
} from "@material-ui/core";
import Clear from "@material-ui/icons/CloseRounded";
import schRdxFns from "fitbud/redux/schedules";
import mlSchRdxFns from "fitbud/redux/mlSchedules";
import Refresh from "@material-ui/icons/Refresh";
import useListItemStyle from "fitbud/hooks/useListItemStyle";
import SearchIcon from "@material-ui/icons/Search";
//deprecated

const WoListInner = props => {
  const classes = useListItemStyle();
  const { docs, onSelect, selected, multiSelect, checked } = props;
  let onClick = null;

  if (!!onSelect)
    if (!multiSelect) {
      onClick = doc => {
        onSelect(doc._id);
      };
    } else {
      onClick = doc => {
        onSelect(doc);
      };
    }
  if (!docs) return null;

  return (
    <>
      {docs.map((doc, index) => (
        <ListItem
          className="fpy-10 fpx-20 w-100 d-flex justify-content-between align-items-center"
          key={index}
          id={doc.id}
          selected={selected === doc._id}
          divider
          button
          classes={{
            root: classes.root,
            selected: classes.selected
          }}
          onClick={() => onClick(doc)}
        >
          <ListItemText
            className="w-100 d-flex justify-content-between align-items-center"
            disableTypography
            primary={
              <Typography
                className={multiSelect ? "w-75" : "w-100"}
                variant={
                  checked &&
                  checked.findIndex(item => item._id === doc._id) !== -1
                    ? "h6"
                    : "body1"
                }
              >
                {doc.data.ref_name}
              </Typography>
            }
          />
          {multiSelect && (
            <Checkbox
              className="fp-0"
              color="primary"
              checked={checked && !!checked.find(i => i._id === doc._id)}
              disableRipple
            />
          )}
        </ListItem>
      ))}
    </>
  );
};

class SchList extends React.Component {
  state = { q: "", selectedIndex: null };
  scrollerRef = React.createRef();
  currentKeyRdxFns = this.props[this.props.keyName];
  
  handleChange = e => {
    this.currentKeyRdxFns.searching(e.target.value);
    this.setState({ q: e.target.value });
  };
  handleScroll = e => {
    const { count, searchCount, docs, searchResult } = this.props;
    if (e.target.scrollTop + e.target.clientHeight >= (e.target.scrollHeight - 100)) {
      if (!!this.state.q) {
        if (!!searchResult)
          if (searchCount > searchResult.length) {
            this.currentKeyRdxFns.searching(this.state.q);
          }
      } else {
        if (!!docs)
          if (count > docs.length) {
            this.currentKeyRdxFns.request();
          }
      }
    }
  };

  render() {
    const { q } = this.state;
    const {
      loading,
      docs,
      selected,
      onSelect,
      multiSelect,
      checked,
      moduleName,
      searchResult,
    } = this.props;
    const scroll = !multiSelect && "overflow-auto";
    return (
      <div
        className={`h-100 d-flex flex-column`}
        ref={this.scrollerRef}
        onScroll={this.handleScroll}
      >
        <div className="sticky-top" style={{ zIndex: 9 }}>
          <div className=" d-flex fpl-20 fpr-10 bg-white">
            <InputBase
              fullWidth
              classes={{
                root: "medium"
              }}
              value={q}
              onChange={this.handleChange}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              placeholder="Search ..."
            />
            {!q && (
              <IconButton onClick={() => this.fetch(true)}>
                <Refresh />
              </IconButton>
            )}
            {!!q && (
              <IconButton
                onClick={() => {
                  this.setState({ q: "" });
                  this.currentKeyRdxFns.searching("");
                }}
              >
                <Clear />
              </IconButton>
            )}
          </div>
          <Divider />
        </div>
        {!!loading && (
            <div className="white-overlay bg-transparent position-relative">
              <LinearProgress />
            </div>
        )}
        <List disablePadding className="position-relative overflow-auto">
          <WoListInner
            docs={!!q ? searchResult : docs}
            onSelect={onSelect}
            selected={selected}
            multiSelect={multiSelect}
            checked={checked}
            moduleName={moduleName}
          />
        </List>
      </div>
    );
  }

  componentDidMount() {
    if (!this.props.loading && !this.props.docs) this.fetch();
  }
  fetch = (refresh = false) => {
    if (this.props.loading) return;
    this.currentKeyRdxFns.toggleLoader();
    this.currentKeyRdxFns.request(refresh);
  };
}
const mapStateToProps = (s, op) => {
  return { ...s[op.keyName] };
};
const mapDispatchToProps = d => {
  return {
    schedules: { ...schRdxFns(d) },
    mlSchedules: { ...mlSchRdxFns(d) },
  };
};

export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(SchList)
);
