import React,{useEffect,useState,useRef, useMemo} from 'react';
import {Avatar as MuiAvatar} from "@material-ui/core";
import clsx from "clsx";
import {getUiAvatar} from "fitbud/utils/helpers";
import { parseImgUrl } from "croppy";
import _ from 'lodash';


export const Avatar=({src,base64=false,size,name,width=160,border=true,...props})=>{
    const {className,styles,...rest}=props;
    const errorCount = useRef(0);
    const [err, updateErr] = useState(false);
    const _imgSrc = useMemo(()=>{
    if(!src) return "";    
    if(!!base64) return src;
    const { parsed } = parseImgUrl(src, { width});
    return  parsed.href;
    },[src, width]);
    useEffect(() => {
        errorCount.current = 0;
    }, [src])

    function handleErr(){
        if(!errorCount.current){
            errorCount.current += 1;
        }
        else{
            updateErr(true);
        }
    }
    const commonStyles=_.merge({
        border:border ? "1px solid #D3D9DB": 'none',
        background:'#F2F4F7',
        color:"#6F8099",
        fontSize:20,
        fontWeight:600,
        },
        styles);
    if(!!_imgSrc && !err){
        return(
            <MuiAvatar
            {...rest}
            onError={handleErr}
            src={base64 ? `data:image/jpeg;base64, ${_imgSrc}` : _imgSrc}
            className={
                clsx(className,rest.onClick && "cursor-pointer",'text-uppercase')
                // clsx('avatar-image', className,rest.onClick && "cursor-pointer",)
            }
            classes={{
               root: size
            }}
            style={{...commonStyles}}
            >
                {getUiAvatar(name)}
            </MuiAvatar>
        )
    }
    return(
        <MuiAvatar
        {...rest}
        className={
            clsx(className,rest.onclick && "cursor-pointer","text-uppercase")
            // clsx(className, 'avatar-image', rest.onclick && "cursor-pointer","text-uppercase")
        }
        classes={{
          root: size
        }}
        style={{
          ...commonStyles
        }}
        >
        {getUiAvatar(name)}
        </MuiAvatar>
    )
    
}
