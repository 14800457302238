import React,{useMemo} from 'react';
import { sum,union,uniq} from "lodash";
import {fetchThumbnail} from "fitbud/api";
import { secondsToDuration} from "fitbud/components/durationInput";
import {pluralize,roundNumber} from "fitbud/utils/helpers";
import {MAX_REP_DURATION,MAX_REP_VALUE} from "fitbud/utils/constants";

//dictionary,selectedIds,2nd dictionary
export const mapIdToObject=(map=[],idsArray=[],demux = undefined)=>{
  const finalArr=[];
  idsArray.forEach(e=>{
       const found=map.find(i => {
        if (demux && demux[e]) return i.value === demux[e];
        return i.value===e
       });
       if(found){
        finalArr.push(found)
       }
       
  });
  return finalArr;
}
function getValue(arr=[],isRest=true,type){
    if(!arr.length) return 0;
    else if(arr.length===1) return arr[0];//fixed
    else if(arr.length>1) {
          return arr[1];
    }
  }

 export function calculateSegmentDuration({exercises,sets,duration,rest_after,rest,type,rest_after_circuit_enable,rest_after_circuit,duration_per_exercise,emom_type="ex"},per_rep_time,storedEx){
    //total
    let totalDuration=0;// total
    let totalExDuration=0;// exercise
    let totalRestDuration=0; // rest
    //exercises
    let nonPerSetExDurationSingleSet=0;// non-perset - exercise- single-set-duration
    let totalPersetExDuration=0; // perset-exercise-totalset-duration
    //rest
    let restDurationForSingleSetWoLastSet=0;//rest duration for single set without last set
    let restDurationForLastSet=0;//rest duration for last set only
    //----Calculation-----
    if(rest_after==="set"){
      restDurationForSingleSetWoLastSet=getValue(rest);
    }
    if(type==="amrap") return duration;
    else{
      exercises.forEach((i,j)=>{
        //--rest after exercise case
        if(rest_after==="exercise"){
          restDurationForSingleSetWoLastSet+=getValue(i.rest);
          if(j<(exercises.length-1)){
            restDurationForLastSet+=getValue(i.rest);
          }
        }
        if(type==="emom"){
          if(emom_type==="ex"){
            nonPerSetExDurationSingleSet+=duration_per_exercise
          }
          else if(emom_type==="set"){
            nonPerSetExDurationSingleSet=duration_per_exercise;
          }
        }
        else{
          //---perset case----
          if(i.type==="perset"){
            totalPersetExDuration=i.ref_type==='duration'?
            sum(i.values.slice(0,sets))
            :i.values.slice(0,sets).reduce((sum,i)=>{
              const value=i===MAX_REP_VALUE?MAX_REP_DURATION:i*per_rep_time;
              return(sum+value);
            },0)
            if(i.side && i.side!=="none"){
              //Not doubling in emom as `duration_per_exercise`(both /ex or /set) in emom is inclusive of it.
              totalPersetExDuration*=2;
            }
          }
          //---non-perset case exercises----
          else{
            const v=getValue(i.values,false,i.type);
            let value=i.ref_type==="duration"? v
            :(v===MAX_REP_VALUE?MAX_REP_DURATION:(v*per_rep_time));
            if(i.side && i.side!=="none"){
              //Not doubling in emom as `duration_per_exercise`(both /ex or /set) in emom is inclusive of it.
              value*=2;
            }
            nonPerSetExDurationSingleSet+=value;
          }
        }
      });
    }
    totalExDuration=(sets*nonPerSetExDurationSingleSet)+totalPersetExDuration;
    restDurationForLastSet=!rest_after_circuit_enable?
      restDurationForSingleSetWoLastSet
      :(getValue(rest_after_circuit)+restDurationForLastSet);
    totalRestDuration=(restDurationForSingleSetWoLastSet*(sets-1))+(restDurationForLastSet);
    totalDuration=totalExDuration+(type==="emom" && emom_type==="set"?0:totalRestDuration);
    return totalDuration;
  }

  export function calculateEquipmentsNBodyParts(groups=[],exerciseMeta){
    let equipments=[];
    let body_parts=[];
    groups.forEach(g=>{
      g.exercises.forEach(e=>{
        const exId=e.ref.id;
        const {equipments:metaEquipments=[],body_parts:metaBodyParts=[]}=exerciseMeta[exId]||{};
        equipments=union(equipments,metaEquipments);
        body_parts=union(body_parts,metaBodyParts);
      })
    });
    return {equipments,body_parts};
  }

  export function exportTags(data={},valueKey="value",labelKey="label"){
    const arr=[];
    Object.entries(data).forEach(item => {
      arr.push({ [valueKey]: item[0], [labelKey]: item[1].value });
    });
    return arr||[];
  }


export function formatTime(value){
    if(value<10){
        return `0${value}`;
    }
    return value;
}
export const parseYTVideoId=(url)=>{
  //TODO: check if url is valid or not;
  const VID_REGEX = /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
  return url.match(VID_REGEX)[1];
}

export const getYTThumbnail=(url)=>{
  const id=parseYTVideoId(url);
  return fetchThumbnail(id,'youtube');
}

export const parseVimeoVideoId=(url)=>{
  //TODO: check if url is valid or not;
  const VID_REGEX = /vimeo.*\/(\d+)/i;
  return url.match(VID_REGEX)[1];
}

export const getVimeoThumbnail=(id)=>{
  return fetchThumbnail(id,'vimeo');
}
export const videoExists=(obj)=>{
  if(!obj) obj={}
  if(!Object.keys(obj).length) return false;
  return obj.url||obj.s3Url;
}
export const isVimeoType = (obj) =>{
  if (!obj) return false;
  if (!Object.keys(obj).length) return false;
  const { type, vimeo } = obj;
  if (type === 'vimeo') return true;
  // else if (!!vimeo) return true;
  return false;
}
export const mediaObjExist=(obj)=>{
  if(!obj) obj={}
  if(!Object.keys(obj).length) return false;
  return obj.url||obj.s3Url||obj.s3Path||obj.videoId;
}

export const sanitizeBodyParts=(ids=[],primDictionary=[],secDictionary={})=>{
  if(!ids || (Array.isArray(ids) && !ids.length)) return [];
  const primDictionaryArr=primDictionary.map(i=>i.value);//only ids
  const arr=[];
  ids.forEach(i=>{
    if(primDictionaryArr.includes(i)) arr.push(i);
    else if(secDictionary[i]){
      arr.push(secDictionary[i]);
    }
    else{
      //ignore that id
    }
  });
  return uniq(arr);
}
//List & Meta Duration format: x h y m | x hr | y min
export const durationToHMString=(duration=0,list=false)=>{
  if(duration<60) {
    if(list)return "1m" 
    return "< 1 min";
  }
  const parsedDuration=secondsToDuration(duration);
  let [h,m,s]=parsedDuration.split(" : ").map(i=>Number(i));//[h,m,s]
  if(s>=30){
    m+=1;//rounding off minutes wrt seconds
  } 
  let durationStr="";
  if(!!h && !!m){
    durationStr+=h+ (!!list ? "h" : pluralize(h," hr"))+" " ;
    durationStr+=m+ (!!list ? "m" : pluralize(m," min"));
  }
  else{
    if(!h){
      durationStr+=m+ (!!list ? "m" : pluralize(m," min")) ;
    }
    else if(!m){
      durationStr+=h+ (!!list ? "h" : pluralize(h," hr")) ;
    }
  }
  return durationStr;
}
export const DurationToHMSComp=({duration,list})=>{
  const str=useMemo(()=>{
    if(duration<60) {
      if(list)return (<>
        <span className='font-weight-600'>1</span>
        m
      </>)
      return (<>
      <span className='font-weight-600'> &#x3C; 1 </span> min
      </>)
    }
    const parsedDuration=secondsToDuration(duration);
    let [h,m,s]=parsedDuration.split(" : ").map(i=>Number(i));//[h,m,s]
    if(s>=30){
      m+=1;//rounding off minutes wrt seconds
    } 
    const unitH=!!list?'h':pluralize(h," hr");
    const unitM=!!list?'m':pluralize(m," min");
    if(!!h && !!m){
      return(<>
        <span className='font-weight-600'>{h}</span>
        {unitH} &nbsp; 
        <span className='font-weight-600'>{m}</span> 
        {unitM}
        </>)
    }
    return(<>
    {!h && <><span className='font-weight-600'>{m}</span> {unitM}</>}
    {!m && <><span className='font-weight-600'>{h}</span> {unitH}</>}</>)
  },[duration])
  return <>{str}</>;
}
//Editor Duration format: h:mm:ss || m:ss
export const durationToColonString=(duration)=>{
  const _duration=secondsToDuration(duration);
  const [h,m,s]=_duration.split(" : ");//[h,m,s]
  let str;
  if(duration>=3600){
    str=Number(h)+":"+m+":"+s;
  }
  else{
    str=Number(m)+":"+s;
  }
  return str;
}

export const durationToUserSchCardFormat=(done,todo)=>{
  //todo
  const _todoDuration=secondsToDuration(todo);
  const [th,tm,ts]=_todoDuration.split(" : ").map(i=>Number(i));//[h,m,s]
  //done
  const _doneDuration=secondsToDuration(done);
  const [dh,dm,ds]=_doneDuration.split(" : ").map(i=>Number(i));//[h,m,s]

  if(th||dh){
    const todoInHr=roundNumber(todo/3600);
    const doneInHr=done?roundNumber(done/3600):0;
    return{
      done:doneInHr,
      todo:todoInHr,
      heading:"Hrs"
    }
  }
  else if(tm||dm){
    const todoInHr=roundNumber(todo/60);
    const doneInHr=done?roundNumber(done/60):0;
    return{
      done:doneInHr,
      todo:todoInHr,
      heading:"Mins"
    }
  }
  else{
    return{
      done:done||0,
      todo:todo,
      heading:"Secs"
    } 
  }
}
