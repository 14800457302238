import { useEffect, useRef } from 'react';


const AudioTrack = ({ track }) => {
  const audioEl = useRef();

  useEffect(() => {
    audioEl.current = track.attach();
    return () => track.detach().forEach(el => el.remove());
  }, [track]);

  return null;
}

export default AudioTrack;
