import _ from "lodash";
import React, {useContext,useMemo, useEffect,useState} from "react";
import {
  Card,
  CardHeader,
  Typography,
  CardContent,
  IconButton,
  Chip,
  Grid,
  Divider,
} from "@material-ui/core";
import {MenuList} from "fitbud/components/menuList";
import { MoreLessTextBtn } from "fitbud/components/moreLessText";
import {DraftBanner} from "fitbud/components/draftBanner";
import {Avatar} from "fitbud/components/avatar";
import CopyAction from "fitbud/components/copyAction";
import DeleteAction from "fitbud/components/deleteAction";
import VideoPlayer from "fitbud/components/videoPlayer";
import { DRAFT,FILE_STATUS,PUBLISHED,TO_PUBLISH_WARNING_MSG } from "fitbud/utils/constants";
import { RoleContext } from "fitbud/providers/roleProvider";
import { TagsContext } from 'fitbud/providers/tagsProvider';
import {disabledIconDuringUpload, roundMacros} from "fitbud/utils/helpers";
import DetailEditIcon from "fitbud/icons/detailEdit";
import clsx from 'clsx';
import {GetDetailSecLine} from "fitbud/components/catalog/detailSecondLine";
import {MediaPlaceholder} from "fitbud/components/mediaPlaceholder";
import moment from 'moment';
import {EditorPlaceholder} from "fitbud/views/exercises/editorPlaceholder";
import addIcon from "fitbud/images/plus.svg";
import {videoExists,mediaObjExist,durationToHMString} from "./helperfn";
import Confirmation from "fitbud/components/confirmationDialog";
import {PublishAlert} from "fitbud/components/publishAlert";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import OptionsIcon from "fitbud/icons/verticalOptions"
import { PlayIcon } from 'fitbud/icons/play';

const DEFAULT_WO_TYPE='Workout';
const META="meta";
const EDITOR="editor";

function Details({ id, doc,summary, openMetaForm,targetAreasData, isPreview, 
  copyItem, disableActions, deleteItem,
  draftBannerProps={},
  editMode,openExEditor,
  handleEditMode,showOnlyEdit,uploadingError={},videoStatusRefresh,draftDoc,
  isDraftAvailable,
  stopPublishPipeline,
  ...rest }) {
  const { tagName } = useContext(TagsContext);
  const [state,updateState]=useState({duration:0,bodyPart:[]});
  const [showProgressWarning,toggleProgressWarning]=useState(null);//[null,'meta','editor']
  const [stopPublishLoader,toggleStopPublishLoader]=useState(false);
  const [isDeleteConfirmation, toggleDeleteConfirmation] = useState(false);

  const confirmDialogTitle = 'Delete workout';
  const confirmDialogMsg = 'Are you sure you want to delete the workout?'

  const handleEdit=(mode)=>{
    if(isDraftAvailable && !!_.get(draftDoc,'to_publish',false)){
      toggleProgressWarning(mode);
      return;
    }
    confirmEdit(mode);
  }
  const closeEditPopup=()=>{
    toggleProgressWarning(null);
  }
  const confirmEdit=async(mode=showProgressWarning)=>{
    if(isDraftAvailable && _.get(draftDoc,'to_publish',false)){
      toggleStopPublishLoader(true);
      const draftDoc=await stopPublishPipeline();
      toggleStopPublishLoader(false);
      if(!draftDoc) {
        return;
      }
    }
    mode===META?openMetaForm():openExEditor();
    closeEditPopup();
  }
  useEffect(()=>{
    if(!doc.is_single_video){
      const {calculated,override,flags}=summary||{};
      const duration=flags['duration']?override.duration:calculated.duration;
      const bodyPartSelected=((flags['body_parts']?override.bodyPartObj:calculated.bodyPartObj)||[]).map(i=>i.label)
      //Note: Summary is utilising 'target_area` from wo doc. No change needed here
      const newState={
        duration:(duration||0),
        bodyPart:bodyPartSelected
      };
      updateState({...newState});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[summary])
  useEffect(()=>{
    if(!!doc.is_single_video){
      const bodyPart=[];
      doc.target_area.forEach(i=>{
        const found=targetAreasData.find(j=>j.value===i);
        if(!!found && !!found.label){
          bodyPart.push(found.label);
        }
      })
      const newState={
        duration:doc.duration,
        bodyPart
      }
      updateState({...newState});
    }
  },[doc.duration,doc.target_area,targetAreasData])
  const { catalogAdmin } = useContext(RoleContext);
 
  const type = useMemo(() => {
    return tagName('woSubTypes', doc.sub_type, doc.sub_type || DEFAULT_WO_TYPE);
  }, [doc.sub_type, tagName]);
  const secondLine=GetDetailSecLine('workout',doc);
  const _description=_.get(doc,'desc','').trim();
  const isDraft=draftBannerProps.showingDoc===DRAFT;
  const to_publish=_.get(doc,'to_publish',false);
  const duration=useMemo(()=>durationToHMString(state.duration),[state.duration])
  const [minHR, maxHR] = doc.target_hr || [];
  const menuOptions = useMemo(() => {
    let options = [{label: "Download as PDF", value: 'pdf'}]
    if(catalogAdmin && !showOnlyEdit && !isDraft) {
      options.unshift({label: "Delete", value: "delete"})
    }
    return options
  }, [catalogAdmin, showOnlyEdit, isDraft])

  function handleOptionClick(e, action) {
    switch(action) {
      case 'delete': 
        toggleDeleteConfirmation(true)
        break;
      case 'pdf':
        window.print()
        break;
      default:
    }
  }

  useEffect(() => {
    let title;
    function handleBeforePrint() {
      title = document.title;
      document.title = doc?.title;
    }
    function handleAfterPrint() {
      document.title = title;
    }
    window.addEventListener('beforeprint', handleBeforePrint);
    window.addEventListener('afterprint', handleAfterPrint);

    return () => {
      window.removeEventListener('beforeprint', handleBeforePrint);
      window.removeEventListener('afterprint', handleAfterPrint);
    };
  }, [doc.title]);
  
  return (
    <>
    {draftBannerProps.isDraftAvailable && !isPreview && <DraftBanner {...draftBannerProps} doc={doc}/>}
      <Card square elevation={0}>
        {to_publish && <PublishAlert to_publish={to_publish}/>}
        <CardHeader
          title={doc.title}
          subheader={secondLine}
          avatar={
            <Avatar
              src={doc.thumbnail}
              name={doc.title}
              size="large"
              alt={doc.title || " "}
            />
          }
          action={
            isPreview ? null : (
              <div className="d-print-none">
                <IconButton onClick={()=>handleEdit(META)}>
                  <DetailEditIcon />
                </IconButton>
                {(!showOnlyEdit && !isDraft) && <CopyAction
                  title="Workout"
                  hide={disableActions}
                  onCopy={copyItem}
                  isDisabled={disabledIconDuringUpload(doc, uploadingError.media_bgon||uploadingError.media_bgoff)}
                />}
                <MoreOption
                  disabled={disableActions}
                  options={menuOptions}
                  handleClick={handleOptionClick}
                />
              </div>
            )
          }
          classes={{
            root: "main-header-root",
            title: "main-title",
            subheader: !secondLine && "d-none",
          }}
        />
        <CardContent>
          {_description && <MoreLessTextBtn text={_description} className="desc fmb-25" btnClassName="viewMore"/>}
          <Grid container>
            {/* type */}
            <Grid item xs={4}>
              <Typography className="fmb-10 field-title">Type</Typography>
              <Typography className="field-value">
                {type}
              </Typography>
            </Grid>
            {/* time */}
            <Grid item xs={4}>
              <Typography className="fmb-10 field-title">Duration</Typography>
              <Typography className="field-value">
                {duration}
              </Typography>
            </Grid>
            {/* intensity */}
            <Grid item xs={4}>
              <Typography className="fmb-10 field-title">Intensity</Typography>
              <Typography className="field-value">
                {_.capitalize(doc.intensity || "-")}
              </Typography>
            </Grid>
            <Grid item xs={4} className="fmt-25">
              <Typography className="fmb-10 field-title">Target Calories</Typography>
              <Typography className="field-value">
                {doc.target_cals === 0 || !!doc.target_cals ? `${roundMacros(doc.target_cals)} cals` : "Not Specified"}
              </Typography>
            </Grid>
            <Grid item xs={4} className="fmt-25">
              <Typography className="fmb-10 field-title">Target Heart Rate</Typography>
              <Typography className="field-value">
                {minHR && maxHR ? `${minHR} - ${maxHR}` : (!!minHR && minHR) || (!!maxHR && maxHR)} {minHR || maxHR ? "bpm" : "Not Specified"}
              </Typography>
            </Grid>
            {/* target-areas */}
            <Grid item xs={12} className="fmt-25">
              <Typography
                className={clsx(
                  !state.bodyPart.length && "fmb-10",
                  "field-title"
                )}
              >
                Target Area
              </Typography>
              <Typography
                className={clsx(!state.bodyPart.length && "field-value")}
              >
                {state.bodyPart.length
                  ? state.bodyPart.map((i) => (
                      <Chip className="chips fmt-10 fmr-10 p-exact" key={i} label={i} />
                    ))
                  : "Not Specified"}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {doc.is_single_video && <>
         <Divider className="dense" />
         <LongWoDetail 
          doc={doc} 
          editMode={editMode} 
          isPreview={isPreview} 
          handleEdit={()=>handleEdit(EDITOR)} 
          videoStatusRefresh={videoStatusRefresh}  
          uploadingError={uploadingError}
          {...rest}
          />
      </>}
      {showProgressWarning && <Confirmation
       open
       handleClose={closeEditPopup}
       handleChange={()=>confirmEdit()}//intentional syntax(check default param in confirmEdit)
       handleCancel={closeEditPopup}
       handle
       title="Warning"
       msg={TO_PUBLISH_WARNING_MSG}
       confirmOption="Yes, Continue"
       loading={stopPublishLoader}
      />}
      {isDeleteConfirmation && (
        <Confirmation
          open={true}
          handleChange={deleteItem}
          handleCancel={() => toggleDeleteConfirmation(false)}
          title={confirmDialogTitle}
          msg={confirmDialogMsg}
          confirmOption="Yes, Delete"
        />
      )}
    </>
  );
}

export default Details;

const LongWoDetail=({doc,isPreview,handleEdit,uploadingError={},videoStatusRefresh,editMode})=>{
  const media=_.get(doc,"media.0",{})||{};
  const media_bgoff=_.get(doc,"media_bgoff",{})||{};
  const noMediaAvlbl=!videoExists(media) && !videoExists(media_bgoff);

  const {comp}=useContext(FirebaseAuthContext);
  let features=useMemo(()=>{
    if(comp && comp.exists && comp.data){
      return comp.data().features||{};
    }
    return {};
  },[comp])
  const showPrimaryVideo=useMemo(()=>{
    return (!!mediaObjExist(media)||uploadingError.media_bgon);
  },[media,uploadingError]);
  const showSecondaryVid=useMemo(()=>{
    return !!(features.bgoff && _.get(doc, "media.0.type") !== "youtube" && (mediaObjExist(media_bgoff))||uploadingError.media_bgoff)
  },[_.get(doc,'media.0.type',""),features.bgoff,media_bgoff]);
  const isPVideoSuccessful=useMemo(()=>{
    return (videoExists(media) && media.status!==FILE_STATUS.error && !uploadingError.media_bgon);
  },[media,uploadingError]);
  const isSVideoSuccessful=useMemo(()=>{
    return (videoExists(media_bgoff) && media_bgoff.status!==FILE_STATUS.error && !uploadingError.media_bgoff);
  },[media_bgoff,uploadingError]);
  const [isPVideoPause, setPVideoPause ] = useState(false);//primary video
  const [isSVideoPause, setSVideoPause ] = useState(false);//secondary video
  useEffect(()=>{
    if(!!editMode){
      setPVideoPause(true);
      setSVideoPause(true);
    } 
  },[editMode])
  return (
    <Card square elevation={0}>
      <CardHeader
        title="Media"
        action={
          isPreview ? null : (
            <IconButton onClick={handleEdit} className="d-print-none">
              {!noMediaAvlbl ? (
                <DetailEditIcon />
              ) : (
                <img src={addIcon} alt="" />
              )}
            </IconButton>
          )
        }
        classes={{
          root: "sub-header-root",
          title: "sub-title",
          action: "margin-top-negative",
        }}
      />
      <CardContent>
        {noMediaAvlbl ? (
          <EditorPlaceholder
            keyName="workout-video-placeholder"
          />
        ) : (
          <div className="d-flex flex-column" style={{ gap: 20 }}>
            <div className="d-flex flex-wrap" style={{ gap: 20 }}>
              {/*---- media -----*/}
              {showPrimaryVideo && (
                <div>
                 {features.bgoff && <Typography color="primary" className="mb-10 font_13_600">
                    WITH MUSIC
                  </Typography>}
                  {isPVideoSuccessful? (
                    <div className="media-container media-video position-relative">
                    <img
                        src={media.thumbnailUrl}
                        alt="print images"
                        className="w-100 position-absolute h-100 d-none d-print-block"
                        style={{ objectFit: 'cover' }}
                      />
                      <PlayIcon
                        style={{ width: 'unset', height: 'unset' }}
                        className="position-absolute d-none d-print-block"
                      />
                      <VideoPlayer
                        url={media.url || media.s3Url}
                        mediaType={media.type}
                        thumbnailUrl={media.thumbnailUrl}
                        pause={isPVideoPause}
                        aspectRatio={
                          doc.is_crop_square
                            ? 1 / 1
                            : !!parseInt(media.videoHeight) &&
                              !!parseInt(media.videoWidth)
                            ? parseInt(media.videoWidth) /
                              parseInt(media.videoHeight)
                            : 0
                        }
                        playerProps={{
                          onPause: () => {
                            setPVideoPause(true);
                          },
                          onPlay: () => {
                            setPVideoPause(false);
                          },
                        }}
                      />
                    </div>
                  ):(
                    <MediaPlaceholder
                      media={media}
                      uploadingError={uploadingError.media_bgon}
                      onRefresh={()=>videoStatusRefresh("media_bgon")}
                    />
                  )}
                  </div>
              )}
              { showSecondaryVid && (
                <div>
                  <Typography color="primary" className="font_13_600 mb-10">
                    WITHOUT MUSIC
                  </Typography>
                  {isSVideoSuccessful ? (
                    <div className="media-container media-video position-relative">
                    <img
                        src={media_bgoff.thumbnailUrl}
                        alt="print images"
                        className="w-100 position-absolute h-100 d-none d-print-block"
                        style={{ objectFit: 'cover' }}
                      />
                      <PlayIcon
                        style={{ width: 'unset', height: 'unset' }}
                        className="position-absolute d-none d-print-block"
                      />
                      <VideoPlayer
                        url={media_bgoff.url || media_bgoff.s3Url}
                        mediaType={media_bgoff.type}
                        thumbnailUrl={media_bgoff.thumbnailUrl}
                        pause={isSVideoPause}
                        aspectRatio={
                          doc.is_crop_square
                            ? 1 / 1
                            : !!parseInt(media_bgoff.videoHeight) &&
                              !!parseInt(media_bgoff.videoWidth)
                            ? parseInt(media_bgoff.videoWidth) /
                              parseInt(media_bgoff.videoHeight)
                            : 0
                        }
                        playerProps={{
                          onPause: () => {
                            setSVideoPause(true);
                          },
                          onPlay: () => {
                            setSVideoPause(false);
                          },
                        }}
                      />
                    </div>
                  ) : (
                    <MediaPlaceholder
                      media={media_bgoff}
                      uploadingError={uploadingError.media_bgoff}
                      onRefresh={()=>videoStatusRefresh("media_bgoff")}
                    />
                  )}
                </div>
              )}
            </div>
            <SingleVideoMeta doc={doc} />
          </div>
        )}
      </CardContent>
    </Card>
  );
}
const SingleVideoMeta=({doc})=>{
  const intro_ends_at={
    hour:moment.utc(doc.intro_duration * 1000).hours(),
    min:moment.utc(doc.intro_duration * 1000).minutes(),
    sec:moment.utc(doc.intro_duration * 1000).seconds()
  };
  const warmup_ends_at={
    hour:moment.utc((doc.warmup_duration+doc.intro_duration) * 1000).hours(),
    min:moment.utc((doc.warmup_duration+doc.intro_duration) * 1000).minutes(),
    sec:moment.utc((doc.warmup_duration+doc.intro_duration) * 1000).seconds()
  }
  const cooldown_ends_at={
    hour:moment.utc((doc.total_duration-doc.outro_duration) * 1000).hours(),
    min:moment.utc((doc.total_duration-doc.outro_duration) * 1000).minutes(),
    sec:moment.utc((doc.total_duration-doc.outro_duration) * 1000).seconds()
  }
  return(
    <div className="d-flex w-75">
      {!!doc.intro_duration && <div className="flex-1">
        <Typography className="field-title mb-10">Intro</Typography>
        <Typography className="fmb-25 field-value">
            <>
              {intro_ends_at.hour ? <span>{intro_ends_at.hour} hr  </span>:null}
              {intro_ends_at.min ? <span>{intro_ends_at.min} min  </span>:null}
              {((!intro_ends_at.hour && !intro_ends_at.min)||!!intro_ends_at.sec) && <span>{intro_ends_at.sec} sec  </span>}
            </>
        </Typography>
      </div>}
     {!!doc.warmup_duration && <div className="flex-1">
        <Typography className="field-title mb-10">Warmup</Typography>
        <Typography className="fmb-25 field-value">
          <>
            {warmup_ends_at.hour ? <span>{warmup_ends_at.hour} hr  </span>:null}
            {warmup_ends_at.min ? <span>{warmup_ends_at.min} min  </span>:null}
            {((!warmup_ends_at.hour && !warmup_ends_at.min)||!!warmup_ends_at.sec) && <span>{warmup_ends_at.sec} sec  </span>}
          </>
        </Typography>
      </div>}
      {!!doc.outro_duration && <div className="flex-1">
        <Typography className="field-title mb-10">Cooldown</Typography>
        <Typography className="fmb-25 field-value">
          <>
            {cooldown_ends_at.hour ? <span>{cooldown_ends_at.hour} hr  </span>:null}
            {cooldown_ends_at.min ? <span>{cooldown_ends_at.min} min  </span>:null}
            {((!cooldown_ends_at.hour && !cooldown_ends_at.min)||!!cooldown_ends_at.sec) && <span>{cooldown_ends_at.sec} sec  </span>}
          </>
        </Typography>
      </div>}
  </div>
  )
}

function MoreOption(props) {
  const { className, style } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  let disabled = props.disabled;
  const handleClick = e => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleMenuItemClick(...args) {
    handleClose()
    props.handleClick(...args)
  }

  if (!!disabled) return null;
  return (
    <>
      <IconButton onClick={handleClick} className={className} style={style}>
        <OptionsIcon />
      </IconButton>
      <MenuList
        anchorEl={anchorEl}
        onClose={handleClose}
        options={props.options}
        handleClick={handleMenuItemClick}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      />
    </>
  );
}