import React from 'react';
import { DialogTitle, Toolbar, AppBar, Typography, IconButton, Divider } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseRounded';
import SearchInput from 'fitbud/components/catalogSelector/selectorInput';
import BackIcon from '@material-ui/icons/KeyboardBackspace';

const SelectorHeader = (props) => {
  const { handleClose, headerActions, keyName, hideLibrarySwitch = false, title, isFilter = true, disableFilter = false, hideSearch = false, isBack } = props;
  return (
    <DialogTitle className="fp-0" disableTypography>
      <AppBar position="sticky">
        <Toolbar className="fpx-20 fpy-10  flex-column align-items-start">
          <div className="d-flex align-items-center w-100">
            <IconButton className="fmr-5" edge="start" onClick={handleClose}>
              {isBack ? <BackIcon className={'text-black'} /> : <CloseIcon className={'text-black'} />}
            </IconButton>
            <Typography variant="h3">{title || `Select ${keyName}`}</Typography>
            <div className="flex-grow-1" />
            {headerActions && headerActions()}
          </div>
          {!hideSearch && (
            <div className="w-100 fmt-10">
              <SearchInput
                hideLibrarySwitch={hideLibrarySwitch}
                keyName={keyName}
                isFilter={isFilter}
                disableFilter={disableFilter}
              />
            </div>
          )}
        </Toolbar>
        <Divider />
      </AppBar>
    </DialogTitle>
  );
};

export default SelectorHeader;
