import React from "react";
import NumberInput from "fitbud/components/numUnitInput";
import {DurationWrapper} from "fitbud/views/workouts/components/amrapDurationInput";

export default function ExerciseTypeSubTypeForm(props) {
  const {
    unit,
    sub_type,
    sets,
    handleChange,
    values,
    loading,
    errors,
    disabled
  } = props;

  let numFields = 0;
  switch (sub_type) {
    case "fixed":
      numFields = 1;
      break;
    case "perset":
      numFields = sets || 0;
      break;
    case "range":
      numFields = 2;
      break;

    default:
      break;
  }

  let boxStyles = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit,100%)"
  };
  if (numFields === 2) {
    boxStyles = {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fit, calc(100%/2 - 10px))"
    };
  } else if (numFields > 2) {
    boxStyles = {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fit, calc(100%/3 - 10px))",
      gridRowGap:"10px"
    };
  }

  let label = '', _unit = '';
  // eslint-disable-next-line default-case 
  switch(unit) {
    case 'duration':
      label = 'Duration';
      _unit = 'sec';
      break;
    case 'reps':
      label = 'Reps';
      _unit = 'reps';
      break;
    case 'rest':
      label = 'Rest';
      _unit = 'sec';
      break;
    case 'weight':
      label = 'Weight';
      _unit = 'weight';
    case 'equipment-weight' :
      label = 'Weight';
      _unit = 'equipment-weight';
      break;
  }

  return (
    <div className="justify-content-between fmb-25" style={boxStyles}>
      {[...Array(numFields)].map((f, i) => {
        const _label=sub_type === 'range' ? ['Min', 'Max'][i] : (sub_type === 'perset' ? `Set ${i+1}` : label)
        if(unit==="duration" || unit==="rest"){
          return(
            <DurationWrapper
              name={`values_${i}`}
              value={values[i]||0}
              handleChange={handleChange}
              label={_label}
              hideHr={true}
              amrap={false}
              classes={{root:"small d-flex justify-content-center",input:"small size_16_600"}}
              type="displayValue"
              error={!!errors[i]}
            />
          )
        }
        return (
          <NumberInput
              id="outlined-bare"
              label={sub_type === 'range' ? ['Min', 'Max'][i] : (
                sub_type === 'perset' ? `Set ${i+1}` : label
              )}
              unit={_unit}
              name={`values_${i}`}
              value={values[i]}
              error={!!errors[i]}
              variant="outlined"
              disabled={loading || disabled}
              onChange={handleChange}
              required
              InputProps={{classes: {root: "medium",input: "size_16_500 medium"}}}
              LabelProps={{classes: {control:'fp-0',label: 'fmb-5', labelTxt: 'font_13_500'}}}
            />
        );
      })}
    </div>
  );
}
