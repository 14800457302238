import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const CameraIcon = props => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 20"
    width="20" height="20"
    classes={{
      root: "small"
    }}
  >
    <g fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.1666 15.8333C19.1666 16.2754 18.991 16.6993 18.6784 17.0118C18.3659 17.3244 17.9419 17.5 17.4999 17.5H2.49992C2.05789 17.5 1.63397 17.3244 1.32141 17.0118C1.00885 16.6993 0.833252 16.2754 0.833252 15.8333V6.66667C0.833252 6.22464 1.00885 5.80072 1.32141 5.48816C1.63397 5.17559 2.05789 5 2.49992 5H5.83325L7.49992 2.5H12.4999L14.1666 5H17.4999C17.9419 5 18.3659 5.17559 18.6784 5.48816C18.991 5.80072 19.1666 6.22464 19.1666 6.66667V15.8333Z" stroke="#0D0D0D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10.0001 14.1667C11.841 14.1667 13.3334 12.6743 13.3334 10.8333C13.3334 8.99238 11.841 7.5 10.0001 7.5C8.15913 7.5 6.66675 8.99238 6.66675 10.8333C6.66675 12.6743 8.15913 14.1667 10.0001 14.1667Z" stroke="#0D0D0D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
  </SvgIcon>
);
