import { MenuItem, OutlinedInput, Select, TextField } from '@material-ui/core';
import DropDownIcon from "@material-ui/icons/ExpandMore";
import { DatePicker } from '@material-ui/pickers';
import firebase from 'firebase';
import Confirmation from 'fitbud/components/confirmationDialog';
import Dialog from 'fitbud/components/Dialog';
import { FormTextField } from 'fitbud/components/form-fields';
import { CalendarIcon } from 'fitbud/icons/calendarIconAlt';
import { Conversion } from 'fitbud/providers/conversion';
import appRdxFns from "fitbud/redux/app";
import { DEFAULT_ERROR, oprtnlDateFormat } from 'fitbud/utils/constants';
import { range } from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useContext, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { calculateValues } from '../subComponents';

const FEET_OPTIONS = range(0, 10, 1);
const INCH_OPTIONS = range(0, 12, 1);
const convertHeightToDecimal = ({ft, inch}) => ft+inch/12;


const EditProfileDialog = ({
  open=false,
  onClose,
  userDoc={},
  docId='',
  updateUserDoc,
  unitSystem,
  unitDetails,
  measurableUnits,
}) => {
  const { nick_name='', profile={} } = userDoc || {};
  const { name='', dob='', gender='', height='', weight='' } = profile || {};
  const d = useDispatch();
  const { convertor } = useContext(Conversion);
  const { showLoader, hideLoader } = appRdxFns(d);
  const { enqueueSnackbar } = useSnackbar();

  // Height Logic
  const {unit: heightUnit='', value: heightValues=[0,0] } = {} = useMemo(() => !height || height === 0 ? 
  { unit: convertor.getDisplayUnit('height', height), value: [0, 0]} :
   calculateValues({
    value: height,
    unitSystem,
    unitDetails,
    measurableUnits,
    unit: "height",
    showNothingWhenEmpty: true,
    returnValues: true,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [height]);
 
  // Local State Vars
  const [isDirty, setDirty] = useState(false);
  const [errors, setErrors] = useState({});
  const [heightData, setHeightData] = useState(heightUnit !== 'cm' ?
    {ft: heightValues[0], inch: heightValues[1]}
  : height);
  const [data, setData] = useState({
    name, dob, gender, height: convertor.getDisplayValue('height', height), nick_name, 
    weight: convertor.getDisplayValue('weight', weight),
  });
  const validate = () => {
    return true;
  };
  const handleChange = (e) => {
    setDirty(true);
    setErrors({});
    const { name, value } = e.target;
    if(heightUnit !== 'cm' && (name === 'ft' || name === 'inch') ){
      return setHeightData(prev => ({...prev, [name]: value }));
    };
    setData({...data, [name]: value });
  };
  const handleDateChange = (date) => {
    setDirty(true);
    let newDate = moment(date, 'MMMM Do YYYY').format(oprtnlDateFormat)
    setData({...data, dob:newDate});
  };
  const handleSave = () => {
    if(!validate()) return;
    const finalHeight = heightUnit !== 'cm' ? convertHeightToDecimal(heightData) : data.height;
    const {nick_name, ...rest} = data;
    const changes = {profile: { ...userDoc.profile, ...rest,
      ...data, name: data.name.trim(),  height: convertor.getDBValue('height', finalHeight),
      weight: convertor.getDBValue('weight', data.weight),
    }};
    if (nick_name) changes.nick_name = nick_name.trim();
    const out = {...userDoc, nick_name: data.nick_name.trim(), profile: changes.profile};
    showLoader();
    firebase.firestore().doc(`user_profiles/${docId}`)
      .update(changes)
      .then((res) => {
        enqueueSnackbar("Changes Saved Successfully", { variant: 'success' });
        updateUserDoc({...out});
        onClose();
      }).catch((err)=> {
        enqueueSnackbar(DEFAULT_ERROR, { variant: 'success' });
      }).finally(() => {
          hideLoader();
      });
  };
  return (
    <Dialog 
      open={!!open} maxWidth='sm' fullWidth title='Edit Profile' titleFont="h3"
      appBarColor="bg-body"
      buttonColor="primary"
      buttonSize="f-small"
      toolbarClass="height-60"
      onClose={onClose}
      isDirty={isDirty}
      withConfirmation
      onSave={handleSave}
    >
      <div className='fpx-20 fpt-20'>
        <FormTextField labelVariant={'subtitle1'} fullWidth label="Name">
          <TextField
            id="name"
            name='name'
            autoComplete="off"
            value={data.name}
            onChange={handleChange}
            error={!!errors.name}
            helperText={errors.name}
            variant="outlined"
            type="text"
            InputProps={{
              classes: {
                root: "medium",
                input: "size_15_500 medium"
              }
            }}
          />
        </FormTextField>
        <FormTextField labelVariant={'subtitle1'} fullWidth label="Nick Name">
          <TextField
            id="nick_name"
            name='nick_name'
            autoComplete="off"
            placeholder='Enter Nick Name . . .'
            value={data.nick_name}
            onChange={handleChange}
            error={!!errors.nick_name}
            helperText={errors.nick_name}
            variant="outlined"
            type="text"
            InputProps={{
              classes: {
                root: "medium",
                input: "size_15_500 medium"
              }
            }}
          />
        </FormTextField>
      </div>
      <div className='fpx-20'>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='w-100 fpr-10'>
            <DatePicker
              fullWidth
              allowKeyboardControl
              animateYearScrolling={false}
              invalidLabel={false}
              invalidDateMessage={''}
              initialFocusedDate={null}
              emptyLabel={'Select Date'}
              onChange={handleDateChange}
              value={data.dob || null}
              style={{color: '#DDD'}}
              name="dob"
              format='DD MMM, YYYY'
              inputVariant="outlined"
              TextFieldComponent={(props) => (
                <FormTextField fullWidth label={"Date of Birth"} labelVariant={'subtitle1'}>
                  <TextField {...props} variant='outlined' InputProps={{
                    inputProps: { className: 'cursor-pointer' },
                    classes: { root: "medium", input: 'size_15_500 medium' },
                    style: { color: props.value === 'Select Date' && '#aaa' },
                    endAdornment: <CalendarIcon />
                  }} />
                </FormTextField>
              )}
            />
          </div>
          <div className='w-100 fpl-10'>
            <FormTextField labelVariant={'subtitle1'} fullWidth label="Gender">
              <Select
                fullWidth
                displayEmpty
                value={data.gender}
                onChange={handleChange}
                IconComponent={DropDownIcon}
                input={
                  <OutlinedInput
                    classes={{ root: "medium", input: "size_15_500 select-medium" }}
                    name={'gender'} id='gender'
                  />
                  }>
                <MenuItem disabled value={''}><div className='text-grey'>Select Gender</div></MenuItem>    
                <MenuItem value={'Male'}>Male</MenuItem>
                <MenuItem value={'Female'}>Female</MenuItem>
              </Select>
            </FormTextField>
          </div>
        </div>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='w-100 fpr-10'>
            <FormTextField labelVariant={'subtitle1'} fullWidth label="Height">
              {heightUnit === 'cm' ? (
                <TextField
                  id="height"
                  name='height'
                  autoComplete="off"
                  onKeyDown={e => {
                    if (e.key === ".") {
                      e.preventDefault();
                    }
                  }}
                  value={(data.height) || ''}
                  onChange={handleChange}
                  error={!!errors.height}
                  helperText={errors.height}
                  variant="outlined"
                  required
                  type="number"
                  InputProps={{
                    classes: {
                      root: "medium",
                      input: "size_15_500"
                    },
                    endAdornment: <div className='font_15_500'>{heightUnit}</div>
                  }}
                />
              ) : (
                <div className='d-flex align-items-center justify-content-center' >
                  <Select
                    fullWidth
                    value={heightData['ft']}
                    className='fmr-5'
                    error={errors.height}
                    onChange={handleChange}
                    IconComponent={() => null}
                    input={
                      <OutlinedInput
                        classes={{ root: `medium`, input: "size_15_500 select-medium" }}
                        name={'ft'} id='ft' endAdornment={<div className='font_15_500'>{'ft'}</div>}
                      />
                    }>
                    {FEET_OPTIONS.map((v, i) => <MenuItem value={v} key={`feet-${v}`}>{v}</MenuItem>)}
                  </Select>
                  <Select
                    fullWidth
                    value={heightData['inch']}
                    error={errors.height}
                    className='fml-5'
                    onChange={handleChange}
                    IconComponent={() => null}
                    input={
                      <OutlinedInput
                        classes={{ root: "medium", input: "size_15_500 select-medium" }}
                        name={'inch'} id='inch' endAdornment={<div className='font_15_500'>{'in'}</div>}
                      />
                    }>
                    {INCH_OPTIONS.map((v, i) => <MenuItem value={v} key={`feet-${v}`}>{v}</MenuItem>)}
                  </Select>
                </div>
              )}
            </FormTextField>
          </div>
          <div className='w-100 fpl-10'>
            <FormTextField labelVariant={'subtitle1'} fullWidth label="Weight">
              <TextField
                id="weight"
                name='weight'
                autoComplete="off"
                value={(data.weight) || ''}
                onChange={handleChange}
                error={!!errors.weight}
                helperText={errors.weight}
                variant="outlined"
                required
                type="number"
                InputProps={{
                  classes: {
                    root: "medium",
                    input: "size_15_500",
                  },
                  endAdornment: <div className='font_15_500'>{convertor.getDisplayUnit('weight')}</div>
                }}
              />
            </FormTextField>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default EditProfileDialog;
