import React, { useState, useMemo, useRef, useLayoutEffect } from 'react';
import { Typography, Link } from '@material-ui/core';
import { useToggle } from 'fitbud/hooks/form';
import clsx from 'clsx';

const LINE_LIMIT = 2;
export const MoreLessTextBtn = ({ text, lines = LINE_LIMIT, className,btnClassName, ...restProps }) => {
  const [open, toggle, setOpen] = useToggle(true);
  const [needed, setNeeded] = useState(false);
  const ref = useRef();
  const strippedTxt = useMemo(() => {
    if (!text) return '';
    return text.replace(/\n+/g, ' ');
  }, [text]);
  useLayoutEffect(() => { // only supposed to run when the prop.text changes
    const div = ref.current;
    if (!div) return;
    setOpen(true);
    window.setTimeout(() => {
      const needed = div.clientHeight > lines * 15 * 1.5;
      setNeeded(needed);
      setOpen(!needed);
    }, 0);
  }, [text]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Typography component='div' variant='body2' ref={ref}
      className={`${className || ''} position-relative overflow-hidden white-space-preline font_15_500 lineht_1_5 p-height-auto p-position-unset`}
      {...restProps}
      style={{ height: open ? 'unset' : (lines * 15 * 1.5 - 1) }}>
      {open ? text : strippedTxt}
      {needed && <Link underline='none' component='button' onClick={toggle}
        className={clsx("bg-white font_15_500 lineht_1_5",open?"d-block":"position-absolute inset-bottom-right mb-3px d-print-none",btnClassName)}>
        {open ? 'view less' : '\u00A0... view more'}
      </Link>}
    </Typography>
  );
};
