import React from 'react';
import clsx from 'clsx';
import Fitness from './fitness';
import Nutrition from './nutrition';
import CheckIns from './checkins';
import Water from './water';
import Measurements from './measurements';
import { useReminder } from 'fitbud/views/reminders/provider';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  reminderSwitch: {
    width: 28,
    height: 16,
    padding: 0,
    margin: 12,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: '#1890ff',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }
}))

const ReminderViews = (props) => {
  const { className } = props;
  const { toggleCategory } = useReminder();
  const handleToggle = (item) => toggleCategory(item);

  return (
    <div className={clsx(className, 'px-20 pt-10 pb-20')}>
      <Fitness onToggle={handleToggle} />
      <Nutrition onToggle={handleToggle} />
      <Water onToggle={handleToggle} />
      <CheckIns onToggle={handleToggle} />
      <Measurements onToggle={handleToggle} />
    </div>
  );
};

export default ReminderViews;
