import _ from "lodash";
import React, {useMemo} from "react";
import Select from "react-select";
import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';
import { Typography,IconButton,makeStyles } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/ClearRounded';

const useStyles = makeStyles(() => ({
  error: {
    marginLeft: "14px",
    marginRight: "14px",
    fontSize: "0.75rem",
    fontWeight: 400
  },
}));

function getBorderColor(state, error) {
  if(state.isFocused) return "#4089f7 !important"
  if(error) return "#f44336 !important"
  return "#D3D9DB"
}

const AutoSuggest = ({ options, onChange, value, placeholder = "", isMulti=true, error = "", noSort, disabled,...rest }) => {
  const classes = useStyles();
  const sortedOptions = useMemo(() => noSort ? options : _.sortBy(options, ['label']), [options, noSort]);
  return (
    <>
      <CustomComponent {...rest}
        isDisabled={disabled}
        placeholder={<Typography variant="h4">{placeholder}</Typography>}
        isClearable={false}
        value={value}
        onChange={onChange}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        styles={{
          control: (base, state) => {
            const borderColor = getBorderColor(state, error);

            return {
              ...base,
              minHeight: 48,
              color:"rgba(0, 0, 0, 0.23)",
              borderRadius: "3px",
              borderColor,
              borderWidth: state.isFocused ? "1.5px !important" :"1px !important",
              boxShadow: error && "#f44336 !important",
              '&:hover': {
                borderColor: '#42484b',
              },
            }
          },
          menuPortal: base => ({
            ...base,
            zIndex: 9999
          }),
          placeholder:base=>({
            ...base,
            color:"#6F8099"
          }),
          multiValue: base => ({
            ...base,
            backgroundColor:"#F0F3F5",
            borderRadius:"40px",
            alignItems:"center"
            // backgroundColor: "#ebebeb"
          }),
          multiValueLabel: base => ({
            ...base,
            fontSize:"13px",
            fontWeight:600,
            color:"#37404D",
            paddingLeft: 10,
            paddingRight:0,
            lineHeight: "26px"
          }),
          // multiValueRemove: base => ({
          //   ...base,
          //   // backgroundColor: "#cccccc",
          //   // height: 20,
          //   // width: 20,
          //   marginLeft: 6,
          //   marginRight: 6,
          //   // borderRadius: "50%",
          //   // alignSelf: "center"
          // }),
          indicatorSeparator: base => ({
            ...base,
            display: "none"
          }),
          dropdownIndicator:base=>({
            ...base,
            color:"#37404D",
          }),
          menu: base => ({
            ...base,
            zIndex: 2
          }),
          menuList: base => ({
            ...base,
            fontSize: "0.93rem",
            fontWeight: 500,

          })
        }}
        isMulti ={isMulti}
        options={sortedOptions}
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors
          }
        })}
        components={{
          MultiValueRemove:({innerProps,data})=>{
            const {onClick}=innerProps;
            const {value}=data;
            return(
          <IconButton style={{padding:1,margin:'0px 7px 0px 5px'}} onClick={()=>onClick(value)}>
            <CloseIcon style={{fontSize:12}}/>
          </IconButton>
          )}
        }}
      />
      {error && (
        <Typography variant="caption" color="error" className={classes.error}>
          {error}
        </Typography>
      )}
    </>
  );
};

const CustomComponent=(props)=>{
  const {async=false,loadOptions, creatable= false, ...rest}=props;
  if(creatable) {
    return (
      <CreatableSelect {...rest} />
    )
  }
  if(!async){
    return(
      <Select {...rest} />
    )
  }
  else{
    return(
      <AsyncCreatableSelect 
        loadOptions={loadOptions} 
        formatCreateLabel={(v)=>(<span className="text-primary">Create "{v}"</span>)}
        isValidNewOption={(inputValue="",value="",options)=>{
          return !(options && options.find(i=>i.label.toLowerCase()===inputValue.toLowerCase()));
        }}
        {...rest}/>
    )
  }
}

export default AutoSuggest;
