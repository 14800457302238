import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  DialogContent,
  Switch,
  Typography,
} from "@material-ui/core";
import Dialog from "fitbud/components/Dialog";
import { UserSchContext } from "fitbud/views/schedulev2";
import { WCACInput } from "fitbud/views/schedulev2/editor/rhs";
import { get } from "lodash";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";

const UNIT="water";
const DEFAULT_WATER={value:0,required:false};
const WaterEditor = (props) => {
  const { onClose, open } = props;
  const {
    currWeekId:weekId,
    weekToday,
    dayToday,
    local,
    controller,
    opimport:{day,week},
    aggrs,
    remote
  } = useContext(UserSchContext);
  const { comp } = useContext(FirebaseAuthContext);
  const defaultWtr=comp && get(comp.data(),'defaults.water',0);
  const future=(week * 7 + day) >=dayToday ;
  const water= useMemo(() => {
    let water={...DEFAULT_WATER};
    if(!!future){
      water=get(local, ['plan', 'wc'],{});
      if((!water || !Object.keys(water).length) && !!defaultWtr) return {...water,value:defaultWtr};
      return {...water};
    }
    else{
      if(aggrs){
        const _day="day_"+((week*7)+day+1);
        water.value=get(aggrs,['water_target',_day,'total'],0);
      }
      if(!water.value){
        if(week===weekToday){
          water=get(remote, ['weeks', weekId, 'water'], {})
        }else{
          water=get(local,['weeks',weekId,'water'],{});
        }
      }
      return water;
    }
  }, [future,local,weekId,defaultWtr]);
  //state
  const [state, updateState] = useState(water);
  const [error,setError]=useState('');
  const handleChange=(dbVal)=>updateState(o=>({...o,value:dbVal}));
  const handleToggle=(e,v)=>updateState(o=>({...o,required:v}));
  const handleSave=()=>{
    if(!!state.required && !state.value){
      setError("Please enter valid value");
      return;
    }
    controller.setWCAC(UNIT, state , week , day);
    onClose();
  }
  return (
    <Dialog
      open={open}
      onClose={onClose}
      onSave={handleSave}
      title="Water Intake"
      paperClass="width-400"
      titleFont="h3"
      actionText="Save"
    >
      <DialogContent className="p-20">
        <div className="p-15 mb-20" style={{backgroundColor:'#E8F4FD',borderRadius:6}}>
          <Typography className="font_14_500">Set a daily target for the client's water intake, effective from today onwards.</Typography>
        </div>
        <div className="d-flex  mb-10 align-items-center">
          <Switch checked={state.required} color="primary" edge='start' className='my-n12 mr-8' onChange={handleToggle}/>
          <Typography className="font_15_600">Set Target</Typography>
        </div>
        <WCACInput
          required={true}
          controller={controller}
          value={state.value}
          unit={UNIT}
          handleChange={handleChange}
          disabled={!state.required}
          error={error}
        />
      </DialogContent>
    </Dialog>
  );
};
export default WaterEditor;
