import React, { useState, useContext } from 'react';
import { Slide } from '@material-ui/core';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { useSnackbar } from 'notistack';
import Dialog from 'fitbud/components/Dialog';
import ListItems from 'fitbud/components/listItems';
import * as Sentry from '@sentry/browser';
import mealRepo from 'fitbud/repo/meals';
import SupplementInfo from './info';
import _ from 'lodash';
import { DEFAULT_ERROR } from 'fitbud/utils/constants';
import SelectorCatalogList from 'fitbud/components/catalog/selectorCatalogList';

const ImportSupplementDialog = (props) => {
  const { open, handleClose, onSave, docId: supplementId } = props;
  const [step, setStep] = useState(1);
  const { enqueueSnackbar } = useSnackbar();
  const { cid,comp } = useContext(FirebaseAuthContext);
  const draftFeature= !!comp.data().features.draft;
  const [selectedMealGroups, setSelectedMealGroups] = useState([]);
  const [selectedMealId, setSelectedMealId] = useState(null);

  const fetchMeal = (mealId) => {
    return mealRepo(cid,draftFeature).doc(mealId);
  };

  const onClose = () => {
    step === 1 ? handleClose() : setStep(1);
    setSelectedMealId(null);
    setSelectedMealGroups([]);
  };

  const onMealSelect = async (doc) => {
    const mealId = doc._id;
    if (supplementId === mealId) {
      enqueueSnackbar(`Can't import from the current supplements plan.`, { variant: 'error' });
      return;
    }
    try {
      const mealRef = await fetchMeal(mealId);
      const data = mealRef.data();
      if (!_.get(data, 'groups.length', 0)) {
        enqueueSnackbar(`Supplements groups are empty for this plan.`, { variant: 'error' });
        return;
      }

      setSelectedMealId(mealId);
      setStep(2);
    } catch (err) {
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
      Sentry.captureException(err);
    }
  };

  const handleSelectImportMealGroups = (meal) => {
    const _selectedMeals = [...selectedMealGroups];
    const _type = meal.type;
    const _index = _selectedMeals.findIndex((item) => item.type === _type);
    if (_index === -1) {
      _selectedMeals.push(meal);
    } else {
      _selectedMeals.splice(_index, 1);
    }
    setSelectedMealGroups(_selectedMeals);
  };

  const checkSelectedMealGroup = (meal) => {
    const _type = meal.type;
    const _selectedMeals = [...selectedMealGroups];
    const _index = _selectedMeals.findIndex(item=>item.type === _type);
    return _index ===  -1 ?false : true;
  };

  const handleSave = () => {
    if(!selectedMealGroups || !selectedMealGroups.length){
      enqueueSnackbar(`Supplements are required`, { variant: 'error' });
      return;
    }
    onSave && onSave(selectedMealGroups, handleClose);
  };

  return (
    <Dialog
      titleFont="h3"
      buttonColor="primary"
      fullWidth
      title={step === 1 ? 'Select Supplement Plan' : 'Select Supplement Timing Segment'}
      showBack={step === 2}
      open={open}
      onSave={step === 1 ? null : handleSave}
      toolbarClass="height-60"
      paperClass="width-800 height-70"
      onClose={onClose}
      dialogContentClassName="d-flex flex-column"
      actionText="Import"
      hideHeaderDivider={step === 1 }
    >
      <div className="position-relative d-flex flex-column h-100">
        {step === 1 ? <SelectorCatalogList isFilter={false} onClick={onMealSelect} keyName="supplements" showDraftInd={true}/> : null}
        {step === 2 ? (
          <Slide
            direction="left"
            in={step === 2}
            timeout={{ enter: 100, exit: 100 }}
            mountOnEnter
            unmountOnExit
            style={{ height: '100%' }}
          >
            <div>
              <SupplementInfo
                id={selectedMealId}
                edit={false}
                isImport={true}
                onSelectImportSupplements={handleSelectImportMealGroups}
                checkImportSupplementGroup={checkSelectedMealGroup}
                isPreview={true}
              />
            </div>
          </Slide>
        ) : null}
      </div>
    </Dialog>
  );

  return null;
};

export default ImportSupplementDialog;
