import { Drawer, makeStyles, IconButton, Typography } from '@material-ui/core';
import React from 'react';
import Checkout from './checkout';
import { useBillingContext } from 'fitbud/providers/billing-provider';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '420px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const CheckoutDrawer = (props) => {
  const classes = useStyles();
  const { exitCheckout } = useBillingContext();

  return (
    <Drawer
      anchor="right"
      open
      onClose={exitCheckout}
      classes={{
        paper: classes.paper,
      }}
      disableEscapeKeyDown>
      <Checkout />
    </Drawer>
  );
};

export default CheckoutDrawer;
