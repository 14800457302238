import { Checkbox, FormControlLabel, FormHelperText, TextField, Typography } from '@material-ui/core'
import Dialog from 'fitbud/components/Dialog'
import { FormTextField } from 'fitbud/components/form-fields'
import ImageFileUpload from "fitbud/components/imageSelector";
import React, { useState } from 'react'

const GroupNameDialog = ({ onClose, onSave, refName, groupName="", loading, thumbnail: _thumb, description="", mute=false }) => {
  const [ref_name, setRefName] = useState(refName);
  const [name, setName] = useState(groupName);
  const [desc, setDesc] = useState(description);
  const [thumbnail, setThumbnail] = useState(_thumb);
  const [error, setError] = useState(null);
  const [isDirty, setDirty] = useState(groupName ? false : true);
  const [isMute, setMute] = useState(mute);

  const onChange = (e) => {
    setDirty(true);
    const { id, value } = e.target;
    if(id === "groupImage") setThumbnail(value);
    else if(id === "desc") setDesc(value);
    else if(id === "ref_name") setRefName(value);
    else if(id === "mute") setMute(e.target.checked)
    else setName(value);
  }

  const onSubmit = () => {
    if(!name || !name.trim())
      return setError({ name: "Please enter a valid Group Title"});
    else if(!ref_name || !ref_name.trim())
      return setError({ ref_name: "Please enter a valid Reference Name"});
    onSave(name.trim(), thumbnail, desc.trim(), ref_name.trim(), isMute);
    onClose();
  };

  return (
    <Dialog
      toolbarClass="height-60"
      buttonColor="primary"
      open
      onClose={onClose}
      onSave={onSubmit}
      titleFont="h3"
      appBarColor="bg-body"
      appBarTextColor="text-black"
      paperClass="width-600"
      title={"Add Group Info"}
      withConfirmation
      isDirty={isDirty}
    >
      <div className="mx-20 my-30">
        <div className="d-flex">
          <div className="flex-1 fmr-20 d-flex flex-column justify-content-between">
          <FormTextField fullWidth label="Group Title" required>
            <TextField
              id="name"
              autoComplete="off"
              placeholder="Enter Group Title"
              value={name}
              onChange={onChange}
              error={!!error && error.name}
              helperText={!!error && error.name}
              variant="outlined"
              disabled={loading}
              required
              fullWidth
              type="text"
              InputProps={{
                classes: {
                  root: "medium",
                  input: "size_16_500 medium"
                }
              }}
            />
          </FormTextField>
          <FormTextField fullWidth label="Reference Name" required>
            <TextField
              id="ref_name"
              autoComplete="off"
              placeholder="Enter Reference Name"
              value={ref_name}
              onChange={onChange}
              error={!!error && error.ref_name}
              helperText={!!error && error.ref_name}
              variant="outlined"
              disabled={loading}
              required
              fullWidth
              type="text"
              InputProps={{
                classes: {
                  root: "medium",
                  input: "size_16_500 medium"
                }
              }}
            />
          </FormTextField>
          </div>
        <ImageFileUpload
          id='groupImage'
          onChange={onChange}
          thumbnail={thumbnail}
          size="small"/>
        </div>
          <FormTextField fullWidth label="Group Description" classes={{control: 'mb-0'}}>
            <TextField
              id="desc"
              autoComplete="off"
              placeholder="Write purpose of this group"
              value={desc}
              onChange={onChange}
              variant="outlined"
              disabled={loading}
              required
              fullWidth
              multiline
              minRows={3}
              maxRows={3}
              type="text"
              InputProps={{
                classes: {
                  root: "large mb-0",
                  input: "size_16_500"
                }
              }}
            />
          </FormTextField>
          <FormControlLabel 
            className='mb-0 mt-10'
            label='Mute group for new members'
            control={<Checkbox id="mute" checked={Boolean(isMute)} onChange={onChange} color="primary" />}
          />
          <FormHelperText className='fml-30 font_12_400 fmtn-5'>
            New members added to the group will have notifications turned off by default
          </FormHelperText>
      </div>
    </Dialog>
  )
}

export default GroupNameDialog;
