import React from "react";
import {
  CircularProgress,
  Button,
} from "@material-ui/core";
import {
  ClrdButton,
  PasswordField,
} from "fitbud/components/form-fields";
import firebase from "fitbud/firebase";

const LoginButton = () => (
  <Button component='a' href='/' color='black'
    classes={{label: 'default'}}>Login ❭</Button>
);

class ResetPwd extends React.Component {
  state = {
    code: undefined,
    apiKey: undefined,
    codeVerified: false,
    mail: "",
    pwd: "",
    errors: {},
    loading: true,
    done: false,
  };

  handleChange = e => this.setState({ [e.target.id]: e.target.value });

  valid = () => {
    let out = true;
    let errors = {};
    const { pwd } = this.state;
    if (!pwd) {
      errors.pwd = "Please provide a password";
      out = false;
    }
    this.setState({ errors });
    return out;
  };

  onStartAuth = () => {
    this.setState({ loading: true });
    if (!!this.snack)
      // dismiss any previous error
      this.props.closeSnackbar(this.snack);
  };

  handleAuthError = err => {
    this.setState({ loading: false });
    this.snack = this.props.enqueueSnackbar(
      !!err.code ? err.message : "Something went werong. Please try requesting for rest link", 
      { variant: "error" });
  };

  doReset = e => {
    e.preventDefault();
    if (!this.valid()) return;
    this.onStartAuth();
    const { code, pwd } = this.state;
    firebase
      .auth()
      .confirmPasswordReset(code, pwd)
      .then(() => {
        this.setState({loading: false, done: true})
      })
      .catch(this.handleAuthError)
  };

  renderInternal = () => {
    const { mail, pwd, errors, loading, codeVerified, done } = this.state;
    if (!codeVerified || loading)
      return <div className='w-100 my-5 d-flex justify-content-around'><CircularProgress/></div>;

    if (!!done)
      return <div className='w-100 my-3'>
        Password for {mail} has been reset succesfully. Proceed to <LoginButton/>
      </div>;

    return (
      <>
        <div className='w-100 mb-3'>
          Choose a new password for<br/>
          <span className='font-weight-bold'>{mail}</span>
        </div>
        <PasswordField
          id="pwd"
          fullWidth
          label="New Password"
          value={pwd}
          onChange={this.handleChange}
          className={`mb-3 ${this.state.reset ? 'd-none' : ''}`}
          autoComplete='new-password'
          error={!!errors.pwd}
          helperText={errors.pwd}
          variant="outlined"
          required
        />
        <div className="d-flex w-100 align-items-baseline">
          <ClrdButton
            color="black-white"
            onClick={this.doReset}
            className="f-medium">Proceed</ClrdButton>
          <div className="flex-grow-1" />
          <LoginButton/>
        </div>
      </>
    );
  }

  render() {
    return (
      <div className="position-relative w-100 text-center text-sm-left mw-450">
        {this.renderInternal()}
      </div>
    );
  }

  componentDidMount() {
    const codeMatch = window.location.search.match(/oobCode=([^&#]*)/);
    const keyMatch = window.location.search.match(/apiKey=([^&#]*)/);
    if (!codeMatch || !keyMatch)
      return window.location.href = "/";
    firebase.auth().verifyPasswordResetCode(codeMatch[1])
      .then(mail => {
        this.setState({code: codeMatch[1], apiKey: keyMatch[1], mail, codeVerified: true, loading: false});
      }).catch(err => {
        window.alert("Your request to reset your password has expired or the link has already been used. Please proceed back to the login page and request for a new reset link");
        window.location.href = "/";
      });
  }
}

export default ResetPwd;
