import React from 'react';
import Dialog from 'fitbud/components/Dialog';
import SelectorCatalogList from 'fitbud/components/catalog/selectorCatalogList';
import ArrowIcon from '@material-ui/icons/ArrowForward';

const MealSelector = (props) => {
  const { handleAdd, onClose, mealDoc, selectedMeal, open,title, ...other } = props;

  function save() {}
  function handleClose() {
    onClose();
  }

  const onSelect = (doc) => {
    handleAdd([doc]);
  };

  return (
    <Dialog
      open={open}
      {...other}
      title={title||`Select Meal `}
      
      buttonColor="main"
      paperClass="width-600 height-70"
      toolbarClass="height-60"
      titleFont="h3"
      fullWidth
      onSave={save}
      onClose={handleClose}
      hideHeaderDivider={true}
      dialogContentClassName="d-flex flex-column"
      disableAction={true}
    >
      <div className="position-relative d-flex flex-column h-100">
        <SelectorCatalogList
          keyName={'meals'}
          onClick={onSelect}
          showDraftInd={true}
          listProps={{
            ListItemSecondaryAction: () => (
              <span style={{ marginRight: 8 }}>
                <ArrowIcon />
              </span>
            ),
          }}
        />
      </div>
    </Dialog>
  );
};
export default MealSelector;
