import React from 'react';
// import ParticipantTracks from './participantTracks';
import { makeStyles, Typography } from '@material-ui/core';
import UseIsTrackEnabled from './useIsTrackEnabled';
import useVideoContext from './useVideoContext';
import VideoTrack from './videoTrack';
import AvatarContainer from './avatarContainer';
import UseMainParticipant from './useMainParticipant';
import ParticipantTracks from './participantTracks';
import MainParticipantInfo from './mainParticipantInfo';
import usePublications from './usePublications';
import useTrack from './useTrack';
import useSelectedParticipant from './useSelectedParticipant';

const useStyles = makeStyles(() => ({
  root: {
    gridArea: props => props.isViewEnabled ? 'auto' : '1 / 1 / 2 / 3',
  },
  centerAvater: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

const MainParticipant = () => {
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();
  const mainParticipant = UseMainParticipant();
  const { isViewEnabled,
    room: { localParticipant },
  } = useVideoContext();
  const props = {
    isViewEnabled: isViewEnabled,
  }
  const classes = useStyles(props);

  const publications = usePublications(mainParticipant);
  const videoPublication = publications.find(p => p.trackName.includes('camera'));

  const videoTrack = useTrack(videoPublication);
  const isTrackEnabled = UseIsTrackEnabled(videoTrack);
  
  return (
    <div className={`${classes.root} ${!isTrackEnabled && classes.centerAvater}`}>
      <MainParticipantInfo {...(selectedParticipant ? {key: mainParticipant.sid} : {})} participant={mainParticipant}>
        <ParticipantTracks
          participant={mainParticipant}
          isLocalParticipant={mainParticipant === localParticipant}
        />
      </MainParticipantInfo>
    </div>

  );
}

export default MainParticipant;