import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import { roundMacros, roundNumber } from 'fitbud/utils/helpers';
import {get} from 'lodash';
const MACROS = ['protein', 'fat', 'carbs'];

export const Calories = (props) => {
  const { calories, className, textClassName = 'font_20_700', calClassName="font_20_500" } = props;
  // if (!calories) return null;//new UI-show 0cals
  const _value = roundMacros(calories);

  return (
    <Typography className={clsx(textClassName, className)}>
      <span>{_value.toLocaleString()}</span>&nbsp;<span className={clsx(calClassName)}>cals</span>
    </Typography>
  );
};

const useStylesFacebook = makeStyles((theme) => ({
  container: {
    width: 100,
  },
  root: {
    position: 'relative',
    height: 100,
  },
  bottom: {
    color: '#D3D9DB',
  },
  top: {
    color: '#27AE60',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
    top:-1
  },
  circle: {
    strokeLinecap: 'round',
  },
  content: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
  },
}));

const MacroCircles = (props) => {
  const classes = useStylesFacebook();
  const { label, children, className, value } = props;
  return (
    <div className={clsx(classes.container, className)}>
      <div className={classes.root}>
        <CircularProgress variant="determinate" className={classes.bottom} size={99} thickness={1} value={100} />
        <CircularProgress
          variant="determinate"
          disableShrink
          className={classes.top}
          classes={{
            circle: classes.circle,
          }}
          size={100}
          thickness={2}
          value={value}
        />
        <div className={clsx(classes.content, 'd-flex justify-content-center align-items-center flex-column')}>
          {children}
        </div>
      </div>
      <div className="fmt-10">
        <Typography className="text-center font_13_600 text-capitalize">{label}</Typography>
      </div>
    </div>
  );
};

const getPercent = (key, macros={}) => {
  const { calories } = macros
  const _value = macros[key];
  const _factor = key === 'protein' || key === 'carbs' ? 4 : 9;
  const _percent = ((_value || 0) * _factor * 100) / (calories || 1);
  return _percent;
};

const PFC = (props) => {
  const { macros } = props;
  const display = useMemo(() => {
    const out = {};
    MACROS.forEach(key => {
      const value = roundMacros((macros && macros[key]) || 0);
      const percent = get(macros,'calories',0)?roundNumber(getPercent(key, macros), 0):0;
      out[key] = { value, percent };
    });
    return out;
  }, [macros]);

  return (
    <div className="d-flex justify-content-between" style={{gap:"20px"}}>
      {MACROS.map((key, index) => {
        const {value, percent} = display[key];
        return (
          <MacroCircles key={key + index} label={key} value={getPercent(key, macros) || 0}>
            <Typography className="">
              <span className="font_20_700 ">{value}</span>&nbsp;<span className="font_13_500" >g</span>
            </Typography>
            {!!percent && <Typography className="font_13_500 text-dark-grey" >{percent} %</Typography>}
          </MacroCircles>
        );
      })}
    </div>
  );
};

export default PFC;

export const LinearPfc = (props) => {
  const {
    data,
    className,
    type,
    startAdornment,
    labelClassName = 'font_13_500',
    valueClassName = 'font_13_600',
  } = props;
  if (type === 'percentage') {
    return (
      <div className={clsx('d-flex flex-wrap', className)}>
        {startAdornment}
        {['protein', 'fat', 'carbs'].map((item, index, arg) => {
          return (
            <div key={index} className="d-flex flex-row text-labels ">
              <div className="d-flex align-items-center ">
                <Typography className="">{item.charAt(0).toUpperCase()}</Typography>
                &nbsp;
                <Typography className="">{roundNumber(getPercent(item, data), 0)}</Typography>&nbsp;%
                {index !== arg.length - 1 && <span>&nbsp;•&nbsp;</span>}
              </div>
            </div>
          );
        })}
      </div>
    );
  } else
    return (
      <div className={clsx('d-flex flex-wrap', className)}>
        {startAdornment}
        {['protein', 'fat', 'carbs'].map((item, index, arg) => {
          return (
            <Typography key={index} className="text-labels ">
                <span className={clsx(labelClassName, )}>{item.charAt(0).toUpperCase()}</span>
                &nbsp;
                <span className={clsx(valueClassName, )}>{roundNumber(data[item], 0)} </span>{' '}
                {index !== arg.length - 1 && <span className="">&nbsp;•&nbsp;</span>}
            </Typography>
          );
        })}
      </div>
    );
};
