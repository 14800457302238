import { makeStyles, Typography } from '@material-ui/core';
import { numFormatter } from 'fitbud/utils/helpers';
import React from 'react';
import { Bar, BarChart, CartesianGrid, ReferenceLine, ResponsiveContainer, XAxis, YAxis, Tooltip, Cell } from 'recharts';

// SUPPORTED DATA STRUCTURE
// [
//   {
//     "dataKey-X": "11 Sep",
//     "dataKey-Y": 90,
//   },
//   {
//     "dataKey-X": "12 Sep",
//     "dataKey-Y": 85,
//   },
// ]
const useStyles = makeStyles((theme) => ({
  root:{
    borderRadius: '10px',
  },
  graphRoot: {
    marginLeft: '-30px'
  }
}))
const CustomizedLabel = (props) => {
  return (
    <g>
      <svg width="21" height="21" x={props.viewBox.x + 350} y={props.viewBox.y - 10}>
        <circle cx="10.8624" cy="10.1532" r="10.1183" fill="#F2937B" fillOpacity="0.2"/>
        <circle cx="10.8625" cy="10.1533" r="3.72778" fill="#F3997F"/>
      </svg>
      <text x={props.viewBox.x + 350} y={props.viewBox.y} fill="#111" dy={20} fontSize='10px' fontWeight='600'>
        Goal
      </text>
    </g>
  )
}

const CustomTooltip = ({ active, payload, label, valueLabel, unit, labelFormatter }) => {
  if (active && payload && payload.length) {
    const { value } = payload[0];
    return (
      <div className="d-flex flex-nowrap fp-10" style={{backgroundColor:'#0D0D0D', borderRadius:'3px'}}>
        <div className='fmr-10 text-white'>
          <Typography style={{opacity:'60%', fontSize: '11px' }} align='right' className='fmb-5 text-capitalize'>
            {valueLabel || ''}
          </Typography>
          <Typography style={{opacity:'60%', fontSize: '11px' }} align='right'>
            Date  
          </Typography>
        </div>
        <div>
          {!!valueLabel && (
            <Typography className="text-white fmb-5" style={{ fontSize: '11px' }}>
              {numFormatter(value) || '-'} {' '} {(!!value && unit) || ''}
            </Typography>
          )}
          {!!label && (
            <Typography className="text-white" style={{ fontSize: '11px' }}>
              {labelFormatter(label)}
            </Typography>
          )}
        </div>
      </div>
    );
  }
  return null;
};

const BarGraph = ({data, color="#F08673", goal=false, tooltipConfig=false, className }) => {
  const classes = useStyles();
  return (
    <div className={'position-relative fp-20 fpt-30' + classes.root + ` ${className}`}>
      <ResponsiveContainer height={300}>
        <BarChart data={data} className={`${classes.graphRoot}`}>
          <CartesianGrid strokeDasharray="0" vertical={false} />
          <XAxis
            id='bar-x'
            dataKey="date"
            padding={{ left: 20 }}
            tickCount={7}
            tick={{fontSize: 12 , fontWeight: 500, color:"#000" }} 
            tickLine={false}
            />
          <YAxis
            id='bar-y'
            allowDataOverflow={true}
            tickFormatter={numFormatter} 
            axisLine={false} 
            tick={{fontSize: 12, fontWeight: 500, color:"#000", }} 
            tickLine={false} 
            allowDecimals={false} 
            />
          <Bar dataKey="value" fill={color} radius={[4, 4, 0 , 0]} barSize={20} />
          {!!goal && <ReferenceLine isFront={true} y={goal} label={CustomizedLabel} stroke="#70829B" strokeDasharray="3 3" ifOverflow={"extendDomain"} />}
          {!!tooltipConfig && <Tooltip cursor={{fill: 'rgba(206, 206, 206, 0.2)'}} content={<CustomTooltip />} {...tooltipConfig} />}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default BarGraph;