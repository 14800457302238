import { CircularProgress, IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core';
import clsx from 'clsx';
import firebase from 'firebase';
import { bffUpdateHubspotProp } from 'fitbud/api';
import { ExternalLinkIcon } from 'fitbud/icons/externalLinkIcon';
import { HUBSPOT_PROPS, oprtnlDateFormat } from 'fitbud/utils/constants';
import { hasLength, roundNumber } from 'fitbud/utils/helpers';
import _, { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DifferenceIcon } from '../checkIns';
import { getIdentifier, NoAccessPlaceholder } from '../checkIns/checkinStepper';
import { getCheckins } from '../profile';
import { getSecondaryText } from '../progress/statView';
import QResponses from '../QResponses';
import { DisplayMeasurements, Images } from '../subComponents';
import { ComplianceCircularProgress, DataContainer, HeadingAndLabel, HText, ParentCard, ProgressInfoImages } from './subComponents';
import { useSnackbar } from "notistack";
import { calculateTimePeriod, renderTime } from '../checkIns/checkinStatsView';
import Slider from '../checkIns/slider';
import usePoses from 'fitbud/hooks/usePoses';

export const WeeklyTrendsCards = ({ data, selectedPeriod, complianceConfig, goTo }) => {
  if(!data) return null;
  const { workout, nutrition, water } = data || {};
  const { 
    sessions: {num: woNum, deno: woDeno, compliance: woCompliance } = {}, 
  } = workout || {};
  const { 
    calories: {num: calNum, deno:calDeno, compliance: calCompliance, unit: calUnit } = {},
  } = nutrition || {};
  const {
    water:{ num: waNum, deno: waDeno, unit: waUnit, compliance: waCompliance } = {}
  } = water || {};
  const handleLaunch = (e) => {
    e.preventDefault();
    e.stopPropagation();
    goTo();
  }
  return (
    <ParentCard
      isCollapsible
      title='Weekly Trends' 
      rightAdornment={() => (
        <div className='d-flex'>
          <Tooltip title='Open'>
            <IconButton onClick={handleLaunch} size='small' classes={{root: 'black'}}>
              <ExternalLinkIcon />
            </IconButton>
          </Tooltip>
      </div>
      )}
    >
        {(
          <DataContainer
            divider
            left={() => (<HText title='Workouts' />)}
            center={() => (
              <HeadingAndLabel 
                heading={getSecondaryText(woNum, woDeno)} 
                label={selectedPeriod.text}
              />
            )}
            right={() => (
              <ComplianceCircularProgress compliance={woCompliance} complianceConfig={complianceConfig['workout']} />
            )}  
          />
        )}
        {(
          <DataContainer
            divider
            left={() => (<HText title='Nutrition' />)}
            center={() => (
              <HeadingAndLabel
                heading={getSecondaryText(calNum, calDeno, calUnit)}
                label={selectedPeriod.text}
              />
            )}
            right={() => (
              <ComplianceCircularProgress compliance={calCompliance} complianceConfig={complianceConfig['nutrition']} />
            )}  
          />
        )}
        {(
          <DataContainer 
            left={() => (<HText title='Water' />)}
            center={() => (
              <HeadingAndLabel 
                heading={getSecondaryText(waNum, waDeno, waUnit)}
                label={selectedPeriod.text}
              />
            )}
            right={() => (
              <ComplianceCircularProgress compliance={waCompliance} complianceConfig={complianceConfig['water']} />
            )}  
          />
        )}
    </ParentCard>
  )
};

export const ActivityInfoCard = ({ data, selectedPeriod, complianceConfig, goTo }) => {
  if(!hasLength(data['steps']) && !hasLength(data['energy']) && 
  !hasLength(data['distance']) && !hasLength(data['flights'])) return null;
  const handleLaunch = (e) => {
    e.preventDefault();
    e.stopPropagation();
    goTo();
  }
  return (
    <ParentCard title='Activity'
      isCollapsible
      rightAdornment={() => (
        <div className='d-flex'>
          <Tooltip title='Open'>
            <IconButton onClick={handleLaunch} size='small' classes={{root: 'black'}}>
              <ExternalLinkIcon />
            </IconButton>
          </Tooltip>
      </div>
      )}
    >
      {['steps', 'energy', 'flights', 'distance'].map((item, i) => {
        if(!item) return null;
        const { num, deno, compliance, unit } = (!!data && !!data[item] && data[item][item]) || {};
        return (
          <DataContainer
            key={item+i}
            divider={i < 3}
            left={() => (<HText title={item} className='text-capitalize' />)}
            center={() => (
              <HeadingAndLabel 
                heading={getSecondaryText(item === 'distance' ? num : roundNumber(num, 0),item === 'distance' ? deno : roundNumber(deno, 0), unit)}
                label={selectedPeriod.text}
              />
            )}
            right={() => !!compliance && (
              <ComplianceCircularProgress compliance={compliance} complianceConfig={complianceConfig} />
            )}  
          />
        );
      })}
    </ParentCard>
  )
};

export const ProgNMeasurementsInfoCard = ({ data, measurableTags={}, goTo, type='mea' }) => {
  if(!data) return null;
  let keys = Object.keys(type === 'mea' ? measurableTags : data);
  if(!keys && keys.length === 0) return null;
  const handleLaunch = (e) => {
    e.preventDefault();
    e.stopPropagation();
    goTo();
  }
  const filteredKeys = type === 'mea' ? (keys || []).filter((item) => {
    const itemData = data[item]
    if(!measurableTags[item] || !measurableTags[item].tracking || !itemData || _.isEmpty(itemData)) 
      return false;
    return true;
  }) : keys;
  return (
    <ParentCard title={type === 'mea' ? 'Measurements' : "Progression"}
      isCollapsible
      rightAdornment={() => (
        <div className='d-flex'>
          <Tooltip title='Open'>
            <IconButton onClick={handleLaunch} size='small' classes={{root: 'black'}}>
              <ExternalLinkIcon />
            </IconButton>
          </Tooltip>
      </div>
      )}
    >
      {(filteredKeys|| []).map((item, i) => {
        if(!item) return null;
        let itemData = data[item];
        if(!itemData || _.isEmpty(itemData)) return null;
        const { logs=[], unit='', target=0 } = itemData || {};
        const lastLogged = logs[0];
        const firstLogged = (logs && logs.length && logs[logs.length - 1]) || false;
        const difference = (firstLogged && lastLogged) ? roundNumber(lastLogged.value, 1) - roundNumber(firstLogged.value, 1) : 0;
        const period = logs.length === 1 ? 'a day' : calculateTimePeriod(lastLogged, firstLogged);
        const name=type==="prog"?itemData.name:item.split('_').join(' ');
        const isDuration=itemData.primary==="duration";
        return (
          <DataContainer
            key={item+i}
            divider={i < filteredKeys.length - 1}
            left={() => (<HText title={name} className='text-capitalize' />)}
            center={() => (
              <div className='flex-1'>
                <HeadingAndLabel
                  heading={
                  <>
                    {isDuration ? renderTime(lastLogged.value, false, { small: true}) : roundNumber(lastLogged.value, 1)} {' '}
                    {!isDuration && (
                      <span className='font-weight-normal' style={{fontSize: '16px'}}>
                        {unit}
                      </span>
                    )}
                  </>
                }
                  label={moment(lastLogged.date, oprtnlDateFormat).format('DD MMM YYYY')}
                />
              </div>
            )}
            right={() => difference !== 0 ? (
              <div className='d-flex align-items-start justify-content-end'>
                <HeadingAndLabel
                  headingComponent={() => (
                  <div className='d-flex flex-wrap-none align-items-end justify-content-end'>
                    <div className='fmr-5' style={{marginTop: '-4px'}}>
                      <DifferenceIcon
                        difference={lastLogged.value - firstLogged.value}
                        type={type}
                        target={target}
                        initialValue={firstLogged.value}
                        lastValue={lastLogged.value} 
                      />
                    </div>
                    <Typography className='font_20_700' noWrap>
                      {isDuration ? renderTime(Math.abs(difference), true, { small: true}) : Math.abs(roundNumber(difference, 1))} {' '}
                      {!isDuration && (
                        <span className='font_14_500'>
                          {unit}
                        </span>
                      )}
                      { !!target &&
                        <span className='font_14_500'>
                          {' '} / {Math.abs(roundNumber(target - firstLogged.value, 1))} {unit}
                        </span>
                      }
                    </Typography>
                  </div>)}
                  label={`in ${period}`}
                  labelProps={{align: 'right'}}
                  />
              </div>
            ): null}
          />
        );
      })}
    </ParentCard>
  )
}
const hasPhotos = (data) => {
  if(!data || !data.identifier) return false;
  const {aspect, identifier, ...rest} = data || {}; // eslint-disable-line no-unused-vars
  return Object.keys(rest).length > 0;
}
export const CheckinsInfoCard = (props) => {
  const { data, userDoc, docId, goTo, checkinAggPhotosArray, compChkinConf } = props;
  let mo = checkinAggPhotosArray && checkinAggPhotosArray.length > 1 ?
    checkinAggPhotosArray.map(d => moment(d)) : data && data.map((d) => moment(d.date));
  const minDate = moment(moment.min(mo)).format("YYYYMMDD");
  const maxDate = moment(moment.max(mo)).format("YYYYMMDD");
  let startDate = userDoc.startDate || null;
  const firstCheckins = _.find(data, ['date', minDate]);
  const lastCheckins = _.find(data, ['date', maxDate]);
  const [checkInsLeft, setCheckInsLeft] = useState(firstCheckins);
  const [checkInsRight, setCheckinsRight] = useState(lastCheckins);
  const leftWeeks = fetchWeeks(checkInsLeft);
  const rightWeeks = fetchWeeks(checkInsRight);

  if(!data || !data.length || (!hasPhotos(_.get(checkInsLeft, 'photos', false))
    && !hasPhotos(_.get(checkInsRight, 'photos', false))))
    return null;

  const handleLaunch = () => goTo();

  function fetchWeeks(item, suffix = '') {
    let week = item && item.tag === "00000000" ? 0 : Math.ceil(moment(item && item.tag, oprtnlDateFormat).diff(moment(startDate),"days") / 7);
    if(week < 0) return '';
    return !week ? `Day 1` : `Week ${week}${suffix}`;
  }

  return (
    <ParentCard title='Check Ins'
      isCollapsible
      rightAdornment={() => (
        <div className='d-flex'>
          <Tooltip title='Open'>
            <IconButton onClick={handleLaunch} size='small' classes={{root: 'black'}}>
              <ExternalLinkIcon />
            </IconButton>
          </Tooltip>
        </div>
      )}
    >
      <div className='position-relative fpt-20 mx-auto h-100 fmb-15'>
        <ProgressInfoImages
          compChkinConf={compChkinConf} userChkinConf={userDoc && userDoc.overrides}
          docId={docId} checkInsLeft={checkInsLeft} checkInsRight={checkInsRight} />
      </div>
      <div className="d-flex h-100 flex-wrap justify-content-between align-items-center fpx-20 fmt-10 fmb-5">
        <div className='w-50 d-flex justify-content-center align-items-center' style={{ borderRight: "2px solid #DFDFE0"}}>
          <div aria-controls="day-one" aria-haspopup="true" className="d-flex align-items-center">
            <Typography variant="body1" className='fmr-10 font_15_500'>
              {!!leftWeeks && leftWeeks} {' '} &bull; {' '} {checkInsLeft ? moment(checkInsLeft.date, oprtnlDateFormat).format('DD MMM') : '-'}
            </Typography>
          </div>
        </div>
        {checkInsRight && checkInsLeft && checkInsRight.date !== checkInsLeft.date ? <div className='w-50 d-flex justify-content-center align-items-center'>
          <div aria-controls="right" align='right' aria-haspopup="true" className="d-flex" >
            <Typography variant="body1" className='fmr-10 font_15_500'>
              {!!rightWeeks && rightWeeks} {' '} &bull; {' '} {checkInsRight ? moment(checkInsRight.date, oprtnlDateFormat).format('DD MMM') : '-'}
            </Typography>
          </div>
        </div> : (
          <div className='w-50 d-flex justify-content-center align-items-center'>
            <Typography variant="body1" className='fmr-10 font_15_500'>
              -
            </Typography>
          </div>
        ) }
      </div>
    </ParentCard>
  );
};
const styles = makeStyles((theme) => ({
  imagesContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridRowGap: '15px'
  },
}));
const fetchQuestionnaires = async (cid) => {
  const snapshot = await firebase
    .firestore()
    .collection(`companies/${cid}/questionnaire`)
    .doc("sample")
    .get();
  if(snapshot.exists) return snapshot.data();
  return null;
};

export const OnboardingCheckinCard = (props) => {
  const {
    measurableTags,
    measurableUnits,
    docId,
    unitSystem = "metric",
    unitDetails={},
    aplan = null,
    cid,
    compChkinConf,
    userDoc,
    bmr,
    hasImageAccess,
  } = props;

  const [metrics, saveMetrics] = useState({});
  const [img_err, updateImageErr] = useState({});
  const [questions, setQuestions] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (!!docId) {
      if (hasLength(measurableTags))
        getCheckins({
          docId,
          saveMetrics,
          enqueueSnackbar,
          measurableTags,
          aplan
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docId, measurableTags]);
  const poses = usePoses(compChkinConf, userDoc && userDoc.overrides, metrics);
  function onError(key) {
    updateImageErr(state => ({...state, [key]: true}));
  }

  useEffect(() => {
    if(hasLength(metrics)){
      bffUpdateHubspotProp(HUBSPOT_PROPS.CHECKIN_VIEWED);
    };
  },[metrics]);
  useEffect(() => {
    if(questions) return; 
    fetchQuestionnaires(cid).then((ques)=> {
      if(ques) setQuestions(ques.questions);
      setLoading(false);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const classes = styles();
  if(isLoading){
    return (
      <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
        <CircularProgress />
      </div>
    )
  }
  return (
    <>
      { !isEmpty(metrics) && (<ParentCard title='Onboarding Check In'>
        {hasImageAccess ? <div className='px-15 pt-15 mx-n1'><Slider num={3} className='mb-15' childClass='px-1'>
          {poses.map((i, j) => {
            if(!metrics || !metrics.photos || !_.get(metrics, `photos.${i}`, false) || !_.get(metrics, `photos.${i}.identifier`, metrics.photos.identifier))
              return null;
            const identifier = `${i}_${_.get(metrics, `photos.${i}.identifier`, metrics.photos.identifier)}`;
            let new_identifier = _.split(identifier, "_")[1];
            return (
              <div key={new_identifier+j} style={{minWidth: '135px'}} className="px-1">
                <Images
                  aspect={metrics["photos"] && metrics["photos"]["aspect"]}
                  identifier={identifier}
                  userId={docId}
                  img={metrics["photos"] && metrics["photos"][i]}
                  className="rounded mr-15"
                  error={img_err[i]}
                  onError={() => onError(i)}
                  new_identifier={getIdentifier(metrics, poses)}
                  position={j}
                  imageStyles={{borderRadius: '6px'}}
                  />
              </div>
            );
          })}
        </Slider></div> : <NoAccessPlaceholder className='mb-20' />}
        <div className={clsx(classes.imagesContainer, 'fpx-15', !isEmpty(metrics) && 'fmt-15')}>
          {measurableTags &&
            Object.keys(measurableTags).length > 0 &&
            measurableUnits &&
            Object.keys(measurableUnits).length > 0 &&
            Object.keys(measurableTags).map((i, j) => {
              let field_value =
                metrics && metrics.measurements && metrics.measurements[i];
              if(!field_value) return null;
              return (
                <DisplayMeasurements
                  key={j}
                  label={measurableTags[i].value}
                  unit_type={measurableTags[i].unit_type}
                  unitSystem={unitSystem}
                  unitDetails={unitDetails}
                  value={field_value}
                  measurableUnits={measurableUnits}
                />
              );
            })}
        </div>
      </ParentCard>)}
      <div className='fmt-15'>
      {(!questions || !questions.length || !userDoc || !userDoc.onboarding_response || _.isEmpty(userDoc.onboarding_response)) ? null : (
        <ParentCard title={'Onboarding Questionnaire'} className={clsx(!isEmpty(metrics) && 'fmt-20')}>
          <div className={'align-items-center fmr-20 fmb-15 fpx-15 fpt-15'}>
          <QResponses
            {...userDoc}
            docId={docId}
            measurableTags={measurableTags}
            measurableUnits={measurableUnits}
            unitSystem={unitSystem}
            unitDetails={unitDetails}
            cid={cid}
            ques={questions}
            companyBMR={bmr || {}} />
          </div>
        </ParentCard>
      )}
      </div>
    </>
  );
};
