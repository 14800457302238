import React, { useState } from "react";
import { IconButton, Menu, MenuItem, Typography } from "@material-ui/core";
// import OptionsIcon from "@material-ui/icons/MoreVert";
import OptionsIcon from "fitbud/icons/verticalOptions"
import Confirmation from "fitbud/components/confirmationDialog";
import {MenuList} from "fitbud/components/menuList";


const DeleteAction = (props) => {
  const {className,style}=props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDeleteConfirmation, toggleDeleteConfirmation] = useState(false);
  const open = Boolean(anchorEl);
  let subtitle = props.subtitle || props.title  || "";
  let disabled = props.disabled;
  subtitle  = subtitle.toLowerCase();
  let {
    confirmRequired = true,
    confirmDialogTitle = `Delete ${props.title}`,
    confirmDialogMsg = `Are you sure you want to delete the ${subtitle}?`,
    onDelete,
  } = props;
  const handleClick = e => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const closeConfirmation=()=>{
    toggleDeleteConfirmation(false);
    setAnchorEl(null);
  }
  const handleCancelConfirmation = () => {
    closeConfirmation();
  }
  const handleSubmit = () => {
    onDelete();
    closeConfirmation();
  }

  if(!!disabled) return null;

  return (
    <>
      <IconButton onClick={handleClick} className={className} style={style}>
        <OptionsIcon />
      </IconButton>
      <MenuList
        anchorEl={anchorEl}
        onClose={handleClose}
        options={[{ label: "Delete", value: "delete", dense: true }]}
        handleClick={() => toggleDeleteConfirmation(true)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      />
      {confirmRequired && isDeleteConfirmation && (
        <Confirmation
          open={true}
          handleChange={handleSubmit}
          handleCancel={handleCancelConfirmation}
          title={confirmDialogTitle}
          msg={confirmDialogMsg}
          confirmOption="Yes, Delete"
        />
      )}
    </>
  );
};


export default DeleteAction;