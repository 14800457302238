import React,{useState} from 'react';
import {IconButton,makeStyles,Typography,InputBase, InputAdornment,LinearProgress, Badge, Fade} from '@material-ui/core';
import MuiSearchIcon from "@material-ui/icons/Search";
import CloseIcon from '@material-ui/icons/ClearRounded';
import { FilterIcon } from 'fitbud/icons/filterIcon';
import { LineFilterIcon } from 'fitbud/icons/lineFilterIcon';
import clsx from "clsx";



const styles=makeStyles({
    container:{
        position:(props)=>props.selector?"static":"absolute",
        left:0,
        top:0,
        zIndex:1,
        background:"#F2F4F7",
        width:"100%",
        height:(props)=>props.selector?"":'100%',
        display:"flex",
        flexDirection:"column",
        '& .header':{
            position:"relative",
            borderBottom:'2px solid #D8DCE0',
            padding:(props)=>props.selector?"5px 20px 12px 20px":"15px 2user0px"
        },
        '& .title':{
            fontSize:18,
            fontWeight:600,
            textTransform:"capitalize",
        },
        '& .input':{
            borderRadius: "20px",
            height: 40,
            border: "1px solid #D3D9DB",
            background:'#fff',
            padding:'0px 10px',
        },
        '& .close':{
            color:'#000'
        },
        '& .loading':{
            position:"absolute",
            bottom:-2,
            left:0,
            width:"100%",
            height:2
        }
    }
})

export const ComponentListSearch=({onCloseFn,callback,loading,count,children,selector=false, isFilter, openFilter,
    closeFilter,appliedFilter  })=>{
    const [q,updateQ]=useState('');
    const classes=styles({selector});
    function handleChange(e){
        updateQ(e.target.value);
        if(callback) callback(e.target.value);
    }
    return (
      <div className={classes.container}>
        <div className="header">
          {!selector && (
            <div className="d-flex align-items-center justify-content-between fmb-10">
              <Typography className="title">Search ({loading && !count ? '...' : count || 0}) </Typography>
              <IconButton onClick={onCloseFn} className="close">
                <CloseIcon />
              </IconButton>
            </div>
          )}
          <div className="d-flex w-100 align-items-center">
          <InputBase
            value={q}
            onChange={handleChange}
            fullWidth
            autoFocus
            startAdornment={
              <InputAdornment position="start">
                <MuiSearchIcon className="text-black" />
              </InputAdornment>
            }
            className="input"
            placeholder="Search by name, tags..."
          />
          {isFilter ? (
            <IconButton className="fml-5" onClick={openFilter}>
              {!!appliedFilter ? (
                <Badge color="primary" badgeContent={appliedFilter}>
                  <FilterIcon />
                </Badge>
              ) : (
                <LineFilterIcon />
              )}
            </IconButton>
          ) : null}
          </div>
          {loading && <LinearProgress className="loading" />}
        </div>
        {/* list-part */}
        {children}
      </div>
    );
}