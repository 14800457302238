import _ from 'lodash';
import React, { useEffect, useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { parseImgUrl } from "croppy";
import VideoPlayer from 'fitbud/components/videoPlayer';
import { Card, CardHeader, Typography, CardContent, IconButton, Link, Divider, Chip } from '@material-ui/core';
import RecipesStep from '../recipesStep';
import DetailEditIcon from 'fitbud/icons/detailEdit';
import addIcon from "fitbud/images/plus.svg";
import {MediaPlaceholder} from "fitbud/components/mediaPlaceholder";
import {videoExists,mediaObjExist} from "fitbud/views/workouts/helperfn";

// import Slider from "react-slick";
import VerticalStepper from 'fitbud/components/verticalStepper';
import { MoreLessTextBtn } from 'fitbud/components/moreLessText';
import { FILE_STATUS, DRAFT, TO_PUBLISH_WARNING_MSG } from 'fitbud/utils/constants';
import { DetailPgStyling } from 'fitbud/hooks/useDetailPgStyle';
import FoodHeader from 'fitbud/views/foodRecipes/newUi/foodHeader';
import PFC, { Calories } from 'fitbud/views/foodRecipes/newUi/pfc';
import clsx from 'clsx';
import PdfUploader from "fitbud/components/pdfUploader";

import HowToPrepareImg from "fitbud/images/catalog/howToPrepare.png";
import {PlaceholderContent} from "fitbud/components/catalog/emptyListPlaceholder";
import Ingredients from "./ingredients";
import {DraftBanner} from "fitbud/components/draftBanner";
import {PublishAlert} from "fitbud/components/publishAlert";
import Confirmation from "fitbud/components/confirmationDialog";
import { mapIdToObject } from 'fitbud/views/workouts/helperfn';
const META="meta";
const PREPARE="prepare";

const useStyles = makeStyles((theme) => ({
  macrosContainer:{
    display: "inline-block !important"
  },
  media_container: {
    display: 'flex',
    width: '100%',
    overflowX: 'scroll',
  },
  image: {
    objectFit: 'cover',
    width: '240px !important',
    border: '1px solid #d1d1d1',
    borderRadius: 2.5,
  },
  outerSlick: {
    '& > :first-child': {
      '& > :first-child': {
        '& > :first-child': {
          marginLeft: 0,
        },
      },
    },
  },
}));

function RecipeDetails(props) {
  const classes = useStyles();
  const {
    id,
    doc,
    handleEditMode,
    servingData,
    // updateParent,
    copyItem,
    deleteItem,
    uploadingError,
    videoStatusRefresh,
    editMode,
    isPreview = false,
    draftBannerProps,
    draftFeature,
    handleDraft,
    handlePublish,
    showingDoc,
    toggleDoc,
    draftDoc,
    isDraftAvailable,
    stopPublishPipeline,
    nutritionTags=[],
    handleServingChange
  } = props;
  const [prepareMode, setPrepareMode] = useState(false);
  const [isVideoPause, setVideoPause ] = useState(false);
  const [showProgressWarning,toggleProgressWarning]=useState(null);//[null,'meta','prepare']
  const [stopPublishLoader,toggleStopPublishLoader]=useState(false);

  const _calories = (doc && doc.macros && doc.macros.calories) || 0;
  const serving = useMemo(()=>{
    return _.find(servingData, (d) => d[0] === doc.serving);
  },[servingData,doc.serving]);
  const images = useMemo(() => {
    const out = [];
    if (!doc.media || !doc.media.length) return out;
    doc.media.forEach(x => {
      if (!x.url || x.type !== 'image') return;
      const { parsed } = parseImgUrl(x.url, { width: '240'});
      if (parsed.href) out.push(parsed.href);
    });
    return out;
  }, [doc.media]);
  const media = _.get(doc, 'media.0', {});
  const _hasIngredients = !!(doc.ingredients && doc.ingredients.length);
  const _hasSteps = !!(doc.steps && doc.steps.length)
  const _description = _.get(doc,"description",'').trim();
  const _duration = !!doc.duration ? doc.duration / 60 : "";
  const isDraft=draftBannerProps.showingDoc===DRAFT;
  const to_publish=_.get(doc,'to_publish',false);
  const _tags = useMemo(()=> mapIdToObject(nutritionTags, doc.systags).map(i=>i.label),[doc.systags, nutritionTags]);
  useEffect(()=>{
    if(!!editMode) setVideoPause(true);
  },[editMode])

  const handlePrepareEdit=()=>{
    if(isDraftAvailable && showingDoc !== DRAFT){
      toggleDoc(DRAFT);
    }
    setPrepareMode(true);
  }
  const handleEdit=(mode)=>{
    if(isDraftAvailable && !!_.get(draftDoc,'to_publish',false)){
      toggleProgressWarning(mode);
      return;
    }
    confirmEdit(mode);
  }
  const closeEditPopup=()=>{
    toggleProgressWarning(null);
  }
  const confirmEdit=async(mode=showProgressWarning)=>{
    if(isDraftAvailable && _.get(draftDoc,'to_publish',false)){
      toggleStopPublishLoader(true);
      const draftDoc=await stopPublishPipeline();
      toggleStopPublishLoader(false);
      if(!draftDoc) {
        return;
      }
    }
    mode===META?handleEditMode():handlePrepareEdit();
    closeEditPopup();
  }
  return (
    <DetailPgStyling>
      {draftBannerProps.isDraftAvailable && !isPreview && (
        <DraftBanner
          {...draftBannerProps}
          doc={doc}
        />
      )}
      <Card square elevation={0}>
        {to_publish && <PublishAlert to_publish={to_publish}/>}
        <FoodHeader
          doc={doc}
          handleEditMode={()=>handleEdit(META)}
          onDelete={deleteItem}
          onCopy={copyItem}
          isPreview={isPreview}
          keyName="recipes"
          isDraft={isDraft}
        />
        <CardContent>
          {_description && <MoreLessTextBtn text={_description} className="desc fmb-25" btnClassName="viewMore"/>}
          {!!_duration && <div className="fmb-25">
              <Typography className="fmb-10 field-nutrition-title">COOKING TIME</Typography>
              <Typography className="field-value">{_duration}&nbsp;min </Typography>
          </div>
          }
          <div className="mb-25">
            <Typography className={"field-nutrition-title"}>
              Tags
            </Typography>
            <Typography className={clsx("field-value",!_tags.length && "mt-10")}>
              {_tags.map((i) => (
                  <Chip className="chips fmt-10 fmr-10" key={i} label={i} />
                ))
              }
              {!_tags.length && <span className="font_13_500">Not Specified</span>}
            </Typography>
          </div>
          <Typography className="fmb-10 field-nutrition-title">Summary</Typography>
          <div className="d-inline-block">
            <div className="d-flex align-items-center justify-content-between">
              <Calories calories={_calories} className="fmr-100" />
              <Typography className={clsx(classes.serving, "font_16_600")}>
                1{" "}
                {!serving 
                  ? "Serving"
                  : serving[1].value}
              </Typography>
            </div>
            <div className="fmt-20">
              <PFC macros={doc.macros} />
            </div>
          </div>
          {(!!mediaObjExist(media)||uploadingError) && (
            <Typography className="fmb-10 fmt-25 field-nutrition-title">
              Media
            </Typography>
          )}
          {!!images.length && <div className='mx-n10 mb-n20 overflow-auto text-nowrap'>
            {images.map(x => {
              if (!x) return null;
              return <img alt='' src={x} key={x.url} className='d-inline media-container media-video mb-20 mx-10 border'/>
            })}
          </div>}
          {(!images.length && videoExists(media) && media.status!==FILE_STATUS.error && !uploadingError)? (
            <div className="media-container media-video">
              <VideoPlayer
                url={media.url || media.s3Url}
                mediaType = {media.type}
                thumbnailUrl={media.thumbnailUrl}
                aspectRatio={
                  !!parseInt(media.videoHeight) && !!parseInt(media.videoWidth)
                    ? parseInt(media.videoWidth) / parseInt(media.videoHeight)
                    : 0
                }
                pause={isVideoPause}
                playerProps={{
                  onPause : ()=>{
                    setVideoPause(true);
                  },
                  onPlay : ()=>{
                    setVideoPause(false);
                  }
                }}
              />
            </div>
          ):
          <MediaPlaceholder
            media={media}
            uploadingError={uploadingError}
            onRefresh={videoStatusRefresh}
          />}
          {!!doc.document &&
            !!doc.document[0] &&
            (!!doc.document[0].url || !!doc.document[0].s3Path) && (
              <div className="mt-25">
                <Typography className="fmb-10 field-nutrition-title">
                  Attachment
                </Typography>
                <PdfUploader file={doc.document && doc.document[0]} />
              </div>
            )}
        </CardContent>
      </Card>
      <Divider className="dense" />
      <Ingredients
        docId={props.id}
        servingData={servingData}
        doc={doc}
        isPreview={isPreview}
        draftFeature={draftFeature}
        handleDraft={handleDraft}
        handlePublish={handlePublish}
        showingDoc={showingDoc}
        toggleDoc={toggleDoc}
        draftDoc={draftDoc}
        isDraftAvailable={isDraftAvailable}
        stopPublishPipeline={stopPublishPipeline}
        handleServingChange={handleServingChange}
      />

      {(!isPreview || (isPreview && _hasSteps)) && <Divider />}
      {(!isPreview || (isPreview && _hasSteps)) && (
        <Card square elevation={0}>
          <CardHeader
            title="How to Prepare"
            action={
              isPreview ? null : _hasSteps ? (
                <IconButton onClick={()=>handleEdit(PREPARE)}>
                  {<DetailEditIcon />}
                </IconButton>
              ) : (
                <IconButton onClick={()=>handleEdit(PREPARE)}>
                  <img src={addIcon} alt="" />
                </IconButton>
              )
            }
            classes={{
              root: "sub-header-root",
              title: "sub-title",
              action: "margin-top-negative",
            }}
          />
          <CardContent>
            {_hasSteps ? (
              <VerticalStepper steps={doc.steps} className="desc" />
            ) : (
              <PlaceholderContent
                labelClassName="font_16_600"
                subLabelClassName="font_13_500"
                className="align-items-center d-flex flex-column fpb-30 fpt-33"
                img={HowToPrepareImg}
                label="Add Preparation Steps"
                subLabel="Click on ‘+’ icon to add preparation steps of the recipe"
              />
            )}
          </CardContent>
        </Card>
      )}
      {prepareMode && (
        <RecipesStep
          docId={props.id}
          open={prepareMode}
          onClose={() => setPrepareMode(false)}
          steps={doc.steps ? doc.steps : []}
          // updateParent={updateParent}
          cid={props.cid}
          draftFeature={draftFeature}
          handleDraft={handleDraft}
          handlePublish={handlePublish}
          isDraftAvailable={isDraftAvailable}
        />
      )}
      {showProgressWarning && <Confirmation
        open
        handleClose={closeEditPopup}
        handleChange={()=>confirmEdit()}//intentional syntax(check default param in confirmEdit)
        handleCancel={closeEditPopup}
        handle
        title="Warning"
        msg={TO_PUBLISH_WARNING_MSG}
        confirmOption="Yes, Continue"
        loading={stopPublishLoader}
        />}
    </DetailPgStyling>
  );

}

export default RecipeDetails;
