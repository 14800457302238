import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import React, { useContext, useEffect, useMemo, useState} from 'react';
import _ from 'lodash';
import firebase from 'fitbud/firebase';
import appRdxFns from 'fitbud/redux/app';
import { useDispatch } from 'react-redux';
import { dbToUi, getAccessStatus } from './utils';
import { useSnackbar } from 'notistack';
import { DEFAULT_ERROR } from 'fitbud/utils/constants';
import update from "immutability-helper";
import {getDefaultScheduleSpace, DEFAULT_EXPLORE_SETTING, DEFAULT_EXPLORE_CONFIG} from "../exploreProvider"; 
import * as Sentry from '@sentry/browser';

const ExploreConfigContext = React.createContext({});
export const useExploreConfig = () => useContext(ExploreConfigContext);

const getExploreTypeDocRef = (type,cid)=>{
  return firebase.firestore().doc(`companies/${cid}/explore/${type}`);
}

const ExploreConfigProvider = (props) => {
  const d = useDispatch();
  const { showLoader, hideLoader } = appRdxFns(d);
  const { cid, comp, refreshco } = useContext(FirebaseAuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const compData = comp.data();
  const exploreConfig = _.get(compData, 'explore_config');
  const exploreSettings = _.get(compData, 'explore_settings');
  const companyRef = firebase.firestore().doc(`companies/${cid}`);
  const [exploreData, setExploreData] = useState(null);
  const [accessData, setAccessData] = useState(null);
  const [loading, setLoading] = useState('initial');
  const exploreTabs = useMemo(()=>{
    if (!exploreConfig || _.isEmpty(exploreConfig)) return ['workout', 'nutrition', 'resources'];
    const out = _.chain(exploreConfig)
      .toPairs()
      .orderBy((x) => x[1].position)
      .map((x) => x[0])
      .value();
    return out;
  },[exploreConfig]);
  //mapping of tabs according to parent_type 
  const TABS_PARENT_TYPE_MAPPING = useMemo(()=>{
    if(_.isEmpty(exploreConfig)) return  {workout:["workout"], resources:["resources"], nutrition:["nutrition"]}
    const out = {workout:[], resources:[], nutrition:[]}; //these are possible parent type:
    Object.keys(exploreConfig).forEach((key)=>{
      const parentType = _.get(exploreConfig, `${key}.parent_type`, key);
      out[parentType].push(key);
    })
    return out; 
  },exploreConfig)

  const updateExploreExConfig = async (out, cb) => {
    const {app_config, explore_settings, ...rest} = out || {};
    const _out = {};
    if (!_.isEmpty(rest)) _out.explore_config = rest;
    if (explore_settings && !_.isEmpty(explore_settings)) _out.explore_settings = explore_settings;
    if (app_config && app_config.strings_config && !_.isEmpty(app_config.strings_config)) {
      _out.app_config = app_config;
      if (app_config.strings_config.exploreTitle && _out.explore_config) {
        exploreTabs.forEach((key) => {
          if (rest[key]) rest[key].only_tab_title = app_config.strings_config.exploreTitle;
        });
      }
    }
    if (_.isEmpty(_out)) return;
    showLoader();
    await companyRef.set(_out, { merge: true });
    await refreshco();
    cb && cb();
    hideLoader();
  };

  const updateExploreSetting = async (out, cb) => {
    const _out = {
      explore_settings: out,
    };
    showLoader();
    await companyRef.set(_out, { merge: true });
    await refreshco();
    cb && cb();
    hideLoader();
  };

  const updateCollectionSpace = async (out, type) =>{
    const ref = getExploreTypeDocRef(type,cid);
    const scSpace = _.get(exploreData, `${type}.collectionspace`)
    const rest = !scSpace ? getDefaultScheduleSpace(type) : {}
    const _updatedOut = {...rest, ...out}
    await ref.set({
      schedulespace:{
        ..._updatedOut,
      }
    }, { merge: true });
    await ref.get();
    const _exData = update(exploreData,{ //keeping update local state
      [type]:{
        schedulespace:{
          $merge: _updatedOut
        }
      }
    })
    setExploreData(_exData)
  }

  const updateTitles = async (exConfig, type, separatorConfig, cb)=>{
    const promise = [updateExploreExConfig(exConfig)];
    if(type !== "resources"){
      promise.push(updateCollectionSpace(separatorConfig,type))
    }
    try {
      showLoader();
      await Promise.all(promise);
      cb && cb();
    } catch (err) {
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
      Sentry.captureException(err);
    }
    hideLoader();
  };

  const setDefaultConfig = async ()=>{
    const out = {};
    if (_.isEmpty(exploreSettings)) out.explore_settings = DEFAULT_EXPLORE_SETTING;
    if (_.isEmpty(exploreConfig)) out.explore_config = DEFAULT_EXPLORE_CONFIG;
    if (!_.isEmpty(out)) {
      try {
        await companyRef.set(out, { merge: true });
        refreshco();
      } catch (err) {
        enqueueSnackbar('Something seems to have gone wrong. Please refresh page', { variant: 'error' });
      }
    }
  }

  useEffect(()=>{
     setDefaultConfig();
  },[cid])

  useEffect(() => {
    let unmount = false;
    const promises = exploreTabs.map((type) => getExploreTypeDocRef(type,cid).get()); //fetch explore data for all config keys
    Promise.all(promises)
      .then((args) => {
        let _out = {};
        (args || []).forEach((docSnap, index) => {
          if (!!docSnap && docSnap.exists) _out[exploreTabs[index]] = docSnap.data();
        });
        const accessData = dbToUi(_out, exploreConfig);
        if (!unmount && !_.isEmpty(_out)) {
          setExploreData(_out);
          setAccessData(accessData);
        }
      })
      .finally((err) => {
        setLoading(false);
      });

    return () => {
      unmount = true;
    };

  }, [cid]);

  const savePlanAccessToDB = async ({ id, data }) => {
    const keys = Object.keys(data);
    if(!keys.length || !id) return;
    const status = getAccessStatus(data, false);
    let explore_config_data = {explore_settings: {acl:true}}; // lead access is automatically turned off and acl turned onn when plan access is turned on, removed.
    const promises = keys.map((key) => {
      const docRef = getExploreTypeDocRef(key,cid);
      if(!docRef) return null;
      const dataToSave = _.get(data, key, {});
      explore_config_data[key] = { packs: { [id]: status[key] !== "no", } };
      return docRef.set({ access: { [id]: { ...dataToSave }}}, { merge: true });
    });
    promises.push(updateExploreExConfig(explore_config_data));
    try {
      showLoader();
      await Promise.all(promises);
      setAccessData(prev => ({ ...prev, [id]: { ...data }}));
      hideLoader();
      enqueueSnackbar("Configuration updated", { variant: 'success'});
    } catch(e) { hideLoader();
      Sentry.captureException(e);
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error'});
    }
  };

  const verifyAcl = async (access)=>{
    const prevHasAcl = !_.isEmpty(accessData) //current state
    const hasAcl =  !_.isEmpty(access); //upcoming state
    if(hasAcl !== prevHasAcl){
      await updateExploreExConfig({explore_settings:{acl:hasAcl }})
    }
  };

  const removePlanAccessToDB = async (plan_id) => {
    if(!plan_id) return;
    let explore_config_data = {};
    const promises = exploreTabs.map((key)=>{
      explore_config_data[key] ={packs: { [plan_id]: firebase.firestore.FieldValue.delete() }}
      return getExploreTypeDocRef(key,cid).set({ access: { [plan_id]: firebase.firestore.FieldValue.delete() }}, { merge: true })
    })
    promises.push(updateExploreExConfig(explore_config_data));
    try { showLoader();
      await Promise.all(promises);
      const outAccessData = {...accessData};
      if(!!outAccessData[plan_id]){
        delete outAccessData[plan_id];
      }
      setAccessData(outAccessData);
      await verifyAcl(outAccessData);
      hideLoader()
    } catch(err) {
      Sentry.captureException(err);
      enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
    }
  };

  const removeAllPlanAccess = async() => {
    let explore_config_data = {explore_settings:{acl:false}};
    const promises = exploreTabs.map(key=>{
      explore_config_data[key] = { packs: firebase.firestore.FieldValue.delete() }; 
       return getExploreTypeDocRef(key,cid).set( { access: firebase.firestore.FieldValue.delete() }, { merge: true });
    })
    promises.push(updateExploreExConfig(explore_config_data));
    try { showLoader();
      await Promise.all(promises);
      setAccessData({});
      hideLoader();
    } catch(err) {
      Sentry.captureException(err);
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
    }
  }

  return (
    <ExploreConfigContext.Provider
      value={{ 
        exploreConfig, exploreSettings, exploreData, loading, accessData,
        updateExploreExConfig, updateExploreSetting, savePlanAccessToDB, 
        setLoading, removePlanAccessToDB, removeAllPlanAccess,updateTitles,
        exploreTabs, TABS_PARENT_TYPE_MAPPING, 
      }}
    >
      {props.children}
    </ExploreConfigContext.Provider>
  );
};

export default ExploreConfigProvider;
