import UserList from 'fitbud/components/userList';
import { RoleContext } from 'fitbud/providers/roleProvider';
import _ from 'lodash';
import React, { useContext, useMemo } from 'react';

export default (props) => {
  const { selectUser, comp, cid, tEnabled, ownerId: uid } = props;
  const { isOwner, allClientAccess, isTrainer } = useContext(RoleContext);

  const isIntroCall = useMemo(() => {
    const intro_call = _.get(comp ? comp.data() : {}, 'features.intro_call', false);
    return intro_call && intro_call > 0;
  }, [comp]);

  const defaultFilter = useMemo(() => {
    if (!tEnabled) return {};
    if (isOwner) return {};
    if (!isOwner && isTrainer && allClientAccess) return {};
    return { trainers: [uid] };
  }, [tEnabled, uid, isOwner, isTrainer, allClientAccess]);

  return (
    <UserList
      directSearchMode
      cid={cid}
      keyName={isIntroCall ? 'browse' : 'onboarded'}
      fullWidth
      hideFilter
      disableTagSearch
      placeholder="Search user by name or email"
      onSelect={selectUser}
      searchPlaceholderText="Search a client to schedule a video call"
      defaultFilters={defaultFilter}
    />
  );
};
