import { bindActionCreators } from "redux";
import axnsBuilder from "./actions";
const axns = axnsBuilder();
const actionCreators = {
  markRead: key => ({ type: axns.markRead, key }),
  addNew: key => ({ type: axns.addNew, key }),
  set: payload => ({ type: axns.set, payload })
};

export default dispatch => bindActionCreators(actionCreators, dispatch);
