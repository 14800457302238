import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const CompressIcon = props => (
    <SvgIcon
        {...props}
        viewBox="0 0 14 14"
        classes={{
          root: "small"
        }}
    >
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 1L1 7L7 13" stroke="#8F969F" stroke-opacity="0.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13 1L7 7L13 13" stroke="#8F969F" stroke-opacity="0.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>

    </SvgIcon>
);
