import React, { useContext, useMemo } from 'react';
import {
  Dialog, DialogTitle, DialogContent,
  IconButton, makeStyles, Button, Hidden,
  LinearProgress, CircularProgress, Typography, Tooltip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useToggle } from 'fitbud/hooks/form';
import Confirmation from 'fitbud/components/confirmationDialog';
import { UserSchContext } from '../provider';
import WorkoutAlts from '../workoutAlts';
import AxnBar from './axnBar';
import WeekList from './week-list';
import WeekDays from './weekdays';
import AddWeeksDialog from './addWeeksDialog';
import Picker from './picker';
import RHS from './rhs';
import { CardEditor } from './card-editor';
import eyeIcon from 'fitbud/images/eye.svg';
import { useUserContext } from 'fitbud/views/users/detail';
import {CopyMsg} from "./axnBar";
import { ClrdButton } from 'fitbud/components/form-fields';

const styles = makeStyles(theme => ({
  root: {
    height:"100vh",
    '& .header': { height: 40 },
    '& .left': { minWidth: 260, maxWidth:260, flex:1, minHeight:0 },
    '& .bar': { height: 44 },
    '& .day': { height: 160 },
    '& .daybar': { height: 20 },
    '& .rhsbar': { height: 20 },
    '& .srcsch': { maxWidth: 190 },
    '& .right': { width: '25%', height: 'calc(100vh - 140px)', minWidth: 280 },
    '& #main': { height: "100%" },
    [theme.breakpoints.down('sm')]: {
      '& #main': {  },
      '& .left': { minWidth: 200, maxWidth:200 },
      '& .right': { width: '25%', minWidth: 240 },
    },
    [theme.breakpoints.down('xs')]: {
      // '& #main': { width: '100vw', height: 'calc(100vh - 140px)' },
      '& .right': { width: '25%', minWidth: 280 },
    },
  },
}));

export const getCopyingData=(opcopy,currWeek,weekToday)=>{
  if (!opcopy) return [];
  const { week: srcWeek, day, purpose } = opcopy;
  if (day) {
    switch (purpose) {
      case 'wo': return [true, `Copying Workouts from Week ${srcWeek + 1} Day ${day}`];
      case 'ml': return [true, `Copying Meals from Week ${srcWeek + 1} Day ${day}`];
      case 'singleWO': return [true, `Copying a Workout from Week ${srcWeek + 1} Day ${day}`];
      case 'singleML': return [true, `Copying a Meal from Week ${srcWeek + 1} Day ${day}`];
      default: return [true, `Copying from Week ${srcWeek + 1} Day ${day}`];
    }
  } else {
    //week
    const canPasteHere = currWeek >= weekToday && srcWeek !== currWeek;
    switch (purpose) {
      case 'wo': return [true, `Copying Workouts from Week ${srcWeek + 1}`, canPasteHere];
      case 'ml': return [true, `Copying Meals from Week ${srcWeek + 1}`, canPasteHere];
      case 'sl': return [true, `Copying Supplements from Week ${srcWeek + 1}`, canPasteHere];
      case 'both': return [true, `Copying from Week ${srcWeek + 1}`, canPasteHere];
      default: return [];
    }
  }
}

const Editor = ({profile={}}) => {
  const classes = styles();
  const { toggleProfileDrawer} = useUserContext();
  const {name}=profile;
  const { ready,opimport, currWeek, weekReady,weekToday, block, halt, opmngalt, opconfirm,opcopy,controller,...rest } = useContext(UserSchContext);
  const { confirmation } = opconfirm || {};
  const [rhs, toggleRHS] = useToggle(false);
  const [copying,copyTitle,target]=useMemo(()=>getCopyingData(opcopy,currWeek,weekToday)
  ,[currWeek, weekToday, opcopy])
  
  if (!ready || typeof currWeek === 'undefined') return null;
  return (
    <Dialog open fullScreen disableEscapeKeyDown disableBackdropClick onClose={controller.closeEdit} scroll='paper'
      className={classes.root}>
      <DialogTitle disableTypography className='p-0'>
        <div className='header d-flex flex-row pl-10 border-bottom-2 align-items-center justify-content-between'>
          <div className='d-flex align-items-center '>
            <IconButton disabled={!ready || block || halt} onClick={controller.closeEdit} className="mr-16">
              <CloseIcon classes={{root: 'small'}}/>
            </IconButton>
            <Typography className="font_15_600 mr-15">{name}</Typography>
            <Tooltip title="View Client Profile">
              <IconButton onClick={toggleProfileDrawer}>
                <img src={eyeIcon} alt="view user"/>
              </IconButton>
            </Tooltip>
          </div>
            {copying && <div className='position-relative d-flex align-items-center h-100 p-0' style={{borderRadius:'10px 0px 0px 10px'}}>
              <Typography className='font_15_600 text-white h-100 d-flex align-items-center px-20' style={{backgroundColor:"#65768C",borderRadius:"inherit"}}>{copyTitle}</Typography>
              <ClrdButton color="main" classes={{root:"f-xmedium shadow-none"}} className="rounded-0" onClick={controller.copyClose}>Done</ClrdButton>
            </div>}
            {!copying && <ClrdButton color="primary" variant='contained' classes={{root:"f-xmedium shadow-none"}} className="rounded-0" 
              disabled={!ready || !weekReady || block || halt} onClick={controller.saveme}>Save &amp; Close</ClrdButton>
            }
        </div>
      </DialogTitle>
      <DialogContent className='bg-grey-new d-flex flex-column h-100 p-0'>
        <AxnBar rhs={rhs} toggleRHS={toggleRHS}/>
        <div className='d-flex flex-1'>
          <Hidden xsDown>
            <div className='left flex-shrink-0 border-right-4 overflow-y-auto'>
              <WeekList/>
            </div>
          </Hidden>
          <div className='flex-1 position-relative d-flex flex-column'>
          <div className='w-100 flex-1 position-relative'>
            <div id='main'>
              <WeekDays/>
            </div>
            {/* <Hidden xsDown>
              <div className='right flex-shrink-0 overflow-y-auto overflow-x-hidden bg-white border-left-3'>
                <RHS/>
              </div>
            </Hidden> */}
            {/* <Hidden smUp>
              {rhs && <div className='position-absolute inset-0 d-flex flex-row justify-content-end bg-transluscent-black'>
                <div className='right overflow-y-auto overflow-x-hidden bg-white border-left-3'>
                  <RHS/>
                </div>
              </div>}
            </Hidden> */}
          </div>
          {!weekReady && <LoadingOverlay/>}
          <AddWeeksDialog/>
          {opmngalt && <WorkoutAlts edit/>}
          <Picker/>
          {confirmation && <Confirmation open
            handleClose={controller.closeConfirm} handleCancel={controller.closeConfirm}
            handleChange={controller.applyConfirm} {...confirmation}/>}
          {block && <BlockingLoader/>}
          <CardEditor />
          </div>
        </div>
       
        
      </DialogContent>
    </Dialog>
  );
};

const LoadingOverlay = () => {
  return (
    <div className='position-absolute inset-0 white-overlay'>
      <LinearProgress className='w-100'/>
    </div>
  );
};
const blocker = makeStyles({
  root: {
    zIndex: '1301!important',
  },
  dialogPaper: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  dialogContentRoot: {
    backgroundColor: 'transparent',
  },
  CircularProgress: {
    color: 'white',
  },
});
const BlockingLoader = (props) => {
  const classes = blocker();
  return (
    <Dialog open disableBackdropClick disableEscapeKeyDown
    classes={{ root: classes.root, paper: classes.dialogPaper }}>
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        <CircularProgress classes={{colorPrimary: classes.CircularProgress}}/>
      </DialogContent>
    </Dialog>
  );
};
export default Editor;
