import axnsBuilder from "./actions";
export const DEFAULT_STATE = {
  docs: undefined
};

const axns = axnsBuilder();
export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case axns.setQuestions:
      return {
        ...state,
        docs: action.doc,
        timestamp: Date.now()
      };

    default:
      return state;
  }
};
