import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const PaymentsIcon = props => {
  const {stroke, ...rest} = props;
  return (
    <SvgIcon viewBox="0 0 20 20" classes={{root: 'small empty'}} {...rest}>
     <path stroke={stroke || '#8F969F'} d="M18.0324 11.9965H14.6588C13.4201 11.9957 12.4161 10.9924 12.4153 9.75374C12.4153 8.51503 13.4201 7.51177 14.6588 7.51101H18.0324" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
     <path stroke={stroke || '#8F969F'} d="M15.0405 9.7024H14.7808" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
     <path stroke={stroke || '#8F969F'} fill-rule="evenodd" clip-rule="evenodd" d="M6.45637 2.5H13.6592C16.0744 2.5 18.0323 4.45792 18.0323 6.87305V12.8539C18.0323 15.269 16.0744 17.227 13.6592 17.227H6.45637C4.04124 17.227 2.08331 15.269 2.08331 12.8539V6.87305C2.08331 4.45792 4.04124 2.5 6.45637 2.5Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
     <path stroke={stroke || '#8F969F'} d="M5.86298 6.28181H10.3621" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </SvgIcon>
  );
};
