import React, { useState,useContext } from 'react';
import Dialog from 'fitbud/components/Dialog';
import _ from 'lodash';
import { Button, Typography } from '@material-ui/core';
import SimpleInnerFilter from './simple-inner-filter';
import ListInnerFilter from './list-inner-filter';
import { FOOD_RECIPES_FILTER_CONFIG, MEAL_FILTER_CONFIG, FOOD_FILTER_CONFIG, RECIPES_FILTER_CONFIG, MEAL_FOOD_FILTER_CONFIG } from './nutrition-filter-config';
import {EXERCISE_FILTER_CONFIG, WORKOUT_FILTER_CONFIG, CARDIO_FILTER_CONFIG, WORKOUT_CARDIO_FILTER_CONFIG} from './fitness-filter-config';
import {FirebaseAuthContext} from "fitbud/providers/firebase-auth";
import {PLAN_FILTER_CONFIG} from "./plan-filter-config";
import {SERVICE_FILTER_CONFIG} from "./service-filter-config";

const PUBLISH_STATUS_OBJ={
  label: 'Publish Status',
  subType: 'publish_status',
  filterType: "single_select",
  subOptions:[
    {
      label:"Draft",
      value:"draft"
    },
    {
      label:"Published",
      value:"published"
    }
  ]
};

export const getFilterConfig = (key,filterConfig,draftFeature=false) => {
  let newArr=[];
  if (key === 'exercises' )  newArr=_.cloneDeep(EXERCISE_FILTER_CONFIG);
  else if (key === "workouts") newArr=_.cloneDeep(WORKOUT_FILTER_CONFIG);
  else if (key === "wocardio") newArr= _.cloneDeep(WORKOUT_CARDIO_FILTER_CONFIG);
  else if (key === 'foodRecipesSupps') newArr= _.cloneDeep(FOOD_RECIPES_FILTER_CONFIG);
  else if (key === "cardio") newArr= _.cloneDeep(CARDIO_FILTER_CONFIG);
  else if (key === "meals") newArr= _.cloneDeep(MEAL_FILTER_CONFIG);
  else if (key === "foods") newArr= _.cloneDeep(FOOD_FILTER_CONFIG);
  else if (key === "recipes" || key === "foodRecipes") newArr= _.cloneDeep(RECIPES_FILTER_CONFIG);
  else if (key === "foodMeals") newArr= _.cloneDeep(MEAL_FOOD_FILTER_CONFIG);
  else if (key==="plan") newArr=_.cloneDeep(PLAN_FILTER_CONFIG(filterConfig));
  else if (key==="service") newArr=_.cloneDeep(SERVICE_FILTER_CONFIG);
  else newArr=null;
  if(!draftFeature || !newArr) return newArr;
  else{
    //assuming structure in config files
    const directPush=['cardio','foodRecipesSupps','foods','recipes','foodRecipes','meals','foodMeals','plan'].includes(key);
    if(directPush){
      newArr.push({...PUBLISH_STATUS_OBJ})
    }
    else{
      newArr[0].options.push({...PUBLISH_STATUS_OBJ})
    }
    return newArr;
  }
};

export const getFilterKey = (keyName) => {
  if (keyName === 'foodRecipesSupps' || keyName === "foods" || keyName === "recipes" || keyName === "foodRecipes") return 'foodrecipe_filters';
  else if(keyName === "exercises") return 'exercise_filters';
  else if (keyName === "workouts" || keyName === "wocardio") return 'workout_filters';
  else if (keyName === "cardio") return 'workout_filters';
  else if (keyName === "meals" || keyName === "foodMeals") return 'meal_filters';
  else if (keyName === "plan") return "plan";
  else if (keyName === "service") return "service";
  else if (keyName === "challenges") return "challenge_filters"
};

const CatalogFilter = (props) => {
  const {comp}=useContext(FirebaseAuthContext);
  const draftFeature=!!comp.data().features.draft;
  const { open, handleClose, onSave, title, actionText, keyName, filters: initialFilters, ignoreFilter,filterConfig } = props;
  const _filterKeyName = getFilterKey(keyName);
  const isLinearListFiler = keyName === "exercises" || keyName === "workouts" || keyName === "wocardio"; //key to decide is filter contains inner list or not
  const [filters, setFilters] = useState(() => {
    if (!initialFilters) return {};
    return initialFilters[_filterKeyName] || {};
  });
  const onSelectItem = (args) => {
    const { subType, value, filterType } = args;
    let _subTypeValues = _.get(filters, subType, []);
    if (_.includes(_subTypeValues, value)) {
      _subTypeValues = _.filter(_subTypeValues, (v) => v !== value);
    } else if(filterType==='single_select'){
      _subTypeValues[0]=value;
    }
    else {
      _subTypeValues.push(value);
    }
    setFilters({ ...filters, [subType]: _subTypeValues });
  };

  const _handleSave = () => {
    onSave &&
      onSave({
        [_filterKeyName]: filters,
      });
    handleClose && handleClose();
  };

  const resetFilter = () => {
    setFilters({});
  };

  const getAdditionalActions = () => {
    return (
      <Button onClick={resetFilter} className="fmx-10">
        <Typography variant="h6" color="primary">
          Clear All
        </Typography>
      </Button>
    );
  };

  const getFilterInnerOptions = () => {
    if (isLinearListFiler) {
      const _filterOption = getFilterConfig(keyName, filterConfig,draftFeature);
      if (!_filterOption) return null;
      return <ListInnerFilter ignoreFilter={ignoreFilter} filters={filters} filterOptions={_filterOption} onSelectItem={onSelectItem} />;
    }else  if (!isLinearListFiler) {
      const _filterOption = getFilterConfig(keyName, filterConfig,draftFeature);
      if (!_filterOption) return null;
      return <SimpleInnerFilter filters={filters} filterOptions={_filterOption} onSelectItem={onSelectItem} />;
    }
    return null;
  };
  

  return (
    <Dialog
      toolbarClass="height-60 border-bottom"
      buttonColor="primary"
      open={open}
      onClose={handleClose}
      onSave={_handleSave}
      title={title || 'Filter'}
      titleFont="h3"
      paperClass={isLinearListFiler ? "width-800 height-70":"width-600 height-60"}
      actionText={actionText || 'Apply'}
      additionalActions={getAdditionalActions()}
    >
      {getFilterInnerOptions()}
    </Dialog>
  );
};

export default CatalogFilter;
