import _ from "lodash";
import React, { useState, useContext } from "react";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  IconButton,
  CardActions,
  MenuItem,
  Menu,
  Checkbox,
  Divider
} from "@material-ui/core";
import MoreVert from "@material-ui/icons/MoreVert";
import { withSnackbar } from "notistack";
import { ClrdButton } from "fitbud/components/form-fields";
import { EditorContext } from "./supplementsForm";
import KeyDown from "@material-ui/icons/ExpandMore";
import Supplement from "./supplement";
import ReorderDialog from "fitbud/components/reorderDialog";
import clsx from "clsx";
import { DetailPgStyling } from 'fitbud/hooks/useDetailPgStyle';
import makeStyles from '@material-ui/core/styles/makeStyles';


const useEditorClasses = makeStyles(()=>({
  root:{
    '& .action_btn':{
      // backgroundColor:"#F2F4F4 !important",
      height:"40px !important ",
      padding:"9px 15px",
    }
  }

}))

const useClasses = makeStyles(() => ({
  editModeCategoryContainer: {
    border: '1px solid #DDDDDD !important',
    borderRadius: '10px',
    '& .fmr-12': {
      marginRight: 12,
    },
  },
  importMealContainer:{
    '& .import_meal_divider':{
      borderBottom: "1px solid #D1D1D1",
    },
    '& .bg_offWhite':{
      backgroundColor:"#FAFAFA"
    },
    '& .meal_selected':{
      backgroundColor: "#F0F6FE !important"
    }
  }


}));



const MealCategoryContainer = props => {
  const {
    storedSupplements,
    editorMode: isEditMode,
    handleDelete,
    isImport,
    onSelectImportSupplements,
    checkImportSupplementGroup
  } = useContext(EditorContext);
  const {
    addSupplements,
    meal,
    handleMealType,
    handleReorder,
    error,
    mealCategories,
    index,
    length
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [openAction, setActionState] = useState(false);
  const [openReorder, setReorderDialog] = useState(false);
  const isLast = index === length - 1;
  const editorClasses = useEditorClasses();
  const classes = useClasses();



  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onReorder = list => {
    let updatedSupplements = Array.from(meal.supplements);
    updatedSupplements = list.map(item =>
      updatedSupplements.find(supplementItem => {
        return item.ref.id === supplementItem.ref.id;
      })
    );
    handleReorder({
      grpIndex: props.index,
      supplements: updatedSupplements
    });
  };
  const supplements =
    meal.supplements &&
    !!storedSupplements.length &&
    meal.supplements.map((item, i) => {
      const supplementId = item.ref.id;
      const doc = _.find(storedSupplements, d => supplementId === d.id);
      return { ref: item.ref, name: doc.ref_name };
    });

  const mealType = _.find(mealCategories, d => d[0] === meal.type);

  if (!!error && (error.type || error.supplement)) {
    const errorMsg = error.type ? error.type : error.supplement;
    props.enqueueSnackbar(errorMsg, {
      variant: "error"
    });
  }

  if(isImport)
  return (
    <div className={clsx(classes.importMealContainer)}>
      <Card elevation={0} className={clsx('fpx-20 import_meal_divider', checkImportSupplementGroup(meal) && "meal_selected")}>
        <CardContent className="fp-0   position-relative">
          <div className="d-flex align-items-center justify-content-between">
            <Typography variant="h5" className="fmb-20" component="div">
              {mealType && mealType[1].value}
            </Typography>
            <Checkbox
              color="primary"
              style={{marginRight:-9}} className="ml-10"
              onClick={() => {
                onSelectImportSupplements(meal);
              }}
            />
          </div>
          <SupplementsGroup isImport={isImport} meal={meal} data-group={props.index} isEditMode={isEditMode} isLast={isLast} />
        </CardContent>
      </Card>
    </div>
  );

  if (isEditMode) {
    return (
      <DetailPgStyling className="">
        <Card className={clsx('fmb-20 fpl-30 fpx-20', classes.editModeCategoryContainer, editorClasses.root)}>
          <CardActions
            className="d-flex justify-content-between align-items-center fp-0"
            classes={{
              // root: "medium "
            }}
          >
            <div className="col-3 fp-0">
              <TextField
                className="w-100"
                name="type"
                select
                value={meal.type ? meal.type : "select"}
                variant="outlined"
                required
                SelectProps={{
                  IconComponent: KeyDown,
                  inputProps: {
                    classes: {
                      icon: "fpl-5"
                    }
                  },
                  name: "type"
                }}
                InputProps={{
                  inputProps: {
                    "data-group": props.index
                  },
                  classes: {
                    root: "height-40",
                    input: "size_15_500 height-40 select-height-40"
                  }
                }}
                onChange={e => handleMealType(props.index, e.target.value)}
              >
                <MenuItem key="select" value="select">
                  Select Time
                </MenuItem>
                {mealCategories &&
                  mealCategories.map(cat => (
                    <MenuItem key={cat[0]} value={cat[0]}>
                      {cat[1].value}
                    </MenuItem>
                  ))}
              </TextField>
            </div>
            <div className="col-6 fp-0 d-flex align-items-center justify-content-end">
              <ClrdButton
                classes={{
                  root: "f-medium"
                }}
                // variant="outlined"
                component="div"
                className="action_btn"
                onClick={() => addSupplements("food", 0)}
              >
                <Typography variant="subtitle2" color="primary">
                  Add Supplements
                </Typography>
              </ClrdButton>
              <IconButton
                onClick={e => {
                  handleClick(e);
                  setActionState(true);
                }}
                component="div"
                className="ml-10"
              >
                <MoreVert />
              </IconButton>
            </div>
          </CardActions>
          <CardContent className={clsx("fp-0 d-flex justify-content-between align-items-center position-relative", !!_.get(meal, "supplements.length", 0) && "mt-25")}>
            <SupplementsGroup
              error={error && error.supplements}
              data-group={props.index}
              meal={meal}
              enqueueSnackbar={props.enqueueSnackbar}
              isEditMode={isEditMode}
              isLast={isLast}
            />
          </CardContent>
        </Card>
        {openAction && (
          <Menu
            id="action-menu"
            anchorEl={anchorEl}
            open
            onClose={e => {
              handleClose(e);
              setActionState(false);
            }}
          >
            {meal.supplements.length > 1 && (
              <MenuItem
                onClick={e => {
                  handleClose(e);
                  setActionState(false);
                  setReorderDialog(true);
                }}
              >
                Reorder
              </MenuItem>
            )}
            <MenuItem
              onClick={e => {
                handleClose(e);
                setActionState(false);
                handleDelete({
                  type: "group",
                  grpIndex: props.index
                });
              }}
            >
              Delete
            </MenuItem>
          </Menu>
        )}
        {openReorder && (
          <ReorderDialog
            open
            listData={supplements || []}
            onClose={() => {
              setActionState(false);
              setReorderDialog(false);
            }}
            onSubmit={list => onReorder(list)}
            title="Reorder Supplement Items"
          />
        )}
      </DetailPgStyling>
    );
  } else {
    if (!storedSupplements.length) return null;
    return (
      <Card elevation={0} className={clsx("fpt-0",isEditMode && isLast && 'fpb-30',!isEditMode?"fp-0":'fp-30')}>
        <CardContent className="fp-0   position-relative">
          <Typography variant="h5" className="fmb-20" component="div">
            {mealType && mealType[1].value}
          </Typography>
          <SupplementsGroup meal={meal} data-group={props.index} isEditMode={isEditMode} isLast={isLast}/>
        </CardContent>
      </Card>
    );
  }
};

export const SupplementsGroup = props => {
  const {
    meal: { supplements },
    error,
    isEditMode,
    isLast,
    isImport
  } = props;

  const { storedSupplements } = useContext(EditorContext);
  return (
    <div className="w-100">
      {!!storedSupplements.length &&
        supplements &&
        supplements.map((item, i, thisArg) => {
          const foodRecipeId = item.ref.id;
          const supplementData = _.find(
            storedSupplements,
            d => foodRecipeId === d.id
          );
          return (
            <Supplement
              key={`_item${i}`}
              meal={item}
              qtyError={(error && error[i] && error[i].quantity) || ""}
              supplementData={supplementData}
              itemIndex={i}
              data-group={props["data-group"]}
              length={thisArg.length}
            />
          );
        })}
        {!isImport &&  !isEditMode && !isLast && <Divider className="my-25"/>}
    </div>
  );
};

export default withSnackbar(MealCategoryContainer);
