import React, { useState, useCallback, useEffect, useRef, useContext, useMemo } from "react";
import firebase from "fitbud/firebase";
import { connect } from "react-redux";
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { withSnackbar } from "notistack";
import {
  List,
  LinearProgress,
  CircularProgress,
  IconButton,
  Badge,
  Typography,
  Tooltip,
  makeStyles
} from "@material-ui/core";
import searchIcon from 'fitbud/images/searchIcon.svg';
import { LineFilterIcon } from "fitbud/icons/lineFilterIcon";
import { FilterIcon } from "fitbud/icons/filterIcon";
import { WorkerContext } from "fitbud/providers/workerProvider";
import { calculateHeight, isFilterEmpty } from "fitbud/utils/helpers";
import chatListRdxFns from "fitbud/redux/chatList";
import checkinsListRdxFns from "fitbud/redux/checkinsList";
import pendingSignInListRdxFns from "fitbud/redux/pendingSignInUsersList";
import plansToAssignListRdxFns from "fitbud/redux/plansToAssignList";
import activeUsersListRdxFns from "fitbud/redux/activeUsersList";
import inactiveUsersListRdxFns from "fitbud/redux/inactiveUsersList";
import leadsRdxFns from "fitbud/redux/leadsList";
import browseUsersRdxFns from "fitbud/redux/browseUsersList";
import onboardedUsersRdxFns from "fitbud/redux/onboardedUsersList";
import { WORKER_KEYS, ELASTIC_KEYS, getQuickFilters, EXPORTABLE, NON_FILTER_LISTS, QUICK_FILTER_MAP, NON_QUICK_FILTER_LISTS } from "fitbud/utils/constants";
import useDidMountEffect from "fitbud/hooks/useDidMountEffect";
import NoUser from "fitbud/images/no_user.svg";
import FilterView from "fitbud/components/FilterView";
import _ from "lodash";
import QuickFilters from "fitbud/components/quick-filters";
import Search from "fitbud/components/Search";
import configRdxFns from "fitbud/redux/config";
import { CheckboxFilter, QuickFilterCheckbox, DateRangeFilter } from "fitbud/components/filterComps";
import PlanFilter from 'fitbud/views/users/PlanFilter';
import AttributeFilter from 'fitbud/views/users/AttributeFilter';
import TagFilter from 'fitbud/views/users/TagFilter';
import useTrainers from "fitbud/hooks/useTrainers";
import { RoleContext } from "fitbud/providers/roleProvider";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import { fetchData } from "fitbud/api";
import RefreshIcon from "fitbud/icons/refreshIcon";
import { NavIcon } from "fitbud/partials/appBar";
import ExportClientsDialog from './export-clients-dialog';
import { useToggle } from 'fitbud/hooks/form';

const TrainerFilter = (props) => {
  const fields = [{ label: "Unassigned", value: "unassigned" }].concat(..._.map(props.trainerList, d => ({ label: d.data.name, value: d._id })))
  return <CheckboxFilter {...props} fields={fields} className="fmt-10" />
}

const FILTER_OPTIONS = (props) => {
  return {
    "quick_filters": { text: "Client Type", component: QuickFilterCheckbox },
    "attribute_filters": { text: "Attributes", component: AttributeFilter },
    "plan": { text: "Plan", component: ["inactive", "leads"].includes(props.keyName) ? null: PlanFilter },
    "trainers": { text: "Trainer", component: props.showTrainer ? TrainerFilter : null },
    "tags": { text: "Tags", component: TagFilter },
    "startDate": { text: "Start Date", component: DateRangeFilter }
  }
}


const UserList = props => {
  //contexts
  const location = useLocation();
  const { authUser } = useContext(FirebaseAuthContext);
  const { isOwner, tEnabled, allClientAccess } = useContext(RoleContext);
  const workerContext = useContext(WorkerContext);

  //props
  const { cid, children, docs, loading, keyName, count,
    searchResult, searchCount, staff = {}, config, insertConfig,
    searchNextPage, nextPage, directSearchMode, searchPlaceholderText, onSelect, selected, listProps, 
    placeholder='Search user by name or email', defaultFilters={},
     ...rest } = props;
  //states n refs
  const dataLimit = useRef(0);
  const [isFilterOpen, toggleFilterView] = useToggle(false);
  const [isSearchOpn, toggleSearching, setSearching] = useToggle(false);
  const [filter, setFilter] = useState({});
  const isWorkerBased = WORKER_KEYS.includes(keyName);
  const hasMore = workerContext.hasMore(keyName);

  const routeFilter = _.get(location, 'state.filters', null)
  const staffDoc = staff.doc || {};

  const currentKeyRdxFns = props[keyName];
  const {data: trainerList} = useTrainers(cid);



  const scrollerRef = useCallback(node => {
    if (node !== null) {
      dataLimit.current = calculateHeight(node);
    }
  }, []);

  const queryFn = async ({ q, page, per_page = 15 }) => {
    // pass default filters directly in search from props
    const res = await fetchData('user_profiles', cid, page, q, per_page, null, 'browseUsersList', null, null, null, defaultFilters); 
    return res.data;
  }

  const handleRefresh = () => {
    if (ELASTIC_KEYS.includes(keyName) && currentKeyRdxFns) {
      currentKeyRdxFns.request(true, 0);
    }
  }

  useDidMountEffect(() => {
    if (currentKeyRdxFns) {
      if (!isFilterEmpty(filter)) currentKeyRdxFns.searching('', filter);
      else currentKeyRdxFns.resetSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyName, filter]);

  useEffect(() => {
    setSearching(false);
  }, [keyName, setSearching])

  useEffect(() => {
    setSearching(false);
    if (ELASTIC_KEYS.includes(keyName)) {
      if (currentKeyRdxFns && !(docs && docs.length)) {
        currentKeyRdxFns.toggleLoader();
        currentKeyRdxFns.request(false, dataLimit.current, null);
      }
    }
    (async () => {
      if (
        !config ||
        Object.entries(config).length === 0
      ) {
        const snapshot = await firebase
          .firestore()
          .collection("config")
          .doc("units")
          .get();
        insertConfig(snapshot.data());
      }
    })();

    return () => {
      setFilter({})
      if (currentKeyRdxFns) currentKeyRdxFns.resetSearch();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyName]);

  useEffect(() => {
    if (_.isEmpty(routeFilter)) return;
    setFilter(f => ({ ...f, ...routeFilter }))
  }, [routeFilter])

  const loadFromWorker = () => {
    workerContext.fetchNext({ cid, keyName, trainer_id: !allClientAccess && authUser.uid });
  };

  const handleScroll = e => {
    if (loading) return;
    const { count, docs, searchResult, searchCount } = props;
    if (e.target.scrollTop + e.target.clientHeight >= (e.target.scrollHeight - 100)) {
      if (!isFilterEmpty(filter)) {
        if (!!searchResult)
          if (searchCount > searchResult.length) {
            if (currentKeyRdxFns) currentKeyRdxFns.searching('', filter);
          }
      } else {
        if (WORKER_KEYS.includes(keyName)) {
          loadFromWorker();
        } else {
          if (!!docs)
            if (count > docs.length) {
              if (currentKeyRdxFns) currentKeyRdxFns.request();
            }
        }
      }
    }
  };

  const showSearchData = !isFilterEmpty(filter);
  const userCount = showSearchData ? searchCount : count
  const page = showSearchData ? searchNextPage : nextPage
  const hideChildren = loading && (!page || page < 1) && showSearchData;
  const isEmpty = ((!isFilterEmpty(filter) && (searchResult && searchResult.length === 0)) || (docs && docs.length === 0));
  if (!!directSearchMode) {
    return (
      <div className="flex-1 h-100">
        <Search
          listType="clients"
          searchPlaceholderText={searchPlaceholderText}
          onSelect={onSelect}
          directSearchMode={directSearchMode}
          keyName={!!directSearchMode ? keyName || 'browse' : 'browse'}
          queryFn={queryFn}
          placeholder={placeholder}
          toggleSearching={toggleSearching}
          {...props}
        />
      </div>
    );
  };

  return (
    <div className="h-100 d-flex flex-column position-relative">
      <ListHeader
        {...rest}
        cid={cid}
        placeholder={placeholder}
        onSelect={listProps.onSelect}
        selected={listProps.selected}
        toggleSearch={setSearching}
        openFilterView={toggleFilterView}
        loading={loading}
        filter={filter}
        setFilter={setFilter}
        userCount={userCount}
        isSearchOpn={isSearchOpn}
        queryFn={queryFn}
        keyName={keyName}
        handleRefresh={handleRefresh}
      />
      {isEmpty && !loading && (
        <div className="h-100 d-flex align-items-center justify-content-center">
          <img alt="no users" src={NoUser} />
        </div>
      )}
      <List
        disablePadding
        className="position-relative overflow-auto flex-1"
        id={keyName}
        ref={scrollerRef}
        onScroll={handleScroll}
      >
        {!!children && !hideChildren && children({ docs: (!isFilterEmpty(filter)) ? searchResult : docs, query: '', count: userCount, handleRefresh })}
        {isWorkerBased && hasMore && <div onClick={loadFromWorker} className={`text-center py-2 cursor-pointer font-weight-bold ${loading ? 'text-grey' : 'text-primary'}`}>
          {loading ? 'Loading ...' : 'Load More'}
        </div>}
      </List>
      { isFilterOpen && <FilterView isClient={true} keyName={keyName} cid={cid} open={isFilterOpen} handleClose={toggleFilterView} filter={filter} setFilter={setFilter}
      staffDoc={staffDoc} config={config} filter_opts={FILTER_OPTIONS({keyName, showTrainer: (isOwner && !!tEnabled) })} trainerList={trainerList}/> }
    </div>
  );
};
const ListName = ({ keyName, userCount }) => {
  let name = keyName;
  let count = null;
  const stats = useSelector((state) => state.home);
  if (WORKER_KEYS.includes(keyName)) {
    name = _.upperFirst(keyName);
    const unreadCount = keyName === 'chats' ? stats.newMessages : stats.newCheckins;
    count = (count || unreadCount) && ((count === 10000 ? '10K+' : unreadCount) + (keyName === 'chats' ? ' unread' : ' to review'));
  }
  else {
    //name
    switch (keyName) {
      case "browse": {
        name = "All Clients";
        break;
      }
      default: {
        const qf = QUICK_FILTER_MAP.browse.find(f => f.key === keyName)
        name = qf ? qf.label: '';
        break;
      }
    }
    //count
    count = userCount;
  }
  return (
    <Typography variant="h3" className="flex-grow-1">
      {name}{" "}
      {!_.isNil(count) && <span className="font-weight-500">({((count === 10000 ? '10K+' : (count || 0)))})</span>}
    </Typography>
  )
}
const styles = makeStyles({
  container: {
    position: 'relative',
    background: '#F2F4F7',
    padding:'0px 20px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '2px solid #D8DCE0',
    '& .topContainer':{
      display:'flex',
      justifyContent:"space-between",
      alignItems:"center",
      height:56,
    },
    '& .actionBtnsContainer':{
      display:'flex',
      marginRight:'-8px',
    }
  },
  quickFilters:{
    marginBottom:7//total margin=15px, tags have mb-8
  }
});

const ListHeader = ({ placeholder, keyName, isSearchOpn, fullWidth, loading, filter, userCount, trainer, onSelect, selected, ...rest }) => {
  const [badgeContent,setBadgeContent]=useState(0);
  const { toggleSearch, openFilterView, setFilter, queryFn, listProps, handleRefresh } = rest;
  const classes = styles();
  const trainerDocs = trainer.docs || [];
  const canReload = !WORKER_KEYS.includes(keyName);
  const [mouseIsOver, setMouseIsOver] = useState(false);
  const onMouseEnter = () => setMouseIsOver(true);
  const onMouseLeave = () => setMouseIsOver(false);
  const openSearch = () => toggleSearch(true);
  const closeSearch = () => toggleSearch(false);
  const showDownload = EXPORTABLE.includes(keyName);
  const hideFilter = !!rest.hideFilter || NON_FILTER_LISTS.includes(keyName);
  const hideQwkFilter = !!rest.hideFilter || NON_QUICK_FILTER_LISTS.includes(keyName);
  const showQwkFilters = !hideQwkFilter && (!!getQuickFilters(keyName).length || !isFilterEmpty(filter));
  useEffect(()=>{
    const keys=Object.keys(filter);
    setBadgeContent(keys.reduce((previousVal,currentVal)=>{
      const value=filter[currentVal];
      if(Array.isArray(value)){
          return previousVal+value.length;
      }
      else{
          return previousVal+Object.keys(value).length;
      }
    },0))
  },[filter])


  return (
    <>
      {isSearchOpn && <Search placeholder={placeholder} title="Clients" closeSearch={closeSearch} queryFn={queryFn} keyName="browse" listType="clients" listProps={{ ...listProps, handleRefresh }} onSelect={onSelect} selected={selected}/>}
      <div className={classes.container} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <div className="topContainer">
          <NavIcon/>
          <ListName keyName={keyName} userCount={userCount} />
          <div className="actionBtnsContainer">
            {canReload && mouseIsOver && <>
              <Tooltip title='Refresh'><IconButton onClick={handleRefresh}><RefreshIcon/></IconButton></Tooltip>
              {showDownload && <ExportClientsDialog cid={rest.cid} storeKey={keyName}/>}
            </>}
            {
              !hideFilter && <Tooltip title='Filters'>
                <IconButton onClick={openFilterView}>
                  <Badge color="primary" badgeContent={badgeContent}>
                    {isFilterEmpty(filter) ? <LineFilterIcon /> : <FilterIcon />}
                  </Badge>
                </IconButton>
              </Tooltip>
            }
            {<Tooltip title='Search'><IconButton onClick={openSearch} >
              <img alt="search-icon" src={searchIcon}/>
            </IconButton></Tooltip>}
          </div>
        </div>
          {showQwkFilters && <QuickFilters keyName={keyName} filter={filter} setFilter={setFilter} extraProps={{ trainers: trainerDocs }} overrides={{container:classes.quickFilters}}/>}
          {!!loading && <LinearProgress className={"position-absolute w-100 height-2"} style={{ left: 0, bottom: -2 }} />} 
      </div>
    </>
  )
}


const mapStateToProps = (s, op) => {
  const {
    onboardedUsersList,
    leadsList,
    activeUsersList,
    inactiveUsersList,
    plansToAssignList,
    pendingSignInList,
    chatList,
    checkinsList,
    staff,
    trainer,
    config
  } = s;
  let stateObject = {};
  switch (op.keyName) {
    case "chats":
      stateObject = chatList;
      break;
    case "checkins":
      stateObject = checkinsList;
      break;
    case "pendingSignIn":
      stateObject = pendingSignInList;
      break;
    case "plansToAssign":
      stateObject = plansToAssignList;
      break;
    case "active":
      stateObject = activeUsersList;
      break;
    case "inactive":
      stateObject = inactiveUsersList;
      break;
    case "leads":
      stateObject = leadsList;
      break;
    case "onboarded":
      stateObject = onboardedUsersList;
      break;
    default:
      break;
  }
  let docs, loading, count, query, searchResult, searchCount, nextPage, searchNextPage;
  docs = stateObject.docs;
  count = stateObject.count;
  searchResult = stateObject.searchResult;
  searchCount = stateObject.searchCount;
  query = stateObject.query;
  loading = stateObject.loading;
  nextPage = stateObject.nextPage;
  searchNextPage = stateObject.searchNextPage
  // if (!!docs) {
  return { docs, loading, count, query, searchCount, searchResult, staff, trainer, config: _.get(config, "docs[0]"), nextPage, searchNextPage };
  // } else return { docs, loading, staff, config: _.get(config, "docs[0]") };
};
const mapDispatchToProps = d => {
  const { insert: insertConfig } = configRdxFns(d);
  return {
    chats: { ...chatListRdxFns(d) },
    checkins: { ...checkinsListRdxFns(d) },
    browse: { ...browseUsersRdxFns(d) },
    plansToAssign: { ...plansToAssignListRdxFns(d) },
    pendingSignIn: { ...pendingSignInListRdxFns(d) },
    active: { ...activeUsersListRdxFns(d) },
    inactive: { ...inactiveUsersListRdxFns(d) },
    leads: { ...leadsRdxFns(d) },
    onboarded: { ...onboardedUsersRdxFns(d) },
    insertConfig
  };
};

export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(UserList)
);
