import { IconButton, Menu, MenuItem } from '@material-ui/core';
import Confirmation from 'fitbud/components/confirmationDialog';
import OptionsIcon from 'fitbud/icons/verticalOptions';
import React, { useState } from 'react';

const DEFAULT_DIALOG = {
  open: false,
  name: '',
};

const menuOptions = {
  delete: {
    dialogTitle: `Delete In-App Notification`,
    dialogDescription: `Are you sure you want to delete the in app notification?`,
    confirmText: 'Yes, Delete',
  }
};

export default function Options({ onDelete, style }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmationDialog, setConfirmationDialog] = useState(DEFAULT_DIALOG);
  const open = Boolean(anchorEl);
  const { dialogTitle, dialogDescription, confirmText } = menuOptions[confirmationDialog.name] || {};

  const handleOptionClick = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const openConfirmation = (dialogName) => {
    setConfirmationDialog({
      open: true,
      name: dialogName,
    });
  };

  const closeConfirmation = () => {
    setConfirmationDialog(DEFAULT_DIALOG);
    setAnchorEl(null);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleSubmit = () => {
    onDelete();
    closeConfirmation();
  };

  return (
    <>
      <IconButton onClick={handleOptionClick} style={style}>
        <OptionsIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem dense onClick={() => openConfirmation('delete')}>
          Delete
        </MenuItem>
      </Menu>
      {confirmationDialog.open && (
        <Confirmation
          open
          handleChange={handleSubmit}
          handleCancel={closeConfirmation}
          title={dialogTitle}
          msg={dialogDescription}
          confirmOption={confirmText}
        />
      )}
    </>
  );
}
