import React from 'react';
import {makeStyles,Typography} from '@material-ui/core';
import clsx from 'clsx';

const styles=makeStyles(theme=>({
    draftText:{
        color:"#F29900",
    },
    workoutTag:{
        background:"#FFF",
        border:`1px solid ${theme.palette.primary.main}`,
        color:theme.palette.primary.main,
        fontWeight:600,
        borderRadius:40,
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
    },
    small:{
        height:20,
        width:43,
        fontSize:10
    },
    medium:{
        height:30,
        width:59,
        fontSize:13
    }
}));

export const DraftTag=({className,size="small"})=>{
    const classes=styles();
    return(<div className={clsx(classes.workoutTag,classes[size],className)}>Draft</div>)
}
export const DraftText=({className})=>{
    const classes=styles();
    return (
        <Typography className={clsx("font_13_600",classes.draftText,className)}>Draft</Typography>
    )
}