import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const ClientsIcon = props => {
  const {stroke, ...rest} = props;
  return (
    <SvgIcon viewBox="0 0 20 20" classes={{root: 'small empty'}} {...rest}>
      <path stroke={stroke || '#8F969F'} d="M14.9064 9.0806C16.0689 8.91726 16.9639 7.9206 16.9664 6.7131C16.9664 5.5231 16.0989 4.53643 14.9614 4.34976" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path stroke={stroke || '#8F969F'} d="M16.4404 11.8752C17.5663 12.0436 18.3521 12.4377 18.3521 13.2502C18.3521 13.8094 17.9821 14.1727 17.3838 14.4011" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path stroke={stroke || '#8F969F'} fill-rule="evenodd" clip-rule="evenodd" d="M9.90559 12.2198C7.22726 12.2198 4.93976 12.6257 4.93976 14.2465C4.93976 15.8665 7.21309 16.284 9.90559 16.284C12.5839 16.284 14.8706 15.8823 14.8706 14.2607C14.8706 12.639 12.5981 12.2198 9.90559 12.2198Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path stroke={stroke || '#8F969F'} fill-rule="evenodd" clip-rule="evenodd" d="M9.90561 9.9066C11.6631 9.9066 13.0881 8.48243 13.0881 6.7241C13.0881 4.9666 11.6631 3.5416 9.90561 3.5416C8.14811 3.5416 6.72311 4.9666 6.72311 6.7241C6.71644 8.47576 8.13061 9.90076 9.88227 9.9066H9.90561Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path stroke={stroke || '#8F969F'} d="M4.90399 9.0806C3.74066 8.91726 2.84649 7.9206 2.84399 6.7131C2.84399 5.5231 3.71149 4.53643 4.84899 4.34976" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path stroke={stroke || '#8F969F'} d="M3.36992 11.8752C2.24409 12.0436 1.45825 12.4377 1.45825 13.2502C1.45825 13.8094 1.82825 14.1727 2.42659 14.4011" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </SvgIcon>
  );
};
