import React, { useRef, useState } from "react";
import {
  TextField,
  makeStyles,
  Typography,
  InputAdornment,
  InputBase,
} from "@material-ui/core";
import { FormTextField } from "fitbud/components/form-fields";

const styles = makeStyles({
  mainInput: {
    display: "flex",
    alignItems: "center",
    padding: "0px 8px",
    flex: 1,
    minWidth: "0px",
    "& input": {
      maxWidth: "20px",
      textAlign: "center",
    },
  },
});

const TempoInput = ({ onChange, value = [] }) => {
  const [currValue, updateValue] = useState([...value]);
  const classes = styles();
  const zeroth = useRef(null);
  const first = useRef(null);
  const second = useRef(null);
  const third = useRef(null);

  const getNextRef = (id) => {
    switch (id) {
      case "0":
        return first;
      case "1":
        return second;
      case "2":
        return third;
    }
  };
  const getPrvRef = (id) => {
    switch (id) {
      case "1":
        return zeroth;
      case "2":
        return first;
      case "3":
        return second;
    }
  };
  const valid = (i) => {
    const allowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "x", "X"];
    return allowed.includes(i);
  };
  const handleChange = (e) => {
    let { value: v, id } = e.target;
    const posn = Number(id);
    if (!!v) {
      if (!valid(v)) return;
      const ref = getNextRef(id);
      if (!!ref) ref.current.focus();
      v = String(v).toUpperCase();
    }
    const _val = [...currValue];
    _val[posn] = v;
    updateValue(_val);
    onChange({
      target: {
        id: "tempo",
        value: _val,
      },
    });
  };
  const handleKeyDown = (e, v) => {
    const { target, key } = e;
    const { id, value } = target;
    if (!currValue[Number(id)] && key === "Backspace") {
      const ref = getPrvRef(id);
      if (!!ref) ref.current.focus();
    }
  };
  const focus = (e) => {
    if (!e.target.id) {
      first.current.focus();
    }
  };
  return (
    <div className={classes.mainInput} onClick={focus}>
      <InputBase
        value={currValue[0]}
        id="0"
        inputRef={zeroth}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder="X"
        className="font_15_600"
      />
      <Typography className="font_16_400 mx-5 text-grey">-</Typography>
      <InputBase
        value={currValue[1]}
        id="1"
        inputRef={first}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder="X"
        className="font_15_600"
      />
      <Typography className="font_16_400 mx-5 text-grey">-</Typography>
      <InputBase
        value={currValue[2]}
        id="2"
        inputRef={second}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder="X"
        className="font_15_600"
      />
      <Typography className="font_16_400 mx-5 text-grey">-</Typography>
      <InputBase
        value={currValue[3]}
        id="3"
        inputRef={third}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder="X"
        className="font_15_600"
      />
    </div>
  );
};

export const Tempo = (props) => {
  const { value, onChange, error, label="" } = props;
  return (
    <FormTextField fullWidth label={label} classes={{control:"mb-0"}}>
      <TextField
        fullWidth={true}
        onChange={onChange}
        value={value}
        variant="outlined"
        name="tempo"
        InputProps={{
          inputComponent: TempoInput,
          classes: {
            root: "height-40",
            input: "size_15_600 height-40",
          },
          endAdornment: <Typography className="font_13_500 text-grey">EPCP</Typography>,
        }}
        error={error}
      />
    </FormTextField>
  );
};
