import React from 'react';
import UseIsTrackEnabled from './useIsTrackEnabled';
import useTrack from './useTrack';
import AudioTrack from './audioTrack';
import VideoTrack from './videoTrack';

const Publication = ({ publication, isLocal, disableAudio, videoPriority, isLocalParticipant }) => {
  const track = useTrack(publication);
  const isTrackEnabled = UseIsTrackEnabled(track);
  if (!track || (track.kind === "video" && !isTrackEnabled)) return null;
  
  switch (track.kind) {
    case 'video':
      return (
        <VideoTrack
          track={track}
          isLocal={track.name.includes('camera') && isLocalParticipant}
        />
      );
    case 'audio':
      return <AudioTrack track={track} />;
    default:
      return null;
  }
}

export default Publication;
