import { Grid, Typography } from "@material-ui/core";
import Dialog from "fitbud/components/Dialog";
import { ClrdButton } from "fitbud/components/form-fields";
import { useLiveSessions } from "fitbud/providers/liveSessionsProvider";
import { DEFAULT_ERROR } from "fitbud/utils/constants";
import { cancelBooking, monthNames } from "fitbud/utils/scheduling";
import { useSnackbar } from "notistack";
import React from "react";

export default function CancelDialog(props) {
  const { session, date, cancelMode, onConfirm,  ...restProps } = props;
  const {showBackdrop, hideBackDrop, cid } = useLiveSessions();
  const { enqueueSnackbar } = useSnackbar();

  async function confirmCancel() {
    if(!cancelMode) return onConfirm({noCancel: false});
    showBackdrop();
    const res = await cancelBooking(cid, (session && session.startDate) || date, session.book_id);
    if (!res || !res.success) {
      enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
      restProps.onClose();
      return hideBackDrop();
    }
    restProps.onClose();
    hideBackDrop();
    return enqueueSnackbar("Booking Cancelled", { variant: "success" });
  }

  return (
    <Dialog fullWidth maxWidth="sm" toolbarClass="height-60" {...restProps}>
      <Grid container justify="center" style={{paddingBottom: '55px', paddingTop:"55px"}}>
        <Grid item xs={12} sm={8} container spacing={3} justify="center" className="fmy-5">
          <Grid item container justify="center">
            <Typography variant="h2">{cancelMode ? "Cancel Booking" : "Cancel Appointments"}</Typography>
          </Grid>
          <Grid item xs container justify="center" className="fmy-5">
            {cancelMode ? (
              <Typography variant="body1" align="center" color="textSecondary">
                You are going to cancel the appointment with {session.user.name} on {session.startDate.getDate()}{" "}
                {monthNames[session.startDate.getMonth()].substr(0, 3)}, {session.startDate.getFullYear()} @ {session.slot[0]}.
              </Typography>
            ) : (
              <Typography variant="body1" align="center" color="textSecondary">
                Setting as unavailable will not allow any new appointments for the day.{" "}
                Do you also want to cancel all existing appointments for {date.getDate()}{" "}
                {monthNames[date.getMonth()].substr(0, 3)}?
              </Typography>
            )}
          </Grid>
          <Grid item container justify="center" className="fmy-5">
            <ClrdButton variant="contained" color="primary" onClick={confirmCancel} className="f-large" fullWidth>
              {cancelMode ? "Cancel Booking" : "YES, Cancel Appointments"}
            </ClrdButton>
            {!cancelMode &&  <ClrdButton color="primary" onClick={onConfirm} className="f-large mt-3" fullWidth>
              NO, just set as Unavailable
            </ClrdButton>}
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}
