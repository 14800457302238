import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const style = Object.freeze({fill: 'none'});

export const UpArrowIcon = (props) => {
  return (
    <SvgIcon fill="none" style={style} {...props}>
      <path
        d="M18.0699 9.57L11.9999 3.5L5.92993 9.57"
        stroke="#317FF5"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 20.4999V3.66992"
        stroke="#317FF5"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
};

export const UploadIconCircle = React.memo(() => (<div className='bg-white p-2 rounded-circle'><UpArrowIcon/></div>));
