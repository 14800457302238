import React,{useState,useMemo} from 'react';
import OptionsIcon from "fitbud/icons/verticalOptions"
import { IconButton } from "@material-ui/core";
import {MenuList} from "fitbud/components/menuList";
import Confirmation from "fitbud/components/confirmationDialog";

const DELETE_OPTION={ label: "Delete", value: "delete", dense: true };
const ALTS_OPTION={ label: "Manage Alternates", value: "alts", dense: true };

export const EditorExerciseOptions=(props)=>{
    const {title,className,style,onDelete,openAlternates,isAlternatesAvailable=false}=props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteConfirmation,toggleDeleteConfirmation]=useState(false);
    const openMenu=(e)=>{
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    }
    const closeMenu = () => {
        setAnchorEl(null);
    };
    const handleClick=(e,v)=>{
        switch(v){
            case "delete":{
                openConfirmation();
                break;
            }
            case "alts":{
                openAlternates();
                break;
            }
        }
        closeMenu();
    }
    const openConfirmation=()=>toggleDeleteConfirmation(true);
    const closeConfirmation=()=>toggleDeleteConfirmation(false);
    const options=useMemo(()=>{
        if(!isAlternatesAvailable){
            return([{...DELETE_OPTION}]);
        }
        else{
            return([{...DELETE_OPTION},{...ALTS_OPTION}])
        }
    },[isAlternatesAvailable])
    return(
    <>
        <IconButton onClick={openMenu} className={className} style={style}>
            <OptionsIcon />
        </IconButton> 
        <MenuList
            options={[...options]}
            anchorEl={anchorEl}
            onClose={closeMenu}
            handleClick={handleClick}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
        />
        {deleteConfirmation && (
            <Confirmation
                open={true}
                handleChange={()=>{
                    onDelete();
                    closeConfirmation();
                }}
                handleCancel={closeConfirmation}
                title={`Delete ${title}`}
                msg={`Are you sure you want to delete the ${title}?`}
                confirmOption="Yes, Delete"
            />
      )}
    </>
    )
}