import React, { useEffect, useState } from "react";
import {
  Typography,
  Card,
  makeStyles,
  Grid,
  CardContent,
  Box,
} from "@material-ui/core";
import _ from "lodash";
import Dialog from "fitbud/components/Dialog";
import moment from "moment";
import firebase from "fitbud/firebase";
import * as Sentry from "@sentry/browser";
import { snapshotDocsArrayToDataArray } from "fitbud/utils/helpers";
import NoOrderHistory from "fitbud/images/no_orderHistory.svg";
import CircularLoader from "fitbud/components/CircularLoader";
import {transform} from 'fitbud/utils/helpers';
import { durationInTypes } from "fitbud/views/schedulePlanner/helper";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 370,
    width: 370,
    display: "inline-block!important",
    marginLeft: 15,
    overflow: "visible",
    border: "1px solid #E5E5E5",
    borderRadius: "10px",
  },
  status_container: {
    borderRadius: 3,
    color: "white",
    padding: "3px 10px",
  },
  activePlanBackground: {
    background: "#E1EBFA",
    border: "none"
  },
}));

const NotFound = () => {
  return (
    <div className="d-flex justify-content-center align-items-center h-100 flex-column">
      <img src={NoOrderHistory} alt="No history" />
      <Typography className="font_20_500 fmt-30 fmb-10">
        No Purchases Found
      </Typography>
      <Typography color="textSecondary" variant="body1">
        User has not purchased any item yet
      </Typography>
    </div>
  );
};

const PlanContainer = ({
  pack,
  date,
  currPurchase,
  current_plan_status,
  index,
}) => {
  const classes = useStyles();
  const { title, mode, duration_type, duration, price, currency, discounted_price, subs_duration_type } = pack || {};
  let durationTitle = '';
  if (mode === 'one_time')
    durationTitle = `${durationInTypes(duration, duration_type)} ${transform(duration_type, {capitalize: true, pluralize: duration})}`;
  else if (mode === 'subscription' && subs_duration_type)
    durationTitle = `${_.capitalize(subs_duration_type)}ly`;
  return (
    <Card
      className={`fmb-15 ${index === 0 && "fmt-20 "}text-left ${classes.root}`}
    >
      <CardContent className="fp-20">
        <Grid container>
          <Grid item className="flex-grow-1">
            <Grid
              container
              direction="column"
              alignItems="stretch"
              justify="center"
            >
              <Grid
                container
                item
                xs={12}
                justify="space-between"
                alignItems="center"
                className="fmtn-10"
              >
                <Typography variant="h6">{_.capitalize(title)}</Typography>
                <Box display="flex" alignItems="center">
                  <Typography variant="h6">
                    {currency.toUpperCase()} {!!discounted_price ? discounted_price : price}
                  </Typography>
                </Box>
              </Grid>
              <Typography
                className="fmt-5 fmb-10 font-weight-500"
                variant="subtitle2"
                component="p"
                color="textSecondary"
              >
                {durationTitle} • {mode === "one_time" ? "One time payment" : "Subscription" }
              </Typography>
              <Grid
                container
                item
                xs={12}
                justify="space-between"
                alignItems="center"
              >
                <Typography
                  className="font-weight-500"
                  variant="caption"
                  component="p"
                  color="textSecondary"
                >
                  Transaction Date {moment(date).format("DD MMM YYYY")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const OrderHistory = (props) => {
  const { id: docId, currPurchase, current_plan_status, handleClose } = props;
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchOrderDoc = async () => {
    setLoading(true);
    firebase
      .firestore()
      .collection(`user_profiles/${docId}/order`)
      .get()
      .then((orderDoc) => {
        const data = snapshotDocsArrayToDataArray(orderDoc.docs);
        setList(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        Sentry.captureException(err);
      });
  };

  useEffect(() => {
    fetchOrderDoc();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docId]);

  const planSort = list.sort(
    (a, b) => moment(b.data.date) - moment(a.data.date)
  );

  return (
    <Dialog
      toolbarClass="height-60"
      buttonColor="primary"
      open
      onClose={handleClose}
      title={"Order History"}
      titleFont="h3"
      paperClass={"width-400 height-70"}
      dialogContentClassName="d-flex flex-column"
    >
      {loading ? (
        <CircularLoader />
      ) : (
        <>
          {planSort.length > 0 ? (
            <>
              {planSort.map((item, i) => {
                const { data } = item;
                const { pack, date } = data || {};
                return (
                  <PlanContainer
                    currPurchase={currPurchase}
                    pack={pack}
                    date={date}
                    current_plan_status={current_plan_status}
                    index={i}
                  />
                );
              })}
            </>
          ) : (
            <NotFound />
          )}
        </>
      )}
    </Dialog>
  );
};

export default OrderHistory;
