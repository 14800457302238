import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const FitnessIcon = props => {
  const {stroke, ...rest} = props;
  return (
    <SvgIcon viewBox="0 0 20 20" classes={{root: 'small empty'}} {...rest}>
      <path stroke={stroke || '#8F969F'} d="M19 9H17L15.5 12L12.5 6L11 9H9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path stroke={stroke || '#8F969F'} d="M18.3608 5.32887C18.1303 4.77265 17.7925 4.26729 17.3667 3.84166C16.941 3.41583 16.4357 3.07803 15.8795 2.84757C15.3232 2.6171 14.7271 2.49847 14.125 2.49847C13.5229 2.49847 12.9268 2.6171 12.3705 2.84757C11.8143 3.07803 11.309 3.41583 10.8833 3.84166L10 4.725L9.11666 3.84166C8.25692 2.98192 7.09086 2.49892 5.875 2.49892C4.65914 2.49892 3.49307 2.98192 2.63333 3.84166C1.77359 4.70141 1.29059 5.86747 1.29059 7.08333C1.29059 8.29919 1.77359 9.46525 2.63333 10.325L3.51666 11.2083L10 17.6917L13.2417 14.45" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </SvgIcon>
  );
};
