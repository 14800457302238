import { put, call, all, takeEvery } from 'redux-saga/effects';
// import uuidv4 from 'uuid/v4';
import fileUploadActionBuilder from 'fitbud/redux/fileUpload/actions';
import { vodUpload } from 'fitbud/utils/aws';
import { FILE_STATUS } from 'fitbud/utils/constants';
import {uploadFileToBunnyCDN} from "fitbud/utils/bunnycdn";

const fileUploadAction = fileUploadActionBuilder();

export function* uploadFile({ data }) {
  const { docId, docName, collection, cid, file, path, s3Path, media_bgoff=false } = data;
  const id = s3Path;
  yield put({
    type: fileUploadAction.addFile,
    data: {
      id,
      docId,
      docName,
      cid,
      file,
      path,
      status: FILE_STATUS.uploading,
      progress: 0,
      collection,
      s3Path
    },
  });

  const status = yield call(vodUpload, {
    docId,
    collection,
    path: s3Path,
    file,
    id,
    media_bgoff
  });

  if (status === FILE_STATUS.processing) {
    yield put({
      type: fileUploadAction.updateFile,
      data: { id, status },
    });
  } else {
    yield put({
      type: fileUploadAction.updateFile,
      data: { id, status: FILE_STATUS.error },
    });
  }
}
export function* uploadBunnyFile({data}){
  const {videoObject={},...rest}=data;
  const {videoId}=videoObject||{};
  yield put({
    type: fileUploadAction.addFile,
    data:{
      id:videoId,
      videoId,
      videoObject,
      ...rest,
      status: FILE_STATUS.uploading,
      progress: 0,
    }
  });
  const status=yield call(uploadFileToBunnyCDN,{
    ...data
  });
  if (status === FILE_STATUS.processing) {
    yield put({
      type: fileUploadAction.updateFile,
      data: { id:videoId, status },
    });
  }else if(status==='DELETE_FILE') {
    yield put({
      type: fileUploadAction.clearFile,
      id: videoId
    });
  }
  else {
    yield put({
      type: fileUploadAction.updateFile,
      data: { id:videoId, status: FILE_STATUS.error },
    });
  }
}

export default function* rootSaga() {
  yield all[(
    yield takeEvery(fileUploadAction.uploadFile, uploadFile),
    yield takeEvery(fileUploadAction.uploadBunnyFile,uploadBunnyFile)
    )
  ];
}
