import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Typography } from "@material-ui/core";
import { MoreLessTextBtn } from "fitbud/components/moreLessText";
import clsx from 'clsx';

const UseStyles = makeStyles(theme => ({
  stepAvatar: {
    backgroundColor: "#D7DBE0",
    height: 20,
    width: 20,
    borderRadius: 10,
    color:"#000",
  },
  stepDivider: {
    border: `solid 1px #F2F4F7`,
    flex: 1
  },
  stepText:{
    fontWeight: 600,
    fontSize: "11px"
  }
}));

export default function VerticalStepper(props) {
  let { steps,className } = props;
  const classes = UseStyles();
  const stepsLength=steps.length;
  return (
    <>
      {steps.map((step, index, thisArg) => {
        if (!step || !step.trim().length) {
          return null;
        }
        return (
          <div className="d-flex" key={index}>
            <div className="d-flex flex-column justify-content-start align-items-center">
              <Avatar className={clsx(classes.stepAvatar)}>
                <Typography className={classes.stepText}>{index + 1}</Typography>
              </Avatar>
              {index !== thisArg.length - 1 && (
                <div className={`${classes.stepDivider} light-bg-grey`} />
              )}
            </div>
            <div className={clsx("fml-15",index<(stepsLength-1)? "fmb-15":"")}>
              <MoreLessTextBtn text={step} variant="body1" className={className}/>
            </div>
          </div>
        );
      })}
    </>
  );
}
