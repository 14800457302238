import _get from 'lodash/get';
import React, {useMemo, useState, useEffect} from 'react';
import { useSnackbar } from 'notistack';
import { Button, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Dialog from 'fitbud/components/Dialog';
import { openIntercom } from 'fitbud/utils/intercomm';
import { useToggle, useInput, useValidators } from 'fitbud/hooks/form';
import { bffChangeClientMail } from 'fitbud/api';
import { FormTextField } from 'fitbud/components/form-fields';

const ChangeEmailContent = (props) => {
  const { userDoc, updateUMS, errorMsg, setErrorMsg, toggleLoading, onClose } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [pid, profile, currentEmail] = useMemo(() => {
    if (!userDoc) return [];
    const cid = userDoc.cid;
    const uid = userDoc.uid;
    const profile = userDoc.profile || {};
    const currEmail = profile.email;
    return [cid + ':' + uid, profile, currEmail];
  }, [userDoc]);
  const {parsed: email, props: props1, isValid: isValid1} = useInput('', 'email *');
  const {parsed: email2, props: props2, isValid: isValid2, setError} = useInput('', 'email *');
  const isValid = useValidators(isValid1, isValid2);
  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isValid()) return;
    if (email !== email2) return setError('Emails do not match. Make sure you\'ve typed them correctly');
    if (email === currentEmail) return setError('New email is same as the original email: ' + currentEmail);
    toggleLoading();
    if (!pid || !currentEmail) return setErrorMsg('Unable to change the email at this time. Please contact support to get this resolved.');
    bffChangeClientMail(pid, email)
      .then(resp => {
        const {success, code, message} = resp.data;
        if (success) {
          enqueueSnackbar('Email updated successfully', {variant: 'success'});
          updateUMS({profile: {...profile, email}});
          onClose();
        } else if (code === 'email.conflict') {
          setErrorMsg('Another account with this new email already exists. If you wish to merge the 2 accounts, please contact support');
        } else if (message) {
          setErrorMsg(message);
        } else {
          setErrorMsg('Unable to change the email at this time. Please contact support to get this resolved.');
        }
      }).catch(err => {
        const {code, message} = _get(err, 'response.data');
        if (code === 'email.conflict') {
          setErrorMsg('Another account with this new email already exists. If you wish to merge both accounts, please contact support');
        } else if (message) {
          setErrorMsg(message);
        } else {
          setErrorMsg('Unable to change the email at this time. Please contact support to get this resolved.');
        }
      });
  };
  const tryAgain = () => {
    setErrorMsg(null);
    toggleLoading();
  };
  const contactUs = () => {
    onClose();
    openIntercom();
  };

  if (errorMsg) {
    return (
      <div className='p-20'>
        <Alert severity='error'>{errorMsg}</Alert>
        <div className='mt-20 d-flex flex-row justify-content-end'>
          <Button variant='outlined' className='f-sm-med border-primary' color='primary'
          disableElevation classes={{ label: 'font_15_700' }} onClick={tryAgain}>
            Try Again
          </Button>
          <Button variant='contained' className='f-sm-med ml-20' color='primary'
          disableElevation classes={{ label: 'font_15_700' }} onClick={contactUs}>
            Contact Support
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className='p-20'>
      <form id='change-client-email-form' onSubmit={onSubmit}>
        <FormTextField fullWidth label='Enter New Email'>
          <TextField {...props1} variant='outlined' className='standard'/>
        </FormTextField>
        <FormTextField fullWidth label='Enter New Email Again'>
          <TextField {...props2} variant='outlined' className='standard'/>
        </FormTextField>
      </form>
    </div>
  );
};

const ChangeEmailDialog = ({onClose, ...rest}) => {
  const [loading, toggleLoading, setLoading] = useToggle();
  const [error, setError] = useState(null);
  useEffect(() => {
    setLoading(false);
  }, [error, setLoading]);
  return (
    <Dialog open formId={error ? null : 'change-client-email-form'}
      toolbarClass='height-60'
      paperClass='width-600'
      buttonColor='primary'
      title='Change Email'
      loading={loading}
      onClose={onClose}>
      <ChangeEmailContent {...rest} onClose={onClose} toggleLoading={toggleLoading}
        errorMsg={error} setErrorMsg={setError}/>
    </Dialog>
  );
};

export default ChangeEmailDialog;
