import React from 'react';
import {
  MenuItem, Select,
  OutlinedInput
} from '@material-ui/core';
import { SELECTED_AUDIO_INPUT_KEY } from 'fitbud/utils/constants';
import useDevices from './useDevices';
import useVideoContext from './useVideoContext';
import useMediaStreamTrack from './useMediaStreamTrack';
import DropDownIcon from "@material-ui/icons/ExpandMore";
import { FormTextField } from "fitbud/components/form-fields";


const AudioInputList = (props) => {
  const { audioInputDevices } = useDevices();
  const { localTracks } = useVideoContext();

  const localAudioTrack = localTracks.find(track => track.kind === 'audio');
  const mediaStreamTrack = useMediaStreamTrack(localAudioTrack);
  const localAudioInputDeviceId = mediaStreamTrack && mediaStreamTrack.getSettings().deviceId;

  const replaceTrack = (newDeviceId) => {
    if (newDeviceId) {
      window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId); //store the selected media devices in localStorage
      localAudioTrack.restart({ deviceId: { exact: newDeviceId } });
    }
  }

  return (
    <FormTextField classes={{ control: "fpb-0" }} fullWidth label="Audio" >
      <Select
        IconComponent={DropDownIcon}
        onChange={e => replaceTrack(e.target.value)}
        value={localAudioInputDeviceId || ''}
        style={{height: "40px"}}
        input={
          <OutlinedInput
            classes={{
              root: "height-40",
              input: "size_16_500 select-height-40",
            }}
            name="type"
          />
        }
        required
        {...props}
      >
        {audioInputDevices.length > 0 ? (
          audioInputDevices.map(device => (
            <MenuItem value={device.deviceId} key={device.deviceId}>
              {device.label}
            </MenuItem>
          ))
        ) : (
          <MenuItem>{'No Local Audio'}</MenuItem>
        )}
      </Select>
    </FormTextField>
  )
}

export default AudioInputList;