import _ from 'lodash';
import {roundNumber} from "./helpers";

const SEC = 'sec';
const REPS = 'reps';
const MEAL_VOL = 'meal_volume';
const MEAL_WT = 'meal_weight';
const MEAL_OPTS = [MEAL_VOL, MEAL_WT];
const DURATION_DAYS = 'days';
const DURATION_WEEKS = 'weeks';
const DURATION_MONTHS = 'months';
const EQUIPMENT_WEIGHT = "equipment-weight"
const USER_LOGGED_WEIGHT = "user-logged-weight"
const DURATION_OPTS = [DURATION_DAYS, DURATION_WEEKS, DURATION_MONTHS];
const isDuration = (unit) => (_.includes(DURATION_OPTS, unit));
const unitMapping = unit => {
  if(unit === EQUIPMENT_WEIGHT) unit = "weight";
  if(unit === USER_LOGGED_WEIGHT) unit = "weight";
  return unit;
}
const addComputedFactors=(o={})=>{
  //NOTE: Will not work for custom preference
  if(!!Object.keys(o).length){
    const speed=_.reduce(o.distance,(a,v,key)=>{
      const c={
        [key]:{
          ...v,
          unit:key==="metric"?'km/h':"mi/h"
        }
      }
      if(key==="imperial_us"){
        c['imperial_us']['disp_unit']=c['imperial_us']['unit']+" (US)"
      }
      return {...a,...c}
    },{});
    const pace=_.reduce(o.distance,(a,v,key)=>{
      const c={
        [key]:{
          ...v,
          conversion:1/(v.conversion),
          unit:key==="metric"?'s / km':"s / mi" //sec removed from units:sec/km ,sec/mi
        }
      }
      if(key==="imperial_us"){
        c['imperial_us']['disp_unit']=c['imperial_us']['unit']+" (US)"
      }
      return {...a,...c}
    },{});
    const meters=_.reduce(o.distance,(a,v,key)=>{
      const c={
        [key]:{
          conversion:1,
          disp_unit:"mts",
          unit:"mts"
        }
      };
      return {...a,...c}
    },{});
    _.set(o,'pace',pace);
    _.set(o,'speed',speed);
    _.set(o,'meters',meters);
  }
  return {...o}
}
const addComputedOptions=(o={})=>{
  //NOTE: Will not work for custom preference
  if(!!Object.keys(o).length){
    o['speed']=[
      {system:"imperial",text:"mi/h"},
      {system:"metric",text:"km/h"}
    ];
    o['pace']=[
      {system:"imperial",text:"sec/mi"},
      {system:"metric",text:"sec/km"}
    ];
    o['meters']=[
      {system:"imperial",text:"mts"},
      {system:"metric",text:"mts"}
    ]
  }
  return {...o}
}
const addComputedUserUnitPrefs=(o={})=>{
  if(!!Object.keys(o).length){
    o['speed']=o['distance'];
    o['pace']=o['distance'];
    o['meters']=o['distance'];
  }
  return {...o}
}
class Convertor {
  constructor (unitFactors, unitOptions, userUnitSystem, userUnitPrefs) {
    const _unitFactors=addComputedFactors(unitFactors);
    const _unitOptions=addComputedOptions(unitOptions);
    const _userUnitPrefs=addComputedUserUnitPrefs(userUnitPrefs);
    this.conversionFactor = _unitFactors;
    this.userUnitSystem = _unitOptions;
    this.userUnitPrefs = _userUnitPrefs || {};
    this.unitOptions = {};
    Object.keys(unitFactors || {}).forEach(key => {
      this.unitOptions[key] = unitOptions[key].map(opt => ({label: opt.text, value: opt.system}));
      const system = this.userUnitPrefs[key] || userUnitSystem;
      this.userUnitPrefs[key] = system;
    });
  }

  getConversion = (unit) => {
    unit = unitMapping(unit);
    return _.get(this.conversionFactor, [unit, this.userUnitPrefs[unit]], {});
  }

  getDBValue = (unit, value) => {
    if (!unit)
      return value || 0;
    // eslint-disable-next-line default-case
    switch (unit) {
      case DURATION_DAYS:
        return Number(value || 0);
      case DURATION_WEEKS:
        return 7 * Number(value || 0);
      case DURATION_MONTHS:
        return 30 * Number(value || 0);
    }
    const conversion = this.getConversion(unit).conversion || 1;
    return Number(value || 0) / Number(conversion);
  }

  getDisplayValue = (unit, value, numDecimalOverride) => {
    if (!unit)
      return roundNumber(value || 0, numDecimalOverride || this.getNumDecimals(unit));
    // eslint-disable-next-line default-case
    switch (unit) {
      case DURATION_DAYS:
        return roundNumber(Number(value || 0), 0);
      case DURATION_WEEKS:
        return roundNumber(Number(value || 0) * 7, 0);
      case DURATION_MONTHS:
        return roundNumber(Number(value || 0) * 30, 0);
    }
    const conversion = this.getConversion(unit).conversion || 1;
    const converted = Number(value || 0) * Number(conversion);
    if (unit === USER_LOGGED_WEIGHT)
      return Math.round(4 * converted) / 4;
    if (numDecimalOverride === Number.POSITIVE_INFINITY) return converted;
    return roundNumber(converted, numDecimalOverride || numDecimalOverride === 0 ? numDecimalOverride : this.getNumDecimals(unit));
  }

  getDisplayUnit = (unit) => {
    if(!unit || unit === 'none' || unit === null) return '';
    // eslint-disable-next-line default-case
    switch (unit) {
      case DURATION_DAYS:
        return 'Days';
      case DURATION_WEEKS:
        return 'Weeks';
      case DURATION_MONTHS:
        return 'Months';
      case 'body_fat':
        return '%';
    }
    unit = unitMapping(unit);
    return this.getConversion(unit).unit || unit;
  }

  allowsFloat = (unit) => {
    return !!this.conversionFactor[unit] ||
      unit === 'g' || unit === EQUIPMENT_WEIGHT;
  }

  getToggleOpts = (unit, overrideOpts = undefined) => {
    if (unit.startsWith('meal_'))
      return (overrideOpts || MEAL_OPTS).map(x => ({value: x, label: this.getDisplayUnit(x)}));
    if (isDuration(unit))
      return (overrideOpts || DURATION_OPTS).map(x => ({value: x, label: _.capitalize(x)}));
    return overrideOpts || undefined;
  }

  getNumDecimals = (unit) => {
    unit = unit || "";
    if (isDuration(unit) || unit === REPS || unit.startsWith(SEC))
      return 0;
    if(unit === 'water') return  this.getDisplayUnit(unit) !== 'ml' ? 1 : 0;
    // if(unit === 'distance') return  1;
    if(unit === 'height') return this.getDisplayUnit(unit) !== 'cm' ? 1 : 0;
    if(unit === 'meters' || unit === "cals" || unit==="pace"|| unit==="duration") return 0;
    // if(unit === 'weight') return 1;
    return 2;
  }

  getDisplayWithUnit = (unit, value, { numDecimalOverride, fancy = false} = {}) => {
    if (!unit)
      return [roundNumber(value || 0, numDecimalOverride || this.getNumDecimals(unit)), ''];
    let unitOut = this.getDisplayUnit('height'), valOut = this.getDisplayValue(unit, value, numDecimalOverride);
    if (fancy && unitOut === 'ft') { // change `5.8 ft` to `5'10"`
      unitOut = '';
      valOut = this.getDisplayValue(unit, value, Number.POSITIVE_INFINITY);
      const feet = Math.floor(valOut);
      const inch = Math.round(12 * (valOut % 1));
      valOut = `${feet}'${inch}"`;
    }
    return [valOut, unitOut];
  }
}

export default Convertor;
