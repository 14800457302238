import { makeStyles } from "@material-ui/core";
import Dialog from "./dialog";
import { withSnackbar } from "notistack";
import React, {useContext, useEffect} from "react";
import moment from "moment";
import _ from "lodash";
import * as Sentry from "@sentry/browser";
import useVideoContext from './useVideoContext';
import { useLiveSessions } from "fitbud/providers/liveSessionsProvider";
import LocalVideoPreview from "./localVideoPreview";
import MenuBar from "./menuBar";
import Room from "./room";
import useRoomState from "./useRoomState";
import ReJoin from "./reJoin";
import firebase from "fitbud/firebase";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";

const useStyles = makeStyles(() => ({
  mainRoot: {
    overflow: "hidden",
    background: "#17171A",
    // paddingBottom: "72px",
    height: "100vh",
  },
}));

const LiveVideo = (props) => {
  const { handleCloseVideoScreen, openVideoScreen, bookings, openVideoScreen: book_id } = useLiveSessions();
  const { reJoin, handleRejoin, handleRHS, removeLocalAudioTrack, removeLocalVideoTrack } = useVideoContext();
  const roomState = useRoomState();
  const classes = useStyles();
  const { cid } = useContext(FirebaseAuthContext);

  const handleReturnHomePage = (e) => {
    handleCloseVideoScreen(e) //handle close video dialog
    handleRHS(e) //handle rejoin start
    removeLocalAudioTrack()
    removeLocalVideoTrack()
  }

  const handleRejoinCall = (e) => {
    handleRejoin(e)
    removeLocalAudioTrack()
    removeLocalVideoTrack()
  }

  const onSubmit = async () => {
    const _booking = _.find(bookings, ['book_id', openVideoScreen]);
    const _trainerTs = _.get(_booking, "trainerTs");
    try {
      const bookingRef = firebase.firestore().collection(`companies/${cid}/bookings`).doc(`${openVideoScreen}`);
      if(_trainerTs) {
        let out = { 
          trainerCallEndTs: moment().toDate()
        }; //set current time stamp when call disconnected
        await bookingRef.update(out);
      }
    } catch (err) {
      console.log(err);
      Sentry.captureException(err);
    }
  }

  useEffect(() => {
    if(roomState === "disconnected") {
      onSubmit();
    }
  },[openVideoScreen, roomState])
  
  return (
    <Dialog fullScreen open={Boolean(openVideoScreen)} onClose={handleCloseVideoScreen} roomState={roomState}>
      <div>
        {roomState === "disconnected" ? (
          <>
            {!!reJoin ? (
              <ReJoin
                handleRHS={handleReturnHomePage}
                handleRejoin={handleRejoinCall}
              />
            ) : (
              <LocalVideoPreview />
            )}
          </>
        ) : (
          <div className={classes.mainRoot}>
            <Room />
            <MenuBar />
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default withSnackbar(LiveVideo);
