import React from 'react';
import { MenuItem, Select, OutlinedInput, InputAdornment, makeStyles, Typography } from '@material-ui/core';
import Dialog from 'fitbud/components/Dialog';
import DropDownIcon from '@material-ui/icons/ExpandMore';
import { FormTextField } from 'fitbud/components/form-fields';
import { TimePicker } from '@material-ui/pickers';
import clsx from 'clsx';
import { date2hhmm } from 'fitbud/utils/scheduling';
import moment from 'moment';
import { useReminder } from 'fitbud/views/reminders/provider';
import _ from 'lodash';
import { hhMMToDate, WEEK_OPTIONS, DAYS_OPTIONS } from 'fitbud/views/reminders/helper';
const useStyles = makeStyles(() => ({
  timeInput: {
    minHeight: '48px',
    fontSize: '15px',
    fontWeight: 600,
  },
}));

const MeasurementEditor = () => {
  const { reminders, setConfigKey, dirty, onSave, closeEdit, getMeasurementRccOptions, time_picker_step } =
    useReminder();
  const { day_of_week, enabled, mode, time, day_of_month } = _.get(reminders, 'measurements', {});
  const classes = useStyles();
  const isEveryday = (mode || "").includes('day');
  const isMonthly = (mode || '').includes('month');
  const MEASUREMENTS_MODE_OPTIONS = getMeasurementRccOptions();

  const handleChange = (e) => {
    const key = e.target.name || e.target.id;
    const value = e.target.value;
    setConfigKey('measurements', key, value);
  };

  const onTimeChange = (key) => (time) => {
    const t = moment(time);
    const hhmm = date2hhmm(t);
    setConfigKey('measurements', key, hhmm);
  };

  const handleSubmit = () => {
    onSave('measurements');
  };

  return (
    <Dialog
      toolbarClass="height-60"
      buttonColor="primary"
      title="Measurements Reminder"
      open
      withConfirmation
      isDirty={dirty}
      onClose={closeEdit}
      paperClass="width-500"
      titleFont="h3"
      onSave={handleSubmit}
      actionText="Save"
    >
      <div className="px-20 pt-20">
        {/* <Typography className="font_16_600 text-0d0d0d mb-20">Preferred Timing</Typography> */}
        <FormTextField fullWidth label="Send Reminders">
          <Select
            IconComponent={DropDownIcon}
            input={
              <OutlinedInput
                classes={{
                  root: 'medium',
                  input: 'size_15_600 select-medium',
                }}
                name="mode"
              />
            }
            value={mode}
            onChange={handleChange}
            name="mode"
          >
            {MEASUREMENTS_MODE_OPTIONS.map((op) => (
              <MenuItem key={op.value} value={op.id}>
                {op.label}
              </MenuItem>
            ))}
          </Select>
        </FormTextField>
        {!isEveryday && (
          <React.Fragment>
            {!isMonthly && (
              <FormTextField fullWidth label="Day">
                <Select
                  IconComponent={DropDownIcon}
                  input={
                    <OutlinedInput
                      classes={{
                        root: 'medium',
                        input: 'size_15_600 select-medium text-capitalize',
                      }}
                      name="day_of_week"
                    />
                  }
                  value={day_of_week}
                  onChange={handleChange}
                  name="day_of_week"
                >
                  {WEEK_OPTIONS.map((op) => (
                    <MenuItem key={op.value} value={op.value} className="text-capitalize">
                      {op.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormTextField>
            )}
            {!!isMonthly && (
              <FormTextField fullWidth label="Day">
                <Select
                  IconComponent={DropDownIcon}
                  input={
                    <OutlinedInput
                      classes={{
                        root: 'medium',
                        input: 'size_15_600 select-medium',
                      }}
                      name="day_of_month"
                    />
                  }
                  value={day_of_month}
                  onChange={handleChange}
                  name="day_of_month"
                >
                  {DAYS_OPTIONS.map((op) => (
                    <MenuItem key={op.value} value={op.value}>
                      {op.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormTextField>
            )}
          </React.Fragment>
        )}

        <FormTextField fullWidth label="Time">
          <TimePicker
            format="hh:mm a"
            value={hhMMToDate(time)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <DropDownIcon />
                </InputAdornment>
              ),
              className: clsx(classes.timeInput),
            }}
            inputVariant="outlined"
            onChange={onTimeChange('time')}
            minutesStep={time_picker_step}
          />
        </FormTextField>
      </div>
    </Dialog>
  );
};
export default MeasurementEditor;
