import _ from "lodash";
import moment from "moment";
import firebase from "fitbud/firebase";
import { roundNumber } from "fitbud/utils/helpers";
import { oprtnlDateFormat } from "fitbud/utils/constants";

export const minutesToHours = (mins) => {
  //Returns in [hours, minutes] format
  const exactMoment = moment().startOf('day').add(mins, 'minutes');
  return [exactMoment.clone().hours(), exactMoment.clone().minutes() ];
}

const defaultLogs =  [
  {date: '20211115', value: '45', unit: 'mins'},
  {date: '20211116', value: '45', unit: 'mins'},
  {date: '20211117', value: '45', unit: 'mins'},
  {date: '20211118', value: '45', unit: 'mins'},
  {date: '20211119', value: '45', unit: 'mins'},
];
const defaultData = {
  primary:false,
  secondary:false,
  graphColor :'#F08673',
  views: ['first', 'second', 'third'],
  logs: [...defaultLogs],
  showNav: true,
}

export const parseTarget = (rawData, planDuration, mode) => {
  if(!rawData) return false;
  let out = {};
  for(let i = 1; i <= planDuration ;i++){
    const dayData = rawData[`day_${i}`];
    if(!dayData || _.isEmpty(dayData)) continue;
    if(mode === 'workout') {
      const keys = Object.keys(dayData);
      if(!keys.length) continue;
      const totalWorkouts = keys.length;
      let totalDuration = 0;
      let totalExCount = 0;
      keys.forEach((key) => {
        const data = dayData[key]
        totalDuration = totalDuration+data.duration;
        totalExCount = totalExCount+data.exCount;
      })
      out[`day_${i}`] = { totalDuration, totalExCount, totalWorkouts }
    } else {
      out[`day_${i}`] = { ...dayData }
    }
  }
  return _.isEmpty(out) ? false : out;
}

export const parseProgress = (rawData, mode, planStartDate) => {
  if(!rawData) return false;
  let out = {};
  const startDate = moment(planStartDate, oprtnlDateFormat);
  const keys = Object.keys(rawData);
  if(!keys.length) return out;
  keys.forEach((key) => {
    if(mode === 'workout'){
      const {start_time, cals, exCount, duration, end_time, is_complete  } = rawData[key];
      if((!start_time && !end_time) || !is_complete) return;
      const daysDiff = moment((start_time || end_time).toDate()).diff(startDate, 'days');
      const dayData = _.get(out, `day_${daysDiff+1}`, {});
      // Set / Add values of workouts for day_${daysDiff+1}
      dayData.totalWorkouts = dayData.totalWorkouts ? dayData.totalWorkouts + 1 : 1;
      dayData.cals = dayData.cals ? dayData.cals + cals : cals;
      dayData.exCount = dayData.exCount ? dayData.exCount + exCount : exCount;
      dayData.duration = dayData.duration ? dayData.duration + duration : duration;
      return out[`day_${daysDiff+1}`] = { ...dayData };
    }
    const dayData = rawData[key];
    if(!dayData) return;
    const daysDiff = moment(key, oprtnlDateFormat).diff(startDate, 'days');
    return out[`day_${daysDiff+1}`] = { ...dayData };
  });
  
  return _.isEmpty(out) ? false : out;
}

export const findPlanPeriod = (date, planStartDate, planDuration, viewLimit='weekly') => {
  let out = {};
  const planStartMoment = moment(planStartDate, oprtnlDateFormat);
  let diffFromStart = moment(date).diff(moment(planStartDate, oprtnlDateFormat), 'days');
  if (diffFromStart >= planDuration) diffFromStart = planDuration - 1;
  if(viewLimit === 'weekly'){
    let i = Math.floor(diffFromStart / 7);
    const startDate = planStartMoment.clone().add((i * 7), 'days');
    let endDate = startDate.clone().add(6, 'days');
    if(endDate.clone().isAfter(planStartMoment.clone().add(planDuration - 1, 'days'))) 
      endDate = planStartMoment.clone().add(planDuration - 1, 'days');
    if(moment().isAfter(startDate) && moment().isBefore(endDate))
      endDate = moment();
    let week = {
    name: `Week ${i+1}`,
    startDate: startDate.clone().toDate(),
    endDate: endDate.clone().endOf('day').toDate(),
    };
    out = week;
  } else {
    let i = Math.floor(diffFromStart / 30);
    let startDate = planStartMoment.clone().add((i * 30), 'days').startOf('month');
    if(startDate.isBefore(planStartMoment.clone())) startDate = planStartMoment.clone();
    let endDate = startDate.clone().endOf('month');
    if(endDate.clone().isAfter(planStartMoment.clone().add(planDuration - 1, 'days')))
      endDate = planStartMoment.clone().add(planDuration - 1, 'days');
    if(moment().isAfter(startDate) && moment().isBefore(endDate))
      endDate = moment();
    let week = {
      name: `Month ${i+1}`,
      startDate: startDate.clone().toDate(),
      endDate: endDate.clone().endOf('day').toDate(),
    }
    out = week;
  }
  return out;
}

export const sumWorkoutData = (date, _workout={}, progress, target) => {
  let out = { ..._workout };
  const { totalWorkouts=0, cals=0, duration=0 } = progress || {};
  const { totalWorkouts: totalWorkouts_target=0 } = target || {};
  if(target){
    // Copy target_data
    out.totalWorkouts_target = out.totalWorkouts_target ? out.totalWorkouts_target + totalWorkouts_target : totalWorkouts_target;
  }
  // Logs
  out.logs.sessions = out.logs && out.logs.sessions ? [...out.logs.sessions, {date, value: totalWorkouts || 0 }] : [{date, value: totalWorkouts || 0}];
  out.logs.energy = out.logs && out.logs.energy ? [...out.logs.energy, {date, value: cals || 0, unit: 'cals' }] : [{date, value: cals || 0, unit: 'cals'}];
  out.logs.duration = out.logs && out.logs.duration ? [...out.logs.duration, {date, value: roundNumber((duration || 0) / 60, 1), unit:'mins' }] 
    : [{date, value: roundNumber((duration || 0) / 60, 1), unit:'mins' }];
  // Progress
  if(progress){
    out.totalEntries = out.totalEntries ? out.totalEntries + 1 : 1;
    out.totalWorkouts = out.totalWorkouts ? out.totalWorkouts + totalWorkouts : totalWorkouts;
    out.calories = out.calories ? out.calories + cals : cals;
    out.duration = out.duration ? out.duration + (duration || 0) / 60 : (duration || 0) / 60;
  }
  return out;
};
export const sumNutritionData = (date, _nutrition={}, progress, target) => {
  let out = { ..._nutrition };
  let { calories=0, fat=0, carbs=0, protein=0 } = progress || {};
  const { calories: calories_target=0, carbs: carbs_target=0,  fat: fat_target=0, protein: protein_target=0 } = target || {};
  if(target){
    // Copy target_data
    out.totalEntries = out.totalEntries ? out.totalEntries + 1 : 1;
    out.calories_target = out.calories_target ? out.calories_target + calories_target : calories_target;
    out.carbs_target = out.carbs_target ? out.carbs_target + carbs_target : carbs_target;
    out.fat_target = out.fat_target ? out.fat_target + fat_target : fat_target;
    out.protein_target = out.protein_target ? out.protein_target + protein_target : protein_target;
  }
  // Logs
  out.logs.calories = out.logs && out.logs.calories ? [...out.logs.calories, {date, value: roundNumber(calories || 0, 0), unit: 'cals'}] : [{date, value: roundNumber(calories || 0, 0), unit: 'cals'}];
  out.logs.fat = out.logs && out.logs.fat ? [...out.logs.fat, {date, value: roundNumber(fat || 0, 0), unit: 'g'}] : [{date, value: roundNumber(fat || 0, 0), unit: 'g'}];
  out.logs.carbs = out.logs && out.logs.carbs ? [...out.logs.carbs, {date, value: roundNumber(carbs || 0, 0), unit: 'g'}] : [{date, value: roundNumber(carbs || 0, 0), unit: 'g'}];
  out.logs.protein = out.logs && out.logs.protein ? [...out.logs.protein, {date, value: roundNumber(protein || 0, 0), unit: 'g'}] : [{date, value: roundNumber(protein || 0, 0), unit: 'g'}];
  // Progress
  if(progress){
    out.calories = out.calories ? out.calories + calories : calories;
    out.carbs = out.carbs ? out.carbs + carbs : carbs;
    out.fat = out.fat ? out.fat + fat: fat;
    out.protein = out.protein ? out.protein + protein : protein;  
  }
  return out;
};
export const sumActivityData = (date, _activity={}, progress, target, {getDisplayValue, getDisplayUnit}) => {
  let out = { ..._activity };
  let { distance_travelled: distance=0, energy=0, flights=0, steps=0 } = progress || {};
  let { distance: distance_target=0, energy: energy_target=0,  flights: flights_target=0, steps: steps_target=0 } = target || {};
  const distanceUnit = getDisplayUnit('distance');
  out.distance_unit = distanceUnit;
  out.energy_unit = 'cals';
  out.logs.distance = out.logs && out.logs.distance ? [...out.logs.distance, {date, value: distance || 0, unit: distanceUnit}] : [{date, value: distance || 0, unit: distanceUnit}];
  out.logs.energy = out.logs && out.logs.energy ? [...out.logs.energy, {date, value: energy || 0, unit:'cals'}] : [{date, value: energy, unit:'cals'}];
  out.logs.flights = out.logs && out.logs.flights ? [...out.logs.flights, {date, value: flights || 0}] : [{date, value: flights || 0}];
  out.logs.steps = out.logs && out.logs.steps ? [...out.logs.steps, {date, value: steps || 0}] : [{date, value: steps || 0}];
  if(target){
    // Copy target_data
    out.totalEntries = out.totalEntries ? out.totalEntries + 1 : 1;
    out.distance_target = out.distance_target ? out.distance_target + distance_target : distance_target;
    out.energy_target = out.energy_target ? out.energy_target + energy_target : energy_target;
    out.flights_target = out.flights_target ? out.flights_target + flights_target : flights_target;
    out.steps_target = out.steps_target ? out.steps_target + steps_target : steps_target;
  }
  if(progress){
    out.distance = out.distance ? out.distance + distance : distance;
    out.energy = out.energy ? out.energy + energy : energy;
    out.flights = out.flights ? out.flights + flights : flights;
    out.steps = out.steps ? out.steps + steps : steps;
  } 
  return out;
};
export const sumWaterData = (date, _water={}, progress, target, {getDisplayValue, getDisplayUnit}) => {
  let out = { ..._water };
  let { total=0 } = progress || {};
  let { total: total_target=0 } = target || {};
  const unit = getDisplayUnit('water');
  out.unit = unit;
  // Logs
  out.logs.water = out.logs && out.logs.water ? [...out.logs.water, {date, value: total || 0, unit }] : [{date, value: total || 0, unit }];
  if(target){
    // Copy target_data
    out.totalEntries = out.totalEntries ? out.totalEntries + 1 : 1;
    out.total_target = out.total_target ? out.total_target + total_target : total_target;
  }
  // Progress
  if(progress){
    out.total = out.total ? out.total + total: total;
  }
  return out;
};
const calcCompliance = (num, deno, type='') => {
  if(!type) return 0;
  let result = (num / deno) * 100;
  if(type !== 'nutrition'){
    if(result >= 100) return 100;
    return roundNumber(result, 0);
  };
  if(result >= 200) return 200;
  if(result > 100) {
    result = 100 - (result % 100);
  };
  return roundNumber(result, 0);
};
const tooltipLabelFormatter = (date) => moment(date, 'MM/DD').format('DD MMM');
export const convertData = ({workout, nutrition, water, activity}, {getDisplayValue, getDisplayUnit}) => {
  let out = { workout: {...defaultData}, nutrition: {...defaultData}, water: {...defaultData}, 
    steps: {...defaultData}, flights: {...defaultData}, distance: {...defaultData}, energy: {...defaultData} };
  if(workout){
    // Duration, calories - calculated with per day = total_sum_for_the_selected_period / total_days_in_period
    // Workout Compliance - calculated with per day = total_workouts / total_workout_target * 100
    const {totalWorkouts, totalWorkouts_target, duration, totalDays, calories, logs } = workout;
    const durationMinutes = roundNumber((duration / totalDays), 2);
    const totalCalories = roundNumber((calories / totalDays), 0);
    const workoutCompliance = totalWorkouts && totalWorkouts_target ? 
      calcCompliance(roundNumber(totalWorkouts, 0), roundNumber(totalWorkouts_target, 0), 'workouts')
        : false;
    out.workout.logs = logs;
    out.workout.primary = 'sessions';
    out.workout.secondary = ['duration', 'energy'];
    out.workout.sessions = {
      num: roundNumber(totalWorkouts, 0),
      deno: roundNumber(totalWorkouts_target, 0),
      compliance: (!!workoutCompliance && workoutCompliance > 100) ? 100 : workoutCompliance,
      label:'Completed'
    };
    out.workout.duration = {
      num: durationMinutes,
      type:'duration',
      unit: 'mins',
    };
    out.workout.energy = {
      num: totalCalories,
      type:'calories',
      unit: 'cals',
    };
    out.workout.views = ['sessions','duration', 'energy'];
    // Graph config
    out.workout.graphColor = '#F08673'; // @TODO Maybe Replace Hard coded
    out.workout.tooltipConfig = {
      sessions: {
        labelFormatter: tooltipLabelFormatter,
        valueLabel: 'Sessions',
      },
      duration: {
        labelFormatter: tooltipLabelFormatter,
        valueLabel: 'Duration',
        unit: 'mins',
      },
      energy: {
        labelFormatter: tooltipLabelFormatter,
        valueLabel: 'Calories',
        unit: 'cals'
      }
    }
  }
  // Nutrition Data
  if(nutrition){
    const { totalEntries, logs, totalDays } = nutrition;
    const primary = 'calories';
    const secondary = ['protein', 'fat', 'carbs'];
    out.nutrition.primary = primary;
    out.nutrition.secondary = [...secondary];
    out.nutrition.logs = logs;
    [primary, ...secondary].forEach((item) => {
      const progress = nutrition[item];
      const progress_target = nutrition[`${item}_target`];
      const unit = item === 'calories' ?  'cals' : 'g';
      out.nutrition[item] = {
        num: !!progress ? roundNumber((progress / totalDays), 0) : false,
        deno: !!progress_target ? roundNumber((progress_target / totalEntries), 0) : false,
        unit,
        compliance: progress && progress_target ?
          calcCompliance(roundNumber(progress / totalDays, 0), roundNumber(progress_target / totalEntries, 0), 'nutrition')
            : false,
        label:'Daily Avg',
        complianceType:'nutrition',
      };
      out.nutrition.goal = !!out.nutrition.goal ?
        { ...out.nutrition.goal, [item]: progress_target ? roundNumber(progress_target / totalEntries, 0): false } 
        : { [item]: progress_target ? roundNumber(progress_target / totalEntries, 0): false,
      };
      out.nutrition.tooltipConfig = !!out.nutrition.tooltipConfig ?
        { ...out.nutrition.tooltipConfig, [item]: { labelFormatter: tooltipLabelFormatter, valueLabel: item, unit} } 
          : { [item]: { labelFormatter: tooltipLabelFormatter, valueLabel: item, unit},
      };
    })
    out.nutrition.views = [primary, ...secondary];
    out.nutrition.graphColor = '#7CD492';
  }
  // Water Data
  if(water){
    const { total, total_target, totalDays, logs, totalEntries, unit } = water;
    out.water.primary = 'water';
    const compliance = total && total_target ? 
      calcCompliance(getDisplayValue('water', total / totalDays), getDisplayValue('water', total_target / totalEntries), 'water')
        :false
    out.water.logs = {
      water: ((!!logs && logs.water && logs.water.map((i) =>({...i, value: getDisplayValue('water', i.value), unit: getDisplayUnit('water') })))) || []
    };
    out.water.goal = {water: total_target ? getDisplayValue('water',total_target / totalEntries) : false}
    out.water.water = {
      num: getDisplayValue('water', total / totalDays),
      deno: total_target ? getDisplayValue('water', total_target / totalEntries) : false,
      compliance: getDisplayValue('percentage', compliance),
      unit,
      label:'Daily Avg',
    }
    out.water.secondary = false;
    out.water.graphColor = '#48B5E0';
    out.water.views= ['water'];
    out.water.showNav = false;
    out.water.tooltipConfig = {
      water: {
        labelFormatter: tooltipLabelFormatter,
        valueLabel: 'Amount',
        unit,
      }
    }
  }
  if(activity){
    const { totalDays, logs, totalEntries } = activity;
    const keys = ['steps', 'energy', 'flights', 'distance'];
    keys.forEach((subView)=> {
      const progress = activity[subView];
      const progress_target = activity[`${subView}_target`];
      const compliance = progress && progress_target ? 
        calcCompliance(
          roundNumber(progress / totalDays, subView === 'distance' ? 1 : 0), 
          roundNumber(progress_target / totalEntries, subView === 'distance' ? 1 : 0), 'activity')
          : 0;
      // arrange like this as rest of the data is in the similar structure - out.viewName.subViewName - treat view === subView
      out[subView] = {
        primary: subView,
        logs:{
          [subView] :subView === 'distance' ? 
            (!!logs && (logs[subView] || []).map(i => ({...i, value: getDisplayValue('distance', i.value)})) ) || [] 
            : (!!logs && logs[subView] )|| [],
        },
        goal: {[subView]: progress_target ? 
          subView === 'distance' ? getDisplayValue(subView, (progress_target / totalEntries))
            : roundNumber((progress_target / totalEntries), 0)
              : false},
        [subView]: {
          num: progress ? subView === 'distance' ? 
            getDisplayValue('distance', progress / totalDays) 
              : roundNumber(progress / totalDays, 0) 
                : false,
          deno: progress_target ?  subView === 'distance' ? 
            getDisplayValue('distance', progress_target / totalEntries) : 
              roundNumber(progress_target / totalEntries, 0)
                : false,
          compliance: (!!compliance && compliance > 100) ? 100 : compliance,
          unit: activity[`${subView}_unit`],
          label:'Daily Avg',
        },
        graphColor: '#F8BD45',
        views: [`${subView}`],
        showNav: false,
        tooltipConfig: {
          [subView]: {
            labelFormatter: tooltipLabelFormatter,
            valueLabel: 'Amount',
            unit: activity[`${subView}_unit`]
          },
        },
      }
    })
  }
  return out;
};
export const findComplianceMark = (config, compliance) => {
  let out = false;
  if(!config || !config.length || (compliance !==0 && !compliance)) return out;
  config = config.sort((a,b) => a.below - b.below);
  for(let i = 0; i < config.length; i++){
    if(compliance <= config[i].below){
      out = { ...config[i] };
      break;
    };
  };
  return out;
}
const getFirebasePromises = (aplan, view) => [
  firebase.firestore().doc(`plans/${aplan}/aggregate/${view}`).get(),
  firebase.firestore().doc(`plans/${aplan}/aggregate/${view}_target`).get()
];

export const fetchWorkouts = async(aplan) => {
  const [progress, target] = await Promise.all([...getFirebasePromises(aplan, 'workout')])
  return { progress, target, type: 'workout' }
}

export const fetchWater = async(aplan) => {
  const [progress, target] = await Promise.all([...getFirebasePromises(aplan, 'water')]);
  return { progress, target, type: 'water' }
}

export const fetchNutrition = async(aplan) => {
  const [progress, target] = await Promise.all([...getFirebasePromises(aplan, 'nutrition')]);
  return { progress, target, type: 'nutrition' }
}

export const fetchActivity = async(aplan) => {
  const [progress, target] = await Promise.all([...getFirebasePromises(aplan, 'activity')]);
  return { progress, target, type: 'activity' }
}

export const fetchComplianceConfig = async (cid) => {
  const res = await firebase.firestore().doc(`config/appConfig`).get();
  return { res, type: 'complianceMark' };
}