import React from 'react';
import { NavLink } from 'react-router-dom';

// needed to fix
// - "Function components cannot be given refs. Attempts to access this ref will fail. Did you mean to use React.forwardRef()?"
// - Invalid prop `component` supplied to `ForwardRef(ButtonBase)`. Expected an element type that can hold a ref. Did you accidentally provide a plain function component instead?
// - For more information see https://material-ui.com/r/caveat-with-refs-guide
export default React.forwardRef((props, _) => {
  const { staticContext, ...rest } = props
  return <NavLink {...rest}/>
})
