import _ from 'lodash';
import React, { useContext } from 'react';
import Img from 'react-image';
import { DetailPgStyling } from 'fitbud/hooks/useDetailPgStyle';
import FoodHeader from 'fitbud/views/foodRecipes/newUi/foodHeader';

import { Card,Typography,Link, CardContent,Grid,Tooltip } from '@material-ui/core';
import { Conversion } from 'fitbud/providers/conversion';
import { GMML_SERVINGS } from 'fitbud/utils/helpers';
import { MoreLessTextBtn } from 'fitbud/components/moreLessText';
import {SupplementLinkIcon} from "fitbud/icons/catalogSecondLineIcons";
import copy2clip from 'copy-to-clipboard';
import { useSnackbar } from 'notistack';
import {DRAFT} from "fitbud/utils/constants"
import {DraftBanner} from "fitbud/components/draftBanner";

function SupplementDetails(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { doc, handleEditMode, servingData, copyItem, deleteItem,isPreview,draftBannerProps={} } = props;
  const { convertor } = useContext(Conversion);
  const getServingSize = () => {
    let size = doc.serving_size;
    let unit = doc.serving_unit || '';
    if (doc.type === 'supplement' && doc.serving_type) {
      size = convertor.getDisplayValue(doc.serving_type, size);
      unit = convertor.getDisplayUnit(doc.serving_type);
    }
    return `${size} ${unit}`;
  };
  const onCopy=()=>{
    copy2clip(doc.link);
    enqueueSnackbar('Copied');
  }

  const serving = _.find(servingData, (d) => d[0] === doc.serving);
  const _description = _.get(doc,"description",'').trim();
  const isDraft=draftBannerProps.showingDoc===DRAFT;

  return (
    <DetailPgStyling>
      {draftBannerProps.isDraftAvailable && !isPreview && (
        <DraftBanner
          {...draftBannerProps}
          doc={doc}
        />
      )}
      <Card square elevation={0}>
        <FoodHeader
          isPreview={isPreview}
          doc={doc}
          handleEditMode={handleEditMode}
          onDelete={deleteItem}
          onCopy={copyItem}
          keyName="supplements"
          isDraft={isDraft}
        />
        <CardContent>
          {_description && (
            <MoreLessTextBtn
              text={_description}
              className="desc fmb-25"
              btnClassName="viewMore"
            />
          )}
          <Grid container>
            <Grid item xs={12}>
              <Typography className="fmb-10 field-nutrition-title">
                Serving Size
              </Typography>
              <Typography className="field-value">
                1{" "}
                {!serving || GMML_SERVINGS.includes(doc.serving)
                  ? "Serving"
                  : _.capitalize(serving[1].value)}
                {!!doc.serving_size && ` (${getServingSize()})`}
              </Typography>
            </Grid>
            {!!doc.link && (
              <Grid item xs={12} className="fmt-25">
                <Typography className="fmb-10 field-nutrition-title">
                  Link{" "}
                  <Tooltip title="Copy Link">
                    <SupplementLinkIcon
                      onClick={onCopy}
                      className="cursor-pointer"
                    />
                  </Tooltip>
                </Typography>
                <Typography
                  component={Link}
                  href={doc.link.startsWith('http')?doc.link:`https://${doc.link}`}
                  target="_blank"
                  className="field-value text-primary"
                >
                  {doc.link}
                </Typography>
              </Grid>
            )}
            {!!doc.thumbnail && (
              <Grid item xs={12} className="fmt-25">
                <Typography className="fmb-10 field-nutrition-title">
                  Media
                </Typography>
                <div className="media-container media-img">
                  <Img
                    src={[
                      doc.thumbnail,
                      doc.thumbnail.replace("240", "original"),
                    ]}
                  />
                </div>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </DetailPgStyling>
  );

}

export default SupplementDetails;
