import React from "react";
import {
  Typography,
} from "@material-ui/core";

import PeopleGroup from "fitbud/images/people_group.svg";

export const GroupChatPlaceholder = () => {
  return (<div className=" h-100 text-center d-flex align-items-center justify-content-center flex-column px-5 ">
    <img alt="noitem" src={PeopleGroup} />
    <Typography variant="h5" className="fmt-20">
      Send messages to multiple clients at once.
    </Typography>
    <Typography variant="h5" className="fmt-20">
      To start sending, select the audience from the left panel.
    </Typography>
  </div>)
}

export const GroupChatMessagePlaceholder = ({hideDescription=false}) => {
  return (<div className="w-100 h-100 text-center d-flex align-items-center justify-content-center flex-column px-5 ">
    <img alt="noitem" src={PeopleGroup} />
    <Typography variant="h3" className="fmt-20">
      No messages yet
    </Typography>
   {!hideDescription && <Typography variant="h5" className="fmt-20 w-75" color="textSecondary">
      Be the first to post! Send a message to welcome the group members and get the ball rolling.
    </Typography>}
  </div>)
}

