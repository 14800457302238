import makeStyles from "@material-ui/core/styles/makeStyles";


export const useStyles = makeStyles((theme) => ({
    root: {
      minHeight: '60px',
      '& .MuiListItemText-primary': {
        marginBottom: '2px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        fontWeight: '600',
      },
      '& .MuiListItemText-secondary': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      '&.Mui-selected': {
        backgroundColor: '#E8F1FC',
        '& $free':{
          backgroundColor:"white"
        },
        '&:hover': {
          backgroundColor: '#E8F1FC',
        },
        
      },
      '&:hover': {
        backgroundColor: '#F6F9FE',
      },
    },
    content: {
      width: 240,
      height: 220,
    },
    addCollectionButton: {
      width: '360px',
      height: '40px',
      background: '#EDF3FE',
      borderRadius: '5px',
      marginTop: '12px',
      fontSize: '15px',
      padding: '8px 0',
      marginBottom: '15px',
      cursor: 'pointer',
      color: '#4089F7',
      marginLeft:10
    },
    activeBadge: {
      // minWidth: '6px !important',
      // width: '6px !important',
      // height: '6px !important',
      color: '#05B715',
      marginLeft:10
      // borderRadius: '50%',
    },
    inactiveBadge: {
      // minWidth: '6px !important',
      // width: '6px !important',
      // height: '6px !important',
      color: '#D73717',
      marginLeft:10
      // borderRadius: '50%',
    },
    free:{
      borderRadius:40,
      height: 22,
      backgroundColor:"#F2F4F7"
    }
  }));