import axnsBuilder from "./actions";

export const DEFAULT_STATE = {
  activeUsers: null,
  newMessages: null,
  newCheckins: null,
  leads: null,
};

const axns = axnsBuilder();
export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case axns.addNew:
      return {
        ...state,
        [action.key]: state[action.key] + 1,
        timestamp: Date.now()
      };
    case axns.markRead:
      return {
        ...state,
        [action.key]: Math.max(0, state[action.key] - 1),
        timestamp: Date.now()
      };
    case axns.set:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state;
  }
};
