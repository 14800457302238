import _ from 'lodash';
import React, { useState, useEffect, useContext } from "react";
import {
  TextField,
  InputAdornment
} from "@material-ui/core";
import {Conversion} from "fitbud/providers/conversion";
import { FormTextField } from "fitbud/components/form-fields";
import { countDecimals, fakeEvent } from "fitbud/utils/helpers";
import { ToggleLabel } from "./toggles";
import {MAX_REP_VALUE} from "fitbud/utils/constants";

const DECOR_NONE = 0;
const DECOR_TEXT = 1;
const DECOR_TOGGLE = 2;

const inputProps = (allowsFloat = false, overrides) => {
  let out = {min: 0, step: allowsFloat ? '0.01' : '1', autocomplete: 'off'};
  if (overrides) out = {...out, ...overrides};
  return out;
}

const NumUnitInput = (props) => {
  const {
    id, name,
    unit = '', opts = undefined,
    decor = DECOR_TOGGLE,
    ignoreInitial = false,
    label, error,
    value: defaultValue,
    onChange, onUnitChange,
    onKeyDown,
    LabelProps = {},
    InputProps = {},
    disabled, readOnly,
    noFloat = false,
    fullWidth = true,
    hideUnitChangeIcon=false,
    type="number",
    ...rest
  } = props;
  const {convertor} = useContext(Conversion);
  const initialValue = sanitize(defaultValue);
  const [value, setValue] = useState(ignoreInitial ? initialValue : convertor.getDisplayValue(unit, initialValue));

  // start updating value if field has become disabled/readonly
  useEffect(() => {
    if (disabled || readOnly)
      setValue(ignoreInitial ? initialValue : convertor.getDisplayValue(unit, initialValue))
  }, [initialValue, disabled, readOnly]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    !ignoreInitial && onChange && onChange(fakeEvent(id || name, initialValue, {display: value, unit}), true) // second arguments is passing for to stop marking dirty in parents handler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, name]);
  const _onChange = (e) => {
    //----MAX REPS-------
    let maxRepCase=false;
    if(e.target.type==="text"){
      if(_.includes(e.target.value,'x')){
        e.target.value=String(MAX_REP_VALUE);
        maxRepCase=true;
      }
      else{
        const regex=new RegExp("^[0-9]*$");
        if(regex.test(e.target.value)){
          e.target.type=!!e.target.value?"number":"text";
        }
        else return;
      }
    }
    //----MAX REPS-------
    const eValue=e.target.value;
    if (disabled || readOnly) return; // component behaves in uncontrolled state in this scenario
    const numDecimals = noFloat ? 0 : convertor.getNumDecimals(unit);
    if (countDecimals(eValue) > numDecimals) return;
    let value = convertor.getDisplayValue('', eValue, numDecimals);
    setValue(maxRepCase?"X":value);
    e.target.value = convertor.getDBValue(unit, value);
    e.target.display = value;
    e.target.unit = unit;
    onChange && onChange(e);
  };
  const _onUnitChange = (x) => {
    if (onUnitChange) onUnitChange(x);
    if (onChange) // XXX setTimeout needed to bypass the async nature of setState ... I think
    setTimeout(() => onChange(fakeEvent(id || name, convertor.getDBValue(x, value), {display: value, unit: x})), 0);
  }
  const {inputProps: _inputProps, ..._InputProps} = InputProps;
  return (
    <FormTextField fullWidth={fullWidth} label={label} {...LabelProps}>
      <TextField id={id} name={name}
        type={type} error={error}
        disabled={disabled}
        readOnly={readOnly}
        onChange={_onChange}
        onKeyDown={onKeyDown}
        value={value || 0}
        InputProps={{
          inputProps: inputProps(!noFloat && convertor.allowsFloat(unit), _inputProps),
          endAdornment: <EndAdornment convertor={convertor} unit={unit} opts={opts} disabled={disabled} decor={decor} onChange={_onUnitChange} hideUnitChangeIcon={hideUnitChangeIcon}/>,
          ..._InputProps
        }}
        {...rest}
      />
    </FormTextField>
  );
};

const EndAdornment = React.memo((props) => {
  const {convertor, unit = '', opts = undefined, decor, onChange,hideUnitChangeIcon,disabled} = props;
  if (!unit || !decor || decor === DECOR_NONE)
    return null;
  const _opts = convertor.getToggleOpts(unit, opts);
  if (_opts && decor === DECOR_TOGGLE && !!onChange) {
    return (<InputAdornment position="end">
      <ToggleLabel disabled={disabled} size="small" value={unit} options={_opts} onChange={onChange} hideBtn={hideUnitChangeIcon}/>
    </InputAdornment>);
  }
  const text = convertor.getDisplayUnit(unit);
  if (decor >= DECOR_TEXT && !!text)
    return <InputAdornment position="end">{text}</InputAdornment>;
  return null;
});
const checkX=n=>n===MAX_REP_VALUE?"X":n;
const sanitize = x => {
  const out = _.toNumber(x);
  return _.isFinite(out) ? checkX(out) : 0;
}

export default NumUnitInput;
