import React,{useMemo} from 'react';
import { makeStyles,Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import infoIcon from "fitbud/images/info.svg";
import {secondsToDuration} from "fitbud/components/durationInput";
const styles=makeStyles({
    container:{
        width: "fit-content",
        border: '1px solid #D3D9DB',
        transform: 'translate(-50%, 0px)',
        position: 'absolute',
        left: 0,
        top: 30,
        padding:'4px 10px',
        borderRadius:30,
        background:"#fff",
        zIndex: 5
    }

})
export const SegmentDuration=({durationInSecs})=>{
    const formattedTime=useMemo(()=>{
        const time=secondsToDuration(durationInSecs).split(" : ");
        return (!Number(time[0])?time.slice(1):time).join(":");
    },[durationInSecs])
    const classes=styles();
    return(
        <div className={clsx(classes.container,'shadow font_16_600')}>
          <span className='fmr-5'>{formattedTime}</span>
          <Tooltip title="Estimated duration of workout segment">
            <img
                src={infoIcon}
                alt=""
                height={10}
                width={10}
              />
          </Tooltip>
        </div>
    )
}