import React, {useMemo, useContext} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
  import { secondsToDuration } from "fitbud/components/durationInput";
  import { Conversion } from "fitbud/providers/conversion";
  import clsx from 'clsx';
const useStyles = makeStyles(theme => ({
    stepAvatar: {
      backgroundColor:theme.palette.primary.main,
      height: 10,
      width: 10,
      borderRadius: 5,
      marginTop: 5
    },
    stepDivider: {
      border: `solid 2px ${theme.palette.background.default}`,
      flex: 1,
      marginBottom: "-5px"
    },
    stepsRoot:{
      "& .stepTitle":{
        fontSize: '15px',
        fontWeight: 500,
        paddingBottom: '5px',
      },
      "& .stepValue":{
        fontSize: '30px',
        fontWeight: 600,
      },
      "& .stepUnit":{
        fontSize: '15px',
        fontWeight: 500,
      }
    }
  }));
  
const ManualDetails = ({dayWiseData}) => {
    const classes = useStyles();
    const { convertor } = useContext(Conversion);
    const {cals,distance,duration,min_hr,max_hr,avg_hr}=dayWiseData;
    const [ h,m,s ] = useMemo(() => {
        const parsedDuration=secondsToDuration(dayWiseData.duration || 0);
        return parsedDuration.split(" : ").map(i=>Number(i));
      },[duration]);
    const _distance = useMemo(()=>convertor.getDisplayValue("distance", distance),[distance]);
    const speed=useMemo(()=>{
        const timeInHr=duration?duration/3600:0;
        if(timeInHr && distance) return (distance/timeInHr).toFixed(1);
        return 0;
    },[duration,distance]);
    const [paceH,paceM,paceS]=useMemo(()=>{
        if(!!duration && distance) {
            const parsed=secondsToDuration(duration/distance);
            return parsed.split(" : ").map(i=>Number(i));
        }
        return [0,0,0];
    },[duration,distance])
    return (
        <div className={clsx('mt-20',classes.stepsRoot)}>  
            {/*----------- DISTANCE ----------*/}
            {!!_distance && <div className='d-flex'>
                <div className="d-flex flex-column justify-content-start align-items-center">
                    <div className={classes.stepAvatar}></div>
                    {!!(duration||cals||(min_hr || max_hr || avg_hr)||speed||(paceM || paceS)) && <div className={`${classes.stepDivider} light-bg-grey`} />}
                </div>
                <div className="fpl-20 fpb-25">
                    <Typography className="stepTitle">
                        Distance
                    </Typography>
                    <div className="d-flex align-items-baseline">
                        <Typography className="stepValue">{_distance}</Typography>
                        <Typography className='stepUnit'>&nbsp;{convertor.getDisplayUnit("distance")}</Typography>
                    </div>
                </div>
            </div>}
            {/*----------- DURATION ----------*/}
            {!!duration && <div className='d-flex'>
                <div className="d-flex flex-column justify-content-start align-items-center">
                    <div className={classes.stepAvatar}></div>
                    {!!(cals||(min_hr || max_hr || avg_hr)||speed||(paceM || paceS)) && <div className={`${classes.stepDivider} light-bg-grey`} />}
                </div>
                <div className="fpl-20 fpb-25">
                    <Typography className="stepTitle">
                        Duration
                    </Typography>
                    <div className="d-flex align-items-baseline">
                        {h > 0 && (<>
                            <Typography className="stepValue mr-5">&nbsp;{h}</Typography>
                            <Typography className='stepUnit'>hr</Typography>
                        </>)}
                        {m > 0 && (<>
                            <Typography className="stepValue mr-5">&nbsp;{m}</Typography>
                            <Typography className='stepUnit'>min</Typography>
                        </>)}
                        {s > 0 && (<>
                        <Typography className="stepValue">{(s < 10 ? "0" : "") + s} </Typography>
                        <Typography className='stepUnit'>sec</Typography>
                        </>)}
                    </div>
                </div>
            </div>}
            {/*----------- ENERGY ----------*/}
            {!!cals && <div className='d-flex'>
                <div className="d-flex flex-column justify-content-start align-items-center">
                    <div className={classes.stepAvatar}></div>
                   {!!((min_hr || max_hr || avg_hr)||speed||(paceM || paceS)) && <div className={`${classes.stepDivider} light-bg-grey`} />}
                </div>
                <div className="fpl-20 fpb-25">
                    <Typography className="stepTitle">
                        Energy
                    </Typography>
                    <div className="d-flex align-items-baseline stepValue">
                    <Typography className="stepValue">{cals}</Typography>
                    <Typography className='stepUnit'>&nbsp;cals</Typography>
                    </div>
                </div>
            </div>}
            {/*----------- HEART RATE ----------*/}
            {!!(min_hr || max_hr || avg_hr) &&  <div className='d-flex'>
                <div className="d-flex flex-column justify-content-start align-items-center">
                    <div className={classes.stepAvatar}></div>
                    {!!(speed||(paceM || paceS)) && <div className={`${classes.stepDivider} light-bg-grey`} />}
                </div>
                <div className="fpl-20 fpb-25">
                    <Typography className="stepTitle">
                        Heart Rate
                    </Typography>
                    <div className="d-flex align-items-baseline stepValue">
                        {min_hr && max_hr && (<>
                        <Typography className="stepValue">
                            {min_hr + " - " + max_hr}
                        </Typography>
                        <Typography className='stepUnit'>&nbsp;bpm</Typography>
                        </>)}
                        {!!avg_hr && (<>
                            <Typography className='stepValue'>&nbsp;/ {avg_hr}</Typography>
                        <Typography className='stepUnit'>&nbsp;avg</Typography>
                        </>)}
                    </div>
                </div>
            </div>}
            {/*----------- SPEED ----------*/}
            {!!speed && <div className='d-flex'>
                <div className="d-flex flex-column justify-content-start align-items-center">
                    <div className={classes.stepAvatar}></div>
                    {!!((paceM || paceS)) && <div className={`${classes.stepDivider} light-bg-grey`} />}
                </div>
                <div className="fpl-20 fpb-25">
                    <Typography className="stepTitle">
                        Speed
                    </Typography>
                    <div className="d-flex align-items-baseline stepValue">
                    <Typography className="stepValue">{speed} </Typography>
                    <Typography className="stepUnit">&nbsp;{convertor.getDisplayUnit('speed')}</Typography>
                    </div>
                </div>
            </div>}
            {/*----------- PACE ----------*/}
            {!!(paceM || paceS) && <div className='d-flex'>
                <div className="d-flex flex-column justify-content-start align-items-center">
                    <div className={classes.stepAvatar}></div>
                </div>
                <div className="fpl-20">
                    <Typography className="stepTitle">
                        Pace
                    </Typography>
                    <div className="d-flex align-items-baseline stepValue">
                    <Typography className="stepValue">{paceM}</Typography>
                    <Typography className="stepUnit">&nbsp;min</Typography>
                    <Typography className="stepValue">&nbsp;{paceS}</Typography>
                    <Typography className="stepUnit">&nbsp;sec / km</Typography>
                    </div>
                </div>
            </div>}
        </div>
    )
}
export default ManualDetails;