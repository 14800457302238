import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const PlayIcon = props => (
  <SvgIcon
    width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
   <g id="Group 30">
    <circle id="Oval" cx="25" cy="25" r="25" fill="black" fill-opacity="0.5"/>
    <path id="Vector" d="M18.75 12.898C18.75 12.7247 18.9406 12.6191 19.0875 12.7109L38.4507 24.8129C38.5889 24.8993 38.5889 25.1007 38.4507 25.1871L19.0875 37.2891C18.9406 37.3809 18.75 37.2753 18.75 37.102V12.898Z" fill="white"/>
   </g>
  </SvgIcon>);