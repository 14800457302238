import React, { useState } from "react";
import { withSnackbar } from "notistack";
import { Popover, Typography } from "@material-ui/core";
import { ClrdButton } from "fitbud/components/form-fields";
import ExerciseTypeSubTypeForm from "./exerciseTypeSubTypeForm";
import { Tabs } from "fitbud/components/tabs";


const RestPopover = props => {
  const { anchorEl, onClose, handleRestChange, type:keyType } = props;

  let restType = "range";
  if (props.values.length === 1) {
    restType = "fixed";
  }

  const [type, setType] = useState(restType);
  const [values, setValues] = useState(props.values);
  const [errors, setErrors] = useState({});

  const handleValueChange = e => {
    const newValues = [...values];
    const valueIndex = e.target.name.split("_").pop();
    newValues[valueIndex] = parseInt(e.target.value);
    setValues(newValues);
    setErrors({});
  };

  const handleType = (value) => {
    setErrors({});
    let _out = [];
    if(value === "fixed") _out = [0]
    else if(value === "range") _out = [0,0]
    setValues(_out);
    setType(value);
  };

  const onSubmit = e => {
    if (!valid()) return;

    handleRestChange && handleRestChange(e, values, () => {
      setErrors({});
      onClose();
    }, keyType);
  };

  function valid() {
    let out = true;
    let errors = {};
    errors.values = [];
    switch (type) {
      case "fixed":
        //allow 0s fixed
        // if (!values[0]) {
        //   errors.values[0] = "Please enter a valid rest duration";
        //   out = false;
        // }
        
        break;
      case "range":
        if (!values[0]) {
          errors.values[0] = "Please enter a valid rest duration";
          out = false;
        }
        if (!values[1]) {
          errors.values[1] = "Please enter a valid rest duration";
          out = false;
        }
        if(values[0] && values[1] && values[0]>values[1]){
          errors.values[0] = "Please provide valid min value";
          out = false;
        }
        break;
      default:
        break;
    }
    setErrors(errors);
    const firstErr=(errors.values||[]).find(i=>!!i);
    if(firstErr){
      props.enqueueSnackbar(firstErr, {
        variant: "error"
      });
    }
    return out;
  }

  return (
    <Popover
      id="rest-popover"
      open
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      classes={{
        paper: "medium"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
    >
      <div className="fp-20">
        <Typography className="font_15_600 font-weight-700 fmb-20">Rest</Typography>
        <Tabs
          value={type}
          handleChange={handleType}
          options={[
            {value:'fixed',label:'Fixed'},
            {value:"range",label:"Range"}
          ]}
          componentType="slideBtns"
          useMountHack={true}
        />
        <ExerciseTypeSubTypeForm
          unit='rest'
          sub_type={type}
          key={`_rest_${type}`}
          handleChange={handleValueChange}
          values={values}
          errors={errors.values || []}
          inputProps={{
            "data-group": props["data-group"],
            "data-exercise": props.index
          }}
        />
        <ClrdButton
          variant="contained"
          color="primary"
          fullWidth
          className=" f-xlarge"
          onClick={onSubmit}
          data-group={props["data-group"]}
          data-exercise={props.index}
        >
          <Typography className="font_15_600 font-weight-700">Save</Typography>
        </ClrdButton>
      </div>
    </Popover>
  );
};

export default withSnackbar(RestPopover);
