import React, { useState, useContext } from "react";
import { Typography, IconButton, Divider, TextField } from "@material-ui/core";
import OptionsIcon from "@material-ui/icons/MoreVert";
import { MenuList } from "fitbud/components/menuList";
import { EditorContext } from "fitbud/views/workouts/exerciseEditor";
import { capitalize } from "lodash";
import Dialog from "fitbud/components/Dialog";
import { FormTextField } from "fitbud/components/form-fields";
import clsx from "clsx";

export const GrpHeaderPrevw = ({
  title,
  description,
  isEditMode,
  grpIndex,
}) => {
  const { handleGrpHeader } = useContext(EditorContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isHeaderFormOpen, toggleHeaderForm] = useState(false);

  const openMenu = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const onClose = () => {
    setAnchorEl(null);
  };
  const closeHeaderForm = () => {
    toggleHeaderForm(false);
  };
  const formSubmit = (values) => {
    handleGrpHeader(grpIndex, values);
    closeHeaderForm();
  };
  const handleClick = (e, v) => {
    if (v === "Edit") {
      toggleHeaderForm("edit");
    } else if (v === "Delete") {
      handleGrpHeader(grpIndex, { title: "", description: "" });
    }
    onClose();
  };
  if (!title) return null;
  return (
    <div className={clsx("w-100", isEditMode && "d-flex")}>
      <div style={{ flex: 1 }} className="text-wrap">
        <Typography className="font_16_600 mb-10">{title}</Typography>
        {description && (
          <Typography
            className="font_14_500 mb-10 text-grey"
            style={{ lineHeight: "20px" }}
          >
            {description}
          </Typography>
        )}
      </div>
      {isEditMode ? (
        <div>
          <IconButton
            onClick={openMenu}
            className="position-relative"
            style={{ top: "-8px" }}
          >
            <OptionsIcon />
          </IconButton>
          {!!anchorEl && (
            <MenuList
              anchorEl={anchorEl}
              onClose={onClose}
              options={["Edit", "Delete"]}
              handleClick={handleClick}
            />
          )}
        </div>
      ) : (
        <Divider className="mb-20 p-exact" />
      )}
      {/* {isEditMode && (
          <>
            <IconButton
              onClick={openMenu}
              className="position-absolute"
              style={{ right: 0, top: "-8px" }}
            >
              <OptionsIcon />
            </IconButton>
            {!!anchorEl && (
              <MenuList
                anchorEl={anchorEl}
                onClose={onClose}
                options={["Edit", "Delete"]}
                handleClick={handleClick}
              />
            )}
          </>
        )} */}
      {!!isHeaderFormOpen && (
        <GrpHeaderForm
          onClose={closeHeaderForm}
          handleSubmit={formSubmit}
          initialValues={{ description, title }}
        />
      )}
    </div>
  );
};
export const GrpHeaderForm = ({
  handleSubmit,
  onClose,
  initialValues = {},
  action = "add",
}) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const { title, description } = values;
  const handleChange = (e) => {
    const { id, name, value } = e.target;
    const chng = { [id || name]: value };
    setValues((o) => ({
      ...o,
      ...chng,
    }));
  };
  const _handleSubmit = () => {
    const { title = "", description = "" } = values;
    const errors = {};
    if (!title.trim()) {
      errors["title"] = "Please enter valid title";
    }
    setErrors(errors);
    if (!!Object.keys(errors).length) {
      return;
    }
    handleSubmit({
      title: title.trim(),
      description: description.trim(),
    });
  };
  return (
    <Dialog
      open
      title={`${capitalize(action)} Segment Header`}
      onSave={_handleSubmit}
      onClose={onClose}
      dialogContentClassName="fp-20"
      paperClass="width-400"
    >
      <FormTextField fullWidth label="Header Name" required>
        <TextField
          id="title"
          autoComplete="off"
          value={title}
          onChange={handleChange}
          error={!!errors.title}
          helperText={errors.title}
          variant="outlined"
          // disabled={loading}
          required
          type="text"
          InputProps={{
            classes: {
              root: "medium",
              input: "size_16_500 medium",
            },
          }}
        />
      </FormTextField>
      <FormTextField
        fullWidth
        label="Description"
        classes={{ control: "mb-0" }}
      >
        <TextField
          multiline
          // rows="2"
          // rowsMax="10"
          id="description"
          fullWidth
          onChange={handleChange}
          value={description}
          error={!!errors.description}
          helperText={errors.description}
          variant="outlined"
          // disabled={loading}
          required
          InputProps={{
            classes: {
              root: "large",
              input: "size_16_500",
            },
          }}
        />
      </FormTextField>
    </Dialog>
  );
};
