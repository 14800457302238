import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

const ImportIcon = props => { 
  const theme = useTheme();
  let stroke = '#37404D'; // theme.palette.action.active;
  const { color, ...rest } = props;
  if (color) {
    if (theme.palette[color])
      stroke = theme.palette[color].main;
    else
      stroke = color;
  }
  return (
    <SvgIcon {...rest}>
      <path d='M4 13V19C4 19.5304 4.21071 20.0391 4.58579 20.4142C4.96086 20.7893 5.46957 21 6 21H18C18.5304 21 19.0391 20.7893 19.4142 20.4142C19.7893 20.0391 20 19.5304 20 19V13' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' stroke={stroke} fill='none'/>
      <path d='M12 15L15.5 11.5M12 3V15V3ZM12 15L8.5 11.5L12 15Z' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' stroke={stroke} fill='none'/>
    </SvgIcon>
  )
};

export default ImportIcon;
