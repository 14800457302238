import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const MoneyIcon = props => (
  <SvgIcon
  width="16" height="16" viewBox="0 0 16 16"
  classes={{
    root: "small"
  }}
  {...props}
  >
    <g fill="none">
      <path d="M12.8667 5.27999V8.71334C12.8667 10.7667 11.6933 11.6466 9.93335 11.6466H4.07334C3.77334 11.6466 3.48668 11.62 3.22001 11.56C3.05334 11.5334 2.89335 11.4867 2.74668 11.4333C1.74668 11.06 1.14001 10.1933 1.14001 8.71334V5.27999C1.14001 3.22666 2.31334 2.34668 4.07334 2.34668H9.93335C11.4267 2.34668 12.5 2.98001 12.7867 4.42668C12.8333 4.69334 12.8667 4.96666 12.8667 5.27999Z" stroke="#6F8099" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M14.8674 7.28007V10.7134C14.8674 12.7668 13.6941 13.6467 11.9341 13.6467H6.0741C5.58076 13.6467 5.1341 13.5801 4.74744 13.4334C3.9541 13.1401 3.4141 12.5334 3.22076 11.5601C3.48743 11.6201 3.7741 11.6467 4.0741 11.6467H9.9341C11.6941 11.6467 12.8674 10.7668 12.8674 8.71342V5.28007C12.8674 4.96674 12.8408 4.68676 12.7874 4.42676C14.0541 4.69342 14.8674 5.58674 14.8674 7.28007Z" stroke="#6F8099" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.99896 8.75989C7.97098 8.75989 8.75897 7.9719 8.75897 6.99988C8.75897 6.02786 7.97098 5.23987 6.99896 5.23987C6.02694 5.23987 5.23895 6.02786 5.23895 6.99988C5.23895 7.9719 6.02694 8.75989 6.99896 8.75989Z" stroke="#6F8099" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M3.18671 5.53333V8.46667" stroke="#6F8099" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.8145 5.53357V8.46692" stroke="#6F8099" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </g>
  </SvgIcon>
);
