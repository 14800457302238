import _ from 'lodash';
import { useMemo, useContext, useEffect } from 'react';
import { TagsContext } from 'fitbud/providers/tagsProvider';

const sortedPoses = (keys, map) => {
  if (!keys || !keys.length || !map) return [];
  return _.chain(keys).map(key => {
    const { default_priority, priority } = map[key] || {};
    return {key, priority: (priority || default_priority || 0)};
  }).sortBy(['priority']).map('key').value();
};

const grabKeys = chkin => {
  if (!chkin || !chkin.photos) return [];
  const {aspect, identifier, ...rest} = chkin.photos || {}; // eslint-disable-line no-unused-vars
  return Object.keys(rest);
};

const usePoses = (compConf, userOverrides, ...chkins) => {
  const { maps: { photos }, fetchTags } = useContext(TagsContext);
  useEffect(() => {
    fetchTags('photos');
  }, [fetchTags]);
  const out = useMemo(() => {
    if (!photos || !chkins || !chkins.length) return [];
    const { poses: cp2 } = compConf || {};
    const { poses: up2 } = (userOverrides && userOverrides.checkin_config) || {};
    const map = {...photos, ...(cp2 || false), ...(up2 || false)};
    const list = _.union(...chkins.map(grabKeys));
    return sortedPoses(list, map);
  }, [photos, compConf, userOverrides, chkins]);
  return out;
};

export default usePoses;
