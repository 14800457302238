import React, { useState,useCallback, useEffect, useLayoutEffect, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { List,  LinearProgress, InputBase, InputAdornment, IconButton, Badge } from "@material-ui/core";
import { FilterIcon } from 'fitbud/icons/filterIcon';
import { LineFilterIcon } from 'fitbud/icons/lineFilterIcon';
import { makeStyles } from "@material-ui/core/styles";
import { bffUpdateHubspotProp, searchExercises } from "fitbud/api";
import { useSnackbar } from "notistack";
import { DEFAULT_ERROR, HUBSPOT_PROPS } from "fitbud/utils/constants";
import {DumbList,Placeholder} from "fitbud/components/catalog";
import {get,debounce} from 'lodash';
import clsx from 'clsx';
import {listSecondLine} from 'fitbud/components/catalog';
import searchIcon from 'fitbud/images/searchIcon.svg';
import CatalogFilter from 'fitbud/components/catalog-filter';
import { isFilterExists } from 'fitbud/components/catalog/util';
import { getExIcon } from "fitbud/utils/catalog";
import { Alert } from "@material-ui/lab";
const ExerciseSearchList = props => {
  const {
    list,
    query,
    loading,
    nextPage,
    onClick,
    fetchMore,
    showInfo,
    ...listProps
  } = props;
  const listRef = useRef();
  useLayoutEffect(() => {
    listRef.current.scrollTo(0,0)
  },[query])
  const parseListData=(l)=>{
    const mediaType=get(l,'data.media_type');
    const obj={
        primaryText:get(l,'data.ref_name'),
        secondaryText:listSecondLine({doc:l,keyName:'exercise-selector'}),
        src:get(l,'data.thumbnail'),
        //only for exercises
        icon:getExIcon({type:mediaType})
    }
    return {...obj};
  }
  return (
    <>
      {loading && <LinearProgress className='position-absolute w-100' style={{top:0,left:0,zIndex:1}}/>}
      <List id="scrollableDiv" disablePadding className="overflow-auto w-100" ref={listRef}>
        {showInfo && (
          <Alert severity="info" icon={false} className="font_13_500 p-20" classes={{ message: "p-0" }}>
            <span className="font_13_600">Comprehensive Exercise Database</span>.{" "}
            Access 4000+ exercises with interactive videos
          </Alert>
        )}
        <InfiniteScroll
          dataLength={list.length}
          next={fetchMore}
          hasMore={nextPage}
          scrollableTarget="scrollableDiv"
        >
        <>
          <DumbList
            docs={list}
            parseListData={parseListData}
            onClick={(e,doc)=>onClick(doc)}
            {...listProps}
          />
        </>
        </InfiniteScroll>
      </List>
    </>
  );
}
const styles = makeStyles((t) => ({
    header:{
      borderBottom:'1px solid #D8DCE0',
    },
    input:{
      borderRadius: "20px",
      height: 40,
      border: "1px solid #D3D9DB",
      background:'#fff',
      padding:'0px 10px',
    },
    placeholder: {
      width: "60%"
    }
  }));
//Used by: Exercise view: add Fitbudd Ex List
const ListEDCItems = props => {
    const classes=styles();
    const {
      searchOff,
      query:propsQuery="",
      noResultFoundAction,
      filterState: filterStateProp,
      ...restProps
    } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [list, updateList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [nextPage, updateNextPage] = useState(null);
    const [q, setQ] = useState(propsQuery);
    const [filterOpen, setFilterOpen] = useState(false);
    const [filterState, setFilterState] = useState({});
    const [showInfo, setShowInfo] = useState(false);
    const filterRef = useRef({});
    const _appliedFilter = isFilterExists(filterState)
    //---analytics-----
    useEffect(() => {
      bffUpdateHubspotProp(HUBSPOT_PROPS.EXERCISE_DB_BROWSED);
    }, []);
    //-----xx----------
    useEffect(()=>{
      filterRef.current = filterState;
    },[filterState])

    useEffect(()=>{
      //query coming as props
      setQ(propsQuery)
    },[propsQuery])
    useEffect(()=>{
      //filters coming as props
      setFilterState(filterStateProp);
      // filterRef.current = filterState;
    },[filterStateProp])
    useEffect(()=>{
      if(!!q) debouncedFetch()
      else fetch();
    }, [q,filterState ]);


    const handleSearchChange = e => {
      setQ(e.target.value);
    };
    const fetch=async()=>{
      let latestQ;
      setQ(o=>latestQ=o);
      try{
        setLoading(true);
        const {data} = await searchExercises(latestQ, null, filterRef.current);
        const { data: result, next } = data;
        setShowInfo(!latestQ)
        if (!!result) {
          updateList(result)
          updateNextPage(next);
          setLoading(false);
        }
      } catch(e) {
        const {message} = e.response || {};
        enqueueSnackbar(message || DEFAULT_ERROR, { variant: "error" });
        setLoading(false);
      }
    }
    const debouncedFetch=useCallback(debounce(fetch,500),[])
    const fetchMore = async () => {
      try {
        setLoading(true);
        const { data } = await searchExercises(q, nextPage, filterRef.current);
        const { data: result, next } = data;
        const newList = [...list, ...result];
        if (!!result) updateList(newList);
        updateNextPage(next);
        setLoading(false);
      }catch(e) {
        const {success, message} = e.response
        if(!success) enqueueSnackbar(message, { variant: "error" });
        setLoading(false);
      }
    }
    const openFilter = () =>{
      setFilterOpen(true)
    }
    const closeFilter = () =>{
      setFilterOpen(false)
    }

    const applyFilter = (fState) =>{
      setFilterState(fState)
      filterRef.current = fState;
    };


   return (
      <div className="flex-1 w-100 d-flex flex-column">
        {!searchOff && <div className={clsx("bg-light-grey fpb-10 fpx-20 pt-5 d-flex",classes.header)}>
          <InputBase
            value={q}
            onChange={handleSearchChange}
            fullWidth
            autoFocus
            placeholder="Search by name, tags..."
            startAdornment={
                <InputAdornment position="start">
                    <img alt="search-icon" src={searchIcon} />
                </InputAdornment>
            }
            className={classes.input}
          />
          <IconButton onClick={openFilter} className="fml-5 ">
            {!!_appliedFilter ? (
              <Badge color="primary" badgeContent={_appliedFilter}>
                <FilterIcon />
              </Badge>
              ) :
              <LineFilterIcon />
            }
          </IconButton>
        </div>
        }
        <div className="position-relative flex-1 d-flex w-100">
        {!loading && !list.length && (
           <Placeholder
             noResultFoundAction={noResultFoundAction}
             keyName={'exercises'}
             isSearch={!!q}
             noResultLabel="Oops! It looks like we don't have that one yet."
             className={classes.placeholder}
           />
         )}
          <ExerciseSearchList
            list={list}
            nextPage={nextPage}
            loading={loading}
            fetchMore={fetchMore}
            query={q}
            showInfo={showInfo}
            {...restProps} 
          />
        </div>
        {filterOpen && <CatalogFilter
          filters={filterState}
          keyName="exercises"
          open={true}
          onSave={applyFilter}
          handleClose={closeFilter}
          ignoreFilter={["source"]}
        />
      }
      </div>
    );
  };
  
export default ListEDCItems;
