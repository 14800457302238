import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import usePublications from './usePublications';
import useTrack from './useTrack';
import useVideoContext from './useVideoContext';
import UseParticipantIsReconnecting from './useParticipantIsReconnecting';
import AvatarContainer from './avatarContainer';
import AudioLevelIndicator from './audioLevelIndicator';
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import firebase from "fitbud/firebase";
import useSelectedParticipant from './useSelectedParticipant';
import UseIsTrackEnabled from './useIsTrackEnabled';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  identity: {
    color: 'white',
    fontSize: '1.2em',
    display: 'inline-flex',
    alignItems: 'center',
  },
  infoContainer: {
    left: '0',
    right: '0',
    bottom: '0',
    height: '100px',
    padding: '0 1.43em',
    zIndex: '10',
    position: 'absolute',
    width: '100%',
  },
  reconnectingContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(40, 42, 43, 0.75)',
    zIndex: 1,
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'black',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
  },
}));





const MainParticipantInfo = ({ participant, children }) => {
  const classes = useStyles();
  const {
    room: { localParticipant },
    getUserRef,
    sessionData,
    isViewEnabled
  } = useVideoContext();
  const { authUser } = useContext(FirebaseAuthContext);
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();
  const [name, setName] = useState("");
  const isLocal = localParticipant === participant;
  const isSelected = selectedParticipant === localParticipant

  const publications = usePublications(participant);
  const videoPublication = publications.find(p => p.trackName.includes('camera'));

  let videoTrack = useTrack(videoPublication);

  const audioPublication = publications.find(p => p.kind === 'audio');
  const audioTrack = useTrack(audioPublication);

  const isTrackEnabled = UseIsTrackEnabled(videoTrack);

  const participantIdentity = participant.identity;

  useEffect(() => {
    Promise.all([
      getUserRef(participantIdentity),
    ])
      .then(([userDoc]) => {
        if(userDoc.data()){
          setName(userDoc.data().name)
        }else{
          setName(sessionData.name)
        }
      })
      .catch((err) => {

      });
  }, [participantIdentity]);
  
  const isParticipantReconnecting = UseParticipantIsReconnecting(participant);

  let userName = _.truncate(
    name, {
       'length': 25,
     }
  );
  
  return (
    <>
      <div className={classes.infoContainer}>
        <div className={classes.identity}>
          <AudioLevelIndicator audioTrack={audioTrack} />
          <Typography variant="body1" color="inherit">
            {isLocal ? ' You' : isViewEnabled && userName}
          </Typography>
        </div>
      </div>
      {(!isTrackEnabled) && (
        <div>
          <AvatarContainer isLocal={isLocal} identity={userName} audioTrack={audioTrack} />
        </div>
      )}
      {isParticipantReconnecting && (
        <div className={classes.reconnectingContainer}>
          <Typography variant="body1" style={{ color: 'white' }}>
            Reconnecting...
          </Typography>
        </div>
      )}
      {children}
    </>
  );
}

export default MainParticipantInfo;
