import React from 'react'
import _ from "lodash";
import { SearchCheckboxFilter } from "fitbud/components/filterComps";
import { fetchData } from "fitbud/api";
import { TAG_INDEX } from "fitbud/utils/constants";

function TagFilter(props) {
    const { cid } = props;
    const dataParser = ({data = { data: [] }}) => _.map(data && data.data, d => d.data.name)
    const fetch = (q) => fetchData(TAG_INDEX, cid, 0, q)
    return (
        <SearchCheckboxFilter {...props} dataParser={dataParser} fetchData={fetch} prefix="#" isMulti/>
    )
}

export default TagFilter
