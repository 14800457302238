import React, {
  useEffect,
  useState,
  useContext,
  useReducer,
  useLayoutEffect
} from "react";
import uuidv4 from "uuid/v4";
import firebase from "fitbud/firebase";
import { useHistory } from "react-router-dom";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import { AnalyticsContext } from "fitbud/providers/analytics";
import { fetchData } from "fitbud/api";
import { withSnackbar } from "notistack";
import { connect, useSelector } from "react-redux";
import {
  getWidthHeight,
  checkWidthHeight} from "fitbud/utils/helpers";
import {
  imageResizer,
  uploadDataUrl,
  fileToBase64
} from "fitbud/utils/services";
import "emoji-mart/css/emoji-mart.css";
import { makeStyles } from "@material-ui/core/styles";
import { 
  IconButton, 
  AppBar,
  Toolbar,
  Typography,
  Divider,
  Menu,
  MenuItem
} from "@material-ui/core";

import appRdxFns from "fitbud/redux/app";
import broadcastsRdxFns from "fitbud/redux/broadcastList";
import withWidth from "@material-ui/core/withWidth/index";
import BroadcastMessageListView from 'fitbud/views/broadcasts/broadcastMessageListView'
import BroadcastRecipientsList from 'fitbud/views/broadcasts/broadcastRecipientsList'
import ChatInput from 'fitbud/views/chats/chatInput';
import { snapshotDocsArrayToDataArray } from "fitbud/utils/helpers";
import AudioRecorder from "fitbud/components/audio-recorder/audioRecorder";
import MediaPreview from "fitbud/views/chats/mediaPreview";
import _ from "lodash";
import { DEFAULT_ERROR } from "fitbud/utils/constants";
import { handleError } from "fitbud/utils/helpers";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Confirmation from "fitbud/components/confirmationDialog";
import PageNotFound from "fitbud/views/pageNotFound";
import CircularLoader from "fitbud/components/CircularLoader";
import { getFilterString, DEFAULT_BROADCAST_GROUPS } from "./broadcastList";

const CHAT_IMAGE_MAX_WIDTH = 1920;
const CHAT_IMAGE_MAX_HEIGHT = 1920;
const CHAT_IMAGE_THUMBNAIL_MAX_WIDTH = 100;
const CHAT_IMAGE_THUMBNAIL_MAX_HEIGHT = 100;
const CHAT_FILE_SIZE_DEFAULT = 3; // MB

const PER_PAGE = 1;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  preview: {
    width: "100%",
    height: "max-content",
    minHeight: "100%",
    top: 0,
    backgroundColor: "white"
  },
  rootPaper: {
    padding: "10px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#f2f2f2",
    borderRadius: "0",
    border: "none"
  },
  input: {
    // width: "90%",
    borderRadius: "25px",
    backgroundColor: "#ffffff",
    padding: "10px 25px 10px 20px"
  },
  toolbar: {
    borderLeft: "none"
  },
  noMinHeight: {
    minHeight: "unset"
  },
  chatArea: {
    background: "#ffffff",
    flex: "1",
    minHeight: "0",
    overflow: "scroll",
    [theme.breakpoints.down("xs")]: {
      height: "calc(100vh - 187px)"
    }
  },
  bubble: {
    width: "100%",
    whiteSpace: "pre-wrap"
  },
  timeLeft: {
    marginRight: "auto",
    marginLeft: "30px",
    marginTop: "10px",
    justifyContent: "start",
    color: "#818181"
  },
  timeRight: {
    marginTop: "10px",
    marginRight: "30px",
    marginLeft: "auto",
    justifyContent: "flex-end",
    color: "#818181"
  },

  leftMsg: {
    width: "max-content",
    maxWidth: "50%",
    background: "#f7f7f7",
    wordWrap: "break-word",
    marginRight: "auto",
    borderBottom: "none",
    marginLeft: "30px",
    position: "relative",
    borderRadius: "0 15px 15px 15px"
  },

  rightMsg: {
    width: "max-content",
    maxWidth: "50%",
    background: "white",
    wordWrap: "break-word",
    marginRight: "30px",
    border: "solid 1px #cccccc",
    marginLeft: "auto",
    borderRadius: "15px 0 15px  15px"
  },
  mediaRight: {
    padding: 0,
    paddingRight: "30px",
    marginLeft: "auto",
    border: "none"
  },
  mediaLeft: {
    padding: 0,
    paddingLeft: "30px",
    marginRight: "auto",
    border: "none"
  },
  dateTab: {
    width: "160px",
    backgroundColor: "#dfecff",
    borderRadius: "16px",
    margin: "auto",
    color: "#4089f7"
  },
  emojis: {
    position: "absolute",
    bottom: 70,
    zIndex: 1200,
    left: 10,
    cssFloat: "right",
    "& .emoji-mart-preview": {
      display: "none"
    }
  },
  wrapperInner: {
    display: "flex",
    flexDirection: "column"
  },
  textareaInput: {
    maxHeight: "100px",
    overflow: "scroll",
    minHeight: "20px"
  },
  wrapper: {
    minHeight: "0px",
    width: "100%"
  },
  collapseHeight: {
    maxHeight: "320px"
  },
  attachmentAnchor: {
    color: "#000",
    "&:hover": {
      textDecoration: "none"
    }
  },
  banner: { color: "#65768C", background: '#FAFAFA' }
}));

function reducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case "add":
      return {
        ...state,
        messages: [...state.messages, ...snapshotDocsArrayToDataArray(payload).reverse()],
        lastFetchedMsg: !!state.messages.length
          ? state.messages[0]
          : payload[payload.length - 1],
      };
    case "fetchPrevMsgs":
      return {
        ...state,
        messages: [...snapshotDocsArrayToDataArray(payload).reverse(), ...state.messages],
        lastFetchedMsg: !!payload.length ? payload[payload.length - 1] : null,
        scrollToId: state.messages[0]._id
      };
    // case "modified": {
    //   const data = payload.data();
    //   const index = state.messages.findIndex(msg => msg._id === payload.id);
    //   if (index === -1) {
    //     return state;
    //   }
    //   const newMessages = [...state.messages];
    //   newMessages[index] = { _id: payload.id, data };
    //   return {
    //     ...state,
    //     messages: newMessages
    //   };
    // }
    default:
      return state;
  }
}
export const resizeAndUpload = async (
  previewFile, cid, sendMessage, path, isBroadcast, broadcastGrpId, uid, groupType="broadcasts"
) => {
  if (!previewFile.file) return;
  let ext = (previewFile.file.name || "").split(".").pop().toLowerCase();
  const isImage = !!previewFile.isImage;
  const isAudio = !!previewFile.isAudio;
  const isVideo = !!previewFile.isVideo;
  const identifier = `${uuidv4()}.${ext}`;
  if(path) path = path.concat("", `/${identifier}`);
  let originalWidth = previewFile.width;
  let originalHeight = previewFile.height;
  let dimensions = checkWidthHeight(
    originalWidth,
    originalHeight,
    CHAT_IMAGE_MAX_WIDTH,
    CHAT_IMAGE_MAX_HEIGHT
  )
    ? { width: originalWidth, height: originalHeight }
    : getWidthHeight(
      previewFile.aspect,
      CHAT_IMAGE_MAX_WIDTH,
      CHAT_IMAGE_MAX_HEIGHT
    );
  let thumbnail_dimensions = getWidthHeight(
    previewFile.aspect,
    CHAT_IMAGE_THUMBNAIL_MAX_WIDTH,
    CHAT_IMAGE_THUMBNAIL_MAX_HEIGHT
  );
  let dataUri = "";
  let dataUriThumbnail = ""
  if (isImage) {
    dataUri = await imageResizer(
      previewFile.file,
      ext.toUpperCase(),
      dimensions.width,
      dimensions.height,
      "base64"
    );

    dataUriThumbnail = await imageResizer(
      previewFile.file,
      ext.toUpperCase(),
      thumbnail_dimensions.width,
      thumbnail_dimensions.height,
      "base64"
    );
  } else {
    dataUri = await fileToBase64(previewFile.file)
  }

  await uploadDataUrl(dataUri, path);
  let payload = {};
  if (isImage) {
    if (ext.toLowerCase() === "jpg") ext = "jpeg";
    payload = {
      authorId: cid,
      staffId:uid,
      type: "image",
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      media: {
        imageData: dataUriThumbnail.replace(`data:image/${ext};base64,`, ""),
        completed: false,
        identifier,
        aspect: previewFile.aspect,
      },
    };
    if(isBroadcast) payload.media.base_path = `companies/${broadcastGrpId}/${groupType}`;
  } else if (isVideo){
    payload = {
      authorId: cid,
      staffId:uid,
      body: previewFile && previewFile.file.name,
      type: 'video',
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      attachment: {
        // imageData: dataUriThumbnail.replace(`data:image/${ext};base64,`, ""), // Thumbnail
        completed: true,
        identifier,
        aspect: Number(previewFile.aspect),
        size: previewFile && previewFile.file.size,
        width: previewFile.width,
        height: previewFile.height,
      }
    };
    if(isBroadcast) payload.attachment.base_path = `companies/${broadcastGrpId}/${groupType}`;
  } else if (isAudio){
    payload = {
      authorId: cid,
      staffId:uid,
      body: previewFile && previewFile.file.name,
      type: 'audio',
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      attachment: {
        completed: true,
        identifier,
        duration: previewFile && previewFile.duration,
        size: previewFile && previewFile.file.size,
      },
    };
    if(isBroadcast) payload.attachment.base_path = `companies/${broadcastGrpId}/${groupType}`;
  } else {
    payload = {
      body: previewFile && previewFile.file.name,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      type: "attachment",
      authorId: cid,
      staffId:uid,
      attachment: {
        completed: true,
        identifier,
        size: previewFile && previewFile.file.size,
      }
    };
    if(isBroadcast) payload.attachment.base_path = `companies/${broadcastGrpId}/${groupType}`;
  }
  if(!_.isEmpty(payload)) return sendMessage({...payload});
};
const initialState = {
  messages: [],
  lastFetchedMsg: null,
  scrollToId: `broadcastMessagesEnd`,
};

const BroadcastView = props => {
  const { appConfig, enqueueSnackbar, broadcastGrpId } = props;
  const { cid, comp, userProfile:{uid}={} } = useContext(FirebaseAuthContext);
  const { event } = useContext(AnalyticsContext);
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [group, setGroup] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [previewFile, setPreviewFile] = useState({});
  const [state, dispatch] = useReducer(reducer, initialState);
  const { messages, lastFetchedMsg, scrollToId } = state;
  const [messageData, setMessageData] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showAudio, setShowAudioRecorder] = useState(false);
  const [sendToLastRecipients, setSendToLastRecipients] = useState(false);

  const { hideLoader, showLoader, delete: deleteRdxFn } = props;
  // listener for changes in chat doc
  const scrollToItem = () => {
    if (document.getElementById(scrollToId)) {
      document.getElementById(scrollToId).scrollIntoView({ behaviour: "smooth", block: "end" });
    }
  };

  const planRedux = useSelector(s => s.plans);
  // on mount scroll to bottom
  useLayoutEffect(scrollToItem, [messages.length]);

  const fetchGroupData = async () => {
    setLoading(true);
    try {
      const docSnap = await firebase
        .firestore()
        .doc(`groups/${broadcastGrpId}`)
        .get();
      
      if (docSnap.exists) {
        setGroup(docSnap.data());
      }else {
        const group = DEFAULT_BROADCAST_GROUPS(cid).find(grp => grp._id === broadcastGrpId);
        const data = { ...(group.data), deleted: false, type: 'auto', deletable: false }
        if(group) {
          await firebase.firestore().doc(`groups/${broadcastGrpId}`).set(data);
          setGroup(data);
        }
      }
    } catch (error) {
      console.log(error)
      handleError(error, enqueueSnackbar)
    }
    setLoading(false);
  }
  
  const fetchBroadcasts = async () => {
    setFetching(true);
    try {
      const snapshot = await firebase
        .firestore()
        .collection(`groups/${broadcastGrpId}/broadcasts`)
        .orderBy('_cat', 'desc')
        .limit(10).get();
      
      dispatch({ type: "add", payload: snapshot.docs });;
    } catch (error) {
      console.log(error)
      handleError(error, enqueueSnackbar)
    }
    setFetching(false);
  }
  useLayoutEffect(() => {
    fetchGroupData();
    fetchBroadcasts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [broadcastGrpId])
  

  const sendMessage = async msg => {
    try {
      showLoader();
      if (msg.body) msg.body = msg.body.trim();
      if (msg.body === "" && (msg.type === 'text' || msg.type === 'video')) {
        hideLoader();
        return;
      }
      if(uid) msg.staffId = uid;
      let userDocs = [];
      if (!sendToLastRecipients) {
        const response = await fetchData('user_profiles', cid, 0, null, PER_PAGE, '', '', null, null, null, group.criteria)
        userDocs = response.data.data;
        if (!userDocs.length) {
          hideLoader();
          enqueueSnackbar('No Users Selected. Please change the group', { variant: 'error' })
          return;
        }
      }
      const broadcastMsg = {
        payload: msg,
        as_group: false,
        schedule: null,
        _sent: false,
        _cat: firebase.firestore.FieldValue.serverTimestamp(),
        _next: true
      }

      const broadcastRef = firebase.firestore().collection(`groups/${broadcastGrpId}/broadcasts`).doc();
      const groupRef = firebase.firestore().doc(`groups/${broadcastGrpId}`);
      await firebase.firestore().runTransaction(async t => {
        let promises = [];
        promises.push(t.set(broadcastRef, broadcastMsg));
        const changes = {
          total_messages: firebase.firestore.FieldValue.increment(1),
          last_message_body: broadcastMsg.payload.body || null,
          last_message_timestamp: broadcastMsg.payload.timestamp,
          last_message_type: broadcastMsg.payload.type,
        };
        ;
        promises.push(t.set(groupRef, changes, { merge: true }));
        await Promise.all(promises);
      });
      broadcastMsg.ts = Date.now();
      dispatch({ type: "add", payload: [{ exists: true, data: () => broadcastMsg, id: broadcastRef.id }] });
      // if(broadcastGrpId === `${cid}:newleadstomessage`) {
      //   setSendToLastRecipients(true);
      // }
      hideLoader();
      event('broadcast_send');
      return true;
    } catch (err) {
      console.log(err);
      hideLoader();
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' })
    }
  };

  const fetchMore = async () => {
    if (fetching) return;
    if (!lastFetchedMsg) {
      setFetching(false);
      return;
    }
    setFetching(true);
    const fetchedMsgs = await firebase
      .firestore()
      .collection(`groups/${broadcastGrpId}/broadcasts`)
      .orderBy("_cat", "desc")
      .startAfter(lastFetchedMsg)
      .limit(10)
      .get();

    setFetching(false);
    dispatch({ type: "fetchPrevMsgs", payload: fetchedMsgs.docs });
  };

  const cancelPreview = () => {
    setPreviewFile({});
  };

  const handleSend = async () => {
    if (!previewFile.file) return;
    showLoader();
    try {
      const path = `companies/${broadcastGrpId}/broadcasts/chats/${previewFile.isImage ? 'original' : 'attachment'}`;
      await resizeAndUpload(previewFile, cid, sendMessage, path, true, broadcastGrpId, uid);
      if(previewFile.isAudio) setShowAudioRecorder(false);
      setPreviewFile({});
      hideLoader();
    } catch (err) {
      console.log(err);
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' })
      hideLoader();
    }
  };

  // useEffect(() => {
  //   const unsubscribe = firebase
  //     .firestore()
  //     .collection(`groups/${broadcastGrpId}/broadcasts`)
  //     .where("_cat", ">", firebase.firestore.Timestamp.fromDate(new Date()))
  //     .onSnapshot({ includeMetadataChanges: true }, querySnapshot => {
  //       if (!!querySnapshot.docChanges().length) {
  //         querySnapshot.docChanges().forEach(function (change) {
  //           if (change.type === "modified") {
  //             console.log("modified")
  //             dispatch({ type: "modified", payload: change.doc });
  //           }
  //         });
  //       }
  //     });
  //   return () => unsubscribe();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  useEffect(() => {
    if (!previewFile.file) return;
    let fileSize = (previewFile && previewFile.file.size) || 0;
    const maxSize = (appConfig && appConfig.company_chat_file_max_size) || CHAT_FILE_SIZE_DEFAULT;

    if (!previewFile.isVideo && !previewFile.isAudio && !previewFile.isImage && fileSize > 1024 * 1024 * maxSize) {
      enqueueSnackbar(`Document size should not be more than ${maxSize} MB`, { variant: "error" });
      setPreviewFile({});
      return;
    }
    const { chat_config } = comp.data() || {};
    const maxVideoSize = (chat_config && chat_config.company_max_video_size) || 16; // in MB
    const maxVideoDuration = (chat_config && chat_config.company_max_video_duration) || 90; // in seconds
    if(previewFile.isVideo && fileSize > 1024 * 1024 * maxVideoSize) {
      enqueueSnackbar(`Video file size should not be more than ${maxVideoSize} MB`, { variant: "error" });
      setPreviewFile({});
      return;
    }
    if(previewFile.isVideo && previewFile.duration >  maxVideoDuration) {
      enqueueSnackbar(`Video file duration should not be more than ${maxVideoDuration} seconds`, { variant: "error" });
      setPreviewFile({});
      return;
    }
    if (!previewFile.isImage && !previewFile.isVideo && !previewFile.isAudio) {
      handleSend();
    }
  }, [previewFile, appConfig, enqueueSnackbar]); // eslint-disable-line react-hooks/exhaustive-deps

  function handleClick(e) {
    setAnchorEl(e.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }
  const handleDelete = async () => {
    showLoader();
    try {
      await firebase
        .firestore()
        .doc(`groups/${broadcastGrpId}`)
        .update({ deleted: true });
    } catch (error) {
      console.log(error)
      handleError(error, enqueueSnackbar)
    }
    deleteRdxFn(broadcastGrpId);
    setShowDeleteConfirmation(false);
    hideLoader();
    history.goBack();
  }
  if(loading) return <div className="d-flex flex-column h-100  w-100 position-relative"><CircularLoader className="position-absolute" /></div>
  if((!group && !loading) || (group && group.deleted)) return <PageNotFound keyName="Broadcast Group" />
  return (
    <div className="d-flex flex-column h-100 position-relative w-100 overflow-auto">
      <AppBar
        position="static"
        color="primary"
      >
        <Toolbar className="d-flex flex-row justify-content-between align-items-center fpl-0 fpr-10 bg-white" >
          <div className="d-flex flex-column fp-25">
            <Typography variant="h2">{group.ref_name}</Typography>
            { getFilterString(group.criteria, planRedux.docs) && <Typography variant="body2" className="text-dark-grey fmt-10">{getFilterString(group.criteria, planRedux.docs)}</Typography>}
          </div>
          {group.deletable && <IconButton edge="start" onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
          }
        </Toolbar>
        <Divider />
      </AppBar>
      <BroadcastMessageListView broadcastGrpId={broadcastGrpId} messages={messages} fetchMore={fetchMore} setMessageData={setMessageData} lastFetchedMsg={lastFetchedMsg} fetching={fetching} />
      <ChatInput sendMessage={sendMessage} setPreviewFile={setPreviewFile} setShowAudioRecorder={setShowAudioRecorder}/>
      { showAudio && (
            <AudioRecorder 
                onSave={handleSend}
                onChange={(data) => setPreviewFile(data)}
                open={showAudio}
                onClose={()=> {cancelPreview();setShowAudioRecorder(false)}}
                hideAction={!previewFile || !previewFile.file}
                />
        )}
      {previewFile && previewFile.file && !previewFile.isAudio  &&  (
          <MediaPreview previewFile={previewFile} onSend={handleSend} onCancel={cancelPreview} />
        )}
      {!!messageData && <BroadcastRecipientsList cid={cid} recipients={messageData.recipientsV2 || []} handleClose={setMessageData} />}
      {showDeleteConfirmation && (
        <Confirmation
          open
          handleClose={() =>
            setShowDeleteConfirmation(false)
          }
          handleChange={handleDelete}
          handleCancel={() =>
            setShowDeleteConfirmation(false)
          }
          title="Confirmation"
          msg="Are you sure you want to delete this group?"
          confirmOption={"Yes, Delete"}
        />
      )}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        elevation={4}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        classes={{
          paper: `fb-border  ${classes.menu}`
        }}
        onClick={handleClose}
      >
        <MenuItem key='Assign Trainer' onClick={() => setShowDeleteConfirmation(true)}>
            Delete Group
          </MenuItem>
      </Menu>
    </div>
  );
};

const mapDispatchToProps = d => {
  return {
    ...appRdxFns(d),
    ...broadcastsRdxFns(d)

  };
};

export default withWidth()(
  withSnackbar(connect(null, mapDispatchToProps)(BroadcastView))
);
