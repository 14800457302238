import _ from 'lodash';
import { Tooltip, LinearProgress, CircularProgress, Fab, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import firebase from 'firebase';
import Confirmation from 'fitbud/components/confirmationDialog';
import { ClrdButton } from 'fitbud/components/form-fields';
import { NoNotes } from 'fitbud/icons/noNotes';
import appRdxFns from "fitbud/redux/app";
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import NoteDialog from './noteDialog';

const Notes = (props) => {
  const { loadNotes, noteDocs, id, hideFab, refreshNotes } = props;
  const [openNotesDialog, setNotesDialog] = useState(false);
  const [isLoading, setLoading] = useState(!noteDocs.length);
  const [deleteId, setDeleteId] = useState(false);
  const [isMoreLoading, setMoreLoading] = useState(false);

  const d = useDispatch();
  const { showLoader, hideLoader } = appRdxFns(d);

  useEffect(() => {
    if (!noteDocs.length) {
      loadNotes(false, null, setLoading);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (editMode, note) => {
    return (e) => {
      e.preventDefault();
      if (editMode) {
        return setNotesDialog({ mode: 'edit', selected: { ...note } });
      }
      setNotesDialog(true);
    };
  };

  const closeDialog = (msg) => {
    if (msg === 'refresh') loadNotes(false, null, setLoading, true);
    setNotesDialog(false);
  };
  const handleDelete = (noteId) => {
    return (e) => {
      e.preventDefault()
      setDeleteId(noteId);
    };
  };
  const deleteNote = () => {
    if(!deleteId) return;
    const docRef = firebase.firestore().doc(`user_profiles/${id}/notes/${deleteId}`);
    showLoader();
    docRef.delete().then((res) => {
      if(!!refreshNotes) refreshNotes();
      else loadNotes(false, null, setLoading, true);
      setDeleteId(false);
      hideLoader();
    })
  };

  let scrollRef = useRef();
  const handleScroll = debounce(() => {
    if (isMoreLoading) return;
    if(!scrollRef || !scrollRef.current) return;
    let height = scrollRef.current.scrollHeight - scrollRef.current.clientHeight;
    const scrollTop = scrollRef.current.scrollTop;
    if (height <= scrollTop) {
      loadNotes(true, _.last(noteDocs), setMoreLoading)
    } else console.log(height, scrollTop);
  }, 200);

  return (
    <div className="fp-20 d-flex flex-1 overflow-auto h-100 flex-column position-relative align-items-center" 
      style={{ paddingBottom: '40px !important' }}
      ref={scrollRef} onScroll={handleScroll}>
      {!isLoading && !noteDocs.length && (
        <div className='d-flex flex-column w-100 h-100 justify-content-center align-items-center'>
          <NoNotes style={{width:120,height:120}} />
          <Typography variant='h3' className='fmb-10'>
            No Notes Added
          </Typography>
          <Typography variant='body1' color='textSecondary' align='center'>
            To add a note for this client, use the + button below
          </Typography>
          <Typography className='fmt-10 font_14_400' variant='body1' color='textSecondary' style={{maxWidth: '70%'}} align='center'>
            Use Notes to track the important client information such as latest injuries, equipment available etc.
          </Typography>
        </div>
      )}
      <Confirmation
        open={!!deleteId}
        handleCancel={() => setDeleteId(false)}
        handleChange={deleteNote}
        title='Warning'
        msg='This note will be deleted and this action cannot be undone.'
        confirmOption="Yes, Continue"
      />
      {!!openNotesDialog && (
        <NoteDialog
          userId={id}
          open={openNotesDialog}
          onClose={closeDialog}
          loader={{ showLoader, hideLoader }}
        />
      )}
      {!!isLoading && (
        <div className='d-flex w-100 h-100 justify-content-center align-items-center'>
          <CircularProgress />
        </div>
      )}
      {!isLoading && noteDocs.map((note, index) => {
        if (!note || !note.data()) return null;
        const { text, ts } = note.data();
        return (
          <div className={`fp-20 bg-white text-break w-100 ${index ? 'fmt-20' : ''}`} key={note.id + index}>
            <Typography className='body1' style={{whiteSpace: 'pre-wrap'}}>
              {text}
            </Typography>
            <div className='d-flex justify-content-between align-items-center fmt-10 '>
              <Tooltip enterDelay={0} title={moment(ts.toDate()).format('dddd, MMM Do, h:mm:ss a')} placement='right'>
                <Typography className='font_13_500' color='textSecondary'>
                  {moment(ts.toDate()).fromNow()}
                </Typography>
              </Tooltip>
              <div>
                <ClrdButton onClick={handleClick(true, { ...note.data(), id: note.id })}>
                  <EditIcon classes={{ root: 'small' }} className='fmr-10' /> Edit
                </ClrdButton>
                <ClrdButton onClick={handleDelete(note.id)}>
                  <DeleteIcon classes={{ root: 'small' }} className='fmr-10' />  Delete
                </ClrdButton>
              </div>
            </div>
          </div>
        );
      })}
      <div className='fmy-20 w-100'>{isMoreLoading && <LinearProgress className='w-100'/>}</div>
      {!isLoading && !hideFab && <Fab style={{ position: 'fixed', bottom: "20px", right: '20px' }} onClick={handleClick()} color='primary'>
        <Add />
      </Fab>}
    </div>
  )
}

export default Notes;
