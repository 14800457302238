import React, { useState } from 'react';
import { AppBar, Drawer, IconButton, Toolbar, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ClrdButton } from 'fitbud/components/form-fields';
import Notes from '../userNotes';
import NoteDialog from '../userNotes/noteDialog';

const NotesDrawer = ({ open, userDoc, docId, noteDocs, loadNotes, refreshNotes, loader, ...rest }) => {
  const [openNoteDialog, setOpenDialog] = useState(false);
  const handleClose = async(msg) => {
    if (msg === 'refresh'){
      loader.showLoader();
      await loadNotes(false, null, null, true);
      loader.hideLoader();
    }
    setOpenDialog(false);
  };
  return (
    <>
    {!!openNoteDialog && (
      <NoteDialog
        userId={docId}
        open={openNoteDialog}
        onClose={handleClose}
        loader={{ ...loader }}
      />
    )}
    <Drawer anchor="right" open={!!open} {...rest} classes={{ paper: 'drawer-420' }}>
      <AppBar
        position="sticky"
        color="primary"
      >
        <Toolbar
          classes={{
            root: "height-60"
          }}
          className={
            "d-flex flex-row bg-white justify-content-between align-items-center fpx-10"
          }
        >
          <div className='d-flex align-items-center'>
            <IconButton edge="end" color="inherit" onClick={rest.onClose}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h3" className='fml-20'>{"Client Notes"}</Typography>
          </div>
          <ClrdButton
            className="f-medium"
            variant="contained"
            color='primary'
            onClick={() => setOpenDialog(true)}
            style={{ boxShadow: 'none' }}
          >
            <Typography className='font_13_500'>
              Add New
            </Typography>
          </ClrdButton>
        </Toolbar>
      </AppBar>
      <div className='bg-light-grey flex-1 h-100'>
        <Notes
          refreshNotes={refreshNotes}
          userDoc={userDoc} 
          id={docId} 
          noteDocs={noteDocs} 
          loadNotes={loadNotes} 
          hideFab 
        />
      </div>
    </Drawer>
    </>
  )
}
export default NotesDrawer;
