import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const CheckinsIcon = props => (
    <SvgIcon
        {...props}
        viewBox="0 0 20 20"
        classes={{
          root: "small"
        }}
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5335 3.37608C13.3751 3.71108 13.6326 4.87775 13.9768 5.25275C14.321 5.62775 14.8135 5.75525 15.086 5.75525C16.5343 5.75525 17.7085 6.92942 17.7085 8.37692V13.2061C17.7085 15.1477 16.1335 16.7227 14.1918 16.7227H5.80848C3.86598 16.7227 2.29181 15.1477 2.29181 13.2061V8.37692C2.29181 6.92942 3.46598 5.75525 4.91431 5.75525C5.18598 5.75525 5.67848 5.62775 6.02348 5.25275C6.36764 4.87775 6.62431 3.71108 7.46598 3.37608C8.30848 3.04108 11.6918 3.04108 12.5335 3.37608Z" stroke="#8F969F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.5795 7.91667H14.587" stroke="#8F969F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.649 10.94C12.649 9.47667 11.4632 8.29083 9.99987 8.29083C8.53654 8.29083 7.35071 9.47667 7.35071 10.94C7.35071 12.4033 8.53654 13.5892 9.99987 13.5892C11.4632 13.5892 12.649 12.4033 12.649 10.94Z" stroke="#8F969F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </SvgIcon>
);
